class Permission {
    handleAddingPermisstion(permissionsGroup:any){
        localStorage.setItem('permission',permissionsGroup)
    }
    handleAddingPath(path:string){
        localStorage.setItem('path',path)
    }
    handleGettingPermission(groupKey: string) {
        let actionPermission = localStorage.getItem(`${groupKey}`)
        return actionPermission ? actionPermission.split(',') : [];
    }
    handleGettingPath() {
        let actionPath = localStorage.getItem(`path`)
        return actionPath
    }
}

export default new Permission()
export const _ = '';