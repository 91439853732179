import { Button, Col, Divider, Form, Input, InputNumber, message, Row, Table } from "antd";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BasicUserInfo from "../../components/basicUserInfo";
import BulkErrorsModal from "../../components/bulkErrorsModal";
import Spinner from "../../components/spinner";
import { stepperMoving } from "../../stores/actions";
import { getLatestInActiveReqAPI, refundInActiveRequestsAPI } from "../../utils/apisConsts";
import { errorMessage } from "../../utils/commonStringsConsts";
import { inActiveRequestsColumns } from "../../utils/tableColumns";
import TransactionErrorModal from "../../components/transactionErrorModal";

interface RowDataType {
    amount?: string | number;
    createdAt?: any;
    fees?: string | number;
    status?: any,
    advanceID?: Number,
    key: number | string
}
export default function RefundInActiveRequestForm() {
    const userInfo = useSelector((state: any) => state.actionsReducer.info)
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [requests, setRequests] = useState<RowDataType[]>([])
    const [selectedRequests, setSelectedRequests] = useState<RowDataType[]>([])
    const [resErrorContent, setResErrorContent] = useState<any>({
        resErrorContent: "",
        validationErrors: []
    })
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleMessageModal, setVisibleMessageModal] = useState(false)

    const dispatch = useDispatch()

    const rowSelection = {
        onSelect: (record: RowDataType, selected: boolean) => {
            const isFound = selectedRequests.some((req: any) => req.advancesID === record.advanceID)
            if (!isFound && selected) {
                setSelectedRequests([
                    ...selectedRequests,
                    record
                ])
            }
            else if (!selected) {
                setSelectedRequests(selectedRequests.filter((req: any) => req.advanceID !== record.advanceID))
            }
        },
        onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
            if (!selected) {
                setSelectedRequests([])
            }
            else {
                setSelectedRequests([
                    ...selectedRequests,
                    ...changeRows
                ])
            }
        }
    };

    useEffect(() => {
        getRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRequests = async () => {
        try {
            setLoading(true)
            const requestsRes: any = await dispatch(request({
                url: getLatestInActiveReqAPI, method: "GET",
                params: {
                    phone: userInfo.phone,
                    status: "3",
                    type: "1,10,14,15",
                    limit: 10,
                    hasFees: true,
                    isActiveRequest: false,
                }
            }))
            if (requestsRes.data.status === 200) {
                const userRequests = Array.isArray(requestsRes.data.data.userRecentRequests)
                    ? requestsRes.data.data.userRecentRequests.map((request: any, i: number) => ({ ...request, key: i + 1 }))
                    : []
                if (!userRequests.length) {
                    message.error("The user has no in-active requests to be refunded", 4)
                    dispatch(stepperMoving(0))
                    return
                }
                setRequests(userRequests)
                setLoading(false)
            }
            else {
                message.error(requestsRes.data.message, 4)
                dispatch(stepperMoving(0))
            }
        }
        catch (error: any) {
            console.log("🚀 ~ file: refundInActiveRequestsForm.tsx:83 ~ getRequests ~ error", error)
            message.error(errorMessage)
            dispatch(stepperMoving(0))
        }
    }

    const submitRefund = async (values: { comment: string }) => {
        if (!selectedRequests.length) {
            message.error(`Refunds are not possible for no requests, please select the requests  to be refunded`, 4)
            return
        }
        try {
            setSending(true)
            const response: any = await dispatch(request({
                url: refundInActiveRequestsAPI, method: "POST",
                data: { phone: userInfo.phone, comment: values.comment, advances: selectedRequests }
            }))

            if (response.data.status === 200) { //success
                message.success(response.data.message, 4)
                dispatch(stepperMoving(0))
            }
            else if (response.data.status === 422 && response.data.errorData?.invalidRequests) { //open errors list popup
                setResErrorContent({
                    resErrorContent: response.data.errorData.message,
                    validationErrors: response.data.errorData.invalidRequests
                })
                setVisibleModal(true)
            }
            else if (response.data.status === 400 && typeof response.data.errorData === "string") { //open transaction error popup
                setResErrorContent({
                    resErrorContent: {
                        message: response.data.message,
                        errorData: response.data.errorData
                    },
                    validationErrors: []
                })
                setVisibleMessageModal(true)
            }
            else {
                message.error(response.data.errorData, 4)
            }
            setSending(false)
        }
        catch (error: any) {
            console.log("🚀 ~ file: refundInActiveRequestsForm.tsx:91 ~ submitRefund ~ error", error)
            message.error(errorMessage)
            setSending(false)
        }
    }

    const closeModal = () => {
        setVisibleMessageModal(false)
        setVisibleModal(false)
    }

    return (
        <div className="refundRequest">
            {!requests.length && loading ? <Spinner size={"large"} />
                : <>
                    <Divider orientation="left">User Latest Requests</Divider>
                    <Table
                        pagination={false}
                        onChange={() => { }}
                        rowSelection={rowSelection}
                        columns={[
                            {
                                title: 'Refund Amount',
                                dataIndex: '',
                                fixed: 'left',
                                render: (row) => {
                                    const disableInput = !selectedRequests.some((req: any) => req.key === row.key)
                                    return <InputNumber placeholder="refundAmount" disabled={disableInput} value={disableInput ? 0 : undefined}
                                        onChange={(value) => setSelectedRequests(selectedRequests.map((req: any) => req.key === row.key ? { ...req, refundAmount: value } : req))} />
                                }
                            },
                            ...inActiveRequestsColumns
                        ]}
                        dataSource={requests}
                        loading={loading}
                        scroll={{ x: 'max-content' }}
                    />
                    <Form name="nest-messages" onFinish={submitRefund}>
                        <Row>
                            <Col span='24'>
                                <Form.Item
                                    name="comment"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Input.TextArea placeholder="Enter a comment" />
                                </Form.Item>
                            </Col>
                            <Col span='24' className="btnCol">
                                <Button shape="round" type="primary" block htmlType="submit" loading={sending}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <Divider orientation="left">User Information</Divider>
                    <BasicUserInfo info={userInfo} />
                </>
            }
            <BulkErrorsModal errors={resErrorContent.validationErrors} visibleModal={visibleModal} handleCloseModal={closeModal} errorMessage={resErrorContent.resErrorContent} />
            <TransactionErrorModal resErrorContent={resErrorContent.resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={closeModal} />
        </div>
    )
}