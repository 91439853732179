import React, { Component } from 'react'
import PinInput from "react-pin-input";
import { Button } from 'antd';
import Timer from './timer';
import { connect } from 'react-redux';
import { changePinCodeStatus, postApi, addPinCode, stepperMoving } from "../stores/actions";
import NotificationAlert from './notificationAlert';
import { CloseCircleOutlined } from '@ant-design/icons';

interface PROPS {
    changePinCodeStatus: any,
    postApi: any,
    addPinCode: any,
    time_out: boolean,
    phoneNumber: string,
    externalPhone?: string | null,
    submitFunc?: any,
    twilio?: boolean,
    userLang?: ("ar" | "en"),
    create?: boolean,
    stepperMoving?: any,
    phone: string,
    confirmUpdateData?: any,
    getOtpValue?: any,
    otpLoad?: boolean,
    otpModalVisible?: boolean,
}
class SubmittingBin extends Component<PROPS>{
    state = {
        btnLoader: false,
        successNotification: true,
        value: '',
        time: 30,
        errorMessage: null,
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.time_out !== prevProps.time_out) {
            this.onPinClear();
            this.setState({ errorMessage: null })
        };
    }
    pinOnChange = (value: any) => {
        this.setState({ value, errorMessage: null });
    };
    pinOnCompleteLogin = async (value: string) => {
        if (this.props.time_out) {
            this.onPinClear()
            return 0
        }
        if (this.props.getOtpValue) {
            this.props.getOtpValue(value);
            return
        }
        let info = {
            phone: this.props.externalPhone ? this.props.externalPhone : this.props.phoneNumber ? this.props.phoneNumber : this.props.phone,
            code: value
        }

        this.responseHandling(true, true)
        const verifyOtp = await this.props.postApi(`auth/verify-otp`, info);
        try {
            if (verifyOtp.error) {
                this.onPinClear()
                this.responseHandling(false, false)
                return 0;
            }
            if (verifyOtp.payload.status === 200) {
                (this.props.phoneNumber && this.props.create) && this.props.stepperMoving(2)
                if (this.props.submitFunc) {
                    this.props.submitFunc()
                    return
                }
                this.props.changePinCodeStatus(true)
                this.responseHandling(true, false)
                this.props.addPinCode(value)
            } else {
                console.log('teeeeeeeeeeeeeeeeeeeest');
                
                this.setState({ errorMessage: verifyOtp.payload.message })
                this.onPinClear()
                this.responseHandling(true, false)
            }

        } catch (error) {
            this.onPinClear()
            this.responseHandling(false, false)
        }
    }
    responseHandling = (successNotification: any, btnLoader: boolean) => {
        this.setState({ successNotification: successNotification, btnLoader: btnLoader })
    }
    onPinClear = () => {
        this.setState({
            value: ""
        });
        this.pinCode.clear();
    };
    pinCode: any;
    render() {
        let { btnLoader, successNotification, time, errorMessage } = this.state;
        let { time_out, phoneNumber, externalPhone, twilio, userLang, phone } = this.props;
        return (
            <div>
                <PinInput length={5} focus
                    onChange={this.pinOnChange}
                    ref={pin => (this.pinCode = pin)}
                    type="numeric"
                    inputMode="number"
                    style={{ padding: '10px', borderRadius: '5px' }}
                    inputStyle={time_out ? { borderColor: 'red' } : { borderColor: '#408033' }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    onComplete={this.pinOnCompleteLogin}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                {time_out && <><span className='timeOut'>Time out your pin code not valid anymore</span><br /></>}
                {errorMessage && <><span className='timeOut'>{errorMessage}</span><br /></>}

                {<Timer time={time} phone={externalPhone ? externalPhone : phoneNumber ? phoneNumber : phone} twilio={twilio} userLang={userLang} setLoading={(val: boolean) => this.setState({ btnLoader: val })} />}
                <Button className='bin bin-disabled' type="primary" htmlType="submit" loading={this.props.otpLoad ? this.props.otpLoad : btnLoader} block>
                    Submit
                </Button><br />
                {!successNotification && <NotificationAlert message={'Pin code has a problem, please try again'} placement={'topLeft'} duration={4} icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />} />}

            </div>
        )
    }
}
const mapStateToProp = (state: any) => ({
    time_out: state.auth.time_out,
    phoneNumber: state.actionsReducer.info.phone,
    phone: state.auth.phoneNumber,
    otpModalVisible: state.actionsReducer.otpModalVisible
})
export default connect(mapStateToProp, { changePinCodeStatus, postApi, addPinCode, stepperMoving })(SubmittingBin)