import { Button, Col, DatePicker, Descriptions, Form, FormInstance, InputNumber, message, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import helper from "../../utils/helper";
import { errorMessage } from "../../utils/commonStringsConsts";
import OutPutResult from "../../components/result";
import moment from "moment";
import { request } from "../../apis/axiosRequest";
import { createExternalPaymentApi, getOneCompanyApi, getPaymentCyclesApi, getUserInfoApi, getUserInstallmentsApi } from "../../utils/apisConsts";
import AllCompanyInfo from "../../components/companyInfo";
import { getDataInfo, stepperMoving } from "../../stores/actions";
import DataTable from "../../components/dataTable";
const { Option } = Select;

interface Props {
    isCompany: boolean,
    identifier: any
}
export default function SubmitEarlyPayment({ isCompany, identifier }: Props) {
    const [loadingData, setLoadingData] = useState(false)
    const [sendingData, setSendingData] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [paymentCycles, setPaymentCycles] = useState<{ [key: string]: any }>([])
    const [data, setData] = useState<any>(null)
    const [installments, setInstallment] = useState<any[]>([])
    const [loadingTableData, setLoadingTableData] = useState(false)
    const [columns, setColumns] = useState<any[]>([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        total: 0,
    })
    const dispatch = useDispatch()
    const formRef = React.createRef<FormInstance>();

    useEffect(() => {
        setLoadingData(true)
        getData()
            .then((payer) => getPaymentCycles(payer))
            .then(() => setLoadingData(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPaymentCycles = async (payer: { [key: string]: any }) => {
        console.log("🚀 ~ file: submitEarlyPayment.tsx ~ line 37 ~ getPaymentCycles ~ payer", payer);
        const params = {
            companyID: isCompany ? identifier : payer.userInfo.companyID,
            mostRecent: true
        }
        try {
            const paymentCyclesRes: { [key: string]: any } = await dispatch(request({ url: getPaymentCyclesApi, method: "GET", params: params }))
            if (paymentCyclesRes.data.status === 200) {
                const todayDate = new Date()
                const payerDate = new Date()
                payerDate.setDate(isCompany ? payer.data.actualCutOffDate : payer.userInfo.actualCutOffDate)
                if (payerDate <= todayDate) {
                    payerDate.setMonth(payerDate.getMonth() + 1)
                }
                const currentCycle = {
                    cutOffCycleDate: payerDate,
                    paymentCycle: Number(paymentCyclesRes.data.allPaymentCycles.paymentCycle) + 1
                }
                setPaymentCycles([paymentCyclesRes.data.allPaymentCycles, currentCycle])
            } else {
                message.error(paymentCyclesRes.data.message)
                setErrorMode(true)
            }
        }
        catch (error: any) {
            setErrorMode(true)
            setLoadingData(false)
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }

    const getData = async () => {
        try {
            const resData: { [key: string]: any } = await dispatch(request({ url: isCompany ? `${getOneCompanyApi}/${identifier}` : `${getUserInfoApi}?phone=${identifier}` }))
            if (resData.data.status === 200) {
                setData(isCompany ? resData.data.data : resData.data.userInfo)
                isCompany && dispatch(getDataInfo(resData.data.data))
                return resData.data
            } else {
                message.error(resData.data.message)
                setErrorMode(true)
            }
        }
        catch (error: any) {
            setErrorMode(true)
            setLoadingData(false)
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }

    const createEarlyPayment = async (values: any) => {
        try {
            setSendingData(true)
            const response: { [key: string]: any } = await dispatch(request({
                url: createExternalPaymentApi, method: "POST",
                data: {
                    ...(isCompany ?
                        { companyID: identifier }
                        : { userID: data.userID, startEarly: values.startEarly }),
                    paymentCycle: values.paymentCycle,
                    amount: values.amount,
                    collectionDate: moment(values.collectionDate).format("YYYY-MM-DD")
                }
            }))
            if (response.data.status === 200) {
                message.success(response.data.message)
                dispatch(stepperMoving(0))
            } else {
                message.error(response.data.message)
            }
            setSendingData(false)
        }
        catch (error: any) {
            setSendingData(false)
            message.error(errorMessage)
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }

    const handleTableChange = (e: any) => {
        console.log(e);
        setLoadingTableData(true)
        setTimeout(() => {
            setPagination(e)
            setLoadingTableData(false)
        }, 500);
    }

    const getInstallments = async (paymentCycle: number) => {
        try {
            setLoadingTableData(true)
            const installmentsRes: { [key: string]: any } = await dispatch(request({ url: getUserInstallmentsApi, method: "GET", params: { userID: data.userID, paymentCycle } }))
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 87 ~ getInstallments ~ installmentsRes", installmentsRes)
            if (installmentsRes.data.status === 200) {
                const installmentsData = Array.isArray(installmentsRes.data.installments) ?
                    installmentsRes.data.installments.map((installment: any) => ({
                        "User ID": installment["Advance.userID"],
                        "Advance ID": installment.advanceID,
                        "Service Type": installment["advanceID.serviceType"],
                        "Installment ID": installment.installmentID,
                        "Instalment Order": installment.instalmentOrder,
                        "Payment Month": moment(installment.actualPaymentDate).format('Do MMM, YYYY'),
                        "Payments Amount (EGP)": installment.paymentsAmount,
                        "Processing Fees (EGP)": installment.processingFees,
                        "Discount (EGP)": installment.advanceID,
                        "Revenue (EGP)": installment.revenueAfterDiscount
                    })) : []
                handleTableChange({
                    current: 1,
                    total: installmentsRes.data.installmentsCount,
                    pageSize: 5
                })
                setInstallment(installmentsData)
                setColumns(helper.generateTableColumns(installmentsData))
            } else if (installmentsRes.data.status === 404) {
                message.success(installmentsRes.data.message)
                setInstallment([])
            }
            else {
                message.error(installmentsRes.data.message)
                setInstallment([])
            }
            setLoadingTableData(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setLoadingTableData(false)
            setInstallment([])
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }

    return (
        errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
            <div className="createEarlyPayment">
                <Spin size="large" spinning={loadingData}>
                    <Form name="nest-messages" onFinish={createEarlyPayment} ref={formRef}>
                        <Row className="inputsRow">
                            <Col span={8}>
                                <label>Amount <span>*</span></label>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: 'Required' }, { type: "number", min: 1 }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label>Invoice Month <span>*</span></label>
                                <Form.Item
                                    name="paymentCycle"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select a month" onChange={!isCompany ? getInstallments : () => { }}>
                                        {Array.isArray(paymentCycles) &&
                                            paymentCycles.map((paymentCycle: { id: number, paymentCycle: number, cutOffCycleDate: string, isLocked: boolean }) => <Option key={paymentCycle.id} value={paymentCycle.paymentCycle} disabled={paymentCycle.isLocked}>{moment(paymentCycle.cutOffCycleDate).format('Do MMM, YYYY')}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label htmlFor="">Collection Date </label>
                                <Form.Item name="collectionDate"
                                    rules={[{ required: true, message: 'Required' }]}>
                                    <DatePicker format={"YYYY/MM/DD"} />
                                </Form.Item>
                            </Col>
                            {!isCompany && <Col span='8'>
                                <label>Start From <span>*</span></label>
                                <Form.Item
                                    name="startEarly"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select an option">
                                        <Option value={1}>Early Installments</Option>
                                        <Option value={0}>Late Installments</Option>
                                    </Select>
                                </Form.Item>
                            </Col>}
                            <Col span='24' className="btnCol">
                                <Form.Item>
                                    <Button shape="round" type="primary" block htmlType="submit" loading={sendingData}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col span={24}>
                            {
                                data && (
                                    isCompany ? <AllCompanyInfo /> :
                                        <Descriptions bordered style={{ margin: '15px 0' }}>
                                            <Descriptions.Item label="Name">{data.name ? data.name : 'NA'}</Descriptions.Item>
                                            <Descriptions.Item label="Phone">{data.phone ? data.phone : 'NA'}</Descriptions.Item>
                                            <Descriptions.Item label="Company">{data.companyName ? data.companyName : 'NA'}</Descriptions.Item>
                                        </Descriptions>
                                )
                            }
                        </Col>
                    </Row>
                    {(!isCompany && Array.isArray(installments) && installments.length > 0) ? <Row>
                        <Col>
                            <label className="tableLabel">User Installments</label>
                        </Col>
                        <Col span='24' className="btnCol">
                            <DataTable columns={columns} pagination={pagination} rows={installments} handleTableChange={handleTableChange}
                                loading={loadingTableData} scrolling={{ x: 2000 }} />
                        </Col>
                    </Row>
                        : loadingTableData && <Spin size="large" />
                    }
                </Spin>
            </div>
    )
}