import { Form, Row, Col, Select, Button, FormInstance, message, Input } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApi, patchApi, stepperMoving } from '../../stores/actions';
import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
import { errorMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';
import UserInfoUI from '../../components/uiInfo';

const { Option } = Select;

interface Props {
    getApi?: any,
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountHolderNameEn: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        userID: any,
    }
    patchApi?: any,
    stepperMoving?: any,
}
class UpdateBankInfo extends Component<Props> {
    state = {
        loading: false,
        statuses: [],
        reasons: [],
        banks: [],
        loadData: false,
        errorMode: false,
        dataSpin: false,
        bankInitialValue: null
    }
    formRef = React.createRef<FormInstance>();
    async componentDidMount() {
        await this.getDropDawns(`users/usersReasons`, 'statuses');
        await this.getBanks()
    }
    getBanks = async () => {
        this.setState({ loadData: true })
        const drops = await this.props.getApi('/users/banks');
        const res = await drops.payload;
        console.log("🚀 ~ file: userInfoForm.tsx ~ line 72 ~ EditUserInfo ~ getBanks= ~ res", res)
        try {
            if (res.status === 200) {
                const bankObj: { bankID: number } = res.banks.filter((bank: any) => this.props.info?.bankName === bank.bankName)[0]
                this.setState({ banks: res.banks, loadData: false, outboundData: false, bankInitialValue: bankObj ? bankObj.bankID : null })
            } else {
                this.setState({ loadData: false, outboundData: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ loadData: false, outboundData: false, errorMode: true })
        }
    }
    getDropDawns = async (apiLink: string, dynamicState: string) => {
        this.setState({ loadData: true })
        const drops = await this.props.getApi(apiLink);
        const res = await drops.payload;
        try {
            if (res.status === 200) {
                this.setState({ [dynamicState]: res.reasons, loadData: false })
                this.formRef.current!.setFieldsValue({ reasons: null, reason: null, module: null });

            } else {
                this.setState({ loadData: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ loadData: false, errorMode: true })
        }
    }

    onOtherSelected = (value: any, detail: any) => {
        console.log(detail.name.name);

        if (detail.name.name.includes('Other')) {
            this.setState({ otherComment: true })
            return
        }
        this.setState({ otherComment: false })

    }
    gettingReasons = (value: string, status: any) => {
        console.log("🚀 ~ file: userStatus.tsx ~ line 79 ~ UserStatus ~ reason", status)
        this.setState({ reasons: status.status.reasons });
        this.formRef.current!.setFieldsValue({ reasons: null });
    }

    changeUserStatus = async (values: any) => {
        console.log(values);
        this.setState({ dataSpin: true })
        const data = {
            userID: this.props.info?.userID,
            bankInfo: {
                bankID: values.bankID,
                accountNumber: values.accountNumber,
                accountHolderName: values.accountHolderName,
                accountHolderNameEn: values.accountHolderNameEn,
            }
        }
        this.setState({ changeStatusSpin: true })
        const endingCall = await this.props.patchApi('/users/updateBankInfo', data);

        try {
            const res = endingCall.payload;
            if (res.status === 200) {
                console.log(res);
                this.setState({ changeStatusSpin: false, dataSpin: false });
                message.success(res.message);
                helper.resetAccountTableInStepper();
                this.props.stepperMoving(0)
            }
            else {
                this.setState({ changeStatusSpin: false, dataSpin: false })
                message.error(res.message)
            }
        } catch (error) {
            this.setState({ changeStatusSpin: false, dataSpin: false })
            message.error('Something went wrong, please try again');

        }

    }
    render() {
        const { loadData, errorMode, banks, dataSpin, bankInitialValue } = this.state;
        const { info } = this.props;
        const bankInfo = [
            {
                key: 'Bank Name',
                value: <Form.Item
                    name="bankID"
                    initialValue={bankInitialValue}
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Select style={{ width: '100%' }}
                        placeholder="Select a bank name">
                        {Array.isArray(banks) && banks?.map((bank: any, key: number) => {
                            return <Option value={bank.bankID} key={key}>{bank.bankName}</Option>
                        })}
                    </Select>
                </Form.Item>
            },
            {
                key: 'Bank Account holder Name',
                value: <Form.Item
                    name="accountHolderName"
                    rules={[
                        { required: true, message: "Account name is mandatory!" },
                    ]}
                    initialValue={info?.bankAccountHolderName}
                >
                    <Input placeholder="Enter the account holder name" />
                </Form.Item>
            },
            {
                key: 'Bank Account holder Name (English)',
                value: <Form.Item
                    name="accountHolderNameEn"
                    rules={[
                        { pattern: helper.regexValidation.englishRegex, message: 'Account name (English) must be in english' },
                        { min: 3, message: `Account name (English) can't be less than 3 chars` },
                        { max: 250, message: `Account name (English) can't be more than 250 chars` },
                    ]}
                    initialValue={info?.bankAccountHolderNameEn}
                >
                    <Input placeholder="Enter the account holder name in english" />
                </Form.Item>
            },
            {
                key: 'Bank Account Number',
                value: <Form.Item
                    name="accountNumber"
                    rules={[
                        { required: true, message: "Account number is mandatory!" },
                    ]}
                    initialValue={info?.bankAccountNumber}
                >
                    <Input placeholder="Enter the account number"  />
                </Form.Item>
            },
        ]
        return (
            <>
                {loadData ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                    btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> : <>
                    <Form name="nest-messages" ref={this.formRef} onFinish={this.changeUserStatus}>
                        <Row>
                            <Col span={24}>
                                <UserInfoUI userData={bankInfo} activeKey={1} header={'General Comment'} nonEditable={true} collapsible={"disabled"} />
                            </Col>
                            <Col className='colOfAssign' span='24'>

                                <Form.Item>
                                    <Button style={{ margin: '22px 0 0 0 ', float: 'right', backgroundColor:"#7CC58F", borderColor:"#7CC58F" }} shape="round" type="primary" htmlType="submit" loading={dataSpin} >
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                </>}


            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, patchApi, stepperMoving })(UpdateBankInfo)

