import React, { Component, createRef } from 'react'
import { Button, Col, Divider, Form, FormInstance, Image, Input, InputNumber, Row, Select, Switch } from 'antd'
import { connect } from 'react-redux'
import { saveEmailData, stepperMoving } from '../../stores/actions'
import regex from '../../utils/helper';
import { arabicInputError, englishInputError } from '../../utils/commonStringsConsts';
import { emailObjType } from '../../utils/commonInterfaces';

interface Props {
    englishEmailData: emailObjType,
    arabicEmailData: emailObjType,
    saveEmailData(value: emailObjType, lang: ("en" | "ar")): void,
    stepperMoving(value: number): void,
    emailLang: "ar" | "en"
}

class CreateEmailForm extends Component<Props>{
    formRef = createRef<FormInstance>();
    formRef2 = createRef<FormInstance>();
    validation: any = regex.regexValidation
    state: any | { language: "ar" | "en" } = {
        formDisabled: true,
        ifFreePeriod: this.props.emailLang === "ar" ? this.props.arabicEmailData.ifFreePeriod : this.props.englishEmailData.ifFreePeriod,
        language: this.props.emailLang,
        formInitialValues: this.props.emailLang === "ar" ? this.props.arabicEmailData : this.props.englishEmailData,
        langValidation: {
            pattern: this.props.emailLang === "ar" ? this.validation.arabicRegex : this.validation.englishRegex,
            message: this.props.emailLang === "ar" ? arabicInputError : englishInputError
        }
    }

    componentDidMount(){
        console.log("this.state.langValidation = ", this.state.langValidation);
        
    }

    toggleForm = () => {
        this.setState((prevState: any) => ({ formDisabled: !prevState.formDisabled }))
    }
    saveData = (values: any, next?: boolean) => {
        this.props.saveEmailData({
            ...(this.state.language === "ar" ? { ...this.props.arabicEmailData } : { ...this.props.englishEmailData }),
            ...values
        }, this.state.language)

        this.toggleForm()

        if (next) {
            this.goToPreview()
            return
        }

        const drawer = document.querySelector(".ant-drawer-body")
        if (drawer) {
            drawer.scrollTop = 0
        }
    }
    goToPreview = () => {
        this.props.stepperMoving(2)
    }

    onFinishFailed = (error: any) => {
        console.log("🚀 ~ file: createEmailForm.tsx ~ line 48 ~ CreateEmailForm ~ error", error)
    }

    onChangeLanguage = (value: ("ar" | "en")) => {
        this.setState({
            language: value,
            formInitialValues: value === "ar" ? this.props.arabicEmailData : this.props.englishEmailData,
            langValidation: {
                pattern: value === "ar" ? this.validation.arabicRegex : this.validation.englishRegex,
                message: value === "ar" ? arabicInputError : englishInputError
            }
        }, () => {
            this.formRef.current!.resetFields()
        })
    }

    render() {
        const validation: any = this.validation
        const { formDisabled, ifFreePeriod, language, formInitialValues, langValidation } = this.state

        return (
            <div className='emailsForm' id='emailsForm'>

                <Form
                    name="basic"
                    ref={this.formRef2}
                    initialValues={formInitialValues}
                    onFinish={(values) => this.saveData(values, true)}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Divider orientation="left">Required Information</Divider>
                    <Row>
                        <Col span={6}>
                            <label htmlFor="">email language <span>*</span> </label>
                            <Form.Item initialValue={this.props.emailLang} name="lang" rules={[{ required: true, message: 'Required' }]}>
                                <Select style={{ width: "100%" }} placeholder="Select email language" onChange={this.onChangeLanguage}>
                                    <Select.Option value={"ar"}>Arabic</Select.Option>
                                    <Select.Option value={"en"}>English</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label htmlFor="">Installment options <span>*</span> </label>
                            <Form.Item name="installmentOption" rules={[{ required: true, message: 'Required' }]}>
                                <Select style={{ width: "100%" }} placeholder="Select email language">
                                    <Select.Option value={3}>3 Months</Select.Option>
                                    <Select.Option value={5}>5 Months</Select.Option>
                                    <Select.Option value={10}>10 Months</Select.Option>
                                    <Select.Option value={12}>12 Months</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Ref link <span> *</span></label>
                            <Form.Item name="refLink" rules={[{ required: true, message: 'Required' }, { pattern: validation.urlRegex, message: "Invalid link" }]}>
                                <Input placeholder='Please enter ref link' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label htmlFor="">Free period</label>
                            <Form.Item name="ifFreePeriod" valuePropName="checked">
                                <Switch onChange={() => this.setState((prevState: any) => ({ ifFreePeriod: !prevState.ifFreePeriod }))} />
                            </Form.Item>
                        </Col>
                        {ifFreePeriod && <Col span={6} className="serviceFees">
                            <label htmlFor="">Period <span>*</span></label>
                            <Form.Item name="freePeriod" rules={[{ required: true, message: "Required" }]}>
                                <InputNumber min={1} placeholder='Please enter free period' />
                            </Form.Item>
                        </Col>}
                        <Col span={24} className='topButtons'>
                            <Button htmlType='submit' type="primary">
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Form
                    name="basic"
                    ref={this.formRef}
                    initialValues={formInitialValues}
                    onFinish={this.saveData}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Divider orientation="left">Edit Email
                    </Divider>
                    <Row>
                        <Col span={12}>
                            <Switch className='enableForm' checked={!formDisabled} onChange={this.toggleForm} />
                            {formDisabled ? "Open to enable editing" : "Close to discard changes"}
                        </Col>
                        <Col span={12} className='topButtons'>
                            <Button type="primary" onClick={this.toggleForm}>
                                {formDisabled ? "Enable Editing" : "Discard Changes"}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='logoCol' span={24}>
                            <Image
                                width={150}
                                src={language === "ar" ? this.props.arabicEmailData.companyLogo : this.props.englishEmailData.companyLogo}
                            />
                        </Col>
                        <Col span={8}>
                            <label htmlFor="">Company Name <span>*</span></label>
                            <Form.Item name="companyName" rules={[{ required: true, message: 'Required' }]}>
                                <Input disabled={formDisabled} placeholder='Please enter company name' />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <label htmlFor="">Sentence after the name <span>*</span></label>
                            <Form.Item name="headerAfterName" rules={[{ required: true, message: 'Required' }, langValidation]}>
                                <Input disabled={formDisabled} placeholder='Please enter the sentence' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label htmlFor="">Description<span>*</span></label>
                            <Form.Item name="description" rules={[{ required: true, message: 'Required' }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter the description' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left">Services</Divider>
                    <Row>
                        <Col span={12}>
                            <label htmlFor="">Salary advance service <span>*</span></label>
                            <Form.Item name="salaryAdvanceService" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter salary advance service description' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Payment service <span>*</span></label>
                            <Form.Item name="paymentService" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter payment service description' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Internal transferring service <span>*</span></label>
                            <Form.Item name="internalTransferringService" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter internal transferring service description' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Shopping service <span>*</span></label>
                            <Form.Item name="shoppingService" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter shopping service description' />
                            </Form.Item>
                        </Col>
                        <Col span={18} className="installmentDescription">
                            <label htmlFor="">Installment description <span>*</span></label>
                            <Form.Item name="payrollDescription" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter Installment description' />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="serviceFees">
                            <label htmlFor="">Service fees <span>*</span></label>
                            <Form.Item name="serviceFees" rules={[{ required: true, message: "Required" }]}>
                                <InputNumber min={0} step={0.1} disabled={formDisabled} placeholder='Please enter service fees' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left">How It Works</Divider>
                    <Row>
                        <Col span={12}>
                            <label htmlFor="">Section 1 <span>*</span></label>
                            <Form.Item name="howItWorks1" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter section 1 description' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Section 2 <span>*</span></label>
                            <Form.Item name="howItWorks2" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter section 2 description' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Section 3 <span>*</span></label>
                            <Form.Item name="howItWorks3" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter section 3 description' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Section 4 <span>*</span></label>
                            <Form.Item name="howItWorks4" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter section 4 description' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label htmlFor="">Video link <span>*</span></label>
                            <Form.Item name="videoLink" rules={[{ required: true, message: "Required" }, { pattern: validation.urlRegex, message: "Invalid link" }]}>
                                <Input disabled={formDisabled} placeholder='Please enter how it works video link' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left">Contact Us</Divider>
                    <Row className='contactUs'>
                        <Col span={16}>
                            <label htmlFor=""> Contact us description <span>*</span></label>
                            <Form.Item name="questionsString" rules={[{ required: true, message: "Required" }, langValidation]}>
                                <Input.TextArea disabled={formDisabled} placeholder='Please enter contact us description' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <label htmlFor=""> NowPay contact <span>*</span></label>
                            <Form.Item name="questionsContact" rules={[{ required: true, message: "Required" }]}>
                                <Input disabled={formDisabled} placeholder='Please enter nowpay contact' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col span={24}>
                        <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
                            <Button disabled={formDisabled} type="primary" htmlType="submit" loading={false}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>

            </div>
        )
    }
}

const mapStateToProp = (state: any) => ({
    englishEmailData: state.actionsReducer.englishEmailData,
    arabicEmailData: state.actionsReducer.arabicEmailData,
    emailLang: state.actionsReducer.emailLang
})

export default connect(mapStateToProp, { saveEmailData, stepperMoving })(CreateEmailForm)
