import { truckingApis } from "./commonStringsConsts";

/** Apis for trucking app */
export const getCompanyBalanceApi = `${truckingApis}companies/balance`
export const createVirtualDepositApi = `${truckingApis}users/createVirtualDeposit`
export const updateCompanyBalanceApi = `${truckingApis}deposits`
export const getAllDrivers = `${truckingApis}users`
export const getTruckingCompaniesApi = `${truckingApis}companies`
export const createTransferApi = `${truckingApis}transfers`
export const createTransactionApi = `${truckingApis}transactions/transaction`
export const updateCompanyRefApi = `${truckingApis}transactions/companyRef`

export const approveBankTransactionsApi = `${truckingApis}transactions/approveBankTransactions`

/**Apis for OPS Tool */
export const createPartnerAPI = "/partners/createPartner";
export const getUserDocsAPI = '/users/getUserDocs?phone=';
export const getPartners = "/partners/getPartners";
export const getNonDeployedCompanies = "/companies/getNonDeployedCompanies";
export const createPartnerBranches = "/partners/createPartnerBranches";
export const getUngroupedCompanies = '/companies/getUngroupedCompanies';
export const createCompanyGroup = '/companies/createCompanyGroup';
export const resolveUserDocs = '/users/resolveUserDocs';
export const getCompaniesGroups = '/companies/getCompaniesGroups';
export const assignVipUser = '/users/assigneVipUser';
export const getFeesCompaniesApi = '/fees/getFeesCompanies';
export const assignFeesCompaniesApi = '/fees/assignCompanyToFeesModel';
export const allCompanies = '/companies/get';
export const unDeployedCompanies = '/companies/get?deployed=false';
export const deployedCompanies = '/companies/get?deployed=true';
export const companiesApi = '/companies/get';
export const getInvoicesApi = '/invoices/getInvoices' //takes ?companyID=13&paymentCycle=3;
export const getAllInstallmentsApi = `/installments/getAllInstallments`;
export const getPaymentCyclesApi = '/paymentCycles/getPaymentCycles'
export const getTotalDeductionsSumApi = '/installments/getInstallmentsSum'
export const generateInvoiceApi = '/installments/getInstallmentsPivot'
export const lockPaymentCycleApi = '/paymentCycles/lockPaymentCycle'  //patch Api
export const unlockPaymentCycleApi = '/paymentCycles/unlockPaymentCycle' //patch Api
export const getUserFreePeriodsApis = '/callCenter/getUserFreePeriods';
export const createLostPlanInstallmentsApi = '/callCenter/lostPlanInstallments'
export const getLostPlanInstallmentsApi = '/callCenter/lostPlanInstallments'
export const updateLostPlanInstallmentsApi = '/callCenter/lostPlanInstallments'
export const assignNonDeployedCompanyToFeesModel = '/fees/assignNonDeployedCompanyToFeesModel'
export const getOtpApi = '/users/getUserLastOtp'
export const sendOtpTwilioApi = '/users/sendOtpViaTwilio'
export const getUserInfoApi = 'users/getUserInfo'
export const getUserInstallmentsApi = '/users/getUserInstallments'
export const updateUserCompanyApi = '/users/updateUserCompany'
export const uploadUserDocsApi = "users/uploadUserDocs"
export const uploadApprovedUserDocsApi = "/users/uploadApprovedUserDocs"
export const getOneCompanyApi = '/companies/getOneCompany'
export const createExternalPaymentApi = '/requests/externalPayment'
export const revokeRequestApi = '/requests/revokeRequest' // /:id
export const createMeezaRequestsApi = "/requests/createBulkMeezaCards"
export const createClearanceApi = "/requests/createClearanceFees"
export const createCollectionsApi = '/invoices/addCollections'
export const bulkCreateExternalPaymentApi = '/requests/bulkExternalPayment'
export const createAdjustmentApi = '/invoices/createAdjustment'
export const getUserAndCompanyAmountsAPI = 'invoices/getUserAndCompanyAmounts'
export const deleteAdjustmentApi = '/invoices/deleteAdjustment'
export const getAdjustmentApi = '/invoices/getAdjustment'
export const updateBulkApprovedUsersApi = "/users/updateBulkApprovedUsers"
export const updateBulkApprovedUsersAdvanced = "users/updateBulkApprovedUsersAdvanced"
export const updateCompanyInfoAPI = '/companies/updateCompanyInfo'
export const getAllProducts = `/onlineShopping/products`
export const getOnePartnerApi = 'partners/';
export const patchTopPartnerApi = '/partners/popularToggle/'
export const updateCompanyInfoAdvanceAPI = '/companies/updateCompanyInfoAdvance'
export const createLostApi = '/invoices/createLostRequest'
export const createExceptionLostApi = 'invoices/createExceptionLostRequest';
export const getLostDetailsApi = '/invoices/getLostDetails'
export const createLostCollectionApi = '/invoices/createLostCollection'
export const deleteLostApi = '/requests/deleteLostRequest'
export const addBrandsApi = `onlineShopping/brands`;
export const addCatagoriesApi = `onlineShopping/categories`;
export const deleteCreditApi = '/invoices/deleteDebitCredit'
export const createCompanyApi = '/companies/createCompany'
export const getBlockedUserAmountApi = '/invoices/getBlockedUserAmount';
export const getPartnersTypesApi = '/partners/getPartnerTypes';
export const createPartnersTypesApi = '/partners/createPartnerType';
export const createBulkProductsApi = '/onlineShopping/products/bulkCreateProducts';
export const updateBulkProductsApi = '/onlineShopping/BulkUpdateProducts';
export const getStoreNamesApi = '/onlineShopping/getProductsStores';
export const getOnlineTransactionsApi = 'requests/onlineShoppingRequests';
export const getRequestsStatusApi = 'requests/requestStatus';
export const updateOnlineTransactionsApi = 'requests/changeRequestStatus/';
export const dataAnalyticsScheduleApi = `analytics/scheduleApi`;
export const dataAnalyticsDemandApi = `analytics/callApi`;
export const deleteScheduledApi = `analytics/deleteScheduledApi`
export const terminatePidApi = `analytics/terminateApi`;
export const getRequestIssuesApi = 'requests/getAdvanceIssues';
export const resolveIssueApi = '/requests/updateAdvanceIssue';
export const resolveSystemIssuesApi = 'requests/resolveAdvancesIssuesAutomatically';
export const getLatestInActiveReqAPI = '/requests/getUserRecentRequests'
export const refundInActiveRequestsAPI = '/requests/createRefundRequest'
export const getRequestInstallmentsApi = '/requests/getAdvanceDetails'
export const refundActiveRequestsAPI = '/requests/refundActiveRequest'
export const clearRefundApi = `/requests/clearAdvanceRefund`;
export const getAdvancesStatusesAPI = "/requests/advancesStatusTypes"
export const updateRequestStatusAPI = "requests/updateRequestStatus"
export const sendOTPApi = `auth/send-otp`
export const getUserBalanceApi = `requests/user-balance-by-phone`;
export const createRequestApi = `/requests/createRequest`;
export const createExceptionsRequestApi = `/requests/createExceptionRequest`;
export const getRequestApi = 'requests/getRequest/';
export const recalculateRequestComparisonApi = 'requests/getRecalculateRequestComparison';
export const paymentPlansApi = '/users/paymentPlans/';
export const getRequestFeesApi = '/users/viewRequestFees'
export const getServiceTypesApi = '/services/'
export const recalculateRequestApi = '/requests/recalculateRequest'
export const getRecalculatedRequestApi = "requests/getRecalculateRequestComparison"
export const addRequestsCommentsApi = "requests/addRequestsComments"
export const addUsersCommentsApi = "users/addUsersComments"
export const getDepartmentsApi = 'companies/getDepartments';
export const createDepartmentsApi = 'companies/createDepartments';
export const importCallReportsApi = 'callCenter/importReport';
export const getAllSuspensionReasonsApi = '/users/suspensionReasons';
export const transfersAnalyticsApi = "transfers/analytics";
export const transactionsAnalyticsApi = "transactions/analytics";
export const getAllTransfersApi = "transfers"
export const getAllTransactionsApi = "transactions"
export const getUserByPhoneApi = 'users/getUserInfo?phone='
export const getCompanySuspensionReasonsApi = '/services/companiesSuspensionReasons';
export const getBlockingIssuesTypesApi = `requests/getBlockingIssueTypes`;
export const getBlockingIssuesApi = `requests/getBlockingIssues`;
export const updateAdvanceIssueApi = 'requests/updateAdvanceIssue'
export const getServiceGroupsApi = 'services/getAllServicesGroups';
export const changeRequestStatusApi = 'requests/updateRequestStatus';
export const getUserBanksApi = `users/banks`
export const createBulkUsersAPI = `/users/createBulkUsers`;
export const createExceptionAdjustmentApi = `invoices/createExceptionAdjustment`;
export const getUserAccountsApi = `users/getUserAccounts?phone=`;
export const getAmanBalanceApi = `requests/amanBalance`