import React, { Component } from 'react'
import { Form, Input, Select, Button, Row, Col, FormInstance, message } from 'antd';
import { connect } from 'react-redux';
import { postApi, getApi } from '../stores/actions';
import OutPutResult from '../components/result';
import Spinner from '../components/spinner';
const { Option } = Select;
interface Props {
    postApi: any,
    getApi: any,
}
class createFeesGroup extends Component<Props> {
    formRef = React.createRef<FormInstance>();
    
    state = {
        services: [],
        loading: false,
        loadingData: true,
        errorMode: false
    }
    componentDidMount() {
        this.getDataToDropDowns();
    }

    getDataToDropDowns = async () => {
        const services = await this.props.getApi(`/fees/getServices`);
        try {
            const data = await services.payload;
            if (data.status === 200) {
                this.setState({ loadingData: false, services: data.data })
            } else {
                this.setState({ loadingData: false, errorMode: true });
            }
        } catch (error) {
            this.setState({ loadingData: false, errorMode: true })
        }
    }
    createGroup = async (value: any) => {
        this.setState({ loading: true })
        let data = {} as any;
        data = {
            services: value.services,
            name: value.name,
        }
        const sendGroupData = await this.props.postApi('/fees/createServicesGroup', data);
        try {
            const data = await sendGroupData.payload;

            if (data.status === 200) {

                this.setState({ loading: false });
                message.success(data.message, 4);
                this.formRef.current!.resetFields();
            } else {
                this.setState({ loading: false });
                message.error(data.message, 4);
            }
        } catch (error) {
            this.setState({ loading: false });
            message.error('Something went wrong, please try again', 4);
        }
    }
    refreshPage = () => {
        window.location.reload();
    }
    render() {
        const { services, loading, loadingData, errorMode } = this.state;
        return (
            <>
                {loadingData ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh and try againg'}
                    btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> :
                    <div className='groupFees'>
                        <div className='assignService'>
                            <Form name="nest-messages" ref={this.formRef} onFinish={this.createGroup}>
                                <Row>
                                    <Col className='colOfAssign' span='8'>
                                        <label>Services </label>

                                        <Form.Item
                                            name="services"
                                            rules={[{ required: true, message: 'Services are mandatory!', type: 'array' }]}
                                            hasFeedback
                                        >
                                            <Select mode="multiple"
                                                placeholder="Please select the services"
                                                optionFilterProp="children"
                                                filterOption={(input: string, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {services.map((service: any, key: number) => {
                                                    return <Option value={service.id} key={key}>{service.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </Col>
                                    <Col className='colOfAssign' span='8'>
                                        <label>group name </label>
                                        <Form.Item name={['name']} rules={[{
                                            required: true, message: 'Group name is mandatory'
                                        }]} style={{ width: '100%' }}>
                                            <Input placeholder='Create a group name' />
                                        </Form.Item>

                                    </Col>

                                    <Col className='colOfAssign' span='4'>

                                        <Form.Item>
                                            <Button className='createGroupBtn' shape="round" type="primary" block htmlType="submit" loading={loading} >
                                                Create
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Form>
                        </div>
                    </div>
                }
            </>

        )
    }
}
export default connect(null, { postApi, getApi })(createFeesGroup)
