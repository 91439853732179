import { Row, Col } from 'antd';
import moment from 'moment';
import { Component } from 'react'
import helper from '../../utils/helper';
interface Props {
    data: {
        salariesPaid: string,
        fees: number,
        subject: string,
        com: string,
        date: string,
        serial: string,
        settled: string,
        total: string,
        taxRate: number,
        taxFees: number,
        debitAndCredit: number,
        discount: number,
        previousCreditRevenue: number
    }
}
export class InvoiceTemp extends Component<Props> {
    render() {
        const { data } = this.props;
        return (
            <>
                <div className="invoice">
                    <div className="nowPayLogo">
                        <img src="https://operations.nowpay.cloud/static/media/loginLogoIcon.34b51808.71c456dc.png" alt="" width={'40px'} />
                        <h2>nowpay</h2>
                    </div>
                    <div className="invoiceHeader">
                        <Row>
                            <Col span={12}>
                                <div className="leftSide">
                                    <h3>NowPay</h3>
                                    <h5 className='address'>12 Nile st, Above Al-Yaman Cultural Center, Giza</h5>
                                    <h5>COM: <span>{data.com}</span></h5>
                                    <h5>SUBJECT: <span>{data.subject}</span></h5>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="rightSide">
                                    <h3 className='claim'>CLAIM</h3>
                                    <h5>SERIAL # [<span>{data.serial}</span>]</h5>
                                    <h5>DATE: <span>{moment(data.date).format("MMMM DD, YYYY")}</span></h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="invoiceBody">
                        <table>
                            <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Advance Salaries Paid</td>
                                <td><span>{helper.amountFormatter(data.salariesPaid)}</span></td>
                            </tr>
                            {/* {data.fees && <tr>
                                <td></td>
                                <td>Service Fees</td>
                                <td><span>{data.fees}</span></td>

                            </tr>}
                            {data.discount ? <tr>
                                <td></td>
                                <td>Discount</td>
                                <td><span>{data.discount}</span></td>

                            </tr> : ""} */}
                            {/* {data.taxRate ? <tr>
                                <td></td>
                                <td>Tax Rate on Service Fees</td>
                                <td><span>-{data.taxRate}%</span></td>

                            </tr> : ""}
                            {data.taxFees ? <tr>
                                <td></td>
                                <td>Tax on Services Fees</td>
                                <td><span>{data.taxFees}</span></td>

                            </tr> : ""} */}
                            {data.settled ? <tr>
                                <td></td>
                                <td>Settled by External Payments</td>
                                <td><span>{"-" + helper.amountFormatter(data.settled)}</span></td>

                            </tr> : ""}
                            {data.debitAndCredit ? <tr>
                                <td></td>
                                <td>Debit/Credit</td>
                                <td><span>{helper.amountFormatter(data.debitAndCredit)}</span></td>

                            </tr> : ""}
                            <tr>
                                <td></td>
                                <td>Total Due Collection</td>
                                <td><span>{helper.amountFormatter(data.total)}</span></td>
                            </tr>

                        </table>
                        <span className='total'>
                            <span className='egp'>EGP</span>
                            <span className='amount'>{helper.amountFormatter(data.total)}</span>
                        </span>
                    </div>
                    <div className="invoiceFooter">
                        <ul className="contact">
                            {data.fees ?
                                <><li>{(Number(data.fees) > 0 ? "Net Revenue= " : Number(data.fees) < 0 ? "Discount= " : "") + helper.amountFormatter(data.fees)}EGP (included in Advance Salaries Paid) </li>
                                    {
                                        data.taxRate || data.taxFees ?
                                            <>
                                                <li>Tax Rate on Service Fees: {data.taxRate}% </li>
                                                <li>Tax on Service Fees: {Number(data.fees) < 0 ? 0 : helper.amountFormatter(data.taxFees)}EGP </li>
                                            </> : ""
                                    }
                                </>
                                : ""}
                            {data.previousCreditRevenue ?
                                <li>Debit/Credit {(Number(data.previousCreditRevenue) > 0 ? "Revenue " : Number(data.previousCreditRevenue) < 0 ? "Discount " : "")}for {moment(data.date).month(new Date(data.date).getMonth()-1).format("MMM YYYY")} = {helper.amountFormatter(Number(data.previousCreditRevenue).toFixed(2))}EGP</li>
                                : ""}
                        </ul>
                        <p className="contact">
                            If you have any questions concerning this note, contact <span>support@nowpay.cash</span><br />
                            This Claim isn't confirmation of your payment.
                        </p>
                        <p className="signature">
                            <span><span className='signatureWord'>signature:</span> <img src={'https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/signature.png'} alt="" /></span>
                        </p>
                        <img className='stamp' src={"https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/NowPay_Stamp.png"} alt="" />
                    </div>
                </div>
            </>
        )
    }
}

export default InvoiceTemp