import React, { Component } from 'react'
import { Steps, Descriptions, Button, Row, Col } from 'antd';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import NotificationAlert from '../components/notificationAlert';
import PhoneNumber from '../components/phoneNumber';
import ModalCompnent from '../components/modal';
const { Step } = Steps;
const regexEgypt = /^((\+?20)|0)?1[0125]\d{8}$/;
export class ChangePhoneNumber extends Component {
    state = {
        current: 0,
        visibleModal: false,
        loadingConfirm: false,
        successNotification: null,
        phone_is_valid: false,
        modal_phone_is_valid: false,
        phone: null,
        modalPhone: null,
        submit_disable: true,
        submitPhoneNum: false,
        modal_submit_disable: true,
        userData: [
            { name: 'User Name', value: 'Mohammed Swaaf' },
            { name: 'Email', value: 'mohammedswaaf@gmail.com' },
            { name: 'Company Name', value: 'nowPay' },
            { name: 'Bank Name', value: 'CIB' },
            { name: 'Requests', value: '5' },
            { name: 'Deductions', value: '107.5 EGP' },
            { name: 'Max Allowed Advance', value: '20.000 EGP' },
            { name: 'Current Allowed Advance', value: '10.000 EGP' },
        ]
    }
    onStepperClick = (step: number) => {
        if (this.state.current === 0) {
            return
        }
        this.setState({ current: step })
    }
    phoneNumberChange = (value: string) => {
        if (regexEgypt.test(value)) {
            if (this.state.current === 0) {
                this.setState({ phone_is_valid: false, submit_disable: false, phone: value.slice(-11) })
            } else {
                this.setState({ modal_phone_is_valid: false, modalPhone: value.slice(-11), modal_submit_disable: false })
            }
        }
        else {
            if (this.state.current === 0) {
                this.setState({ phone_is_valid: true, submit_disable: true, modal_submit_disable: true })
            } else {
                this.setState({ modal_phone_is_valid: true, modal_submit_disable: true })
            }
        }
    }
    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (this.state.submit_disable && this.state.modal_submit_disable) {
            return
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.submitPhoneNumber();
        }
    }
    submitPhoneNumber = () => {
        if (this.state.current === 0) {
            console.log(this.state.phone);

            this.setState({ submitPhoneNum: true, phone: this.state.phone })
            setTimeout(() => {
                this.setState({ current: 1 }, () => {
                    this.setState({ phone_is_valid: false, submit_disable: true, submitPhoneNum: false })
                })
            }, 1500);
        } else {
            this.setState({ loadingConfirm: true })
            setTimeout(() => {
                this.setState({
                    loadingConfirm: false, visibleModal: false, current: 0, successNotification: true,
                    modal_phone_is_valid: false, modal_submit_disable: true, modalPhone: null,
                })
            }, 1500);
        }

    }
    handleOpenChangPhoneModal = () => {
        this.setState({ visibleModal: true })
    }

    handleCloseNumbeModal = () => {
        this.setState({ visibleModal: false,modalPhone:null })
    }
    render() {
        const { current, visibleModal, loadingConfirm, successNotification, phone, userData,
            phone_is_valid, submit_disable, submitPhoneNum, modal_submit_disable, modal_phone_is_valid } = this.state;
        const phoneNumberStep = <div className="phoneStep">
            <Row>
                <Col span={8}></Col>
                <Col span={8}>
                    <PhoneNumber externalChangeFunction={this.phoneNumberChange} externalSubmitPhoneNumber={true} externalOnKey={this.onKeyDown} />
                    {phone_is_valid && <><span className='validNumber'>Phone number is invalid</span><br /></>}
                    <Button className={submit_disable ? 'disabled-ant-btn' : 'phoneBtn'} type="primary" loading={submitPhoneNum} htmlType="submit" block disabled={submit_disable}
                        onClick={this.submitPhoneNumber}>Submit</Button>
                </Col>
                <Col span={8}></Col>
            </Row>


        </div>;
        const userInformation = <>
            <Descriptions
                title={`Number#${phone}`}
                bordered
                layout="vertical"
                size={'small'}
                extra={<Button className='cancelBtn' type="default" shape='round' onClick={this.handleOpenChangPhoneModal}>Change</Button>}
            >
                {userData.map((data, index) => {
                    return <Descriptions.Item key={index} label={data.name}>{data.value}</Descriptions.Item>
                })}
            </Descriptions>
        </>
        return (
            <div>
                <Steps current={current}>
                    <Step title="User Number" onStepClick={() => this.onStepperClick(0)} />
                    <Step title="Change Phone Number" />
                </Steps>
                {current === 0 && <div className='phoneNumber' >{phoneNumberStep}</div>}
                {current === 1 && <div className='phoneNumber' >{userInformation}</div>}
                <div className='modal'>
                    <ModalCompnent loadingConfirm={loadingConfirm} visibleModal={visibleModal} okText={'Edit'} cancelText={'Cancel'}
                        title={'Change Phone Number'} handleAcceptModal={this.submitPhoneNumber} btnDisable={modal_submit_disable} handleCloseModal={this.handleCloseNumbeModal}
                        content={<>
                            <PhoneNumber externalChangeFunction={this.phoneNumberChange} externalSubmitPhoneNumber={true} externalOnKey={this.onKeyDown} />
                            {modal_phone_is_valid && <><span style={{ color: '#ff000e' }}>Phone number is invalid</span><br /></>}
                        </>} />
                </div>

                {successNotification === true && <NotificationAlert message={'Phone number changed successfully'} placement={'topLeft'} duration={4} icon={<CheckCircleTwoTone twoToneColor="#52c41a" />} />}
                {successNotification === false && <NotificationAlert message={'Change phone number has been failed'} placement={'topLeft'} duration={4} icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />} />}

            </div>
        )
    }
}

export default ChangePhoneNumber
