import { Form, Row, Col, Select, Button, FormInstance, message, Result } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request } from '../stores/actions';
import OutPutResult from '../components/result';
import Spinner from '../components/spinner';
import { errorMessage } from '../utils/commonStringsConsts';
import helper from '../utils/helper';
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import DataTable from '../components/dataTable';
import Helper from '../utils/helper';
import XLSX from "xlsx";
import SheetTemplate from '../components/bulkActionSheetTemplate';
import { bulkUpdateStatusTemplate } from '../utils/bulkSheetsTemplates';
import BulkErrorsModal from '../components/bulkErrorsModal';
import TransactionErrorModal from '../components/transactionErrorModal';

const { Option } = Select;

interface Props {
    request?: any,
    removeData: boolean
}

class BulkUpdateUserStatus extends Component<Props> {
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        loading: false,
        statuses: [],
        reasons: [],
        loadData: false,
        errorMode: false,
        dataSpin: false,
        tableData: [],
        tableColumns: [],
        tableLoading: false,
        pagination: {},
        uploadingError: false,
        visibleModal: false,
        uploadingErrorMessage: "",
        validationErrors:[],
        resErrorContent: undefined,
        visibleMessageModal:false
    }
    formRef = React.createRef<FormInstance>();

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
            this.clearData()
        }
    }

    getDropDawns = async (apiLink: string, dynamicState: string) => {
        this.setState({ loadData: true })
        try {
            const drops = await this.props.request({ url: apiLink, method: "GET" });
            const res = drops.data;
            if (res.status === 200) {
                this.setState({ [dynamicState]: res.reasons, loadData: false })
                this.formRef.current!.setFieldsValue({ reasons: null, reason: null, module: null });
            } else {
                this.setState({ loadData: false, errorMode: true })
            }
        } catch (error) {
            console.log("🚀 ~ file: bulkUpdateUserStatus.tsx ~ line 54 ~ BulkUpdateUserStatus ~ getDropDawns= ~ error", error)
            this.setState({ loadData: false, errorMode: true })
        }
    }

    onOtherSelected = (value: any, detail: any) => {
        if (detail.name.name.includes('Other')) {
            this.setState({ otherComment: true })
            return
        }
        this.setState({ otherComment: false })
    }

    gettingReasons = (value: string, status: any) => {
        console.log("🚀 ~ file: userStatus.tsx ~ line 79 ~ UserStatus ~ reason", status)
        this.setState({ reasons: status.status.reasons });
        this.formRef.current!.setFieldsValue({ reasons: null });
    }

    changeUserStatus = async (values: { reasons: number }) => {
        this.setState({ dataSpin: true })

        const data = {
            sheetData: this.state.tableData,
            statusID: values.reasons
        }

        try {
            const res = await this.props.request({ url: '/users/updateBulkStatus', method: "Patch", data });
            if (res.data.status === 200) { //show success message
                console.log(res);
                message.success(res.data.message)
                this.clearData()
            }
            else if (res.data.status === 422) { //open errors list popup
                this.setState({
                    resErrorContent: res.data.message,
                    validationErrors: res.data.reason,
                    visibleModal: true
                })
            }
            else if (res.data.status === 400 && res.data.reason) { //open transaction error popup
                this.setState({
                    resErrorContent: {
                        message: res.data.message,
                        errorData: res.data.reason
                    },
                    visibleMessageModal: true
                })
            }
            else { // show any other status errors messages (400 without reason OR 500)
                message.error(res.data.message)
            }
            this.setState({ dataSpin: false })
        } catch (error) { // show any response status errors messages except 200(OK)
            this.setState({ dataSpin: false })
            console.log(error);
            message.error(errorMessage);
        }

    }

    handleFile(file: any /*:File*/) {
        this.setState({ tableLoading: true, uploadingError: false })
        console.log('file = ', file);
        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            let fileReader = new FileReader(), rows: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {
                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rows = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                const validation: any = helper.validateUploadSheetColumns(rows, bulkUpdateStatusTemplate)
                console.log("🚀 ~ file: bulkUpdateUserStatus.tsx ~ line 135 ~ BulkUpdateUserStatus ~ handleFile ~ validation", validation)
                if (!validation.success) {
                    this.setState({
                        tableLoading: false, uploadingError: true,
                        uploadingErrorMessage: validation.message
                    })
                    return
                }
                const tableColumns = helper.generateTableColumns(validation.data)
                this.setState({ tableData: validation.data, tableLoading: false, tableColumns }, () => {
                    this.getDropDawns(`users/usersReasons`, 'statuses');
                })

            }
        }
    }

    handleUploadFile = (e: { target: { files: any, value: any }; }) => {
        this.setState({ tableData: [], uploadingError: false });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        // to clear input value to be able to add the same file again
        e.target.value = null;
    }

    handleTablePagination = (e: any) => {
        console.log("🚀 ~ file: bulkUpdateUserStatus.tsx ~ line 135 ~ BulkUpdateUserStatus ~ e", e)
        this.setState({
            pagination: e, tableLoading: true
        })
        setTimeout(() => this.setState({ tableLoading: false }), 1500)
    }

    openSelectFile = () => {
        this.fileInputRef.current!.click();
    }

    handleCloseModal = () => {
        this.setState({ visibleModal: false, visibleMessageModal:false })
    }

    clearData = () => {
        this.setState({ tableData: [] })
    }

    render() {
        const { reasons, statuses, loadData, errorMode, dataSpin, tableColumns, pagination, tableData, tableLoading, uploadingError, visibleModal, uploadingErrorMessage, validationErrors, resErrorContent, visibleMessageModal } = this.state;
        return (
            <div className='statusBulkUpdate'>
                <input
                    hidden
                    type="file"
                    className="form-control"
                    id="file"
                    ref={this.fileInputRef}
                    accept=".csv,.xlsx"
                    onChange={this.handleUploadFile}
                />
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Col span={4}><Button icon={<UploadOutlined />} onClick={this.openSelectFile} >Click to Upload file</Button></Col>
                    <Col span={20}>{tableData.length > 0 ? <><b>Uploaded Rows Count: </b> {tableData.length} {tableData.length > 1 ? "Users" : "User"}</> : "Please Upload your file as shown in the following table, Only the following data will be sent"}</Col>
                </Row>
                {tableData.length === 0 && <SheetTemplate columns={bulkUpdateStatusTemplate} />}
                {(Array.isArray(tableData) && tableData.length > 0 && !uploadingError) &&
                    <>
                        {loadData || tableLoading ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                            <div className='tableSection'>
                                <Form className='statusForm' name="nest-messages" ref={this.formRef} onFinish={this.changeUserStatus}>
                                    <Row>

                                        <Col className='module' span='8' style={{ padding: '0 15px 0 0' }}>
                                            <label>statuses </label>

                                            <Form.Item
                                                name="module"
                                                rules={[{ required: true, message: 'Required' }]}

                                            >
                                                <Select
                                                    onChange={this.gettingReasons}
                                                    placeholder="Select a status">
                                                    {statuses.map((status: any, key: number) => {
                                                        return <Option value={status.id} status={status} key={key}>{status.userType}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>

                                        </Col>
                                        <Col className='detail' span='8' style={{ padding: '0 15px 0 0' }}>
                                            <label>reasons </label>
                                            <Form.Item
                                                name="reasons"
                                                rules={[{ required: true, message: 'Required' }]}

                                            >
                                                <Select
                                                    onChange={this.onOtherSelected}
                                                    disabled={reasons.length === 0}
                                                    placeholder="Select a reason">
                                                    {reasons.map((detail: any, key: number) => {
                                                        return <Option value={detail.id} name={detail} key={key}>{detail.name}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>

                                        </Col>


                                        <Col className='colOfAssign' span='4'>
                                            <Form.Item>
                                                <Button style={{ margin: '22px 0 0 0 ' }} className='changeUserStatusBtn' shape="round" type="primary" block htmlType="submit" loading={dataSpin} >
                                                    Change
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <DataTable columns={tableColumns}
                                    pagination={pagination}
                                    rows={tableData}
                                    handleTableChange={this.handleTablePagination}
                                    loading={tableLoading}
                                    scrolling={{ x: 1000 }} />
                            </div>}</>
                }
                {
                    uploadingError &&
                    <Result
                        icon={<FrownOutlined />}
                        title={uploadingErrorMessage}
                    />
                }
                <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={this.handleCloseModal} errorMessage={resErrorContent} />
                <TransactionErrorModal resErrorContent={resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={this.handleCloseModal} />

            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, { request })(BulkUpdateUserStatus) 
