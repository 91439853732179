import { Form, Row, Col, Select, Button, FormInstance, message } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApi, patchApi, stepperMoving } from '../../stores/actions';
import AllUserInfo from '../../components/allUserInfo';
import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
import { errorMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';
const { Option } = Select;

interface Props {
    getApi?: any,
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        selectedCompany: number
    }
    patchApi?: any,
    stepperMoving?: any,
}
class UserStatus extends Component<Props> {
    state = {
        loading: false,
        statuses: [],
        reasons: [],
        loadData: false,
        errorMode: false,
        dataSpin:false,
    }
    formRef = React.createRef<FormInstance>();
    async componentDidMount() {
        if (!this.props.info?.selectedCompany) {
            this.setState({errorMode: true})
            return
        }
        await this.getDropDawns(`users/usersReasons`, 'statuses');
    }
    getDropDawns = async (apiLink: string, dynamicState: string) => {
        this.setState({ loadData: true })
        const drops = await this.props.getApi(apiLink);
        const res = await drops.payload;
        try {
            if (res.status === 200) {
                this.setState({ [dynamicState]: res.reasons, loadData: false })
                this.formRef.current!.setFieldsValue({ reasons: null, reason: null, module: null });

            } else {
                this.setState({ loadData: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ loadData: false, errorMode: true })
        }
    }

    onOtherSelected = (value: any, detail: any) => {
        console.log(detail.name.name);

        if (detail.name.name.includes('Other')) {
            this.setState({ otherComment: true })
            return
        }
        this.setState({ otherComment: false })

    }
    gettingReasons = (value: string, status: any) => {
        console.log("🚀 ~ file: userStatus.tsx ~ line 79 ~ UserStatus ~ reason", status)
        this.setState({ reasons: status.status.reasons });
        this.formRef.current!.setFieldsValue({ reasons: null });
    }

    changeUserStatus = async (values: { reasons: number }) => {
        this.setState({dataSpin:true})
        const data = {
            phones: [this.props.info?.phone],
            statusID: values.reasons,
            companyID: this.props.info?.selectedCompany
        }
        const endingCall = await this.props.patchApi('/users/updateStatus', data);

        try {
            const res = endingCall.payload;
            if (res.status === 200) {
                console.log(res);
                message.success(res.message)
                this.props.stepperMoving(0)
            }
            else {
                console.log(res.message);
                message.error(res.message)
            }
            this.setState({ dataSpin: false })
        } catch (error) {
            this.setState({ dataSpin: false })
            console.log(error);
            message.error('Something went wrong, please try again');

        }

    }
    render() {
        const { reasons, statuses, loadData, errorMode,dataSpin } = this.state;
        const { info } = this.props;
        return (
            <>
                {loadData ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                    btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> : <>
                    <AllUserInfo info={info} />
                    <Form name="nest-messages" ref={this.formRef} onFinish={this.changeUserStatus}>
                        <Row>

                            <Col className='module' span='8' style={{ padding: '0 15px 0 0' }}>
                                <label>statuses </label>

                                <Form.Item
                                    name="module"
                                    rules={[{ required: true, message: 'Required' }]}

                                >
                                    <Select
                                        onChange={this.gettingReasons}
                                        placeholder="Select a status">
                                        {statuses.map((status: any, key: number) => {
                                            return <Option value={status.id} status={status} key={key}>{status.userType}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col className='detail' span='8' style={{ padding: '0 15px 0 0' }}>
                                <label>reasons </label>
                                <Form.Item
                                    name="reasons"
                                    rules={[{ required: true, message: 'Required' }]}

                                >
                                    <Select
                                        onChange={this.onOtherSelected}
                                        disabled={reasons.length === 0}
                                        placeholder="Select a reason">
                                        {reasons.map((detail: any, key: number) => {
                                            return <Option value={detail.id} name={detail} key={key}>{detail.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                            </Col>


                            <Col className='colOfAssign' span='4'>

                                <Form.Item>
                                    <Button style={{ margin: '22px 0 0 0 ' }} className='changeUserStatusBtn' shape="round" type="primary" block htmlType="submit" loading={dataSpin} >
                                        Change
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                </>}


            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, patchApi, stepperMoving })(UserStatus) 
