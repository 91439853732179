import { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import Deductions from './deductions';

interface Props{
    generateInvoice: boolean
}

class ViewDeductions extends Component <Props> {
    

    render() {
        const componentSteps = [
            {
                title: 'Select the Companies ',
                content: <GetInfo isAllCompanies={true} isMultipleSelect apiLink=''/>,
                id: 0,
            }, {
                title: 'Deductions',
                content: <Deductions generateInvoice={this.props.generateInvoice}/>,
                id: 1
            },
        ]
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default ViewDeductions