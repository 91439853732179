
import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import CreateLostForm from './createLostForm';
import { createExceptionLostApi, getUserByPhoneApi } from '../../utils/apisConsts';


class CreateExceptLost extends Component {
    state = {
        componentSteps: [
            {
                title: 'Get User',
                content: <GetInfo apiLink={getUserByPhoneApi} />,
                id: 0,
            }, {
                title: 'Create Exception Lost',
                content: <CreateLostForm lostApi={createExceptionLostApi} isException={true} mostRecent={false} />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default CreateExceptLost
