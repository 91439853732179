import { COLLAPSED_BAR,ACTIVE_NAV } from './types';

export const toggleSideBar = (value: boolean) => {
    return (dispatch: any) => dispatch({
        type: COLLAPSED_BAR,
        payload: value,
    })
}
export const activeNavigation = (value: string) => {
    return (dispatch: any) => dispatch({
        type: ACTIVE_NAV,
        payload: value,
    })
}