import React, { Component } from 'react'
import { Form, Input, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { isAuthintecated, changePhoneNumberStatus, changePinCodeStatus, changeForgotPasswordStatus, postApi, getAccessRoutes } from "../stores/actions";
import Cookies from 'js-cookie';
import { withRouter, RouteComponentProps } from "react-router-dom";
import NotificationAlert from './notificationAlert';
interface Props extends RouteComponentProps<any> {
    isAuthintecated: any,
    changePhoneNumberStatus: any,
    changePinCodeStatus: any,
    changeForgotPasswordStatus: any,
    postApi: any,
    phoneNumber: string,
    pinCode: string,
    getAccessRoutes: any
}

class SubmittingPassword extends Component<Props> {
    state = {
        btnLoader: false,
        successNotification: true,
        errorMessage: null,
    }
    onFinish = async (values: any) => {
        this.responseHandling(true, true)
        const info = {
            phone: this.props.phoneNumber,
            code: this.props.pinCode,
            // password: values.password,
            // phone: '01554537572',
            // code: '61172',
            password: values.password,
        };
        const submitPassword = await this.props.postApi(`auth/login`, info);
        try {
            if (submitPassword.error) {
                this.responseHandling(false, false)
                return 0;
            }
            if (submitPassword.payload.status === 200) {
                this.responseHandling(true, false);
                console.log(submitPassword.payload.accessRoutes);
                let date = new Date();
                date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                // Cookies.set('token', submitPassword.payload.token, { expires: 1 });
                Cookies.set('token', submitPassword.payload.token, { expires: date });
                setTimeout(() => {
                    this.props.isAuthintecated(true);
                    this.props.getAccessRoutes(submitPassword.payload.accessRoutes)
                    this.props.history.push(`/${submitPassword.payload.accessRoutes[0].path}`)
                    this.props.changePhoneNumberStatus(false);
                    this.props.changePinCodeStatus(false);
                }, 100);
            } else {
                this.responseHandling(true, false)
                this.setState({ errorMessage: submitPassword.payload.message })
                console.log(this.state.errorMessage);

            }
            console.log(submitPassword.payload);


        } catch (error) {
            this.responseHandling(false, false)
        }


    };
    responseHandling = (successNotification: any, btnLoader: boolean) => {
        this.setState({ successNotification: successNotification, btnLoader: btnLoader })
    }
    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    passForgot = () => {
        this.props.changeForgotPasswordStatus(true)
    }
    render() {
        const { btnLoader, successNotification, errorMessage } = this.state;

        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}                    >
                        <Input.Password placeholder="Password" onChange={() => this.setState({ errorMessage: null })} />
                    </Form.Item>
                    {errorMessage && <><span style={{ float: 'left', color: '#ff4d4f' }}>{errorMessage}</span><br /></>}

                    <Button type="primary" htmlType="submit" loading={btnLoader} block >
                        Log In
                    </Button><br />
                    <span className='passForgot' onClick={this.passForgot}>Forgotten password?</span>

                </Form>
                {!successNotification && <NotificationAlert message={'Something went wrong, please try again later'} placement={'topLeft'} duration={4} icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />} />}

            </div>
        )
    }
}
const mapStateToProp = (state: any) => ({
    pinCode: state.auth.pinCode,
    phoneNumber: state.auth.phoneNumber,
})
export default withRouter(connect(mapStateToProp, {
    isAuthintecated, changeForgotPasswordStatus,
    changePhoneNumberStatus, changePinCodeStatus, postApi, getAccessRoutes
})(SubmittingPassword))
