import { Row, Col, Button, message, Result, Form, Select, Input } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request, stepperMoving } from '../../stores/actions';
import Spinner from '../../components/spinner';
import helper from '../../utils/helper';
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import DataTable from '../../components/dataTable';
import Helper from '../../utils/helper';
import XLSX from "xlsx";
import SheetTemplate from '../../components/bulkActionSheetTemplate';
import BulkErrorsModal from '../../components/bulkErrorsModal';
// import { errorMessage } from '../../utils/commonStringsConsts';
import { bulkRecalculateRequestsTemplate } from '../../utils/bulkSheetsTemplates';
import OutPutResult from '../../components/result';
import { getRecalculatedRequestApi } from '../../utils/apisConsts';
import { errorMessage } from '../../utils/commonStringsConsts';
const { Option } = Select

interface Props {
    request?: any,
    stepperMoving?: any,
    removeData: boolean,
    setRecalculatedRequests: any,
    setRecalculationData: any
}


class BulkUploadRequests extends Component<Props> {
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        dataSpin: false,
        tableData: [],
        tableColumns: [],
        tableLoading: false,
        pagination: {},
        uploadingError: false,
        visibleModal: false,
        uploadingErrorMessage: "",
        validationErrors: [],
        resErrorContent: "",
        loadingCompanies: false,
        companies: [],
        errorMode: false
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
            this.clearData()
        }
    }

    componentDidMount(): void {
        this.getCompanies()
    }

    getCompanies = async () => {
        try {
            this.setState({ loadingCompanies: true })
            const companiesRes: any = await helper.getAllCompanies()
            if (companiesRes.status === 200) {
                this.setState({ companies: companiesRes.data, loadingCompanies: false })
            }
            else {
                this.setState({ errorMode: true })
            }
        }
        catch (error) {
            console.log("🚀 ~ file: bulkUploadRequests.tsx:61 ~ BulkUploadRequests ~ getCompanies= ~ error", error)
            this.setState({ errorMode: true })
        }
    }

    submitRequests = async (values: any) => {
        if (!Array.isArray(this.state.tableData) || !this.state.tableData.length) {
            message.error("No requests to be calculated", 4)
            return
        }
        this.setState({ dataSpin: true })
        try {

            const advanceIDs = Array.isArray(this.state.tableData) ? this.state.tableData.map((row: { requestID: string | number }) => row.requestID) : []
            const params = {
                companyID: values.companyID,
                advanceIDs: advanceIDs.join(","),
                getAllPlanOptions: false,
                lessData: true
            }
            const res = await this.props.request({ url: getRecalculatedRequestApi, method: "GET", params });
            if (res.data.status === 200) { //show success message
                this.props.setRecalculatedRequests(res.data.recalculateRequestComparisons)
                this.props.setRecalculationData({
                    companyID: values.companyID,
                    advanceIDs,
                    comment: values.comment
                })
                this.props.stepperMoving(1)
            }
            else if (res.data.status === 422) { //open errors list popup
                this.setState({
                    resErrorContent: res.data.data.message,
                    validationErrors: res.data.data.failedAdvances,
                    visibleModal: true
                })
            }
            else { // show any other status errors messages (400 without reason OR 500)
                message.error(res.data.message, 4)
            }
            this.setState({ dataSpin: false })
        } catch (error) { // show any response status errors messages except 200(OK)
            this.setState({ dataSpin: false })
            message.error(errorMessage);
        }
    }

    handleFile(file: any /*:File*/) {
        this.setState({ tableLoading: true, uploadingError: false })
        console.log('file = ', file);
        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            let fileReader = new FileReader(), rows: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {
                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rows = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                const validation: any = helper.validateUploadSheetColumns(rows, bulkRecalculateRequestsTemplate)
                if (!validation.success) {
                    this.setState({
                        tableLoading: false, uploadingError: true,
                        uploadingErrorMessage: validation.message
                    })
                    return
                }
                const tableColumns = helper.generateTableColumns(validation.data)
                this.setState({ tableColumns, tableData: validation.data, tableLoading: false })

            }
        }
    }

    handleUploadFile = (e: { target: { files: any, value: any }; }) => {
        this.setState({ tableData: [], uploadingError: false });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        // to clear input value to be able to add the same file again
        e.target.value = null;
    }

    handleTablePagination = (e: any) => {
        this.setState({
            pagination: e, tableLoading: true
        })
        setTimeout(() => this.setState({ tableLoading: false }), 1500)
    }

    openSelectFile = () => {
        this.fileInputRef.current!.click();
    }

    handleCloseModal = () => {
        this.setState({ visibleModal: false, visibleMessageModal: false })
    }

    clearData = () => {
        this.setState({ tableData: [] })
    }

    dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    render() {
        const { dataSpin, tableColumns, pagination, tableData, tableLoading, uploadingError, visibleModal, uploadingErrorMessage, validationErrors, resErrorContent,
            loadingCompanies, companies, errorMode } = this.state;
        return (errorMode ?
            <OutPutResult status={"500"} title={"Oops!"} subTitle={"Something went wrong, please refresh the page and try again"}
                btnTitle={"Refresh the page"}
                btnClick={helper.refreshPage}
            />
            : <div className='statusBulkUpdate'>
                <input
                    hidden
                    type="file"
                    className="form-control"
                    id="file"
                    ref={this.fileInputRef}
                    accept=".csv,.xlsx"
                    onChange={this.handleUploadFile}
                />
                <Form onFinish={this.submitRequests}>
                    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Col span={4}><Button icon={<UploadOutlined />} onClick={this.openSelectFile} >Click to Upload file</Button></Col>
                        <Col span={18}>{tableData.length > 0 ? <><b>Uploaded Rows Count: </b> {tableData.length} {tableData.length > 1 ? "Requests" : "Request"}</> : "Please Upload your file as shown in the following table, Only the following data will be sent"}</Col>
                        {tableData && tableData.length > 0 && <Col className='colOfAssign' span={2}>
                            <Form.Item>
                                <Button type="primary" block loading={dataSpin} htmlType="submit">
                                    Next
                                </Button>
                            </Form.Item>
                        </Col>}
                    </Row>
                    {tableData && tableData.length > 0 && <Row style={{ marginBottom: "20px" }}>
                        <Col span={8}>
                            <label htmlFor="">Companies <span>*</span></label>
                            <Form.Item
                                name="companyID"
                                rules={[{ required: true, message: 'Required' }]}

                            >
                                <Select
                                    placeholder='Please select a company'
                                    loading={loadingCompanies}
                                    showSearch
                                    filterOption={((input, option: any) => this.dropDownSearch(input, option))}
                                >
                                    {
                                        Array.isArray(companies) &&
                                        companies.map((company: { id: number, name: string }, index) =>
                                            <Option key={company.name + index} value={company.id}>{company.name}</Option>
                                        )}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={16} style={{paddingLeft:"10px"}}>
                            <label htmlFor="">Recalculation Reason <span>*</span></label>
                            <Form.Item name='comment' rules={[
                                { required: true, message: 'Reason is mandatory' }]}>
                                <Input.TextArea style={{height:"33px"}}  placeholder='Enter your reason' />
                            </Form.Item>
                        </Col>
                    </Row>}
                </Form>
                {tableData.length === 0 && <SheetTemplate columns={bulkRecalculateRequestsTemplate} />}
                {(Array.isArray(tableData) && tableData.length > 0 && !uploadingError) &&
                    <>
                        {(tableLoading && tableData.length === 0) ? <Spinner size={'large'} /> :
                            <div className='tableSection'>
                                <DataTable columns={tableColumns}
                                    pagination={pagination}
                                    rows={tableData}
                                    handleTableChange={this.handleTablePagination}
                                    loading={tableLoading}
                                    scrolling={{ x: 1000 }} />
                            </div>}</>
                }
                {
                    uploadingError &&
                    <Result
                        icon={<FrownOutlined />}
                        title={uploadingErrorMessage}
                    />
                }
                <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={this.handleCloseModal} errorMessage={resErrorContent} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, { request, stepperMoving })(BulkUploadRequests) 
