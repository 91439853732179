import { Form, Input, Button, ConfigProvider, message, FormInstance } from "antd";
import regex from '../../../utils/helper'
import { useDispatch } from 'react-redux';
import { move, request, setDriverData } from "../../../stores/actions";
import { createRef, useEffect, useState } from "react";
import { arabicErrorMessage, truckingApis } from "../../../utils/commonStringsConsts";
import { useRouteMatch } from "react-router-dom";
interface Props {
  apiLink?: string,
}
function ViewDrivers(props: Props) {
  const [spin, setSpin] = useState(false);
  const dispatch = useDispatch();
  const formRef = createRef<FormInstance>();
  const match: { [key: string]: any } = useRouteMatch();

  useEffect(() => {
    checkIfDeepLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDriverInfo = async (values: { phone: string }) => {
    try {
      setSpin(true)

      const res: any = await dispatch(request({ url: props.apiLink ? `${props.apiLink}?phone=${values.phone}` : `${truckingApis}users?phone=${values.phone}`, method: 'get' }))
      const driverInfo = await res.data;
      if (driverInfo.status === 200) {
        console.log('driverInfo.data.users = ', driverInfo.data.users);
        if (driverInfo.data.users.length === 0) {
          message.error(arabicErrorMessage)
          setSpin(false)
          return
        }
        if (Array.isArray(driverInfo.data.users)) {
          dispatch(setDriverData(driverInfo.data?.users[0]))
          dispatch(move(1));
          return
        }
        dispatch(setDriverData(driverInfo.data?.users))
        dispatch(move(1));
      } else if (driverInfo.status === 404) {
        message.error('لا يوجد سائق بهذا الرقم')
        setSpin(false)
        return 0
      } else {
        message.error('حدث خطأ ما')
        setSpin(false)
      }
    } catch (err: any) {
      if (err.response.status === 404) {
        message.error('لا يوجد سائق بهذا الرقم')
        setSpin(false)
        return 0
      }
      message.error('حدث خطأ ما')
      setSpin(false)
    }
  }

  const checkIfDeepLink = () => {
    if (match.params.id) {
      formRef.current?.setFieldsValue({
        phone: match.params.id
      })
      formRef.current?.submit()
    }
  }

  return (
    <>
      <ConfigProvider direction="rtl">
        <div className="driverInfo">

          <Form name="nest-messages" onFinish={getDriverInfo} ref={formRef}>
            <Form.Item name={'phone'} rules={[{ required: true, message: 'يجب ادخال رقم التليفون' }, { pattern: regex.regexValidation.egyptPhoneNum, message: 'هذا الرقم غير صحيح' }]}>
              <Input placeholder='رقم التليفون' />

            </Form.Item>
            {/* <span style={{ margin: '15px 0 0 0', color: '#ff4d4f' }}>{errorMessage}</span> */}
            <Form.Item>
              <Button className='driverInfoBtn' shape="round" type="primary" loading={spin} block htmlType="submit">
                إدخال
              </Button>
            </Form.Item>


          </Form>
        </div>
      </ConfigProvider>
    </>
  )
}
export default ViewDrivers;