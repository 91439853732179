import { Form, Row, Col, Input, Button, message } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { request, stepperMoving, getDataInfo, getUserBalanceData, getRequestDetails } from '../stores/actions'
import { getRequestApi, getUserBalanceApi, sendOTPApi } from '../utils/apisConsts'
import { errorMessage } from '../utils/commonStringsConsts'
import helper from '../utils/helper'
interface Props {
    isPhone?: boolean,
    apiLink: string,
    update?: boolean,

}
export default function GetBalance(props: Props) {
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false);

    const getUserOTP = async (values: any) => {
        const userData: any = await dispatch(request({ url: sendOTPApi, data: values, method: 'POST' }));
        const res = await userData.data;
        try {
            if (res.status === 200) {
                dispatch(stepperMoving(1));
                setLoad(false)
                return
            }
            message.error(res.message);
            setLoad(false)
        } catch (error) {
            message.error(errorMessage);
            setLoad(false)
        }
    }

    const getUserBalance = async (values: any) => {
        try {
            const userData: any = await dispatch(request({ url: getUserBalanceApi, params: values, method: 'GET' }));
            const res = await userData.data;
            if (res.status === 200) {
                dispatch(getDataInfo(values));
                dispatch(getUserBalanceData(res))
                !props.update && getUserOTP(values);
                dispatch(stepperMoving(1))

                return true
            }
            message.error(res.message);
            setLoad(false)
        } catch (error) {
            message.error(errorMessage);
            setLoad(false)
        }

    }

    const getRequestInfo = async (values: any) => {
        console.log("🚀 ~ file: getBalance.tsx:54 ~ getRequestInfo ~ values", values)
        setLoad(true);
        try {
            const requestDetails: any = await dispatch(request({ method: 'GET', url: `${getRequestApi}${values?.advanceID}`, }));
            const res: any = await requestDetails.data;
            console.log("🚀 ~ file: getBalance.tsx:59 ~ getRequestInfo ~ requestDetails", res.requestDetails)
            if (res.status === 200) {
                if (!res.requestDetails.editableAmount && !res.requestDetails.editableInstallments) {
                    message.error(`Update is not allowed on this request`);
                    setLoad(false);
                    return
                }
                dispatch(getRequestDetails(res.requestDetails));
                let data = { phone: res.requestDetails.phone }
                await getUserBalance(data);
                return
            };
            message.error(res.message)
            setLoad(false);
        } catch (error) {
            message.error(errorMessage);
            setLoad(false);
        }
    }
    const onFinish = async (values: any) => {
        setLoad(true);
        if (props.isPhone) {
            await getUserBalance(values);

            return
        };
        getRequestInfo(values);
    }
    return (
        <div className='userInfo'>
            <Form name="nest-messages" onFinish={onFinish}>
                <Row>

                    <Col className='phoneForm' span={8}>

                        {<>
                            <Form.Item name={!props.isPhone ? 'advanceID' : 'phone'} rules={!props.isPhone ? [
                                { required: true, message: 'Request ID is mandatory' }] : [
                                { required: true, message: 'Phone is mandatory' }, {
                                    pattern: helper.regexValidation.egyptPhoneNum, message: 'Please enter Egyptian number'
                                }]} style={{ width: '100%' }}>
                                <Input placeholder={!props.isPhone ? 'Enter The Request ID' : 'Enter the phone'} />
                            </Form.Item>
                        </>}

                        {/* <span className="errorMessage">{errorMessage && errorMessage}</span> */}
                        {/* <span style={{ margin: '15px 0 0 0', color: '#ff4d4f' }}>{errorMessage}</span> */}
                        <Form.Item>
                            <Button className='userBtn' shape="round" type="primary" loading={load} block htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
