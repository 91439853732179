import { Button, Descriptions, message } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../apis/axiosRequest'
import DataTable from '../../components/dataTable'
import { stepperMoving } from '../../stores/actions'
import { deleteCreditApi } from '../../utils/apisConsts'
import { errorMessage } from '../../utils/commonStringsConsts'
import { adjustmentTableColumns } from '../../utils/tableColumns'

export default function DeleteDebitAndCreditForm() {
  const advance = useSelector((state: any) => state.actionsReducer.info)
  const [sendingData, setSendingData] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (Array.isArray(advance.installments) && advance.installments.length > 0) {
      const creditNotes = advance.installments.filter((installment:any)=> installment.creditNote)
      if (!creditNotes.length) {
        message.error("The request type is not Debit/Credit", 4)
        dispatch(stepperMoving(0))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advance])

  const deleteRequest = async () => {
    try {
      setSendingData(true)
      const deleteRes: { [key: string]: any } = await dispatch(request({
        url: deleteCreditApi, method: "DELETE", data: { advanceID: advance.requestID }
      }))
      if (deleteRes.data.status === 200) {
        message.success(deleteRes.data.message)
        dispatch(stepperMoving(0))
      } else {
        message.error(`${deleteRes.data.message}, Reason: ${deleteRes.data.reason}`)
      }
      setSendingData(false)
    }
    catch (error: any) {
      console.log("🚀 ~ file: deleteDebitAndCreditForm.tsx:43 ~ deleteRequest ~ error", error)
      message.error(errorMessage)
      setSendingData(false)
    }
  }

  return (
    <div style={{ padding: "30px 0" }}>
      <Descriptions
        title={`Request #${advance.requestID}`}
        bordered
        size={'small'}
        extra={<Button className='cancelBtn' type="default" shape='round' danger onClick={deleteRequest} loading={sendingData}>Delete Request</Button>}
      >
        <Descriptions.Item span={2} label="User ID">{advance.userID}</Descriptions.Item>
        <Descriptions.Item span={2} label="Request Type">Debit/Credit</Descriptions.Item>
        <Descriptions.Item span={2} label="Amount">{advance.amount}</Descriptions.Item>
        <Descriptions.Item span={2} label="Number Of Months">{advance.numberOfMonths}</Descriptions.Item>
        <Descriptions.Item span={2} label="Created At">{moment(advance.createdAt).format("Do MMM, YYYY")}</Descriptions.Item>
      </Descriptions>

      {Array.isArray(advance.installments) && advance.installments.length > 0 &&
        <div style={{ marginTop: "25px" }}>
          <label style={{ fontWeight: "bolder", fontSize: "16px" }}>Request Installments</label>
          <DataTable
            columns={adjustmentTableColumns}
            rows={advance.installments}
            pagination={{}}
            loading={false}
            scrolling={{}}
          />
        </div>}
    </div>
  )
}
