import React, { useEffect, useState } from 'react';
import AllCompanyInfo from '../../components/companyInfo';
import { Button, Col, Divider, Form, FormInstance, message, Radio, Row, Select, Tag } from 'antd';
import DataTable from '../../components/dataTable';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../apis/axiosRequest';
import Spinner from '../../components/spinner';
import OutPutResult from '../../components/result';
import { errorMessage } from '../../utils/commonStringsConsts';
import { companyInfo } from '../../utils/commonInterfaces'
import helper from '../../utils/helper';

const { Option } = Select;

export default function SuspendCompanyServicesForm() {
    const formRef = React.createRef<FormInstance>();
    const [servicesGroupsDetails, setServicesGroupsDetails] = useState([])
    const [suspendedServiceGroupID, setSuspendedServiceGroupID] = useState(0)
    const [loadingData, setLoadingData] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [reasons, setReasons] = useState([])
    const [loadingReasons, setLoadingReasons] = useState(false)

    const companyInfo = useSelector<{ [key: string]: any }, companyInfo>(state => state.actionsReducer.info)
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Suspended',
            dataIndex: 'id',
            key: 'id',
            render: (id: any) => <Radio value={id}></Radio>,
            width: "110px"
        },
        {
            title: 'Service Group',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Services',
            dataIndex: 'services',
            key: 'services',
            render: (services: any) => (
                <>
                    {services.map((service: any) => (
                        <Tag color="blue" style={{ margin: `2px 5px` }} key={service}>
                            {service}
                        </Tag>
                    ))}
                </>
            )
        },
    ];

    useEffect(() => {
        getCompanyServiceGroups()
        getReasons()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getReasons = async () => {
        try {
            setLoadingReasons(true)
            const res: any = await helper.getSuspensionReasons(true)
            if (res.data.status === 200) {
                setReasons(res.data.data)
            }
            else {
                setErrorMode(true)
            }
            setLoadingReasons(false)
        }
        catch (error) {
            setLoadingReasons(false)
            setErrorMode(true)
            console.log("🚀 ~ file: userSuspending.tsx:53 ~ UserSuspending ~ getReasons= ~ error:", error)
        }
    }

    const getCompanyServiceGroups = async () => {
        try {
            setLoadingData(true)
            const companyServices: { [key: string]: any } = await dispatch(request({
                url: "/services/getCompanySuspendedServices", method: "GET", params: {
                    companyID: companyInfo.id
                }
            }))
            if (companyServices.data.status === 200 || companyServices.data.status === 204) {
                const data = companyServices.data
                console.log("🚀 ~ file: suspendCompanyServicesForm.tsx ~ line 63 ~ getCompanyServiceGroups ~ data", data)
                setServicesGroupsDetails(data.servicesGroupsDetails)
                data.suspendedServiceGroupID && setSuspendedServiceGroupID(data.suspendedServiceGroupID)
            }
            else {
                setErrorMode(true)
            }
            setLoadingData(false)
        }
        catch (e: any) {
            setLoadingData(false)
            setErrorMode(true)
            console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 28 ~ getGlobalCaps ~ e", e)
        }
    }

    const updateServicesStatus = async (values: { serviceGroupID: number, reasonID: number }) => {
        console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 70 ~ updateCaps ~ values", values)
        try {
            setUpdating(true)
            const dataObj = {
                companyID: companyInfo.id,
                serviceGroupID: values.serviceGroupID,
                reasonID: values.reasonID
            }
            const data: { [key: string]: any } = await dispatch(request({
                url: "services/updateCompanySuspension", method: "PATCH", data: dataObj
            }))
            if (data.data.status === 200) {
                message.success(data.data.message, 4)
            }
            else {
                message.error(data.data.message, 4)
            }
            setUpdating(false)
        }
        catch (e: any) {
            setUpdating(false)
            message.error(errorMessage, 4)
        }
    }

    return (
        loadingData ? <Spinner size={"large"} />
            : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage} />
                : <>
                    <Divider orientation="left" className='formTitle'>Services Groups</Divider>
                    <Form ref={formRef} name="nest-messages" onFinish={updateServicesStatus} initialValues={{ serviceGroupID: suspendedServiceGroupID }}>
                        <Form.Item name={"serviceGroupID"}>
                            <Radio.Group>
                                <DataTable
                                    columns={columns}
                                    rows={servicesGroupsDetails}
                                    pagination={false}
                                    loading={loadingData}
                                    scrolling={{ x: 100, y: 300 }}
                                />
                            </Radio.Group>
                        </Form.Item>
                        <Row>
                            <Col span='12' style={{ padding: '0 15px 0 0' }}>
                                <label>Suspension reasons <span>*</span></label>

                                <Form.Item
                                    name="reasonID"
                                    rules={[{ required: true, message: 'Required' }]}

                                >
                                    <Select
                                        loading={loadingReasons}
                                        placeholder="Select a reason"
                                    >
                                        {Array.isArray(reasons) && reasons.map((reason: any, key: number) => {
                                            return <Option value={reason.id} key={key}>{reason.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col span='12' style={{ padding: '0 15px 0 0' }}>
                                <Form.Item>
                                    <Button className='createCompanyGroupBtn' shape="round" type="primary" htmlType="submit" loading={updating} style={{ margin: '18px 0', padding: "4px 30px", float: "right" }}>
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <span className='formTitle'>Overview Dashboard: </span><span className='action' onClick={() => helper.setRedirect("https://dashboard.nowpay.cloud/dashboard/276")}>https://dashboard.nowpay.cloud/dashboard/276</span>
                    </Form>
                    <Divider orientation="left" className='formTitle'>Company Information</Divider>
                    <AllCompanyInfo />

                </>)
}