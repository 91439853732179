import { Button, Col, ConfigProvider, Form, Input, message, Popconfirm, Row, Select, Table } from "antd"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { request } from "../../../apis/axiosRequest"
import OutPutResult from "../../../components/result"
import Spinner from "../../../components/spinner"
import { createTransactionApi, getAllDrivers } from "../../../utils/apisConsts"
import { arabicErrorMessage } from "../../../utils/commonStringsConsts"
import helper from "../../../utils/helper"
const { Option } = Select;

interface Props {
  ops?: boolean,
  columns: [],
  searchable?: boolean,
  role: string,
  reGetSupervisors?: any,
  inlineAmount?: any,
  inlineBank?: any,
  amountRef?: any,
  bankRef?: any,
}
interface RowDataType {
  name?: string;
  uuid?: string,
  id?: number,
}
export default function DriversList(props: Props) {
  const [drivers, setDrivers] = useState([])
  const [errorView, setErrorView] = useState(false)
  const [loading, setLoading] = useState(false);
  const [paginating, setPaginating] = useState({
    pageSize: 10,
    total: 0,
    current: 1,
    formValues: null
  })
  const [clearSearchVisible, setClearSearchVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const dispatch = useDispatch()
  const [disableCompany, setDisableCompany] = useState(false)

  useEffect(() => {
    getDrivers(paginating.formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginating.current, paginating.pageSize, paginating.formValues])
  useEffect(() => {
    props.searchable && storeCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const storeCompanies = async () => {
    const companiesData: any = await helper.getTruckingCompanies();
    if (companiesData.status === 200) {
      setCompanies(companiesData.data.data.companies)
    } else {
      setDisableCompany(true)
    }
    console.log(companiesData.data.data.companies);

  }
  const changePagination = async (values: any) => {
    setPaginating({
      ...paginating,
      current: values?.current,
      pageSize: values.pageSize
    })
  }

  const getDrivers = async (values?: any) => {

    setLoading(true)
    try {
      const res: any = await dispatch(request({
        url: getAllDrivers, method: 'get', params: {
          page: paginating.current,
          limit: paginating.pageSize,
          role: props.role,
          ...(values && values.name && { name: values.name }),
          ...(values && values.phone && { phone: values.phone }),
          ...(values && values.company && { companyName: values.company }),
          ...(values && values.staffID && { staffID: values.staffID }),
        }
      }))
      if (res.data.status === 200) {
        // isFirstRender.current = false;
        const data = Array.isArray(res.data.data.users) && res.data.data.users.map((i: any) => {
          return {
            ...i,
            virtualBalance: `${helper.amountFormatter(i?.balance?.userBalanceWithoutFees)} جنيه مصري`,
            virtualDeposits: `${helper.amountFormatter(i?.balance?.userDepositsAmount)} جنيه مصري`,
          }
        })
        setDrivers(res.data.data.users ? data : [])
        setPaginating({
          ...paginating,
          total: res.data.data.totalUsers
        })
      } else {
        setErrorView(true)
      }
      setLoading(false)
    } catch (err: any) {
      setErrorView(true)
      setLoading(false)
    }
  }
  useEffect(() => {
    props.reGetSupervisors && props.reGetSupervisors(() => {
      getDrivers(paginating.formValues);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSearch = (values: any, clearSearch?: boolean) => {
    console.log(values);

    if (values && !values.name && !values.phone && !values.company && !values.staffID) {
      message.error("لا يمكن البحث بدون ادخال البيانات")
      return
    }
    else if (values) {
      setPaginating({
        ...paginating,
        current: 1,
        formValues: values
      })
      setClearSearchVisible(true)
    }
    if (clearSearch) {
      setPaginating({
        ...paginating,
        current: 1,
        formValues: null
      })
      setClearSearchVisible(false)
      let form: any = document.getElementById("filtersForm");
      form.reset();
    }
  }


  const confirmTransaction = async (row: RowDataType) => {
    try {
      if (row.id === props.inlineAmount.userID && row.id === props.inlineBank.userID) {
        let data = {
          services: [{ id: 12, amount: props.inlineAmount.amount }],
          userBankID: props.inlineBank.cardNumber,
          uuid: row?.uuid,
          isBulk: true,
        }
        if (data.services[0].amount < 30) {
          message.error('المصروف يجب ان لا يكون اقل من 30 جنيه');
          return
        }
        const apiData: any = await helper.callApiRequest(createTransactionApi, 'POST', '', data);
        const resp = apiData.data;
        if (resp.status === 200) {
          console.log("🚀 ~ file: driversList.tsx:156 ~ confirmTransaction ~ data:", data);
          message.success(`تم تحويل المصروف بنجاح للسائق ${row.name}`)
          getDrivers();
         
          if (props.inlineAmount.userID) {
            Array.isArray(props.amountRef.current) && props.amountRef.current.map((i: any) => {
             return i!.resetFields();
            })
          }
          if (props.inlineAmount.userID) {
            Array.isArray(props.bankRef.current) && props.bankRef.current.map((i: any) => {
             return i!.resetFields();
            })
          }
          props.inlineAmount.userID = null;
          return
        }
        message.error(resp.message);
      }
      message.error('بجب ادخال المصروف والبنك لنفس السائق');

    } catch (error: any) {
      if (error.data.status === 433) {
        message.error('لا يوجد رصيد كافي');
        return
      }
      message.error(arabicErrorMessage);
    }

  }

  const addTransferBtn = {
    title: 'تحويل المصروف',
    dataIndex: '',
    render: (row: RowDataType) => <>
      <Popconfirm
        title={`هل انت متأكد لتحويل مصروف الي ${row.name}`}
        onConfirm={() => confirmTransaction(row)}
        okText='نعم متأكد'
        cancelText='لا'

      >
        <Button style={{ background: '#fff', color: '#7CC58F', borderColor: '#7CC58F', fontWeight: 500 }}>تحويل</Button>
      </Popconfirm>
    </>
  }
  let allColumns = props.ops === false ? [...props.columns, addTransferBtn] : props.columns;


  return (

    <ConfigProvider direction="rtl">
      <div className="driversList">
        {props.searchable !== false && <Form
          name="filtersForm"
          onFinish={handleSearch}
        >
          <Row>
            <Col xs={24} md={6} lg={6}>
              <label>اسم السائق</label>
              <Form.Item
                name="name"
              >
                <Input placeholder="ادخل الاسم" />
              </Form.Item>
            </Col>
            <Col xs={24} md={6} lg={6}>
              <label>رقم الهاتف </label>
              <Form.Item
                name="phone"
                rules={[{ pattern: helper.regexValidation.egyptPhoneNum, message: "رقم الهاتف غير صحيح" }]}
              >
                <Input placeholder="ادخل رقم الهاتف" />
              </Form.Item>
            </Col>
            {!props.ops && <Col xs={24} md={6} lg={6}>
              <label>كود السائق </label>
              <Form.Item
                name="staffID"
              >
                <Input placeholder="ادخل الكود" />
              </Form.Item>
            </Col>}
            {props.ops && <Col xs={24} md={6} lg={6}>
              <label className='label' htmlFor="">الشركة </label>
              <Form.Item name="company">
                <Select style={{ width: '100%' }} disabled={disableCompany} placeholder='اختار الشركة '>
                  {Array.isArray(companies) && companies.map((company: any, key: number) => {
                    return <Option value={company.name} key={key} >{company.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>}
            <Col xs={24} md={6} lg={6} className="btnCol">
              <Form.Item>
                <Button shape="round" type="primary" block htmlType="submit" disabled={loading}>
                  بحث
                </Button>
              </Form.Item>
            </Col>
            {clearSearchVisible && <Col xs={24} md={4} lg={4} className="btnCol">
              <Form.Item>
                <Button shape="round" type="ghost" block onClick={() => handleSearch(null, true)} style={{ backgroundColor: "unset", color: "red", borderColor: "red" }}>
                  مسح البحث
                </Button>
              </Form.Item>
            </Col>}
          </Row>
        </Form>}

        {loading && !drivers.length ? <Spinner size="large" /> : errorView ? <OutPutResult status={'500'} title={arabicErrorMessage} subTitle={' '}
          btnTitle={'إعادة تحميل الصفحه'} btnClick={helper.refreshPage} /> :
          <div className='driversList'>
            <Table
              pagination={paginating}
              onChange={changePagination}
              columns={allColumns}
              dataSource={drivers}
              loading={loading}
              scroll={{ x: 'max-content' }}
            />
          </div>}
      </div>
    </ConfigProvider>
  )
}