import { message, Descriptions } from 'antd';
import moment from 'moment';
import PaymentPlan from '../../assets/images/Group@2x.png'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';


export default function FeesView() {
    const [installmentSelected, setInstallmentSelected] = useState(1);
    const [monthlyPayment, setMonthlyPayment] = useState([])
    const [totalDue, setTotalDue] = useState(0)
    const [totalCardFees, setTotalCardFees] = useState(0);
    const [discount, setDiscount] = useState(0);
    const dataOfPlans = useSelector((state: any) => state.actionsReducer.viewPaymentPlansArr);
    console.log("🚀 ~ file: viewFees.tsx:11 ~ FeesView ~ dataOfPlans:", dataOfPlans)
    useEffect(() => {
        handlePlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handlePlans = () => {
        if (dataOfPlans.plansOptions.length === 0) {
            message.error(`You don't have plans`, 5)
            return
        }

        let plan = dataOfPlans.plansOptions;
        for (let onePlan = 0; onePlan < plan.length; onePlan++) {
            if (plan[onePlan].isValid) {
                setInstallmentSelected(plan[onePlan].noOfPayments);
                setMonthlyPayment(plan[onePlan].installments);
                setTotalDue(plan[onePlan].summary.total);
                setDiscount(plan[onePlan].summary.discount);
                setTotalCardFees(plan[onePlan].summary.totalProcessingFees)
                break
            } else {
                setInstallmentSelected(plan[onePlan].noOfPayments);
            }
        }
    }

    const plansSelected = (plan: any) => {
        setMonthlyPayment(plan.installments);
        setInstallmentSelected(plan.noOfPayments)
        setTotalCardFees(plan.summary.totalProcessingFees);
        setTotalDue(plan.summary.total);
        setDiscount(plan.summary.discount)

    }

    const { requestedAmount, plansOptions } = dataOfPlans;

    return (
        <>
            <div className="viewFees">
                <div className="paymentPlan">
                    <img className='salaryAdvance' width='200' height='100' src={PaymentPlan} alt="" /><br />
                    <div className='caption'>
                        <span className='numberShouldPay'>{requestedAmount} EGP</span><br />
                    </div>
                    <div className='plansContainer'>
                        {'validPlan' ? <p className='payBack'>Pay back over {installmentSelected} {installmentSelected > 1 ? 'months' : 'month'}</p> :
                            <p className='payBack'>Pay back over {installmentSelected} {installmentSelected > 1 ? 'months' : 'month'} not avilable</p>}
                        {plansOptions.map((plan: any, i: number) => {
                            return <span key={i} className={installmentSelected === plan.noOfPayments ? 'selectedPlan' : plan.isValid ? 'plans' : 'unValidPlan'}
                                onClick={() => plansSelected(plan)}  >{plan.noOfPayments}</span>
                        })}
                    </div>

                    {
                        <>
                            <Descriptions bordered>
                                <Descriptions.Item label="Amount">{requestedAmount}</Descriptions.Item>
                                <Descriptions.Item label="Processing Fees">{totalCardFees}</Descriptions.Item>
                                <Descriptions.Item label="Discount">{discount}</Descriptions.Item>
                                <Descriptions.Item span={3} label="Total">{totalDue}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions bordered title="Monthly Payments" style={{ marginTop: "20px" }}>
                                {
                                    monthlyPayment.map((month: { month: number, amount: number, serviceFees: number, processingFees: number }) => {
                                        const date = new Date()
                                        date.setMonth(month.month - 1)
                                        return <Descriptions.Item span={2} label={moment(date).format('MMMM')}>{month.amount}{month.processingFees ? " + " + month.processingFees : ""}{month.serviceFees ? " + " + month.serviceFees : ""} EGP</Descriptions.Item>
                                    })
                                }
                            </Descriptions>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

