import React from 'react'
import OutPutResult from '../../../components/result'
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom'
import helper from '../../../utils/helper';
interface IRootState {
    [key: string]: any;
    caption: string,
}
export default function Step3() {
    const history = useHistory();
    const usersCount: any = useSelector<IRootState>(state => state.actionsReducer.usersCount);
    const routeToHrManagement = () => {
        history.push('/hr/hrGetUsers');
        helper.refreshPage()
    }
    return (
        <>
            <div className="approveUsers">
                <OutPutResult status={'success'} subTitle={`تمت اضافة ${usersCount} عنصر جديد الي النظام`} title={'تم تحميل البيانات علي النظام بنجاح'} />
                {<Button className='reUpload' onClick={routeToHrManagement}>الذهاب لإدارة الموظفين</Button>}

            </div>
        </>
    )
}
