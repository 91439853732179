export const GET_INFO = 'GET_INFO';
export const MOVE_STEP = 'MOVE_STEP';
export const UPDATE_REQUESTS = 'UPDATE_REQUESTS';
export const SAVE_PARTNER_Id = 'SAVE_PARTNER_Id';
export const SAVE_ARABIC_EMAIL = 'SAVE_ARABIC_EMAIL';
export const SAVE_ENGLISH_EMAIL = 'SAVE_ENGLISH_EMAIL';
export const Empty_Drawer = 'Empty_Drawer';
export const HR_STEP = 'HR_STEP';
export const USER_COUNT = 'USER_COUNT';
export const CAPTION_CONFIRMATION = 'CAPTION_CONFIRMATION';
export const USERS_TO_APPROVE = 'USERS_TO_APPROVE';
export const DATA_SHEET = 'DATA_SHEET';
export const ONE_PARTNER = 'ONE_PARTNER';
export const REQUEST_ID = 'REQUEST_ID';
export const USER_BALANCE = 'USER_BALANCE';
export const USER_PLANS = 'USER_PLANS';
export const REQUEST_DETAILS = 'REQUEST_DETAILS';
export const OTP_MODAL = 'OTP_MODAL';
export const DEDUCT_FILTERS = 'DEDUCT_FILTERS';
export const USER_ACCOUNTS = 'USER_ACCOUNTS';
export const VIEW_ACCOUNTS = 'VIEW_ACCOUNTS';
export const VIEW_PAYMENT_PLANS = 'VIEW_PAYMENT_PLANS';
