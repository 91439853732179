import { Row, Col, Button, message, Table, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hrStepper, patchApi, changeConfirmationCaption, getAllUsersCount, getUsersToApprove } from '../../../stores/actions';
import { LoadingOutlined } from '@ant-design/icons';
import OutPutResult from '../../../components/result';

interface IRootState {
    [key: string]: any;
    usersCount: number,
    hrStep: number,
    usersToApprove: any,
}
const columns: any = [

    {
        title: 'رقم السطر',
        dataIndex: 'colNumber',
    },
    {
        title: 'رقم تليفون الموظف',
        dataIndex: 'phone',
    },
    {
        title: 'نوع الخطأ',
        dataIndex: 'problem',
        render: (record: { problem: any }) =>
            <>
                <ul style={{ margin: '0', padding: '0' }}>
                    {Array.isArray(record) && record?.map((x: any, key: any) => {
                        return <li style={{ color: '#CC4949', listStyle: 'none' }} key={key}>{x.error}</li>
                    })}
                </ul>
            </>
    },
];
export default function Step2() {
    const [approveSpin, setApproveSpin] = useState(false);
    const [loader, setLoader] = useState(false)
    const [userUploaded, setUsersUploaded] = useState(false)
    const [reason, setReasons] = useState([]);
    const [errorMode, setErrorMode] = useState(false);
    const dispatch = useDispatch();
    const usersCount: any = useSelector<IRootState>(state => state.actionsReducer.usersCount);
    const hrStep: any = useSelector<IRootState>(state => state.actionsReducer.hrStep);
    const usersToApprove: any = useSelector<IRootState>(state => state.actionsReducer.usersToApprove);
    const dataSheet: any = useSelector<IRootState>(state => state.actionsReducer.dataSheet);


    const updateUsersData = useCallback(async (dataSheet: any) => {
        let usersCount;

        try {
            const apiData: any = await dispatch(patchApi(`hr/users`, dataSheet));

            const res = await apiData.payload;

            if (res.status === 200) {
                usersCount = res?.data?.createdUsers?.count + res?.data?.updatedUsers?.updateCount;
                setUsersUploaded(true)
                dispatch(getAllUsersCount(usersCount));
                dispatch(getUsersToApprove(res?.data?.sheetData))
                setLoader(false);

                return
            };

            const newReasons: any = Array.isArray(res?.data?.reason) && res?.data?.reason.map((reason: any, key: any) => {
                return {
                    colNumber: reason?.row,
                    phone: reason?.phone,
                    problem: reason?.arabicErrors,
                }
            });
            setReasons(newReasons);
            setLoader(false);
        } catch (error) {
            // setEditFailed(true);
            setLoader(false);
            setErrorMode(true)
        }
    }, [dispatch]);
    useEffect(() => {
        setLoader(true)
        updateUsersData(dataSheet)
    }, [updateUsersData,dataSheet])
    const bulkApproveUsers = async () => {
        const data = {
            reasonID: 52,
            sheetData: usersToApprove,
        }
        setApproveSpin(true)
        const api: any = await dispatch(patchApi(`hr/hrBulkUpdateStatus`, data))
        const res = await api.payload;
        try {
            if (res.status !== 200) {
                setApproveSpin(false)
                message.error(res.message, 4)
                return 0
            }

            dispatch(hrStepper(hrStep + 1));
            dispatch(changeConfirmationCaption(`لقد تم الموافقه علي ${usersCount && usersCount} مستخدم بنجاح`))

        } catch (error) {
            setApproveSpin(false)
        }
    }
    const refuseApprovingUsers = () => {
        dispatch(hrStepper(hrStep + 1));
        dispatch(changeConfirmationCaption('لقد تم التعديل بدون الموافقه علي المستخدمين بنجاح '))

    }
    const antIcon = <LoadingOutlined style={{ fontSize: 100, color: '#7CC58F' }} spin />;
    return (
        <>
            <div className="approveUsers" style={{ textAlign: 'center' }}>


                {loader ? <div className='waiting'>
                    <Spin indicator={antIcon} style={{ margin: '20px 0' }} />
                    <p className='check'>جاري التحقق من الملف وتحميل البيانات ...</p>
                    <p className='checkHint'>انتظر... قد تستغرق هذه العمليه لعدة دقائق</p>
                </div> : userUploaded ?
                    <>
                        <h3 className='downloaded'>لقد تم تحميل {usersCount && usersCount} موظف</h3>
                        <p className='question'>هل تريد الموافقه علي كل الموظفين في الملف؟</p>
                        <p className="info1">بالضغط علي نعم سوف يتم تحويل الموظفين الي المراجعه النهائيه <br />
                            من قبل ناوباي ويمكنهم البدأ في استخدام التطبيق</p>
                        <p className="info2">
                            بالضغط علي لا سيتم تحميل الملف ويمكنك الموافقه علي المستخدمين
                            <br /> كل مستخدم علي حدا من الصفحه الرئيسيه للبوابه
                        </p>
                        <Row>
                            <Col span={12}>
                                <Button className='approve' onClick={bulkApproveUsers} loading={approveSpin}>نعم أريد الموافقه علي الموظفين بالملف</Button>
                            </Col>
                            <Col span={12}>
                                <Button className='notApprove' onClick={refuseApprovingUsers}>لا أريد الموافقه علي الموظفين الأن</Button>
                            </Col>
                        </Row>
                    </> : errorMode ? <OutPutResult status={'error'} subTitle={' '} title={'عذراً فشلت عملية تحميل البيانات'} btnClick={() => dispatch(hrStepper(0))}
                    /> :
                        <div className="uploaded" style={{ textAlign: 'center' }}>
                            {/* <Row style={{ textAlign: 'center', margin: '20px 0' }}>
                            <Col span={12}>
                                <div className="failed" style={{ textAlign: 'center' }}>
                                    موظفين بياناتهم غير صحيحه {failedValidation && failedValidation}
                                    <CheckCircleOutlined style={{ marginRight: '3px' }} />   </div>
                            </Col>
                            <Col span={12}>
                                <div className="successes" style={{ textAlign: 'center' }}>
                                    موظفين بياناتهم صحيحه {passedValidation && passedValidation}
                                    <CheckCircleOutlined style={{ marginRight: '3px' }} />   </div>
                            </Col>
                        </Row> */}
                            <div className="table">
                                <Table columns={columns} dataSource={reason} pagination={false} />
                            </div>
                            {/* <Button className='reUpload' onClick={() => dispatch(hrStepper(0))}>اعاده تحميل الملف</Button> */}
                        </div>
                }

                {!userUploaded&&<Button className='reUpload' onClick={() => dispatch(hrStepper(0))}>اعاده تحميل الملف</Button>}
            </div>
        </>
    )
}
