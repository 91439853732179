import { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import { getAdjustmentApi } from '../../utils/apisConsts';
import DeleteDebitAndCreditForm from './deleteDebitAndCreditForm';

class DeleteDebitAndCredit extends Component {
    state = {
        componentSteps: [
            {
                title: 'Request ID',
                content: <GetInfo isAdjustment placeHolder="Enter Request ID" apiLink={getAdjustmentApi}/>,
                id: 0,
            }, {
                title: 'Delete Debit/Credit',
                content: <DeleteDebitAndCreditForm />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default DeleteDebitAndCredit