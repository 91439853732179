import { Button, Col, Form, FormInstance, Input, message, Row, Select } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    stepperMoving, getDataInfo, getApi, savePartnerID, addCompanies,
    getOnePartner, getRequestID, getUserAccounts, viewAccountTable
} from '../stores/actions';
// import regex from '../utils/helper'
import OutPutResult from './result';
import Spinner from './spinner';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { deployedCompanies, getCompaniesGroups, getPartners, unDeployedCompanies, companiesApi, getOnePartnerApi } from '../utils/apisConsts';
import { errorMessage } from '../utils/commonStringsConsts'
import GetInfoPic from '../assets/images/getInfoPic.png'
import UserIdVector from "../assets/images/userIdVector.png"
import UserPhoneVector from "../assets/images/userPhoneVector.png"
import UserAccounts from './userAccounts';

const { Option } = Select;


interface Props extends RouteComponentProps<any> {
    stepperMoving(num: number): void
    stepNumber: number,
    getDataInfo(info: {}): void,
    getApi?: any,
    apiLink?: string,
    isNonDeployedCompanies?: boolean,
    isDeployedCompanies?: boolean,
    request?: boolean,
    isPartner?: boolean,
    savePartnerID(value: number | string): void,
    isHoldingComp?: boolean,
    placeHolder?: string,
    isAllCompanies?: boolean,
    isMultipleSelect?: boolean,
    addCompanies(value: []): void,
    getRequestID(value: []): void,
    isApprovedUser?: boolean,
    unSuspendUser?: boolean,
    suspendUser?: boolean,
    userStatus?: boolean,
    param?: boolean,
    isAdjustment?: boolean,
    onePartner?: boolean,
    getOnePartner?: any,
    moveStepByRequestID?: boolean,
    isUserAccounts?: boolean,
    getUserAccounts(value: []): void,
    viewAccountTable(value: boolean): void,
    viewAccounts: boolean,
}
class GetInfo extends Component<Props> {
    formRef = React.createRef<FormInstance>();
    inputsKey = "info"
    state = {
        load: false,
        errorMessage: '',
        companies: [],
        loadCompanies: false,
        errorMode: false,
        selectedPartnerId: "",
        isUserID: false
    }
    componentDidMount() {
        // this.props.stepperMoving(this.props.stepNumber + 1);
        // this.props.isAllCompanies && this.getCompanies(undefined);
        this.props.isNonDeployedCompanies && this.getCompanies(false);
        this.props.isDeployedCompanies && this.getCompanies(true);
        this.props.isAllCompanies && this.getCompanies();
        this.props.isPartner && this.getPartners();
        this.props.isHoldingComp && this.getHoldingCompanies()

        this.checkIfDeepLink()
    }

    checkIfDeepLink = () => {
        if (this.props.userStatus) {
            return
        }
        if (this.props.match.params.id) {
            if (this.props.match.params.id[0] === "0") {
                this.props.match.params.id = this.props.match.params.id.slice(1)
            }
            this.formRef.current?.setFieldsValue({
                [this.inputsKey]: this.props.match.params.id
            })
            this.formRef.current?.submit()
        }
    }
    getCompanies = async (deployed?: boolean) => {

        this.setState({ loadCompanies: true })

        const companies = await this.props.getApi(deployed ? deployedCompanies : this.props.isAllCompanies ? companiesApi : unDeployedCompanies);
        try {
            const res = await companies.payload;
            if (res.status === 200) {
                this.setState({ loadCompanies: false, companies: res.data });
                this.props.addCompanies(res.data)
            } else {
                this.setState({ errorMessage: res.message, loadCompanies: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ errorMessage: errorMessage, loadCompanies: false, errorMode: true })
        }
    }


    getPartners = async () => {
        this.setState({ loadCompanies: true })
        try {
            const Partners = await this.props.getApi(getPartners);
            const res = Partners.payload
            if (res.status === 200) {
                this.setState({ loadCompanies: false, companies: res.partners })
            } else {
                this.setState({ errorMessage: res.message, loadCompanies: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ errorMessage: errorMessage, loadCompanies: false, errorMode: true })
        }
    }

    savePartnerInfo = () => {
        if (this.props.isHoldingComp) {
            this.props.stepperMoving(this.props.stepNumber + 1);
            return
        }
        this.props.savePartnerID(this.state.selectedPartnerId);
        this.props.stepperMoving(this.props.stepNumber + 1);
    }

    getHoldingCompanies = async () => {

        this.setState({ loadCompanies: true })

        const holdingCompanies = await this.props.getApi(getCompaniesGroups);
        try {
            const res = await holdingCompanies.payload;
            if (res.status === 200) {
                this.setState({ loadCompanies: false, companies: res.data })
            } else {
                this.setState({ errorMessage: res.message, loadCompanies: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ errorMessage: errorMessage, loadCompanies: false, errorMode: true })
        }
    }


    getDataInfo = async (value: any) => {
        if (this.props.moveStepByRequestID) {
            this.props.getRequestID(value.info);
            this.props.stepperMoving(this.props.stepNumber + 1);
            return

        }
        console.log("🚀 ~ file: getInfo.tsx ~ line 124 ~ GetInfo ~ getDataInfo= ~ value", value)
        let data;
        if (this.props.param) {
            data = `/${value[this.inputsKey]}`
        }
        else if (this.props.request) {
            data = `?issueID=1&advanceID=${value.requestID}`
        }
        else if (this.props.isAdjustment) {
            data = `?advanceID=${value.info}`
        }
        else if (this.state.isUserID) {
            data = `&userID=${value.info}`
        }
        else {
            data = value[this.inputsKey]
            console.log("🚀 ~ file: getInfo.tsx:168 ~ GetInfo ~ getDataInfo= ~ data:", data)
        }
        this.setState({ load: true })
        const getUserData = await this.props.getApi(`${this.props.onePartner ? getOnePartnerApi : this.props.apiLink}${data}`);
        console.log(data);
        console.log("🚀 ~ file: getInfo.tsx:163 ~ GetInfo ~ getDataInfo= ~ this.props.apiLink:", this.props.apiLink)

        try {
            const res = await getUserData.payload;
            console.log("🚀 ~ file: getInfo.tsx:177 ~ GetInfo ~ getDataInfo= ~ res:", res)

            this.props.getOnePartner(res.partnerDetails)
            if (res.status === 200) {
                if (this.props.isApprovedUser && res.userInfo.userStatusType === 'Approved') {
                    message.error(`The user is approved you don't have permission`)
                }
                else if (this.props.unSuspendUser && (res.userInfo.isSuspended === 'No')) {
                    message.error(`This user has not suspended`)
                }
                else if (this.props.suspendUser && (res.userInfo.isSuspended === 'Yes')) {
                    message.error(`This user has already been suspended`)
                }
                else {
                    if (this.props.userStatus) {
                        if (res.userInfo.companyID !== value.company) {
                            message.error("User's company is not matching the company you specified")
                            return
                        }
                        res.userInfo && this.props.getDataInfo({ ...res.userInfo, selectedCompany: value.company })
                    }
                    else if (this.props.isAdjustment) {
                        delete res.status
                        this.props.getDataInfo({ ...res })
                    }
                    else {
                        if (res?.users) {
                            console.log('res.userInfo = ', res.users);
                            this.props.getUserAccounts(res?.users);
                            this.props.viewAccountTable(true);
                            this.setState({ load: false })
                            this.props.stepperMoving(this.props.stepNumber);
                            return

                        } else {
                            this.props.getDataInfo(res.userInfo ? res.userInfo : res.requestDetails ? res.requestDetails : res.userDocs ? res.userDocs : res.data);
                            this.props.viewAccountTable(false);
                        }
                    }
                    this.props.stepperMoving(this.props.stepNumber + 1);
                }
            } else if (this.props.isAdjustment && res.status === 400) {
                this.setState({ errorMessage: res.reason })
            } else {
                this.setState({ errorMessage: res.message })
            }
            this.setState({ load: false })
        } catch (error) {
            this.setState({ errorMessage: errorMessage, load: false })
        }
    }
    dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    refreshPage = () => {
        window.location.reload();
    }
    changeCompanies = (value: any, data: any) => {
        if (this.props.isAllCompanies) {
            this.props.getDataInfo(value);
            return;
        }


        this.setState({ selectedPartnerId: value })
    }
    render() {
        const { load, errorMessage, companies, loadCompanies, errorMode } = this.state;
        const { isNonDeployedCompanies, isDeployedCompanies, isPartner, moveStepByRequestID, request, isHoldingComp, placeHolder, isAllCompanies, isMultipleSelect, userStatus, isAdjustment, viewAccounts } = this.props;
        return (
            <>{!viewAccounts ?
                <div className='userInfo'>

                    {loadCompanies ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh and try againg'}
                        btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> :
                        <Form name="nest-messages" onFinish={this.props.apiLink || moveStepByRequestID ? this.getDataInfo : this.savePartnerInfo} ref={this.formRef}>
                            <Row>

                                <Col className='phoneForm' span={8}>
                                    <img src={GetInfoPic} alt='GetInfoPic' style={{ display: 'flex', margin: '0 auto' }} />

                                    {(isDeployedCompanies || isNonDeployedCompanies || isAllCompanies || isPartner || isHoldingComp || userStatus) && <Form.Item name={this.props.userStatus ? "company" : this.inputsKey} rules={[{ required: true, message: 'Required' }]}>
                                        <Select style={{ width: '100%', marginBottom: userStatus ? '10px' : 0 }}
                                            placeholder={placeHolder ? placeHolder : 'Please select the company'}
                                            showSearch
                                            allowClear
                                            mode={isMultipleSelect ? "multiple" : undefined}
                                            filterOption={((input, option: any) => this.dropDownSearch(input, option))}
                                            onChange={this.changeCompanies}
                                        >
                                            {companies.map((company: any, key: number) => {
                                                return <Option value={company.id} data={company} key={key}>{company.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    }
                                    {(!(isDeployedCompanies || isNonDeployedCompanies || isAllCompanies || isPartner || isHoldingComp) || userStatus) && <>
                                        <Form.Item name={this.inputsKey} rules={request ? [
                                            { required: true, message: 'Request ID is mandatory' }]
                                            : isAdjustment ? [
                                                { required: true, message: "Adjustment ID is Required" }]
                                                : [
                                                    { required: true, message: 'Phone Or User ID is mandatory' },
                                                    // {
                                                    //     pattern: regex.regexValidation.egyptPhoneNum, message: 'Please enter Egyptian number'
                                                    // }
                                                ]} style={{ width: '100%' }}>
                                            <div className='infoInputs'>

                                                <div className='inputFrame' style={{ marginTop: "25px" }}>
                                                    {/* <img src={UserPhoneVector} className='icons' alt='icon' /> */}
                                                    <Input placeholder={(placeHolder && !userStatus) ? placeHolder : request ? 'Enter The Request ID' : 'Enter User’s Phone number'}
                                                        prefix={<img src={UserPhoneVector} className='icons' alt='icon' />} onChange={() => this.setState({ errorMessage: '', isUserID: false })} />
                                                </div>
                                                {!request ? <>
                                                    <span className='orSpan'>Or</span>
                                                    <div className='inputFrame'>
                                                        {/* <img src={UserIdVector} className='icons' alt='icon'/> */}
                                                        <Input placeholder={'Enter User’s ID'} prefix={<img src={UserIdVector} className='icons' alt='icon' />} onChange={() => this.setState({ errorMessage: '', isUserID: true })} />

                                                    </div>

                                                </>
                                                    : ""
                                                }

                                            </div>
                                        </Form.Item>
                                    </>}

                                    <span className="errorMessage">{errorMessage && errorMessage}</span>
                                    {/* <span style={{ margin: '15px 0 0 0', color: '#ff4d4f' }}>{errorMessage}</span> */}
                                    <Form.Item>
                                        <Button className='userBtn' shape="round" type="primary" loading={load} block htmlType="submit" style={{ backgroundColor: "#7CC58F", borderColor: "#7CC58F" }}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>}

                </div> : <UserAccounts />}
            </>

        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
    stepNumber: state.actionsReducer.stepNumber,
    viewAccounts: state.actionsReducer.viewAccounts,
})
export default withRouter(connect(mapStateToProps, {
    stepperMoving, getDataInfo, getUserAccounts, viewAccountTable,
    getApi, savePartnerID, addCompanies, getOnePartner, getRequestID
})(GetInfo))