import { Button, Checkbox, Col, Descriptions, Divider, Form, message, Result, Row, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../apis/axiosRequest';
import AllUserInfo from '../../components/allUserInfo';
import DataTable from '../../components/dataTable';
import ModalCompnent from '../../components/modal';
import OutPutResult from '../../components/result';
import { userInfo } from '../../utils/commonInterfaces';
import { errorMessage } from '../../utils/commonStringsConsts';
interface IRootState {
    [key: string]: {
        info?: userInfo,
    }
}

export default function UnAssignUserRoleForm() {
    const info: any = useSelector<IRootState>(state => state.actionsReducer.info);
    const [loadingData, setLoadingData] = useState(false)
    const [unAssigning, setUnAssigning] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [allUserRoles, setAllUserRoles] = useState([])
    const [visibleModal, setVisibleModal] = useState(false)
    const [resMessage, setResMessage] = useState("")
    const [unUpdatedRoles, setUnUpdatedRoles] = useState<string[]>([])
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Un-Assign',
            dataIndex: '',
            key: '',
            render: (role: { roleId: number }) => <Form.Item name={role.roleId} valuePropName="checked" noStyle><Checkbox /></Form.Item>
            ,
            width: "110px"
        },
        {
            title: 'User Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Role Allowed Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (actions: any) => (
                <>
                    {actions.map((action: any) => (
                        <Tag color="blue" style={{ margin: `2px 5px` }} key={action}>
                            {action}
                        </Tag>
                    ))}
                </>
            )
        },
    ];

    useEffect(() => {
        getAssignedRoles(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCloseModal = () => {
        setVisibleModal(false)
    }

    const getAssignedRoles = async (initialCall?: boolean) => {
        try {
            setLoadingData(true)
            const userRoles: { [key: string]: any } = await dispatch(request({
                url: "/users/getAllPermissions", method: "GET", params: {
                    userID: info.userID
                }
            }))
            console.log("🚀 ~ file: assignUserRoleForm.tsx ~ line 70 ~ getAllRoles ~ userRoles", userRoles)
            if (userRoles.data.status === 200) {
                const roles = userRoles.data.roles
                setAllUserRoles(roles)
            }
            else if (userRoles.data.status === 401) {
                initialCall && message.error(userRoles.data.message, 4)
                setAllUserRoles([])
            }
            else {
                setErrorMode(true)
            }
            setLoadingData(false)
        }
        catch (e: any) {
            setLoadingData(false)
            setErrorMode(true)
            console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 28 ~ getGlobalCaps ~ e", e)
        }
    }

    const unAssignUserRoles = async (values: { [key: string]: string }) => {
        console.log("🚀 ~ file: assignUserRoleForm.tsx ~ line 161 ~ assignUserRoles ~ values", values)
        const chosenRoles: any[] = Object.keys(values).filter((key) => !!values[key]).map((key) => parseInt(key))
        try {
            setUnAssigning(true)
            const res: { [key: string]: any } = await dispatch(request({
                url: '/users/unAssignRolesToUser', method: "DELETE", data: {
                    userId: info.userID,
                    rolesIds: chosenRoles
                }
            }));
            if (res.data.status === 200) {
                if (res.data.unUpdatedRoles && res.data.unUpdatedRoles.length > 0) {
                    const returnedRoles: string[] = allUserRoles.filter((role: { roleId: number }) => res.data.unUpdatedRoles.includes(role.roleId)).map((role: { role: string }) => role.role)
                    console.log("🚀 ~ file: assignUserRoleForm.tsx ~ line 114 ~ assignUserRoles ~ returnedRoles", returnedRoles)
                    setUnUpdatedRoles(returnedRoles)
                    setResMessage(res.data.message)
                    setVisibleModal(true)
                }
                else {
                    message.success(res.data.message, 4);
                }
                getAssignedRoles()
            } else {
                message.error(res.data.message, 4);
            }
            setUnAssigning(false)
        } catch (error) {
            console.log("🚀 ~ file: assignUserRoleForm.tsx ~ line 105 ~ unAssignUserRoles ~ error", error)
            setUnAssigning(false)
            message.error(errorMessage, 4);
        }
    }

    return (
        errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage} />
            : <>
                <Form name="nest-messages" onFinish={unAssignUserRoles} initialValues={{}} style={{ marginTop: "10px" }}>
                    <DataTable
                        columns={columns}
                        rows={allUserRoles}
                        pagination={false}
                        loading={loadingData}
                        scrolling={{ x: 100 }}
                    />
                    <Form.Item>
                        <Button className='createCompanyGroupBtn' shape="round" type="primary" htmlType="submit" loading={unAssigning} style={{ margin: '18px 0', padding: "4px 30px", float: "right" }}>
                            Un-Assign
                        </Button>
                    </Form.Item>
                </Form>
                <Divider orientation="left" className='formTitle'>User Information</Divider>
                <AllUserInfo info={info} />
                <ModalCompnent visibleModal={visibleModal} handleAcceptModal={handleCloseModal} handleCloseModal={handleCloseModal} none="none"
                    content={
                        <>
                            <Result
                                status="success"
                                title={<p style={{ fontSize: '18px', padding: 0 }}>{resMessage}</p>}
                            />
                            <Descriptions layout="vertical" bordered style={{ margin: '15px 0' }}>
                                <Descriptions.Item label="Un-updated Roles" span={3}>
                                    <Row>
                                        {
                                            Array.isArray(unUpdatedRoles) && unUpdatedRoles.length > 0 &&
                                            unUpdatedRoles.map((role: string) => <Col span={6}>{role}</Col>)
                                        }
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    } />
            </>)
}