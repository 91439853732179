import { Row, Col, Button, message, Result } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request } from '../stores/actions';
import Spinner from '../components/spinner';
import helper from '../utils/helper';
import { UploadOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';
import DataTable from '../components/dataTable';
import Helper from '../utils/helper';
import XLSX from "xlsx";
// import SheetTemplate from '../components/bulkActionSheetTemplate';
// import { BulkCallInfoTemplate, bulkCreateClearanceTemplate } from '../utils/bulkSheetsTemplates';
import BulkErrorsModal from '../components/bulkErrorsModal';
import { importCallReportsApi } from '../utils/apisConsts';
import { errorMessage } from '../utils/commonStringsConsts';
import TransactionErrorModal from '../components/transactionErrorModal';


interface Props {
  request?: any,
  removeData: boolean
}

class BulkCallInfo extends Component<Props> {
  fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
  state = {
    dataSpin: false,
    tableData: [],
    tableColumns: [],
    tableLoading: false,
    pagination: {},
    uploadingError: false,
    visibleModal: false,
    uploadingErrorMessage: "",
    validationErrors: [],
    resErrorContent: undefined,
    visibleMessageModal: false
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
      this.clearData()
    }
  }



  handleFile(file: any /*:File*/) {
    this.setState({ tableLoading: true, uploadingError: false })
    /* Boilerplate to set up FileReader */
    let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
    if (!acceptable_exe.exec(file.name)) {
      message.error(`The extension of (${file.name}) is not supported!`);
    } else {
      let fileReader = new FileReader(), rows: any, result, workbook: XLSX.WorkBook;
      fileReader.readAsBinaryString(file);
      fileReader.onload = (event: any) => {
        result = event.target.result;
        workbook = XLSX.read(result, { type: "binary", cellDates: true });
        workbook.SheetNames.forEach(sheet => {
          rows = helper.sheetToJson(workbook.Sheets[sheet]);
        });
        const modifiedRows = rows.map((row: any) => {
          const modifiedDuration = new Date(row.Duration).toString() === 'Invalid Date' ? '' : new Date(row.Duration).toLocaleTimeString('it-IT')
          const modifiedDurationWait = new Date(row['Duration Wait']).toString() === 'Invalid Date' ? '' : new Date(row['Duration Wait']).toLocaleTimeString('it-IT')
          return { ...row, Duration: modifiedDuration, 'Duration Wait': modifiedDurationWait, 'Start Time': helper.excelDateFormat(row['Start Time'], 'M/D/YYYY h:mm'), 'End Time': helper.excelDateFormat(row['End Time'], 'M/D/YYYY h:mm') }
        })
        const tableColumns = helper.generateTableColumns(modifiedRows)
        this.setState({ tableColumns, tableData: modifiedRows, tableLoading: false })
      }
    }
  }

  handleUploadFile = (e: { target: { files: any, value: any }; }) => {
    this.setState({ tableData: [], uploadingError: false });

    const files = e.target.files;
    if (files && files[0]) this.handleFile(files[0]);

    // to clear input value to be able to add the same file again
    e.target.value = null;
  }

  handleTablePagination = (e: any) => {
    this.setState({
      pagination: e, tableLoading: true
    })
    setTimeout(() => this.setState({ tableLoading: false }), 1500)
  }

  openSelectFile = () => {
    this.fileInputRef.current!.click();
  }

  handleCloseModal = () => {
    this.setState({ visibleModal: false, visibleMessageModal: false })
  }

  submitCallInfo = async () => {
    this.setState({ dataSpin: true })
    try {
      const res = await this.props.request({ url: importCallReportsApi, method: "Post", data: { reports: this.state.tableData } });
      if (res.data.status === 200) { //show success message
        message.success(res.data.message)
        this.clearData()
      }
      else if (res.data.status === 400 && res.data.reason) { //open transaction error popup
        this.setState({
          resErrorContent: {
            message: res.data.message,
            errorData: res.data.reason
        },
          visibleMessageModal: true
        })
      }
      else { // show any other status errors messages (400 without reason OR 500)
        message.error(res.data.message)
      }
      this.setState({ dataSpin: false })
    } catch (error) { // show any response status errors messages except 200(OK)
      console.log("🚀 ~ file: bulkCreateClearance.tsx:75 ~ BulkCreateClearance ~ submitRequests= ~ error", error)
      this.setState({ dataSpin: false })
      message.error(errorMessage);
    }
  }

  clearData = () => {
    this.setState({ tableData: [] })
  }

  render() {
    const { dataSpin, tableColumns, pagination, tableData, tableLoading, uploadingError, visibleModal, uploadingErrorMessage, validationErrors, resErrorContent, visibleMessageModal } = this.state;
    return (
      <div className='statusBulkUpdate'>
        <input
          hidden
          type="file"
          className="form-control"
          id="file"
          ref={this.fileInputRef}
          accept=".csv,.xlsx"
          onChange={this.handleUploadFile}
        />
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col span={4}><Button icon={<UploadOutlined />} onClick={this.openSelectFile} >Click to Upload file</Button></Col>
          <Col span={18}>{tableData.length > 0 && <><b>Uploaded Rows Count: </b> {tableData.length} {tableData.length > 1 ? "Users" : "User"}</>}</Col>
          {tableData && tableData.length > 0 && <Col className='colOfAssign' span={2}>
            <Button type="primary" block loading={dataSpin} onClick={this.submitCallInfo}>
              Submit
            </Button>
          </Col>}
        </Row>
        {tableData.length === 0 && <Result
          icon={<SmileOutlined />}
          title={'Please upload call center information file'}
        />}
        {(Array.isArray(tableData) && tableData.length > 0 && !uploadingError) &&
          <>
            {(tableLoading && tableData.length === 0) ? <Spinner size={'large'} /> :
              <div className='tableSection'>
                <DataTable columns={tableColumns}
                  pagination={pagination}
                  rows={tableData}
                  handleTableChange={this.handleTablePagination}
                  loading={false}
                  scrolling={{ x: 'max-content' }} />
              </div>}</>}
        {uploadingError &&
          <Result
            icon={<FrownOutlined />}
            title={uploadingErrorMessage}
          />
        }
        <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={this.handleCloseModal} errorMessage={resErrorContent} />
        <TransactionErrorModal resErrorContent={resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={this.handleCloseModal} />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, { request })(BulkCallInfo) 
