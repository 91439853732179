import { Form, Row, Col, Upload, Button, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
// import { englishInputError, arabicInputError } from '../../utils/commonStringsConsts'
import { UploadOutlined } from '@ant-design/icons';
import helper from '../../utils/helper';
import { postApi } from '../../stores/actions';
import { useDispatch } from 'react-redux';

interface Props {
    name: string,
    isCategories?: boolean,
    apiLink: string,
    isPartnerTypes?: boolean,
}
export default function CategoriesAndBrands(props: Props) {
    const [loader, setLoader] = useState(false)



    const [photos, setPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const dispatch = useDispatch();
    const formRef: any = React.useRef([]);

    useEffect(() => {
        formRef.current?.resetFields();
    }, [])

    const normFile = (e: any) => {
        setPhotos(e.file.originFileObj)
        return e.fileList || e.file;
    };
    const normSelectedFile = (e: any) => {
        setSelectedPhotos(e.file.originFileObj)
        return e.fileList || e.file;
    };
    const submitData = async (values: any) => {
        setLoader(true)
        const data = props.isPartnerTypes ? {
            typeNameEn: values.typeNameEn,
            typeNameAr: values.typeNameAr,
        } : {
            nameEn: values.nameEn,
            nameAr: values.nameAr,
        }

        const formData = new FormData();
        const file: any = photos;
        const selectedFiles: any = props.isCategories && selectedPhotos;
        formData.append("image", file)
        props.isCategories && formData.append("selectedImage", selectedFiles)
        for (let key in data) {
            formData.append(key, JSON.stringify(values[key]))
        }
        const apiData: any = await dispatch(postApi(props.apiLink, formData));
        const res = await apiData.payload;
        try {
            if (res.status === 200 || res.status === 201) {
                setLoader(false)
                message.success(`${props.name} Created Successfully`)
                formRef.current?.resetFields();
                return
            }
            message.error(`${props.name} Rejected`);
            setLoader(false)
        } catch (error) {
            message.error(`Something went wrong, please try again`);
            setLoader(false)
        }

    }
    return (
        <>
            <div className='CategoriesAndBrands'>
                <Form
                    ref={formRef}
                    // initialValues={{ remember: true }}
                    onFinish={submitData}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row>

                        <Col span={12} style={{ padding: '0 20px 0 0' }}>
                            <label htmlFor="">{props.name} <span>*</span></label>
                            <Form.Item name={props.isPartnerTypes?'typeNameEn':'nameEn'}
                                rules={[{ required: true, message: "Required" }, { pattern: helper.regexValidation.englishRegex, message: 'Enter the name with English' }]}
                            >
                                <Input placeholder={props.name} />
                            </Form.Item>
                            {/* {!this.state.categoriesValid && <span className="categoriesError">{englishInputError}</span>} */}
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">{`${props.name} in Arabic`} <span>*</span></label>
                            <Form.Item name={props.isPartnerTypes?'typeNameAr':'nameAr'}
                                rules={[{ required: true, message: "Required" }, { pattern: helper.regexValidation.arabicRegex, message: 'Enter the name with Arabic' }]}
                            >
                                <Input placeholder={`Arabic ${props.name}`} />
                            </Form.Item>
                            {/* {!this.state.categoriesArValid && <span className="categoriesError">{arabicInputError}</span>} */}
                        </Col>

                    </Row>
                    <Row className='images'>
                        <Col span={12}>
                            <label htmlFor="">Upload Image <span>*</span></label>
                            <Form.Item
                                name="image"
                                getValueFromEvent={normFile}
                                rules={[{ required: true, message: 'Required' }]}>
                                <Upload accept='.png,.jpg' {...helper.dataFile} maxCount={1} name="logo" listType="picture">
                                    <Button icon={<UploadOutlined />}>Click to upload the logo</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        {props.isCategories ? <Col span={12}>
                            <label htmlFor="">Upload Selected Image <span>*</span></label>
                            <Form.Item
                                name="selectedImage"
                                getValueFromEvent={normSelectedFile}
                                rules={[{ required: true, message: 'Required' }]}>
                                <Upload accept='.png,.jpg' {...helper.dataFile} maxCount={1} name="logo" listType="picture">
                                    <Button icon={<UploadOutlined />}>Click to upload the logo</Button>
                                </Upload>
                            </Form.Item>
                        </Col> : ''}
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loader} style={{ float: 'right', margin: '15px 0' }}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </div>
        </>
    )
}
