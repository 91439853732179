import { Descriptions, Badge } from 'antd'
import moment from 'moment'
import React from 'react'
import helper from '../utils/helper'
import { TrueIcon } from '../utils/commonIcons'
import { FalseIcon } from '../utils/commonIcons'
interface Props {
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        hiringDate?: string,
        jobTitle?: string,
        hasMeezaCard?: number,
        department?: any,
        suspensionStatus?: {
            comment: string,
            date: string,
            isSuspended: boolean,
            reason: string
        }
    }
}
export default function allUserInfo(props: Props) {
    const { info } = props;
    return (
        <>
            <Descriptions bordered style={{ margin: '15px 0' }}>
                <Descriptions.Item label="Name" span={2}>{info?.name ? info?.name : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Company" span={2}>{info?.companyName ? info?.companyName : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Phone" span={2}>{info?.phone ? info?.phone : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Email" span={2}>{info?.email ? info?.email : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Account Created" span={2}>{info?.accountCreatedAt ? helper.changeDateFormat(info?.accountCreatedAt) : 'NA'}</Descriptions.Item>

                <Descriptions.Item label="Bank Name" span={2}>{info?.bankName ? info?.bankName : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Bank Holder Name" span={2}>{info?.bankAccountHolderName ? info?.bankAccountHolderName : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Bank Account Number" span={2}>{info?.bankAccountNumber ? info?.bankAccountNumber : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Status" span={2}>
                    <Badge color={info?.userStatus.includes('Active') ? "green" : 'grey'} status={info?.userStatus.includes('Active') ? "processing" : 'default'} text={info?.userStatusType ? info?.userStatusType : 'NA'} />
                </Descriptions.Item>
                <Descriptions.Item label="Reason" span={2}>
                    {info?.userStatus}
                </Descriptions.Item>

                <Descriptions.Item label="National ID Number" span={2}>{info?.nationalIDNumber ? info?.nationalIDNumber : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Net Salary" span={2}>{info?.netSalary ? info?.netSalary : 'NA'}</Descriptions.Item>

                <Descriptions.Item label="Max Advance Allowed Per Company" >{info?.maxAdvancePercentAllowedPerCompany ? info?.maxAdvancePercentAllowedPerCompany : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Max Balance">{info?.maxBalance ? info?.maxBalance : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Max Shopping Balance">{info?.maxShoppingBalnce ? info?.maxShoppingBalnce : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Company Max Installment Count">{info?.companyMaxInstallmentCount ? info?.companyMaxInstallmentCount : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Wallet">{info?.wallet ? info?.wallet : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Staff ID">{info?.staff_id ? info?.staff_id : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Hiring Date">{info?.hiringDate ? moment(info.hiringDate).format("Do MMM, YYYY") : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Job Title">{info?.jobTitle ? info?.jobTitle : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Department" span={2}>{info?.department?.nameEn ? info?.department?.nameEn : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Has Meeza Card">{info?.hasMeezaCard ? (info?.hasMeezaCard === 2 ? "Received" : info?.hasMeezaCard === 1 ? "Requested" : "None") : 'NA'}</Descriptions.Item>

            </Descriptions>
            <Descriptions bordered style={{ margin: '15px 0' }} title="Suspension information">
                <Descriptions.Item label="Suspended">{info?.suspensionStatus?.isSuspended ? TrueIcon : FalseIcon}</Descriptions.Item>
                <Descriptions.Item label={info?.suspensionStatus?.isSuspended ? "Suspension Date" : "Un-Suspension Date"}>{info?.suspensionStatus?.date ? moment(info?.suspensionStatus?.date).format("Do MMM, YYYY") : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Suspension Reason">{info?.suspensionStatus?.reason ? info?.suspensionStatus?.reason : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Comment">{info?.suspensionStatus?.comment ? info?.suspensionStatus?.comment : 'NA'}</Descriptions.Item>
            </Descriptions>
        </>
    )
}
