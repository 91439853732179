import { AUTHED, NUMBER_VERIFY, PIN_VERIFY, FORGOT_PASSWORD,TIME_OUT,PHONE_NUMBER,PIN_CODE,ROUTES } from './types';
export * from '../../../apis/postRequest';

export const isAuthintecated = (value: boolean) => {
    return (dispatch: any) => dispatch({
        type: AUTHED,
        payload: value,
    })
}
export const changePhoneNumberStatus = (value: boolean) => {
    return (dispatch: any) => dispatch({
        type: NUMBER_VERIFY,
        payload: value,
    })
}
export const addPhoneNumber = (value: string) => {
    return (dispatch: any) => dispatch({
        type: PHONE_NUMBER,
        payload: value,
    })
}

export const addPinCode = (value: string) => {
    return (dispatch: any) => dispatch({
        type: PIN_CODE,
        payload: value,
    })
}

export const changePinCodeStatus = (value: boolean) => {
    return (dispatch: any) => dispatch({
        type: PIN_VERIFY,
        payload: value,
    })
}

export const changeForgotPasswordStatus = (value: boolean) => {
    return (dispatch: any) => dispatch({
        type: FORGOT_PASSWORD,
        payload: value,
    })
}

export const TimerTimeOut = (value: boolean) => {
    return (dispatch: any) => dispatch({
        type: TIME_OUT,
        payload: value,
    })
}
export const getAccessRoutes = (value: []) => {
    return (dispatch: any) => dispatch({
        type: ROUTES,
        payload: value,
    })
}
