import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import ViewPartnerBranchesSheet from './viewPartnerBranchesSheet';

class CreatePartnerBranches extends Component{
    state = {
        componentSteps: [
            {
                title: 'Select Partner',
                content: <GetInfo isPartner={true} placeHolder='Please select the partner'/>,
                id: 0,
            }, {
                title: 'Add partner branches',
                content: <ViewPartnerBranchesSheet />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <div className='create-merchant'>
                <Stepper componentSteps={componentSteps} />
            </div>
        )
    }
}

export default CreatePartnerBranches
