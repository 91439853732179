import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Logo from '../../../assets/images/loginLogoIcon.34b51808.png'
import PhoneNumber from '../../../components/phoneNumber';
import SubmittingPassword from '../../../components/submitPassword';
import SubmittingBin from '../../../components/submitBinNumber';
import SubmittingNewPassword from '../../../components/submitNewPassword';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from "react-router-dom";
import Cookies from 'js-cookie';
import { isAuthintecated } from "../../../stores/actions";

interface LoginProps extends RouteComponentProps<any> {
    phone_verified?: boolean
    pin_code_verified?: boolean,
    forgot_password?: boolean,
    loginPathName:any,
    isAuthintecated:any
}
class Login extends Component<LoginProps> {
    componentDidMount(){
        let user = Cookies.get('token')&&true;  
        if(user){
            this.props.history.push('/')
        }else{
            this.props.isAuthintecated(false)
        }
    }
    render() {
        const { phone_verified, pin_code_verified, forgot_password } = this.props;
        // Layout steps
        const Login_layouts= !phone_verified && !pin_code_verified && !forgot_password ? <PhoneNumber />
            : phone_verified && !pin_code_verified && !forgot_password ? <SubmittingBin />
                : phone_verified && pin_code_verified && !forgot_password ? <SubmittingPassword />
                    : phone_verified && pin_code_verified && forgot_password ? <SubmittingNewPassword /> : null;
        return (
            <div className='main'>
                <div className="container">
                    <Row>
                        <Col xs={24}>
                            <img className='logo' src={Logo} alt='logo' />
                        </Col>
                        <Col className='ant-form-login' xs={24}>
                            {Login_layouts}
                        </Col>
                    </Row><br />
                </div>


            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    phone_verified: state.auth.phone_verified,
    pin_code_verified: state.auth.pin_code_verified,
    forgot_password: state.auth.forgot_password,
})
export default withRouter(connect(mapStateToProps, {isAuthintecated})(Login))