import { Button, Col, Descriptions, message, Row } from "antd"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BasicUserInfo from "../../components/basicUserInfo";
import DataTable from "../../components/dataTable";
import OutPutResult from "../../components/result";
import Spinner from "../../components/spinner";
import { stepperMoving } from "../../stores/actions";
import { deleteLostApi, getLostDetailsApi } from "../../utils/apisConsts";
import { errorMessage } from "../../utils/commonStringsConsts";
import helper from "../../utils/helper";
import { lostCollections } from "../../utils/tableColumns";

export default function DeleteLostForm() {
    const userInfo = useSelector((state: any) => state.actionsReducer.info)
    const dispatch = useDispatch()
    const [lostCase, setLostCase] = useState<{ [key: string]: any }>([])
    const [loadingGettingLost, setLoadingGettingLost] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    useEffect(() => {
        getLostDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLostDetails = async () => {
        try {
            setLoadingGettingLost(true)
            const lostDetails: { [key: string]: any } = await dispatch(request({
                url: getLostDetailsApi, method: "GET", params: {
                    userID: userInfo.userID
                }
            }))
            if (lostDetails.data.status === 200) {
                setLostCase({
                    ...lostDetails.data,
                    totalCollectedAmount: lostDetails.data.collections.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue.paymentsAmount), 0)
                })
            }
            else if (lostDetails.data.status === 400 && lostDetails.data.reason) {
                message.error(`${lostDetails.data.message}, Reason: ${lostDetails.data.reason}`, 4)
                dispatch(stepperMoving(0))
            }
            else {
                message.error(lostDetails.data.message, 4)
                setErrorMode(true)
            }
            setLoadingGettingLost(false)
        }
        catch (error) {
            console.log("🚀 ~ file: lostCollectionForm.tsx ~ line 46 ~ getLostDetails ~ error", error)
            setLoadingGettingLost(false)
            setErrorMode(true)
        }
    }



    const deleteLost = async (values: any) => {
        try {
            setLoadingDelete(true)
            const deleteRes: { [key: string]: any } = await dispatch(request({
                url: `${deleteLostApi}/${lostCase.lostRequestID}`, method: "PATCH", data: { note: "asd" }
            }))
            if (deleteRes.data.status === 200) {
                message.success(deleteRes.data.message)
                dispatch(stepperMoving(0))
            } else {
                message.error(deleteRes.data.message)
            }
            setLoadingDelete(false)
        }
        catch (error: any) {
            console.log("🚀 ~ file: deleteAdjustmentForm.tsx ~ line 28 ~ deleteAdvance ~ error", error)
            message.error(errorMessage)
            setLoadingDelete(false)
        }
    }


    return (
        errorMode ?
            <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh the page and try again'}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} />
            : loadingGettingLost ? <Spinner size="large" />
                : <div className="deleteLost">
                    <Row>
                        <Col span={24}>
                            <Descriptions
                                title={`Lost Request #${lostCase.lostRequestID}`}
                                bordered
                                size={'small'}
                                extra={<Button className='cancelBtn' type="default" shape='round' danger onClick={deleteLost} loading={loadingDelete}>Delete Lost Request</Button>}
                            >
                                <Descriptions.Item span={2} label="Total Lost Amount (EGP)">{helper.amountFormatter(lostCase.totalLostAmount)}</Descriptions.Item>
                                <Descriptions.Item span={2} label="Total Collected Amount (EGP)">{helper.amountFormatter(lostCase.totalCollectedAmount)}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {Array.isArray(lostCase.collections) && lostCase.collections.length > 0 &&
                            <Col span={24} className="collectionDetails">
                                <label className="infoLabel">Collections Details</label>
                                <DataTable
                                    columns={lostCollections}
                                    rows={lostCase.collections}
                                    pagination={false}
                                    loading={loadingGettingLost}
                                    scrolling={{ y: 300 }}
                                />
                            </Col>}
                    </Row>
                    <Row>
                        <Col span={24}>
                            <label className="infoLabel">User Information</label>
                            <BasicUserInfo info={userInfo} />
                        </Col>
                    </Row>
                </div>
    )
}
