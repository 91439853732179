import { useState, useEffect, useCallback } from 'react'
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, message } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import helper from '../utils/helper';
import { createLostPlanInstallmentsApi, getLostPlanInstallmentsApi, getLostDetailsApi, updateLostPlanInstallmentsApi } from '../utils/apisConsts';
import { errorMessage } from '../utils/commonStringsConsts';
import moment from 'moment';
import Spinner from '../components/spinner';
import OutPutResult from '../components/result';
import UserInfoUI from '../components/uiInfo'
import { userLostCollectionColumns } from '../utils/tableColumns';
import DataTable from '../components/dataTable';
interface Values {
    amount: number;
    date: string;
    comment: string;
}
interface Collection { paymentsAmount: number, actualPaymentDate: string, discount: number }
interface CollectionPlansProps {
    userID: number;
    onCancel: () => void;
}
const CollectionPlans = ({
    userID,
    onCancel
}: CollectionPlansProps) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingInstallments, setIsLoadingInstallments] = useState(false)
    const [isError, setIsError] = useState(false)
    const [installments, setInstallments] = useState([{}])
    const [lostDetails, setLostDetails] = useState([{}])
    const [collections, setCollections] = useState([])
    const fetchLostData = useCallback(async () => {
        setIsLoadingInstallments(true)
        try {
            const [lostDetails, lostPlanInstallments]: any[] = await Promise.all([
                helper.callApiRequest(`${getLostDetailsApi}?userID=${userID}`, 'GET'),
                helper.callApiRequest(`${getLostPlanInstallmentsApi}?userID=${userID}`, 'GET')])
            setIsLoadingInstallments(false)
            if (lostDetails?.data?.status === 400 || lostPlanInstallments?.data?.status === 400) {
                message.error(lostDetails?.data?.reason, 5)
            }
            if (lostDetails?.data?.status !== 200 || lostPlanInstallments?.data?.status !== 200) setIsError(true)
            // plans installments
            if (lostPlanInstallments?.data?.installments.length === 0) {
                setInstallments([{}])
                form.setFieldsValue({ installments: [{}] })
            }
            else {
                const modifiedInstallments = lostPlanInstallments?.data?.installments.map(({ amount, comment, date }: Values) => {
                    return { amount: Number(amount), date: moment(date), comment }
                })
                setInstallments(modifiedInstallments)
                form.setFieldsValue({ installments: modifiedInstallments })
            }
            //lost details
            setLostDetails([
                { key: 'Lost Amount', value: lostDetails?.data?.totalLostAmount ? `${helper.amountFormatter(lostDetails?.data?.totalLostAmount)} EGP` : 0 },
                { key: 'Lost Discount', value: lostDetails?.data?.lostReq?.discount ? `${helper.amountFormatter(lostDetails?.data?.lostReq?.discount)} EGP` : 0 },
                { key: 'Lost Date', value: lostDetails?.data?.lostReq?.actualPaymentDate ? moment(lostDetails?.data?.lostReq?.actualPaymentDate).format("DD-MM-YYYY"): '' },
                { key: 'Remaining Amount', value: lostDetails?.data?.remainingAmount ? `${helper.amountFormatter(lostDetails?.data?.remainingAmount)} EGP` : 0 }])
            // collections
            setCollections(lostDetails?.data?.collections.map((({ paymentsAmount, actualPaymentDate, discount }: Collection) => {
                return {
                    paymentsAmount: paymentsAmount ? `${helper.amountFormatter(paymentsAmount)} EGP` : 0,
                    actualPaymentDate: actualPaymentDate ?? '',
                    discount: discount ? `${helper.amountFormatter(discount)} EGP` : 0
                }
            })))
        } catch (err: any) {
            setIsError(true)
            setIsLoadingInstallments(false)
        }
    }, [userID, form])
    useEffect(() => {
        fetchLostData()
    }, [fetchLostData])
    const handleSubmitCollectionPlans = async (values: Values[]) => {
        setIsLoading(true)
        const body = { ...values, userID }
        let url, method
        if (Object.keys(installments[0]).length > 0) {
            url = updateLostPlanInstallmentsApi
            method = 'PUT'
        } else {
            url = createLostPlanInstallmentsApi
            method = 'POST'
        }
        try {
            const response: any = await helper.callApiRequest(url, method, '', body);
            setIsLoading(false)
            if (response?.data?.status === 200) {
                message.success(response?.data?.message, 5)
                fetchLostData()
            }
            if (response?.data?.status === 400) {
                message.error(response?.data?.message, 5)
            }
        } catch (err: any) {
            setIsLoading(false)
            message.error(errorMessage, 5);
        }
        form.resetFields()
        //onCancel()
    }
    return (
        <div style={{ width: '34.25rem', minHeight: '640px' }}>
            {isLoadingInstallments || isLoading ?
                <Spinner size='large' /> : isError ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                    btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                    <>
                        <UserInfoUI userData={lostDetails} activeKey={2} header={'Lost and Remaining Amount'} nonEditable={true} />
                        <Col span={24} style={{ margin: '20px 0' }}>
                            <label style={{ fontWeight: 'bolder' }}>Collections</label>
                            <DataTable columns={userLostCollectionColumns} rows={collections}
                                pagination={false} loading={false} scrolling={{ x: 100, y: 300 }} />
                        </Col>
                        <label style={{ margin: '0 0 5px', fontWeight: 'bolder' }}>{`${Object.keys(installments[0]).length > 0 ? 'Update' : 'Create'} Collection Plan`}</label>
                        <Form
                            layout='vertical'
                            form={form}
                            autoComplete="off"
                            onFinish={handleSubmitCollectionPlans}
                            initialValues={{ installments }}
                            style={{ margin: '10px 0' }}
                        >
                            <Form.List name="installments">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <>
                                                <Row key={key}>
                                                    <Col span={12}>
                                                        <label> Amount</label><span style={{ color: '#ff4d4f' }}>*</span>
                                                        <Form.Item {...restField} className='collectionAmount' name={[name, 'amount']} rules={[{ required: true, message: 'Required' }]}>
                                                            <InputNumber min={1} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>

                                                        <label> Date</label><span style={{ color: '#ff4d4f' }}>*</span>

                                                        <Form.Item {...restField} className='collectionDate' name={[name, 'date']} rules={[{ required: true, message: 'Required' }]}>
                                                            <DatePicker />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={23} style={{ margin: '10px 0' }}>
                                                        <label> Comment </label><span style={{ color: '#ff4d4f' }}>*</span>
                                                        <Form.Item {...restField} name={[name, 'comment']} style={{ margin: '0 0 20px' }} rules={[{ required: true, message: 'Required' }]}>
                                                            <Input.TextArea />
                                                        </Form.Item>
                                                    </Col>
                                                    {(key >= 1 || Object.keys(installments[0]).length > 0) && (

                                                        <Col span={1}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} style={{ position: 'relative', top: '50%', left: '50%' }} />
                                                        </Col>
                                                    )}

                                                </Row>
                                            </>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ margin: '0 0 20px' }}>
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <div className='collectionFormBtns'>
                                <Button type='ghost' htmlType='reset' className='collectionFormBtns__btn' onClick={() => {
                                    onCancel()
                                    form.resetFields()
                                }}>Cancel</Button>
                                <Button type='primary' htmlType='submit' loading={isLoading}>{Object.keys(installments[0]).length > 0 ? 'Update' : 'Create'}</Button>
                            </div>
                        </Form>
                    </>
            }
        </div>
    )
}

export default CollectionPlans