import { message } from 'antd';
import { userAccountsColumns } from '../utils/tableColumns'
import DataTable from './dataTable'
import helper from '../utils/helper';
import { getUserInfoApi } from '../utils/apisConsts';
import { errorMessage } from '../utils/commonStringsConsts';
import { useDispatch, useSelector } from 'react-redux';
import { getDataInfo, stepperMoving } from '../stores/actions';
import { useState } from 'react';
import { ITableCol } from '../utils/commonInterfaces';

export default function UserAccounts() {
    const dispatch = useDispatch();
    const userAccounts = useSelector((state: ITableCol) => state.actionsReducer.userAccounts) as ITableCol[];
    let current = useSelector((state: any) => state.actionsReducer.stepNumber);
    const [mouseLoader, setMouseLoader] = useState(false);
    const getUserData = async (row: string, isUserID?: boolean) => {
        setMouseLoader(true);
        const params = {
            userID: isUserID ? row : '',
            phone: !isUserID ? row : '',
        }
        try {
            const apiData: any = await helper.callApiRequest(getUserInfoApi, 'GET', params);
            const res = apiData.data;
            if (res?.status === 200) {
                dispatch(getDataInfo(res?.userInfo));
                dispatch(stepperMoving(current + 1))
                return
            }
            message.error(res?.message, 5000)
        } catch (error) {
            message.error(errorMessage, 3000)
        }
        setMouseLoader(false);
    }
    const assignUserID: ITableCol = {
        title: 'User ID',
        dataIndex: 'userID',
        render: (userID: string) => <span style={mouseLoader ? { textDecoration: 'underLine', cursor: 'progress' } :
            { textDecoration: 'underLine', cursor: 'pointer' }} onClick={() => !mouseLoader && getUserData(userID, true)}>{userID}</span>
    }
    const assignPhoneNumber: ITableCol = {
        title: 'User Phone',
        dataIndex: 'phone',
        render: (phoneNumber: string) => <span style={mouseLoader ? { textDecoration: 'underLine', cursor: 'progress' } :
            { textDecoration: 'underLine', cursor: 'pointer' }} onClick={() => !mouseLoader && getUserData(phoneNumber)}>{phoneNumber}</span>
    }
    const columns: ITableCol[] = [assignUserID, assignPhoneNumber, ...userAccountsColumns];
    return (
        <>
            <div style={mouseLoader ? { marginTop: '15px', cursor: 'progress' } : { marginTop: '15px' }}>
                <DataTable columns={columns} rows={userAccounts}
                    pagination={{}} loading={false} scrolling={{ x: 100, y: 300 }} />
            </div>
        </>

    )
}


