import {
  Form,
  Row,
  Col,
  Input,
  FormInstance,
  message,
  Upload,
  Select,
  Button,
  DatePicker,
} from "antd";
import React, { Component } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import helper from "../../utils/helper";
import { connect } from "react-redux";
import { patchApi, stepperMoving, getApi } from "../../stores/actions";
import moment from "moment";
import UserInfoUI from '../../components/uiInfo';
const { Option } = Select;

interface Props {
  info?: {
    name: string;
    firstName: string;
    middleName: string;
    lastName: string;
    staff_id: string;
    email: string;
    nationalIDNumber: string;
    consent: any;
    nationalId: any;
    hrLetter: any;
    userID: number;
    companyName: string;
    netSalary: number;
    bankAccountHolderName: string
    bankAccountHolderNameEn: string,
    bankAccountNumber: string
    bankName: string;
    phone: string;
    hiringDate: string,
    jobTitle: string,
    companyID: number,
    department: any,
    bankID: number,
  };
  patchApi: any;
  stepperMoving: any;
  getApi: any;
}
export class EditUserInfo extends Component<Props> {

  state = {
    imageFile: [],
    hrLetterFiles: "",
    concentFiles: "",
    backNationalIdFiles: "",
    frontNationalIdFiles: "",
    oldHrLetterFiles: this.props.info && Array.isArray(this.props.info.hrLetter) && this.props.info.hrLetter[0] ? this.props.info.hrLetter : [""],
    oldConcentFiles: this.props.info && Array.isArray(this.props.info.consent) && this.props.info.consent[0] ? this.props.info.consent : [""],
    oldFrontNationalIdFiles: this.props.info
      ? this.props.info.nationalId[0] === ""
        ? [""]
        : [this.props.info.nationalId[0]]
      : [""],
    oldBackNationalIdFiles: this.props.info
      ? this.props.info.nationalId[1] === ""
        ? [""]
        : [this.props.info.nationalId[1]]
      : [""],
    sending: false,
    banks: [],
    bankInitialValue: null,
    departments: []
  };

  formRef = React.createRef<FormInstance>();
  componentDidMount() {
    this.getBanks()
    this.getCompanyDepartments()
  }
  getBanks = async () => {
    this.setState({ loadData: true })
    const drops = await this.props.getApi('/users/banks');
    const res = await drops.payload;
    console.log("🚀 ~ file: userInfoForm.tsx ~ line 72 ~ EditUserInfo ~ getBanks= ~ res", res)
    try {
      if (res.status === 200) {
        const bankObj: { bankID: number } = res.banks.filter((bank: any) => this.props.info?.bankName === bank.bankName)[0]
        this.setState({ banks: res.banks, loadData: false, outboundData: false, bankInitialValue: bankObj ? bankObj.bankID : null }, () => {
          this.formRef.current!.setFieldsValue({ bankID: this.state.bankInitialValue })
        })
      } else {
        this.setState({ loadData: false, outboundData: false, errorMode: true })
      }
    } catch (error) {
      this.setState({ loadData: false, outboundData: false, errorMode: true })
    }
  }

  checkDocsChanged = () => {
    let updatedDocs: string[] = []
    if (this.props.info) {
      if (this.state.oldConcentFiles[0] !== this.props.info.consent[0] || this.state.concentFiles) {
        updatedDocs.push("consent")
      }
      if (this.state.oldHrLetterFiles[0] !== this.props.info.hrLetter[0] || this.state.hrLetterFiles) {
        updatedDocs.push("hrLetter")
      }
      if ((this.state.oldFrontNationalIdFiles[0] !== this.props.info.nationalId[0] || this.state.frontNationalIdFiles) && !updatedDocs.includes("nationalID")) {
        updatedDocs.push("nationalID")
      }
      if ((this.state.oldBackNationalIdFiles[0] !== this.props.info.nationalId[1] || this.state.backNationalIdFiles) && !updatedDocs.includes("nationalID")) {
        updatedDocs.push("nationalID")
      }
    }
    return updatedDocs;
  }

  updateUser = async (values: any) => {
    console.log("🚀 ~ file: userInfoForm.tsx ~ line 87 ~ EditUserInfo ~ updateUser= ~ values", values)
    this.setState({ sending: true });
    const data: any = {
      userID: this.props.info && this.props.info.userID,
      firstName: values.firstName ?? this.props.info?.firstName,
      middleName: values.middleName ?? this.props.info?.middleName,
      lastName: values.lastName ?? this.props.info?.lastName,
      email: values.email ?? this.props.info?.email,
      nationalIdNumber: values.nationalId ?? this.props.info?.nationalIDNumber,
      phone: values.phone ?? this.props.info?.phone,
      bankID: values.bankID ?? this.props.info?.bankID,
      accountHolderName: values.accountHolderName ?? this.props.info?.bankAccountHolderName,
      accountHolderNameEn: values.accountHolderNameEn ?? this.props.info?.bankAccountHolderNameEn,
      accountNumber: values.accountNumber ?? this.props.info?.bankAccountNumber,
      netSalary: values.netSalary ?? this.props.info?.netSalary,
      nationalIdFront:
        this.state.oldFrontNationalIdFiles[0]
          ? this.state.oldFrontNationalIdFiles
          : this.state.frontNationalIdFiles,
      nationalIdBack:
        this.state.oldBackNationalIdFiles[0]
          ? this.state.oldBackNationalIdFiles
          : this.state.backNationalIdFiles,
      consent:
        this.state.oldConcentFiles[0]
          ? this.state.oldConcentFiles
          : this.state.concentFiles,
      hrLetter:
        this.state.oldHrLetterFiles[0]
          ? this.state.oldHrLetterFiles
          : this.state.hrLetterFiles,
      staff_id: values.staff_id ?? this.props.info?.staff_id,
      updatedDocs: this.checkDocsChanged().length > 0 ? this.checkDocsChanged() : [" "],
      hiringDate: values.hiringDate ? helper.changeDateFormat(values.hiringDate) : helper.changeDateFormat(this.props.info?.hiringDate),
      jobTitle: values.jobTitle ?? this.props.info?.jobTitle,
      departmentID: values.departmentID ? values.departmentID : this.props.info?.department ? this.props.info?.department.id : null
    };
    const formData = new FormData();
    Object.keys(data).map((key, _index) => {
      //append all data to Form data [used when we uploads files with data]
      formData.append(key, data[key]);
      return 0;
    });
    const updateUser = await this.props.patchApi(
      "/users/updateUserInfo",
      formData
    );
    const res = await updateUser.payload;
    try {
      if (res.status === 200) {
        this.setState({ sending: false });
        message.success(res.message, 4);
        helper.resetAccountTableInStepper();
        this.props.stepperMoving(0);
      } else {
        this.setState({ sending: false });
        message.error(res.message, 4);
      }
    } catch (error) {
      this.setState({ sending: false });
      message.error("Something went wrong, please try again", 4);
    }
    console.log(
      "🚀 ~ file: editUserInfo.tsx ~ line 59 ~ EditUserInfo ~ updateUser= ~ data",
      data
    );
    console.log(
      "🚀 ~ file: userInfoForm.tsx ~ line 62 ~ EditUserInfo ~ updateUser= ~ formData",
      formData
    );
  };


  normFileHr = (e: any) => {
    this.setState({ hrLetterFiles: e.fileList.length > 0 ? e.file.originFileObj : "" });
    return e.fileList || e.file;
  };
  normFileConcent = (e: any) => {
    this.setState({ concentFiles: e.fileList.length > 0 ? e.file.originFileObj : "" });
    return e.fileList || e.file;
  };
  normFileBackId = (e: any) => {
    this.setState({ backNationalIdFiles: e.fileList.length > 0 ? e.file.originFileObj : "" });
    return e.fileList || e.file;
  };
  normFileFrontId = (e: any) => {
    this.setState({ frontNationalIdFiles: e.fileList.length > 0 ? e.file.originFileObj : "" });
    return e.fileList || e.file;
  };
  deleteOldPhoto = (idx: any, arrName: any) => {
    var array = [...[arrName]];
    if (idx !== -1) {
      array.splice(idx, 1);
      this.setState({ [arrName]: array }, () => {
        console.log(
          "🚀 ~ file: editUserInfo.tsx ~ line 149 ~ EditUserInfo ~ this.setState ~ arrName",
          this.state.oldFrontNationalIdFiles
        );
      });
    }
  };

  getCompanyDepartments = async () => {
    try {
      this.setState({ loadData: true })
      const departs: any = await helper.getDepartments({ companyID: this.props.info?.companyID })
      if (departs.data.status === 200) {
        this.setState({ loadData: false, departments: departs.data.departments })
      }
      else {
        this.setState({ loadData: false, errorMode: true })
      }
    }
    catch (error) {
      console.log("🚀 ~ file: userInfoForm.tsx:233 ~ EditUserInfo ~ getCompanyDepartments= ~ error:", error)
      this.setState({ loadData: false, errorMode: true })
    }
  }


  render() {
    const { info } = this.props;
    const {
      oldHrLetterFiles,
      oldConcentFiles,
      oldBackNationalIdFiles,
      oldFrontNationalIdFiles,
      sending,
      banks,
      bankInitialValue,
      departments
    } = this.state;
    const validation: any = helper.regexValidation;
    const basicInfo = [
      {
        key: 'Name', value: <Row>
          <Col span="8">
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: "First name mandatory!" },
              ]}
              initialValue={info?.firstName}
            >
              <Input placeholder="Enter the first name" />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              name={"middleName"}
              rules={[
                {
                  required: true,
                  message: "Group name is mandatory",
                },
              ]}
              initialValue={info?.middleName}
            >
              <Input placeholder="Enter the middle name" />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: "lastName is mandatory!" },
              ]}
              initialValue={info?.lastName}
            >
              <Input placeholder="Enter the last name" />
            </Form.Item>
          </Col>
        </Row>
      },
      { key: 'Company', value: info?.companyName ? info?.companyName : "NA" },
      {
        key: 'Staff ID',
        value: <Form.Item
          name="staff_id"
          rules={[
            { required: true, message: "Staff Id is mandatory!" },
          ]}
          initialValue={info?.staff_id}
        >
          <Input placeholder="Enter the Staff Id" />
        </Form.Item>
      },
      {
        key: 'Job Title',
        value: <Form.Item
          name="jobTitle"
          initialValue={info?.jobTitle}
        >
          <Input placeholder="Enter the job title" />
        </Form.Item>
      },
      {
        key: 'Department',
        value: <Form.Item
          name="departmentID"
          initialValue={this.props.info?.department?.nameEn}
        // rules={[{ required: true, message: 'Required' }]}
        >
          <Select style={{ width: '90%' }}
            placeholder="Select a department name">
            {Array.isArray(departments) && departments.map((department: any, key: number) => {
              return <Option value={department.id} key={key}>{department.nameEn}</Option>
            })}
          </Select>
        </Form.Item>
      },
      {
        key: 'Hiring Date',
        value: <Form.Item
          name="hiringDate"
          initialValue={info?.hiringDate ? moment(info?.hiringDate) : null}>
          <DatePicker placeholder="Enter the hiring date" style={{ width: '90%' }} />
        </Form.Item>
      }
    ]

    const personalInfo = [
      {
        key: 'Phone',
        value: <Form.Item
          name={'phone'}
          rules={[
            { required: true, message: "Phone is mandatory" },
          ]}
          initialValue={info?.phone ? info?.phone : 'NA'}
        >
          <Input placeholder='Enter your phone ' style={{ width: '90%' }} />
        </Form.Item>
      },
      {
        key: 'Email',
        value: <Form.Item
          name="email"
          rules={[
            // { required: true, message: "Email is mandatory!" },
            {
              pattern: validation.email,
              message: "Email is not valid",
            },
          ]}
          initialValue={info?.email}
        >
          <Input placeholder="Enter the email" />
        </Form.Item>
      },
      {
        key: 'National ID',
        value: <Form.Item
          name="nationalId"
          rules={[
            {
              pattern: validation.nationalId,
              message: "National id is not valid",
            },
          ]}
          /** info.nationalIDNumber.includes('null') ? 'NA' : info.nationalIDNumber */
          initialValue={info?.nationalIDNumber && info?.nationalIDNumber}
        >
          <Input placeholder="Enter the National Id" />
        </Form.Item>
      },
    ]

    const bankInfo = [
      {
        key: 'Bank Name',
        value: <Form.Item
          name="bankID"
          initialValue={bankInitialValue}
          rules={[{ required: true, message: 'Required' }]}
        >
          <Select style={{ width: '90%' }}
            placeholder="Select a bank name">
            {banks.map((bank: any, key: number) => {
              return <Option value={bank.bankID} key={key}>{bank.bankName}</Option>
            })}
          </Select>
        </Form.Item>
      },
      {
        key: 'Bank Account holder Name',
        value: <Form.Item
          name="accountHolderName"
          rules={[
            { required: true, message: "Account name is mandatory!" },
          ]}
          initialValue={info?.bankAccountHolderName}
        >
          <Input placeholder="Enter the account holder name" />
        </Form.Item>
      },
      {
        key: 'Bank Account holder Name (English)',
        value: <Form.Item
          name="accountHolderNameEn"
          rules={[
            { pattern: helper.regexValidation.englishRegex, message: 'Account name (English) must be in english' },
            { min: 3, message: `Account name (English) can't be less than 3 chars` },
            { max: 250, message: `Account name (English) can't be more than 250 chars` },
          ]}
          initialValue={info?.bankAccountHolderNameEn}
        >
          <Input placeholder="Enter the account holder name in english" />
        </Form.Item>
      },
      {
        key: 'Bank Account Number',
        value: <Form.Item
          name="accountNumber"
          rules={[
            { required: true, message: "Account number is mandatory!" },
          ]}
          initialValue={info?.bankAccountNumber}
        >
          <Input placeholder="Enter the account number" />
        </Form.Item>
      },
      // { key: 'Meeza Card Status', value:  },
    ]

    // const salaryInfo = [
    //   {
    //     key: 'Net Salary',
    //     value: <Form.Item
    //       name="netSalary"
    //       rules={[
    //         { required: true, message: "Net salary is mandatory!" },
    //       ]}
    //       initialValue={info?.netSalary ? info?.netSalary : ''}
    //     >
    //       <Input placeholder="Enter the net salary" />
    //     </Form.Item>
    //   }
    // ]

    const documents = [
      {
        key: 'Hr Letter',
        value: <>
          {!oldHrLetterFiles[0] && (
            <Form.Item
              name="hrLetterFiles"
              valuePropName="fileList"
              getValueFromEvent={this.normFileHr}
              initialValue={oldHrLetterFiles[0] ? oldHrLetterFiles : []}
            >
              <Upload
                {...helper.dataFile}
                maxCount={1}
                name="logo"
                listType="picture"
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          )}
          <div>
            {(Array.isArray(oldHrLetterFiles)) && oldHrLetterFiles[0] &&
              oldHrLetterFiles.map((file: any, index: any) => {
                return (
                  <>
                    <div
                      className="oldPhoto"
                      style={{
                        border: "1px solid #ddd",
                        padding: "7px 15px",
                        width: "327px",
                        height: " 65px",
                        margin: "10px 0",
                      }}
                    >
                      <img src={file} alt="Hr" width={34} height={50} />
                      <span
                        style={{
                          textAlign: "center",
                          margin: "0 23px",
                          justifyContent: "center",
                        }}
                      >
                        Hr Letter
                      </span>
                      <span
                        style={{
                          float: "right",
                          margin: "10px -3px",
                          color: "red",
                        }}
                      >
                        <DeleteOutlined
                          onClick={() => {
                            this.deleteOldPhoto(
                              index,
                              "oldHrLetterFiles"
                            );
                          }}
                        />
                      </span>
                    </div>
                  </>
                );
              })}
          </div>
        </>
      },
      {
        key: 'Consent',
        value: <>
          {!oldConcentFiles[0] && (
            <Form.Item
              name="concentFiles"
              valuePropName="fileList"
              getValueFromEvent={this.normFileConcent}
              initialValue={oldConcentFiles[0] ? oldConcentFiles : []}
            // rules={[{ required: oldConcentFiles.length === 0 ? true : false, message: 'Required' }]}
            >
              <Upload
                {...helper.dataFile}
                maxCount={1}
                name="logo"
                listType="picture"
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          )}
          {(Array.isArray(oldConcentFiles)) && oldConcentFiles[0] &&
            oldConcentFiles.map((file: any, index: any) => {
              return (
                <>
                  <div
                    className="oldPhoto"
                    style={{
                      border: "1px solid #ddd",
                      padding: "7px 15px",
                      width: "327px",
                      height: " 65px",
                      margin: "10px 0",
                    }}
                  >
                    <img src={file} alt="Hr" width={34} height={50} />
                    <span
                      style={{
                        textAlign: "center",
                        margin: "0 23px",
                        justifyContent: "center",
                      }}
                    >
                      Consent{" "}
                    </span>
                    <span
                      style={{
                        float: "right",
                        margin: "10px -3px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined
                        color="red"
                        onClick={() => {
                          this.deleteOldPhoto(index, "oldConcentFiles");
                        }}
                      />
                    </span>
                  </div>
                </>
              );
            })}
        </>
      },
      {
        key: 'Front of National ID',
        value: <>
          {!oldFrontNationalIdFiles[0] && (
            <Form.Item
              name="frontNational"
              valuePropName="fileList"
              getValueFromEvent={this.normFileFrontId}
            // rules={[{ required: oldFrontNationalIdFiles.length === 0 ? true : false, message: 'Required' }]}
            >
              <Upload
                {...helper.dataFile}
                maxCount={1}
                name="logo"
                listType="picture"
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          )}
          {(Array.isArray(oldFrontNationalIdFiles)) && oldFrontNationalIdFiles[0] &&
            oldFrontNationalIdFiles.map((file: any, index: any) => {
              return (
                <>
                  <div
                    className="oldPhoto"
                    style={{
                      border: "1px solid #ddd",
                      padding: "7px 15px",
                      width: "327px",
                      height: " 65px",
                      margin: "10px 0",
                    }}
                  >
                    <img src={file} alt="Hr" width={34} height={50} />
                    <span
                      style={{
                        textAlign: "center",
                        margin: "0 23px",
                        justifyContent: "center",
                      }}
                    >
                      Front National Id{" "}
                    </span>
                    <span
                      style={{
                        float: "right",
                        margin: "10px -3px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined
                        onClick={() => {
                          this.deleteOldPhoto(
                            index,
                            "oldFrontNationalIdFiles"
                          );
                        }}
                      />
                    </span>
                  </div>
                </>
              );
            })}
        </>
      },
      {
        key: 'Back of National ID',
        value: <>
          {!oldBackNationalIdFiles[0] && (
            <Form.Item
              name="backNational"
              valuePropName="fileList"
              getValueFromEvent={this.normFileBackId}
            // rules={[{ required: oldBackNationalIdFiles.length === 0 ? true : false, message: 'Required' }]}
            >
              <Upload
                {...helper.dataFile}
                maxCount={1}
                name="logo"
                listType="picture"
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          )}
          {(Array.isArray(oldBackNationalIdFiles)) && oldBackNationalIdFiles[0] &&
            oldBackNationalIdFiles.map((file: any, index: any) => {
              return (
                <>
                  <div
                    className="oldPhoto"
                    style={{
                      border: "1px solid #ddd",
                      padding: "7px 15px",
                      width: "327px",
                      height: " 65px",
                      margin: "10px 0",
                    }}
                  >
                    <img src={file} alt="Hr" width={34} height={50} />
                    <span
                      style={{
                        textAlign: "center",
                        margin: "0 23px",
                        justifyContent: "center",
                      }}
                    >
                      Back National Id{" "}
                    </span>
                    <span
                      style={{
                        float: "right",
                        margin: "10px -3px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined
                        onClick={() => {
                          this.deleteOldPhoto(
                            index,
                            "oldBackNationalIdFiles"
                          );
                        }}
                      />
                    </span>
                  </div>
                </>
              );
            })}
        </>
      }
    ]
    return (
      <div className="user">
        <Form
          name="nest-messages"
          ref={this.formRef}
          onFinish={this.updateUser}
        >
          {info && (
            <>
              <Row>
                <Col span="24">
                  <UserInfoUI userData={basicInfo} activeKey={1} header={'Basic Information'} nonEditable={true} />
                </Col>
                <Col span="24">
                  <UserInfoUI userData={personalInfo} activeKey={2} header={'Personal Information'} nonEditable={true} />
                </Col>
                <Col span="24">
                  <UserInfoUI userData={bankInfo} activeKey={4} header={'Bank Information'} nonEditable={true} />
                </Col>
                {/* <Col span="24">
                  <UserInfoUI userData={salaryInfo} activeKey={5} header={'Salary Information'} nonEditable={true} />
                </Col> */}
                <Col span="24">
                  <UserInfoUI userData={documents} activeKey={6} header={'Required Documents'} nonEditable={true} />
                </Col>
              </Row>

              <Row>
                <Col className="colOfAssign" span="24">
                  <Form.Item style={{ marginTop: "30px", float: "right" }}>
                    <Button
                      className="updateUserBtn"
                      type="primary"
                      block
                      shape="round"
                      htmlType="submit"
                      loading={sending}
                      style={{ backgroundColor: "#7CC58F", borderColor: "#7CC58F" }}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  info: state.actionsReducer.info,
});
export default connect(mapStateToProps, { patchApi, stepperMoving, getApi })(
  EditUserInfo
);
