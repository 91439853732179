
import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import UserStatus from './userStatus';
import { getUserByPhoneApi } from '../../utils/apisConsts';

class ViewUserStatus extends Component{
    state = {
        componentSteps: [
            {
                title: 'Get User',
                content: <GetInfo apiLink={getUserByPhoneApi} placeHolder='Please select the user company' userStatus={true} isAllCompanies={true}/>,
                id: 0,
            }, 
            {
                title: 'User Status',
                content: <UserStatus />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default ViewUserStatus
