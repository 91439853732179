import { ConfigProvider, Select, Space, Table, Segmented, Tag, Button, Col, Row, Form, message, FormInstance, notification, Input } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useState, useEffect } from 'react';
import TruckingModal from '../modal';
import { useDispatch } from 'react-redux';
import { getApi, patchApi, request } from '../../../stores/actions';
import helper from '../../../utils/helper';
import OutPutResult from '../../../components/result';
import { arabicErrorMessage, truckingApis } from '../../../utils/commonStringsConsts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { getAllTransactionsApi, transactionsAnalyticsApi, updateCompanyRefApi } from '../../../utils/apisConsts';
import PdfComponent from '../../../components/pdf';
import ExpenseTemp from './expenseTemp';
import { EditIcon } from '../../../utils/commonIcons';
interface DataType {
  key?: React.Key;
  name?: string;
  age?: number;
  address?: string;
  id?: any,
}

interface Props {
  analyticsApi?: string
  getDataApi?: string
}

function ViewExpenses(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openTruckingModal, setOpenTruckingModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [spin, setSpin] = useState(false);
  const [errorView, setErrorView] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paginating, setPaginating] = useState({
    pageSize: 10,
    total: 0,
    current: 1,
  })
  const [partnerAccountant, setPartnerAccountant] = useState(false);
  const [filters, setFilters] = useState([]);
  const [oneFilter, setOneFilter] = useState('all');
  const [oneTransaction, setOneTransaction] = useState({
    name: '',
    totalAmount: 0,
    createdAt: '',
    transactionsBreakdown: [],
    userBankID: { bankId: { nameAr: '' } }
  });
  const [canceledTransaction, setCanceledTransaction] = useState({});
  const [cancelStatusSpin, setCancelStatusSpin] = useState(false);
  const [allRows, setAllRows] = useState<DataType[]>([]);
  const [transactionID, setTransactionID] = useState(0);
  const [keys, setKeys] = useState<React.Key[]>([]);
  const [statusList, setStatusList] = useState([]);
  const [modalToPrint, setModalToPrint] = useState(false);
  const [reusedTransaction, setReusedTransaction] = useState({
    company: '',
    totalAmount: '',
    name: '',
    transactionID: 0,
    referenceNumber: 0,
    createdAt: '',
    serviceOne: '',
    serviceTwo: '',
    serviceThree: '',
    serviceFour: '',
    logoURL: '',
    actionBy: {
      firstName: "",
      lastName: "",
      middleName: "",
    },
    transactionsBreakdown: [],
    userID: {
      staffID: '',
      nationalID: '',
      company: {
        logoURL: ''
      }
    },
    // staffID:'',
    companyRef: '',
    id: '',
  });
  const [modalRefNumber, setModalRefNumber] = useState(false);
  const [loaderToUpdate, setLoaderToUpdate] = useState(false);
  const [dashboardURL, setDashboardURL] = useState('');
  const dispatch = useDispatch();
  const formRef: any = React.useRef([]);
  const selectRef = React.createRef<FormInstance>();
  const referenceNumberRef: any = React.useRef([]);
  const getFilters = async () => {

    setSpin(true);
    if (!navigator.onLine) {
      setErrorMessage('أنت غير متصل بالإنترنت');
      setSpin(false);
      setErrorView(true)
      return 0
    }

    const analytics: any = await dispatch
      (getApi(`${truckingApis}${props.analyticsApi ? props.analyticsApi : transactionsAnalyticsApi}`, {}));

    try {
      const res = await analytics.payload;

      if (res.status !== 200) {
        setFilters([])
        return 0
      }
      const changeAnalyticsFormat = Array.isArray(res?.data?.analytics) && res?.data?.analytics.map((analytic: any, key: any) => {
        return {
          label: `${analytic?.nameAr} (${analytic?.count ? analytic?.count : 0})`,
          value: analytic?.nameEn,
          number: analytic?.count,
        }
      })
      const analyticsReversed: any = [...changeAnalyticsFormat].reverse()
      setFilters(analyticsReversed)
      setSpin(false);
    } catch (error: any) {
      setFilters([])
      setErrorMessage('حدث خطأ ')
      setSpin(false)
    }
  }


  const getCurrentFilter = (value: any) => {
    // setPaginating(paginating)
    setOneFilter(value)
    const data = {
      pageSize: paginating.pageSize,
      total: paginating.total,
      current: 1,
      filter: value
    }

    getTransactions(data);
  }

  const getTransactions = async (data?: any) => {
    setErrorView(false)
    setSpin(true);
    if (!navigator.onLine) {
      setErrorMessage('أنت غير متصل بالإنترنت')
      setSpin(false);
      setErrorView(true)
      return 0
    }


    const transactions: any = await dispatch
      (getApi(`${truckingApis}${props.getDataApi ? props.getDataApi : getAllTransactionsApi}?filter=${data.filter}&page=${data.current}&limit=${data.pageSize}`, {}));

    try {
      const res = await transactions.payload;

      if (res.status !== 200) {
        setErrorMessage('حدث خطأ ');
        setSpin(false);
        setErrorView(true)
        return 0
      }
      if (res?.data?.transactions) {
        setDashboardURL(res.data.dashboardURL)
      }
      if (res?.data?.transactions?.length === 0 || res?.data?.transfers?.length === 0) {
        setErrorMessage('لا توجد طلبات');
        setKeys([])
        setSpin(false);
        setRequests(props.getDataApi ? res?.data?.transfers : res?.data?.transactions);

        setAllRows([])
        setStatusList([])

        setTimeout(() => {
          setPaginating((prevState: any) => ({
            ...prevState,
            total: res?.data?.count,
            current: data?.current,
            // pageSize: data.pageSize
          }))
        }, 1);
        // setErrorView(true);
        return 0
      }


      setPartnerAccountant(transactions.payload.data.roles.includes('PartnerAccountant') || transactions.payload.data.roles.includes('Supervisor'))
      let changeDataFormat: any
      if (props.getDataApi) {
        changeDataFormat = res?.data?.transfers.map((transfer: any, key: any) => {
          return {
            ...transfer,
            logoURL: transfer?.user?.company?.logoURL,
            name: transfer?.user?.firstName + ' ' + transfer?.user?.middleName + ' ' + transfer?.user?.lastName,
            company: transfer?.user?.company.name,
            totalAmount: helper.amountFormatter(transfer?.amount) + ` جنيه`,
            createdAt: helper.changeTruckingDateFormat(transfer?.createdAt),
            transactionID: transfer?.referenceNumber,
            status: statusColor({ ...transfer, transactionStatusID: transfer.transferStatus }),
            changeStatus: transfer?.validStatuses,
            accountNumberFrom: transfer?.fromUserBank?.accountNumber,
            cardNumberFrom: transfer?.fromUserBank?.cardNumber,
            nameFrom: transfer?.fromUserBank?.firstName + ' ' + transfer?.fromUserBank?.lastName,
            accountNumberTo: transfer?.toUserBank?.accountNumber,
            cardNumberTo: transfer?.toUserBank?.cardNumber,
            nameTo: transfer?.toUserBank?.firstName + ' ' + transfer?.toUserBank?.lastName,
            companyRef: transfer?.companyRef ?? 'لا يوجد',
            time: moment(transfer?.createdAt).format("mm : HH"),
            key: setTimeout(() => {
              return `${key} ${data.current} ${data.filter}`
            }, 100),
          }
        })
      }
      else {
        changeDataFormat = res?.data?.transactions.map((transaction: any, key: any) => {
          return {
            ...transaction,
            name: transaction?.userID?.firstName + ' ' + transaction?.userID?.middleName + ' ' + transaction?.userID?.lastName,
            logoURL:transaction?.userID?.company?.logoURL,
            phone: transaction?.userID?.phone,
            company: transaction?.userID?.company?.name,
            paymentWay: transaction?.userBankID?.bankId?.nameAr,
            accountNumber: transaction?.userBankID?.accountNumber,
            cardNumber: transaction?.userBankID?.cardNumber,
            totalAmount: helper.amountFormatter(transaction?.totalAmount) + ` جنيه`,
            createdAt: helper.changeTruckingDateFormat(transaction?.createdAt),
            status: statusColor(transaction),
            changeStatus: transaction?.validStatuses,
            accountantName: transaction?.actionBy?.firstName + ' ' + transaction?.actionBy?.lastName,
            transactionID: transaction?.referenceNumber,

            time: moment(transaction?.createdAt).format("mm : HH"),
            key: setTimeout(() => {
              return `${key} ${data.current} ${data.filter}`
            }, 100),
          }
        })
      }
      setKeys([])
      setSpin(false);
      setStatusList(changeDataFormat[0].validStatuses)
      setRequests(changeDataFormat);
      setAllRows([])
      setTimeout(() => {
        setPaginating((prevState: any) => ({
          ...prevState,
          total: res?.data?.count,
          current: data?.current,
          // pageSize: data.pageSize
        }))
      }, 100);


    } catch (error: any) {
      console.log("🚀 ~ file: viewExpenses.tsx:192 ~ getTransactions ~ error:", error)
      setErrorMessage('حدث خطأ ')
      setSpin(false)
      setErrorView(true)
    }
  }
  const statusColor = (transaction: { transactionStatusID: { nameAr: string, id: number } }) => {
    switch (transaction?.transactionStatusID?.id) {
      case 1:
        return <Tag style={{ border: 'none', background: '#F7F7F7', color: '#ADAEAF', fontSize: '15px', width: 'content-fit' }}>{transaction?.transactionStatusID?.nameAr}</Tag>
      case 2:

        return <Tag style={{ border: 'none', background: '#F1F1FC', color: '#4B5BD9', fontSize: '15px', width: 'content-fit' }}>{transaction?.transactionStatusID?.nameAr}</Tag>
      case 3:

        return <Tag style={{ border: 'none', background: '#F4F8F2', color: '#64A743', fontSize: '15px', width: 'content-fit' }}>{transaction?.transactionStatusID?.nameAr}</Tag>
      case 4:

        return <Tag style={{ border: 'none', background: '#FBF1F1', color: '#D75248', fontSize: '15px', width: 'content-fit' }}>{transaction?.transactionStatusID?.nameAr}</Tag>
      case 5:

        return <Tag style={{ border: 'none', background: '#FCF5E5', color: '#FF9300', fontSize: '15px', width: 'content-fit' }}>{transaction?.transactionStatusID?.nameAr}</Tag>
      case 6:

        return <Tag style={{ border: 'none', background: '#F1EEE9', color: '#D0C9C0', fontSize: '15px', width: 'content-fit' }}>{transaction?.transactionStatusID?.nameAr}</Tag>

      default:
        break;
    }
  }
  useEffect(() => {
    setOneFilter('all')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      pageSize: 10,
      total: 0,
      current: 1,
      filter: "all"
    }
    getTransactions(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDataApi])

  useEffect(() => {
    getFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.analyticsApi])

  const rowSelection = {

    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setKeys(selectedRowKeys)
      const rows: DataType[] = selectedRows.map((row: any) => { return row.id });
      setAllRows(rows)

    },
    keys,
  };
  const transactionCancelingOpenModal = (id: any, record: any) => {
    const data = {
      statusID: id,
      ...(!props.getDataApi ? { transactionIDs: [record.id] } :
        { transferIDs: [record.id] }),
    }
    setCanceledTransaction(data)
    setOpenTruckingModal(true)
  }
  const closeModal = () => {
    setOpenModal(false)
  }
  const closeTruckingModal = () => {
    setOpenTruckingModal(false);

    transactionID ? formRef.current[transactionID]!.resetFields() : selectRef.current!?.resetFields();
  }
  const changePagination = async (values: any) => {
    await setPaginating((prevState: any) => ({
      ...prevState,
      current: values?.current,
      pageSize: values.pageSize
    }))

    const data = {
      pageSize: values.pageSize,
      current: values?.current,
      filter: oneFilter
    }
    await getTransactions(data);


  }
  const changeTransactionsStatuses = async (transaction: any, id: any) => {
    setTransactionID(transaction.id)
    const data = {
      ...(!props.getDataApi ? { transactionIDs: transaction.id ? [transaction.id] : allRows } :
        { transferIDs: transaction.id ? [transaction.id] : allRows }),
      statusID: id,
    }
    setCanceledTransaction(data);
    setOpenTruckingModal(true)


  }
  const cancelPartnerAction = async () => {
    setCancelStatusSpin(true)
    const changeStatus: any = await dispatch(patchApi(`${truckingApis}${!props.getDataApi ? "transactions" : "transfers"}/status`, canceledTransaction))
    const res = await changeStatus.payload;


    try {
      if (res.status !== 200) {
        message.error(`لا يمكن تغير هذه الحالة`);
        setCancelStatusSpin(false)
        return
      }
      setCancelStatusSpin(false)
      const data = {
        pageSize: paginating.pageSize,
        current: paginating?.current,
        filter: oneFilter
      }
      getTransactions(data);
      getFilters();
      setOpenTruckingModal(false)
      message.success(`تم تغيير الحاله بنجاح`);

    } catch (error) {
      message.error(`حدث خطأ ما`);
      setCancelStatusSpin(false)
    }
  }
  const showMoreDetail = (record: any) => {
    console.log("🚀 ~ file: viewExpenses.tsx:359 ~ showMoreDetail ~ record:", record)
    setOneTransaction(record)
    setOpenModal(true)

  }
  const showPdfToPrint = (transaction: any) => {
    console.log("🚀 ~ file: viewExpenses.tsx:363 ~ showPdfToPrint ~ transaction:", transaction)
    setModalToPrint(true);
    setReusedTransaction(transaction)
  }
  const closePrintingModal = () => {
    setModalToPrint(false);
  }
  const cancelMultipleRows = () => {
    const data = {
      statusID: 5,
      ...(!props.getDataApi ? { transactionIDs: allRows } :
        { transferIDs: allRows })
    }
    setCanceledTransaction(data);
    setOpenTruckingModal(true)
  }
  const openNotificationWithIcon = (num: any, title: string) => {
    notification['success']({
      message: `تم نسخ ${title} ${num} `,
      placement: 'top',
    });
  };

  const openNumberReferenceModal = async (transaction: any) => {
    console.log("🚀 ~ file: viewExpenses.tsx:416 ~ openNumberReferenceModal ~ transaction:", transaction)
    await setReusedTransaction(transaction);
    await setModalRefNumber(true);
    await referenceNumberRef.current!.resetFields();

  }
  const closeRefModal = () => {
    setModalRefNumber(false);
    referenceNumberRef.current!.resetFields();
  }

  const updateRefNumber = async (values: { companyRef: string, id: number }) => {
    console.log("🚀 ~ file: viewExpenses.tsx:423 ~ updateRefNumber ~ values:", values);
    try {
      setLoaderToUpdate(true);
      const data = {
        companyRef: values.companyRef,
        transactionID: reusedTransaction.id
      }
      const apiRequest: any = await dispatch(request({ url: updateCompanyRefApi, method: 'PATCH', data: data }));
      const resp = apiRequest.data;
      if (resp.status === 200) {
        message.success('تم التعديل بنجاح', 4);
        setLoaderToUpdate(false);
        closeRefModal();
        const dataForTransactions = {
          pageSize: paginating.pageSize,
          current: paginating?.current,
          filter: oneFilter
        }
        getTransactions(dataForTransactions);
        getFilters();
        return

      }
      message.error(resp.message, 4);
      setLoaderToUpdate(false);
    } catch (error) {
      message.error(arabicErrorMessage, 4);
      setLoaderToUpdate(false);
    }

    // referenceNumberRef.current!.resetFields();
  }
  enum columnsTitle {
    requestNumber = ' رقم الطلب',
    senderName = 'اسم الراسل',
    employeeName = 'اسم الموظف',
    companyName = 'اسم الشركة',
    name = 'اسم المستلم',
    phone = 'رقم المستلم',
    paymentWay = 'طريقة الدفع',
    internalDocument = 'السند الداخلي',
    showMore = 'تفاصيل',
    status = 'حالة العمليه',
    createAt = 'تاريخ العمليه',
    time = 'وقت العمليه',
    print = 'طباعه',
    changeStatus = 'تغيير الحالة',
    AccountNumber = 'رقم الحساب',
    SenderAccountNumber = 'رقم كارت الراسل',
    cardNumber = 'رقم الكارت',
    senderCardNumber = 'رقم كارت الراسل',
    totalAmount = 'اجمالي المصروفات'
  }
  const senderColumns: any = [
    {
      title: `${columnsTitle.senderName}`,
      dataIndex: 'nameFrom',
    },
    (!partnerAccountant && requests.length ? {
      title: `${columnsTitle.SenderAccountNumber}`,
      render: (_text: any, record: any) =>
        <>
          <CopyToClipboard text={record.accountNumberFrom} onCopy={() => openNotificationWithIcon(record.accountNumberFrom, columnsTitle.SenderAccountNumber)}>
            <span style={{ textDecoration: 'underLine', cursor: 'pointer' }}>{record.accountNumberFrom}</span>

          </CopyToClipboard>
        </>
      ,
    } : {}),
    {
      title: `${columnsTitle.senderCardNumber}`,
      render: (_text: any, record: any) =>
        <>
          <CopyToClipboard text={record.cardNumberFrom} onCopy={() => openNotificationWithIcon(record.cardNumberFrom, columnsTitle.senderCardNumber)}>
            <span style={{ textDecoration: 'underLine', cursor: 'pointer' }}>{record.cardNumberFrom}</span>

          </CopyToClipboard>
        </>
      ,
    },
  ]

  const columns: ColumnsType<DataType> = [

    {
      title: `${columnsTitle.requestNumber} `,
      dataIndex: 'transactionID',
    },
    {
      title: `${columnsTitle.employeeName}`,
      dataIndex: !props.getDataApi ? 'accountantName' : "name",
    },
    {
      title: `${columnsTitle.companyName}`,
      dataIndex: 'company',
    },
    ...(props.getDataApi ? senderColumns : []),
    {
      title: `${columnsTitle.name}`,
      dataIndex: props.getDataApi ? "nameTo" : 'name',
    },
    (!props.getDataApi ? {
      title: `${columnsTitle.phone}`,
      dataIndex: 'phone',
    } : {}),
    (!props.getDataApi ? {
      title: `${columnsTitle.paymentWay}`,
      dataIndex: 'paymentWay',
    } : {}),
    (!partnerAccountant && requests.length ? {
      title: `${columnsTitle.AccountNumber}`,
      render: (_text: any, record: any) =>
        <>
          <CopyToClipboard text={props.getDataApi ? record.accountNumberTo : record.accountNumber} onCopy={() => openNotificationWithIcon(props.getDataApi ? record.accountNumberTo : record.accountNumber, columnsTitle.AccountNumber)}>
            <span style={{ textDecoration: 'underLine', cursor: 'pointer' }}>{props.getDataApi ? record.accountNumberTo : record.accountNumber}</span>

          </CopyToClipboard>
        </>
      ,
    } : {}),
    {
      title: `${columnsTitle.cardNumber}`,
      render: (_text: any, record: any) =>
        <>
          <CopyToClipboard text={props.getDataApi ? record.cardNumberTo : record.cardNumber} onCopy={() => openNotificationWithIcon(props.getDataApi ? record.cardNumberTo : record.cardNumber, columnsTitle.cardNumber)}>
            <span style={{ textDecoration: 'underLine', cursor: 'pointer' }}>{props.getDataApi ? record.cardNumberTo : record.cardNumber}</span>

          </CopyToClipboard>
        </>
      ,
    },
    {
      title: `${columnsTitle.internalDocument}`,
      dataIndex: '',
      render: (_text: any, record: any) =>
        <>
          <span>{record.companyRef ?? 'اضافه سند'}</span>&nbsp;&nbsp;
          {partnerAccountant && <span onClick={() => openNumberReferenceModal(record)} style={{ cursor: 'pointer' }}>{EditIcon}</span>}

        </>

    },
    {
      title: `${columnsTitle.totalAmount}`,
      dataIndex: 'totalAmount',
      render: (_text: any, record: any) =>
        <>
          <CopyToClipboard text={helper.getNumberFromString(record.totalAmount, 'جنيه')} onCopy={() => openNotificationWithIcon(record.totalAmount, columnsTitle.totalAmount)}>
            <span style={{ textDecoration: 'underLine', cursor: 'pointer' }}>{record.totalAmount}</span>
          </CopyToClipboard>
        </>
      ,
    },
    {
      title: `${columnsTitle.time}`,
      dataIndex: 'time',

    },
    {
      title: `${columnsTitle.createAt}`,
      dataIndex: 'createdAt',
    },
    {
      title: `${columnsTitle.status}`,
      dataIndex: 'status',
    },
    (!props.getDataApi ? {
      title: `${columnsTitle.showMore}`,
      key: 'showMore',
      width: 90,
      render: (_text: any, record: any) =>
        <>
          <span onClick={() => showMoreDetail(record)} style={{ textDecoration: 'underLine', cursor: 'pointer' }}>تفاصيل</span>
        </>
      ,
    } : {}),
    {
      title: `${columnsTitle.print}`,
      width: 90,
      render: (_text: any, record: any) =>
        <>
          <span onClick={() => showPdfToPrint(record)} style={{ textDecoration: 'underLine', cursor: 'pointer' }}>طباعه</span>
        </>
      ,
    },
    {
      title: (partnerAccountant ? '' : `${columnsTitle.changeStatus}`),
      key: 'changeStatus',
      width: 90,
      // fixed:true,
      render: (_text: any, record: any) => ((
        partnerAccountant ?
          <Space size="middle" style={{ color: 'blue' }}>
            <>
              {record.transactionStatusID === 7 || record.transactionStatusID === 5 ? <span>{record.requestStatus}</span>
                :
                <>
                  {Array.isArray(record.validStatuses) &&
                    record.validStatuses.map((industry: any, key: any) => {
                      return (industry.id === 5 && industry.isValid && allRows.length <= 1) ? <span key={key} style={{ color: 'red', cursor: 'pointer' }} onClick={() => transactionCancelingOpenModal(industry.id, record)}>الغاء</span> : ''

                    })}
                </>
              }
            </>

          </Space>
          :
          <>
            {(allRows.length <= 1 || record.validStatuses.length === 0 || oneFilter === 'all') && <Form name="nest-messages"
              ref={(el: any) => (formRef.current[record.id] = el)}>
              {<Form.Item
                name={"status" + record.id}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select style={{ width: "100%" }} placeholder="اختار الحاله" onChange={(e) => changeTransactionsStatuses(record, e)} >
                  {

                    Array.isArray(record.validStatuses) &&
                    record.validStatuses.map((industry: any) => {
                      return (industry.isValid && <Select.Option allowClear value={industry.id}>{industry.nameAr}</Select.Option>)
                    })
                  }

                </Select>
              </Form.Item>}
            </Form>}
          </>
      )
      ),
    },

  ];


  const content = <>
    <div className='declineRequest'>

      <p className='question'>هل انت متأكد من تغيير حالة الطلب ؟</p>
      <Button className='decline' onClick={closeTruckingModal}>لا اريد </Button>
      <Button className='approve' loading={cancelStatusSpin} onClick={cancelPartnerAction}>نعم , اريد تغيير حالة الطلب</Button>
    </div>
  </>
  let orderReviewContent = <>
    <div className='reviewDriverOrder'>
      <div className="driverExpenses">
        <h3>بيانات المستلم</h3>

        <Row>
          <Col xs={24} md={12} lg={12}>
            <div className='info'>
              <h4>الاسم</h4>
              {oneTransaction?.name}
            </div>
          </Col>
          <Col span={6}>
            <div>
              <h4 style={{ display: 'flex' }}>المبلغ الكلي</h4>
              {helper.amountFormatter(oneTransaction?.totalAmount)}
            </div>
          </Col>
          <Col span={6}>
            <div>
              <h4 style={{ display: 'flex' }}>طريقة الصرف</h4>
              {oneTransaction?.userBankID?.bankId?.nameAr}
            </div>
          </Col>
          <Col span={6}>
            <div>
              <h4 style={{ display: 'flex' }}>التاريخ</h4>
              {oneTransaction?.createdAt}
            </div>
          </Col>
        </Row>

      </div>
      {/* <hr /> */}
      <div className="driverExpenses">
        <h3> المدفوعات</h3>
        <Row>
          {Array.isArray(oneTransaction.transactionsBreakdown) && oneTransaction.transactionsBreakdown.map((oneSummary: any, key: any) => {
            return (
              <Col key={key} span={6}>
                <h4>{oneSummary.serviceID.nameAr}</h4>
                <p>{helper.amountFormatter(oneSummary.amount)} جنيه</p>
              </Col>
            )
          })}
        </Row>
      </div>
    </div>
    <div className='btns' style={{ float: 'left' }}>
      <Button className='decline' onClick={closeModal}>الرجوع الي إدارة التحويلات</Button>
    </div>
  </>


  const updateRefNumberContent = <>
    <div className='declineRequest'>
      <Form name="nest-messages" onFinish={updateRefNumber} ref={referenceNumberRef}>
        <label>السندالداخلي</label>
        <Form.Item
          style={{ padding: '15px 0' }}
          initialValue={reusedTransaction?.companyRef}
          name={"companyRef"}
          rules={[{ required: true, message: 'اجباري' }]}
        >
          <Input placeholder='اضافة سند داخلي' />
        </Form.Item>
        <Form.Item>
          <Button className='decline' onClick={closeRefModal}>رجوع</Button>
          <Button className='approve' htmlType="submit" loading={loaderToUpdate}>تعديل السند الداخلي</Button>
        </Form.Item>
      </Form>
    </div>
  </>

  return (
    <>



      <ConfigProvider direction='rtl'>



        <div className="filter">
          {!props.getDataApi && <button className="dashboardBtn" onClick={() => helper.setRedirect(dashboardURL ? dashboardURL : 'https://reports.nowpay.cloud/dashboard/17')}>التقارير </button>}
          <Row>
            <Col>
              <div className="box">
                <Segmented onChange={getCurrentFilter} options={filters} defaultValue={'all'} />
              </div>
            </Col>
            {(partnerAccountant && oneFilter === 'created' && allRows.length > 1) && <Col span={12}>
              <Button onClick={cancelMultipleRows} style={{
                float: 'left', color: 'rgb(243, 36, 36)', background: 'rgb(255, 195, 195)',
                border: '1px solid rgb(243, 36, 36)', width: '170px', height: '40px', borderRadius: '5px'
              }}>الغاء</Button>
            </Col>}

          </Row>
          {(!partnerAccountant && oneFilter !== 'all' && allRows.length > 1) && <Form name="nest-messages"
            ref={selectRef}>
            <Form.Item

              initialValue={undefined}
              name={"status"}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select style={{ width: "20%", margin: '20px 0 0 0 ' }} placeholder="اختار الحاله" onChange={(e) => changeTransactionsStatuses(allRows, e)} >
                {

                  Array.isArray(statusList) &&
                  statusList.map((industry: any) => {
                    return industry.isValid && <Select.Option allowClear value={industry.id}>{industry.nameAr}</Select.Option>
                  })
                }

              </Select>
            </Form.Item>
          </Form>}
        </div>
        {errorView ? <OutPutResult status={'500'} title={errorMessage} subTitle={' '}
          btnTitle={'إعادة تحميل الصفحه'} btnClick={helper.refreshPage} /> : <div className='expenses'>
          <Table
            pagination={paginating}
            onChange={changePagination}
            rowSelection={rowSelection}
            columns={columns}
            direction='rtl'
            dataSource={requests}
            loading={spin}
            scroll={{ x: 'max-content' }}
          />
        </div>}
        <TruckingModal handleCloseModal={closeModal} visibleModal={openModal} content={orderReviewContent} title={'تفاصيل الطلب'} />
        <TruckingModal handleCloseModal={closeTruckingModal} visibleModal={openTruckingModal} content={content} title={'تغيير حالة الطلب'} />
        <TruckingModal handleCloseModal={closeRefModal} visibleModal={modalRefNumber} content={updateRefNumberContent} title={'تعديل السند الداخلي'} />

        <TruckingModal handleCloseModal={closePrintingModal} visibleModal={modalToPrint} content={<PdfComponent fileName={'سند تحول الاموال'} btnName={'طباعه'} content={<ExpenseTemp transaction={reusedTransaction} />} />} title={'طباعة الطلب'} />
      </ConfigProvider>
    </>
  );
}

export default ViewExpenses