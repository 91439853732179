
import React, { Component } from 'react'
import Stepper from '../../components/stepper';
import GetPaymentPlans from './getPaymentPlans';
import FeesView from './viewFees';

class ViewRequestFees extends Component {
    state = {
        componentSteps: [
            {
                title: 'Get Payment Plans',
                content: <GetPaymentPlans />,
                id: 0,
            }, {
                title: 'Request Fees',
                content: <FeesView />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default ViewRequestFees
