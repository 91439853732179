import { Component } from 'react'
import Stepper from '../../components/stepper';
import BulkUploadRequests from './bulkUploadRequests';
import ViewRecalculatedRequests from './viewRecalculatedRequests';

class BulkRecalculateRequest extends Component {
    state = {
        recalculatedRequests:[],
        recalculationData:{
            companyID:"",
            advanceIDs:[],
            comment: ""
        }
    }

    render() {
        const componentSteps = [
            {
                title: 'Upload Requests',
                content: <BulkUploadRequests 
                setRecalculatedRequests={(recalculatedRequests: any[])=> this.setState({recalculatedRequests})}
                setRecalculationData={(recalculationData: any[])=> this.setState({recalculationData})}
                />,
                id: 0,
            }, {
                title: 'Review and confirm',
                content: <ViewRecalculatedRequests recalculatedRequests={this.state.recalculatedRequests} recalculationData={this.state.recalculationData}/>,
                id: 1
            },
        ]
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default BulkRecalculateRequest