import React, { useState, useEffect, useCallback } from 'react'

import { Form, Row, Col, Select, Input, Button, Upload, message, FormInstance } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import helper from "../utils/helper";
import { connect } from 'react-redux';
import { getApi, postApi } from '../stores/actions';
import { createCompanyGroup, getUngroupedCompanies } from '../utils/apisConsts';
import OutPutResult from '../components/result';
import Spinner from '../components/spinner';

const { Option } = Select;

    let formRef = React.createRef<FormInstance>();

function CreateCompanyGroup(props: { getApi: any, postApi: any }) {
    const [companies, serCompanies] = useState([]);
    const [loader, setLoader] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [spinCreateGroup, setSpinCreateGroup] = useState(false);
    const [images, setImages] = useState([]);


    const getCompanies = useCallback(async () => {
        setLoader(true)
        const ungroupedCompanies = await props.getApi(getUngroupedCompanies);
        try {
            const res = await ungroupedCompanies.payload;
            if (res.status === 200) {
                serCompanies(res.data);
                setLoader(false)
            } else {
                setLoader(false);
                setErrorMode(true);
            }
        } catch (error) {
            setLoader(false);
            setErrorMode(true);
        }
    }, [props])
    useEffect(() => {
        getCompanies()
    }, [getCompanies]);
   
    const createGroupCompany = async (data: any) => {
        setSpinCreateGroup(true);
        const formData = new FormData();
        let logo: any = images;
        formData.append("logo", logo)
        for (let key in data) {
            (key !== "logo") && formData.append(key, JSON.stringify(data[key]))
        }
        const sendGroupedCompanies = await props.postApi(createCompanyGroup, formData);
        try {
            const res = await sendGroupedCompanies.payload;
            if (res.status === 200) {
                getCompanies();
                message.success(res.message)
                setSpinCreateGroup(false);
                formRef.current?.resetFields();              // data.target.reset();

            } else {
                setSpinCreateGroup(false)
                message.error(res.message);
            }
        } catch (error) {
            setSpinCreateGroup(false);
            message.error('Something went wrong, please try again');
        }

        console.log(data);

    }
    const normFile = (e: any) => {
        setImages(e.file.originFileObj)
        return e.fileList || e.file;
    };

    return (
        <>

            {loader ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh and try againg'}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                <div className='companiesGroup'>
                    <div className='assignCompanies'>
                        <Form name="nest-messages" ref={formRef} onFinish={createGroupCompany}>
                            <Row>
                                <Col className='colOfAssign' span='12'>
                                    <label>Group Name <span>*</span></label>
                                    <Form.Item name={'groupName'} rules={
                                        [{
                                            required: true, message: 'Group name is mandatory',
                                        }, {
                                            pattern: helper.regexValidation.englishRegex, message: 'Must be English name '
                                        }]} style={{ width: '95%' }}>
                                        <Input placeholder='Create a group name' />
                                    </Form.Item>

                                </Col>
                                <Col className='colOfAssign' span='12'>
                                    <label>Group arabic name <span>*</span></label>
                                    <Form.Item name={'groupArabicName'} rules={[{
                                        required: true, message: 'Group arabic name is mandatory'
                                    }, {
                                        pattern: helper.regexValidation.arabicRegex, message: 'Must be Arabic name'
                                    }]} style={{ width: '100%' }}>
                                        <Input placeholder='Create a group arabic name' />
                                    </Form.Item>

                                </Col>



                                <Col span={24}>
                                    <label htmlFor="">Upload <span>*</span></label>
                                    <Form.Item
                                        name="logo"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[{ required: true, message: 'Required' }]}>
                                        <Upload {...helper.dataFile} maxCount={1} name="logo" action="/upload.do" listType="picture">
                                            <Button icon={<UploadOutlined />}>Click to upload the logo</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>




                                <Col className='colOfAssign' span='24'>
                                    <label>Companies <span>*</span></label>

                                    <Form.Item
                                        name="companyIds"
                                        rules={[{ required: true, message: 'Companies are mandatory!', type: 'array' }]}
                                        hasFeedback
                                    >
                                        <Select mode="multiple"
                                            placeholder="Please select the companies"
                                            optionFilterProp="children"
                                            filterOption={(input: string, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {companies.map((service: any, key: number) => {
                                                return <Option value={service.id} key={key}>{service.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>

                                </Col>


                                <Col className='colOfAssign' span='24'>

                                    <Form.Item>
                                        <Button className='createCompanyGroupBtn' shape="round" type="primary" loading={spinCreateGroup} htmlType="submit">
                                            Create
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>


                        </Form>
                    </div>
                </div>
            }
        </>
    )
}

export default connect(null, { getApi, postApi })(CreateCompanyGroup)

