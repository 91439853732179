import { Descriptions } from 'antd'
interface Props {
    backErrors: any
}
export default function ErrorsContent(props: Props) {

    const { backErrors } = props;
    return (
        <>

            <Descriptions bordered style={{ margin: '15px 0' }}>
                {Array.isArray(backErrors) && backErrors.map((error: any, key: any) => {
                    return <Descriptions.Item key={key} label={error?.fieldName} span={3}>
                        <ul>
                            {Array.isArray(error.errors) &&
                                error?.errors.map((reason: string, secKey: any) => {
                                    return <li key={secKey} style={{ color: '#CC4949' }}>{reason}</li>
                                })}
                        </ul>
                    </Descriptions.Item>
                })}
            </Descriptions>

        </>
    )
}
