import { Image, Form, Button, Input, FormInstance, message } from 'antd';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import BasicUserInfo from '../../components/basicUserInfo';
import ModalCompnent from '../../components/modal';
import { getApi, postApi, stepperMoving } from '../../stores/actions';
import { resolveUserDocs } from '../../utils/apisConsts';

interface Props {
    info: {
        name: string,
        staff_id: number,
        companyName: string,
        userID: number,
        phone: string,
        jobTitle: string,
        email: string,
        userStatusType: string,
        consent: {
            id: number,
            docType: number,
            approved: boolean,
            comment: string,
            urls: [],
        },
        hrLetter: {
            id: number,
            docType: number,
            approved: boolean,
            comment: string,
            urls: [],
        },
        nationalId: {
            id: number,
            docType: number,
            approved: boolean,
            comment: string,
            urls: [],
        }
    },
    getApi: any,
    postApi: any,
    stepperMoving: any,
}
export class ApproveDocs extends Component<Props> {
    formRef = React.createRef<FormInstance>();
    state = {
        visibleModal: false,
        approvedHr: this.props.info.hrLetter.approved,
        approvedConsent: this.props.info.consent.approved,
        approvedNationalID: this.props.info.nationalId.approved,
        rejectHr: false,
        rejectConsent: false,
        rejectNationalID: false,
        docType: null,
        comment: '',
        errorLength: '',
        errorMessage: false,
        areaErrors: '',
        approvedHrSpin: false,
        approvedConsentSpin: false,
        approvedNationalIDSpin: false,
        rejectLoader: false,
    }
    handleApproveUserDocs = async (doc: any, docTypeApprove: string, docRejecting: string, loaderStatus: string) => {
        this.setState({ [loaderStatus]: true });
        let obj = {
            userID: this.props.info.userID,
            approved: true,
            docType: doc.docType,
            comment: '',
        }
        const sendDocApproved = await this.props.postApi(resolveUserDocs, obj);
        try {
            const res = sendDocApproved.payload;
            if (res.status === 200) {
                this.setState({ [docTypeApprove]: true, [docRejecting]: false, [loaderStatus]: false });
                message.success(res.message)

            } else {
                this.setState({ [loaderStatus]: false });
                message.error(res.message)
            }
            console.log(res);

        } catch (error) {
            this.setState({ [loaderStatus]: false });
            message.error('Something went wrong, please try again')
        }
    }
    handleRejectUserDocs = (doc: any) => {

        this.setState({ visibleModal: true, docType: doc.docType })
    }
    areaChange = (e: any) => {
        this.setState({ comment: e[0].value, areaErrors: e[0].errors, errorLength: false, errorMessage: '' })
    }
    handleAcceptModal = async () => {

        let reject = this.state.docType === 1 ? 'rejectConsent' : this.state.docType === 2 ? 'rejectNationalID' : 'rejectHr';
        let approve = this.state.docType === 1 ? 'approvedConsent' : this.state.docType === 2 ? 'approvedNationalID' : 'approvedHr';

        if (this.state.comment.length === 0) {
            this.setState({ errorLength: true });
            return
        }
        if (this.state.areaErrors.length > 0) {
            return
        }
        let obj = {
            userID: this.props.info.userID,
            approved: false,
            docType: this.state.docType,
            comment: this.state.comment
        }
        this.setState({ rejectLoader: true })
        const sendDocApproved = await this.props.postApi(resolveUserDocs, obj);
        try {
            const res = sendDocApproved.payload;
            if (res.status === 200) {
                this.setState({ visibleModal: false, [reject]: true, [approve]: false, rejectLoader: false })
                message.success(res.message);
                this.formRef.current?.resetFields();


            } else {
                message.error(res.message);
                this.setState({ rejectLoader: false })
            }
            console.log(res);

        } catch (error) {
            message.error('Something went wrong, please try again');
            this.setState({ rejectLoader: false })
        }
    }
    handleCloseTheModal = () => {
        this.setState({ visibleModal: false });
        this.formRef.current?.resetFields();
    }
    render() {
        const { visibleModal, approvedHr, approvedConsent, errorLength, errorMessage,
            approvedNationalID, rejectHr, rejectConsent, rejectNationalID, rejectLoader,
            approvedHrSpin, approvedConsentSpin, approvedNationalIDSpin, } = this.state;
        const modalContent = <>
            <label> Comment </label><span style={{ color: '#ff4d4f' }}>*</span>
            <Form name="areaa" ref={this.formRef} onFieldsChange={this.areaChange}>
                <Form.Item name={'comment'} rules={[
                    { min: 10, max: 150, message: 'The reason must be between 10 and 150 characters', },
                ]}>
                    <Input.TextArea />
                </Form.Item>
            </Form>


            {errorLength && <span style={{ color: '#ff4d4f', position: 'relative', bottom: '0px' }}>Reason is requierd</span>}
            {errorMessage && <span style={{ color: '#ff4d4f', position: 'relative', bottom: '0px' }}>{errorMessage}</span>}
            <p className='confirmationText'>Are you sure you want to reject the {this.state.docType === 1 ? 'consent' : this.state.docType === 2 ? 'national ID' : 'HR'} document ?</p>
        </>
        const modal = <ModalCompnent visibleModal={visibleModal} loadingConfirm={rejectLoader} cancelText='No' okText='Yes' handleAcceptModal={this.handleAcceptModal}
            content={modalContent} title='Document Rejection' handleCloseModal={this.handleCloseTheModal} />

        return (
            <div className='approveDocs'>
                <BasicUserInfo info={this.props.info} />

                <div className="docs">
                    <span className='label'>HR Letter</span>
                    <Form name="horizontal_login" layout="inline" >

                        <span className='image'>
                            <Image.PreviewGroup>
                                {Array.isArray(this.props.info.hrLetter.urls) && this.props.info.hrLetter.urls.map((idx: string) => {
                                    return <>
                                        {idx !== "" ? <Image width={100} height={100} src={idx} /> : ''}
                                    </>
                                })}
                            </Image.PreviewGroup>
                        </span>

                        <span className='approving'>
                            {rejectHr || (!approvedHr && approvedHr !== null) ? <Button danger type="primary" htmlType="submit">
                                Rejected
                            </Button> : <Button ghost danger onClick={() => this.handleRejectUserDocs(this.props.info.hrLetter)} type="primary" htmlType="submit">
                                Reject
                            </Button>}
                            {approvedHr ? <Button type="primary" style={{ background: '#89c87c', borderColor: '#89c87c' }}>
                                Approved
                            </Button> : <Button className='approve' ghost type="primary" loading={approvedHrSpin} onClick={() => this.handleApproveUserDocs(this.props.info.hrLetter, 'approvedHr', 'rejectHr', 'approvedHrSpin')} htmlType="submit">
                                Approve
                            </Button>}
                        </span>

                    </Form>
                </div>
                <div className="docs">
                    <span className='label'>Consent</span>
                    <Form name="horizontal_login" layout="inline" >

                        <span className='image'>
                            <Image.PreviewGroup>
                                {Array.isArray(this.props.info.consent.urls) && this.props.info.consent.urls.map((idx: string) => {
                                    return <>
                                        {idx !== "" ? <Image width={100} height={100} src={idx} /> : ''}
                                    </>
                                })}
                            </Image.PreviewGroup>
                        </span>

                        <span className='approving'>
                            <span className='approving'>
                                {rejectConsent || (!approvedConsent && approvedConsent !== null) ? <Button danger type="primary" htmlType="submit">
                                    Rejected
                                </Button> : <Button ghost danger type="primary" onClick={() => this.handleRejectUserDocs(this.props.info.consent)} htmlType="submit">
                                    Reject
                                </Button>}
                                {approvedConsent ? <Button type="primary" style={{ background: '#89c87c', borderColor: '#89c87c' }}>
                                    Approved
                                </Button> : <Button ghost type="primary" loading={approvedConsentSpin} onClick={() => this.handleApproveUserDocs(this.props.info.consent, 'approvedConsent', 'rejectConsent', 'approvedConsentSpin')} htmlType="submit">
                                    Approve
                                </Button>}
                            </span>
                        </span>

                    </Form>
                </div>
                <div className="docs">
                    <span className='label'>National ID</span>
                    <Form name="horizontal_login" layout="inline" >

                        <span className='image'>
                            <Image.PreviewGroup>
                                {Array.isArray(this.props.info.nationalId.urls) && this.props.info.nationalId.urls.map((idx: string) => {
                                    return <>
                                        {idx !== "" ? <Image width={100} height={100} src={idx} /> : ''}
                                    </>
                                })}
                            </Image.PreviewGroup>
                        </span>

                        <span className='approving'>
                            {rejectNationalID || (!approvedNationalID && approvedNationalID !== null) ? <Button danger type="primary" htmlType="submit">
                                Rejected
                            </Button> : <Button className='rejectHR' danger ghost type="primary" onClick={() => this.handleRejectUserDocs(this.props.info.nationalId)} htmlType="submit">
                                Reject
                            </Button>}
                            {approvedNationalID ? <Button type="primary" style={{ background: '#89c87c', borderColor: '#89c87c' }}>
                                Approved
                            </Button> : <Button className='approveHR' ghost type="primary" onClick={() => this.handleApproveUserDocs(this.props.info.nationalId, 'approvedNationalID', 'rejectNationalID', 'approvedNationalIDSpin')} loading={approvedNationalIDSpin} htmlType="submit">
                                Approve
                            </Button>}
                        </span>

                    </Form>
                </div>
                {modal}
            </div>

        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, postApi, stepperMoving })(ApproveDocs) 
