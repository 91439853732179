
import moment from "moment"
import helper from "./helper"

export const columnsOfDeductionsTable = [
    {
        title: 'User ID',
        dataIndex: 'userID',
        width: 100
    },
    {
        title: 'Name',
        dataIndex: 'name',
        width: 150
    },

    {
        title: 'Company Name',
        dataIndex: 'companyName',
        width: 150
    },
    {
        title: 'Advance ID',
        dataIndex: 'AdvanceID',
        width: 130
    },
    {
        title: 'Installment ID',
        dataIndex: 'installmentID',
        width: 140
    },
    {
        title: 'Payments Amount EGP',
        dataIndex: 'paymentsAmountEGP',
        width: 200
    },
    {
        title: 'Payment Cycle Date',
        dataIndex: 'paymentCycleDate',
        width: 200
    },
    {
        title: 'Instalment Order',
        dataIndex: 'instalmentOrder',
        width: 150
    },
    {
        title: 'Payment Cycle',
        dataIndex: 'paymentCycle',
        width: 130,
    },

    {
        title: 'Advance Fees',
        dataIndex: 'advanceFees',
        width: 130
    },
    // {
    //     title: 'Job Title',
    //     dataIndex: 'jobTitle',
    //     width: 130
    // },
    {
        title: 'Staff ID',
        dataIndex: 'staffID',
        width: 130
    },
    {
        title: 'Raya Ref Number',
        dataIndex: 'rayaRefNumber',
        width: 160
    },

    {
        title: 'Service Type Name',
        dataIndex: 'serviceTypeName',
        width: 170
    },

    {
        title: 'Installment Discounted Amount',
        dataIndex: 'installmentDiscountedAmount',
        width: 260,
    },
    {
        title: 'Installment Principal',
        dataIndex: 'installmentPrincipal',
        width: 170
    },
    {
        title: 'Installment Fees',
        dataIndex: 'installmentFees',
        width: 150
    },
]

export const invoicesTableColumns = [
    {
        title: 'Company Name',
        dataIndex: 'companyName',
        width: 40
    },
    {
        title: 'Serial NO',
        dataIndex: 'serialNumber',
        width: 40
    },
    {
        title: 'Total Amount',
        dataIndex: '',
        width: 40,
        render: (row: any) => {
            console.log("🚀 ~ file: tableColumns.ts ~ line 119 ~ row", row)

            return Number(row.total) ? Number(row.total).toFixed(2) : 0
        }
    },
    {
        title: 'Generated On',
        dataIndex: 'createdAt',
        width: 50,
        render: (row: any) => moment(row).format('LL - LTS')
    },
]

export const adjustmentTableColumns = [
    {
        title: 'Installment Order',
        dataIndex: 'installmentOrder',
        width: 40
    },
    {
        title: 'Invoice Month',
        dataIndex: 'invoiceMonth',
        width: 40
    },
    {
        title: 'Payment Amount',
        dataIndex: 'paymentAmount',
        width: 40
    },
]

export const lostCollections = [
    {
        title: 'Installment ID',
        dataIndex: 'installmentID',
        width: 40
    },
    {
        title: 'Collection Amount (EGP)',
        dataIndex: 'paymentsAmount',
        width: 40,
        render: (row: any) => helper.amountFormatter(row)
    },
    {
        title: 'Collection Date',
        dataIndex: 'actualPaymentDate',
        width: 40,
        render: (row: any) => moment(row).format('LL')
    },
]

export const truckingUsersBulkCreateColumns = [
    {
        title: 'name',
        dataIndex: 'name',
        width: 150,
    },
    {
        title: 'phone',
        dataIndex: 'phone',
        width: 150,
    },
    {
        title: 'companyName',
        dataIndex: 'companyName',
        width: 150,
    },
    {
        title: 'nationalID',
        dataIndex: 'nationalID',
        width: 150,
    },
    {
        title: 'role',
        dataIndex: 'role',
        width: 150,
    },
    {
        title: 'staffID',
        dataIndex: 'staffID',
        width: 100,
    },
    {
        title: 'payroll',
        dataIndex: 'payroll',
        width: 100,
    },
    {
        title: 'bankName',
        dataIndex: 'bankName',
        width: 100,
    },
    {
        title: 'accountNumber',
        dataIndex: 'accountNumber',
        width: 150,
    },
    {
        title: 'accountName',
        dataIndex: 'accountName',
        width: 150,
    },
    {
        title: 'cardNumber',
        dataIndex: 'cardNumber',
        width: 150,
    },
];
export let resolveIssuesColumns: any = [


    {
        title: 'Request ID',
        dataIndex: 'advanceID',
    },
    {
        title: 'Issue ID',
        dataIndex: 'id',
    },
    {
        title: 'Reason',
        dataIndex: 'AdvancesBlockingIssue.name',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
    },
    {
        title: 'Resolved',
        dataIndex: 'isResolvedIcon',
    },
    {
        title: 'Resolved At',
        dataIndex: 'resolvedAt',
    },
    {
        title: 'Resolved By',
        dataIndex: 'resolvedBy',
    },
]

export const inActiveRequestsColumns = [
    {
        title: 'Request ID',
        dataIndex: 'advanceID',
    },
    {
        title: 'Amount',
        dataIndex: 'requestedAmount',
    },
    {
        title: 'Total Fees',
        dataIndex: 'totalFees',
    },
    {
        title: 'Total Discount',
        dataIndex: 'totalDiscount',
    },
    {
        title: 'Service Type',
        dataIndex: 'service',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
    {
        title: 'Created At',
        dataIndex: 'date',
        render: (date: any) => moment(date).format("LL")
    }
]

export const activeRequestsColumns = [
    {
        title: 'installment ID',
        dataIndex: 'installmentID',
    },
    {
        title: 'Payment Amount',
        dataIndex: 'paymentsAmount',
    },
    {
        title: 'Principal',
        dataIndex: 'principalBeforeDiscount',
    },
    {
        title: 'Fees',
        dataIndex: 'revenueAfterDiscount',
    },
    {
        title: 'Processing Fees',
        dataIndex: 'processingFees',
    },
    {
        title: 'Discount',
        dataIndex: 'discount',
    },
    {
        title: "Installment Order",
        dataIndex: "instalmentOrder",
    },
    {
        title: "Payment Date",
        dataIndex: "actualPaymentDate",
        render: (date: string) => moment(date).format("LL")
    },
]

export let driversListAccountantColumns = [
    {
        title: 'كود السائق',
        dataIndex: 'staffID',
    },
    {
        title: 'اسم السائق',
        dataIndex: 'name',
    },
    {
        title: 'رقم الهاتف',
        dataIndex: 'phone',
    }
]

export let driversListOpsColumns = [
    {
        title: 'كود السائق',
        dataIndex: 'staffID',
    },
    {
        title: 'اسم السائق',
        dataIndex: 'name',
    },
    {
        title: 'رقم الهاتف',
        dataIndex: 'phone',
    }
]

export let supervisorsListAccountantColumns = [
    {
        title: 'كود المشرف',
        dataIndex: 'staffID',
    },
    {
        title: 'اسم المشرف',
        dataIndex: 'name',
    },
    {
        title: 'رقم الهاتف',
        dataIndex: 'phone',
    },
    {
        title: 'الرصيد المتاح',
        dataIndex: 'virtualBalance',
    },
    {
        title: ' الأموال المخصصه',
        dataIndex: 'virtualDeposits',
    }
]

export const recalculatedRequestsColumns = [
    {
        title: "Request ID",
        dataIndex: "",
        render: (row: any) => row.oldData.advanceID
    },
    {
        title: "Requested Amount",
        dataIndex: "",
        render: (row: any) => row.oldData.requestedAmount
    },
    {
        title: "Number Of Months",
        dataIndex: "",
        render: (row: any) => row.oldData.numberOfMonths
    }
]
export const recalculatedInstallmentsColumns = [
    {
        title: "Payment Amount",
        dataIndex: "paymentsAmount",
    },
    {
        title: "Principal",
        dataIndex: "principalBeforeDiscount",
    },
    {
        title: "Fees",
        dataIndex: "revenueAfterDiscount",
    },
    {
        title: "Discount",
        dataIndex: "discount",
    },
]

export const requestBlockingIssues = [
    {
        title: 'Advance ID',
        dataIndex: 'advanceID',
    },
    {
        title: 'User ID',
        dataIndex: 'userID',
    },
    {
        title: 'Username',
        dataIndex: 'username',
    },
    {
        title: 'Created',
        dataIndex: 'createdAt',
    },
    {
        title: 'Requested Amount',
        dataIndex: 'requestedAmount',
    },
    {
        title: 'Company',
        dataIndex: 'companyName',
    },
    {
        title: "Issue Type",
        dataIndex: "issueType",
    },

]
export const userAccountsColumns = [
    {
        title: 'Staff ID',
        dataIndex: 'staffID',
    },
    {
        title: 'User Name',
        dataIndex: 'userName',
    },
    {
        title: 'Company',
        dataIndex: 'companyName',
    },
    {
        title: "User Status",
        dataIndex: "userStatus",
    },
]
export const userLostCollectionColumns = [
    {
        title: 'Amount',
        dataIndex: 'paymentsAmount',
    },
    {
        title: 'Discount',
        dataIndex: 'discount',
    },
    {
        title: 'Date',
        dataIndex: 'actualPaymentDate',
        render: (date: string) => moment(date).format("DD-MM-YYYY")
    }
]