
import {
    USER_PLANS,OTP_MODAL, DATA_SHEET, USER_BALANCE, REQUEST_DETAILS,VIEW_ACCOUNTS,
    Empty_Drawer, GET_INFO, MOVE_STEP, SAVE_ARABIC_EMAIL, SAVE_ENGLISH_EMAIL, ONE_PARTNER, REQUEST_ID,
    SAVE_PARTNER_Id, UPDATE_REQUESTS, HR_STEP, USER_COUNT, CAPTION_CONFIRMATION, USERS_TO_APPROVE, DEDUCT_FILTERS, USER_ACCOUNTS, VIEW_PAYMENT_PLANS
} from "./types";

export const getDataInfo = (value: any) => {
    return (dispatch: any) => dispatch({
        type: GET_INFO,
        payload: value
    })
}
export const stepperMoving = (value: any) => {
    return (dispatch: any) => dispatch({
        type: MOVE_STEP,
        payload: value
    })
}
export const updateRequests = (value: any) => {
    return (dispatch: any) => dispatch({
        type: UPDATE_REQUESTS,
        payload: value
    })
}
export const savePartnerID = (value: any) => {
    return (dispatch: any) => dispatch({
        type: SAVE_PARTNER_Id,
        payload: value
    })
}
export const saveEmailData = (value: any, lang: ("en" | "ar")) => {
    return (dispatch: any) => dispatch({
        type: lang === "en" ? SAVE_ENGLISH_EMAIL : SAVE_ARABIC_EMAIL,
        payload: value
    })
}

export const emptyDrawer = (value: any) => {
    return (dispatch: any) => dispatch({
        type: Empty_Drawer,
        payload: value
    })
}


/**HR Module **/
export const hrStepper = (value: any) => {
    return (dispatch: any) => dispatch({
        type: HR_STEP,
        payload: value
    })
}
export const getAllUsersCount = (value: any) => {
    return (dispatch: any) => dispatch({
        type: USER_COUNT,
        payload: value
    })
}

export const changeConfirmationCaption = (value: any) => {
    return (dispatch: any) => dispatch({
        type: CAPTION_CONFIRMATION,
        payload: value
    })
}

export const getUsersToApprove = (value: any) => {
    return (dispatch: any) => dispatch({
        type: USERS_TO_APPROVE,
        payload: value
    })
}

export const getDataSheet = (value: any) => {
    return (dispatch: any) => dispatch({
        type: DATA_SHEET,
        payload: value
    })
}

export const getOnePartner = (value: any) => {
    return (dispatch: any) => dispatch({
        type: ONE_PARTNER,
        payload: value
    })
}

export const getRequestID = (value: any) => {
    return (dispatch: any) => dispatch({
        type: REQUEST_ID,
        payload: value
    })
}

export const getUserBalanceData = (value: any) => {
    return (dispatch: any) => dispatch({
        type: USER_BALANCE,
        payload: value
    })
}

export const getPaymentPlans = (value: any) => {
    return (dispatch: any) => dispatch({
        type: USER_PLANS,
        payload: value,
    })
}

export const getRequestDetails = (value:any)=>{
    return (dispatch: any) => dispatch({
        type: REQUEST_DETAILS,
        payload: value,
    })
}

export const openOTPModal = (value:any)=>{
    return (dispatch: any) => dispatch({
        type: OTP_MODAL,
        payload: value,
    })
};
export const getDeductionsFilters = (value: any) => {
    return (dispatch: any) => dispatch({
        type: DEDUCT_FILTERS,
        payload: value
    })
}
export const getUserAccounts = (value: any) => {
    return (dispatch: any) => dispatch({
        type: USER_ACCOUNTS,
        payload: value
    })
}
export const viewAccountTable = (value: any) => {
    return (dispatch: any) => dispatch({
        type: VIEW_ACCOUNTS,
        payload: value
    })
}
export const viewPaymentPlans = (value: any) => {
    return (dispatch: any) => dispatch({
        type: VIEW_PAYMENT_PLANS,
        payload: value
    })
}