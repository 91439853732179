import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Stepper from '../components/stepper';
import EditCompanyForm1 from './editCompany/editCompanyForm1';
import EditCompanyForm3 from './editCompany/editCompanyForm3';
import EditCompanyForm2 from './editCompany/editCompanyForm2';
import { request, stepperMoving } from '../stores/actions'
import Spinner from '../components/spinner';
import OutPutResult from '../components/result';
import helper from '../utils/helper';
import { errorMessage } from '../utils/commonStringsConsts';
import { Button } from 'antd';

function CreateCompany() {
    const [companyData, setCompanyData] = useState({});
    const current = useSelector((state: any) => state.actionsReducer.stepNumber)
    const [loadingData, setLoadingData] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [allStatus, setAllStatus] = useState([]);
    const [teams, setTeams] = useState([]);
    const [companyIndustries, setCompanyIndustries] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        getData(`/companies/getReasons`, { reasons: setAllStatus });
        getData(`/companies/getTeams`, { teams: setTeams });
        getData(`/companies/getIndustriesAndTypes`, { companyIndustries: setCompanyIndustries, companyTypes: setCompanyTypes });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (apiLink: string, dynamicState: { [key: string]: any }) => {
        setLoadingData(true)
        try {
            const data: any = await dispatch(request({ url: apiLink, method: "GET" }));
            if (data.data.status === 200) {
                for (let key in dynamicState) {
                    dynamicState[key](data.data[key])
                }
            } else {
                setErrorMode(true)
            }
            setLoadingData(false)
        } catch (error) {
            console.log(error);
            setErrorMode(true)
            setLoadingData(false)
        }
    }


    return (
        loadingData ?
            <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                <>
                    <Stepper componentSteps={[
                        {
                            title: 'Basic Info',
                            content: <EditCompanyForm1 allStatus={allStatus} companyData={companyData} setCompanyData={setCompanyData} companyIndustries={companyIndustries}
                                companyTypes={companyTypes} editAllInfo={true} create={true}/>,
                            id: 0
                        },
                        {
                            title: 'Additional Info',
                            content: <EditCompanyForm2 companyData={companyData} setCompanyData={setCompanyData}  editAllInfo={true} create={true}/>,
                            id: 1
                        },
                        {
                            title: 'Teams Info',
                            content: <EditCompanyForm3 companyData={companyData} setCompanyData={setCompanyData} teams={teams} editAllInfo={true} companyOldInfo={{}} create={true}/>,
                            id: 2
                        },
                    ]} />
                    <div className="steps-action" style={{ margin: '-55px 0 0 0' }}>
                        {current > 0 && (
                            <Button onClick={() => dispatch(stepperMoving(Number(current)-1))}>
                                Previous
                            </Button>
                        )}
                    </div>
                </>
    )
}


export default CreateCompany
