import { Button, Col, DatePicker, Form, FormInstance, InputNumber, message, Row } from 'antd';
import moment from 'moment';
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { getApi, postApi, stepperMoving, request } from '../../stores/actions';
import { assignVipUser } from '../../utils/apisConsts';
import UserInfoUI from '../../components/uiInfo';
import helper from '../../utils/helper';

// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

// const { Option } = Select;
interface userInfo {
    name: string,
    staff_id: number,
    companyName: string,
    userID: number,
    phone: string,
    jobTitle: string,
    email: string,
    vipFreeMonths: number | null,
    vipFreeMonthsStartDate: string | null,
    hiringDate: string
}
interface Props {
    info: userInfo,
    getApi: any,
    postApi: any,
    stepperMoving: any,
    request: any
}

export class AssignUser extends Component<Props> {
    formRef = createRef<FormInstance>();
    state = {
        loading: false
    }
    assignUser = async (value: { vibFreeMonths: string, startDate: string }) => {
        this.setState({ loading: true })
        const data = {
            userID: this.props.info.userID,
            vipFreeMonths: value.vibFreeMonths,
            vipFreeMonthsStartDate: value.startDate
        }
        try {
            const request = await this.props.request({ url: assignVipUser, method: "put", data: data });
            const res = await request.data;
            if (res.status === 200) {
                this.setState({ loading: false })
                message.success(res.message, 4);
                this.formRef.current!.resetFields();
                helper.resetAccountTableInStepper();
                this.props.stepperMoving(0)
            } else {
                this.setState({ loading: false })
                message.error(res.message, 4);
            }
        } catch (error) {
            console.log("🚀 ~ file: assignUser.tsx ~ line 25 ~ AssignUser ~ assignUser= ~ error", error);
            this.setState({ loading: false });
            message.error('Something went wrong, please try again', 4);
        }
    }
    dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    render() {
        const { loading } = this.state;
        console.log(this.props.info.vipFreeMonthsStartDate);

        const vipUserInfo = [
            {
                key: 'Number of Free Months',
                value: <Form.Item
                    name={'vibFreeMonths'}
                    initialValue={this.props.info.vipFreeMonths ?? ''}
                    rules={[{ required: true, message: 'Required' }, { type: 'number', min: 0, max: 60, message: 'must be between 0 and 60' }]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='Enter The number of months' />
                </Form.Item>
            },
            {
                key: 'Start Date',
                value: <Form.Item
                    name={'startDate'}
                    rules={[{ required: true, message: 'Required' }]}
                    initialValue={this.props.info.vipFreeMonthsStartDate ? moment(this.props.info.vipFreeMonthsStartDate) : null}
                >
                    <DatePicker style={{ width: '100%' }} disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return current && current < moment(customDate, "YYYY-MM-DD");
                    }} />
                </Form.Item>
            }

        ]
        return (
            <div className='userFeesModel'>
                <div className="assignUser">

                    <Form
                        ref={this.formRef}
                        initialValues={{ type: "type_1" }}
                        onFinish={this.assignUser}
                    >

                        <Row>

                            <Col span={24}>
                                <UserInfoUI userData={vipUserInfo} activeKey={1} header={'VIP User'} nonEditable={true} collapsible={"disabled"} />
                            </Col>

                            <Col style={{ margin: '30px 0 0 0 ' }} span={24}>
                                <Form.Item>
                                    <Button style={{ float: 'right', backgroundColor: "#7CC58F", borderColor: "#7CC58F" }} shape='round' type="primary" htmlType="submit" loading={loading}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>

                </div>

            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, postApi, stepperMoving, request })(AssignUser)
