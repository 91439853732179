import { useSelector } from 'react-redux';
import ViewDrivers from '../add-expenses/viewDrivers';
import VerifyRequest from './verifyRequest';

interface IRootState {
    [key: string]: {
      step:number
    };
  }
function VerifyParent() {
    const stepper: any = useSelector<IRootState>(state => state.truckingReducer.step);
    
    return (
        <>
            {stepper === 0 ? <ViewDrivers /> : <VerifyRequest />}
        </>
    )
}

export default VerifyParent
