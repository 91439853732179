import React from 'react'
import OutPutResult from '../../../components/result'
import { useDispatch, useSelector } from 'react-redux';
import { move } from '../../../stores/actions';
import { Button } from 'antd';
interface IRootState {
    [key: string]: any;
}
export default function Step3() {
    const users: any = useSelector<IRootState>(state => state.actionsReducer.dataSheet.sheetData);
    const dispatch= useDispatch()
    return (
        <>
            <div className="truckUsersSuccess">
                <OutPutResult status={'success'} subTitle={`${users.length} Employee${users.length > 1 ? "s":""} have been uploaded successfully`} title={'Data has been added to the system successfully'} />
                {<Button className='reUpload' onClick={()=>dispatch(move(0))}>Upload another file</Button>}
            </div>
        </>
    )
}
