
import React, { Component } from 'react'
import Stepper from '../../components/stepper';
import SelectUserOrCompany from './selectUserOrCompany';
import SubmitEarlyPayment from './submitEarlyPayment';

class EarlyPayment extends Component{
    state = {
        isCompany: true,
        identifier: null
    }

    render() {
        const { isCompany, identifier } = this.state;
        const componentSteps = [
            {
                title: 'Select Company and User',
                content: <SelectUserOrCompany isCompany={isCompany} setIsCompany={(isCompany: boolean)=>this.setState({isCompany})} setIdentifier={(identifier: any)=>this.setState({identifier})}/>,
                id: 0,
            }, {
                title: 'Submit Early Payment',
                content: <SubmitEarlyPayment isCompany={isCompany} identifier={identifier}/>,
                id: 1
            },
        ]
        
        return (
            <div className='createEarlyPayment'>
                <Stepper componentSteps={componentSteps}/>
            </div>
        )
    }
}


export default EarlyPayment