import { combineReducers } from "redux";
import generalReducer from "./viewsReducer/general/reduser";
import authReducer from './viewsReducer/auth/reducer';
import componentReducer from './componentsReducer/reducers';
import actionsReducer from './actionsReducer/reducer';
import truckingReducer from './truckingReducer/reducer'
const rootReducer = combineReducers({
  general: generalReducer,
  auth: authReducer,
  componentReducer: componentReducer,
  actionsReducer: actionsReducer,
  truckingReducer: truckingReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;