import { Button, Col, Divider, Form, FormInstance, InputNumber, message, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { request } from '../../apis/axiosRequest';
import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
import { errorMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';

interface CapsTypes {
    maxMonthlyMobileRechargeInternetTopUp: number | null,
    maxDailyBillPayments: number | null,
    maxMonthlyUserBillPayments: number | null,
    maxMonthlyUserRequests: number | null,
    MaxOngoingSalaryAdvance_SalaryPercentThreshold: number | null,
    MaxOngoingSalaryAdvance_NoOfReqs: number | null,
    maxMonthlyMobileRechargeInternetTopUpFlag: boolean,
    maxDailyBillPaymentsFlag: boolean,
    maxMonthlyUserBillPaymentsFlag: boolean,
    maxMonthlyUserRequestsFlag: boolean,
    salaryAdvanceCapsFlag: boolean
}
export default function UpdateGlobalCaps() {
    const [globalCaps, setGlobalCaps] = useState<CapsTypes>({
        maxMonthlyMobileRechargeInternetTopUp: 0,
        maxDailyBillPayments: 0,
        maxMonthlyUserBillPayments: 0,
        maxMonthlyUserRequests: 0,
        MaxOngoingSalaryAdvance_SalaryPercentThreshold: 0,
        MaxOngoingSalaryAdvance_NoOfReqs: 0,
        maxMonthlyMobileRechargeInternetTopUpFlag: false,
        maxDailyBillPaymentsFlag: false,
        maxMonthlyUserBillPaymentsFlag: false,
        maxMonthlyUserRequestsFlag: false,
        salaryAdvanceCapsFlag: false
    })
    const [updating, setUpdating] = useState(false)
    const [loadingCaps, setLoadingCaps] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const dispatch = useDispatch()
    const formRef = React.createRef<FormInstance>();

    useEffect(() => {
        getGlobalCaps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGlobalCaps = async () => {
        try {
            setLoadingCaps(true)
            const caps: { [key: string]: any } = await dispatch(request({ url: "/services/getAllGlobalServicesCaps", method: "GET" }))
            if (caps.data.status === 200) {
                const data: CapsTypes = caps.data.data
                setGlobalCaps({
                    maxMonthlyMobileRechargeInternetTopUp: data.maxMonthlyMobileRechargeInternetTopUp || 0,
                    maxDailyBillPayments: data.maxDailyBillPayments || 0,
                    maxMonthlyUserBillPayments: data.maxMonthlyUserBillPayments || 0,
                    maxMonthlyUserRequests: data.maxMonthlyUserRequests || 0,
                    MaxOngoingSalaryAdvance_SalaryPercentThreshold: data.MaxOngoingSalaryAdvance_SalaryPercentThreshold,
                    MaxOngoingSalaryAdvance_NoOfReqs: data.MaxOngoingSalaryAdvance_NoOfReqs,
                    maxMonthlyMobileRechargeInternetTopUpFlag: !!data.maxMonthlyMobileRechargeInternetTopUp,
                    maxDailyBillPaymentsFlag: !!data.maxDailyBillPayments,
                    maxMonthlyUserBillPaymentsFlag: !!data.maxMonthlyUserBillPayments,
                    maxMonthlyUserRequestsFlag: !!data.maxMonthlyUserRequests,
                    salaryAdvanceCapsFlag: !!data.MaxOngoingSalaryAdvance_SalaryPercentThreshold || !!data.MaxOngoingSalaryAdvance_NoOfReqs
                })
            }
            else {
                setErrorMode(true)
            }
            setLoadingCaps(false)
        }
        catch (e: any) {
            setLoadingCaps(false)
            setErrorMode(true)
            console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 28 ~ getGlobalCaps ~ e", e)
        }
    }
    const updateCaps = async (values: CapsTypes) => {
        console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 70 ~ updateCaps ~ values", values)
        try {
            setUpdating(true)
            const dataObj = {
                // maxMonthlyMobileRechargeInternetTopUp: 0,
                maxDailyBillPayments: (!values.maxDailyBillPayments || !values.maxDailyBillPaymentsFlag) ? 0 : values.maxDailyBillPayments,
                maxMonthlyUserBillPayments: (!values.maxMonthlyUserBillPayments || !values.maxMonthlyUserBillPaymentsFlag) ? 0 : values.maxMonthlyUserBillPayments,
                maxMonthlyUserRequests: (!values.maxMonthlyUserRequests || !values.maxMonthlyUserRequestsFlag) ? 0 : values.maxMonthlyUserRequests,
                MaxOngoingSalaryAdvance_NoOfReqs: (!values.MaxOngoingSalaryAdvance_NoOfReqs || !values.salaryAdvanceCapsFlag) ? 0 : values.MaxOngoingSalaryAdvance_NoOfReqs,
                MaxOngoingSalaryAdvance_SalaryPercentThreshold: (!values.MaxOngoingSalaryAdvance_SalaryPercentThreshold || !values.salaryAdvanceCapsFlag) ? 0 : values.MaxOngoingSalaryAdvance_SalaryPercentThreshold,
            }
            const data: { [key: string]: any } = await dispatch(request({
                url: "/services/updateGlobalServicesCaps", method: "PATCH", data: dataObj
            }))
            if (data.data.status === 200) {
                message.success(data.data.message, 4)
                setGlobalCaps({
                    ...values,
                    ...dataObj
                })
            }
            else {
                message.error(data.data.message, 4)
            }
            setUpdating(false)
        }
        catch (e: any) {
            setUpdating(false)
            message.error(errorMessage, 4)
        }
    }
    const limitationToggle = (value: boolean, key: string,
        inputKey: "maxDailyBillPayments" | "maxMonthlyMobileRechargeInternetTopUp" | "maxMonthlyUserBillPayments"
            | "maxMonthlyUserRequests" | "MaxOngoingSalaryAdvance_SalaryPercentThreshold" | "MaxOngoingSalaryAdvance_NoOfReqs") => {
        console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 97 ~ limitationToggle ~ value", value)
        setGlobalCaps({
            ...globalCaps,
            [key]: value
        })
        if (!value) {
            formRef.current?.setFields([{
                name: inputKey,
                value: globalCaps[inputKey],
                errors: []
            }]);
        }
    }

    return (
        loadingCaps ? <Spinner size={"large"} />
            : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage} />
                : <div>
                    <Form ref={formRef} name="nest-messages" onFinish={updateCaps} initialValues={globalCaps} className='globalCapsForm servicesCapsForm'>
                        <Row>
                            <Col span='24'>
                                <Divider orientation="left" className='formTitle'>Global</Divider>
                            </Col>
                            <Col className='servicesInputs' span='12'>
                                <Row>
                                    <Col span={24}>
                                        <label>Bill Payments - Last 24 hrs (EGP) <span>*</span></label>
                                    </Col>
                                    <Col span={24} className="switchCol">
                                        <p>Limited:</p>
                                        <Form.Item name="maxDailyBillPaymentsFlag">
                                            <Switch defaultChecked={globalCaps.maxDailyBillPaymentsFlag} onChange={(value) => limitationToggle(value, "maxDailyBillPaymentsFlag", "maxDailyBillPayments")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="maxDailyBillPayments" rules={[{ required: globalCaps.maxDailyBillPaymentsFlag, message: 'This field is required' },
                                        { type: "number", min: globalCaps.maxDailyBillPaymentsFlag ? 1 : undefined, message: "The limit can't be less than 1" },
                                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                                            <InputNumber step={1} placeholder="Enter service max limit" disabled={!globalCaps.maxDailyBillPaymentsFlag} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span='24'>
                                <Divider orientation="left" className='formTitle'>User</Divider>
                            </Col>
                            <Col className='servicesInputs' span='12'>
                                <Row>
                                    <Col span={24}>
                                        <label>Bill Payments - Payment Cycle (EGP) <span>*</span></label>
                                    </Col>
                                    <Col span={24} className="switchCol">
                                        <p>Limited:</p>
                                        <Form.Item name="maxMonthlyUserBillPaymentsFlag" >
                                            <Switch defaultChecked={globalCaps.maxMonthlyUserBillPaymentsFlag} onChange={(value) => limitationToggle(value, "maxMonthlyUserBillPaymentsFlag", "maxMonthlyUserBillPayments")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="maxMonthlyUserBillPayments" rules={[{ required: globalCaps.maxMonthlyUserBillPaymentsFlag, message: 'This field is required' },
                                        { type: "number", min: globalCaps.maxMonthlyUserBillPaymentsFlag ? 1 : undefined, message: "The limit can't be less than 1" },
                                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                                            <InputNumber placeholder="Enter service max limit" disabled={!globalCaps.maxMonthlyUserBillPaymentsFlag} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='servicesInputs' span='12'>
                                <Row>
                                    <Col span={24}>
                                        <label>Total Requests - Payment Cycle (EGP) <span>*</span></label>
                                    </Col>
                                    <Col span={24} className="switchCol">
                                        <p>Limited:</p>
                                        <Form.Item name="maxMonthlyUserRequestsFlag" >
                                            <Switch defaultChecked={globalCaps.maxMonthlyUserRequestsFlag} onChange={(value) => limitationToggle(value, "maxMonthlyUserRequestsFlag", "maxMonthlyUserRequests")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="maxMonthlyUserRequests" rules={[{ required: globalCaps.maxMonthlyUserRequestsFlag, message: 'This field is required' },
                                        { type: "number", min: globalCaps.maxMonthlyUserRequestsFlag ? 1 : undefined, message: "The limit can't be less than 1" },
                                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                                            <InputNumber placeholder="Enter service max limit" disabled={!globalCaps.maxMonthlyUserRequestsFlag} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span='24'>
                                <label>Salary Advance Caps <span>*</span></label>
                            </Col>
                            <Col span={24} className='servicesInputs' style={{ marginBottom: "10px" }}>
                                <Row>
                                    <Col span={24} className="switchCol">
                                        <p>Limited:</p>
                                        <Form.Item name="salaryAdvanceCapsFlag" >
                                            <Switch defaultChecked={globalCaps.salaryAdvanceCapsFlag} onChange={(value) => {
                                                limitationToggle(value, "salaryAdvanceCapsFlag", "MaxOngoingSalaryAdvance_NoOfReqs")
                                                limitationToggle(value, "salaryAdvanceCapsFlag", "MaxOngoingSalaryAdvance_SalaryPercentThreshold")
                                            }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='servicesInputs' span='12'>
                                <Row>
                                    <Col span={24}>
                                        <label>Number of salary advance requests</label>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="MaxOngoingSalaryAdvance_NoOfReqs" rules={[{ required: globalCaps.salaryAdvanceCapsFlag, message: 'This field is required' },
                                        { type: "number", min: globalCaps.salaryAdvanceCapsFlag ? 1 : undefined, message: "The number of requests can't be less than 1" },
                                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                                            <InputNumber placeholder="Enter number of requests" disabled={!globalCaps.salaryAdvanceCapsFlag} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='servicesInputs' span='12'>
                                <Row>
                                    <Col span={24}>
                                        <label>Salary percent threshold (%)</label>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="MaxOngoingSalaryAdvance_SalaryPercentThreshold" rules={[{ required: globalCaps.salaryAdvanceCapsFlag, message: 'This field is required' },
                                        { type: "number", min: globalCaps.salaryAdvanceCapsFlag ? 10 : undefined, max: 100, message: "The percent must be between 10% and 100%" },
                                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                                            <InputNumber placeholder="Enter the salary percent" disabled={!globalCaps.salaryAdvanceCapsFlag} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span='24'>
                                <Form.Item>
                                    <Button style={{ margin: '22px 0 0 0 ', width: "15%", float: "right" }} shape="round" type="primary" block htmlType="submit" loading={updating} >
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <span className='formTitle'>Overview Dashboard: </span><span className='action' onClick={() => helper.setRedirect("https://dashboard.nowpay.cloud/dashboard/277")}>https://dashboard.nowpay.cloud/dashboard/277</span>
                    </Form>
                </div >
    )
}