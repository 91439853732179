import { Image } from 'antd';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import DataTable from '../../components/dataTable';

interface Props {
  info?: {
    Companies: [{
      name: string,
      companyName: string,
      id: number,
      description: string,
      logoID: string,
      salaryCycleStartDay: string,
      salaryCycleEndDay: string,
      arabicName: string,
      arabicDescription: string,

      maxInstallmentCount: string,
      salaryPreparationDay: string,

      maxAdvancePercentAllowed: number,
      fees: number,
      shoppingBalancePercentage: number,

      createdAt: string,
      staff_id: string,
      maxShoppingInstallmentsCount: number,
      deployed: any
    }]
    arabicName: string
    createdAt: string
    id: number
    logoID: string
    name: string
  }
}
export class ViewCompanyGroups extends Component<Props> {
  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: 70
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: 200
      },

      {
        title: 'Fees',
        dataIndex: 'fees',
        width: 40
      },
      {
        title: 'Company Image',
        key: 'operation',
        fixed: 'right',
        width: 50,
        render: (_text: any, record: any) => (
          <Image
            width={150}
            src={record?.logoID}
          />
        ),
      }
    ]
    const { info } = this.props;
    console.log("🚀 ~ file: viewCompanyGroups.tsx ~ line 66 ~ ViewCompanyGroups ~ render ~ info", info)
    return (

      <div>

        {info &&
          <div className="companyProfile">
            <Image
              width={150}
              src={info?.logoID}
            />
            <h3>{info.name ? `${info.name} Group` : 'NA'}</h3>

            {/* <Descriptions bordered style={{ margin: '15px 0' }}>
              <Descriptions.Item label="Name" span={2}>{info.name ? info.name : 'NA'}</Descriptions.Item>
              <Descriptions.Item label="Arabic Name" span={2}>{info.arabicName ? info.arabicName : 'NA'}</Descriptions.Item>
            </Descriptions> */}
            <div style={{margin:'50px 0 0 0'}}>

              <DataTable columns={columns} rows={info.Companies} pagination={true} loading={false} scrolling={false} />
            </div>

          </div>
        }
      </div>

    )
  }
}
const mapStateToProps = (state: any) => ({
  info: state.actionsReducer.info,
})
export default connect(mapStateToProps, null)(ViewCompanyGroups)
