import React, { Fragment, PureComponent } from 'react'
import { Card } from 'antd';
import Helper from '../../utils/helper';
const { Meta } = Card;
interface Props {
    groupActions: any,
    selectedAction: any,
    permission?: any,
}
interface actionAttributes {
    icon: boolean,
    name: boolean,
    description: boolean,
    actionName?: string,
}

export class MainLayoutGroups extends PureComponent<Props> {
    
    render() {
        /**helper.isArabic */
        const { groupActions, selectedAction, permission } = this.props
        return (
            <>
                {permission ?

                    <div className='mainBodyGroups'>
                        {groupActions && groupActions.map((action: actionAttributes, idx: React.Key | null | undefined) => {
                            return <Fragment key={idx}>

                                {permission ? permission.includes(action.actionName) &&

                                    <Card className='card' style={{ direction: Helper.isArabic(action.name)?'rtl':'ltr'}}
                                        key={idx} onClick={() => selectedAction(action)}>
                                        <Meta className='metaCard'
                                            avatar={action.icon}
                                            title={action.name}
                                            description={action.description} />
                                    </Card> : null}
                            </Fragment>
                        })}
                    </div>
                    : <h2>You don't have permission to view this portal</h2>}
            </>
        )
    }
}

export default MainLayoutGroups
