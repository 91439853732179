
import { Steps } from 'antd';
import { useSelector } from 'react-redux';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
const { Step } = Steps;

interface IRootState {
    [key: string]: any;
    step: number,
}

export default function CreateTruckingUsers() {
    const step: any = useSelector<IRootState>(state => state.truckingReducer.step);
    const steps = [
        {
            title: 'Choose and upload the file',
            content: <Step1 />,
        },
        {
            title: 'Check and upload data',
            content: <Step2 />,
        },
        {
            title: 'Data confirmation',
            content: <Step3 />,
        },
    ];
    return (
            <div className='bulkCreateTruckingUsers'>
                <Steps type='navigation' current={step}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div className="steps-content">{steps[step].content}</div>

            </div>
    )
}