import React, { Component } from 'react'
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { toggleSideBar } from '../../stores/actions';
import helper from '../../utils/helper';

interface Props extends RouteComponentProps<any> {
    is_bar_collapsed?: boolean,
    toggleSideBar: any,
    active_nav?: any,
    accessRoutes?: any,
}

class SideBar extends Component<Props> {
    state = {
        active: this.props.location.pathname,
    }
    // UNSAFE_componentWillMount() {
    //     this.setState({ active: this.props.location.pathname })
    // }
    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ active: this.props.location.pathname.toString() })
        }
    }

    handleNavigating = (route: string) => {
        this.props.history.push(route);
    }
    render() {
        const { accessRoutes } = this.props;
        const menuNodes = <>

            {accessRoutes.map((menu: any) => {
                return <Menu.Item key={`/${menu.path}`} style={{textTransform:'capitalize'}} onClick={() => this.handleNavigating(`/${menu.path}`)}>
                    {helper.unCamelCase(menu.path)}{menu.path.includes('Trucking')&&' / نقل'} <span className='actionsNum'>{menu.actions.length}</span>
                    
                </Menu.Item>
            })}

        </>
        return (
            <div className='sideBar'>

                <Menu
                    defaultSelectedKeys={[this.props.location.pathname]}
                    selectedKeys={[this.state.active]}
                    mode="inline"
                    inlineCollapsed={this.props.is_bar_collapsed}>

                    {menuNodes}
                </Menu>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => ({
    is_bar_collapsed: state.componentReducer.is_bar_collapsed,
    active_nav: state.componentReducer.active_nav,
})
export default withRouter(connect(mapStateToProps, { toggleSideBar })(SideBar))
