import { Descriptions, Image, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import helper from '../../utils/helper'
import { getAmanBalanceApi } from '../../utils/apisConsts'
import OutPutResult from '../../components/result';

export default function AmanBalance() {
    const [balance, setBalance] = useState(0);
    const [isLoader, setIsLoader] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    useEffect(() => {
        getAmanBalance()
    }, [])
    const getAmanBalance = async () => {
        setIsLoader(true);
        try {
            const amanBalance: any = await helper.callApiRequest(getAmanBalanceApi, 'GET');
            if (amanBalance?.data?.status === 200) {
                setIsLoader(false)
                setBalance(amanBalance?.data?.data?.amanBalance);
                return
            }
            setErrorMode(true);
        } catch (error) {
            setErrorMode(true);
        };
        setIsLoader(false);
    }
    const amanBody = <>
        <Image
            width={150}
            preview={false}
            src={`https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/aman.png`}
        />
        <Descriptions bordered style={{ margin: '15px 0' }}>
            <Descriptions.Item label="Aman Balance">{helper.amountFormatter(balance) + ' EGP'}</Descriptions.Item>
        </Descriptions>
    </>
    return (
        <>
            {isLoader ?
                <Skeleton loading={isLoader} active avatar>
                    {amanBody}
                </Skeleton> : errorMode ?
                    <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh the page and try again'}
                        btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                    <div className="companyProfile">
                        {amanBody}
                    </div>}
        </>
    )
}
