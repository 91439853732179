import React, { Component } from "react";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
interface Props {
  apiData?: any;
  fileName: string;
  otherSheets?: any[];
  buttonName?: string,
  buttonColor?: string,
  multipleFiles?: boolean,
  generateData?: any

}

export const exportToCSV = async (apiData: unknown[], fileName: string, generateData?: any, otherSheets?: any[], fileDownloadedName?: string) => {
  if (generateData) {
    apiData = await generateData()
  }
  const allFiles = [];
  if (Array.isArray(apiData) && apiData.length > 0) allFiles.push({
    name: fileName,
    data: apiData,
  })
  if (otherSheets) {
    for (let index = 0; index < otherSheets.length; index++) {
      if (otherSheets[index].onClick) {
        otherSheets[index].data = await otherSheets[index].onClick()
      }
      allFiles.push(otherSheets[index]);
    }
  }
  for (let i = 0; i < allFiles.length; i++) {
    let sheets = { data: XLSX.utils.json_to_sheet(allFiles[i].data) };
    let names = ["data"];
    const wb = { Sheets: sheets, SheetNames: names };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    allFiles.length > 1 ?
      FileSaver.saveAs(data, allFiles[i].statusName + fileExtension) :
      FileSaver.saveAs(data, allFiles[i].name + fileExtension)
  }
};

class ExportToExcel extends Component<Props> {
  state = {};

  render() {
    const menu = (
      <Menu>
        <Menu.Item
          icon={<DownloadOutlined />}
          onClick={(e) => exportToCSV(
            this.props.apiData,
            this.props.fileName,
            this.props.generateData,
            this.props.otherSheets || []
          )}
        >
          Download all sheets
        </Menu.Item>
        {this.props.apiData && <Menu.Item
          icon={<DownloadOutlined />}
          onClick={(e) => exportToCSV(this.props.apiData, this.props.fileName, this.props.generateData)}
        >
          {this.props.fileName}
        </Menu.Item>}
        {this.props.otherSheets?.map((sheet, i) => (
          <Menu.Item
            key={i}
            icon={<DownloadOutlined />}
            onClick={async (e) => {
              if (sheet.onClick) {
                const data = await sheet.onClick()
                sheet.data = data
              }
              exportToCSV(sheet.data, sheet.statusName, this.props.generateData)
            }}
          >
            {sheet.name}
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <>
        {!this.props.otherSheets ? (

          <>
            {this.props.multipleFiles ?
              <Button type="default" onClick={() => exportToCSV(this.props.apiData, this.props.fileName, this.props.generateData)}
                style={{ color: this.props.buttonColor, borderColor: this.props.buttonColor, margin: '0 19px' }}>
                {this.props.buttonName}
              </Button>
              : <DownloadOutlined
                onClick={(e) =>
                  exportToCSV(this.props.apiData, this.props.fileName, this.props.generateData)
                }
                style={{ float: "right", margin: "20px 0 0 0", fontSize: "25px" }}
              />}
          </>
        ) : (
          <Dropdown overlay={menu}>
            <DownloadOutlined style={{ float: "right", margin: "20px 0 0 0", fontSize: "25px" }} />
          </Dropdown>
        )}

      </>
    );
  }
}

export default ExportToExcel;
