

class Actions {
    actionsCreated() {
        const actions: any[] = [
            // Users
            {
                name: 'Change user company',
                id: 2,
                description: 'Click to change user company',
                layout: null,
                actionName: 'changeCompany'
            },
            {
                name: 'Change Phone Number',
                id: 3,
                description: 'Click to change user phone number',
                layout: null,
                actionName: 'changePhoneNumber',
            },
            {
                name: 'Change user status',
                id: 4,
                description: 'Click to change user status',
                layout: null,
                actionName: 'changeStatus'
            },
            {
                name: 'Users Batch Update',
                id: 5,
                description: 'Click to use batch update',
                layout: null,
                actionName: 'updateBulkUsers',
            },


            // Requests
            {
                name: 'Create Request',
                id: 6,
                description: 'Click to create user request',
                layout: null,
                actionName: 'createRequest'
            },
            {
                name: 'Cancel Request',
                id: 7,
                description: 'Click to cancel any request',
                layout: null,
                actionName: 'cancelRequest'
            },
            // Notifications
            {
                name: 'SMS Notifications',
                id: 8,
                description: 'Click to send SMS',
                layout: null,
                actionName: 'smsNotification'
            },
            {
                name: 'Push Notifications',
                id: 9,
                description: 'Click to send push notifications',
                layout: null,
                actionName: 'pushNotification'
            },
            {
                name: 'Voice Message',
                id: 10,
                description: 'Click to send voice message',
                layout: null,
                actionName: 'voiceNotification'
            },

            //Companies
            {
                name: 'Create Company',
                id: 13,
                description: 'Click to create Company',
                layout: null,
                actionName: 'createCompany'
            },
            //Merchant
            {
                name: 'Offline Transactions',
                id: 14,
                description: 'Click to view the transactions of offline shopping',
                layout: null,
                actionName: 'cancelMerchantRequest'
            },
            //Fees
            {
                name: 'Create group',
                id: 15,
                description: 'Click to Create group to fees engin',
                layout: null,
                actionName: 'createServicesGroup'
            },
            {
                name: 'View Groups',
                id: 18,
                description: 'Click to View Groups',
                layout: null,
                actionName: 'getServiceGroup'
            },
            {
                name: 'Create model',
                id: 16,
                description: 'Click to Create model to fees engin',
                layout: null,
                actionName: 'createFeesModel'
            },
            {
                name: 'View Models',
                id: 19,
                description: 'Click to View Models',
                layout: null,
                actionName: 'getModelsFeesDetails'
            },
            {
                name: 'Assign company to fees model',
                id: 17,
                description: 'Click to assign company to fees model',
                layout: null,
                actionName: 'assignCompanyToFeesModel'
            },
            {
                name: 'View Companies',
                id: 20,
                description: 'Click to View Companies',
                layout: null,
                actionName: 'getCompaniesFees'
            },
            //Call center module 
            {
                name: 'Start Call',
                id: 21,
                description: 'Click to start a new call',
                layout: null,
                actionName: 'addCallInfo'
            },
            //View user info getUserInfo
            {
                name: 'View User Information ',
                id: 22,
                description: 'Click to View user information',
                layout: null,
                actionName: 'getUserInfo'
            },

            //View requests list getAllRequests
            {
                name: 'View Requests',
                id: 23,
                description: 'Click to View requests list',
                layout: null,
                actionName: 'getAllRequests'
            },
            //View user info getUserInfo
            {
                name: 'Update User Information ',
                id: 24,
                description: 'Click to Update user information',
                layout: null,
                actionName: 'updateUserInfo'
            },
            //Change Deployment Status
            {
                name: 'Deploy Company',
                id: 25,
                description: 'Click to Deploy a Company',
                layout: null,
                actionName: 'changeDeploymentStatus'
            },
            //Approve High amount Request
            {
                name: 'High Amount Request',
                id: 26,
                description: 'Click to Approve High Amount Request',
                layout: null,
                actionName: 'approveHighAmount'
            },
            // Test zoho ticket
            {
                name: 'Test Zoho Ticket',
                id: 30,
                description: 'Click to Test Zoho Ticket',
                layout: null,
                actionName: 'testZohoTicket'
            },
            // Fees assign user to model
            {
                name: 'Assign VIP User',
                id: 31,
                description: 'Click to Assign VIP User',
                layout: null,
                actionName: 'assignUserToFeesModel'
            },
            //Create merchant
            {
                name: 'Create Partner',
                id: 32,
                description: 'Click to create Partner',
                layout: null,
                actionName: 'createPartner'
            },
            //Approve use documents 
            {
                name: 'Approve User Documents',
                id: 33,
                description: 'Click to approve or reject the documents',
                layout: null,
                actionName: 'approveUserDocs'
            },
            //Create merchant
            {
                name: 'Upload Partner Branches Sheet',
                id: 34,
                description: 'Click to upload Partner Branches',
                layout: null,
                actionName: 'createPartnerBranches'
            },
            //Create company group
            {
                name: 'Create Company Group',
                description: 'Click to create company group',
                layout: null,
                actionName: 'createCompanyGroup'
            },
            // create welcome email
            {
                name: 'Create Welcome Email',
                description: 'Click to create welcome email',
                layout: null,
                actionName: 'createWelcomeEmail'
            },
            //getCompaniesGroups
            {
                name: 'View Company Groups',
                description: 'Click to view the company groups',
                layout: null,
                actionName: 'getCompaniesGroups',
            },
            {
                name: 'Assign undeployed company',
                description: 'Click to assign undeployed company',
                layout: null,
                actionName: 'assignNonDeployedCompanyToFeesModel',
            },
            //getDeductions
            {
                name: 'View Deductions',
                description: 'Click to view all deductions',
                layout: null,
                actionName: 'getDeductions',
            },
            {
                name: 'Generate Invoices',
                description: 'Click to view all deductions and generate invoices',
                layout: null,
                actionName: 'generateInvoice',
            },
            //invoices 
            {
                name: 'Lock Payment Cycles',
                description: 'Click to lock payment cycles',
                layout: null,
                actionName: "lockPaymentCycle",
            },
            {
                name: 'Unlock Payment Cycles',
                description: 'Click to unlock payment cycles',
                layout: null,
                actionName: "unlockPaymentCycle",
            },
            /**Trucking Module */
            {
                name: 'تحديد التحويلات اليومية ',
                description: ' اضغط لتحديد التحويلات اليومية ',
                layout: null,
                actionName: 'viewDrivers',
            },
            {
                name: 'تسجيل الإيصالات ',
                description: 'اضغط لتسجيل الإيصالات ',
                layout: null,
                actionName: 'addVerifiedTransactions',
            },
            {
                name: 'تحويل إجمالي المستحق للمستلم ',
                description: 'اضغط لتحويل إجمالي المستحق للمستلم',
                layout: null,
                actionName: 'payroll',
            },
            {
                name: 'إدارة التحويلات',
                description: ' اضغط لإدارة التحويلات',
                layout: null,
                actionName: 'updateTruckingStatus',
            },
            // get user last otp
            {
                name: 'Send User OTP',
                description: 'Click to send user OTP',
                layout: null,
                actionName: 'sendOtpViaTwilio',
            },
            // view company details
            {
                name: 'View Company Details',
                description: 'Click to view company',
                layout: null,
                actionName: 'getOneCompany',
            },
            {
                name: 'User Status',
                description: 'Click to Change user status',
                layout: null,
                actionName: 'updateStatus',
            },
            {
                name: 'User Salary',
                description: 'Click to Change user salary',
                layout: null,
                actionName: 'updateUserSalary',
            },
            {
                name: 'User Bank Information',
                description: 'Click to change user bank information',
                layout: null,
                actionName: 'updateBankInfo',
            },
            {
                name: 'Change approved Users Info',
                description: 'Click to change approved users info',
                layout: null,
                actionName: 'updateApprovedUserInfo',
            },
            {
                name: 'User Status Bulk Update',
                description: 'Click to Change users statuses',
                layout: null,
                actionName: 'updateBulkStatus',
            },
            {
                name: 'Suspend User',
                description: 'Click to suspend users',
                layout: null,
                actionName: 'suspendUser',
            },
            {
                name: 'Force Reset User Password',
                description: 'Click to force reset user password',
                layout: null,
                actionName: 'updateForceResetPassword',
            },
            {
                name: 'Upload User Documents',
                description: 'Click to Upload user documents',
                layout: null,
                actionName: 'uploadUserDocs',
            },
            {
                name: 'Un-Suspend User',
                description: 'Click to un-suspend users',
                layout: null,
                actionName: 'unSuspendUser',
            },
            {
                name: 'Update Global Services Caps',
                description: 'Click to update caps',
                layout: null,
                actionName: 'updateGlobalServicesCaps',
            },
            {
                name: 'Update Company Services Caps',
                description: 'Click to update caps',
                layout: null,
                actionName: 'updateCompanyCap',
            },
            {
                name: 'Update User Services Cap',
                description: 'Click to update caps',
                layout: null,
                actionName: 'updateUserCap',
            },
            {
                name: 'Suspend/Un-Suspend Global Services',
                description: 'Click to suspend/un-suspend services',
                layout: null,
                actionName: 'updateGlobalServiceSuspension',
            },
            {
                name: 'Suspend/Un-Suspend Company Services',
                description: 'Click to suspend/un-suspend services',
                layout: null,
                actionName: 'updateCompanySuspension',
            },
            {
                name: 'إدارة الموظفين',
                description: ' اضغط لإدارة الموظفين',
                layout: null,
                actionName: 'hrGetUsers',
            },
            {
                name: 'Users Batch Create',
                id: 5,
                description: 'Click to use batch create',
                layout: null,
                actionName: 'createBulkUsers',
            },
            {
                name: 'Assign User To Role',
                description: 'Click to assign user to role',
                layout: null,
                actionName: 'assignUserToRole',
            },
            {
                name: 'Un-Assign User To Role',
                description: 'Click to un-assign user to role',
                layout: null,
                actionName: 'unAssignUserFromRole',
            },
            {
                name: 'Change User Company',
                description: 'Click to change the user company',
                layout: null,
                actionName: 'updateUserCompany',
            },
            {
                name: 'Approved Users Batch Update',
                description: 'Click to use batch update',
                layout: null,
                actionName: 'updateBulkApprovedUsers',
            },
            {
                name: 'إضافة مجموعة من العاملين',
                description: ' اضغط لإضافة مجموعة من العاملين',
                layout: null,
                actionName: 'hrBulkUpsertUsers',
            },
            {
                name: 'Add External Payment',
                description: 'Click to add external payment',
                layout: null,
                actionName: 'externalPayment',
            },
            {
                name: 'Revoke Request',
                description: 'Click to revoke request',
                layout: null,
                actionName: 'revokeRequest',
            },
            {
                name: 'Batch Create Meeza Cards Requests',
                description: 'Click to create meeza cards requests',
                layout: null,
                actionName: 'createBulkMeezaCards',
            },
            {
                name: 'Batch Create Clearance Transactions',
                description: 'Click to create clearance transactions',
                layout: null,
                actionName: 'clearanceTransactions',
            },
            {
                name: 'Create Collections',
                description: 'Click to create invoices collections',
                layout: null,
                actionName: 'addCollections',
            },
            {
                name: 'External Payments Batch Create',
                description: 'Click to create external payments',
                layout: null,
                actionName: 'bulkExternalPayment',
            },
            {
                name: 'Create Adjustment',
                description: 'Click to create adjustment',
                layout: null,
                actionName: 'createAdjustment',
            },
            {
                name: 'Delete Adjustment',
                description: 'Click to delete adjustment',
                layout: null,
                actionName: 'deleteAdjustment',
            },
            {
                name: 'Edit Company',
                description: 'Click to edit Company',
                layout: null,
                actionName: 'updateCompanyInfo'
            },
            {
                name: 'Edit Company - Advanced',
                description: 'Click to edit all company information',
                layout: null,
                actionName: 'updateCompanyInfoAdvance'
            },
            {
                name: 'Approved Users Batch Update (All Info)',
                description: 'Click to use batch update',
                layout: null,
                actionName: 'updateBulkApprovedUsersAdvanced',
            },
            {
                name: 'Change Best Seller Products',
                description: 'Click to control the best seller products ',
                layout: null,
                actionName: 'bestSellerToggle',
            },
            {
                name: 'Choose The Popular Partner',
                description: 'Click to control the best seller products ',
                layout: null,
                actionName: 'partnerPopularToggle',
            },
            //Categories And Brands
            {
                name: 'Add new categories',
                description: 'Click to add new categories ',
                layout: null,
                actionName: 'categories',
            },
            {
                name: 'Add new brands',
                description: 'Click to Add new brands ',
                layout: null,
                actionName: 'brands',
            },
            {
                name: 'Create Lost Request',
                description: 'Click to create lost',
                layout: null,
                actionName: 'createLostRequest',
            },
            {
                name: 'Create Lost Collection',
                description: 'Click to create lost collection',
                layout: null,
                actionName: 'createLostCollection',
            },
            {
                name: 'Delete Lost Request',
                description: 'Click to delete lost',
                layout: null,
                actionName: 'deleteLostRequest',
            },
            // Create bulk products
            {
                name: 'Create Bulk Products',
                description: 'Click to create bulk products ',
                layout: null,
                actionName: 'bulkCreateProducts',
            },
            {
                name: 'Delete Debit/Credit Request',
                description: 'Click to delete debit/credit request',
                layout: null,
                actionName: 'deleteDebitCredit',
            },
            {
                name: 'Create Exception Request',
                description: 'Click to create request with no limits',
                layout: null,
                actionName: 'createExceptionRequest',
            },
            {
                name: 'Create Partner Type',
                description: 'Click to create new partner type',
                layout: null,
                actionName: 'createPartnerType',
            },
            {
                name: 'Update Bulk Products',
                description: 'Click to update bulk products',
                layout: null,
                actionName: 'bulkUpdateProducts',
            },
            {
                name: 'Online Shopping Transactions',
                description: 'Click to view online shopping transactions',
                layout: null,
                actionName: 'onlineShoppingRequests',
            },
            {
                name: 'تحديث رصيد الشركة',
                description: 'إضغط لتحديث رصيد الشركة',
                layout: null,
                actionName: 'addCompanyDeposit',
            },
            {
                name: 'قائمة العاملين',
                description: ' اضغط لعرض و تحديد التحويلات اليومية للمستخدمين ',
                layout: null,
                actionName: 'viewDriversList',
            },
            {
                name: 'إضافة مجموعة من العاملين',
                description: ' اضغط لإضافة مجموعة من العاملين',
                layout: null,
                actionName: 'createTruckingUser',
            },
            {
                name: 'Run DA API on a Demand',
                description: 'Click to run DA API on a demand',
                layout: null,
                actionName: "callAnalyticsApi",
            },
            {
                name: 'Run DA API on a schedule',
                description: 'Click to run DA API on a schedule',
                layout: null,
                actionName: "scheduleAnalyticsApi",
            },
            {
                name: 'Delete analytics scheduled API',
                description: 'Click to delete analytics scheduled API',
                layout: null,
                actionName: "deleteScheduledAnalyticsApi",
            },
            {
                name: 'Terminate Analytics Api',
                description: 'Click to terminate analytics API',
                layout: null,
                actionName: "terminateAnalyticsApi",
            },
            {
                name: 'Resolving Request Issues ',
                description: 'Click to resolving request issues',
                layout: null,
                actionName: 'resolveAdvanceIssuesManually'
            },
            {
                name: 'Updating Block Issues',
                description: 'Click to updating block issues',
                layout: null,
                actionName: 'resolveAdvancesIssuesAutomatically'
            },
            {
                name: 'Refund In-Active Requests',
                description: 'Click to create refund request for in-active requests',
                layout: null,
                actionName: "createRefundRequest",
            },
            {
                name: 'Deductions',
                description: 'Click to view Deductions',
                layout: null,
                actionName: "newDeductions",
            },
            {
                name: 'Clear Refund',
                description: 'Click to clear refund',
                layout: null,
                actionName: "clearAdvanceRefund",
            },
            {
                name: 'Refund Active Requests',
                description: 'Click to create refund request for active requests',
                layout: null,
                actionName: "refundActiveRequest",
            },
            {
                name: 'Recalculate Batch Requests',
                description: 'Click to recalculate requests',
                layout: null,
                actionName: "recalculateRequests",
            },
            {
                name: 'عرض السائقين',
                description: 'اضغط للعرض والبحث عن السائقين',
                layout: null,
                actionName: "viewTruckingUsers",
            },
            {
                name: 'Batch Update Requests Statuses',
                description: 'Click to update requests statuses',
                layout: null,
                actionName: "bulkChangeRequestsStatus",
            },
            {
                name: 'Update Request ',
                description: 'Click to update request',
                layout: null,
                actionName: "updateRequest",
            },
            {
                name: 'Batch Recalculate Requests',
                description: 'Click to recalculate requests',
                layout: null,
                actionName: "recalculateRequests",
            },
            {
                name: 'Batch Create Requests Comments',
                description: 'Click to create requests comments',
                layout: null,
                actionName: "addRequestsComments",
            },
            {
                name: 'Batch Create Users Comments',
                description: 'Click to create users comments',
                layout: null,
                actionName: "addUsersComments",
            },
            {
                name: 'Import Call Information file',
                description: 'Click to import call information file',
                layout: null,
                actionName: "importCallCenterReport",
            }
            ,
            {
                name: 'Create Departments',
                description: 'Click to create departments',
                layout: null,
                actionName: "createDepartments",
            },
            {
                name: 'تخصيص الأموال',
                description: 'اضغط لتخصيص الأموال',
                layout: null,
                actionName: "createVirtualDeposit",
            },
            {
                //createTransfer
                name: 'تحويل الاموال بين العاملين',
                description: ' اضغط لتحويل الأموال بين العاملين',
                layout: null,
                actionName: "createTransfer",
            },
            {
                //createTransfer
                name: 'اداراة التحويلات بين العاملين',
                description: 'اضغط لاداراة التحويلات بين العاملين   ',
                layout: null,
                actionName: "changeTransferStatus",
            },
            {
                //sendingEmails
                name: 'ارسال تنبيهات',
                description: 'اضغط لارسال التنبيهات      ',
                layout: null,
                actionName: "sendingEmails",
            },
            {
                name: 'Add User Comment',
                description: 'Click to add user comment',
                layout: null,
                actionName: "userCreateSingleComment",
            },
            {
                name: 'View all requests with blocking issues',
                description: 'Click to view all requests with blocking issues',
                layout: null,
                actionName: "getBlockingIssues",
            },
            {
                name: 'Create Exceptions Lost',
                description: 'Click to create exceptions lost',
                layout: null,
                actionName: "createExceptionLost",
            },
            {
                name: 'Create Single User',
                description: 'Click to Create Single User',
                layout: null,
                actionName: "createUser",
            },
            // {
            //     name: 'Test Chat GPT',
            //     description: 'Click to Test Chat GPT',
            //     layout: null,
            //     actionName: "testChatGPT",
            // },

            {
                name: 'Create Exception Adjustment',
                description: 'Click to create exception adjustment',
                layout: null,
                actionName: "createExceptionAdjustment",
            },
            {
                name: 'View Request Fees',
                description: 'Click to View Request Fees',
                layout: null,
                actionName: "viewRequestFees",
            },
            {
                name: 'View Aman Balance',
                description: 'Click to Aman Balance',
                layout: null,
                actionName: "getAmanBalance",
            },
            {
                name: 'Upload Approved User Docs ',
                description: 'Click to Upload Approved User Documents',
                layout: null,
                actionName: "uploadApprovedUserDocs",
            },
        ]
        return actions
    }
}

export default new Actions()
export const _ = '';
