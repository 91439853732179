import { Descriptions, Button, Image, message } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { patchApi, stepperMoving } from '../../stores/actions'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import Helper from '../../utils/helper';
import ModalComponent from '../../components/modal'
import { companyInfo } from '../../utils/commonInterfaces';

interface Props {
    info?: companyInfo,
    patchApi: any,
    stepperMoving: any,
    deployNotAllowed?: boolean
}
class DeployCompany extends Component<Props> {
    state = {
        loading: false,
        visibleSpocsModal: false,
        visibleDepartmentsModal: false
    }
    updateDeploymentStatus = async () => {
        this.setState({ loading: true })
        let data = { deployed: true }
        const deployCompany = await this.props.patchApi(`/companies/changeDeploymentStatus/${this.props.info && this.props.info.id}`, data);
        console.log("🚀 ~ file: deployCompany.tsx ~ line 40 ~ DeployCompany ~ updateDeploymentStatus= ~ deployCompany", deployCompany)
        const res = deployCompany.payload;
        try {
            if (res.status === 200) {
                this.setState({ loading: false })
                message.success(res.message, 5);
                this.props.stepperMoving(0)
            } else {
                this.setState({ loading: false });
                message.error(res.message, 5)
            }
        } catch (error) {
            this.setState({ loading: false });
            message.error('Something went wrong, please try again')

        }
    }

    handleShowSpocs = (value: boolean) => {
        this.setState({ visibleSpocsModal: value })
    }

    handleShowDepartments = (value: boolean) => {
        this.setState({ visibleDepartmentsModal: value })
    }

    filterCommunicationDays = (cycles: any[]) => {
        if (!cycles) {
            return []
        }
        let days: any[] = []
        for (let index = 0; index < cycles.length; index++) {
            const cycleDays = Object.entries(cycles[index])
                .filter(([key, value]) => value === 1 && key !== "id")
                .map(([key, value]) => key)
            days = [...days, ...cycleDays]
        }
        return <p>{
            days.map((day, index) => {
                if (index !== days.length - 1) {
                    return day + ", "
                }
                return day
            })
        }</p>
    }

    render() {
        let { info, deployNotAllowed } = this.props;
        const { loading, visibleSpocsModal, visibleDepartmentsModal } = this.state;
        const trueIcon = <CheckCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#52c41a" />
        const falseIcon = <CloseCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#eb2f96" />
        return (

            <div>

                {info &&
                    <div className="companyProfile">
                        {/* <img src={info?.logoID} alt="company image" /> */}
                        <Image
                            width={150}
                            src={info?.logoID}
                        />

                        <Descriptions bordered style={{ margin: '15px 0' }}>
                            <Descriptions.Item label="Name" span={2}>{info.name ? info.name : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Arabic Name" span={2}>{info.arabicName ? info.arabicName : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Description" span={3}>{info.description ? info.description : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Arabic Description" span={3}>{info.arabicDescription ? info.arabicDescription : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Communication Cycle Days" span={3}>{(info.communicationCycles && info.communicationCycles.length > 0) ? this.filterCommunicationDays(info.communicationCycles) : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Company Industry" span={2}>{info.companyIndustry ? info.companyIndustry : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Company Type" span={2}>{info.companyType ? info.companyType : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="In-Active Status" span={2}>{info.defaultInactiveNextStatus ? info.defaultInactiveNextStatus : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Sign-up Status" span={2}>{info.defaultSignupStatus ? info.defaultSignupStatus : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Salary Cycle Start Day" span={2}>{info.salaryCycleStartDay ? info.salaryCycleStartDay : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Salary Cycle EndDay" span={2}>{info.salaryCycleEndDay ? info.salaryCycleEndDay : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Salary Preparation Day" span={2}>{info.salaryPreparationDay ? info.salaryPreparationDay : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Collection Exact Day" span={2}>{info.collectionDayExact ? info.collectionDayExact : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Max Allowed Monthly" span={2}>{info.maxMonthlyPercentAllowed ? info.maxMonthlyPercentAllowed : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Max allowed percentage amount" span={2}>{info.maxAdvancePercentAllowed ? info.maxAdvancePercentAllowed : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Installments count" span={2}>{info.maxInstallmentCount ? info.maxInstallmentCount : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Shopping Balance Percentage" span={2}>{info.shoppingBalancePercentage ? info.shoppingBalancePercentage : 'NA'}</Descriptions.Item>
                            <Descriptions.Item label="Fees" span={2}>{info.fees ? info.fees : 0}</Descriptions.Item>
                            <Descriptions.Item label="Free Deduction Fees" span={2}>{info.hasDeductionBasedFreePeriods ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="Company Free Months" span={2}>{info.companyFreeMonths ? info.companyFreeMonths : 0}</Descriptions.Item>
                            <Descriptions.Item label="Company Users Free Months" span={2}>{info.userFreePaymentCycles ? info.userFreePaymentCycles : 0}</Descriptions.Item>
                            <Descriptions.Item label="Deployment Date" span={2}>{ info.deploymentDate? Helper.changeDateFormat(info.deploymentDate): 'NA' }</Descriptions.Item>
                            <Descriptions.Item label="Company Free Period Expiry Date" span={2}>{ info.freePeriodExpiryDate? Helper.changeDateFormat(info.freePeriodExpiryDate): 'NA' }</Descriptions.Item>
                            <Descriptions.Item label="Estimated Number Of Users" span={2}>{info.estimatedNumbersOfUsers ? info.estimatedNumbersOfUsers : 0}</Descriptions.Item>
                            <Descriptions.Item label="Cash Enabled" span={2}>{info.cashEnabled ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="Request Required Active User" span={2}>{info.requestRequiresActiveUser ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="Required Promissory Note" span={2}>{info.promissoryNoteRequired ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="Required HR Letter" span={2}>{info.hrLetterRequired ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="Required Consent" span={2}>{info.consentRequired ? trueIcon : falseIcon}</Descriptions.Item>

                            <Descriptions.Item label="Required Staff ID" span={2}>{info.staffIDRequired ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="Staff ID Provided by the company" span={2}>{info.staffIDRequiredByCompany ? trueIcon : falseIcon}</Descriptions.Item>

                            <Descriptions.Item label="Required National ID" span={2}>{info.nationalIDRequired ? trueIcon : falseIcon}</Descriptions.Item>
                            <Descriptions.Item label="National ID Provided by the company" span={2}>{info.nationalIDRequiredByCompany ? trueIcon : falseIcon}</Descriptions.Item>

                            <Descriptions.Item label="Available For Deployment" span={2}>{info.availableForDeployment ? info.availableForDeployment : 0}</Descriptions.Item>
                            <Descriptions.Item label="Teams" span={2}>{(info.SPOCs && info.SPOCs.length > 0) ? <Button onClick={() => this.handleShowSpocs(true)} type="link">Show teams</Button> : 'NA'}</Descriptions.Item>
                            
                            <Descriptions.Item label="Departments" span={2}>{(info.departments && info.departments.length > 0) ? <Button onClick={() => this.handleShowDepartments(true)} type="link">Show departments</Button> : 'NA'}</Descriptions.Item>

                            <Descriptions.Item label="Established At" span={2}>{info.establishedAt ? Helper.changeDateFormat(info.establishedAt) : 'NA'}</Descriptions.Item>
                            {deployNotAllowed && <Descriptions.Item label="Deployed" span={2}>{info.deployed ? trueIcon : falseIcon}</Descriptions.Item>}
                            <Descriptions.Item label="Created At" span={2}>{info.createdAt ? Helper.changeDateFormat(info.createdAt) : 'NA'}</Descriptions.Item>
                        </Descriptions>

                        {!deployNotAllowed && <Button className='createGroupBtn' shape="round" type="primary" loading={loading} onClick={this.updateDeploymentStatus} >
                            Deploy
                        </Button>}

                        <ModalComponent none='none' content={
                            (info.SPOCs && Array.isArray(info.SPOCs)) ? info.SPOCs.map((team: { [key: string]: string | number }) =>
                                <Descriptions bordered style={{ marginBottom: "25px" }}>
                                    <Descriptions.Item label="Team" span={3}>{team["Team.name"] ? team["Team.name"] : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Title" span={2}>{team.title ? team.title : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Name" span={2}>{team.name ? team.name : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Email" span={2}>{team.email ? team.email : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Phone" span={2}>{team.phone ? team.phone : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Secondary Title" span={2}>{team.titleSecondary ? team.titleSecondary : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Secondary Name" span={2}>{team.nameSecondary ? team.nameSecondary : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Secondary Email" span={2}>{team.emailSecondary ? team.emailSecondary : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Secondary phone" span={2}>{team.phoneSecondary ? team.phoneSecondary : 'NA'}</Descriptions.Item>
                                </Descriptions>
                            )
                                : "There are no Teams"}
                            title={`Group services`} visibleModal={visibleSpocsModal} handleCloseModal={() => this.handleShowSpocs(false)} handleAcceptModal={() => this.handleShowSpocs(false)} />

                        <ModalComponent none='none' content={
                            (info.departments && Array.isArray(info.departments)) ? info.departments.map((department: any) =>
                                <Descriptions bordered style={{ marginBottom: "25px" }}>
                                    <Descriptions.Item label="ID" span={3}>{department?.Department?.id ? department.Department.id : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Name EN" span={2}>{department?.Department?.nameEn ? department.Department.nameEn : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Name AR" span={2  }>{department?.Department?.nameAr ? department.Department.nameAr : 'NA'}</Descriptions.Item>
                                    <Descriptions.Item label="Description" span={3}>{department?.Department?.description ? department.Department.description : 'NA'}</Descriptions.Item>
                                </Descriptions>
                            )
                                : "There are no Departments"}
                            title={`Departments`} visibleModal={visibleDepartmentsModal} handleCloseModal={() => this.handleShowDepartments(false)} handleAcceptModal={() => this.handleShowDepartments(false)} />
                    </div>
                }
            </div>

        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { patchApi, stepperMoving })(DeployCompany)
