import { Form, Button, ConfigProvider, Select } from "antd";
import { useDispatch } from 'react-redux';
import { move, request } from "../../../stores/actions";
import { useEffect, useState } from "react";
import OutPutResult from "../../../components/result";
import helper from "../../../utils/helper";
import Spinner from "../../../components/spinner";

const { Option } = Select;

interface Props {
    apiLink?: string,
    setCompany: any
}
function GetCompanies(props: Props) {
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [companies, setCompanies] = useState([])
    const [errorMode, setErrorMode] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const dispatch = useDispatch();

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompanies = async () => {
        try {
            setLoadingCompanies(true)
            const res: any = await dispatch(request({ url: props.apiLink, method: 'get' }))
            if (res.data.status === 200) {
                setCompanies(res.data.data.companies)
            } else {
                setErrorMessage('حدث خطأ ما')
                setErrorMode(true)
            }
            setLoadingCompanies(false)
        } catch (err: any) {
            setErrorMessage('حدث خطأ ما')
            setErrorMode(true)
            setLoadingCompanies(false)
        }
    }

    const dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    
    const submitCompany = (values: { companyID: string }, data?: {[key: string]: any}) => {
        props.setCompany(values.companyID)
        dispatch(move(1));
    }

    return (
        <>
            <ConfigProvider direction="rtl">
                {loadingCompanies ? <Spinner size={"large"}/> : errorMode ? <OutPutResult status={'500'} title={errorMessage} subTitle={' '}
                    btnTitle={'إعادة تحميل الصفحه'} btnClick={helper.refreshPage} /> :
                    <div className="driverInfo">
                        
                        <Form name="nest-messages" onFinish={submitCompany}>
                            <Form.Item name={'companyID'} rules={[{ required: true, message: 'يجب اختيار الشركة' }]}>
                                <Select
                                    placeholder={'اختر الشركة'}
                                    showSearch
                                    allowClear
                                    filterOption={((input, option: any) => dropDownSearch(input, option))}
                                >
                                    {Array.isArray(companies) && companies.map((company: any, key: number) => {
                                        return <Option value={company.id} data={company} key={key}>{company.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button className='driverInfoBtn' shape="round" type="primary" block htmlType="submit">
                                    إدخال
                                </Button>
                            </Form.Item>


                        </Form>
                    </div>}
            </ConfigProvider>
        </>
    )
}
export default GetCompanies;