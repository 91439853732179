import React, { useState } from 'react'
import PartnerInfo from '../../../components/partnerInfo'
import { useSelector, useDispatch } from 'react-redux';
import { patchApi,stepperMoving } from '../../../stores/actions';
import { message } from 'antd';
import { patchTopPartnerApi } from '../../../utils/apisConsts';
interface IRootState {
    [key: string]: {
        onePartner: any
    },
    patchApi: any,
    stepperMoving:any,
}
export default function Partner() {
    const [popularSpin, setPopularSpin] = useState(false);
    const [isBug,setIsBug] = useState(false);
    const partnerInfo: any = useSelector<IRootState>(state => state.actionsReducer.onePartner);
    const dispatch = useDispatch();

    const changeToTopPartner = async () => {
        setPopularSpin(true);
        try {
            const allProds: any = await dispatch(patchApi(`${patchTopPartnerApi}${partnerInfo.partnerID}`, {}));
            const res = allProds.payload;
            if (res.status === 200) {
                message.success(res.message);
                setPopularSpin(false);
                setIsBug(false);
                dispatch(stepperMoving(0))

            }
            else {
                message.error(res.message);
                setPopularSpin(false);
                setIsBug(true)
            }
        } catch (error) {
            message.error('something went wrong, please try again');
            setPopularSpin(false);
            setIsBug(true)
        }
    }
    return (
        <>
            <PartnerInfo info={partnerInfo} changeToTopPartner={changeToTopPartner} spin={popularSpin} isBug={isBug} />
        </>
    )
}
