import React, { Component } from 'react'
import { connect } from 'react-redux'
import { emailObjType } from '../../utils/commonInterfaces'

interface Props {
    englishEmailData: emailObjType
}

class WelcomeEmailEnglish extends Component <Props> {


    render() {
        const { companyName, companyLogo, headerAfterName, description, ifFreePeriod, freePeriod, refLink,
            salaryAdvanceService, paymentService, internalTransferringService, shoppingService, payrollDescription, serviceFees,
            howItWorks1, howItWorks2, howItWorks3, howItWorks4, videoLink, questionsContact, questionsString } = this.props.englishEmailData;

        return (
            <table id="email-body" style={{ width: "100%" }}>

                {/* Header Section */}
                <table role="presentation"
                    //    border="0" cellpadding="0" cellspacing="0"
                    style={{ width: "100%", background: "#004e64", padding: "15px" }}>
                    <tbody>
                        <tr>
                            <td style={{ lineHeight: "24px", fontSize: "16px", margin: 0, padding: "15px 120px" }}>
                                <img draggable="false"
                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/group-18550@3x.png"
                                    alt="Logo" width="144" height="32.7" className="d-inline-block align-text-top" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* End Header Section */}
                <table role="presentation"
                    // border="0" cellpadding="0" cellspacing="0"
                    style={{ width: "100%", background: "#d9e9e9" }}>
                    <tbody>
                        <tr>
                            <td align="center" style={{ lineHeight: "24px", fontSize: "16px", margin: 0, padding: "30px 16px" }}>

                                {/* Banner section */}
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ lineHeight: "24px", fontSize: "16px", margin: 0 }} align="center">
                                                <img className="img-fluid" src={companyLogo} alt="logo" width="150"
                                                    style={{ height: "100", lineHeight: "100%", outline: "none", textDecoration: "none", display: "block", maxWidth: "100%", width: "300px", border: "0 none" }} />
                                                <div className="previewTitle" style={{
                                                    fontSize: "35px",
                                                    color: "#004e64",
                                                    fontWeight: "bold",
                                                    lineHeight: "40px",
                                                    textAlign: "center",
                                                    fontFamily: "Tajawal",
                                                    padding: "30px 0 0 0"
                                                }}>
                                                    {companyName + " "} {headerAfterName}
                                                </div>

                                                <div className="preview" style={{
                                                    fontSize: "20px",
                                                    color: "#737f89",
                                                    fontWeight: 600,
                                                    letterSpacing: "0.63px",
                                                    lineHeight: "30px",
                                                    margin: "16px auto",
                                                    textAlign: "center",
                                                    fontFamily: "Tajawal",
                                                }}>
                                                    {description}
                                                    <br />
                                                    {ifFreePeriod && <span style={{textDecoration: "underline"}}>The service is free for {freePeriod} month{freePeriod>1 && "s"} only</span>}
                                                </div>
                                                <span><a href={refLink}><img
                                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/Component+162+231%402x.png" className="img-fluid"
                                                    alt="download" width="380" height="53px" style={{
                                                        margin: "23px auto 0 auto",
                                                        display: "flex"
                                                    }} /></a></span>
                                                <br /><br /><br />
                                                <img draggable="false"
                                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/path-48399@3x.png"
                                                    className="img-fluid lineDivided" alt="line" width="651" style={{
                                                        margin: "0px auto",
                                                        display: "flex"
                                                    }} />
                                                <br /><br /><br />
                                            </td>
                                            <br /><br /><br />
                                        </tr>
                                    </tbody>
                                </table>
                                {/* End Banner section */}

                                {/* Header of service section */}
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr style={{ padding: "30px", backgroundColor: "#fff" }}>
                                            <td className="col-6"
                                                style={{ lineHeight: "24px", fontSize: "16px", minHeight: "1px", fontWeight: "normal", padding: "30px", width: "50%", margin: 0 }}
                                            // align="left" valign="top"
                                            >
                                                <span className="featuers">Benefits</span>
                                            </td>
                                            <td className="col-6"
                                                style={{ lineHeight: "24px", fontSize: "16px", minHeight: "1px", fontWeight: "normal", padding: "30px", width: "50%", margin: 0 }}
                                                align="right" valign="top">

                                                <span><a className="nowPayService" href="#nowPayServiceBody"
                                                    style={{ color: "#004e64", textDecoration: "underline" }}>Nowpay
                                                    Services</a></span>
                                            </td>


                                        </tr>

                                    </tbody>
                                </table>
                                {/* End Header of service section */}

                                {/* Service section */}
                                <table
                                    //  align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr>

                                            <td className="col-12" style={{ padding: 0, backgroundColor: "#fff" }}>

                                                <img draggable="false"
                                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/Banner(1).png"
                                                    className="card-img-top" alt="..." width="655" style={{ margin: "0 auto 0 auto", display: "flex" }} />
                                                <div className="service" style={{ width: "655px" }}>
                                                    <table>
                                                        <tr>
                                                            <td style={{ padding: "50px 50px 15px 30px" }}>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            <img src="https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/service-icon-1.svg" alt="icon1" />
                                                                        </td>
                                                                        <td style={{ padding: "0 0px 0px 15px" }}>
                                                                            <b style={{ fontSize: "18px" }}>Salary advance service</b>
                                                                            <p style={{ fontSize: "17px", color: "#58636c", letterSpacing: "-0.43", marginTop: "10px" }}>
                                                                                {salaryAdvanceService}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "0px 50px 15px 30px" }}>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            <img src="https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/service-icon-2.svg" alt="icon2" />
                                                                        </td>
                                                                        <td style={{ padding: "0 0px 0px 15px" }}>
                                                                            <b style={{ fontSize: "18px" }}>Payment service</b>
                                                                            <p style={{ fontSize: "17px", color: "#58636c", letterSpacing: "-0.43", marginTop: "10px" }}>
                                                                                {paymentService}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "0px 50px 15px 30px" }}>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            <img src="https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/service-icon-3.svg" alt="icon3" />
                                                                        </td>
                                                                        <td style={{ padding: "0 0px 0px 15px" }}>
                                                                            <b style={{ fontSize: "18px" }}>Internal transferring service</b>
                                                                            <p style={{ fontSize: "17px", color: "#58636c", letterSpacing: "-0.43", marginTop: "10px" }}>{internalTransferringService}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "0px 50px 15px 30px" }}>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            <img src="https://elasticbeanstalk-us-east-2-927288046810.s3.us-east-2.amazonaws.com/Logos/service-icon-4.svg" alt="icon4" />
                                                                        </td>
                                                                        <td style={{ padding: "0 0px 0px 15px" }}>
                                                                            <b style={{ fontSize: "18px" }}>Shopping service</b>
                                                                            <p style={{ fontSize: "17px", color: "#58636c", letterSpacing: "-0.43", marginTop: "10px" }}>
                                                                                {shoppingService}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <span><a href={refLink}><img
                                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/Component+162+231%402x.png" className="img-fluid"
                                                    alt="download" width="380" height="53px" style={{
                                                        margin: "23px auto 0 auto",
                                                        display: "flex"
                                                    }} /></a></span>
                                                <div className="texts" style={{
                                                    margin: "24px auto", textAlign: "center",
                                                    lineHeight: "20px",
                                                    fontSize: "15px",
                                                    color: "#737f89"
                                                }}>
                                                    <p className="card-text" style={{ margin: "0 0 10px 0" }}>
                                                        {payrollDescription}
                                                    </p>
                                                    <p className="card-text" style={{ fontWeight: "bolder" }}>
                                                        with {serviceFees}% service
                                                        fees
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/*End Service section */}

                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "32px auto 0 auto" }}>
                                    <tbody style={{ margin: "50px 0 0 0" }}>
                                        <tr style={{ padding: "30px" }}>


                                            <td className="col-12"
                                                style={{ lineHeight: "24px", fontSize: "16px", minHeight: "1px", fontWeight: "normal", padding: "30px", width: "50%", margin: 0 }}
                                                align="right" valign="top">

                                            </td>

                                        </tr>

                                    </tbody>
                                </table>

                                {/* How to use the APP */}
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0px auto 0 auto" }}>
                                    <tbody style={{ margin: "50px 0 0 0" }}>
                                        <tr style={{ padding: "30px", backgroundColor: "#fff" }}>


                                            <td className="col-12"
                                                style={{ lineHeight: "24px", fontSize: "16px", minHeight: "1px", fontWeight: "bold", padding: "30px", width: "50%", margin: 0 }}
                                                align="left" valign="top">
                                                <span className="featuers">How it works</span>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr>

                                            <td className="col-12" style={{ padding: 0, backgroundColor: "#fff" }}>
                                                <img draggable="false"
                                                    src='https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/mask.png'
                                                    className="card-img-top" alt="..." width="655" style={{ margin: "0 auto 0 auto", display: "flex" }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr style={{ backgroundColor: "#fff" }}>

                                            <td className="col-6"
                                                style={{ minHeight: "1px", padding: "42px 20px 39px 20px", width: "50%", margin: 0 }}
                                                align="center" valign="top">
                                                <table>
                                                    <tr>
                                                        <td style={{ fontSize: "48px", color: "#d9e9e9", fontWeight: "bolder" }}>01</td>
                                                        <td style={{ fontSize: "17px", lineHeight: "20px", paddingLeft: "20px" }}>{howItWorks1} &nbsp;
                                                            <a href={refLink} style={{ textDecoration: "underline", color: "black" }}>download the app</a>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <hr style={{ border: "0.5px solid #d9e9e9", width: "100%", marginTop: "20px" }} />
                                            </td>
                                            <td className="col-6"
                                                style={{ minHeight: "1px", padding: "42px 20px 39px 20px", width: "50%", margin: 0 }}
                                                align="center" valign="top">
                                                <table>
                                                    <tr>
                                                        <td style={{ fontSize: "48px", color: "#d9e9e9", fontWeight: "bolder" }}>02</td>
                                                        <td style={{ fontSize: "17px", lineHeight: "20px", paddingLeft: "20px" }}>{howItWorks2}</td>
                                                    </tr>
                                                </table>
                                                <hr style={{ border: "0.5px solid #d9e9e9", width: "100%", marginTop: "20px" }} />
                                            </td>


                                        </tr>
                                        <tr style={{ backgroundColor: "#fff" }}>

                                            <td className="col-6"
                                                style={{ minHeight: "1px", padding: "42px 20px 39px 20px", width: "50%", margin: 0 }}
                                                align="center" valign="top">
                                                <table>
                                                    <tr>
                                                        <td style={{ fontSize: "48px", color: "#d9e9e9", fontWeight: "bolder" }}>03</td>
                                                        <td style={{ fontSize: "16px", lineHeight: "20px", paddingLeft: "20px" }}>{howItWorks3}</td>
                                                    </tr>
                                                </table>
                                                <hr style={{ border: "0.5px solid #d9e9e9", width: "100%", marginTop: "20px" }} />
                                            </td>
                                            <td className="col-6"
                                                style={{ minHeight: "1px", padding: "42px 20px 39px 20px", width: "50%", margin: 0 }}
                                                align="center" valign="top">
                                                <table>
                                                    <tr>
                                                        <td style={{ fontSize: "48px", color: "#d9e9e9", fontWeight: "bolder" }}>04</td>
                                                        <td style={{ fontSize: "17px", lineHeight: "20px", paddingLeft: "20px" }}>{howItWorks4}</td>
                                                    </tr>
                                                </table>
                                                <hr style={{ border: "0.5px solid #d9e9e9", width: "100%", marginTop: "20px" }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr>
                                            <td className="col-12" style={{ padding: 0, backgroundColor: "#fff" }}>

                                                <a href={videoLink}
                                                    target="_blank" rel="noreferrer"><img draggable="false"
                                                        src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/Component162 233@2x.png"
                                                        className="img-fluid btnImage" alt="download" width="380" height="53px"
                                                        style={{ display: "flex", margin: "20px auto" }} /></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* End How to use the APP */}

                                {/* Links */}
                                <table
                                    //  align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", maxWidth: "655px", margin: "0 auto" }} id='downloadSection'>
                                    <tbody>
                                        <tr>

                                            <td className="col-12" style={{ padding: 0, textAlign: "center" }}>
                                                <br /> <br />
                                                <span style={{ margin: "0 0px", display: "inline-block" }}><a
                                                    href="https://apps.apple.com/eg/app/nowpay/id1453539431"
                                                    target="_blank" rel="noreferrer"><img draggable="false"
                                                        src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/eng@2x.png"
                                                        className="img-fluid lineDivided" alt="line"
                                                        width="151" /></a></span>&nbsp;&nbsp;&nbsp;
                                                <span style={{ display: "inline-block" }}><a
                                                    href="https://play.google.com/store/apps/details?id=com.nowpay.nowpay&hl=en&gl=US"
                                                    target="_blank" rel="noreferrer"><img draggable="false"
                                                        src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/eng-2@2x.png"
                                                        className="img-fluid lineDivided" alt="line"
                                                        width="151" /></a></span>

                                                <br /><br />
                                                <img draggable="false"
                                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/path-48399@3x.png"
                                                    className="img-fluid lineDivided" alt="line" width="651" />
                                                <br />
                                                <br />
                                                <p style={{ color: "#6b6c7d", margin: 0 }}>Follow us on</p><br />
                                                <div>
                                                    <span style={{ display: "inline-block" }}><a
                                                        href="https://web.facebook.com/NowPayDotCash" target="_blank" rel="noreferrer">
                                                        <img draggable="false"
                                                            src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/group-7323@2x.png"
                                                            className="img-fluid lineDivided" alt="line" width="31"
                                                            height="30" />
                                                    </a></span>&nbsp;&nbsp;&nbsp;
                                                    <span style={{ margin: "0 0px", display: "inline-block" }}><a
                                                        href="https://www.linkedin.com/company/nowpay/" target="_blank" rel="noreferrer">
                                                        <img draggable="false"
                                                            src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/group-7325@2x.png"
                                                            className="img-fluid lineDivided" alt="line" width="31"
                                                            height="30" />
                                                    </a></span>
                                                </div>
                                                <br />
                                                <img draggable="false" style={{ display: "flex", margin: "0px auto 0 auto" }}
                                                    src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/emailLogo/path-48399@3x.png"
                                                    className="img-fluid lineDividedFooter" alt="line" width="651" /><br />
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                {/* End Links */}

                                {/* contact us */}
                                <table
                                    // align="center" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                    style={{ width: "100%", margin: "0 auto" }}>
                                    <tbody>
                                        <tr>
                                            <br />
                                            <td align="center" valign="top">
                                                <footer className="footer" style={{
                                                    textAlign: "center",
                                                    margin: "0px 0 0px 0",
                                                    padding: "0 0 0px 0", width: "100%"
                                                }}>
                                                    <p style={{
                                                        fontSize: "16px",
                                                        lineHeight: "20px",
                                                        color: "#737f89"
                                                    }}>{questionsString + " "}
                                                        <span style={{ textDecoration: "underline" }}>{questionsContact}</span>
                                                    </p><br />

                                                </footer>
                                                <br />
                                            </td>
                                            <br />
                                        </tr>
                                    </tbody>
                                </table>
                                {/* End contact us */}

                            </td>
                        </tr>
                    </tbody>
                </table>
            </table>
        )
    }
}

const mapStateToProp = (state: any) => ({
    englishEmailData: state.actionsReducer.englishEmailData,
})

export default connect(mapStateToProp, null)(WelcomeEmailEnglish)