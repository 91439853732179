import React, { Component } from 'react'
import { Drawer } from 'antd';
import Helper from '../utils/helper';

interface Props {
  drawerLayout: any,
  drawerTitle?: string,
  showDrawer: any,
  cancelDrawer: any,
  visible: boolean,
  drawerWidth?: any,
  hiddenScroll?: boolean
}

class CommanDrawer extends Component<Props> {

  render() {
    const { drawerLayout, drawerTitle, visible, cancelDrawer, drawerWidth, hiddenScroll } = this.props;
    let intFrameWidth: number = window.innerWidth * 0.8;

    return (
      <div>
        <>
          <Drawer
            title={drawerTitle ? drawerTitle : "Create a new account"}
            headerStyle={{ textAlign: Helper.isArabic(drawerTitle) ? 'end' : 'end' }}
            width={drawerWidth < intFrameWidth ? drawerWidth : intFrameWidth}
            // width={320}
            onClose={cancelDrawer}
            visible={visible}
            bodyStyle={{
              paddingBottom: 80, padding: Helper.isArabic(drawerTitle) ? 0 : 24,
              overflowY: hiddenScroll ? 'hidden' : 'auto', direction: Helper.isArabic(drawerTitle) ? 'rtl' : 'ltr',
              overflowX: "hidden"
            }}
          >
            {drawerLayout}
            <Drawer
              title="Two-level Drawer"
              width={320}
              closable={false}
              onClose={() => { }}
              visible={false}
            >
              This is two-level drawer
            </Drawer>
          </Drawer>
        </>
      </div >
    )
  }
}
export default CommanDrawer
