import React from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from 'antd'

import PdfData from './pdfData';

class PdfComponent extends React.Component {

  render() {
    return (
      <div>

        <PdfData ref={(response) => (this.componentRef = response)} content={this.props.content} />
        <div style={{ textAlign: 'center' }}>
          <ReactToPrint
            documentTitle={this.props.fileName ?? 'invoice'}
            content={() => this.componentRef}
            trigger={() => <Button
              style={this.props.btnName ? { color: "#7285a4", borderColor: "#7285a4", width: '200px', margin: '20px 0 0 60px' } :
                { color: "#7285a4", borderColor: "#7285a4" }}>
              {this.props.btnName ?? 'Print To PDF'}
            </Button>}
          />
        </div>
      </div>
    );
  }

}

export default PdfComponent;