import { Form, Row, Col, Button, message, Input } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { patchApi, stepperMoving } from '../../stores/actions';
import UserInfoUI from '../../components/uiInfo';
import helper from '../../utils/helper';

interface Props {
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        suspensionStatus: {
            reason: string,
            comment: string
        }
    }
    patchApi?: any,
    stepperMoving?: any,
}
class UserUnSuspendingForm extends Component<Props> {
    state = {
        unSuspSpin: false,
    }

    unSuspendingAction = async (values: { comment: string }) => {
        this.setState({ unSuspSpin: true })
        const data: any = {
            phone: this.props.info?.phone,
            comment: values?.comment
        }
        const endingCall = await this.props.patchApi('/users/unSuspendUser', data);
        try {
            const res = endingCall.payload;
            if (res.status === 200) {
                console.log(res);
                message.success(res.message);
                helper.resetAccountTableInStepper();
                this.props.stepperMoving(0)
            }
            else {
                console.log(res.message);
                message.error(res.message)
            }
            this.setState({ unSuspSpin: false })
        } catch (error) {
            this.setState({ unSuspSpin: false })
            console.log(error);
            message.error('Something went wrong, please try again');

        }
    }
    render() {
        const { unSuspSpin } = this.state;
        const { info } = this.props
        const suspensionStatusInfo = [
            {
                key: 'Suspension Reason',
                value: info?.suspensionStatus?.reason ? info?.suspensionStatus?.reason : 'NA'
            },
            {
                key: 'Suspension Comments',
                value: info?.suspensionStatus?.comment ? info?.suspensionStatus?.comment : 'NA'
            },
            {
                key: 'Comment',
                value: <Form.Item
                    name="comment"
                    rules={[{ required: true, message: 'Required' }]}

                >
                    <Input.TextArea placeholder="Enter a comment" style={{ height: "32px" }} />
                </Form.Item>
            }
        ]
        return (
            <>
                <Form name="nest-messages" onFinish={this.unSuspendingAction}>
                    <Row>
                        <Col span={24}>
                            <UserInfoUI userData={suspensionStatusInfo} activeKey={1} header={'Suspension Status'} nonEditable={true} collapsible={"disabled"} />
                        </Col>
                        <Col className='colOfAssign' span='24' style={{ display: "flex", justifyContent: "end" }}>
                            <Form.Item>
                                <Button style={{ margin: '22px 0 0 22px ', background: '#7CC58F', borderColor: '#7CC58F', width: '120px' }} htmlType="submit" className='suspendUserBtn' shape="round" type="primary" loading={unSuspSpin} >
                                    Unsuspend
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { patchApi, stepperMoving })(UserUnSuspendingForm)


