import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import ViewInvoice from './viewInvoice';

interface Props{
    lock: boolean
}

class ApproveInvoice extends Component<Props>{

    render() {
       const componentSteps = [
            {
                title: 'Select Company',
                content: <GetInfo isAllCompanies={true} placeHolder='Please select the company'/>,
                id: 0,
            }, {
                title: this.props.lock ? 'Lock Invoice' : 'Unlock Invoice',
                content: <ViewInvoice lock={this.props.lock}/>,
                id: 1
            },
        ]
        return (
            <div className='create-merchant'>
                <Stepper componentSteps={componentSteps} />
            </div>
        )
    }
}

export default ApproveInvoice
