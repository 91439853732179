import { Row, Col, Slider, InputNumber, Button, message, Form } from 'antd'
import React, { useState } from 'react'
import SalaryAdvanceIMG from '../assets/images/Group 26@2x.png';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../apis/axiosRequest';
import helper from '../utils/helper';
import { stepperMoving, getPaymentPlans } from '../stores/actions';
import { paymentPlansApi, recalculateRequestComparisonApi } from '../utils/apisConsts';

interface Props {
    noLimits?: boolean,
    step?: number,
    updateRequest?: boolean,
}
export default function SalaryAdvance(props: Props) {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.actionsReducer.userBalance);

    const requestData = useSelector((state: any) => state.actionsReducer.requestDetails);
    const [amount, setAmount] = useState(props.updateRequest ? requestData?.requestedAmount : 0);
    const [submitSalaryBtn, setSubmitSalaryBtn] = useState(false);
    const [float, setFloat] = useState(false)
    const onSliderChange = (value: any) => {

        setAmount(value);
        setFloat(false)
        if (helper.regexValidation.integerNumbers.test(value) === false && (value && value.length !== 0)) {
            setFloat(true)
            message.error(`The fractions are not allowed`, 5)
        }
    }





    const submitSalaryAdvance = async () => {

        const infoToCreate = {
            serviceID: 1,
            amountToPlan: Number(amount),
            ...(props.noLimits && { exception: 1 })
        }

        const infoToUpdate = {
            advanceIDs: requestData?.advanceID,
            ...((Number(amount) !== Number(requestData?.requestedAmount) || !props.updateRequest) && { amount: Number(amount) }),
            getAllPlanOptions: true
        }
        if (amount >= 100) {
            setSubmitSalaryBtn(true);
            if (props.updateRequest) {
                salaryAdvanceForUpdate(infoToUpdate)
                console.log(infoToUpdate);
                return
            }
            salaryAdvanceForCreate(infoToCreate);
        } else {
            message.error('Minimum amount is 100 EGP', 5)
        }

    }

    const salaryAdvanceForUpdate = async (info: any) => {

        try {
            const getPlans: any = await dispatch(request({ url: recalculateRequestComparisonApi, params: info, method: 'GET' }))
            const data = await getPlans.data;

            if (data.status === 200) {
                if (data?.recalculateRequestComparisons[0]?.newData?.plansOptions.length === 0) {
                    setSubmitSalaryBtn(false)
                    message.error(`You don't have plans`, 5);
                    setSubmitSalaryBtn(false);
                    return
                }

                await dispatch(getPaymentPlans(data?.recalculateRequestComparisons[0]));
                await setSubmitSalaryBtn(false);
                await dispatch(stepperMoving(props.step));

            } else {
                message.error(data?.data?.failedAdvances[0]?.errors[0], 5)
                setSubmitSalaryBtn(false);
            }
        } catch (error: any) {
            setSubmitSalaryBtn(false);
            message.error('Something went wrong please try again', 5)
        }
    }

    const salaryAdvanceForCreate = async (info: any) => {
        const getPlans: any = await dispatch(request({ url: `${paymentPlansApi}${user.userID}`, data: info, method: 'POST' }))
        const data = await getPlans.data;
        try {
            if (data.status === 200) {
                if (data.plans.plansOptions.length === 0) {
                    setSubmitSalaryBtn(false)
                    message.error(`You don't have plans`, 5);
                    setSubmitSalaryBtn(false);
                    return
                }

                await dispatch(getPaymentPlans(data.plans));
                await setSubmitSalaryBtn(false);
                await dispatch(stepperMoving(props.step));


            } else {
                message.error(data.message, 5)
                setSubmitSalaryBtn(false);
            }
        } catch (error) {
            setSubmitSalaryBtn(false);
            message.error('Something went wrong please try again', 5)
        }
    }

    return (
        <>
            {console.log(requestData)}
            <div className='salarySlider'>
                <Row style={{ justifyContent: 'center' }}>
                    <Col span={24}>
                        <img className='salaryAdvance' width='250' height='250' src={SalaryAdvanceIMG} alt="" />
                    </Col>
                    <Col span={24}>

                        <span className='sliderText' style={{ margin: '0' }}>Current Balance</span>
                        <span className='sliderText'>{user?.balance}</span>
                    </Col>
                    <Col span={12}>
                        <Slider
                            min={0}
                            max={user?.balance}
                            step={50}
                            onChange={onSliderChange}
                            value={amount}
                            disabled={!requestData.editableAmount && props.updateRequest}
                        />
                    </Col>
                    <Col span={4}>
                        <Form>
                            <Form.Item initialValue={'5000'} >
                                <InputNumber

                                    min={0}
                                    max={user?.balance}
                                    style={{ margin: '0 16px' }}
                                    value={amount}
                                    onChange={onSliderChange}
                                    step={50}
                                    disabled={!requestData.editableAmount && props.updateRequest}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Button className={float ? 'submitSliderDisabled' : 'submitSlider'} disabled={float} shape="round"
                            type="primary" loading={submitSalaryBtn} onClick={submitSalaryAdvance}>
                            Submit
                        </Button>

                    </Col>
                </Row>
            </div>
        </>
    )
}
