import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { getApi } from '../stores/actions';
import Spinner from './spinner';
import OutPutResult from './result';
import { ReloadOutlined } from '@ant-design/icons';
interface Props {
    getApi: any,
    columns: any[],
    rows?: any[],
    handleTableChange?: any,
    pagination: any,
    loading: boolean,
    scrolling: any,
    gettingData?: any,
    apiLink?: string,
    hiddenRefreshing?: boolean,
    loadData?: boolean,
}
class ReusableDataTable extends Component<Props> {
    state = {
        tableRows: [],
        loader: false,
        errorMode: false,
        errorMessage: ''
    }
    componentDidMount() {
        this.props.loadData && this.getDataForTable()
    }
    getDataForTable = async () => {
        this.setState({ loader: true })
        const data = await this.props.getApi(this.props.apiLink);
        try {
            const res = await data.payload;
            if (res.status === 200) {
                console.log(res);
                this.setState({ tableRows: res.data, loader: false })

            } else if (res.status === 204) {
                this.setState({ loader: false, errorMode: true, errorMessage: res.message })
            }
            else {
                this.setState({ loader: false, errorMode: true, errorMessage: res.message })
            }
        } catch (error) {
            console.log(error);
            this.setState({ loader: false, errorMode: true, errorMessage: `Something went wrong, please try again` })

        }
    }
    refreshPage = () => {
        window.location.reload();
    }
    refreshRequests = () => {
        this.getDataForTable()
    }
    render() {
        const { tableRows, loader, errorMode, errorMessage } = this.state;
        const { columns, rows, handleTableChange, pagination, loading, scrolling, hiddenRefreshing } = this.props;
        return <div>
            {!hiddenRefreshing && <ReloadOutlined style={{ fontSize: '20px', float: 'right', margin: '0px 10px 10px 0 ' }} onClick={this.refreshRequests} />}
            {loader ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> :
                <Table
                    columns={columns}
                    dataSource={rows ? rows : tableRows}
                    scroll={scrolling}
                    onChange={handleTableChange}
                    pagination={pagination}
                    loading={loading}
                    size={'middle'}
                    bordered={true} />}
        </div>;
    }
}

export default connect(null, { getApi })(ReusableDataTable);
