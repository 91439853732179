import React, { Component } from 'react'
import GetInfo from '../../../components/getInfo';
import Stepper from '../../../components/stepper';
import Partner from './partner';


class PopularPartner extends Component {
    state = {
        componentSteps: [
            {
                title: 'Get Partners',
                content: <GetInfo onePartner apiLink='partners/getPartners/' isPartner placeHolder='Select the partner' />,
                // content: <GetInfo apiLink='companies/getOneCompany/' isNonDeployedCompanies={true} />,
                id: 0,
            }, {
                title: 'Popular Partner',
                content: <Partner />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default PopularPartner
