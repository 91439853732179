import { Component } from "react";
import { Form, Input, Button } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  isAuthintecated,
  changePhoneNumberStatus,
  changePinCodeStatus,
  changeForgotPasswordStatus,
  getAccessRoutes,
  request,
} from "../stores/actions";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NotificationAlert from "./notificationAlert";

interface Props extends RouteComponentProps<any> {
  isAuthintecated: any;
  changePhoneNumberStatus: any;
  changePinCodeStatus: any;
  changeForgotPasswordStatus: any;
  phoneNumber: string;
  getAccessRoutes: any;
  request: any;
  pinCode: string;
}
class SubmittingNewPassword extends Component<Props> {
  state = {
    btnLoader: false,
    successNotification: true,
    errorMessage: null,
    loginAgain:false,
    passChanged:false
  };

  onFinish = async (values: any) => {
    this.responseHandling(true, true);
    const info = {
      phone: this.props.phoneNumber,
      password: values.password,
      code: this.props.pinCode,
    };
    try {
      const submitPassword = await this.props.request({
        url: `/auth/change-password`,
        method: "put",
        data: info,
      });
      if (submitPassword.error) {
        this.responseHandling(false, false);
        return 0;
      }
      if (submitPassword.data.status === 200) {
        this.responseHandling(true, false);
        this.setState({passChanged:true})
        this.goLogin()
      }
      else {
        this.responseHandling(true, false);
        if (submitPassword.data.status === 404 || submitPassword.data.status === 403) {
           this.setState({loginAgain:true})
        }
        this.setState({ errorMessage: submitPassword.data.message });
      }
    } catch (error: any) {
      console.log(
        "🚀 ~ file: submitNewPassword.tsx ~ line 69 ~ SubmittingNewPassword ~ onFinish= ~ error",
        error
      );
      this.responseHandling(false, false);
    }
  };

  responseHandling = (successNotification: any, btnLoader: boolean) => {
    this.setState({
      successNotification: successNotification,
      btnLoader: btnLoader,
    });
  };
  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  goLogin = () => {
    this.props.changePhoneNumberStatus(false);
    this.props.changePinCodeStatus(false);
    this.props.changeForgotPasswordStatus(false);
  };

  render() {
    const { btnLoader, successNotification, errorMessage, loginAgain, passChanged } = this.state;
    return (
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <span className="note">
            To conferm with our Password policy,
            <br />
            <ul>
              <li>Minimum limit 8</li>
              <li>Maxmum limit 25</li>
              <li>Characters minimum 1</li>
              <li>Numbers minimum 1</li>
              <li>Symbols minimum 1</li>
              <li>Capital Leters minimum 1</li>
            </ul>
          </span>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                pattern: new RegExp(
                  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
                ),
                message: "Invalid password format!",
              },
              { min: 8, message: `Password can't be less than 8 chars` },
              { max: 25, message: `Password can't be more than 25 chars` },
            ]}
          >
            <Input.Password placeholder="Password"/>
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          {errorMessage && (
            <>
              <span style={{ float: "left", color: "#ff4d4f" }}>
                {errorMessage}
              </span>
              {loginAgain&&<span style={{ float: "left", color: "#ff4d4f" }}>
                , <span style={{ color: "#ff4d4f", textDecoration:"underline", cursor:"pointer" }} onClick={this.goLogin}>login again</span>
              </span>}
              <br />
            </>
          )}
          <Button type="primary" htmlType="submit" block loading={btnLoader}>
            Create
          </Button>
          <br />
        </Form>
        {!successNotification && (
          <NotificationAlert
            message={"Something went wrong, please try again later"}
            placement={"topLeft"}
            duration={4}
            icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />}
          />
        )}
        {passChanged && (
          <NotificationAlert
            message={"Your password has been changed successfully"}
            placement={"topLeft"}
            duration={4}
            icon={<CheckCircleOutlined style={{ color: "#59a948c2" }} />}
          />
        )}
      </div>
    );
  }
}

const mapStateToProp = (state: any) => ({
  phoneNumber: state.auth.phoneNumber,
  pinCode: state.auth.pinCode,
});
export default withRouter(
  connect(mapStateToProp, {
    isAuthintecated,
    changeForgotPasswordStatus,
    changePhoneNumberStatus,
    changePinCodeStatus,
    getAccessRoutes,
    request,
  })(SubmittingNewPassword)
);
