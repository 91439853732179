import { Form, Row, Col, InputNumber, Button } from 'antd'
import React, { useState } from 'react';
import { deleteScheduledApi } from '../../utils/apisConsts';
import { deleteAndTerminate } from './deleteAndTerminateFunc'

interface Props{
    placeholder?: string,
    API?: string,
    validationMessage?: string,
}
export default function DeleteSchedule({placeholder, API, validationMessage}:Props) {
    const idRef: { current: any } = React.useRef();
    const [loader, setLoader] = useState(false)
    const resetID = () => {
        idRef.current!.resetFields();
    }
    const getValues = (values: { id: number }) => {
        const method: string = 'PATCH';
        const apiLink: string = API? API : deleteScheduledApi;
        const data: { id: number } = { id: values.id }
        deleteAndTerminate(method, apiLink, data, resetID, setLoader)
    }
    return (
        <>
            <div className="userInfo">
                <Form name="nest-messages" onFinish={getValues} ref={idRef}>
                    <Row>
                        <Col className='phoneForm' span={8}>
                            <>
                                <Form.Item name='id' rules={[
                                    { required: true, message: validationMessage? validationMessage : 'Schedule ID is mandatory' }]}
                                    style={{ width: '100%' }}>
                                    <InputNumber placeholder={placeholder? placeholder : 'Enter The Schedule ID'} />
                                </Form.Item>
                            </>

                            <Form.Item>
                                <Button className='userBtn' shape="round" type="primary" loading={loader} block htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}
