import React, { Component } from 'react'
import { Steps, Form, Descriptions, InputNumber, Button, Row, Col, Modal, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { CheckCircleTwoTone } from '@ant-design/icons';
import NotificationAlert from '../components/notificationAlert';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { getApi, patchApi } from "../stores/actions";
import Helper from '../utils/helper';

const { Step } = Steps;
interface Props extends RouteComponentProps<any> {
    getApi: any,
    patchApi: any,
}
class CancelRequest extends Component<Props> {
    formRef = React.createRef<FormInstance>();
    state = {
        current: 0,
        requestId: null,
        submitGetRequestBtn: false,
        visibleModal: false,
        loadingConfirm: false,
        successNotification: null,
        cancellationReason: '',
        errorLength: false,
        areaErrors: [],
        errorMessage: '',
        requestInfo: {},
    }
    async componentDidMount() {
    }
    getRquest = async (value: any) => {
        console.log(value.requestId);
        this.setState({ requestId: value.requestId, submitGetRequestBtn: true })
        const request = await this.props.getApi(`requests/getRequest/${value.requestId}`);
        console.log('request = ', request);
        try {
            if (request.payload.status === 200) {
                console.log(request.payload);
                this.setState({ requestInfo: request.payload.requestDetails, current: 1, submitGetRequestBtn: false, });

            } else {
                console.log(request.payload.message);
                this.setState({ errorMessage: request.payload.message, submitGetRequestBtn: false, })
            }
        } catch (error) {
            console.log(error);
            this.setState({ errorMessage: 'Something went wrong,please try again', submitGetRequestBtn: false, })
        }
    }
    onStepperClick = (step: number) => {
        if (this.state.current === 0) {
            return
        }
        this.setState({ current: step })
    }
    handleCancelRequestButton = () => {
        this.setState({ visibleModal: true, successNotification: false });
        // this.formRef.current!.resetFields();
    }
    handleAcceptCancelRequest = async () => {
        if (this.state.cancellationReason.length === 0) {

            this.setState({ errorLength: true });
            return
        }
        if (this.state.areaErrors.length > 0) {
            return
        }
        this.setState({ loadingConfirm: true })
        const reason = {
            cancellationReason: this.state.cancellationReason,
        }
        const caneclRequest = await this.props.patchApi(`requests/cancelRequest/${this.state.requestId}`, reason);
        try {
            if (caneclRequest.payload.status === 200) {
                this.formRef.current!.resetFields();
                this.setState({
                    loadingConfirm: false, visibleModal: false, current: 0,
                    successNotification: true, cancellationReason: ''
                });
            } else {
                this.setState({
                    loadingConfirm: false, errorMessage: caneclRequest.payload.message, cancellationReason: ''
                });
            }
        } catch (error) {
            this.setState({ loadingConfirm: false, errorMessage: 'Something went wrong,please try again', cancellationReason: '' });
        }
    }
    handleAreaChange = (e: { target: { value: string; }; }) => {
        let value = e.target.value;

        this.setState({ cancellationReason: value, errorLength: false })

    }
    areaChange = (e: any) => {
        this.setState({ cancellationReason: e[0].value, areaErrors: e[0].errors, errorLength: false, errorMessage: '' })
    }
    onCancelModel = () => {
        this.setState({ visibleModal: false, errorLength: false, errorMessage: '', cancellationReason: '' })
        this.formRef.current!.resetFields();
    }
    reqNumChange = (e: any) => {
        this.setState({ errorMessage: '' })
        console.log(e[0].value);
    }

    render() {
        const { current, submitGetRequestBtn, visibleModal, loadingConfirm, successNotification,
            requestId, errorLength, errorMessage, requestInfo } = this.state;
        const infoGorRequest = [];
        for (const [key, value] of Object.entries(requestInfo)) {
            infoGorRequest.push(
                <Descriptions.Item label={Helper.unCamelCase(key)}>{value} </Descriptions.Item>
            );
        }
        const cancelRequestLayout = <>
            <Descriptions
                title={`Request#${requestId}`}
                bordered
                size={'small'}
                extra={<Button className='cancelBtn' type="default" shape='round' onClick={this.handleCancelRequestButton}>Cancel</Button>}
            >
                <>
                    {infoGorRequest}
                </>
            </Descriptions>
        </>
        return (
            <div className='cancelRequest'>
                {/* <button>To Users</button> */}
                <Steps current={current}>
                    <Step title="Request Number" onStepClick={() => this.onStepperClick(0)} />
                    <Step title="Cancel Request" />
                </Steps>
                <div>
                    <div className="getRequestSection">


                        {current === 0 &&

                            <Form name="nest-messages" onFieldsChange={this.reqNumChange} ref={this.formRef} onFinish={this.getRquest}>
                                <Row>
                                    <Col span='8'></Col>
                                    <Col span='8'>
                                        <Form.Item name={['requestId']} rules={[{
                                            type: 'number', min: 1, max: 10000000,
                                            required: true, message: 'Request ID is mandatory'
                                        }]} style={{ width: '100%' }}>
                                            <InputNumber placeholder='Enter the request ID' />

                                        </Form.Item>
                                        <span style={{ margin: '15px 0 0 0', color: '#ff4d4f' }}>{errorMessage}</span>
                                        <Form.Item>
                                            <Button className='getRequestBtn' shape="round" type="primary" block htmlType="submit" loading={submitGetRequestBtn}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span='8'></Col>
                                </Row>


                            </Form>
                        }
                    </div>
                    <div className="cancelRequestSection">
                        {current === 1 && cancelRequestLayout}
                    </div>
                </div>
                <div className='modal'>
                    <Modal
                        confirmLoading={loadingConfirm}
                        title="Cancel Confirmation"
                        centered
                        visible={visibleModal}
                        okText='Yes'
                        cancelText='No'
                        onOk={this.handleAcceptCancelRequest}
                        onCancel={this.onCancelModel}
                    >
                        <label> Reason </label><span style={{ color: '#ff4d4f' }}>*</span>
                        <Form name="areaa" ref={this.formRef} onFieldsChange={this.areaChange}>
                            <Form.Item name={['user', 'introduction']} rules={[
                                { min: 10, max: 150, message: 'The reason must be between 10 and 150 characters', },
                            ]}>
                                <Input.TextArea id={errorLength ? 'areaa_user_introduction-error' : ''} />
                            </Form.Item>
                        </Form>


                        {errorLength && <span style={{ color: '#ff4d4f', position: 'relative', bottom: '0px' }}>Reason is requierd</span>}
                        {errorMessage && <span style={{ color: '#ff4d4f', position: 'relative', bottom: '0px' }}>{errorMessage}</span>}
                        <p className='confirmationText'>Are you sure you want to cancel this request #{requestId} ?</p>
                    </Modal>
                </div>
                {successNotification === true && <NotificationAlert message={'Request canceled successfully'} placement={'topLeft'} duration={4} icon={<CheckCircleTwoTone twoToneColor="#52c41a" />} />}
                {/* {successNotification === false && <NotificationAlert message={'Cancel request has been failed'} placement={'topLeft'} duration={4} icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />} />} */}

            </div>
        )
    }
}

export default withRouter(connect(null, { getApi, patchApi })(CancelRequest))
