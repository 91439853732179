import { Row, Col, Button, Table, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hrStepper, getAllUsersCount, getUsersToApprove, stepperMoving, postApi, patchApi } from '../../stores/actions';
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import OutPutResult from '../../components/result';
import { exportToCSV } from '../../components/exportExcel';

interface IRootState {
    [key: string]: any;
    usersCount: number,
    hrStep: number,
    usersToApprove: any,
}
interface Props {
    apiLink: string,
    isUpdate?: boolean,
}
const columns: any = [

    {
        title: 'Part Number',
        dataIndex: 'partNumber',
    },
    {
        title: 'Product Name',
        dataIndex: 'englishTitle',
    },
    {
        title: 'Reasons',
        dataIndex: 'rejectionReason',
        render: (record: { problem: any }) =>
            <>
                <ul style={{ margin: '0', padding: '0' }}>
                    {Array.isArray(record) && record?.map((x: any, key: any) => {
                        return <li style={{ color: '#CC4949', listStyle: 'none' }} key={key}>{x}</li>
                    })}
                </ul>
            </>
    },
];
export default function Confirmation(props: Props) {
    const [loader, setLoader] = useState(false)
    const [reason, setReasons] = useState([]);
    const [errorMode, setErrorMode] = useState(false);
    const [numOfProducts, setNumOfProducts] = useState(0);
    const [numOfSuccessProducts, setNumOfSuccessProducts] = useState(0);
    const [numOfFailedProducts, setNumOfFailedProducts] = useState(0);
    const [hideTable, setHideTable] = useState(false);
    const [rejectedProducts, setRejectedProducts] = useState([]);

    const dispatch = useDispatch();
    const dataSheet: any = useSelector<IRootState>(state => state.actionsReducer.dataSheet);


    const createProducts = useCallback(async (dataSheet: any) => {
        let usersCount;
        const data = {
            products: dataSheet.sheetData
        }

        try {
            const apiData: any = await dispatch(props.isUpdate ? patchApi(props.apiLink, data) : postApi(props.apiLink, data));

            const res = await apiData.payload;
            console.log("🚀 ~ file: confirmation.tsx:57 ~ createProducts ~ res", apiData)

            if (res.status === 200) {

                if (res?.numberOfRejected === 0) {
                    setHideTable(true);
                }
                setNumOfProducts(res?.productsLength)
                setNumOfSuccessProducts(res?.numberOfSuccess);
                setNumOfFailedProducts(res?.numberOfRejected);
                dispatch(getAllUsersCount(usersCount));
                dispatch(getUsersToApprove(res?.data?.sheetData))
                setLoader(false);
                setReasons(res?.rejectedProducts);
                const rejectedProds = res?.rejectedProducts.map((prod: any) => {
                    delete prod.rejectionReason;
                    return {
                        ...prod
                    }
                })
                setRejectedProducts(rejectedProds)



                return
            };
            setLoader(false);
            setErrorMode(true);
        } catch (error) {
            // setEditFailed(true);
            setLoader(false);
            setErrorMode(true)
        }
    }, [dispatch, props.apiLink, props.isUpdate]);
    useEffect(() => {
        setLoader(true)
        createProducts(dataSheet)
    }, [createProducts, dataSheet])

    const antIcon = <LoadingOutlined style={{ fontSize: 100, color: '#7CC58F' }} spin />;
    return (
        <>
            <div className="approveUsers" style={{ textAlign: 'center' }}>


                {loader ? <div className='waiting'>
                    <Spin indicator={antIcon} style={{ margin: '20px 0' }} />
                    <p className='check'>Verifying the file and loading the data ...</p>
                    <p className='checkHint'>Wait... This process may take several minutes</p>
                </div> : errorMode ? <OutPutResult status={'error'} subTitle={' '} title={'Sorry, Data upload failed'} btnClick={() => dispatch(hrStepper(0))}
                /> :
                    <div className="uploaded" style={{}}>
                        <Row style={{ textAlign: 'center', margin: '20px 0' }}>
                            <Col span={6}>
                                <div className="all" style={{}}>
                                    All Products {numOfProducts ? numOfProducts : 0}
                                    <CheckCircleOutlined style={{ margin: '0 3px' }} />   </div>
                            </Col>

                            <Col span={6}>
                                <div className="failed" style={{}}>
                                    Failed {numOfFailedProducts ? numOfFailedProducts : 0}
                                    <CheckCircleOutlined style={{ margin: '0 3px' }} />   </div>
                            </Col>

                            <Col span={6}>
                                <div className="successes" style={{}}>
                                    Succeeded {numOfSuccessProducts ? numOfSuccessProducts : 0}
                                    <CheckCircleOutlined style={{ margin: '0 3px' }} />   </div>
                            </Col>
                            {numOfFailedProducts !== 0 && <Col span={6}>
                                <Button className='down' onClick={() => exportToCSV(rejectedProducts, 'Rejected users')}>Download</Button>
                            </Col>}
                        </Row>
                        <div className="table">
                            {!hideTable ? <Table columns={columns} dataSource={reason} pagination={false} /> : <OutPutResult status={'success'} subTitle={' '} title={`The products ${props.isUpdate ? 'updated' : 'created'} successfully`} btnClick={() => dispatch(hrStepper(0))}
                            />}
                        </div>
                        {/* <Button className='reUpload' onClick={() => dispatch(hrStepper(0))}>اعاده تحميل الملف</Button> */}
                    </div>
                }

                <Button className='reUpload' onClick={() => dispatch(stepperMoving(0))}>Re-upload file</Button>
            </div>
        </>
    )
}
