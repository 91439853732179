import AddServiceLimit from '../../components/addServiceLimit';
import AllCompanyInfo from '../../components/companyInfo';
import { useSelector } from 'react-redux';
import { companyInfo } from '../../utils/commonInterfaces';
import { Divider } from 'antd';

interface IRootState {
    [key: string]: {
        info?: companyInfo,
    }
}
export default function UpdateCompanyCaps() {
    const info: any = useSelector<IRootState>(state => state.actionsReducer.info);

    return (<>
        <AddServiceLimit defaultCaps={{
            totalReqLimit: info.maxMonthlyRequestsLimitPerUser,
            reqsNum: info.onGoingAdvancesRequestsPerUser,
            salaryPercent: info.onGoingAdvancesPercentThresholdPerUser
        }}
         id={info.id} url='services/updateCompanyCap'/>
        <Divider orientation="left" className='formTitle'>Company Information</Divider>
        <AllCompanyInfo />

    </>)
}