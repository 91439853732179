import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import ViewCompanyGroups from './viewCompanyGroups';

class CompaniesGroup extends Component{
    state = {
        componentSteps: [
            {
                title: 'Select Company Group',
                content: <GetInfo isHoldingComp={true}/>,
                id: 0,
            }, {
                title: 'View Companies',
                content: <ViewCompanyGroups />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <div className='create-merchant'>
                <Stepper componentSteps={componentSteps} />
            </div>
        )
    }
}

export default CompaniesGroup
