import React, { Component } from 'react';
import { Table } from 'antd';
interface Props {
    columns?: any[],
    rows: any[],
    handleTableChange?: any,
    pagination: any,
    loading: boolean,
    scrolling: any,
    genericColumnsNames?: any,
}
class DataTable extends Component<Props> {
   
    render() {
        const { columns, rows, handleTableChange, pagination, loading, scrolling } = this.props;
        return <div style={{ maxWidth: "100%" }}>
            <Table
                // style={{ height: '400px' }}
                columns={columns ? columns : this.props.genericColumnsNames}
                dataSource={rows}
                scroll={scrolling}
                onChange={handleTableChange}
                pagination={pagination}
                loading={loading}
                bordered={true} />
        </div>;
    }
}

export default DataTable;
