import { Form, Row, Col, Input, Button, message } from 'antd'
import { useRef, useState } from 'react';
import { arabicInputError, englishInputError, errorMessage, firstOption } from '../utils/commonStringsConsts'
import { MinusCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { request } from '../apis/axiosRequest';
import { createDepartmentsApi } from '../utils/apisConsts';
import helper from '../utils/helper';
import { AddIcon } from '../utils/commonIcons';


export default function CreateDepartments() {
    const dispatch = useDispatch();
    const formRef: any = useRef([]);
    const [loader, setLoader] = useState(false);
    const handleCreateDepartments = async (values: any) => {
        setLoader(true);
        try {
            const apiData: any = await dispatch(request({ url: createDepartmentsApi, method: 'POST', data: values }));
            const resp = await apiData.data;
            console.log(resp);

            if (resp.status === 200) {
                setLoader(false);
                message.success(resp.message, 6);
                formRef.current?.resetFields();
                return
            }
            message.error(resp.message, 6);
            setLoader(false);
        } catch (error) {
            message.error(errorMessage, 6);
            setLoader(false);
        }

    }

    return (
        <div className="createDepartment">
            <Form
                ref={formRef}
                initialValues={{ departments: firstOption }}
                onFinish={handleCreateDepartments}>
                <Form.List name="departments">

                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <>
                                    <Row key={field.key}>

                                        <Col span={12}>
                                            <label htmlFor="">English Name </label>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'nameEn']}
                                                rules={[{ required: true, message: 'Required' },
                                                { pattern: helper.regexValidation.englishRegex, message: englishInputError }]}                                            // rules={[{ required: true, message: 'Missing amount' }]}
                                            >
                                                <Input placeholder='Enter the english name ' />
                                            </Form.Item>

                                        </Col>
                                        <Col span={12}>
                                            <label htmlFor="">Arabic Name </label>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'nameAr']}
                                                rules={[{ required: true, message: 'Required' },
                                                { pattern: helper.regexValidation.arabicRegex, message: arabicInputError }]}                                            // rules={[{ required: true, message: 'Missing amount' }]}
                                            >
                                                <Input placeholder='Enter the arabic name ' />
                                            </Form.Item>

                                        </Col>


                                        <Col span={fields.length !== 1 ? 23 : 24}>
                                            <label htmlFor="">Description</label>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'description']}>
                                                <Input.TextArea placeholder='Enter the description ' />
                                            </Form.Item>

                                        </Col>
                                        {fields.length !== 1 && <Col span={1}>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} style={{ margin: '40px 0 0 20px' }} />
                                        </Col>}


                                    </Row>
                                </>
                            ))}



                            {fields.length <= 128 && <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={AddIcon} style={{ margin: '20px 0' }}>
                                    Add
                                </Button>
                            </Form.Item>}
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loader}>
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}
