import { Form, Row, Col, Button, Radio, ConfigProvider, InputNumber, Descriptions } from 'antd'
import { useSelector, useDispatch } from 'react-redux';

import { move, postApi } from '../../../stores/actions';
import { useState } from 'react';
// import helper from '../../../utils/helper';
import TruckingModal from '../modal';
import helper from '../../../utils/helper';
import { truckingApis } from '../../../utils/commonStringsConsts';
// import { useHistory } from 'react-router-dom'
interface IRootState {
  [key: string]: {
    driverData: {
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      previousExpenses: number,
      sumOldSuccessfulTransactions: number,
      sumOldVerifiedTransactions: number,
      sumThisCycleSuccessfulTransactions: number,
      sumThisCycleVerifiedTransactions: number,
      userBanks: any,
      expectedPayroll: number,
      allExpenses: number,
      payroll: number,
      from: string,
      to: string,
    }
  };
}
function Salary() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const driverSelector: any = useSelector<IRootState>(state => state.truckingReducer.driverData);
  const [openTruckingModal, setOpenTruckingModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [sending, setSending] = useState(false)
  const [totalSalary, setTotalSalary] = useState(Number);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [payrollResp, setPayrollResponse] = useState({
    actionBy: {
      firstName: '',
      lastName: '',
      middleName: '',
    },
    referenceNumber: '',
  });




  const closeTruckingModal = () => {
    setOpenTruckingModal(false);

  }

  const confirmPayroll = async (values: any) => {
    console.log("🚀 ~ file: payrollRequest.tsx ~ line 52 ~ confirmPayroll ~ values", values)
    setSending(true)
    setTotalSalary(values.salary)
    const data = {
      userID: driverSelector?.id,
      userBankID: selectedBankID !== 0 ? selectedBankID : driverSelector?.userBanks.length > 0 ? driverSelector?.userBanks[0].id : null,
      amount: values.salary,
    }
    const driverExpenses: any = await dispatch(postApi(`${truckingApis}transactions/payroll`, data));
    console.log("🚀 ~ file: payrollRequest.tsx ~ line 64 ~ confirmPayroll ~ driverExpenses", driverExpenses)
    try {
      const res = await driverExpenses.payload;
      if (res.status === 433) {
        setOpenTruckingModal(true)
        setSending(false)
        setModalTitle(' رسالة خطأ');
        setModalMessage('لا يوجد رصيد كافي')
        return
      }
      if (res.status !== 200) {

        setOpenTruckingModal(true)
        setSending(false)
        setModalTitle(' رسالة خطأ');
        setModalMessage(' حدث خطأ ما رسالة خطأ برجاء التواصل معنا علي رقم 01119849885 الخاص بناوباي')

        return
      }
      setPayrollResponse(res.data)
      setOpenTruckingModal(true)
      setModalTitle('تأكيد');
      setModalMessage('تم طلب تحويل الراتب بنجاح')

      setSending(false)


    } catch (error) {
      setOpenTruckingModal(true)
      setModalTitle('رسالة خطأ');
      setModalMessage(' حدث خطأ ما رسالة خطأ برجاء التواصل معنا علي رقم 01119849885 الخاص بناوباي')
      setSending(false)
    }
    console.log("🚀 ~ file: payrollRequest.tsx ~ line 110 ~ confirmPayroll ~ data", data);
  }


  const changeBanks = (evt: any) => {
    setSelectedBankID(evt.target.value)
  }
  const approveRequest = () => {
    setOpenTruckingModal(false);
    if (modalMessage.includes('بنجاح')) {
      dispatch(move(0))
    }
  }

  let toExpenses = <>
    <div className="reviewOrderBalance">
      <div style={{ textAlign: 'center' }}>
        <h3>{modalMessage}</h3>
      </div>
      {payrollResp?.actionBy?.firstName && <Descriptions bordered>
        {<Descriptions.Item span={3} label="رقم الطلب">{payrollResp?.referenceNumber || 'لا يوجد'}</Descriptions.Item>}
        {<Descriptions.Item span={3} label="من">{`${payrollResp?.actionBy?.firstName || 'لا يوجد'} ${payrollResp?.actionBy?.lastName || ''}`}</Descriptions.Item>}
        {<Descriptions.Item label="إلي" span={3}>{driverSelector.name ? driverSelector?.firstName + ' ' + driverSelector?.lastName : driverSelector?.name}</Descriptions.Item>}
        {<Descriptions.Item span={3} label="المبلغ">{(totalSalary && `${helper.amountFormatter(totalSalary)} جنية`) || '0'}</Descriptions.Item>}
        {<Descriptions.Item label="التاريخ" span={3}>
          {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}
        </Descriptions.Item>}
      </Descriptions>}
      <div className='btns' style={{ textAlign: 'center' }}>
        <Button className='approve' onClick={approveRequest}>موافق</Button>
      </div>
    </div>
  </>

  return (

    <div>
      <ConfigProvider direction="rtl">
        {
          <>
            <div className="site-card-border-less-wrapper" style={{ background: '#fbfbfbfa', padding: '20px' }} >

              <div>

                <div className="userInfo"  style={{ padding: '20px', borderRadius: '5px', background: '#fff' }}>
                  <Row>
                    <Col xs={24} md={12} lg={12}>
                      <h3 className='cartType' >اسم المستلم</h3>
                      <h3 className='cartNum'>{driverSelector.name ? driverSelector?.firstName + ' ' + driverSelector?.lastName : driverSelector?.name}</h3>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <h3 className='cartType' >رقم المستلم</h3>
                      <h3 className='cartNum'>{driverSelector?.phone || 'لا يوجد'}</h3>
                    </Col>
                    <Col xs={24} style={{ margin: '35px 0 0 0',textAlign:'right' }}>
                      <h3 className='cartType' >الرقم القومي للمستلم</h3>
                      <h3 className='cartNum'>{driverSelector?.nationalID || 'لا يوجد'}</h3>
                    </Col>
                  </Row>



                </div>
              </div>

            </div>

            <div className='collectExpense'>
              <Form
                initialValues={{ type: "type_1" }}
                onFinish={confirmPayroll}
              >
                {driverSelector?.userBanks.length > 0 && <div className="site-card-border-less-wrapper" style={{ background: '#fbfbfbfa' }}>
                  <h2>  طريقة الدفع </h2>
                  <div className="expenseForm" >
                    <label className='worthy'>إجمالي المستحق</label>
                    <Form.Item name="salary" rules={[{ required: true, message: 'اجباري' }, { type: 'number', min: 0, message: `القيمة غير صحيحة` },]}>
                      <InputNumber placeholder='إجمالي المستحق للمستلم' />
                    </Form.Item>
                    <Form.Item name="bank" rules={[{ required: true, message: 'يجب إختيار طريقه للدفع' }]}>
                      <Radio.Group onChange={changeBanks}>
                        {Array.isArray(driverSelector?.userBanks) && driverSelector.userBanks.map((radio: any, key: any) => {
                          return <Radio value={radio.id} key={key} style={{ color: '#000' }}>
                            <span className='cartType' >{radio.bankId.nameAr}</span> <br />
                            <span className='cartNum'>{radio.cardNumber}</span>
                          </Radio>
                        })}
                      </Radio.Group>
                    </Form.Item>
                    {/* <hr /> */}
                    <br />

                    <div className='submitExpense'>
                      <Button className='confirm' type="primary" loading={sending} htmlType="submit">
                        تأكيد الطلب
                      </Button>
                      <Button className='back' type="primary" onClick={() => dispatch(move(0))}>
                        رجوع
                      </Button>
                    </div>
                  </div>
                </div>}

              </Form>

            </div>


          </>}
        <TruckingModal handleCloseModal={closeTruckingModal} visibleModal={openTruckingModal} content={toExpenses} title={modalTitle} />
      </ConfigProvider>

    </div>
  )
}
export default Salary

