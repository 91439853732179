import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import { Button } from 'antd';
import { connect } from 'react-redux';
import { changePhoneNumberStatus, addPhoneNumber, postApi } from "../stores/actions";
import { CloseCircleOutlined } from '@ant-design/icons';
import NotificationAlert from './notificationAlert';
interface PROPS {
    changePhoneNumberStatus: any
    addPhoneNumber: any,
    postApi: any,
    externalChangeFunction?: any,
    externalOnKey?: any,
    externalSubmitPhoneNumber?: boolean,
    value?: any
}
class PhoneNumber extends Component<PROPS> {
    state = {
        phone: '',
        phoneIsValid: false,
        submitDisable: true,
        btnLoader: false,
        successNotification: true,
        errorMessage: null,
    }
    phoneOnChange = (value: string) => {
        this.setState({ errorMessage: null })
        let regexEgypt = /^((\+?20)|0)?1[0125]\d{8}$/;
        // regexUSA = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

        if (regexEgypt.test(value)) {
            this.setState({ phoneIsValid: false, submitDisable: false, phone: value.slice(-11) })
        }
        else {
            this.setState({ phoneIsValid: true, submitDisable: true })
        }
    }
    handlePhoneNumber = async () => {
        if (this.state.submitDisable) {
            return
        }
        const info = {
            phone: this.state.phone
        }
        this.responseHandling(true, true)
        const sendOtp = await this.props.postApi(`auth/send-otp`, info);
        console.log('sendOtp = ',sendOtp);
        
        try {
            // if (sendOtp.error) {
            //     this.responseHandling(false, false)
            //     return 0;
            // }
            if (sendOtp.payload.status === 200) {
                this.props.changePhoneNumberStatus(true)
                this.props.addPhoneNumber(this.state.phone)
                this.responseHandling(true, false)
            } else {
                this.responseHandling(true, false)
                this.setState({ errorMessage: sendOtp.payload.message })
                console.log(this.state.errorMessage);

            }
            console.log(sendOtp.payload);

        } catch (error) {
            this.responseHandling(false, false)
        }
    }
    responseHandling = (successNotification: any, btnLoader: boolean) => {
        this.setState({ successNotification: successNotification, btnLoader: btnLoader })
    }
    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (this.state.submitDisable) {
            return
        }
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handlePhoneNumber()

        }
    }
    render() {
        let { phone, phoneIsValid, submitDisable, btnLoader, successNotification, errorMessage } = this.state;
        let { externalChangeFunction, externalSubmitPhoneNumber, externalOnKey, value } = this.props;
        return (
            <div className='phoneSection'>
                <div className="num">
                    <PhoneInput
                        country={'eg'}
                        onlyCountries={['eg']}
                        disableDropdown
                        value={externalChangeFunction ? value : phone}
                        onChange={externalChangeFunction ? externalChangeFunction : this.phoneOnChange}
                        containerStyle={{
                            width: "0"
                        }}
                        disableCountryCode={true}
                        placeholder='Mobile Number'
                        onKeyDown={externalOnKey ? externalOnKey : this.onKeyDown}
                    />
                </div>
                {phoneIsValid && <><span className='validNumber'>Phone number is invalid</span><br /></>}
                {errorMessage && <><span className='validNumber'>{errorMessage}</span><br /></>}
                {!externalSubmitPhoneNumber && <Button className={submitDisable ? 'disabled-ant-btn' : 'phoneBtn'} type="primary" htmlType="submit" block disabled={phoneIsValid}
                    onClick={this.handlePhoneNumber} loading={btnLoader}>Submit</Button>}
                {!successNotification && <NotificationAlert message={'Something went wrong, please try again later'} placement={'topLeft'} duration={4} icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />} />}
            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    phone_verified: state.auth.phone_verified,
    pin_code_verified: state.auth.pin_code_verified,
    forgot_password: state.auth.forgot_password,
})
export default connect(mapStateToProps, { changePhoneNumberStatus, addPhoneNumber, postApi })(PhoneNumber)