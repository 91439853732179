import { message } from 'antd';
import { request } from '../../apis/axiosRequest';
import store from '../../stores/index'

export const deleteAndTerminate = async (method: string | any, apiLink: string, data: any, resetID: any, setLoader: any) => {
    const dispatch = store.dispatch;
    try {
        setLoader(true);
        const apiData: any = await dispatch(request({ method: method, url: apiLink, data: data }));
        const res = await apiData.data;
        if (res.status === 200) {
            setLoader(false);
            message.success(res.message);
            resetID()
            return
        }
        message.error(res.message);
        setLoader(false);

    } catch (error: any) {
        setLoader(false);
        message.error(error.response.data.message)

    }
}
