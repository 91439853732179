import { Descriptions, Result } from "antd"
import { exportToCSV } from "./exportExcel"
import ModalCompnent from "./modal"

interface errorsType {
    userID?: number,
    phone?: number,
    requestID?: number,
    invoiceNumber?: string,
    errors: string[]
}

interface Props {
    errors: errorsType[],
    visibleModal: boolean,
    handleCloseModal: any,
    errorMessage?: string
}

export default function BulkErrorsModal(props: Props) {
    const generateErrorsList = (errors: string[]) => {
        return <ul>
            {errors && Array.isArray(errors) && errors.map((error) => <li>{error}</li>)}
        </ul>
    }
    const generateErrorsTable = () => {
        const rows = props.errors && Array.isArray(props.errors) &&
            props.errors.map((errorRow: errorsType) => <Descriptions.Item span={4} label={ errorRow.phone || errorRow.userID || errorRow.invoiceNumber || errorRow.requestID }>{generateErrorsList(errorRow.errors)}</Descriptions.Item>)
        return <>
            <Result
                status="error"
                style={{ padding: "30px" }}
                title={<p style={{ fontSize: '18px', padding: 0 }}>{props.errorMessage ? props.errorMessage : "Data hasn't been Updated"}</p>}
            />
            <Descriptions bordered>
                <Descriptions.Item span={3} labelStyle={{minWidth: "100px"}} label={<b>{props.errors[0] ? (props.errors[0].phone ? "Phone" : props.errors[0].invoiceNumber? "Invoice Number" : props.errors[0].requestID? "Request ID" : "User ID") : ""}</b>}><b>Errors Descriptions</b></Descriptions.Item>
                {rows}
            </Descriptions>
        </>
    }

    const downloadErrorReport = () => {
        if (!props.errors || !Array.isArray(props.errors)) {
            return
        }
        let errors: any[] = []
        for (let index = 0; index < props.errors.length; index++) {
            errors = [
                ...errors,
                ...props.errors[index].errors.map((error) => (
                    {
                        ...(props.errors[index].userID ? { userID: props.errors[index].userID } : props.errors[index].invoiceNumber? {invoiceNumber: props.errors[index].invoiceNumber} : props.errors[index].requestID ? { requestID:  props.errors[index].requestID } : { phone: props.errors[index].phone }),
                        errors: error
                    }
                ))
            ]
        }
        exportToCSV(errors, "Errors Report")
    }

    return (
        <ModalCompnent content={generateErrorsTable()}
            visibleModal={props.visibleModal} handleCloseModal={props.handleCloseModal} handleAcceptModal={downloadErrorReport} cancelText="Close" okText="Download Errors Report" />
    )
}