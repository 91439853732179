import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import CreateEmailForm from './createEmailForm';
import SendEmail from './sendEmail'

class CreateWelcomeEmail extends Component{
    state = {
        componentSteps: [
            {
                title: 'Select Company',
                content: <div style={{marginTop:"30px"}}><GetInfo apiLink='companies/getOneCompany/' isDeployedCompanies={true}/></div>,
                id: 0,
            },
            {
                title: 'Configure',
                content: <CreateEmailForm/>,
                id: 1,
            },
            {
                title: 'Send Email',
                content: <SendEmail />,
                id: 2
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <div className='create-merchant'>
                <Stepper componentSteps={componentSteps} />
            </div>
        )
    }
}

export default CreateWelcomeEmail
