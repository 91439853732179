import React, { useState } from 'react'
import { Form, Button, DatePicker, Alert } from 'antd';
import helper from '../../utils/helper';
import { resolveAutomaticFunc } from './resolveAutomaticFunc';

export default function ResolveIssuesByDate() {
  const formFilterRef: { current: any } = React.useRef();
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';
  const [loader, setLoader] = useState(false);

  const resetDates = () => {
    formFilterRef.current!.resetFields();
  }
  const resolveIssuesByDate = async (values: any) => {
    const data = {
      dateFrom: values?.dateRange ? helper.changeDateFormat(values.dateRange[0]._d) : '',
      dateTo: values?.dateRange ? helper.changeDateFormat(values.dateRange[1]._d) : '',
    }
    await resolveAutomaticFunc(data, setLoader, resetDates);
  }
  return (
    <>
      <Alert
        message={<span>Update blocking issues using date<span className='action' style={{ float: 'right' }}
          onClick={() => helper.setRedirect(`https://dashboard.nowpay.cloud/question`)}>Requests with issues dashboard</span></span>}
        type="info" showIcon style={{ margin: '0 0 15px 0' }} />

      <div style={{ textAlign: 'center' }}>

        <Form
          ref={formFilterRef}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={resolveIssuesByDate}
          autoComplete="off"
        >

          <Form.Item name="dateRange" rules={[{ required: true, message: 'Please enter the dates' }]}>
            <RangePicker format={dateFormat} style={{ width: '50%' }} />
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loader} style={{ width: 'fit-content', margin: '25px 0' }}>
              Updating Block Issues
            </Button>
          </Form.Item>

        </Form>

      </div>
    </>
  )
}
