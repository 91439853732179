import { Form, Row, Col, Select, Button, FormInstance, message, Result } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request } from '../stores/actions';
import OutPutResult from '../components/result';
import Spinner from '../components/spinner';
import { errorMessage } from '../utils/commonStringsConsts';
import helper from '../utils/helper';
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import DataTable from '../components/dataTable';
import Helper from '../utils/helper';
import XLSX from "xlsx";
import SheetTemplate from '../components/bulkActionSheetTemplate';
import { bulkUpdateRequestsStatusesTemplate } from '../utils/bulkSheetsTemplates';
import BulkErrorsModal from '../components/bulkErrorsModal';
import { getAdvancesStatusesAPI, updateRequestStatusAPI } from '../utils/apisConsts';
import TransactionErrorModal from '../components/transactionErrorModal';

const { Option } = Select;

interface Props {
    request?: any,
    removeData: boolean,
}

class BulkUpdateRequestStatus extends Component<Props> {
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        statuses: [],
        errorMode: false,
        sending: false,
        tableData: [],
        tableColumns: [],
        tableLoading: false,
        pagination: {},
        uploadingError: false,
        visibleModal: false,
        uploadingErrorMessage: "",
        validationErrors: [],
        resErrorContent: undefined,
        visibleMessageModal: false,
        statusesLoading: false,
        loaderUploading: false,
    }
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.getStatuses()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
            this.clearData()
        }
    }

    sendRequests = async (values: { status: number }) => {
        this.setState({ sending: true })
        try {
            const advanceIDs = this.state.tableData.map((row: any) => row.requestID)
            const changeReqStatusRes: any = await this.props.request({ url: updateRequestStatusAPI, method: "PATCH", data: { newStatus: values.status, advanceIDs } })
            if (changeReqStatusRes.data.status === 200) {
                message.success(changeReqStatusRes.data.message, 4)
                this.setState({ tableData: [] })
            }
            else if (changeReqStatusRes.data.status === 422) {
                this.setState({
                    resErrorContent: changeReqStatusRes.data.message,
                    validationErrors: changeReqStatusRes.data.data.failedAdvances,
                    visibleModal: true
                })
            }
            else if (changeReqStatusRes.data.status === 400) {
                this.setState({
                    resErrorContent: {
                        message: changeReqStatusRes.data.message,
                        errorData: changeReqStatusRes.data.reason
                    },
                    visibleMessageModal: true
                })
            }
            else {
                message.error(changeReqStatusRes.data.message, 4)
            }
            this.setState({ sending: false })
        }
        catch (error: any) {
            this.setState({ sending: false })
            message.error(errorMessage, 4)
        }
    }

    getStatuses = async () => {
        this.setState({ statusesLoading: true })
        try {
            const statuses: any = await this.props.request({ url: getAdvancesStatusesAPI, method: "GET", params: { select: "bulkUpdateRequestStatus" } })
            if (statuses.data.status === 200) {
                this.setState({ statuses: statuses.data.data.advancesStatusTypes, statusesLoading: false })
            }
            else {
                this.setState({ errorMode: true, statusesLoading: false })
            }
        }
        catch (error: any) {
            this.setState({ statusesLoading: false, errorMode: true })
        }
    }

    handleFile(file: any /*:File*/) {
        this.setState({ tableLoading: true, uploadingError: false })

        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            let fileReader = new FileReader(), rows: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {
                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rows = helper.sheetToJson(workbook.Sheets[sheet]);
                });

                const validation: any = helper.validateUploadSheetColumns(rows, bulkUpdateRequestsStatusesTemplate, true);
                // this.setState({ loaderUploading: true })
                if (!validation.success) {
                    this.setState({
                        tableLoading: false, uploadingError: true,
                        uploadingErrorMessage: validation.message,
                        loaderUploading: false
                    })
                    return
                }
                const tableColumns = helper.generateTableColumns(validation.data)
                this.setState({ tableData: validation.data, tableLoading: false, tableColumns, loaderUploading: false })

            }
        }
    }

    handleUploadFile = (e: { target: { files: any, value: any }; }) => {
        this.setState({ tableData: [], uploadingError: false, loaderUploading: true });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        // to clear input value to be able to add the same file again
        e.target.value = null;
    }

    handleTablePagination = (e: any) => {
        this.setState({
            pagination: e, tableLoading: true
        })
        setTimeout(() => this.setState({ tableLoading: false }), 1500)
    }

    openSelectFile = () => {
        this.fileInputRef.current!.click();
    }

    handleCloseModal = () => {
        this.setState({ visibleModal: false, visibleMessageModal: false })
    }

    clearData = () => {
        this.setState({ tableData: [] })
    }

    render() {
        const { statuses, errorMode, sending, tableColumns, pagination, tableData, tableLoading, uploadingError, visibleModal, uploadingErrorMessage, validationErrors, resErrorContent, visibleMessageModal,
            statusesLoading, loaderUploading } = this.state;
        return (
            <>
           
            <div className='statusBulkUpdate'>
                
                <input
                    hidden
                    type="file"
                    className="form-control"
                    id="file"
                    ref={this.fileInputRef}
                    accept=".csv,.xlsx"
                    onChange={this.handleUploadFile}
                />
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Col span={5}><Button disabled={loaderUploading} icon={<UploadOutlined />} onClick={this.openSelectFile} >Click to Upload file</Button></Col>
                    <Col span={19}>{tableData.length > 0 ? <><b>Uploaded Rows Count: </b> {tableData.length} {tableData.length > 1 ? "Requests" : "Request"}</> : "Please Upload your file as shown in the following table, Only the following data will be sent"}</Col>
                </Row>
                {tableData.length === 0 && <SheetTemplate columns={bulkUpdateRequestsStatusesTemplate} />}
                {loaderUploading && <Spinner size={'large'} caption='Requests are uploading ...'/>}
                {(Array.isArray(tableData) && tableData.length > 0 && !uploadingError) &&
                    <>
                        {tableLoading ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                            <div className='tableSection'>
                                <Form className='statusForm' name="nest-messages" ref={this.formRef} onFinish={this.sendRequests}>
                                    <Row>
                                        <Col className='module' span='8' style={{ padding: '0 15px 0 0' }}>
                                            <label>statuses </label>
                                            <Form.Item
                                                name="status"
                                                rules={[{ required: true, message: 'Required' }]}

                                            >
                                                <Select
                                                    loading={statusesLoading}
                                                    placeholder="Select a status">
                                                    {statuses.map((status: any, key: number) => {
                                                        return <Option value={status.advancesStatusTypeID} status={status} key={key}>{status.StatusType}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col className='colOfAssign' span='4'>
                                            <Form.Item>
                                                <Button style={{ margin: '22px 0 0 0 ' }} className='changeUserStatusBtn' shape="round" type="primary" block htmlType="submit" loading={sending} >
                                                    Change
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <DataTable columns={tableColumns}
                                    pagination={pagination}
                                    rows={tableData}
                                    handleTableChange={this.handleTablePagination}
                                    loading={tableLoading}
                                    scrolling={{ x: 1000 }} />
                            </div>}</>
                }
                {
                    uploadingError &&
                    <Result
                        icon={<FrownOutlined />}
                        title={uploadingErrorMessage}
                    />
                }
                <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={this.handleCloseModal} errorMessage={resErrorContent} />
                <TransactionErrorModal resErrorContent={resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={this.handleCloseModal} />

            </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, { request })(BulkUpdateRequestStatus) 
