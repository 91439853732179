import { message } from 'antd';
import { request } from '../../apis/axiosRequest';
import store from '../../stores';
import { resolveSystemIssuesApi } from '../../utils/apisConsts';
import { errorMessage } from '../../utils/commonStringsConsts';


export const resolveAutomaticFunc = async (data?: any,setLoading?: any, resetDates?: any) => {
  const dispatch = store.dispatch

  setLoading(true);

  try {
    const apiData: any = await dispatch(request({ method: 'PATCH', url: resolveSystemIssuesApi, data: data }));
    const res = apiData.data;

    if (res.status === 200) {
      if (res?.resolved && res?.unresolved) {
        setLoading()
        if (res?.unresolved.length > 0) {
          message.error('Not all issues have resolved, check requests with issues dashboard', 4);
          return res
        }
        resetDates();
        message.success('Updated successfully', 4);
        return
      }
      setLoading();
      
      message.info(res.message, 4);
      resetDates();
      return
    }
    setLoading()
    message.error(res.message, 4)
  } catch (error) {
    setLoading()
    message.error(errorMessage, 4)
  }
}
