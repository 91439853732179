
import React, { Component } from 'react'
import { connect } from 'react-redux';
import GetBalance from '../../../components/getBalance';
import PaymentPlans from '../../../components/paymentPlans';
import SalaryAdvance from '../../../components/salaryAdvance';
import Stepper from '../../../components/stepper';
import { getRequestApi } from '../../../utils/apisConsts';

interface Props {
    info: any,
    noLimits: boolean,
}


class UpdateRequest extends Component<Props> {

    state = {
        componentSteps: [
            {
                title: 'Request ID',
                content: <div className='phoneNumber'><GetBalance update={true} apiLink={getRequestApi} /></div>,
                id: 0,
            }, {
                title: 'Salary Advance',
                content: <SalaryAdvance step={3} updateRequest noLimits={this.props.noLimits} />,
                id: 1
            },
            {
                title: 'Payment Plans',
                content: <PaymentPlans noLimits={this.props.noLimits} isUpdate />,
                id: 3
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <div className='createRequest'>
                    <Stepper componentSteps={componentSteps} />
                </div>
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, {})(UpdateRequest)
