import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/spinner';
import { getDeductionsFilters, stepperMoving, getDataInfo } from '../../stores/actions';
import { noFilterDataMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';
// import React from 'react'
// import { request } from '../../apis/axiosRequest'
// import { errorMessage } from '../../utils/commonStringsConsts'
// import userStatus from '../userStatus/userStatus'

export default function FilterInvoices() {
    const { Option } = Select;
    const dispatch = useDispatch();
    const [companies, setCompanies] = useState([]);
    const [loadCompanies, setLoadCompanies] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [companyMandatory, setCompanyMandatory] = useState(false)
    const [hasCompany, setHasCompany] = useState(false);
    const [hasUserID, setHasUserID] = useState(false);
    const [hasRequestID, setHasRequestID] = useState(false);
    const [hasStaffID, setHasStaffID] = useState(false);

    useEffect(() => {
        storeCompanies()

    }, [])
    const storeCompanies = async () => {
        try {
            setLoadCompanies(true);
            const allComp: any = await helper.getCompanies();
            if (allComp.status === 200) {
                setLoadCompanies(false);
                setCompanies(allComp.data.data);
                return
            };
            setLoadCompanies(false);
            setErrorMode(true)
            message.error(allComp.message);
        } catch (er: any) {
            setLoadCompanies(false);
            setErrorMode(true)
            message.error('Something went wrong with companies');

        }

    }
    const dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const changeStaffID = (e: any) => {
        let staffIDValue: string = e.target.value;
        if (staffIDValue.length > 0) {
            setCompanyMandatory(true);
            setHasStaffID(true);
        } else {
            setCompanyMandatory(false);
            setHasStaffID(false);
        }

    }

    const changeUserID = (e: any) => {
        onChangingInputs(e, setHasUserID)
    }

    const changeRequestID = (e: any) => {
        onChangingInputs(e, setHasRequestID)
    }
    const onChangingInputs = (values: any, dynamicState: any) => {
        let data: string = values.target.value;
        if (data.length > 0) {
            dynamicState(true);
        } else {
            dynamicState(false);
        }
    }


    const selectedCompany = (values: any, data: any) => {
        if (values) {
            setHasCompany(true);

            dispatch(getDataInfo(data))
            return
        }
        // console.log("🚀 ~ file: filterInvoices.tsx:61 ~ selectedCompany ~ values", values);
        setHasCompany(false);
    }

    const getInvoicesParams = (values: any) => {
        if (!values.requestID && !values.userID && !values.companyID && !values.staffID) {
            message.error(noFilterDataMessage, 4);
            return;
        };
        dispatch(getDeductionsFilters(values))
        dispatch(stepperMoving(1));
        // console.log("🚀 ~ file: getInvoices.tsx:13 ~ getInvoicesParams ~ value", values)
    }
    return (
        <>
            <div className='deduct'>
                {loadCompanies ? <Spinner size={'large'} /> : <Form name="nest-messages" onFinish={getInvoicesParams}>
                    <Row>

                        <Col className='deductForm' span={8}>

                            {<Form.Item name={"companyID"} rules={[{ required: companyMandatory, message: 'Company is mandatory with staffID ' }]}>
                                <Select style={{ width: '100%' }}
                                    placeholder={'Please select the company'}
                                    showSearch
                                    allowClear
                                    disabled={hasUserID || hasRequestID || errorMode}
                                    onChange={selectedCompany}
                                    filterOption={((input, option: any) => dropDownSearch(input, option))}
                                >
                                    {Array.isArray(companies) && companies.map((company: any, key: number) => {
                                        return <Option value={company.id} data={company} key={key}>{company.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            }
                            {<>
                                <Form.Item name={'staffID'} style={{ width: '100%' }}>
                                    <Input placeholder={'Enter The Staff ID'} onChange={changeStaffID} disabled={hasUserID || hasRequestID || errorMode} />
                                </Form.Item>
                            </>}
                            {<>
                                <Form.Item name={'requestID'} style={{ width: '100%' }}>
                                    <Input placeholder={'Enter The Request ID'} disabled={hasCompany || hasUserID || hasStaffID} onChange={changeRequestID} />
                                </Form.Item>
                            </>}
                            {<>
                                <Form.Item name={'userID'} style={{ width: '100%' }}>
                                    <Input placeholder={'Enter The User ID'} disabled={hasCompany || hasRequestID || hasStaffID} onChange={changeUserID} />
                                </Form.Item>
                            </>}

                            <Form.Item>
                                <Button className='btn' shape="round" type="primary" loading={false} block htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>}
            </div>
        </>
    )
}
