
import { STEP,SET_DRIVER_DATA } from "./types"; 

export const move = (value: number) => {
    return (dispatch: any) => dispatch({
        type: STEP,
        payload: value
    })
}

export const setDriverData = (value: {}) => {
    return (dispatch: any) => dispatch({
        type: SET_DRIVER_DATA,
        payload: value
    })
}