import axios from '../stores/requests';

export const getComponies = () => {
    return async ( _dispatch:any) => {
        try {
            const { data } = await axios.get(`/companies/locations`);
            return {
                payload: data
            };
        } catch (e) {
            return {
                error: true,
            };
        }
    }
}