import React, { Component, createRef } from 'react'

import { Form, Select, Button, Input, InputNumber, Row, Col, message, FormInstance, Switch } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { postApi, getApi } from '../stores/actions';
import Spinner from '../components/spinner';
import OutPutResult from '../components/result';
const { Option } = Select;


// let arr = [] as any
interface Props {
    postApi: any,
    getApi: any
}
class CreateFeesModel extends Component<Props> {
    formRef = createRef<FormInstance>();

    state = {
        isInterest: false,
        loading: false,
        inValidKey: null,
        errorMessage: '',
        fees: [],
        loadingFeesTypes: true,
        errorMode: false,
        feesModelType: 1,
        feesModelTypes:[{
            "typeID": 1,
            "name": "Normal Fees"
        }]
    }
    componentDidMount() {
        this.getFesTypes();
        this.getFeesModelTypes();
    }
    handleChange = (val: number, selectType:("feesTypeID" | "feesModelType")) => {
        console.log(val);
        if (selectType === "feesTypeID") {
            if (val === 1) {
                this.setState({ isInterest: true })
            } else {
                this.setState({ isInterest: false })
            }
            return
        }
        this.setState({feesModelType: val},()=>{

        })
        console.log("🚀 ~ file: createFeesModel.tsx ~ line 46 ~ CreateFeesModel ~ this.setState ~ feesModelType", this.state.feesModelType)
    }
    getFesTypes = async () => {
        const feesTypes = await this.props.getApi(`/fees/getFeesType`);
        try {
            const res = await feesTypes.payload;
            if (res.status === 200) {
                this.setState({ fees: res.data, loadingFeesTypes: false })
            } else {
                this.setState({ errorMode: true, loadingFeesTypes: false })
            }
        } catch (error) {
            this.setState({ errorMode: true, loadingFeesTypes: false })
        }
    }
    sendModelBreacketsRequest = async (data: any) => {
        this.setState({ loading: true })
        const sendBrackets = await this.props.postApi('fees/createFeesModel', data);
        try {
            const res = await sendBrackets.payload;
            if (res.status === 200) {
                this.setState({ loading: false });
                message.success(res.message, 3);
                this.formRef.current!.resetFields();
            } else {
                this.setState({ loading: false });
                message.error(res.message, 3);
            }
        } catch (error) {
            this.setState({ loading: false });
            message.error(`Something went wrong, please try again`, 3);
        }
    }
    getData = (values: any) => {
        let firstData = { rgStart: values.rgStart, amount: values.amount }
        let projects = values.projects;
        let isWork = false;
        // if (projects) {
        //     projects.unshift(firstData)
        // }else{
        //     projects = null
        // }
        if (!projects || projects.length === 0) {
            // this.setState({ loading: true })
            const data = {
                name: values.name,
                feesTypeID: values.feesTypeID,
                description: values.description,
                brackets: [{ rgStart: values.rgStart, amount: values.amount }],
                feesModelTypeID: values.feesModelType,
                ...(values.feesModelType === 2 && {applyOnFirstDeduction: values.ApplyOnFirstDeduction})
            };
            // console.log(data);
            // console.log('projects in if = ', projects);


            this.sendModelBreacketsRequest(data)

            return
        } else {
            projects.unshift(firstData)
            console.log('projects in else = ', projects);
            let element = values.projects[0].rgStart;
            

            for (let index = 0; index < values.projects.length; index++) {
                const el = values.projects[index];
                if (index > 0) {
                    if (index <= values.projects.length - 1) {
                        if (element >= el.rgStart) {
                            console.log('element = ', element, 'start = ', el.rgStart);
                            isWork = false
                            message.error(`Start = ${el.rgStart} can't be equal or smaller than start = ${element} please edit it and try again`, 7);
                            this.setState({
                                inValidKey: index -1
                                
                                , errorMessage: el.rgStart === element ? `This start can't be equal to the previous start
                            ` : `This start can't be smaller than the previous start`
                            });
                            // this.scrollingToError()

                            break
                        } else {

                            element = values.projects[index].rgStart;
                            isWork = true
                        }
                    }
                }
            }
        }



        if (isWork) {
            const data = {
                name: values.name,
                feesTypeID: values.feesTypeID,
                description: values.description,
                brackets: values.projects,
                feesModelTypeID: values.feesModelType,
                ...(values.feesModelType === 2 && {applyOnFirstDeduction: values.ApplyOnFirstDeduction})
            };
            this.sendModelBreacketsRequest(data)
            

        }
    }
    scrollingToError = () => {
        let element: any = document.getElementById('error');
        element.scrollIntoView({ behavior: 'smooth' });
    }
    changeStart = (key?: any, _value?: any) => {
        if (this.state.inValidKey === key) {
            this.setState({ inValidKey: null })
        }
    }
    refreshPage = () => {
        window.location.reload();
    }
    getFeesModelTypes = async () =>{
        const feesModelTypes = await this.props.getApi(`/fees/getFeesModelsTypes`);
        try {
            const res = await feesModelTypes.payload;
            if (res.status === 200) {
                this.setState({ feesModelTypes: res.data, loadingFeesTypes: false })
            } else {
                this.setState({ errorMode: true, loadingFeesTypes: false })
            }
        } catch (error) {
            this.setState({ errorMode: true, loadingFeesTypes: false })
        }
    }
    render() {
        const { isInterest, loading, inValidKey, errorMessage, fees, loadingFeesTypes,
            errorMode, feesModelTypes } = this.state;
        return (
            <div className='feesModel'>
                {loadingFeesTypes ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh and try againg'}
                    btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> :
                    <Form
                        ref={this.formRef}
                        initialValues={{ type: "type_1" }}
                        onFinish={this.getData}
                    >
                        <Row>
                            <Col span={12}>
                                <label htmlFor="">Name</label>
                                <Form.Item
                                    name={'name'}
                                    rules={[{ required: true, message: 'Missing name' }]}
                                >
                                    <Input style={{ width: '90%' }} placeholder='Enter the model name' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Fees Type</label>
                                <Form.Item name="feesTypeID" rules={[{ required: true, message: 'Missing Fees Type' }]}>
                                    <Select onChange={(val: number)=>this.handleChange(val,"feesTypeID")} style={{ width: '100%' }} placeholder='Select the fees type'>
                                        {fees.map((service: any, key: number) => {
                                            return <Option value={service.id} key={key}>{service.type}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Fees Model Type</label>
                                <Form.Item name="feesModelType" rules={[{ required: true, message: 'Missing Fees Model Type' }]} initialValue={1}>
                                    <Select onChange={(val: number)=>this.handleChange(val,"feesModelType")} style={{ width: '90%' }} placeholder='Select fees Model type'>
                                        {feesModelTypes.map((ModelType: any, key: number) => {
                                            return <Option value={ModelType.typeID} key={key}>{ModelType.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.feesModelType === 2 && <Col span={12}>
                                <label htmlFor="">Apply on first deduction</label>
                                <Form.Item name="ApplyOnFirstDeduction" initialValue={false}>
                                    <Switch />
                                </Form.Item>
                            </Col>}
                            <Col span={24}>
                                <label htmlFor="">Description</label>
                                <Form.Item
                                    name={'description'}
                                    rules={[{ required: true, message: 'Missing description' }]}
                                >
                                    <Input.TextArea style={{ width: '100%' }} placeholder='Add description for this model' />
                                </Form.Item>
                            </Col>


                        </Row>

                        <h2>Brackets</h2>
                        <Row>
                            <Col span={12}>
                                <label htmlFor="">Start</label>
                                <Form.Item
                                    name={'rgStart'}
                                    rules={[{ required: true, message: 'Missing start ' }, { type: 'number', min: 0, max: 1000000, message: `Must be between 0 and 1000000 EGP` }]}
                                >
                                    <InputNumber style={{ width: '90%' }} placeholder='Enter the start' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Fees amount</label>
                                <Form.Item
                                    name={'amount'}
                                    rules={[{ required: true, message: 'Missing amount' }, { type: 'number', min: 0, max: isInterest ? 100 : 1000000, message: isInterest ? 'Must be between 0 and 100' : 'Must be between 0 and 1000000 EGP' },]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder='Enter amount of fees' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.List name="projects">

                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(field => (
                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) =>
                                                            prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                        }
                                                    >
                                                        {() => (
                                                            <>
                                                                <label htmlFor="">Start</label>
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'rgStart']}
                                                                    rules={[{ required: true, message: 'Missing start ' }, { type: 'number', min: 0, max: 1000000, message: `Must be between 0 and 1000000` }]}
                                                                >

                                                                    <InputNumber style={{ width: '90%' }} id={field.key === inValidKey ? 'error' : ''} onChange={(e: any) => this.changeStart(field.key, e)} placeholder='Enter the start' />

                                                                </Form.Item>
                                                                {field.key === inValidKey && <span style={{ color: 'red', position: 'relative', bottom: '5' }}>{errorMessage}</span>}
                                                                {console.log('field.key',field.key,' === ',inValidKey,' inValidKey')}
                                                            </>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={11}>
                                                    <label htmlFor="">Fees amount</label>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'amount']}
                                                        rules={[{ required: true, message: 'Missing amount' }, { type: 'number', min: 0, max: isInterest ? 100 : 1000000, message: isInterest ? 'Must be between 0 and 100' : 'Must be between 0 and 1000000' }]}
                                                    >
                                                        <InputNumber style={{ width: '90%' }} placeholder='Enter amount of fees' />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={1}>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '27px', padding: '0 0px' }} />


                                                </Col>


                                            </Row>
                                        </>
                                    ))}



                                    {fields.length <= 128 && <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add
                                        </Button>
                                    </Form.Item>}
                                </>
                            )}
                        </Form.List>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </div>
        )
    }
}
export default connect(null, { postApi, getApi })(CreateFeesModel)
