import React from 'react'
import { connect } from 'react-redux';
import ViewDrivers from './viewDrivers';
import ExpenseRequest from './expenseRequest';
interface data {
    step?: number
}
function expensesParent(props: data) {
    const { step } = props;
    return (
        <>
            {step === 0 ? <ViewDrivers /> : <ExpenseRequest />}
        </>
    )
}
const mapStateToProps = (state: any) => ({
    step: state.truckingReducer.step,
})
export default connect(mapStateToProps, null)(expensesParent)
