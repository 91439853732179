import { ConfigProvider, Steps } from 'antd';
// import React, { useState } from 'react'
import Step1 from './step1';
import Step2 from './step2';
import { useSelector } from 'react-redux';
import Step3 from './step3';

const { Step } = Steps;

const steps = [
    {
        title: 'اختيار الملف وتحميله',
        content: <Step1 />,
    },
    {
        title: 'التحقق وتحميل البيانات ',
        content: <Step2 />,
    },
    {
        title: 'تأكيد البيانات',
        content: <Step3 />,
    },
];
interface IRootState {
    [key: string]: any;
    hrStep: number,
}
function BulkUpdateUsersHrInfo() {
    const hrStep: any = useSelector<IRootState>(state => state.actionsReducer.hrStep);
    return (
        <>
            <ConfigProvider direction="rtl">
                <div className='bulkUpdateUsersHrInfo'>
                    <Steps type='navigation' current={hrStep}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">{steps[hrStep].content}</div>

                </div>
            </ConfigProvider>
        </>
    );
}
export default BulkUpdateUsersHrInfo
