import { Form, Row, Col, InputNumber, Button, Switch, FormInstance, message } from 'antd'
import { createRef, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { patchApi } from '../stores/actions';
import helper from '../utils/helper';
import UserInfoUI from './uiInfo';

interface IRootState {
    limit?: number,
    defaultCaps: {
        totalReqLimit: number,
        reqsNum: number,
        salaryPercent: number
    }
    id?: any,
    isUser?: boolean,
    url: string
}
export default function AddServiceLimit(props: IRootState) {
    const dispatch = useDispatch();

    const formRef = createRef<FormInstance>();

    const [sending, setSending] = useState(false)
    const [caps, setCaps] = useState({
        maxMonthlyRequests: props.defaultCaps.totalReqLimit || 0,
        onGoingAdvancesRequests: props.defaultCaps.reqsNum || 0,
        onGoingAdvancesPercentThreshold: props.defaultCaps.salaryPercent || 0,
        maxMonthlyRequestsChecked: props.defaultCaps.totalReqLimit && props.defaultCaps.totalReqLimit > 0 ? true : false,
        salaryAdvanceChecked: (props.defaultCaps.reqsNum && props.defaultCaps.reqsNum > 0) || (props.defaultCaps.salaryPercent && props.defaultCaps.salaryPercent > 0) ? true : false
    });

    const addLimit = (values: any) => {
        console.log("🚀 ~ file: addServiceLimit.tsx ~ line 36 ~ addLimit ~ values", values)
        let data
        if (props.isUser) {
            data = {
                userID: props.id,
                maxMonthlyRequestsLimit: values.maxMonthlyRequestsChecked ? values.maxMonthlyRequests : 0,
                onGoingAdvancesPercentThreshold: values.salaryAdvanceChecked ? values.onGoingAdvancesPercentThreshold : 0,
                onGoingAdvancesRequests: values.salaryAdvanceChecked ? values.onGoingAdvancesRequests : 0
            }
        } else {
            data = {
                companyID: props.id,
                maxMonthlyRequestsLimitPerUser: values.maxMonthlyRequestsChecked ? values.maxMonthlyRequests : 0,
                onGoingAdvancesPercentThresholdPerUser: values.salaryAdvanceChecked ? values.onGoingAdvancesPercentThreshold : 0,
                onGoingAdvancesRequestsPerUser: values.salaryAdvanceChecked ? values.onGoingAdvancesRequests : 0
            }
        }
        addServiceCap(data, values)
    }
    const limitationToggle = (value: boolean, key: string,
        inputKey: "maxMonthlyRequests" | "onGoingAdvancesPercentThreshold" | "onGoingAdvancesRequests") => {
        console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 97 ~ limitationToggle ~ value", value)
        setCaps({
            ...caps,
            [key]: value
        })
        if (!value) {
            formRef.current?.setFields([{
                name: inputKey,
                value: caps[inputKey],
                errors: []
            }]);
        }
    }
    const addServiceCap = async (data: any, values: any) => {
        setSending(true)

        const cap: any = await dispatch(patchApi(props.url, data));
        try {
            const res = await cap.payload;

            if (res?.status !== 200) {
                setSending(false);
                message.error(res?.message)

                return
            }

            setSending(false);
            setCaps(values)
            message.success(res?.message);

        } catch (error) {
            setSending(false);
            message.error('Something went wrong, please try again')
        }
        console.log("🚀 ~ file: payrollRequest.tsx ~ line 110 ~ confirmPayroll ~ data", data);
    }

    const requestLimitationInfo = [
        {
            key: 'Maximum Active Requests Allowed',
            value: <Row className='servicesInputs'>
                <Col span={24} className="switchCol">
                    <p>Limited:</p>
                    <Form.Item name="salaryAdvanceChecked" initialValue={caps.salaryAdvanceChecked}>
                        <Switch defaultChecked={caps.salaryAdvanceChecked} onChange={(value) => {
                            limitationToggle(value, "salaryAdvanceChecked", "onGoingAdvancesRequests")
                            limitationToggle(value, "salaryAdvanceChecked", "onGoingAdvancesPercentThreshold")
                        }} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={"onGoingAdvancesRequests"}
                        initialValue={caps.onGoingAdvancesRequests}
                        rules={[{ required: caps.salaryAdvanceChecked, message: 'This field is required' },
                        { type: "number", min: caps.salaryAdvanceChecked ? 1 : undefined, message: "The number of requests can't be less than 1" },
                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                        <InputNumber placeholder="Enter number of requests" disabled={!caps.salaryAdvanceChecked} />
                    </Form.Item>
                </Col>
            </Row>
        },
        {
            key: 'Maximum Active Requests Percentage Allowed',
            value: <Row className='servicesInputs'>
                <Col span={24}>
                    <Form.Item name={"onGoingAdvancesPercentThreshold"}
                        initialValue={caps.onGoingAdvancesPercentThreshold}
                        rules={[{ required: caps.salaryAdvanceChecked, message: 'This field is required' },
                        { type: "number", min: caps.salaryAdvanceChecked ? 10 : undefined, max: 100, message: "The percent must be between 10% and 100%" },
                        { pattern: helper.regexValidation.integerNumbers, message: "Fractions and negative values not allowed" }]}>
                        <InputNumber placeholder="Enter the salary percent" disabled={!caps.salaryAdvanceChecked} />
                    </Form.Item>
                </Col>
            </Row>
        },
        {
            key: 'Monthly Requests Limit',
            value: <Row className='servicesInputs'>
                <Col span={24} className="switchCol">
                    <p>Limited</p>
                    <Form.Item name="maxMonthlyRequestsChecked" initialValue={caps.maxMonthlyRequestsChecked}>
                        <Switch defaultChecked={caps.maxMonthlyRequestsChecked} onChange={(value) => limitationToggle(value, "maxMonthlyRequestsChecked", "maxMonthlyRequests")} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={'maxMonthlyRequests'} rules={
                        [{
                            required: true, message: 'Max monthly request is mandatory',
                        },
                        { type: 'number', min: caps.maxMonthlyRequestsChecked ? 1 : undefined, message: `The limit can't be less than 1` },
                        { pattern: helper.regexValidation.integerNumbers, message: `Fractions and negative values not allowed` }
                        ]}
                        initialValue={props.defaultCaps.totalReqLimit}>
                        <InputNumber placeholder='Enter max monthly request' disabled={!caps.maxMonthlyRequestsChecked} />
                    </Form.Item>
                </Col>
            </Row>
        },
        {
            key:"Overview Dashboard",
            value: <span className='action' onClick={() => helper.setRedirect("https://dashboard.nowpay.cloud/dashboard/277")}>https://dashboard.nowpay.cloud/dashboard/277</span>
        }
    ]

    return (
        <>
            <Form onFinish={addLimit} style={{ margin: '20px 0' }} ref={formRef} className="servicesCapsForm">
                <Row>
                    <Col span={24}>
                        <UserInfoUI userData={requestLimitationInfo} activeKey={1} header={'Request Limitations (CAPS)'} nonEditable={true} collapsible={"disabled"} />
                    </Col>

                    <Col className='colOfAssign' span='24' style={{ display: "flex", justifyContent: "end" }}>

                        <Form.Item>
                            <Button className='createCompanyGroupBtn' shape="round" type="primary" htmlType="submit" loading={sending} style={{ margin: '21px 0', padding: "4px 30px", backgroundColor:"#7CC58F", borderColor:"#7CC58F" }}>
                                Update
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
