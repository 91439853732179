enum columnType {
    text = "Text",
    num = "Number"
}

export const bulkUpdateUserInfoTemplate = [
    {
        label: "userID",
        isRequired: false,
        description: "This field accepts only numbers that present Users IDs",
    },
    {
        label: "phone",
        isRequired: false,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "name",
        isRequired: false,
        description: "This field accepts only English characters that present Users names",
    },
    {
        label: "email",
        isRequired: false,
        description: "This field accepts only Email formats that present Users emails ( ex: example@ex.com )",
    },
    {
        label: "nationalID",
        isRequired: false,
        description: "This field accepts only Egyptian national id format that present Users national IDs",
    },
    {
        label: "staff_id",
        isRequired: false,
        description: "This field accepts only numbers that present Users staff IDs",
    },
    {
        label: "bankName",
        isRequired: false,
        description: "This field accepts only these bank names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/2516"
    },
    {
        label: "accountHolderName",
        isRequired: false,
        description: "This field accepts only English characters that present bank account holder name",
    },
    {
        label: "accountHolderNameEn",
        isRequired: false,
        description: "This field accepts only English characters that present bank account holder name (English)",
    },
    {
        label: "accountNumber",
        isRequired: false,
        description: "This field accepts only numbers that present banks accounts numbers",
    },
    {
        label: "netSalary",
        isRequired: false,
        description: "This field accepts only numbers that present Users salaries",
    },
    {
        label: "companyName",
        isRequired: false,
        description: "This field accepts only these companies names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/2517"
    },
    {
        label: "hiringDate",
        isRequired: false,
        description: "This field accepts only dates in this format: MM/DD/YYYY, ex: 05/25/2020",
    },
    {
        label: "jobTitle",
        isRequired: false,
        description: "This field accepts only English characters that present Users job titles",
    },
    {
        label: "departmentName",
        isRequired: false,
        description: "This field accepts only these departments names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/3370"
    }
]

export const bulkUpdateApprovedUserAllInfoTemplate = [
    {
        label: "userID",
        isRequired: true,
        description: "This field accepts only numbers that present Users IDs",
    },
    {
        label: "phone",
        isRequired: false,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "name",
        isRequired: false,
        description: "This field accepts only English characters that present Users names",
    },
    {
        label: "email",
        isRequired: false,
        description: "This field accepts only Email formats that present Users emails ( ex: example@ex.com )",
    },
    {
        label: "nationalID",
        isRequired: false,
        description: "This field accepts only Egyptian national id format that present Users national IDs",
    },
    {
        label: "staff_id",
        isRequired: false,
        description: "This field accepts only numbers that present Users staff IDs",
    },
    {
        label: "bankName",
        isRequired: false,
        description: "This field accepts only these bank names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/2516"
    },
    {
        label: "accountHolderName",
        isRequired: false,
        description: "This field accepts only English characters that present bank account holder name",
    },
    {
        label: "accountHolderNameEn",
        isRequired: false,
        description: "This field accepts only English characters that present bank account holder name (English)",
    },
    {
        label: "accountNumber",
        isRequired: false,
        description: "This field accepts only numbers that present banks accounts numbers",
    },
    {
        label: "netSalary",
        isRequired: false,
        description: "This field accepts only numbers that present Users salaries",
    },
    {
        label: "hiringDate",
        isRequired: false,
        description: "This field accepts only dates in this format: MM/DD/YYYY, ex: 05/25/2020",
    },
    {
        label: "jobTitle",
        isRequired: false,
        description: "This field accepts only English characters that present Users job titles",
    },
    {
        label: "departmentName",
        isRequired: false,
        description: "This field accepts only these departments names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/3370"
    },
    {
        label: "hasMeezaCard",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means the user has Meeza card and 'No' means the user has not Meeza card",
    }
]

export const bulkUpdateApprovedUserInfoTemplate = [
    {
        label: "userID",
        isRequired: true,
        description: "This field accepts only numbers that present Users IDs",
    },
    {
        label: "nationalID",
        isRequired: false,
        description: "This field accepts only Egyptian national id format that present Users national IDs",
    },
    {
        label: "staff_id",
        isRequired: false,
        description: "This field accepts only numbers that present Users staff IDs",
    },
    {
        label: "hiringDate",
        isRequired: false,
        description: "This field accepts only dates in this format: MM/DD/YYYY, ex: 05/25/20205",
    },
    {
        label: "jobTitle",
        isRequired: false,
        description: "This field accepts only English characters that present Users job titles",
    },
    {
        label: "departmentName",
        isRequired: false,
        description: "This field accepts only these departments names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/3370"
    },
    {
        label: "hasMeezaCard",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means the user has Meeza card and 'No' means the user has not Meeza card",
    },
    {
        label: "accountHolderNameEn",
        isRequired: false,
        description: "This field accepts only English characters that present bank account holder name (English)",
    },
]

export const bulkUpdateStatusTemplate = [
    {
        label: "userID",
        isRequired: true,
        description: "This field accepts only numbers that present Users IDs",
    },
    {
        label: "phone",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "companyName",
        isRequired: true,
        description: "This field accepts only these companies names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/2517"
    }
]

export const bulkCreateUserInfoTemplate = [
    {
        label: "phone",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "name",
        isRequired: true,
        description: "This field accepts only English characters that present Users names",
    },
    {
        label: "bankName",
        isRequired: true,
        description: "This field accepts only these bank names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/2516"
    },
    {
        label: "accountHolderName",
        isRequired: true,
        description: "This field accepts only English characters that present bank account holder name",
    },
    {
        label: "accountHolderNameEn",
        isRequired: false,
        description: "This field accepts only English characters that present bank account holder name (English)",
    },
    {
        label: "accountNumber",
        isRequired: true,
        description: "This field accepts only numbers that present banks accounts numbers",
    },
    {
        label: "netSalary",
        isRequired: true,
        description: "This field accepts only numbers that present Users salaries",
    },
    {
        label: "companyName",
        isRequired: true,
        description: "This field accepts only these companies names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/2517"
    },
    {
        label: "email",
        isRequired: false,
        description: "This field accepts only Email formats that present Users emails ( ex: example@ex.com )",
    },
    {
        label: "nationalID",
        isRequired: false,
        description: "This field accepts only Egyptian national id format that present Users national IDs",
    },
    {
        label: "staff_id",
        isRequired: false,
        description: "This field accepts only numbers that present Users staff IDs",
    },
    {
        label: "hiringDate",
        isRequired: false,
        description: "This field accepts only dates in this format: MM/DD/YYYY, ex: 05/25/2020",
    },
    {
        label: "jobTitle",
        isRequired: false,
        description: "This field accepts only English characters that present Users job titles",
    },
]

export const bulkCreateMeezaRequestsTemplate = [
    {
        label: "name",
        isRequired: false,
        description: "This field accepts only English characters that present Users names",
    },
    {
        label: "phone",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "nationalID",
        isRequired: true,
        description: "This field accepts only Egyptian national id format that present Users national IDs",
    },
    {
        label: "batchNumber",
        isRequired: true,
        description: "This field accepts only numbers that present batch number",
    }
]
export const bulkCreateClearanceTemplate = [
    {
        label: "phone",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    }
]
export const bulkCollectionsTemplate = [
    {
        label: "collectionDate",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/DD/YYYY, ex: 05/25/2020",
        colType: columnType.text
    },
    {
        label: "companyName",
        isRequired: true,
        description: "This field accepts only these companies names in the same case: ",
        link: "https://dashboard.nowpay.cloud/question/3113",
        colType: columnType.text
    },
    {
        label: "serialNumber",
        isRequired: true,
        description: "This field presents unique invoice number",
        colType: columnType.text
    },
    {
        label: "amount",
        isRequired: true,
        description: "This field accepts only numbers that present invoice amount in EGP",
        colType: columnType.num
    },
    // {
    //     label: "account",
    //     isRequired: false,
    //     description: "This field accepts only English characters that present customer account",
    //     colType: columnType.text
    // },
    // {
    //     label: "transactionType",
    //     isRequired: false,
    //     description: "This field accepts only English characters that present customer transaction type",
    //     colType: columnType.text
    // }
]

export const bulkCreateEarlyPaymentTemplate = [
    {
        label: "phone",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "amount",
        isRequired: true,
        description: "This field accepts only positive numbers that present the amount",
    },
    {
        label: "invoiceMonth",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },
    {
        label: "Asc",
        isRequired: true,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    }
]

export const bulkCreateProductsTemplate = [
    {
        label: "partNumber",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "technicalModel",
        isRequired: true,
        description: "This field accepts only positive numbers that present the amount",
    },
    {
        label: "storeID",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },
    {
        label: "mainCategory",
        isRequired: true,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "brand",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "englishTitle",
        isRequired: true,
        description: "This field accepts only positive numbers that present the amount",
    },
    {
        label: "arabicTitle",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },
    {
        label: "quantity",
        isRequired: true,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "originalPrice",
        isRequired: true,
        description: "This field accepts only positive numbers that present the amount",
    },
    {
        label: "finalPrice",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },
    {
        label: "imageURL1",
        isRequired: true,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "imageURL2",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "imageURL3",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "imageURL4",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "imageURL5",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "color",
        isRequired: false,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },
    {
        label: "RAM",
        isRequired: false,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    }
]

export const bulkUpdateProductsTemplate = [
    {
        label: "partNumber",
        isRequired: true,
        description: "This field accepts only Egyptian mobile numbers that present Users phones",
    },
    {
        label: "storeID",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },

    {
        label: "quantity",
        isRequired: true,
        description: "This field accepts only Yes or No, choosing 'Yes' means start from early installments and 'No' means start from late installments",
    },
    {
        label: "originalPrice",
        isRequired: true,
        description: "This field accepts only positive numbers that present the amount",
    },
    {
        label: "finalPrice",
        isRequired: true,
        description: "This field accepts only dates in this format: MM/YYYY, ex: 05/2020",
    },
    
]

export const bulkUpdateRequestsStatusesTemplate = [
    {
        label: "requestID",
        isRequired: true,
        description: "This field accepts only numbers that present Requests IDs",
    },
    // {
    //     label: "userID",
    //     isRequired: true,
    //     description: "This field accepts only numbers that present Users IDs",
    // },
]

export const bulkRecalculateRequestsTemplate = [
    {
        label: "requestID",
        isRequired: true,
        description: "This field accepts only numbers that present Requests IDs",
    }
]

export const createRequestsCommentsTemplate = [
    {
        label: "advanceID",
        isRequired: true,
        description: "This field accepts only numbers that present Advances IDs",
    },
    {
        label: "comment",
        isRequired: true,
        description: "This field accepts comments that present advances comment",
    },
]

export const createUsersCommentsTemplate = [
    {
        label: "userID",
        isRequired: true,
        description: "This field accepts only numbers that present Advances IDs",
    },
    {
        label: "comment",
        isRequired: true,
        description: "This field accepts comments that present advances comment",
    },
]

