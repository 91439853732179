
import React, { Component } from 'react'
import Stepper from '../../components/stepper';
import { createBulkProductsApi } from '../../utils/apisConsts';
import { bulkCreateProductsTemplate } from '../../utils/bulkSheetsTemplates';
import Confirmation from './confirmation';
import UploadProds from './uploadProducts';

class BulkCreateProducts extends Component {
    state = {
        componentSteps: [
            {
                title: 'Upload Products',
                content: <UploadProds validCols={bulkCreateProductsTemplate} fileUrl={`https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Nowpay-Docs/initial+Products+file.xlsx`}/>,
                id: 0,
            }, {
                title: 'Confirmation',
                content: <Confirmation apiLink={createBulkProductsApi}  />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <div className='bulkCreateProducts'>
                    <Stepper componentSteps={componentSteps} />
                </div>
            </>
        )
    }
}

export default BulkCreateProducts
