import { message } from "antd"
import { useDispatch, useSelector } from "react-redux"
import CommonDrawer from "./drawer"
import { useEffect, useRef, useState } from "react"
import { stepperMoving } from "../stores/actions"

interface Props {
    children: any,
    actionName: string,
    drawerWidth?: number,
    drawerTitle?: string,
    action: any
}

export default function DrawerBtn(props: Props) {
    const actions = useSelector((state: any) => state.auth.accessRoutes)
    const [showDrawer, setShowDrawer] = useState(false)
    const prevShowDrawer: any = useRef();
    const dispatch = useDispatch()

    const openDrawer = (event: any) => {
        if (actions.length) {
            let actionsArr: string[] = []
            for (let index = 0; index < actions.length; index++) {
                actions[index].actions.map((route: string) => actionsArr.push(route))
            }
            if (!actionsArr.includes(props.actionName)) {
                message.error("You have no permission on this action!", 4)
                return
            }
            setShowDrawer(true)
        }
        else {
            message.error("You have no permissions!", 4)
        }
    }

    useEffect(() => {
        if (showDrawer) {
            prevShowDrawer.current = showDrawer
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDrawer])

    useEffect(() => {
        return () => {
            if (prevShowDrawer.current) {
                prevShowDrawer.current = null
                dispatch(stepperMoving(1))
                setShowDrawer(false)
            }
            else{
                dispatch(stepperMoving(0))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div onClick={(event: any) => event.stopPropagation()}>
            <span onClick={openDrawer}>{props.children}</span>
            <CommonDrawer
                drawerLayout={props.action}
                drawerWidth={900}
                drawerTitle={props.drawerTitle} visible={showDrawer}
                showDrawer={openDrawer} cancelDrawer={() => setShowDrawer(false)} hiddenScroll={false} />
        </div>
    )
}