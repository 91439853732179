import { Descriptions, Image,} from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import Helper from '../utils/helper';

import { useSelector } from 'react-redux';
import { companyInfo } from '../utils/commonInterfaces';
interface IRootState {
    [key: string]: {
        info?: companyInfo,
    }
}
export default function AllCompanyInfo() {
    const info: any = useSelector<IRootState>(state => state.actionsReducer.info);
    const trueIcon = <CheckCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#52c41a" />
    const falseIcon = <CloseCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#eb2f96" />
    const filterCommunicationDays = (cycles: any[]) => {
        if (!cycles) {
            return []
        }
        let days: any[] = []
        for (let index = 0; index < cycles.length; index++) {
            const cycleDays = Object.entries(cycles[index])
                .filter(([key, value]) => value === 1 && key !== "id")
                .map(([key, value]) => key)
            days = [...days, ...cycleDays]
        }
        return <p>{
            days.map((day, index) => {
                if (index !== days.length - 1) {
                    return day + ", "
                }
                return day
            })
        }</p>
    }
    return (
        <>
            <div className="img"
                style={{ textAlign: 'center', margin: '10px 0' }}>
                <Image
                    width={150}
                    src={info?.logoID}
                />
            </div>
            <Descriptions bordered style={{ margin: '15px 0' }}>
                <Descriptions.Item label="Name" span={2}>{info.name ? info.name : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Arabic Name" span={2}>{info.arabicName ? info.arabicName : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Description" span={3}>{info.description ? info.description : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Arabic Description" span={3}>{info.arabicDescription ? info.arabicDescription : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Communication Cycle Days" span={3}>{(info.communicationCycles && info.communicationCycles.length > 0) ? filterCommunicationDays(info.communicationCycles) : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Company Industry" span={2}>{info.companyIndustry ? info.companyIndustry : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Company Type" span={2}>{info.companyType ? info.companyType : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="In-Active Status" span={2}>{info.defaultInactiveNextStatus ? info.defaultInactiveNextStatus : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Sign-up Status" span={2}>{info.defaultSignupStatus ? info.defaultSignupStatus : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Salary Cycle Start Day" span={2}>{info.salaryCycleStartDay ? info.salaryCycleStartDay : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Salary Cycle EndDay" span={2}>{info.salaryCycleEndDay ? info.salaryCycleEndDay : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Salary Preparation Day" span={2}>{info.salaryPreparationDay ? info.salaryPreparationDay : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Collection Exact Day" span={2}>{info.collectionDayExact ? info.collectionDayExact : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Max Allowed Monthly" span={2}>{info.maxMonthlyPercentAllowed ? info.maxMonthlyPercentAllowed : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Max allowed percentage amount" span={2}>{info.maxAdvancePercentAllowed ? info.maxAdvancePercentAllowed : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Installments count" span={2}>{info.maxInstallmentCount ? info.maxInstallmentCount : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Shopping Balance Percentage" span={2}>{info.shoppingBalancePercentage ? info.shoppingBalancePercentage : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Fees" span={2}>{info.fees ? info.fees : 0}</Descriptions.Item>
                <Descriptions.Item label="Free Deduction Fees" span={2}>{info.hasDeductionBasedFreePeriods ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="Company Free Months" span={2}>{info.companyFreeMonths ? info.companyFreeMonths : 0}</Descriptions.Item>
                <Descriptions.Item label="Company Users Free Months" span={2}>{info.userFreePaymentCycles ? info.userFreePaymentCycles : 0}</Descriptions.Item>
                <Descriptions.Item label="Estimated Number Of Users" span={2}>{info.estimatedNumbersOfUsers ? info.estimatedNumbersOfUsers : 0}</Descriptions.Item>
                <Descriptions.Item label="Cash Enabled" span={2}>{info.cashEnabled ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="Request Required Active User" span={2}>{info.requestRequiresActiveUser ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="Required Promissory Note" span={2}>{info.promissoryNoteRequired ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="Required HR Letter" span={2}>{info.hrLetterRequired ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="Required Consent" span={2}>{info.consentRequired ? trueIcon : falseIcon}</Descriptions.Item>

                <Descriptions.Item label="Required Staff ID" span={2}>{info.staffIDRequired ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="Staff ID Provided by the company" span={2}>{info.staffIDRequiredByCompany ? trueIcon : falseIcon}</Descriptions.Item>

                <Descriptions.Item label="Required National ID" span={2}>{info.nationalIDRequired ? trueIcon : falseIcon}</Descriptions.Item>
                <Descriptions.Item label="National ID Provided by the company" span={2}>{info.nationalIDRequiredByCompany ? trueIcon : falseIcon}</Descriptions.Item>

                <Descriptions.Item label="Available For Deployment" span={2}>{info.availableForDeployment ? info.availableForDeployment : 0}</Descriptions.Item>
                {/* <Descriptions.Item label="Teams" span={2}>{(info.SPOCs && info.SPOCs.length > 0) ? <Button onClick={() => this.handleShowSpocs(true)} type="link">Show teams</Button> : 'NA'}</Descriptions.Item> */}

                <Descriptions.Item label="Created At" span={2}>{info.createdAt ? Helper.changeDateFormat(info.createdAt) : 'NA'}</Descriptions.Item>
                <Descriptions.Item label="Established At" span={2}>{info.establishedAt ? Helper.changeDateFormat(info.establishedAt) : 'NA'}</Descriptions.Item>
                {/* {deployNotAllowed && <Descriptions.Item label="Deployed" span={2}>{info.deployed ? trueIcon : falseIcon}</Descriptions.Item>} */}
            </Descriptions>

        </>
    )
}
