import React, { useEffect, useState } from 'react'
import ReusableTable from '../../../components/reusableTable';
import { request } from '../../../apis/axiosRequest';
import { useDispatch } from 'react-redux';
import helper from '../../../utils/helper';
import { Empty, Switch, Col, Button, Form, Input, Row, message, FormInstance, Spin } from 'antd';
import OutPutResult from '../../../components/result';
import Spinner from '../../../components/spinner';
import { errorMessage } from '../../../utils/commonStringsConsts';
import { getAllProducts } from '../../../utils/apisConsts';
import { patchApi } from '../../../apis/patchRequest';


export default function BestSellersProds() {
    const formFilterRef = React.createRef<FormInstance>();
    const switchRef = React.createRef<FormInstance>();
    const [products, setProducts] = useState([]);
    const [loadProducts, setLoadProducts] = useState(true);
    const [tableSpin, setTableSpin] = useState(false);
    const [errorMode, setErrorMode] = useState(false)
    const [paginating, setPaginating] = useState({
        pageSize: 10,
        total: 0,
        current: 1,
    });
    const [filter, setFilter] = useState({
        priceFrom: '',
        priceTo: '',
        partNumber: ''
    })
    const [canClear, setCanClear] = useState(false);
    const [spinSeller, setSpinSeller] = useState(false);
    const dispatch = useDispatch();
    const getProducts =async (data: any) => {

        const params = {
            startPostion: data.current,
            count: data.pageSize,
            priceFrom: data.priceFrom ? Number(data.priceFrom) : '',
            priceTo: data.priceTo ? Number(data.priceTo) : '',
            partNumber: data.partNumber ? data.partNumber : '',
        }
        try {
            const allProds: any = await dispatch(request({
                url: getAllProducts,
                method: "GET",
                params
            }))

            setLoadProducts(true)
            if (allProds.data.status === 200) {
                setTimeout(() => {
                    setPaginating((prevState: any) => ({
                        ...prevState,
                        total: allProds?.data?.productsCount,
                        current: data?.current,
                        // pageSize: data.pageSize
                    }))
                }, 1);
                const res = allProds.data.products;
                const prods = res?.map((oneProd: any, key: string) => {
                    return {
                        ...oneProd,
                        createdAt: oneProd?.createdAt ? helper.changeTruckingDateFormat(oneProd?.createdAt) : 'NA',
                        price: oneProd?.price ? helper.amountFormatter(oneProd?.price) + ' EGP' : 'NA',
                        specialPrice: oneProd?.specialPrice ? helper.amountFormatter(oneProd?.specialPrice) + ' EGP' : 'NA',
                        isBestSeller: oneProd.isBestSeller ? true : false,
                        canLoad: false,
                    }
                })
                setProducts(prods);
                setTableSpin(false);
                setLoadProducts(false)
            }
            else {
                setLoadProducts(false)
                setErrorMode(true);
            }
            setLoadProducts(false)
            // setLoadingServices(false)
        }
        catch (e: any) {
            // setLoadingServices(false)
            setLoadProducts(false)
            setErrorMode(true)
        }
    }
    useEffect(() => {
        const data = {
            pageSize: paginating.pageSize,
            total: paginating.total,
            current: 1,
            ...filter
        }
        getProducts(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePagination = async (values: any) => {
        setTableSpin(true);
        await setPaginating((prevState: any) => ({
            ...prevState,
            current: values?.current,
            pageSize: values.pageSize
        }))

        const data = {
            pageSize: values.pageSize,
            current: values?.current,
            priceFrom: filter.priceFrom ? Number(filter.priceFrom) : '',
            priceTo: filter.priceTo ? Number(filter.priceTo) : '',
            partNumber: filter.partNumber ? filter.partNumber : '',
        }
        await getProducts(data);


    }


    const createBestSeller = async (prod: { partNumber: string, canLoad: boolean }, e: any) => {
        setSpinSeller(true);
        try {
            const allProds: any = await dispatch(patchApi(`/onlineShopping/products/bestSellerToggle/${prod.partNumber}`, {}));
            const res = allProds.payload;
            if (res.status === 200) {
                message.success(res.message);
                setSpinSeller(false);
            }
            else {
                message.error(res.message);
                await resetSwitchingBestSeller()
            }
        } catch (error) {
            message.error('something went wrong, please try again');
            await resetSwitchingBestSeller()

        }
    }
    const resetSwitchingBestSeller = () => {
        switchRef.current!?.resetFields();
        const data = {
            pageSize: paginating.pageSize,
            current: paginating?.current,
            priceFrom: filter.priceFrom ? Number(filter.priceFrom) : '',
            priceTo: filter.priceTo ? Number(filter.priceTo) : '',
            partNumber: filter.partNumber ? filter.partNumber : '',
        }
        getProducts(data);
        setSpinSeller(false);
    }
    const columns: any = [
        {
            title: 'Best Seller',
            dataIndex: 'isBestSeller',
            render: (isBestSeller: any, oneProduct: any) =>

                <>
                    <Form ref={switchRef}>
                        <Form.Item name={'check'}>
                            <Switch onChange={(e) => { createBestSeller(oneProduct, e) }} loading={oneProduct.canLoad} defaultChecked={isBestSeller} />
                        </Form.Item>

                    </Form>
                </>
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            width: 500,
        },
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Store',
            dataIndex: 'storeName',
        },
        {
            title: 'Special Price',
            dataIndex: 'specialPrice',
        },
        {
            title: 'Price',
            dataIndex: 'price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },
        {
            title: 'Category',
            dataIndex: 'categorie',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
        },

    ];

    const handleProductFilter = (values: any) => {

        if (
            !values.priceFrom &&
            !values.priceTo &&
            !values.partNumber
        ) {
            message.error(`You can't search on nothing`, 4);
            return;
        }
        setTableSpin(true)

        setFilter({
            ...filter,
            ...(values.priceFrom ? { priceFrom: values.priceFrom } : { priceFrom: '' }),
            ...(values.priceTo ? { priceTo: values.priceTo } : { priceTo: '' }),
            ...(values.partNumber ? { partNumber: values.partNumber } : { partNumber: '' }),
        })
        const data = {
            pageSize: paginating.pageSize,
            total: paginating.total,
            current: 1,
            priceFrom: values.priceFrom,
            priceTo: values.priceTo,
            partNumber: values.partNumber,
        }
        getProducts(data);
        setCanClear(true);
    }
    const clearFilter = () => {
        setTableSpin(true);
        setFilter((prevState:any)=>({
            ...prevState,
            priceFrom: '',
            priceTo: '',
            partNumber: '',
        }))
        const data = {
            pageSize: paginating.pageSize,
            total: paginating.total,
            current: 1,
            priceFrom: '',
            priceTo: '',
            partNumber: '',
        }
        getProducts(data);
        formFilterRef.current!.resetFields();
        setCanClear(false)
    }


    return (
        <>
            <Spin spinning={spinSeller} tip='The best seller is updating now'>
                <Form name="nest-messages" ref={formFilterRef} style={{ margin: '0 0 25px 0' }} onFinish={handleProductFilter}>
                    <Row>
                        <Col className="slider" span={6} style={{ margin: '0 15px 0 0' }}>
                            <label>Price From </label>
                            <Form.Item name="priceFrom" rules={
                                [{
                                    // pattern: helper.regexValidation.englishRegex, message: 'Must be English name ',

                                }, {
                                    // len: 5, message: 'Must be English name '

                                }]}>
                                <Input placeholder="Enter the start price" defaultValue={''} />
                            </Form.Item>

                        </Col>
                        <Col className="slider" span={6} style={{ margin: '0 15px 0 0' }}>
                            <label>Price To </label>
                            <Form.Item name="priceTo">
                                <Input placeholder="Enter the end price" defaultValue={''} />
                            </Form.Item>

                        </Col>
                        <Col className='module' span='6' style={{ padding: '0 15px 0 0' }}>
                            <label>Part Number</label>

                            <Form.Item name="partNumber">
                                <Input placeholder="Enter the part number" defaultValue={''} />
                            </Form.Item>

                        </Col>
                        <Col >

                            <Form.Item>
                                <Button style={{ margin: '22px 20px 0 0 ', float: 'right' }} className='changeUserStatusBtn' type="primary" htmlType="submit"  >
                                    Filter
                                </Button>
                            </Form.Item>
                        </Col>
                        {canClear && <Col>

                            <Form.Item>
                                <Button style={{ margin: '22px 0 0 0 ', float: 'right', background: 'red', color: '#fff', border: '1px solid red' }} className='changeUserStatusBtn' type="primary" onClick={clearFilter}  >
                                    Clear
                                </Button>
                            </Form.Item>
                        </Col>}
                    </Row>


                </Form>


                {loadProducts ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={`Oops `} subTitle={errorMessage}
                    btnTitle={'Reload'} btnClick={helper.refreshPage} /> : products?.length === 0 ? <Empty description='There are no products' /> :
                    <ReusableTable rows={products} columns={columns} handleTableChange={changePagination}
                        pagination={paginating} loading={tableSpin} scrolling={{ x: 'max-content' }} hiddenRefreshing />}

            </Spin>
        </>
    )
}


