import { Form, Row, Col, Button, message, Result } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request } from '../stores/actions';
import Spinner from '../components/spinner';
import helper from '../utils/helper';
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import DataTable from '../components/dataTable';
import Helper from '../utils/helper';
import XLSX from "xlsx";
import SheetTemplate from '../components/bulkActionSheetTemplate';
import { bulkCreateEarlyPaymentTemplate } from '../utils/bulkSheetsTemplates';
import BulkErrorsModal from '../components/bulkErrorsModal';
import ModalCompnent from '../components/modal';
import { bulkCreateExternalPaymentApi } from '../utils/apisConsts';
import { errorMessage } from '../utils/commonStringsConsts';
import { exportToCSV } from '../components/exportExcel';

interface Props {
    request?: any,
    removeData: boolean
}

class BulkCreateEarlyPayment extends Component<Props> {
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        dataSpin: false,
        tableData: [],
        tableColumns: [],
        tableLoading: false,
        pagination: {},
        uploadingError: false,
        visibleModal: false,
        uploadingErrorMessage: "",
        validationErrors: [],
        resMessageContent: "",
        visibleContentModal: false,
        resPayments: {
            failed: [],
            success: []
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
            this.clearData()
        }
    }

    submitEarlyPayments = async () => {
        this.setState({ dataSpin: true })
        try {
            let sheetData: { [key: string]: any }[] = []
            if (this.state.tableData && this.state.tableData.length > 0) {
                sheetData = this.state.tableData.map((row: { [key: string]: any }) => {
                    const startEarly = row["Asc"]
                    if (startEarly) {
                        row = {
                            ...row,
                            startEarly: String(startEarly).toLowerCase() === "yes" ? true : String(startEarly).toLowerCase() === "no" ? false : startEarly
                        }
                        delete row["Asc"]
                    }
                    return row
                })
            }

            const res = await this.props.request({ url: bulkCreateExternalPaymentApi, method: "Post", data: { sheetData } });
            if (res.data.status === 200) { //show success message
                console.log(res);
                message.success(res.data.message)
                this.clearData()
            }
            else if (res.data.status === 422) { //open errors list popup
                this.setState({
                    resMessageContent: res.data.message,
                    validationErrors: res.data.faliedValidations,
                    visibleModal: true
                })
            }
            else if (res.data.status === 400) { //open unsuccessful requests
                this.setState({
                    resMessageContent: res.data.message,
                    resPayments: {
                        failed: res.data.unsucessfulPayments,
                        success: res.data.sucessfulPayments
                    },
                    visibleContentModal: true
                })
            }
            else { // show any other status errors messages (400 without reason OR 500)
                message.error(res.data.message)
            }
            this.setState({ dataSpin: false })
        } catch (error) { // show any response status errors messages except 200(OK)
            this.setState({ dataSpin: false })
            console.log(error);
            message.error(errorMessage);
        }
    }

    handleFile(file: any /*:File*/) {
        this.setState({ tableLoading: true, uploadingError: false })
        console.log('file = ', file);
        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            let fileReader = new FileReader(), rows: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {
                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rows = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                const validation: any = helper.validateUploadSheetColumns(rows, bulkCreateEarlyPaymentTemplate)
                console.log("🚀 ~ file: bulkUpdateUserStatus.tsx ~ line 135 ~ BulkUpdateUserStatus ~ handleFile ~ validation", validation)
                if (!validation.success) {
                    this.setState({
                        tableLoading: false, uploadingError: true,
                        uploadingErrorMessage: validation.message
                    })
                    return
                }
                const columns = helper.generateTableColumns(validation.data)
                this.setState({ tableColumns: columns, tableData: validation.data, tableLoading: false })

            }
        }
    }

    handleUploadFile = (e: { target: { files: any, value: any }; }) => {
        this.setState({ tableData: [], uploadingError: false });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        // to clear input value to be able to add the same file again
        e.target.value = null;
    }

    handleTablePagination = (e: any) => {
        this.setState({
            pagination: e, tableLoading: true
        })
        setTimeout(() => this.setState({ tableLoading: false }), 1500)
    }

    openSelectFile = () => {
        this.fileInputRef.current!.click();
    }

    handleCloseModal = () => {
        this.setState({ visibleModal: false, visibleContentModal: false })
    }

    clearData = () => {
        this.setState({ tableData: [] })
    }

    downloadSheets = () =>{
        const failed = this.state.resPayments.failed
        const success = this.state.resPayments.success
        if (failed.length) {
            exportToCSV(failed, "Failed external payment transactions")
        }
        if (success.length) {
            exportToCSV(success, "Succeed external payment transactions")
        }
    }

    render() {
        const { dataSpin, tableColumns, pagination, tableData, tableLoading, uploadingError, visibleModal, uploadingErrorMessage, validationErrors, resMessageContent,
            visibleContentModal, resPayments } = this.state;
        const unsuccessRequestContent = <>
            <Result
                status={resPayments.failed.length === tableData.length ? "error" : "success"}
                style={{ padding: "30px" }}
                title={<p style={{ fontSize: '18px', padding: 0 }}>{resMessageContent}</p>}
            />
            <Row>
                <Col span={24}>
                    <p style={{fontSize:"18px"}}>Failed Payments</p>
                </Col>
                <DataTable
                    columns={helper.generateTableColumns(resPayments.failed)}
                    pagination={false}
                    rows={resPayments.failed}
                    loading={false}
                    scrolling={{}} />
            </Row>
        </>
        return (
            <div>
                <input
                    hidden
                    type="file"
                    className="form-control"
                    id="file"
                    ref={this.fileInputRef}
                    accept=".csv,.xlsx"
                    onChange={this.handleUploadFile}
                />
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Col span={4}><Button icon={<UploadOutlined />} onClick={this.openSelectFile} >Click to Upload file</Button></Col>
                    <Col span={18}>{tableData.length > 0 ? <><b>Uploaded Rows Count: </b> {tableData.length} {tableData.length > 1 ? "Payment requests" : "Payment request"}</> : "Please Upload your file as shown in the following table, Only the following data will be sent"}</Col>
                    {tableData && tableData.length > 0 && <Col className='colOfAssign' span={2}>
                        <Form.Item>
                            <Button type="primary" block loading={dataSpin} onClick={this.submitEarlyPayments} disabled={this.state.tableData.length === 0}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>}
                </Row>
                {tableData.length === 0 && <SheetTemplate columns={bulkCreateEarlyPaymentTemplate} />}
                {(Array.isArray(tableData) && tableData.length > 0 && !uploadingError) &&
                    <>
                        {(tableLoading && tableData.length === 0) ? <Spinner size={'large'} /> :
                            <div className='tableSection'>
                                <DataTable columns={tableColumns}
                                    pagination={pagination}
                                    rows={tableData}
                                    handleTableChange={this.handleTablePagination}
                                    loading={tableLoading}
                                    scrolling={{ x: 1000 }} />
                            </div>}</>
                }
                {
                    uploadingError &&
                    <Result
                        icon={<FrownOutlined />}
                        title={uploadingErrorMessage}
                    />
                }
                <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={this.handleCloseModal} errorMessage={resMessageContent} />
                <ModalCompnent content={unsuccessRequestContent}
                    visibleModal={visibleContentModal} handleAcceptModal={this.downloadSheets} handleCloseModal={this.handleCloseModal} okText="Download Failed and Succeed Sheets" cancelText="Close" />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, { request })(BulkCreateEarlyPayment) 
