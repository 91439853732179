import { Button, Col, Form, FormInstance, Input, message, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import helper from "../../utils/helper";
import { request, stepperMoving } from "../../stores/actions";
import { deployedCompanies } from "../../utils/apisConsts"
import { errorMessage } from "../../utils/commonStringsConsts";
import OutPutResult from "../../components/result";
const { Option } = Select;

interface Props{
    isCompany: boolean, 
    setIsCompany: any,
    setIdentifier: any
}
export default function SelectUserOrCompany({isCompany, setIsCompany, setIdentifier}:Props) {
    const [companies, setCompanies] = useState<any[]>([])
    const [loadingData, setLoadingData] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const dispatch = useDispatch()
    const formRef = React.createRef<FormInstance>();

    useEffect(()=>{
        changeInputs(isCompany)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getCompanies = async () => {
        try {
            setLoadingData(true)
            const companiesRes: { [key: string]: any } = await dispatch(request({ url: deployedCompanies, method: "GET" }))
            if (companiesRes.data.status === 200) {
                setCompanies(companiesRes.data.data)
            } else {
                message.error(companiesRes.data.message)
                setErrorMode(true)
            }
            setLoadingData(false)
        }
        catch (error: any) {
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
            setLoadingData(false)
            setErrorMode(true)
        }
    }

    const setPaymentData = async (values: any) => {
        console.log("🚀 ~ file: selectUserOrCompany.tsx ~ line 48 ~ setPaymentData ~ values", values)
        setIdentifier(values.isCompany? values.companyID : values.userPhone)
        dispatch(stepperMoving(1))
    }

    const dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    const changeInputs = async (value: boolean) =>{
        value && await getCompanies()
        setIsCompany(value)
    }
    return (
        errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
            <div className="createEarlyPayment">
                <Spin size="large" spinning={loadingData}>
                    <Form name="nest-messages" onFinish={setPaymentData} ref={formRef}>
                        <Row className="inputsRow">
                            <Col span='12'>
                                <label>Who Paid <span>*</span></label>
                                <Form.Item
                                    name="isCompany"
                                    rules={[{ required: true, message: 'Required' }]}
                                    initialValue={isCompany}
                                >
                                    <Select placeholder="Select a month" onChange={changeInputs}>
                                        <Option value={true}>Company</Option>
                                        <Option value={false}>User</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {isCompany ? <Col span='12'>
                                <label>Company <span>*</span></label>
                                <Form.Item
                                    name="companyID"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select a company" showSearch allowClear
                                        filterOption={((input, option: any) => dropDownSearch(input, option))}
                                    >
                                        {Array.isArray(companies) &&
                                            companies.map((company: { id: number, name: string }) => <Option key={company.id} value={company.id} company={company}>{company.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col> :
                                <Col span='12'>
                                    <label>User Phone <span>*</span></label>
                                    <Form.Item
                                        name="userPhone"
                                        rules={[{ required: true, message: 'Required' },
                                    {pattern: helper.regexValidation.egyptPhoneNum, message: 'Please enter Egyptian number'}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>}
                            <Col span='24' className="btnCol">
                                <Form.Item>
                                    <Button shape="round" type="primary" block htmlType="submit" >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </div>
    )
}