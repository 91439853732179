import React, { useRef, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Input, Row, Col, Divider, message } from 'antd';
import { useDispatch } from 'react-redux';
import Helper from '../../utils/helper';
import { request } from '../../stores/actions';
import { dataAnalyticsDemandApi } from '../../utils/apisConsts';
import { errorMessage, firstOption, variableCasesMsg } from '../../utils/commonStringsConsts';


export default function Demand() {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const formRef: any = useRef([]);


    const runDAApiDemand = async (values: any) => {
        const options = values.options;
        if (Helper.validKeys(options)) {
            return
        }
        setLoader(true);
        const data = {
            path: values.path,
            env: values.env,
            options: Helper.changeKeyByValue(options),
        }
        try {
            const apiData: any = await dispatch(request({ method: 'POST', url: dataAnalyticsDemandApi, data: data }))
            const res = await apiData.data;
            if (res.status === 200) {
                message.success(res.message, 6);
                setLoader(false);
                formRef.current?.resetFields()
                return
            }
            message.error(res.message, 6);
            setLoader(false);
        } catch (error) {
            console.log("🚀 ~ file: schedule.tsx:43 ~ runDAApiSchedule ~ error", error)
            message.error(errorMessage, 6);
            setLoader(false);
        }
    }
    return (
        <>
            <div className='demand'>
                <Form
                    ref={formRef}
                    initialValues={{ options: firstOption }}
                    onFinish={runDAApiDemand}
                >
                    <Row>
                        <Col span={12}>
                            <label htmlFor="">Api Path</label>
                            <Form.Item
                                name={'path'}
                                rules={[{ required: true, message: 'Required' }]}
                            >
                                <Input placeholder='Enter the path' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Environment</label>
                            <Form.Item
                                name={'env'}
                                rules={[{ required: true, message: 'Required' }]}
                            >
                                <Input placeholder='Enter the environment name' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className='divider' orientation="left">Options</Divider>

                    <Form.List name="options">

                        {(fields, { add, remove }) => (
                            <>

                                {fields.map(field => (
                                    <>
                                        <Row key={field.key}>
                                            <Col span={12}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, curValues) =>
                                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                    }>
                                                    {() => (
                                                        <>
                                                            <label htmlFor="">Key</label>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'key']}
                                                                rules={[{ required: true, message: 'Required' },
                                                                { pattern: Helper.regexValidation.variableCases, message: variableCasesMsg }]}
                                                            >

                                                                <Input id={field.key === 0 ? 'error' : ''} placeholder='Enter the key' />

                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={23}>
                                                <label htmlFor="">Value</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'value']}
                                                    rules={[{ required: true, message: 'Required' }]}                                            // rules={[{ required: true, message: 'Missing amount' }]}
                                                >
                                                    <Input.TextArea placeholder='Enter the value ' />
                                                </Form.Item>

                                            </Col>
                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '50%', left: '50%' }} />


                                            </Col>


                                        </Row>
                                    </>
                                ))}



                                {fields.length <= 128 && <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ margin: '20px 0' }}>
                                        Add
                                    </Button>
                                </Form.Item>}
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loader}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    )
}
