import { Form, Row, Col, Button, FormInstance, DatePicker, message, Select, Spin, Result, InputNumber, Switch, Badge, Dropdown, Menu } from 'antd'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DataTable from '../../components/dataTable'
import OutPutResult from '../../components/result'
import { getApi, request, getDeductionsFilters } from '../../stores/actions'
import helper from '../../utils/helper'
import { columnsOfDeductionsTable } from '../../utils/tableColumns';
import ModalComponent from '../../components/modal';
import PdfComponent from '../../components/pdf'
import InvoiceTemp from '../deductions/invoiceTemp'
import { exportToCSV } from '../../components/exportExcel'
import { generateInvoiceApi, getAllInstallmentsApi, getInvoicesApi, getPaymentCyclesApi, getTotalDeductionsSumApi } from '../../utils/apisConsts'
import { checkSumInvoicesError, dateFormat, errorMessage, getDeductionsMsg } from '../../utils/commonStringsConsts'
import { AxiosRequestConfig } from 'axios'
import { SmileOutlined, DownloadOutlined } from "@ant-design/icons";
import Spinner from '../../components/spinner'
import ErrorModal from '../../components/errorModal'

const { Option } = Select;

const { RangePicker } = DatePicker;

interface Props {
    info: any,
    getApi(api: string, info: {}): any,
    companies?: [],
    request(obj: AxiosRequestConfig): any,
    generateInvoice: boolean,
    deductionsFilter: {},
    getDeductionsFilters: any,
}

export class Deductions extends Component<Props> {
    deductFilters: any = this.props.deductionsFilter;

    state = {
        rows: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        filters: {
            userID: null,
            requestID: null,
            statusID: null,
            dateFrom: null,
            dateTo: null,
            staffID: null,
            paymentCycle: null,
            companyID: null,
        },
        loading: false,
        resetFilterBtn: false,
        errorMode: false,
        errorMessage: null,
        visibleModal: false,
        visibleErrorModal: false,
        invoiceObj: {
            salariesPaid: '',
            fees: 0,
            subject: '',
            com: '',
            date: '',
            serial: '',
            settled: '',
            total: '',
            taxRate: 0,
            taxFees: 0,
            debitAndCredit: 0,
            discount: 0,
            fileName: '',
            previousCreditRevenue: 0
        },
        downloadAllDeduction: false,
        allPaymentCycles: [],
        showPaymentCycles: false,
        deductionsSum: 0,
        deductionsSumLoading: false,
        loadingInvoice: false,
        loadingPivot: false,
        generateInvoiceForm: {
            visible: false,
            withFees: true,
            withTax: false,
            taxRate: 3
        },
        loadingPaymentCycles: false,
        sheetsModelVisible: false,
        isDeductions: false,
        isLockedCycle: false,
        lockedInvoice: { taxRatePercent: null },
        viewPaymentCycle: false,
        viewDateRange: false,
        newFilter: false,
        hasPaymentCycle: false,
        hasDateRange: false,
        errorDeductionsMessage: "Hello, There are no deductions",
        paymentCycleMandatory: false,
        deductionDataFile: '',
        companyNameFile: ''

    }
    formFilterRef = React.createRef<FormInstance>();
    invoiceFormRef = React.createRef<FormInstance>();
    pivotFormRef = React.createRef<FormInstance>();


    componentDidMount() {
        this.detectingFilters()
    }
    detectingFilters = async () => {
        const deductFilters: any = this.props.deductionsFilter;
        if (deductFilters.companyID && deductFilters.staffID) {
            this.setState({
                filters: {
                    ...(deductFilters.staffID ? { staffID: deductFilters.staffID } : { staffID: null }),
                    ...(deductFilters.companyID ? { companyID: deductFilters.companyID } : { companyID: null }),
                },
                viewPaymentCycle: true,

            }, () => {
                this.getDeductions();
                this.getPaymentCycles(deductFilters.companyID, this.props.info, 1)

            })
        } else if (deductFilters.companyID) {
            // this.props.getDeductionsFilters({
            //     ...deductFilters.companyID,
            //     staffID: 5000,
            // })
            this.setState({
                viewPaymentCycle: true,
                ...this.state.filters,
                companyID: deductFilters.companyID,
                paymentCycleMandatory: true,
                errorDeductionsMessage: 'Please select a payment cycle',
                loading: false,
            }, async () => {
                this.getPaymentCycles(deductFilters.companyID, this.props.info, 1)

            })
        }
        else if (deductFilters.userID) {
            this.setState({ loading: true })
            const user: any = await helper.getUserInfo(null, deductFilters.userID);
            if (user.status === 200) {
                this.setState({
                    viewPaymentCycle: true,
                    viewDateRange: true,
                    filters: {
                        ...(deductFilters.userID ? { userID: Number(deductFilters.userID) } : { userID: null }),
                        companyID: null
                    },
                }, () => {
                    this.getPaymentCycles(deductFilters.userID, user.userInfo.actualCutOffDate, 0)
                    this.getDeductions();

                })
            } else {
                this.setState({ errorMode: true, errorMessage: errorMessage });
            }


        }
        else if (deductFilters.requestID) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...(deductFilters.requestID ? { requestID: Number(deductFilters.requestID) } : { requestID: null }),
                },
            }, () => {

                this.getDeductions()
            })
        }
        else {
            console.log(false);
        }
    }

    getDeductions = async (resetFilter?: boolean, searchFilter?: any) => {
        this.getDeductionsSum();
        this.setState({ loading: true })
        let filters: { [key: string]: any } = searchFilter ? searchFilter : this.state.filters
        const params = {
            rowsPerPage: this.state.pagination.pageSize,
            pageNum: this.state.pagination.current,
            filterPattern: true,
            ...filters,
        };

        const transactions = await this.props.getApi(getAllInstallmentsApi, { params });
        const data = await transactions.payload;

        try {
            if (data.status === 200) {
                resetFilter && this.setState({ resetFilterBtn: true });

                let rowTable = Array.isArray(data.installments) && data.installments.map((installment: any) => {
                    return ({
                        ...installment,
                        instalmentOrder: `${String(installment.instalmentOrder)}/${String(installment.numberOfMonths)}`,
                        paymentCycleDate: helper.changeDateFormat(installment.paymentCycleDate),
                        jobTitle: installment.jobTitle ? installment.jobTitle : 'NA',
                        paymentsAmountEGP: installment.paymentsAmountEGP ? helper.amountFormatter(installment.paymentsAmountEGP) + ' EGP' : 'NA'
                    })
                })
                this.setState({
                    rows: rowTable,
                    loading: false,
                    pagination: { ...this.state.pagination, total: data.installmentCount },
                    companyNameFile: data?.installments[0]?.companyName,
                    deductionDataFile: data?.installments[0]?.paymentCycleDate

                });
            } else {
                this.setState({ errorMode: true, loading: false, errorMessage: data.message });
            }
        } catch (error) {
            this.setState({ errorMode: true, loading: false, errorMessage: errorMessage });
        }
    }

    generateDeductionsReport = async (formValues: any) => {
        if (this.state.rows.length === 0) {
            message.error("There are no deductions to be downloaded")
            return null
        }
        
        this.setState({ downloadAllDeduction: true })
        let filters: { [key: string]: any } = this.state.filters
        if (filters.paymentCycle) {
            delete filters.dateFrom
            delete filters.dateTo
        }
        const params = {
            filterPattern: true,
            ...filters,
        }
        const dedicationData = await this.props.getApi(getAllInstallmentsApi, { params });
        const data = await dedicationData.payload;

        try {
            if (data.status === 200) {

                let rowTable = Array.isArray(data.installments) && data.installments.map((installment: any) => {
                    formValues && !formValues.withJobTitle && delete installment.jobTitle
                    formValues && !formValues.withLocation && delete installment.location
                    return (
                        {
                            ...installment,
                            instalmentOrder: `${String(installment.instalmentOrder)}/${String(installment.numberOfMonths)}`,
                            paymentCycleDate: helper.changeDateFormat(installment.paymentCycleDate),
                            ...(formValues && formValues.withJobTitle && { jobTitle: installment.jobTitle ? installment.jobTitle : 'NA' })
                        }
                    )
                })
                this.setState({
                    downloadAllDeduction: false,
                    pagination: { ...this.state.pagination, total: data.installmentCount },
                    isDeductions: false
                });

                exportToCSV(rowTable, `${moment(this.state.deductionDataFile).format("MMMM, YYYY")} ${this.state.companyNameFile}_Ded`)
                return rowTable
            } else {
                this.setState({ downloadAllDeduction: false, loading: false });
                message.error(data.message, 4)
                return
            }
        } catch (error) {
            this.setState({ downloadAllDeduction: false, loading: false });
            message.error(errorMessage, 4)
            return
        }
    }

    handleSearch = (values: any) => {
        if (!values.id && !values.advanceID && !values.userID && !values.companyID && !values.staffID && !values.paymentCycle && !values.dateRange) {
            message.error(`You can't search on nothing`, 4);
            return;
        }
        const deductFilters: any = this.props.deductionsFilter;

        this.setState({ loading: true })
        let filters: { [key: string]: any } = this.state.filters

        this.setState({
            pagination: { ...this.state.pagination, current: 1 },
            newFilter: true,
            filters: {
                ...filters,
                ...(values.dateRange ? {
                    dateFrom: helper.changeDateFormat(values.dateRange[0]._d),
                    dateTo: helper.changeDateFormat(values.dateRange[1]._d),
                    // dateFrom: moment(values.dateRange[0]._d).format("YYYY/MM/DD"),
                    // dateTo: moment(values.dateRange[1]._d).format("YYYY/MM/DD")
                } : { dateFrom: 'null', dateTo: 'null' }),
                ...(values.paymentCycle ? { paymentCycle: values.paymentCycle } : { paymentCycle: null }),
                staffID: this.state.filters.staffID,
                companyID: deductFilters.companyID,
                userID: this.state.filters.userID,
            },
        }, async () => {

            if (values.paymentCycle) {

                let paymentCycleObj: { isLocked: boolean } = this.state.allPaymentCycles.filter((paymentCycle: any) => paymentCycle.paymentCycle === values.paymentCycle)[0]
                if (paymentCycleObj && paymentCycleObj.isLocked) {
                    this.setState({ isLockedCycle: true })
                    await this.getLockedInvoice()
                } else {
                    this.setState({ isLockedCycle: false, lockedInvoice: {} })
                }
                const filters = this.state.filters;


                this.getDeductions(true, filters);
            } else {
                this.getDeductions(true);
            }
        })
    }

    clearFilter = () => {

        if (this.state.filters.companyID && !this.state.filters.staffID) {
            message.error(`Sorry you can't reset the filter you've to change the payment cycle`);
            return
        }
        this.setState(
            {
                resetFilterBtn: false, pagination: { ...this.state.pagination, current: 1 },
                newFilter: false,
                filters: {
                    ...this.state.filters,
                    requestID: this.state.filters.requestID,
                    userID: this.state.filters.userID,
                    dateFrom: null, dateTo: null, companyID: this.state.filters.companyID,
                    staffID: this.state.filters.staffID,
                    paymentCycle: null
                },
                showPaymentCycles: false,
                hasPaymentCycle: false,
                hasDateRange: false,
                rows: []
            }, () => {
                this.formFilterRef.current!.resetFields();
                this.formFilterRef.current!.setFieldsValue({
                    companyID: null
                });
                this.getDeductions();
            });

    };

    handlePagination = (val: any) => {
        this.setState({ loading: true, pagination: val }, () => {
            this.getDeductions();
        });
    }

    generateInvoice = async (isInvoice?: boolean, formValues?: any) => {
        isInvoice ? this.setState({ loadingInvoice: true }) : this.setState({ loadingPivot: true })
        if (this.state.filters.companyID && this.state.filters.paymentCycle) {
            if (this.state.rows.length === 0) {
                message.error("No Data to be Printed", 3)
                this.setState({ loadingPivot: false, loadingInvoice: false })
                return 0
            }
            try {
                this.toggleGenerateInvoiceForm(false)
                const invoiceFormData = this.state.generateInvoiceForm
                const invoiceData = await this.props.request({
                    url: generateInvoiceApi, method: "GET", params: {
                        companyID: this.state.filters.companyID,
                        paymentCycle: this.state.filters.paymentCycle,
                        deductTaxRate: invoiceFormData.withTax ? invoiceFormData.taxRate : 0,
                        generateNew: isInvoice ? 1 : 0
                    }
                })
                console.log("🚀 ~ file: view.tsx:383 ~ Deductions ~ generateInvoice= ~ invoiceData:", invoiceData)

                if (invoiceData.data.status === 200) {
                    const sheet: [] = invoiceData.data.installmentPivot.map((data: any) => {
                        return {
                            Username: data.name,
                            "Staff ID": data.staffID,
                            "Phone Number": data.phone,
                            Company: data.companyName,
                            "National ID": data.nationalID,
                            "Cut-off Date": helper.changeDateFormat(data.paymentCycleDate),
                            "Total Due": data.totalDue,
                            ...(formValues && formValues.withJobTitle && { "Job Title": data.jobTitle }),
                            ...(formValues && formValues.withLocation && { Location: data.location })
                        }
                    })
                    const invoice = {
                        salariesPaid: invoiceData.data.installmentTotal.invoiceClaim ? Number(invoiceData.data.installmentTotal.invoiceClaim).toFixed(2) : 0,
                        ...(invoiceFormData.withFees && { fees: invoiceData.data.installmentTotal.netRevenue ? Number(invoiceData.data.installmentTotal.netRevenue).toFixed(2) : 0 }),
                        subject: `${moment(invoiceData.data.installmentTotal.cutoffCycleDate).format("MMMM DD, YYYY")} Claim`,
                        com: invoiceData.data.installmentTotal.companyName,
                        date: invoiceData.data.installmentTotal.cutoffCycleDate,
                        serial: invoiceData.data.installmentTotal.serialNumber,
                        settled: invoiceData.data.installmentTotal.settled ? Number(invoiceData.data.installmentTotal.settled).toFixed(2) : 0,
                        taxRate: invoiceFormData.withTax ? invoiceFormData.taxRate : null,
                        taxFees: (invoiceFormData.withTax && invoiceFormData.withFees) ? (Number(invoiceData.data.installmentTotal.taxAmount)).toFixed(2) : null,
                        total: invoiceData.data.installmentTotal.total ? Number(invoiceData.data.installmentTotal.total).toFixed(2) : 0,
                        discount: invoiceData.data.installmentTotal.discount,
                        debitAndCredit: invoiceData.data.installmentTotal?.creditAndDebit,
                        ...(invoiceFormData.withFees && {previousCreditRevenue: invoiceData.data.installmentTotal?.previousCreditRevenue}),
                        fileName: `${moment(invoiceData.data.installmentTotal.cutoffCycleDate).format("MMMM, YYYY")} ${invoiceData.data.installmentTotal.companyName}_Invoice`
                    }

                    this.setState({ invoiceObj: invoice, loadingPivot: false, loadingInvoice: false })
                    if (!isInvoice) {
                        exportToCSV(sheet, `${moment(this.state.deductionDataFile).format("MMMM, YYYY")} ${this.state.companyNameFile}_Sum`)
                    }
                    return sheet
                } else {
                    this.setState({ loadingPivot: false, loadingInvoice: false, loading: false, visibleErrorModal: true, errorMessage: invoiceData.data.message })
                    return
                }
            }
            catch (e) {
                console.log("🚀 ~ file: deductions.tsx ~ line 238 ~ Deductions ~ generateInvoice= ~ e", e)
                message.error(errorMessage, 4)
                this.setState({ loadingPivot: false, loadingInvoice: false, loading: false })
                return
            }
        }
    }

    viewThePdfModal = (values: any) => {
        this.setState({
            generateInvoiceForm: {
                ...this.state.generateInvoiceForm,
                ...values
            }
        }, async () => {
            const open = await this.generateInvoice(true);
            this.setState({ visibleModal: open ? true : false })
        })
    }
    closeThePdfModal = () => {
        this.setState({ visibleModal: false })
    }

    closeErrorModal = () => {
        this.setState({ visibleErrorModal: false })
    }

    getPaymentCycles = async (id: number, data?: any, flag?: any) => {
        // this.setState({ loading: true })
        if (!id || !data) {
            this.setState({ showPaymentCycles: false })
            return
        }
        const todayDate = new Date()
        const companyDate = new Date()
        let query;
        if (flag === 1) {
            companyDate.setDate(data?.data?.actualCutOffDate)
            query = `?companyID=${id}`
        } else {
            companyDate.setDate(data)
            query = `?userID=${id}`
        }

        if (companyDate <= todayDate) {
            companyDate.setMonth(companyDate.getMonth() + 1)
        }
        const currentCycle = {
            cutOffCycleDate: moment(companyDate).format("MMMM YYYY"),
            cutOffDay: data?.data?.actualCutOffDate ? data.data.actualCutOffDate : data,
        }
        this.setState({ showPaymentCycles: true, loadingPaymentCycles: true, allPaymentCycles: [] })
        const paymentCycles = await this.props.getApi(`${getPaymentCyclesApi}${query}`, {}); //${value.requestId}
        const res = paymentCycles.payload;

        try {
            if (res.status === 200) {
                Object.assign(currentCycle, { paymentCycle: Array.isArray(res.allPaymentCycles) && res.allPaymentCycles.slice(-1)[0].paymentCycle + 1 })
                this.setState({ allPaymentCycles: [...res.allPaymentCycles, currentCycle].reverse(), })
            } else {
                this.setState({ showPaymentCycles: false, })
                message.error(res.message, 4);
            }
            this.setState({ loadingPaymentCycles: false, })
        } catch (error) {
            this.setState({ showPaymentCycles: false, loadingPaymentCycles: false, })
            message.error(errorMessage, 4);
        }

    }

    dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    getDeductionsSum = async () => {
        try {
            this.setState({ deductionsSumLoading: true })
            let filters: { [key: string]: any } = this.state.filters
            if (filters.paymentCycle) {
                delete filters.dateFrom
                delete filters.dateTo
            }
            const params = {
                filterPattern: true,
                ...filters,
            }
            const deductionsSum = await this.props.getApi(`${getTotalDeductionsSumApi}`, {
                params,

            });
            const data = await deductionsSum.payload;
            if (data.status === 200) {
                this.setState({ deductionsSum: data.installmentTotal.installmentSum });
            } else {
                this.setState({ deductionsSum: 0 });
                message.error(data.message, 5)
            }
            this.setState({ deductionsSumLoading: false })
        } catch (error) {
            console.log("🚀 ~ file: deductions.tsx ~ line 293 ~ Deductions ~ getDeductionsSum= ~ error", error)
            this.setState({ deductionsSum: 0, deductionsSumLoading: false, loading: false });
            message.error(errorMessage, 5)
        }
    }

    toggleGenerateInvoiceForm = (val: boolean) => {
        this.setState({
            generateInvoiceForm: {
                ...this.state.generateInvoiceForm,
                visible: val,
                withFees: true,
                withTax: false
            }
        }, () => {
            this.invoiceFormRef.current?.resetFields();
        })
    }

    toggleDownloadSheetsForm = (val: boolean, formValues?: any, isDeductions?: boolean) => {
        this.setState({
            sheetsModelVisible: val,
            isDeductions
        }, () => {
            if (formValues && !this.state.isDeductions) {
                this.generateInvoice(false, formValues)
            }
            else if (formValues && this.state.isDeductions) {
                this.generateDeductionsReport(formValues)
            }
            this.pivotFormRef.current?.resetFields();
        })
    }

    getLockedInvoice = async () => {
        if (this.state.filters.paymentCycle && this.state.filters.companyID) {
            try {
                const invoices = await this.props.request({
                    url: getInvoicesApi, method: "get", params: {
                        ...this.state.filters,
                        paymentCycle: this.state.filters.paymentCycle,
                        filterPattern: true,
                    }
                });


                if (invoices.data.status === 200) {
                    const lockedInvoice: { [key: string]: any } = invoices.data.data.locked ? {
                        ...invoices.data.data.lockedInvoice,
                        lockedInvoice: true
                    } : {}
                    this.setState({ lockedInvoice })
                } else {
                    this.setState({ lockedInvoice: {} })
                    message.error(invoices.data.message, 4)
                }
            }
            catch (e: any) {
                this.setState({ lockedInvoice: {} })
                message.error(errorMessage, 4);
            }
        }

    }

    downloadInvoice = (withFees: boolean) => {
        const lockedInvoice: any = { ...this.state.lockedInvoice }
        const date: { cutOffCycleDate: string } = this.state.allPaymentCycles.filter((paymentCycle: any) => paymentCycle.paymentCycle === lockedInvoice.paymentCycle)[0]
        const invoice = {
            salariesPaid: lockedInvoice.claim ? (Number(lockedInvoice.claim)).toFixed(2) : 0,
            ...(withFees && { fees: lockedInvoice.netRevenue ? Number(lockedInvoice.netRevenue).toFixed(2) : 0 }),
            subject: `${moment(lockedInvoice.cutoffCycleDate).format("MMMM DD, YYYY")} Claim`,
            com: lockedInvoice.companyName,
            date: date.cutOffCycleDate,
            serial: lockedInvoice.serialNumber,
            settled: lockedInvoice.settled ? Number(lockedInvoice.settled).toFixed(2) : 0,
            taxRate: lockedInvoice.taxRatePercent ? lockedInvoice.taxRatePercent : null,
            taxFees: (lockedInvoice.taxAmount && withFees) ? (Number(lockedInvoice.taxAmount)).toFixed(2) : null,
            debitAndCredit: lockedInvoice.debitAndCreditAmounts.debitAndCredit,
            ...(withFees && {previousCreditRevenue: lockedInvoice?.previousCreditRevenue}),
            discount: lockedInvoice.discount,
            total: Number(lockedInvoice.total) ? Number(lockedInvoice.total).toFixed(2) : 0,
            fileName: `${moment(lockedInvoice.cutOffCycleDate).format("MMMM, YYYY")} ${lockedInvoice.companyName}_Invoice`

        }
        this.setState({ invoiceObj: invoice, visibleModal: true })
    }

    changePaymentCycle = (values: any) => {
        this.onChangingInputs(values, 'hasPaymentCycle')

        this.setState({ rows: [], newFilter: false, errorDeductionsMessage: getDeductionsMsg })
    }
    changeDate = (values: any) => {
        this.onChangingInputs(values, 'hasDateRange')
    }

    onChangingInputs = (values: any, dynamicState: any) => {
        let data: any = values;
        if (data) {
            this.setState({ [dynamicState]: true })
        } else {
            this.setState({ [dynamicState]: false })
        }
    }
    render() {
        const { loading, rows, pagination, resetFilterBtn, errorMode, errorMessage, visibleModal, visibleErrorModal,
            downloadAllDeduction, invoiceObj, allPaymentCycles, deductionsSum, deductionsSumLoading,
            loadingInvoice, loadingPivot, filters, generateInvoiceForm, loadingPaymentCycles, sheetsModelVisible,
            isDeductions, isLockedCycle, lockedInvoice, viewPaymentCycle, viewDateRange, newFilter,
            hasDateRange, hasPaymentCycle, errorDeductionsMessage } = this.state;

        const generateInvoiceModalContent = <>
            <Form
                ref={this.invoiceFormRef}
                name="basic"
                onFinish={this.viewThePdfModal}
                autoComplete="off"
                initialValues={generateInvoiceForm}
            >
                <Row className="statusRow">
                    <Col span={24}>
                        <label style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "15px" }}>Please select suitable options</label>
                    </Col>
                    <Col md={24}>
                        <label htmlFor="">Show Service Fees </label>
                        <Form.Item name="withFees">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={generateInvoiceForm.withFees}
                                onChange={(val) => {
                                    this.setState({ generateInvoiceForm: { ...generateInvoiceForm, withTax: val ? generateInvoiceForm.withTax : val, withFees: val } }, () => {
                                        this.invoiceFormRef.current?.resetFields()
                                    })
                                }} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Show Tax on Service Fees </label>
                        <Form.Item name="withTax">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={generateInvoiceForm.withTax} disabled={!generateInvoiceForm.withFees}
                                onChange={(val) => this.setState({ generateInvoiceForm: { ...generateInvoiceForm, withTax: val } })}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Tax Rate on Service Fees {generateInvoiceForm.withTax && <span>*</span>}</label>
                        <Form.Item name="taxRate" rules={[
                            { required: generateInvoiceForm.withTax, message: "Tax rate is mandatory!" },
                            { type: "number", min: 1, max: 100, message: "The rate must be between 1 and 100" },
                        ]}>
                            <InputNumber placeholder='Enter the rate' disabled={!generateInvoiceForm.withTax} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
        const generatePivotModalContent = <>
            <Form
                ref={this.pivotFormRef}
                name="basic"
                onFinish={(values) => this.toggleDownloadSheetsForm(false, values, isDeductions)}
                autoComplete="off"
                initialValues={{
                    withJobTitle: false,
                    withLocation: false
                }}
            >
                <Row className="statusRow">
                    <Col span={24}>
                        <label style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "15px" }}>Please select suitable options</label>
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Add job title column </label>
                        <Form.Item name="withJobTitle">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Add location column </label>
                        <Form.Item name="withLocation">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
        return (
            <>
                {errorMode ?
                    <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                        btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> : <div className="deduction">
                        <div className='deductionsFilter'>

                            <Form
                                ref={this.formFilterRef}
                                name="basic"
                                onFinish={this.handleSearch}
                                autoComplete="off"
                            >
                                <Row className="statusRow">

                                    {viewPaymentCycle && <Col span={12}>
                                        <label htmlFor="">Invoices months</label>
                                        <Form.Item name="paymentCycle">
                                            <Select
                                                placeholder='Please select an invoice month'
                                                loading={loadingPaymentCycles}
                                                onChange={this.changePaymentCycle}
                                                disabled={hasDateRange}
                                                allowClear
                                            >
                                                {
                                                    Array.isArray(allPaymentCycles) &&
                                                    allPaymentCycles.map((paymentCycle: { paymentCycle: number, cutOffCycleDate: string, cutOffDay: string, isLocked: boolean }, index: any) =>
                                                        <Option key={index} value={paymentCycle.paymentCycle}>
                                                            <Row>
                                                                <Col span={16} style={{ padding: 0 }}>
                                                                    <span style={{ color: 'red' }}>{paymentCycle.cutOffDay ? paymentCycle.cutOffDay : moment(paymentCycle.cutOffCycleDate).format('DD')}</span>
                                                                    &nbsp;{moment(paymentCycle.cutOffCycleDate).format('MMMM YYYY')}
                                                                </Col>
                                                                <Col span={8} style={{ padding: 0 }}>
                                                                    <Badge count={paymentCycle.isLocked ? "Locked invoice" : 0} />
                                                                </Col>
                                                            </Row>
                                                        </Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>}
                                    {viewDateRange && <Col md={12}>
                                        <label htmlFor="">Date </label>
                                        <Form.Item name="dateRange" >
                                            <RangePicker format={dateFormat} allowClear onChange={this.changeDate} disabled={hasPaymentCycle} />
                                        </Form.Item>
                                    </Col>}

                                </Row>

                                {(viewDateRange || viewPaymentCycle) && <Row className="btnRows" >
                                    <Col >
                                        <Form.Item>
                                            <Spin spinning={downloadAllDeduction || loading}>
                                                <Button type="default" onClick={() => this.toggleDownloadSheetsForm(true, false, true)}
                                                    style={{ color: "#413F42", borderColor: "#413F42", margin: '0 19px' }} disabled={!rows.length}>
                                                    Deductions Sheet
                                                </Button>
                                            </Spin>

                                        </Form.Item>
                                    </Col>
                                    {newFilter && <>



                                        {this.state.filters.companyID && this.state.filters.paymentCycle
                                            && !this.state.filters.requestID && !this.state.filters.staffID && !this.state.filters.userID && <>
                                                <Col>
                                                    <Form.Item>
                                                        <Spin spinning={loadingPivot || loading}>
                                                            <Button type="default" onClick={() => this.toggleDownloadSheetsForm(true)}
                                                                style={{ color: "#EC9B3B", borderColor: "#EC9B3B", margin: '0 19px' }} disabled={!rows.length}>
                                                                Invoice Sheet
                                                            </Button>
                                                        </Spin>
                                                    </Form.Item>
                                                </Col>

                                                {this.props.generateInvoice && !isLockedCycle && <Col >
                                                    <Form.Item>
                                                        <Spin spinning={loadingInvoice || loading}>
                                                            <Button type="default" onClick={() => this.toggleGenerateInvoiceForm(true)}
                                                                style={{ color: "blue", borderColor: "blue", margin: '0 19px' }} disabled={!rows.length}>
                                                                Generate PDF
                                                            </Button>
                                                        </Spin>
                                                    </Form.Item>
                                                </Col>}
                                                {this.props.generateInvoice && isLockedCycle && <Col >
                                                    <Form.Item>
                                                        <Spin spinning={loadingInvoice || loading}>
                                                            <Dropdown
                                                                overlay={
                                                                    <Menu>
                                                                        {(!loadingInvoice && !loading) ?
                                                                            <>
                                                                                <Menu.Item
                                                                                    icon={<DownloadOutlined />}
                                                                                    onClick={() => this.downloadInvoice(true)}
                                                                                >
                                                                                    With Fees
                                                                                </Menu.Item>
                                                                                <Menu.Item
                                                                                    icon={<DownloadOutlined />}
                                                                                    onClick={() => this.downloadInvoice(false)}
                                                                                    disabled={lockedInvoice.taxRatePercent ? true : false}
                                                                                >
                                                                                    Without Fees
                                                                                </Menu.Item>
                                                                            </>
                                                                            : ""}
                                                                    </Menu>
                                                                }>
                                                                <Button type="default" style={{ color: "blue", borderColor: "blue", margin: '0 19px' }} disabled={!rows.length}>
                                                                    Download PDF
                                                                </Button>
                                                            </Dropdown>
                                                        </Spin>
                                                    </Form.Item>
                                                </Col>}
                                            </>}
                                    </>}
                                    <Col >
                                        <Form.Item>
                                            <Button type="default" htmlType="submit"
                                                style={{ color: "#357C3C", borderColor: "#357C3C", margin: '0 19px' }} disabled={loading}>
                                                Filter
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    {resetFilterBtn && <Col>
                                        <Form.Item>
                                            <Button danger onClick={this.clearFilter}>
                                                Clear filter
                                            </Button>
                                        </Form.Item>
                                    </Col>}

                                </Row>}
                            </Form>

                        </div>
                        {loading && rows.length === 0 ?
                            <Spinner size={"large"} />
                            : rows.length > 0 ?
                                <div className='deductionsTable'>
                                    <DataTable
                                        genericColumnsNames={columnsOfDeductionsTable}
                                        rows={rows}
                                        handleTableChange={this.handlePagination}
                                        pagination={pagination}
                                        loading={loading}
                                        scrolling={{ x: 100, y: 300 }}
                                    />
                                    <span className='totalDeductions'>Deduction Summation: {<Spin spinning={deductionsSumLoading}>EGP {helper.amountFormatter(deductionsSum)} </Spin>}</span>
                                </div>
                                : <Result
                                    icon={<SmileOutlined />}
                                    title={((filters.dateFrom && filters.dateTo) || filters.paymentCycle) ? "There are no deductions for the selected filters!" : errorDeductionsMessage}
                                />
                        }
                        <ModalComponent none={'none'} visibleModal={visibleModal} handleAcceptModal={this.closeThePdfModal} handleCloseModal={this.closeThePdfModal}
                            content={<PdfComponent fileName={invoiceObj?.fileName} content={<InvoiceTemp data={invoiceObj} />} />} />
                        <ModalComponent okText='Generate Invoice' visibleModal={generateInvoiceForm.visible} handleAcceptModal={() => this.invoiceFormRef.current?.submit()} handleCloseModal={() => this.toggleGenerateInvoiceForm(false)}
                            content={generateInvoiceModalContent} />
                        <ModalComponent okText='Download Sheet' visibleModal={sheetsModelVisible} handleAcceptModal={() => this.pivotFormRef.current?.submit()} handleCloseModal={() => this.toggleDownloadSheetsForm(false)}
                            content={generatePivotModalContent} />
                        <ErrorModal handleCloseModal={this.closeErrorModal} visibleModal={visibleErrorModal} errorMessage={checkSumInvoicesError} warn/>
                    </div>
                }


            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
    companies: state.general.companies,
    deductionsFilter: state.actionsReducer.deductionsFilter
})
export default connect(mapStateToProps, { getApi, request, getDeductionsFilters })(Deductions)
