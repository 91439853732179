import React, { Component } from 'react'
import { Modal } from 'antd';
interface Props {
    loadingConfirm?: boolean,
    visibleModal?: boolean,
    handleAcceptModal?: any,
    handleCloseModal?: any,
    okText?: string,
    cancelText?: string,
    title?: string,
    btnDisable?: boolean,
    content?: any,
    none?: any
}
export class ModalCompnent extends Component<Props> {
    render() {
        const { loadingConfirm, visibleModal, okText, cancelText,
            title, handleAcceptModal, handleCloseModal, content, btnDisable, none } = this.props;
        return (
            <div>
                <Modal
                    confirmLoading={loadingConfirm}
                    title={title}
                    centered
                    visible={visibleModal}
                    okText={okText}
                    cancelText={cancelText}
                    onOk={handleAcceptModal}
                    onCancel={handleCloseModal}
                    okButtonProps={{ disabled: btnDisable }}
                    cancelButtonProps={{ style: { display: none ? none : 'inline' } }}
                    
                >
                    {content}

                </Modal>
            </div>
        )
    }
}

export default ModalCompnent
