import React, { useState } from 'react'
import { Button, Col, Row, message } from 'antd';
import XLSX from "xlsx";
import helper from '../../../utils/helper';
import { hrStepper, getDataSheet } from '../../../stores/actions';
import { useDispatch, useSelector } from 'react-redux';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import fileSaver from 'file-saver';
import TruckingModal from '../../Trucking/modal';
interface IRootState {
    [key: string]: any;
    hrStep: number,
}

export default function Step1() {
    const [validFiles, setValidFiles] = useState(false);
    const [viewInstructions, setViewInstructions] = useState(false);
    const dispatch = useDispatch();
    const hrStep: any = useSelector<IRootState>(state => state.actionsReducer.hrStep);

    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    const saveFile = () => fileSaver.saveAs(`https://tech-assets.s3.us-east-2.amazonaws.com/sample.xlsx`, `عينة`);

    const forwardClickToInputElement = () => {
        fileInputRef.current!.click();
    };
    const handleFile = (file: any) => {
        let file_size = file.size / (1024 * 1024), maxSize = 12, size;
        if (file.size < 1024) {
            size = file.size + ' B';
        } else if (file.size < (1024 * 1024)) {
            size = Math.floor(file.size / 1024) + ' KB';
        } else {
            size = Math.floor(file.size / (1024 * 1024)) + ' MB';
        }
        console.log(size);

        /* Boilerplate to set up FileReader */
        let acceptable_exe = helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            setValidFiles(true);
        } else if (file_size > maxSize) {
            setValidFiles(true);
            // return Upload.LIST_IGNORE
        } else {
            setValidFiles(false);
            let fileReader = new FileReader(), rowObject: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {

                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rowObject = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                if (rowObject.length === 0) {
                    message.error('الملف لا يجب ان يكون فارغ');
                    return 0
                }
                const newRowObj = Array.isArray(rowObject) && rowObject.map((user: any, key: any) => {
                    return {
                        row: key + 1,
                        userID: user?.userID ? typeof user.netSalary === 'number' ? Number(user?.userID) : null : null,
                        phone: user?.phone ? user?.phone?.toString() : "",
                        name: user?.name ? user?.name?.toString() : '',
                        bankName: user?.bankName ? user?.bankName?.toString() : '',

                        accountHolderName: user?.bankName?.includes('Cash') ? 'Cash' : user?.accountHolderName ? user?.accountHolderName?.toString() : '',
                        accountNumber: user?.bankName?.includes('Cash') ? 'Cash' : user.accountNumber ? typeof user.accountNumber === 'number' ? user.accountNumber.toString() : '' : '',

                        netSalary: user?.netSalary ? typeof user.netSalary === 'number' ? Number(user?.netSalary) : 0 : 0,
                        companyName: user?.companyName ? user?.companyName?.toString() : '',
                        email: user?.email ? user?.email?.toString() : '',
                        nationalID: user?.nationalID ? user?.nationalID?.toString() : '',
                        staff_id: user?.staff_id ? user?.staff_id?.toString() : '',
                    }
                });

                dispatch(getDataSheet({ sheetData: newRowObj }))
                dispatch(hrStepper(hrStep + 1));
            }
        }
    }

    const handleFileChange = (e: { target: { files: any, value: any }; }) => {
        const files = e.target.files;
        if (files && files[0]) handleFile(files[0]);
        e.target.value = null;
    }

    const cancelInstructionsModal = () => {
        setViewInstructions(false)
    }

    const instructions = <>
        <div className="validation">
            <ul className='list'>
                <li>phone</li>
                <span className='simpleDesc'>رقم التليفون: لابد من ادخال رقم محمول مصري</span>

                <li>name </li>
                <span className='simpleDesc'>اسم الموظف </span>

                <li>email</li>
                <span className='simpleDesc'>البريد الالكتروني الخاص بالموظف </span>

                <li>nationalID</li>
                <span className='simpleDesc'>الرقم القومي للموظف المتكون من 14 رقم </span>

                <li>staff_id</li>
                <span className='simpleDesc'>الرقم التعريفي للموظف داخل المؤسسة</span>

                <li>bankName</li>
                <span className='simpleDesc'>اسم البنك </span>

                <li>accountHolderName</li>
                <span className='simpleDesc'>اسم صاحب الحساب</span>

                <li>accountNumber </li>
                <span className='simpleDesc'>رقم الحساب بالبنك</span>

                <li> netSalary</li>
                <span className='simpleDesc'>صافي الراتب الشهري </span>
            </ul>
            <Button className='cancelInstruction' onClick={() => setViewInstructions(false)}>الغاء</Button>
        </div>
    </>
    return (
        <>
            <div className="instructions">
                <Row>
                    <Col xl={18} md={18} sm={24}>
                        <p className="head">قبل تحميل اقم بتحميل النموذج التالي حتي تقوم بملئ البيانات المطلوبه فيه ثم قم بإعادة رفعها الي النظام</p>
                        <p className='fillData' onClick={() => setViewInstructions(true)} ><ExclamationCircleOutlined /><span className='fillHint'>كيفية ملئ بيانات النموذج</span></p>
                    </Col>
                    <Col xl={6} md={6} sm={24}>
                        <Button className='sample' onClick={saveFile}>تنزيل النموذج</Button>
                    </Col>
                </Row>

            </div>

            {/* <Button className='uploadBtn' loading={spin} onClick={forwardClickToInputElement}>تحميل ملف بيانات الموظفين</Button><br /> */}
            <div className='upload' onClick={forwardClickToInputElement}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">انقر هنا لتحميل الملف للتحميل</p>
                <p className="ant-upload-hint">يدعم تحميل ملف واحد</p>
                {validFiles && <p className="error-hint">{'الامتداد المسموح به CSV او XLS حتي 12 ميجابايت'}</p>}
            </div>
            <span className="maxSize">الحجم الموصى حتي 12 ميجا بايت</span>
            <form className="form-inline">
                <div className="form-group">
                    <label htmlFor="file">  </label>
                    <input
                        hidden
                        type="file"
                        className="form-control"
                        id="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept='.xlsx,.xls,.csv'
                    />
                </div>
            </form>


            <TruckingModal visibleModal={viewInstructions}
                content={instructions}
                handleCloseModal={cancelInstructionsModal} title={'كيف اقوم بملئ بيانات النموذج'} />
        </>
    )
}
