import React, { Component, createRef, JSXElementConstructor } from 'react'

import { Form, Select, Button, Row, Col, message, FormInstance, Tag } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { postApi, getApi } from '../stores/actions';
import Spinner from '../components/spinner';
import OutPutResult from '../components/result';
import DataTable from '../components/dataTable';
import ModalComponent from '../components/modal'


const { Option } = Select;

interface Props {
    postApi: any,
    getApi: any,
    getFeesCompaniesApi: string,
    assignFeesCompaniesApi: string,
}
class AssignCompany extends Component<Props & JSXElementConstructor<never>, {}> {
    formRef = createRef<FormInstance>();

    state = {
        loading: false,
        inValidKey: null,
        errorMessage: '',
        companies: [],
        groups: [],
        models: [],
        loadingData: true,
        errorMode: false,
        groupId: null,
        visibleModal: false,
        title: ''
    }
    async componentDidMount() {
        await this.getArrayDataToAssignCompany(this.props.getFeesCompaniesApi, 'companies');
        await this.getArrayDataToAssignCompany(`/fees/getServiceGroup`, 'groups');
        await this.getArrayDataToAssignCompany(`/fees/getModelsFeesDetails`, 'models');
    }
    async componentDidUpdate(prevProps: any,prevState: any) {
        if (prevProps.getFeesCompaniesApi !== this.props.getFeesCompaniesApi) {
            this.setState({ loadingData: true })
            await this.getArrayDataToAssignCompany(this.props.getFeesCompaniesApi, 'companies');
            return
        }
    }
    getArrayDataToAssignCompany = async (apiLink: string, thisState: string) => {
        const arrs = await this.props.getApi(apiLink);
        this.setState({ loadingData: true })
        try {
            const res = await arrs.payload;
            if (res.status === 200) {

                this.setState({ [thisState]: res.data, loadingData: false })

            } else {
                this.setState({ errorMode: true, loadingData: false })
            }
        } catch (error) {
            this.setState({ errorMode: true, loadingData: false })
        }
    }
    sendCompnyFees = async (data: any) => {
        this.setState({ loading: true })
        const sendBrackets = await this.props.postApi(this.props.assignFeesCompaniesApi, data);
        try {
            const res = await sendBrackets.payload;
            if (res.status === 200) {
                this.setState({ loading: false });
                message.success(res.message, 3);
                this.getArrayDataToAssignCompany(this.props.getFeesCompaniesApi, 'companies');
                this.formRef.current!.resetFields();
            } else {
                this.setState({ loading: false });
                if (res.message.includes('the groups must cover all services') || res.message.includes('There is duplicated service inside the groups')) {
                    this.setState({ visibleModal: true, title: res.message });
                    message.error(res.message, 5);
                    return
                }
                message.error(res.message, 5);
            }
        } catch (error) {
            this.setState({ loading: false });
            message.error(`Something went wrong, please try again`, 5);
        }
    }

    assignCompany = (values: any) => {
        console.log(this.props.assignFeesCompaniesApi);
        
        console.log(values);

        let firstData = { serviceGroupId: values.serviceGroupId, feesModels: values.feesModels }
        let projects = values.projects;
        if (!projects || projects.length === 0) {
            this.setState({ loading: true })
            const data = {
                companyId: values.companyId,
                data: [{ serviceGroupId: values.serviceGroupId, feesModels: values.feesModels }]
            };
            this.sendCompnyFees(data);
            // console.log('data in if = ', data);

            return
        } else {
            projects.unshift(firstData)

            this.setState({ loading: true })
            const data = {
                companyId: values.companyId,
                data: projects
            };
            this.sendCompnyFees(data);
            // console.log('data in else = ', data);

        }

        // console.log(data);
    }
    scrollingToError = () => {
        let element: any = document.getElementById('error');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
        refreshPage = () => {
            window.location.reload();
        }


    handleCloseModal = () => {
        this.setState({ visibleModal: false, dataSheet: [] });
    }
    dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    render() {
        console.log(this);
        
        const { loading, groups, companies, models, loadingData, errorMode, visibleModal } = this.state;
        const columns = [
            {
                title: 'Group Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Services',
                dataIndex: 'services',
                key: 'services',
                render: (services: any) => (
                    <>
                        {services.map((tag: any) => (
                            <Tag color="blue" style={{ margin: `2px 5px` }} key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </>
                )
            },
        ];
        const table = <DataTable columns={columns} rows={groups}
            pagination={{}} loading={loading} scrolling={{ x: 100, y: 300 }} />;
        return (
            <div className='companyAssign'>
                {loadingData ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh and try againg'}
                    btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> :
                    <Form
                        ref={this.formRef}
                        initialValues={{ type: "type_1" }}
                        onFinish={this.assignCompany}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Row>

                            <Col span={24}>
                                <label htmlFor="">Company</label>
                                <Form.Item name="companyId" rules={[{ required: true, message: 'Required' }]}>
                                    <Select style={{ width: '100%' }}
                                        placeholder='Please select the company'
                                        showSearch
                                        allowClear
                                        filterOption={((input, option: any) => this.dropDownSearch(input, option))}>
                                        {companies.map((company: any, key: number) => {
                                            return <Option value={company.id} key={key}>{company.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row>
                            <Col span={12}>
                                <label htmlFor="">Service group</label>
                                <Form.Item name="serviceGroupId" rules={[{ required: true, message: 'Required' }]}>
                                    <Select style={{ width: '100%' }} placeholder='Please select the group service'>
                                        {groups.map((group: any, key: number) => {
                                            return <Option value={group.id} x={group} key={key} >{group.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Fees Models</label>
                                <Form.Item
                                    name="feesModels"
                                    rules={[{ required: true, message: 'Required', type: 'array' }]}
                                    hasFeedback
                                >
                                    <Select mode="multiple"
                                        placeholder="Please select the services"
                                        optionFilterProp="children"
                                        filterOption={(input: string, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {models.map((model: any, key: number) => {
                                            return <Option value={model.id} key={key}>{model.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.List name="projects">

                            {(fields, { add, remove }) => (
                                <>

                                    {fields.map(field => (

                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) =>
                                                            prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                        }
                                                    >
                                                        {() => (
                                                            <>
                                                                <label htmlFor="">Service group</label>
                                                                <Form.Item {...field} name={[field.name, 'serviceGroupId']} rules={[{ required: true, message: 'Required' }]}>
                                                                    <Select style={{ width: '100%' }} placeholder='Please select the group service' >
                                                                        {groups.map((group: any, key: number) => {
                                                                            return <Option value={group.id} services={group.services} key={key}>{group.name}</Option>
                                                                        })}
                                                                    </Select>

                                                                </Form.Item>

                                                            </>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={11}>
                                                    <label htmlFor="">Fees Models</label>

                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'feesModels']}
                                                        rules={[{ required: true, message: 'Required', type: 'array' }]}
                                                        hasFeedback
                                                    >
                                                        <Select mode="multiple"
                                                            placeholder="Please select the services"
                                                            optionFilterProp="children"
                                                            filterOption={(input: string, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            {models.map((model: any, key: number) => {
                                                                return <Option value={model.id} key={key}>{model.name}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '27px', padding: '0 0px' }} />


                                                </Col>


                                            </Row>
                                        </>
                                    ))}



                                    {fields.length <= 28 && <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add
                                        </Button>
                                    </Form.Item>}
                                </>
                            )}
                        </Form.List>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>
                }
                <ModalComponent content={table} title={`Group services`} visibleModal={visibleModal} handleCloseModal={this.handleCloseModal} handleAcceptModal={this.handleCloseModal} />

            </div>
        )
    }
}
export default connect(null, { postApi, getApi })(AssignCompany)
