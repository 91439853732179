import React, { Fragment, PureComponent } from 'react'
import { Steps } from 'antd';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { stepperMoving, viewAccountTable } from '../stores/actions';
const { Step } = Steps;
interface Props extends RouteComponentProps<any> {
    componentSteps?: any,
    stepNumber?: number,
    stepperMoving: any;
    request?: boolean,
    viewAccountTable(value: boolean): void,
}
export class Stepper extends PureComponent<Props> {
    componentDidUpdate(prevProps: any) {

        if (this.props.stepNumber !== prevProps.stepNumber) {
            this.clearHistoryParams(-1)
        }
        if (prevProps.stepNumber === -1) {
            this.props.stepperMoving(0);
        }
    }

    clearHistoryParams = (step: number) => {
        const match = this.props.match
        if (match.params.id && this.props.stepNumber === 0) {
            this.props.history.push(match.url.slice(0, match.url.indexOf(match.params.id) - 1))
            this.props.stepperMoving(step);
            this.props.viewAccountTable(false)
        }
    }

    onStepperChange = (step: number) => {
        this.clearHistoryParams(step)
        if (this.props.stepNumber && this.props.stepNumber !== 0 && step < this.props.stepNumber) {
            this.props.stepperMoving(step);
            this.props.viewAccountTable(false)
        }
    }
    render() {
        const { componentSteps, stepNumber } = this.props;

        return (
            <>

                <Steps current={stepNumber} onChange={this.onStepperChange}>

                    {componentSteps.map((step: any, idx: number) => {
                        return <Step key={idx} title={step.title}></Step>
                    })}
                </Steps>
                {componentSteps.map((layout: any, idx: number) => {
                    return stepNumber === layout.id && <Fragment key={idx}>
                        {layout.content}
                    </Fragment>

                })}
            </>
        )
    }
    onStepperClick(arg0: number): void {
        throw new Error('Method not implemented.');
    }
}
const mapStateToProps = (state: any) => ({
    stepNumber: state.actionsReducer.stepNumber,
})
export default withRouter(connect(mapStateToProps, { stepperMoving, viewAccountTable })(Stepper))
//this.props.viewAccountTable(true);