import { Button, Col, DatePicker, Form, InputNumber, Row, message, Space, Modal, Input, FormInstance } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from '../components/dataTable';
import ExportToExcel from '../components/exportExcel';
import OutPutResult from '../components/result';
import Spinner from '../components/spinner';
import { getApi, patchApi } from '../stores/actions';
import Helper from '../utils/helper';
import regex from '../utils/helper'
import { ReloadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

interface Props {
    getApi: any
    patchApi: any,
}
class OfflineShoppingTransactions extends Component<Props> {
    formRef = React.createRef<FormInstance>();

    formFilterRef = React.createRef<FormInstance>();

    state = {
        colums: [],
        rows: [],
        loadingTransactions: false,
        errorView: false,
        errorMessage: '',
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        loading: false,
        requestID: '',
        startDate: '',
        endDate: '',
        errorMode: false,
        loadingConfirm: false,
        cancellationReason: '',
        areaErrors: [],
        visibleModal: false,
        errorLength: false,
        requestIdCanceling: '',
        phone: '',
        canResetFilter: false,
    }

    componentDidMount() {
        const params = {
            count: this.state.pagination.pageSize,
            pages: this.state.pagination.current,
        }
        this.getTransactions(params, true, 1);
    }


    getTransactions = async (params?: any, reload?: any, current?: any, resetFilter?: boolean) => {
        this.setState({ loadingTransactions: reload ? true : false });
        const transactions = await this.props.getApi(`/requests/getMerchantRequests`, { params });
        const data = await transactions.payload;

        let tableColums: { title: string; dataIndex: string; width: number; }[] = [];
        try {

            if (data.status === 200) {
                if (resetFilter) {
                    this.setState({ canResetFilter: false })
                }
                if (data.requests.requests.length === 0) {
                    this.setState({ loadingTransactions: false, loading: false, rows: [], pagination: { ...this.state.pagination, current: 0, total: 0 } });
                    return
                }

                // delete data.requests.requests[0]['transactionStatusID'];

                let keys = Object.keys(data.requests.requests[0]);

                for (const [_key, value] of Object.entries(keys)) {
                    console.log(_key);

                    tableColums.push(
                        { title: Helper.unCamelCase(value), dataIndex: value, width: 150 }
                    );
                }
                const cols: any = {
                    title: 'Action',
                    key: 'operation',
                    fixed: 'right',
                    width: 90,
                    render: (_text: any, record: any) => (
                        <Space size="middle" style={{ color: 'blue' }}>
                            <>
                                {record.transactionStatusID === 7 || record.transactionStatusID === 5 ? <span style={{ color: '#ff7875' }}>{record.requestStatus}</span>
                                    :
                                    <Button type="primary" danger onClick={() => this.transactionCanceling(record)}>
                                        Cancel
                                    </Button>
                                }
                            </>

                        </Space>
                    ),
                }
                tableColums.push(cols)
                let pagination = {
                    current: current,
                    pageSize: this.state.pagination.pageSize,
                    total: data.requests.TotalRequests,
                };
                const indexOfTransactionID = tableColums.findIndex((element) => element.dataIndex === 'transactionStatusID');
                tableColums.splice(indexOfTransactionID, 1);
                this.setState({
                    rows: data.requests.requests, colums: tableColums,
                    loadingTransactions: false, loading: false, pagination: pagination
                });
            } else {
                this.setState({ errorMode: true, loadingTransactions: false, loading: false, errorMessage: data.message });

            }
        } catch (error) {
            this.setState({ errorMode: true, loadingTransactions: false, loading: false, });
        }

    }
    refreshPage = () => {
        window.location.reload();
    }
    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    onFinish = (value: any) => {
        console.log(value);
        if (!value.transactionId && !value.phone && !value.dateRange) {
            message.error(`You can't search on nothing`, 4)
            return
        }

        if (!value.dateRange) {
            this.setState({
                requestID: value.transactionId,
                phone: value.phone,
            }, () => {
                const params = {
                    requestID: this.state.requestID,
                    phone: this.state.phone,
                    count: 10,
                    pages: 1,
                }
                this.setState({ loading: true, ...this.state.pagination, current: 1 });
                this.getTransactions(params, false, 1);
                this.setState({ canResetFilter: true })
            });
            return 0
        }
        this.setState({
            requestID: value.transactionId,
            phone: value.phone,
            startDate: value.dateRange[0]._d,
            endDate: value.dateRange[1]._d
        }, () => {
            const params = {
                requestID: this.state.requestID,
                phone: this.state.phone,
                startDate: this.changeTimeFormat(this.state.startDate, '00:00:00'),
                endDate: this.changeTimeFormat(this.state.endDate, '23:59:59'),
                count: 10,
                pages: 1,
            }
            this.setState({ loading: true, ...this.state.pagination, current: 1 });
            this.getTransactions(params, false, 1)
        });
    }
    clearFilter = () => {
        const params = {
            count: 10,
            pages: 1,
            
        }
        this.setState({ loading: true, pagination: {}, requestID: null,phone:null,startDate:null,endDate:null });
        this.getTransactions(params, false, 1, true);
        this.formFilterRef.current!.resetFields();

    }
    handleTableChange = (e: any) => {
        console.log(e);
        let params: any = {
            count: e.pageSize,
            pages: e.current,
            requestID: this.state.requestID ? this.state.requestID : '',
            phone: this.state.phone ? this.state.phone : '',
            startDate: this.state.startDate && this.changeTimeFormat(this.state.startDate, '00:00:00'),
            endDate: this.state.endDate && this.changeTimeFormat(this.state.endDate, '23:59:59'),
        }
        console.log(params);

        this.setState({ loading: true, pagination: e }, () => {
            this.getTransactions(params, false, this.state.pagination.current)
        });


    }
    changeTimeFormat = (str: string, constantTime: string) => {
        var date = new Date(str),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        console.log([date.getFullYear(), month, day].join("/") + ` ${constantTime}`);

        return [date.getFullYear(), month, day].join("/") + ` ${constantTime}`;
    };
    changePicker = (val: any) => {
        console.log(val);
        if (!val) {
            this.setState({
                startDate: '',
                endDate: '',
            });
        }
    }

    transactionCanceling = async (_record: any) => {
        this.setState({ requestIdCanceling: _record.requestID, visibleModal: true });
    }
    //Modal
    handleCancelRequestButton = () => {
        this.setState({ visibleModal: true, successNotification: false });
    }
    handleAcceptCancelRequest = async () => {
        if (this.state.cancellationReason.length === 0) {

            this.setState({ errorLength: true });
            return
        }
        if (this.state.areaErrors.length > 0) {
            return
        }
        this.setState({ loadingConfirm: true })
        const reason = {
            cancellationReason: this.state.cancellationReason,
        }
        const cancelMerchantRquest = await this.props.patchApi(`/requests/cancelMerchantRequest/${this.state.requestIdCanceling}`, reason);
        const data = await cancelMerchantRquest.payload;
        

        try {
            if (data.status === 200) {
                this.formRef.current!.resetFields();
                this.setState({
                    loadingConfirm: false, visibleModal: false, cancellationReason: ''
                }, () => {
                    message.success(data.message, 5)
                    const params = {
                        count: this.state.pagination.pageSize,
                        pages: this.state.pagination.current,
                        requestID: this.state.requestID ? this.state.requestID : '',
                        phone: this.state.phone ? this.state.phone : '',
                        startDate: this.state.startDate && this.changeTimeFormat(this.state.startDate, '00:00:00'),
                        endDate: this.state.endDate && this.changeTimeFormat(this.state.endDate, '23:59:59'),
                    }
                    this.getTransactions(params, false, this.state.pagination.current);
                });
            } else {
                this.setState({
                    loadingConfirm: false, errorMessage: data.message, cancellationReason: ''
                });
            }
        } catch (error) {
            this.setState({ loadingConfirm: false, errorMessage: 'Something went wrong,please try again', cancellationReason: '' });
        }
    }
    handleAreaChange = (e: { target: { value: string; }; }) => {
        let value = e.target.value;

        this.setState({ cancellationReason: value, errorLength: false })

    }
    areaChange = (e: any) => {
        this.setState({ cancellationReason: e[0].value, areaErrors: e[0].errors, errorLength: false, errorMessage: '' })
    }
    onCancelModel = () => {
        this.setState({ visibleModal: false, errorLength: false, errorMessage: '', cancellationReason: '' })
        this.formRef.current!.resetFields();
    }
    reqNumChange = (e: any) => {
        this.setState({ errorMessage: '' })
        console.log(e[0].value);
    }

    render() {
        const { colums, rows, loadingTransactions, errorView, errorMessage, loading, pagination, errorMode, loadingConfirm, visibleModal, errorLength, requestIdCanceling, canResetFilter } = this.state;
        const dateFormat = 'YYYY/MM/DD';

        return <div className='offTransactions'>
            <>{errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> :
                <>
                    {loadingTransactions ?
                        <Spinner size='large' /> : errorView ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                            btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> : <>
                            <Form
                                ref={this.formFilterRef}
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                autoComplete="off"
                            >
                                <Row className='statusRow'>
                                    <Col md={8}>
                                        <label htmlFor="">Request ID </label>
                                        <Form.Item name='transactionId' >
                                            <InputNumber placeholder='Search by request id' />
                                        </Form.Item>
                                    </Col>
                                    <Col md={8}>
                                        <label htmlFor="">Phone Number </label>
                                        <Form.Item name='phone' rules={[{ pattern: regex.regexValidation.egyptPhoneNum, message: 'Please input a phone number correctly' }]}>
                                            <Input placeholder='Search by phone number' />
                                        </Form.Item>
                                    </Col>
                                    <Col md={8}>

                                        <label htmlFor="">Date </label>
                                        <Form.Item name="dateRange">
                                            <RangePicker format={dateFormat} onChange={this.changePicker} />
                                        </Form.Item>

                                    </Col>


                                </Row>
                                <Row className='statusRow' style={{ float: 'right' }}>
                                    <Col style={{ float: 'right', margin: '5px 0 0 0' }}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{ background: '#357C3C', borderColor: '#357C3C' }}>
                                                Search
                                            </Button>
                                        </Form.Item></Col>
                                    {canResetFilter && <Col style={{ float: 'right', margin: '17px 0 0 0' }}>
                                        <Form.Item >
                                            <Button danger onClick={this.clearFilter}>
                                                Clear filter
                                            </Button>
                                        </Form.Item></Col>}
                                    {rows.length !== 0 && <Col >
                                        <ExportToExcel apiData={rows} fileName={'transactions'} />
                                    </Col>}
                                    <Col style={{ float: 'right', margin: '19px 0 0 0' }}>
                                        <Form.Item>
                                            <ReloadOutlined style={{ fontSize: '20px' }} onClick={this.refreshPage} />
                                        </Form.Item>
                                    </Col>


                                </Row>
                            </Form>

                            <DataTable columns={colums} rows={rows} handleTableChange={this.handleTableChange}
                                pagination={pagination} loading={loading} scrolling={{ x: 100, y: 300 }} />
                            <div className='modal'>
                                <Modal
                                    confirmLoading={loadingConfirm}
                                    title="Cancel Confirmation"
                                    centered
                                    visible={visibleModal}
                                    okText='Yes'
                                    cancelText='No'
                                    onOk={this.handleAcceptCancelRequest}
                                    onCancel={this.onCancelModel}
                                >
                                    <label> Reason </label><span style={{ color: '#ff4d4f' }}>*</span>
                                    <Form name="areaa" ref={this.formRef} onFieldsChange={this.areaChange}>
                                        <Form.Item name={['user', 'introduction']} rules={[
                                            { min: 10, max: 150, message: 'The reason must be between 10 and 150 characters', },
                                        ]}>
                                            <Input.TextArea id={errorLength ? 'areaa_user_introduction-error' : ''} />
                                        </Form.Item>
                                    </Form>


                                    {errorLength && <span style={{ color: '#ff4d4f', position: 'relative', bottom: '0px' }}>Reason is requierd</span>}
                                    {errorMessage && <span style={{ color: '#ff4d4f', position: 'relative', bottom: '0px' }}>{errorMessage}</span>}
                                    <p className='confirmationText'>Are you sure you want to cancel this request #{requestIdCanceling} ?</p>
                                </Modal>
                            </div>
                        </>
                    }
                </>
            }</>
        </div>;
    }
}

export default connect(null, { getApi, patchApi })(OfflineShoppingTransactions);
