import { Col, Descriptions, Row } from 'antd'
// import moment from 'moment'
// import React from 'react'
import helper from '../../../utils/helper'
import { transactionInterface } from '../../../utils/commonInterfaces' 
// import { info } from 'console'

export default function ExpenseTemp(props: transactionInterface) {
    const { transaction } = props;
    console.log("🚀 ~ file: expenseTemp.tsx:10 ~ ExpenseTemp ~ transaction:", transaction)
    return (
        <>
            <div className="invoice" style={{ width: '700px' }}>
                <div style={{ textAlign: 'center' }}>
                    <Row>
                        <Col span={8}>
                            <img src={transaction?.logoURL} alt="Compony logo" width={'200px'} height={'100px'} />
                        </Col>
                        <Col span={8}>
                            <h2 style={{ position: 'relative', top: '20px' }}>سند تحويل الاموال</h2>
                        </Col>
                        <Col span={8}>
                            <img src="https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/Xpense.png" alt="" width={'200px'} height={'100px'} />
                        </Col>
                    </Row>
                </div>

                <div className="invoiceBody">
                    <Descriptions bordered contentStyle={{ fontSize: '16px', color: '#5a5858' }} labelStyle={{ fontSize: '17px' }}>
                        {<Descriptions.Item span={3} label="اسم الشركه">{transaction?.company ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label="رقم الطلب">{transaction?.transactionID ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label="اسـم المستلـم">{transaction?.name ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label="الكـود الوظيفـي للمستلـم">{transaction?.userID?.staffID ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label="الرقـم القومـي للمستلـم">{transaction?.userID?.nationalID ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label="اسـم المرسـل">{`${transaction?.actionBy?.firstName} ${transaction?.actionBy?.middleName} ${transaction?.actionBy?.lastName}` ?? 'لا يوجد'}</Descriptions.Item>}
                        {transaction.transactionsBreakdown && Array.isArray(transaction.transactionsBreakdown) && transaction.transactionsBreakdown.map((trans: any, key: number) => {
                            return <Descriptions.Item key={key} span={3} label={trans.serviceID.nameAr}>{`${helper.amountFormatter(Math.trunc(trans.amount))} جنيه`}</Descriptions.Item>
                        })}
                        {<Descriptions.Item span={3} label=" المبلغ الكلي للمصروفات">{helper.amountFormatter(transaction?.totalAmount) ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label=" التاريـخ">{transaction?.createdAt ?? 'لا يوجد'}</Descriptions.Item>}
                        {<Descriptions.Item span={3} label="رقم المستند الداخلي">{transaction?.companyRef ?? 'لا يوجد'}</Descriptions.Item>}

                    </Descriptions>

                    <span style={{ float: 'right', fontWeight: 600 }}>
                        <span>التوقيــع</span><br />
                        <span style={{ position: 'relative', top: '5' }}>التاريــــخ</span>
                    </span>
                </div>

            </div>
        </>
    )
}

