import React, { Component } from 'react'
import { connect } from 'react-redux';
import Stepper from '../../components/stepper';
import BulkUpdateForm from './bulkUpdateForm';
import BulkUpdateUploadSheet from './bulkUpdateUploadSheet';

interface Props{
    removeData?: boolean
}

class BulkUpdateUserInfo extends Component <Props> {
    state = {
        sheetData: [],
    }

    componentDidUpdate(prevProps:any, prevState:any){
        if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
            this.setState({sheetData:[]})
        }
    }
    setData = (sheetData: []) => this.setState({ sheetData })
    render() {
        return (
            <div className='bulkUpdateUserInfo'>
                <Stepper componentSteps={[
                    {
                        title: 'Upload Users Sheet',
                        content: <BulkUpdateUploadSheet uploadedData={this.state.sheetData} setData={this.setData} />,
                        id: 0,
                    },
                    {
                        title: 'Submit Data',
                        content: <BulkUpdateForm uploadedData={this.state.sheetData} setData={this.setData}/>,
                        id: 1
                    }
                ]} />
            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, null)(BulkUpdateUserInfo)