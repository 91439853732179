import { Button, Col, Form, FormInstance, InputNumber, Row, Select, message } from 'antd';
import { String } from 'aws-sdk/clients/acm';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OutPutResult from '../components/result';
import { errorMessage, otpSentSuccessfully } from '../utils/commonStringsConsts';
import helper from '../utils/helper';
import { sendOTPApi } from '../utils/apisConsts';

interface State {
    actionsReducer: {
        removeData: boolean
    }
}
interface Props {
    phone?: string
}
const { Option } = Select;
export default function SendUserOtp(props: Props) {
    const regex = helper.regexValidation
    const defaultMessage = props.phone ? "Please submit the form to send OTP" : "Please enter phone number to send its OTP"
    const formRef = React.createRef<FormInstance>();
    const removeData = useSelector<State, boolean>(state => state.actionsReducer.removeData)
    const [gettingOtp, setGettingOtp] = useState(false)
    const [optMessage, setOtpMessage] = useState(defaultMessage)

    useEffect(() => {
        if (removeData) {
            setOtpMessage(defaultMessage)
            formRef.current?.resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeData])

    const getUserInfo = async (phone: String) => {
        try {
            const userInfo: any = await helper.getUserInfo(phone)
            if (userInfo.status === 200) {
                return userInfo.userInfo.language
            }
            else {
                setOtpMessage(userInfo.message)
            }
            return null
        }
        catch (error) {
            setOtpMessage(errorMessage)
            return null
        }
    }
    const senOtp = async (values: { phone: string, type: (0 | 1 | 2 | 3), lang: null | ("ar" | "en") }) => {
        const phone = values.phone[0] === "0" ? values.phone : `0${values.phone}`;
        const info = { phone };
        setGettingOtp(true)
        try {
            if (values.type === 0 || values.type === 3) {
                try {
                    await helper.callApiRequest(sendOTPApi, 'POST', '', info);
                    message.success(otpSentSuccessfully);
                } catch (error: any) {
                    error.status !== 404 ? message.error(error?.data?.message[0]) : message.error(errorMessage);
                }
            } else {
                const userLang = await getUserInfo(phone)
                if (userLang || values.lang) {
                    const res: any = await helper.sendOtpTwilio(phone, values.type, values.lang ? values.lang : userLang)
                    setOtpMessage(res.message)
                }
                else {
                    setOtpMessage("Please Choose OTP language")
                }
            }
        } catch (e) {
            setOtpMessage(errorMessage)
            setGettingOtp(false)
        }
        setGettingOtp(false)
    }


    return (
        <div className='getUserOtp'>
            <Form className='phoneNumberForm' onFinish={senOtp} ref={formRef} initialValues={{ phone: props.phone }}>
                <Row>
                    <Col span={24}>
                        <Form.Item name='phone' rules={[
                            { required: true, message: 'Phone is mandatory' },
                            {
                                pattern: regex.egyptPhoneNum, message: 'Please enter Egyptian number'
                            }]}>
                            <InputNumber placeholder='Enter the phone number' disabled={props.phone ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='type' initialValue={1}>
                            <Select
                                placeholder="Select a type"
                            >
                                <Option value={0}>SMS</Option>
                                <Option value={1}>WhatsApp</Option>
                                {/* <Option value={2}>Voice Call</Option> */}
                                <Option value={3}>SMS via Amazon</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='lang' initialValue={null}>
                            <Select
                                placeholder="Select a language"
                            >
                                <Option value={null}>Account language</Option>
                                <Option value={"ar"}>Arabic</Option>
                                <Option value={"en"}>English</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" block htmlType="submit" loading={gettingOtp}>
                                Send OTP
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <OutPutResult title={optMessage} subTitle={' '} />
        </div>
    )
}