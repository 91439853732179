import { Badge } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FalseIcon, TrueIcon } from '../../utils/commonIcons'
import UserInfoUI from '../../components/uiInfo';
import helper from '../../utils/helper'
import UpdateUserInfo from '../editUserInfo/editUserInfo';
import ViewEditApprovedUserSInfo from '../editApprovedUsersInfo/viewEditApprovedUsersInfo';
import ViewUpdateBankInfo from '../updateBankInfo/viewUpdateBankInfo';
import ViewUserSalary from '../ChangeUserSalary/viewUserSalary';
import UpdateUserCaps from '../updateUserCaps/updateUserCaps';
import ViewAssignUser from '../assignUserToFeesModel/viewAssignUser';
import ViewUserSuspending from '../userSuspending/viewUserSuspending';
import UserUnSuspending from '../userUnSuspending/userUnSuspending';
import CreateUserComment from '../createUserComments/createUserComment';
interface Props {
    info?: {
        userID: number,
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountHolderNameEn: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        hiringDate: string,
        jobTitle: string,
        hasMeezaCard: number,
        department: any,
        suspensionStatus?: {
            comment: string,
            date: string,
            isSuspended: boolean,
            reason: string
        },
        accountCreatedOn: string,
        status: string,
        statusReason: string,
        outstandingAmount: number,
        myBalance: number,
        currentBalance:number,
        rewardBalance: number,
        dbr: number,
        maxMonthlyRequestsLimit: number,
        vipFreeMonths: number,
        vipFreeMonthsStartDate: number,
        appVersion: string,
        platform: string,
        comment: string,
        onGoingAdvancesPercentThreshold: number,
        onGoingAdvancesRequests: number,
        userStatusTypeID: number
    }
}

class UserInfo extends Component<Props> {
    state = {
        basicInfo: [
            { key: 'User ID', value: this.props.info?.userID ? this.props.info.userID : 'NA' },
            { key: 'Name', value: this.props.info?.name ? this.props.info?.name : 'NA' },
            { key: 'Company', value: this.props.info?.companyName ? this.props.info?.companyName : 'NA' },
            { key: 'Staff ID', value: this.props.info?.staff_id ? this.props.info?.staff_id : 'NA' },
            { key: 'Job Title', value: this.props.info?.jobTitle ? this.props.info?.jobTitle : 'NA' },
            { key: 'Department', value: this.props.info?.department?.nameEn ? this.props.info?.department?.nameEn : 'NA' },
            { key: 'Account Created On', value: this.props.info?.accountCreatedAt ? helper.changeDateFormat(this.props.info?.accountCreatedAt) : 'NA' },
            { key: 'Hiring Date', value: this.props.info?.hiringDate ? helper.changeDateFormat(this.props.info?.hiringDate) : 'NA' },
            {
                key: 'Status', value: this.props.info?.userStatus ? <Badge color={this.props.info?.userStatus.includes('Active') ? "green" : 'grey'}
                    status={this.props.info?.userStatus.includes('Active') ? "processing" : 'default'} text={this.props.info?.userStatus} /> : 'NA'
            },
            { key: 'Status Reason', value: this.props.info?.userStatusType ? this.props.info?.userStatusType : 'NA' }
        ],
        personalInfo: [
            { key: 'Phone', value: this.props.info?.phone ? this.props.info?.phone : 'NA' },
            { key: 'Email', value: this.props.info?.email ? this.props.info?.email : 'NA' },
            { key: 'National ID', value: this.props.info?.nationalIDNumber ? this.props.info?.nationalIDNumber : 'NA' }
        ],
        bankInfo: [
            { key: 'Bank Name', value: this.props.info?.bankName ? this.props.info?.bankName : 'NA' },
            { key: 'Bank Account holder Name', value: this.props.info?.bankAccountHolderName ? this.props.info?.bankAccountHolderName : 'NA' },
            { key: 'Bank Account holder Name (English)', value: this.props.info?.bankAccountHolderNameEn ? this.props.info.bankAccountHolderNameEn : 'NA' },
            { key: 'Bank Account Number', value: this.props.info?.bankAccountNumber ? this.props.info?.bankAccountNumber : 'NA' },
            { key: 'Meeza Card Status', value: this.props.info?.hasMeezaCard === 1 ? 'Requested' : this.props.info?.hasMeezaCard === 2 ? 'Received' : 'NA' },
        ],
    
        salaryInfo: [
            { key: 'Net Salary', value: this.props.info?.netSalary ? `${helper.amountFormatter(this.props.info?.netSalary)} EGP` : 'NA' }
        ],
        balanceInfo: [
            { key: 'Current Balance', value: this.props.info?.currentBalance ? `${helper.amountFormatter(this.props.info?.currentBalance)} EGP` : 'NA' },
            { key: 'Maximum Balance', value: this.props.info?.maxBalance ? `${helper.amountFormatter(this.props.info?.maxBalance)} EGP` : 'NA' },
            { key: 'Outstanding Amount', value: this.props.info?.outstandingAmount ? `${helper.amountFormatter(this.props.info?.outstandingAmount)} EGP` : 'NA' },
            { key: 'Reward Balance', value: this.props.info?.rewardBalance ? `${helper.amountFormatter(this.props.info?.rewardBalance)} EGP` : 'NA' },
            { key: 'DBR', value: this.props.info?.dbr ? this.props.info?.dbr : 'NA' },
    
        ],
        requestLimitationInfo: [
            { key: 'Maximum Active Requests Allowed', value: this.props.info?.onGoingAdvancesRequests ? this.props.info?.onGoingAdvancesRequests : 'NA' },
            { key: 'Maximum Active Requests Percentage Allowed', value: this.props.info?.onGoingAdvancesPercentThreshold ? this.props.info?.onGoingAdvancesPercentThreshold : 'NA' },
            { key: 'Monthly Requests Limit', value: this.props.info?.maxMonthlyRequestsLimit ? this.props.info?.maxMonthlyRequestsLimit : 'NA' }
    
        ],
        companyConfigInfo: [
            { key: 'Suspended Service Groups', value: this.props.info?.phone ? 'Maximum Active Requests Allowed' : 'NA' },
            { key: 'Maximum Advance', value: this.props.info?.maxAdvancePercentAllowedPerCompany ? this.props.info?.maxAdvancePercentAllowedPerCompany : 'NA' },
            { key: 'Maximum Shopping Balance', value: this.props.info?.maxMonthlyRequestsLimit ? this.props.info?.maxMonthlyRequestsLimit : 'NA' },
            { key: 'Maximum Balance', value: this.props.info?.phone ? 'Maximum Active Requests Allowed' : 'NA' },
            { key: 'Free Period on Deductions', value: this.props.info?.maxAdvancePercentAllowedPerCompany ? this.props.info?.maxAdvancePercentAllowedPerCompany : 'NA' },
            { key: 'Free Period Expiry Date', value: this.props.info?.maxMonthlyRequestsLimit ? this.props.info?.maxMonthlyRequestsLimit : 'NA' }
        ],
        vipUserInfo: [
            { key: 'Number of Free Months', value: this.props.info?.vipFreeMonths && this.props.info?.vipFreeMonths },
            { key: 'Start Date', value: this.props.info?.netSalary ? `${helper.changeDateFormat(this.props.info?.vipFreeMonthsStartDate)}` : 'NA' }
    
        ],
        suspensionStatusInfo: [
            { key: 'Suspended', value: this.props.info?.suspensionStatus?.isSuspended ? TrueIcon : FalseIcon },
            { key: 'Suspended On', value: this.props.info?.suspensionStatus?.date ? helper.changeDateFormat(this.props.info?.suspensionStatus.date) : 'NA' },
            { key: 'Comment', value: this.props.info?.suspensionStatus?.comment ? this.props.info?.suspensionStatus.comment : 'NA' }
    
        ],
        systemInfo: [
            { key: 'App Version', value: this.props.info?.appVersion ? this.props.info?.appVersion : 'NA' },
            { key: 'Last Login', value: this.props.info?.platform ? this.props.info?.platform : 'NA' },
            { key: 'Platform', value: this.props.info?.platform ? this.props.info?.platform : 'NA' }
    
        ],
        commentInfo: [
            { key: 'Comment', value: this.props.info?.comment ? this.props.info?.comment : 'NA' },
    
        ]
    };
    componentDidUpdate(nextProps: Readonly<Props>): void {
        if (nextProps.info !== this.props.info) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    commentInfo: [
                    { key: 'Comment', value: this.props.info?.comment ? this.props.info?.comment : 'NA' },
                ]}
            })
        }
    }
    render() {
        const { info } = this.props;
        console.log("🚀 ~ file: userInfo.tsx:106 ~ UserInfo ~ render ~ info:", info)

        return (
            <div>
                {info &&
                    <>
                        <UserInfoUI userData={this.state.basicInfo} activeKey={1} header={'Basic Information'}
                            action={this.props?.info?.userStatusTypeID === 1 ? <ViewEditApprovedUserSInfo /> : <UpdateUserInfo />}
                            actionName={this.props?.info?.userStatusTypeID === 1 ? 'updateApprovedUserInfo' : 'updateUserInfo'}
                            drawerTitle={this.props?.info?.userStatusTypeID === 1 ? 'Change approved Users Info' : 'Update User Information'} />
                        <UserInfoUI userData={this.state.personalInfo} activeKey={2} header={'Personal Information'}
                            action={this.props?.info?.userStatusTypeID === 1 ? <ViewEditApprovedUserSInfo /> : <UpdateUserInfo />}
                            actionName={this.props?.info?.userStatusTypeID === 1 ? 'updateApprovedUserInfo' : 'updateUserInfo'}
                            drawerTitle={this.props?.info?.userStatusTypeID === 1 ? 'Change approved Users Info' : 'Update User Information'} />
                        <UserInfoUI userData={this.state.bankInfo} activeKey={3} header={'Bank Information'}
                            action={<ViewUpdateBankInfo />} actionName='updateBankInfo' drawerTitle='User Bank Information'
                        />
                        <UserInfoUI userData={this.state.salaryInfo} activeKey={4} header={'Salary Information'}
                            action={<ViewUserSalary />} actionName='updateUserSalary' drawerTitle='User Salary' />
                        <UserInfoUI userData={this.state.balanceInfo} activeKey={5} header={'Balance Information'} nonEditable={true} />
                        <UserInfoUI userData={this.state.requestLimitationInfo} activeKey={6} header={'Request Limitations (CAPS)'}
                            action={<UpdateUserCaps />} actionName='updateUserCap' drawerTitle='Update User Services Cap' />
                        <UserInfoUI userData={this.state.vipUserInfo} activeKey={7} header={'VIP User'}
                            action={<ViewAssignUser />} actionName='assignUserToFeesModel' drawerTitle='Assign VIP User' />
                        <UserInfoUI userData={this.state.suspensionStatusInfo} activeKey={8} header={'Suspension Status'}
                            action={!this.props?.info?.suspensionStatus?.isSuspended ? <ViewUserSuspending /> : <UserUnSuspending />}
                            actionName={!this.props?.info?.suspensionStatus?.isSuspended ? 'suspendUser' : 'unSuspendUser'}
                            drawerTitle={!this.props?.info?.suspensionStatus?.isSuspended ? 'Suspend User' : 'Un-Suspend User'}
                        />
                        <UserInfoUI userData={this.state.systemInfo} activeKey={9} header={'System Information'} nonEditable={true} />
                        <UserInfoUI userData={this.state.commentInfo} activeKey={10} header={'General Comment'}
                            action={<CreateUserComment />}
                            actionName='userCreateSingleComment' drawerTitle='Add User Comment'
                        />
                    </>
                }
            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, null)(UserInfo)