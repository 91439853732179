import React, { Component } from 'react'
import { notification } from 'antd';
interface Props{
    message:string,
    placement:any,
    duration:number,
    icon:any,
}
export class NotificationAlert extends Component<Props> {
    componentDidMount(){
        const {message,placement,duration,icon}=this.props;
        this.openNotificationWithIcon(message,placement,duration,icon)
    }
    openNotificationWithIcon = (message: string, placement: any, duration: number, icon: any) => {
        notification.open({
            message,
            placement,
            duration,
            icon,
        });
    };
    render() {
        return (
            <div className='alert'>
            </div>
        )
    }
}

export default NotificationAlert
