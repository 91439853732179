import React, { Component } from 'react'
import WelcomeEmail from './welcomeEmail';
import { Email, renderEmail } from 'react-html-email'
import { connect } from 'react-redux';
import WelcomeEmailEnglish from './welcomeEmailEnglish';

const AWS = require('aws-sdk');
const nodemailer = require('nodemailer');

// Set the region, accessKeyId, secretAccessKey and apiVersion
AWS.config.update({
    apiVersion: '2010-12-01',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1'
});

class SendEmail extends Component {

    sendWelcomeEmail = async () => {
        let transporter = nodemailer.createTransport({
            SES: new AWS.SES()
        })
        try {
            const sendingEmail = await transporter.sendMail({
                from: "no-reply@nowpay.cash",
                to: "samarsayed.now@gmail.com",
                subject: "test",
                text: "tessst",
                html: renderEmail(
                    <Email>
                        {this.props.emailLang === "ar" ? <WelcomeEmail />
                            : <WelcomeEmailEnglish />}
                    </Email>
                )
            })
            console.log("🚀 ~ file: sendEmail.jsx ~ line 45 ~ SendEmail ~ sendWelcomeEmail= ~ sendingEmail", sendingEmail)
        }
        catch (e) {
            console.log("🚀 ~ file: sendEmail.jsx ~ line 50 ~ SendEmail ~ sendWelcomeEmail= ~ e", e)
        }
    }

    render() {
        const { emailLang } = this.props
        return (
            <div>
                <button onClick={this.sendWelcomeEmail}>send</button>
                <br />
                <br />
                {emailLang === "ar" ? <WelcomeEmail />
                    : <WelcomeEmailEnglish />}
            </div>
        )
    }
}

const mapStateToProp = (state) => ({
    emailLang: state.actionsReducer.emailLang
})

export default connect(mapStateToProp, null)(SendEmail)
