import { Form, Row, Col, Button, FormInstance, DatePicker, Input, message, Select, Spin, Result, InputNumber, Switch, Badge, Dropdown, Menu } from 'antd'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DataTable from '../../components/dataTable'
import OutPutResult from '../../components/result'
import { getApi, request } from '../../stores/actions'
import helper from '../../utils/helper'
import { columnsOfDeductionsTable } from '../../utils/tableColumns';
import ModalComponent from '../../components/modal';
import PdfComponent from '../../components/pdf'
import InvoiceTemp from './invoiceTemp'
import { exportToCSV } from '../../components/exportExcel'
import { generateInvoiceApi, getAllInstallmentsApi, getInvoicesApi, getPaymentCyclesApi, getTotalDeductionsSumApi } from '../../utils/apisConsts'
import { checkSumInvoicesError, errorMessage } from '../../utils/commonStringsConsts'
import { AxiosRequestConfig } from 'axios'
import { SmileOutlined, DownloadOutlined } from "@ant-design/icons";
import Spinner from '../../components/spinner'
import ErrorModal from '../../components/errorModal'

const { Option } = Select;

const { RangePicker } = DatePicker;

interface Props {
  info: number[],
  getApi(api: string, info: {}): any,
  companies?: [],
  request(obj: AxiosRequestConfig): any,
  generateInvoice: boolean
}
export class Deductions extends Component<Props> {
  state = {
    rows: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    filters: {
      userID: null,
      requestID: null,
      statusID: null,
      dateFrom: null,
      dateTo: null,
      staffID: null,
      paymentCycle: null,
      companyID: this.props.info,
    },
    loading: false,
    resetFilterBtn: false,
    errorMode: false,
    errorMessage: null,
    visibleModal: false,
    invoiceObj: {
      salariesPaid: '',
      fees: 0,
      subject: '',
      com: '',
      date: '',
      serial: '',
      settled: '',
      total: '',
      taxRate: 0,
      taxFees: 0,
      debitAndCredit: 0,
      discount: 0,
      fileName: '',
      previousCreditRevenue: 0
    },
    downloadAllDeduction: false,
    allPaymentCycles: [],
    showPaymentCycles: false,
    deductionsSum: 0,
    deductionsSumLoading: false,
    loadingInvoice: false,
    loadingPivot: false,
    generateInvoiceForm: {
      visible: false,
      withFees: true,
      withTax: false,
      taxRate: 3
    },
    loadingPaymentCycles: false,
    sheetsModelVisible: false,
    isDeductions: false,
    isLockedCycle: false,
    lockedInvoice: { taxRatePercent: null },
    deductionDataFile: '',
    companyNameFile: '',
    visibleErrorModal: false
  }
  formFilterRef = React.createRef<FormInstance>();
  invoiceFormRef = React.createRef<FormInstance>();
  pivotFormRef = React.createRef<FormInstance>();


  componentDidMount() {
    if (this.props.info && this.props.info?.length === 1) {
      const company = { data: this.props.companies?.filter((item: { id: number }) => item.id === this.props.info[0])[0] }
      company.data && this.getPaymentCycles(this.props.info[0], company)
    }
  }

  getDeductions = async () => {
    this.getDeductionsSum();
    this.setState({ loading: true })
    let filters: { [key: string]: any } = this.state.filters
    if (filters.paymentCycle) {
      delete filters.dateFrom
      delete filters.dateTo
    }
    const params = {
      rowsPerPage: this.state.pagination.pageSize,
      pageNum: this.state.pagination.current,
      ...filters
    };

    const transactions = await this.props.getApi(getAllInstallmentsApi, { params });
    const data = await transactions.payload;

    try {
      if (data.status === 200) {
        let rowTable = Array.isArray(data.installments) && data.installments.map((installment: any) => {
          return ({
            ...installment,
            instalmentOrder: `${String(installment.instalmentOrder)}/${String(installment.numberOfMonths)}`,
            paymentCycleDate: helper.changeDateFormat(installment.paymentCycleDate),
            jobTitle: installment.jobTitle ? installment.jobTitle : 'NA',

          })
        })
        this.setState({
          rows: rowTable,
          loading: false,
          pagination: { ...this.state.pagination, total: data.installmentCount },
          companyNameFile: data?.installments[0]?.companyName,
          deductionDataFile: data?.installments[0]?.paymentCycleDate
        });
      } else {
        this.setState({ errorMode: true, loading: false, errorMessage: data.message });
      }
    } catch (error) {
      this.setState({ errorMode: true, loading: false, errorMessage: errorMessage });
    }
  }

  generateDeductionsReport = async (formValues: any) => {
    if (this.state.rows.length === 0) {
      message.error("There are no deductions to be downloaded")
      return null
    }
    if (this.state.pagination.total <= 10) {
      return this.state.rows
    }
    this.setState({ downloadAllDeduction: true })
    let filters: { [key: string]: any } = this.state.filters
    if (filters.paymentCycle) {
      delete filters.dateFrom
      delete filters.dateTo
    }
    const params = filters
    const dedicationData = await this.props.getApi(getAllInstallmentsApi, { params });
    const data = await dedicationData.payload;

    try {
      if (data.status === 200) {
        let rowTable = Array.isArray(data.installments) && data.installments.map((installment: any) => {
          formValues && !formValues.withJobTitle && delete installment.jobTitle
          formValues && !formValues.withLocation && delete installment.location
          return (
            {
              ...installment,
              instalmentOrder: `${String(installment.instalmentOrder)}/${String(installment.numberOfMonths)}`,
              paymentCycleDate: helper.changeDateFormat(installment.paymentCycleDate),
              ...(formValues && formValues.withJobTitle && { jobTitle: installment.jobTitle ? installment.jobTitle : 'NA' })
            }
          )
        })
        this.setState({
          downloadAllDeduction: false,
          pagination: { ...this.state.pagination, total: data.installmentCount },
          isDeductions: false
        });
        exportToCSV(rowTable, `${moment(this.state.deductionDataFile).format("MMMM, YYYY")} ${this.state.companyNameFile}_Ded`)
        return rowTable
      } else {
        this.setState({ downloadAllDeduction: false, loading: false });
        message.error(data.message, 4)
        return
      }
    } catch (error) {
      this.setState({ downloadAllDeduction: false, loading: false });
      message.error(errorMessage, 4)
      return
    }
  }

  handleSearch = (values: any) => {
    if (!values.id && !values.advanceID && !values.userID && !values.companyID && !values.staffID && !values.paymentCycle && !values.dateRange) {
      message.error(`You can't search on nothing`, 4);
      return;
    }
    let filters: { [key: string]: any } = this.state.filters
    if (values.paymentCycle) {
      delete filters.dateFrom
      delete filters.dateTo
    }
    this.setState({
      resetFilterBtn: true,
      pagination: { ...this.state.pagination, current: 1 },
      filters: {
        ...filters,
        ...(values.advanceID ? { requestID: values.advanceID } : { requestID: null }),
        ...(values.dateRange ? {
          dateFrom: moment(values.dateRange[0]._d).format("YYYY/MM/DD"),
          dateTo: moment(values.dateRange[1]._d).format("YYYY/MM/DD")
        } : { dateFrom: null, dateTo: null }),
        ...(values.companyID ? { companyID: [values.companyID] } : { companyID: this.props.info }),
        ...(values.userID ? { userID: values.userID } : { userID: null }),
        ...(values.staffID ? { staffID: values.staffID } : { staffID: null }),
        ...(values.paymentCycle ? { paymentCycle: values.paymentCycle } : { paymentCycle: null })
      },
    }, async () => {
      if (values.paymentCycle) {
        let paymentCycleObj: { isLocked: boolean } = this.state.allPaymentCycles.filter((paymentCycle: any) => paymentCycle.paymentCycle === values.paymentCycle)[0]
        if (paymentCycleObj && paymentCycleObj.isLocked) {
          this.setState({ isLockedCycle: true })
          await this.getLockedInvoice()
        } else {
          this.setState({ isLockedCycle: false, lockedInvoice: {} })
        }
        this.getDeductions();
      }
    })
  }

  clearFilter = () => {
    this.setState(
      {
        resetFilterBtn: false, pagination: { ...this.state.pagination, current: 1 },
        filters: { requestID: null, dateFrom: null, dateTo: null, companyID: this.props.info },
        showPaymentCycles: false,
        allPaymentCycles: [], rows: []
      });
    this.formFilterRef.current!.resetFields();
    this.formFilterRef.current!.setFieldsValue({
      companyID: null
    });
  };

  handlePagination = (val: any) => {
    this.setState({ loading: true, pagination: val }, () => {
      this.getDeductions();
    });
  }

  generateInvoice = async (isInvoice?: boolean, formValues?: any) => {
    isInvoice ? this.setState({ loadingInvoice: true }) : this.setState({ loadingPivot: true })
    if (this.state.filters.companyID && this.state.filters.companyID.length === 1 && this.state.filters.paymentCycle) {
      if (this.state.rows.length === 0) {
        message.error("No Data to be Printed", 3)
        this.setState({ loadingPivot: false, loadingInvoice: false })
        return 0
      }
      try {
        this.toggleGenerateInvoiceForm(false)
        const invoiceFormData = this.state.generateInvoiceForm
        const invoiceData = await this.props.request({
          url: generateInvoiceApi, method: "GET", params: {
            companyID: this.state.filters.companyID[0],
            paymentCycle: this.state.filters.paymentCycle,
            deductTaxRate: invoiceFormData.withTax ? invoiceFormData.taxRate : 0,
            generateNew: isInvoice ? 1 : 0
          }
        })
        console.log('invoiceData.status = ', invoiceData.data.status);

        if (invoiceData.data.status === 200) {
          const sheet: [] = Array.isArray(invoiceData.data.installmentPivot) && invoiceData.data.installmentPivot.map((data: any) => {
            return {
              Username: data.name,
              "Staff ID": data.staffID,
              "Phone Number": data.phone,
              Company: data.companyName,
              "National ID": data.nationalID,
              "Cut-off Date": helper.changeDateFormat(data.paymentCycleDate),
              "Total Due": data.totalDue,
              ...(formValues && formValues.withJobTitle && { "Job Title": data.jobTitle }),
              ...(formValues && formValues.withLocation && { Location: data.location })
            }
          })
          const invoice = {
            salariesPaid: invoiceData.data.installmentTotal.invoiceClaim ? Number(invoiceData.data.installmentTotal.invoiceClaim).toFixed(2) : 0,
            ...(invoiceFormData.withFees && { fees: invoiceData.data.installmentTotal.netRevenue ? Number(invoiceData.data.installmentTotal.netRevenue).toFixed(2) : 0 }),
            subject: `${moment(invoiceData.data.installmentTotal.cutoffCycleDate).format("MMMM DD, YYYY")} Claim`,
            com: invoiceData.data.installmentTotal.companyName,
            date: invoiceData.data.installmentTotal.cutoffCycleDate,
            serial: invoiceData.data.installmentTotal.serialNumber,
            settled: invoiceData.data.installmentTotal.settled ? Number(invoiceData.data.installmentTotal.settled).toFixed(2) : 0,
            taxRate: invoiceFormData.withTax ? invoiceFormData.taxRate : null,
            taxFees: (invoiceFormData.withTax && invoiceFormData.withFees) ? (Number(invoiceData.data.installmentTotal.taxAmount)).toFixed(2) : null,
            total: invoiceData.data.installmentTotal.total ? Number(invoiceData.data.installmentTotal.total).toFixed(2) : 0,
            discount: invoiceData.data.installmentTotal.discount,
            debitAndCredit: invoiceData.data.installmentTotal?.creditAndDebit,
            ...(invoiceFormData.withFees && {previousCreditRevenue: invoiceData.data.installmentTotal?.previousCreditRevenue}),
            fileName: `${moment(invoiceData.data.installmentTotal.cutoffCycleDate).format("MMMM, YYYY")} ${invoiceData.data.installmentTotal.companyName}_Invoice`

          }

          this.setState({ invoiceObj: invoice, loadingPivot: false, loadingInvoice: false })
          if (!isInvoice) {
            exportToCSV(sheet, `${moment(this.state.deductionDataFile).format("MMMM, YYYY")} ${this.state.companyNameFile}_Sum`)
          }
          return sheet
        } else {
          this.setState({ loadingPivot: false, loadingInvoice: false, loading: false, visibleErrorModal: true, errorMessage: invoiceData.data.message })
          return
        }
      }
      catch (e) {
        console.log("🚀 ~ file: deductions.tsx ~ line 238 ~ Deductions ~ generateInvoice= ~ e", e)
        message.error(errorMessage, 4)
        this.setState({ loadingPivot: false, loadingInvoice: false, loading: false })
        return
      }
    }
  }

  viewThePdfModal = (values: any) => {
    this.setState({
      generateInvoiceForm: {
        ...this.state.generateInvoiceForm,
        ...values
      }
    }, async () => {
      const open = await this.generateInvoice(true);
      this.setState({ visibleModal: open ? true : false })
    })
  }
  closeThePdfModal = () => {
    this.setState({ visibleModal: false })
  }

  closeErrorModal = () => {
    this.setState({ visibleErrorModal: false })
  }

  getPaymentCycles = async (companyID: number, data: any) => {

    if (!companyID || !data) {
      this.setState({ showPaymentCycles: false })
      return
    }
    const todayDate = new Date()
    const companyDate = new Date()
    companyDate.setDate(data.data.actualCutOffDate)
    if (companyDate <= todayDate) {
      companyDate.setMonth(companyDate.getMonth() + 1)
    }
    const currentCycle = {
      cutOffCycleDate: moment(companyDate).format("MMMM YYYY"),
      cutOffDay: data.data.actualCutOffDate,
    }
    this.setState({ showPaymentCycles: true, loadingPaymentCycles: true, allPaymentCycles: [] })
    const paymentCycles = await this.props.getApi(`${getPaymentCyclesApi}?companyID=${companyID}`, {}); //${value.requestId}
    const res = paymentCycles.payload;

    try {
      if (res.status === 200) {
        Object.assign(currentCycle, { paymentCycle: Array.isArray(res.allPaymentCycles) && res.allPaymentCycles.slice(-1)[0].paymentCycle + 1 })
        this.setState({ allPaymentCycles: [...res.allPaymentCycles, currentCycle].reverse() })
      } else {
        this.setState({ showPaymentCycles: false })
        message.error(res.message, 4);
      }
      this.setState({ loadingPaymentCycles: false })
    } catch (error) {
      this.setState({ showPaymentCycles: false, loadingPaymentCycles: false })
      message.error(errorMessage, 4);
    }

  }

  dropDownSearch = (input: string, option: { children: string; }) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getDeductionsSum = async () => {
    try {
      this.setState({ deductionsSumLoading: true })
      let filters: { [key: string]: any } = this.state.filters
      if (filters.paymentCycle) {
        delete filters.dateFrom
        delete filters.dateTo
      }
      const deductionsSum = await this.props.getApi(`${getTotalDeductionsSumApi}`, {
        params: filters
      });
      const data = await deductionsSum.payload;
      if (data.status === 200) {
        this.setState({ deductionsSum: data.installmentTotal.installmentSum });
      } else {
        this.setState({ deductionsSum: 0 });
        message.error(data.message, 5)
      }
      this.setState({ deductionsSumLoading: false })
    } catch (error) {
      console.log("🚀 ~ file: deductions.tsx ~ line 293 ~ Deductions ~ getDeductionsSum= ~ error", error)
      this.setState({ deductionsSum: 0, deductionsSumLoading: false, loading: false });
      message.error(errorMessage, 5)
    }
  }

  toggleGenerateInvoiceForm = (val: boolean) => {
    this.setState({
      generateInvoiceForm: {
        ...this.state.generateInvoiceForm,
        visible: val,
        withFees: true,
        withTax: false
      }
    }, () => {
      this.invoiceFormRef.current?.resetFields();
    })
  }

  toggleDownloadSheetsForm = (val: boolean, formValues?: any, isDeductions?: boolean) => {
    this.setState({
      sheetsModelVisible: val,
      isDeductions
    }, () => {
      if (formValues && !this.state.isDeductions) {
        this.generateInvoice(false, formValues)
      }
      else if (formValues && this.state.isDeductions) {
        this.generateDeductionsReport(formValues)
      }
      this.pivotFormRef.current?.resetFields();
    })
  }

  getLockedInvoice = async () => {
    try {
      const invoices = await this.props.request({
        url: getInvoicesApi, method: "get", params: {
          companyID: this.state.filters.companyID,
          paymentCycle: this.state.filters.paymentCycle
        }
      })
      if (invoices.data.status === 200) {
        const lockedInvoice: { [key: string]: any } = invoices.data.data.locked ? {
          ...invoices.data.data.lockedInvoice,
          lockedInvoice: true
        } : {}
        this.setState({ lockedInvoice })
      } else {
        this.setState({ lockedInvoice: {} })
        message.error(invoices.data.message, 4)
      }
    }
    catch (e: any) {
      this.setState({ lockedInvoice: {} })
      message.error(errorMessage, 4)
    }
  }

  downloadInvoice = (withFees: boolean) => {
    const lockedInvoice: any = { ...this.state.lockedInvoice }
    const date: { cutOffCycleDate: string } = this.state.allPaymentCycles.filter((paymentCycle: any) => paymentCycle.paymentCycle === lockedInvoice.paymentCycle)[0]
    const invoice = {
      salariesPaid: lockedInvoice.claim ? (Number(lockedInvoice.claim)).toFixed(2) : 0,
      ...(withFees && { fees: lockedInvoice.netRevenue ? Number(lockedInvoice.netRevenue).toFixed(2) : 0 }),
      subject: `${moment(lockedInvoice.cutoffCycleDate).format("MMMM DD, YYYY")} Claim`,
      com: lockedInvoice.companyName,
      date: date.cutOffCycleDate,
      serial: lockedInvoice.serialNumber,
      settled: lockedInvoice.settled ? Number(lockedInvoice.settled).toFixed(2) : 0,
      taxRate: lockedInvoice.taxRatePercent ? lockedInvoice.taxRatePercent : null,
      taxFees: (lockedInvoice.taxAmount && withFees) ? (Number(lockedInvoice.taxAmount)).toFixed(2) : null,
      debitAndCredit: lockedInvoice.debitAndCreditAmounts?.debitAndCredit,
      ...(withFees && {previousCreditRevenue: lockedInvoice?.previousCreditRevenue}),
      discount: lockedInvoice.discount,
      total: Number(lockedInvoice.total) ? Number(lockedInvoice.total).toFixed(2) : 0,
      fileName: `${moment(lockedInvoice.cutOffCycleDate).format("MMMM, YYYY")} ${lockedInvoice.companyName}_Invoice`

    }
    this.setState({ invoiceObj: invoice, visibleModal: true })
  }

  render() {
    const { loading, rows, pagination, resetFilterBtn, errorMode, errorMessage, visibleModal,
      downloadAllDeduction, invoiceObj, allPaymentCycles, showPaymentCycles, deductionsSum, deductionsSumLoading, visibleErrorModal,
      loadingInvoice, loadingPivot, filters, generateInvoiceForm, loadingPaymentCycles, sheetsModelVisible, isDeductions, isLockedCycle, lockedInvoice } = this.state;
    const { companies } = this.props;
    const dateFormat = "YYYY/MM/DD";
    const generateInvoiceModalContent = <>
      <Form
        ref={this.invoiceFormRef}
        name="basic"
        onFinish={this.viewThePdfModal}
        autoComplete="off"
        initialValues={generateInvoiceForm}
      >
        <Row className="statusRow">
          <Col span={24}>
            <label style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "15px" }}>Please select suitable options</label>
          </Col>
          <Col md={24}>
            <label htmlFor="">Show Service Fees </label>
            <Form.Item name="withFees">
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={generateInvoiceForm.withFees}
                onChange={(val) => {
                  this.setState({ generateInvoiceForm: { ...generateInvoiceForm, withTax: val ? generateInvoiceForm.withTax : val, withFees: val } }, () => {
                    this.invoiceFormRef.current?.resetFields()
                  })
                }} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <label htmlFor="">Show Tax on Service Fees </label>
            <Form.Item name="withTax">
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={generateInvoiceForm.withTax} disabled={!generateInvoiceForm.withFees}
                onChange={(val) => this.setState({ generateInvoiceForm: { ...generateInvoiceForm, withTax: val } })}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <label htmlFor="">Tax Rate on Service Fees {generateInvoiceForm.withTax && <span>*</span>}</label>
            <Form.Item name="taxRate" rules={[
              { required: generateInvoiceForm.withTax, message: "Tax rate is mandatory!" },
              { type: "number", min: 1, max: 100, message: "The rate must be between 1 and 100" },
            ]}>
              <InputNumber placeholder='Enter the rate' disabled={!generateInvoiceForm.withTax} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
    const generatePivotModalContent = <>
      <Form
        ref={this.pivotFormRef}
        name="basic"
        onFinish={(values) => this.toggleDownloadSheetsForm(false, values, isDeductions)}
        autoComplete="off"
        initialValues={{
          withJobTitle: false,
          withLocation: false
        }}
      >
        <Row className="statusRow">
          <Col span={24}>
            <label style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "15px" }}>Please select suitable options</label>
          </Col>
          <Col md={12}>
            <label htmlFor="">Add job title column </label>
            <Form.Item name="withJobTitle">
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <label htmlFor="">Add location column </label>
            <Form.Item name="withLocation">
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
    return (
      <>
        {errorMode ?
          <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> : <div className="deduction">
            <div className='deductionsFilter'>

              <Form
                ref={this.formFilterRef}
                name="basic"
                onFinish={this.handleSearch}
                autoComplete="off"
              >
                <Row className="statusRow">
                  {!showPaymentCycles && <Col md={8}>
                    <label htmlFor="">Date </label>
                    <Form.Item name="dateRange" >
                      <RangePicker format={dateFormat} />
                    </Form.Item>
                  </Col>}
                  {showPaymentCycles && <Col span={8}>
                    <label htmlFor="">Invoices months</label>
                    <Form.Item name="paymentCycle">
                      <Select
                        placeholder='Please select an invoice month'
                        loading={loadingPaymentCycles}
                      >
                        {
                          Array.isArray(allPaymentCycles) &&
                          allPaymentCycles.map((paymentCycle: { paymentCycle: number, cutOffCycleDate: string, cutOffDay: string, isLocked: boolean }, index: any) =>
                            <Option key={index} value={paymentCycle.paymentCycle}>
                              <Row>
                                <Col span={16} style={{ padding: 0 }}>
                                  <span style={{ color: 'red' }}>{paymentCycle.cutOffDay ? paymentCycle.cutOffDay : moment(paymentCycle.cutOffCycleDate).format('DD')}</span>
                                  &nbsp;{moment(paymentCycle.cutOffCycleDate).format('MMMM YYYY')}
                                </Col>
                                <Col span={8} style={{ padding: 0 }}>
                                  <Badge count={paymentCycle.isLocked ? "Locked invoice" : 0} />
                                </Col>
                              </Row>
                            </Option>)
                        }
                      </Select>
                    </Form.Item>
                  </Col>}
                  <Col md={8}>
                    <label htmlFor="">Company</label>
                    <Form.Item name="companyID">
                      <Select style={{ width: '100%' }}
                        onChange={this.getPaymentCycles}
                        placeholder={'Please select the company'}
                        showSearch
                        allowClear
                        defaultValue={this.props.info.length === 1 ? this.props.info[0] : undefined}
                        filterOption={((input, option: any) => this.dropDownSearch(input, option))}
                      >
                        {Array.isArray(companies) && companies.map((company: any, key: number) => {
                          return <Option value={company.id} data={company} key={key}>{company.name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <label htmlFor="">Request ID </label>
                    <Form.Item name="advanceID">
                      <Input placeholder="Search by request id" />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <label htmlFor="">Staff ID</label>
                    <Form.Item name="staffID">
                      <Input placeholder="Search by staff ID" />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <label htmlFor="">User ID</label>
                    <Form.Item name="userID">
                      <Input placeholder="Search by userID" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="btnRows" >
                  <Col >
                    <Form.Item>
                      <Spin spinning={downloadAllDeduction || loading}>
                        <Button type="default" onClick={() => this.toggleDownloadSheetsForm(true, false, true)}
                          style={{ color: "#413F42", borderColor: "#413F42", margin: '0 19px' }} disabled={!rows.length}>
                          Deductions Sheet
                        </Button>
                      </Spin>

                    </Form.Item>
                  </Col>

                  {this.state.filters.companyID && this.state.filters.companyID.length === 1 && this.state.filters.paymentCycle
                    && !this.state.filters.requestID && !this.state.filters.staffID && !this.state.filters.userID && <>
                      <Col >
                        <Form.Item>
                          <Spin spinning={loadingPivot || loading}>
                            <Button type="default" onClick={() => this.toggleDownloadSheetsForm(true)}
                              style={{ color: "#EC9B3B", borderColor: "#EC9B3B", margin: '0 19px' }} disabled={!rows.length}>
                              Invoice Sheet
                            </Button>
                          </Spin>
                        </Form.Item>
                      </Col>

                      {this.props.generateInvoice && !isLockedCycle && <Col >
                        <Form.Item>
                          <Spin spinning={loadingInvoice || loading}>
                            <Button type="default" onClick={() => this.toggleGenerateInvoiceForm(true)}
                              style={{ color: "blue", borderColor: "blue", margin: '0 19px' }} disabled={!rows.length}>
                              Generate PDF
                            </Button>
                          </Spin>
                        </Form.Item>
                      </Col>}
                      {this.props.generateInvoice && isLockedCycle && <Col >
                        <Form.Item>
                          <Spin spinning={loadingInvoice || loading}>
                            <Dropdown
                              overlay={
                                <Menu>
                                  {(!loadingInvoice && !loading) ?
                                    <>
                                      <Menu.Item
                                        icon={<DownloadOutlined />}
                                        onClick={() => this.downloadInvoice(true)}
                                      >
                                        With Fees
                                      </Menu.Item>
                                      <Menu.Item
                                        icon={<DownloadOutlined />}
                                        onClick={() => this.downloadInvoice(false)}
                                        disabled={lockedInvoice.taxRatePercent ? true : false}
                                      >
                                        Without Fees
                                      </Menu.Item>
                                    </>
                                    : ""}
                                </Menu>
                              }>
                              <Button type="default" style={{ color: "blue", borderColor: "blue", margin: '0 19px' }} disabled={!rows.length}>
                                Download PDF
                              </Button>
                            </Dropdown>
                          </Spin>
                        </Form.Item>
                      </Col>}
                    </>}

                  <Col >
                    <Form.Item>
                      <Button type="default" htmlType="submit"
                        style={{ color: "#357C3C", borderColor: "#357C3C", margin: '0 19px' }} disabled={loading}>
                        Filter
                      </Button>
                    </Form.Item>
                  </Col>
                  {resetFilterBtn && <Col>
                    <Form.Item>
                      <Button danger onClick={this.clearFilter}>
                        Clear filter
                      </Button>
                    </Form.Item>
                  </Col>}

                </Row>
              </Form>

            </div>
            {loading && rows.length === 0 ?
              <Spinner size={"large"} />
              : rows.length > 0 ?
                <div className='deductionsTable'>
                  <DataTable
                    genericColumnsNames={columnsOfDeductionsTable}
                    rows={rows}
                    handleTableChange={this.handlePagination}
                    pagination={pagination}
                    loading={loading}
                    scrolling={{ x: 100, y: 300 }}
                  />
                  <span className='totalDeductions'>Deduction Summation: {<Spin spinning={deductionsSumLoading}>EGP {deductionsSum} </Spin>}</span>
                </div>
                : <Result
                  icon={<SmileOutlined />}
                  title={((filters.dateFrom && filters.dateTo) || filters.paymentCycle) ? "There are no deductions for the selected filters!" : "Hello, There are no deductions, Please select your filters to get the data!"}
                />
            }
            <ModalComponent none={'none'} visibleModal={visibleModal} handleAcceptModal={this.closeThePdfModal} handleCloseModal={this.closeThePdfModal}
              content={<PdfComponent fileName={invoiceObj?.fileName} content={<InvoiceTemp data={invoiceObj} />} />} />
            <ModalComponent okText='Generate Invoice' visibleModal={generateInvoiceForm.visible} handleAcceptModal={() => this.invoiceFormRef.current?.submit()} handleCloseModal={() => this.toggleGenerateInvoiceForm(false)}
              content={generateInvoiceModalContent} />
            <ModalComponent okText='Download Sheet' visibleModal={sheetsModelVisible} handleAcceptModal={() => this.pivotFormRef.current?.submit()} handleCloseModal={() => this.toggleDownloadSheetsForm(false)}
              content={generatePivotModalContent} />
            <ErrorModal handleCloseModal={this.closeErrorModal} visibleModal={visibleErrorModal} errorMessage={checkSumInvoicesError} warn />
          </div>
        }


      </>
    )
  }
}
const mapStateToProps = (state: any) => ({
  info: state.actionsReducer.info,
  companies: state.general.companies,
})
export default connect(mapStateToProps, { getApi, request })(Deductions)
