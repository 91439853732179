import { Form, Row, Col, Input, Select, Button, ConfigProvider, Descriptions, message, Radio } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { request } from '../../../apis/axiosRequest';
import { getAllDrivers, createTransferApi } from '../../../utils/apisConsts';
import { arabicErrorMessage, arabicSuccessTransferMessage } from '../../../utils/commonStringsConsts';
import Spinner from '../../../components/spinner';
import OutPutResult from '../../../components/result';
import helper from '../../../utils/helper';
const { Option } = Select;

export default function CreateTransfers() {

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [sender, setSender]: any = useState({});
    const [senderBankID, setSenderBankID]: any = useState({});
    const [receiver, setReceiver]: any = useState({});
    const [receiverBankID, setReceiverBankID]: any = useState({});
    const [loader, setLoader] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [transferLoader, setTransferLoader] = useState(false);
    const formRef: any = useRef([]);
    const getAllUsers = async () => {
        const params = { role: 'Driver' };
        setLoader(true)
        try {
            const apiData: any = await dispatch(request({ url: getAllDrivers, params: params, method: 'GET' }));
            const res = apiData.data;
            if (res.status === 200) {
                setUsers(res.data.users);
                setLoader(false);
                return
            }
            setLoader(false);
            setErrorMode(true);
        } catch (error) {
            setLoader(false);
            setErrorMode(true);
        }
    }

    const onUsersChange = (id: number, user: any) => {
        if (user.isSender) {
            setSender(user.sender);
            formRef.current!.setFieldsValue({ bankSender: null });
            setSenderBankID(null)
            return
        }
        setReceiver(user.receiver);
        formRef.current!.setFieldsValue({ bankReceiver: null });
        setReceiverBankID(null)
    }
    useEffect(() => {
        getAllUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeBanksToSenders = (evt: any) => {
        setSenderBankID(evt.target.value)
    }
    const changeBanksToReceivers = (evt: any) => {
        setReceiverBankID(evt.target.value)
    }
    const transferAmount = async (values: any) => {
        if (senderBankID === receiverBankID) {
            message.error(`لا يمكن ارسال المبلغ لنفس الحساب `);
            return
        }
        try {
            setTransferLoader(true);
            const data = {
                from: senderBankID,
                to: receiverBankID,
                amount: Number(values?.amount)
            }
            const apiData: any = await dispatch(request({ url: createTransferApi, method: 'POST', data: data }));
            const resp = apiData.data;
            if (resp.status === 200) {
                message.success(arabicSuccessTransferMessage);
                setTransferLoader(false);
                formRef.current!.resetFields();
                setSender({})
                setSenderBankID({})
                setReceiver({})
                setReceiverBankID({})
                return
            }
            message.error(resp.message);
            setTransferLoader(false);

        } catch (error) {
            message.error(arabicErrorMessage);
            setTransferLoader(false);
        }
    }
    const dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return (
        <>
            <ConfigProvider direction="rtl">

                {loader ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={arabicErrorMessage} subTitle={' '}
                    btnTitle={'إعادة تحميل الصفحه'} btnClick={helper.refreshPage} /> : <div className='createTransfers'>
                    <Form name="nest-messages" ref={formRef} onFinish={transferAmount}>

                        <Row>
                            <Col span='18' >
                                <label>اسم المرسل</label>

                                <Form.Item
                                    name="sender"
                                    rules={[{ required: true, message: 'اجباري' }]}>
                                    <Select
                                        onChange={onUsersChange}
                                        showSearch
                                        filterOption={((input, option: any) => dropDownSearch(input, option))}
                                        placeholder="اختار اسم المرسل">
                                        {Array.isArray(users) && users.map((user: any, key: number) => {
                                            return <Option value={user.id} sender={user} isSender={true} key={key}>{user.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Descriptions bordered style={{ margin: '18px' }} title="معلومات عن المرسل">
                                <Descriptions.Item label="الاسم" span={2}>{sender?.name ? sender?.name : 'لا يوجد'}</Descriptions.Item>
                                <Descriptions.Item label='رقم الموبايل' span={2}>{sender?.phone ? sender?.phone : 'لا يوجد'}</Descriptions.Item>
                                <Descriptions.Item label='كود المتسخدم ' span={2}>{sender?.staffID ? sender?.staffID : 'لا يوجد'}</Descriptions.Item>
                                <Descriptions.Item label="رقم القومي" span={2}>{sender?.nationalID ? sender?.nationalID : 'لا يوجد'}</Descriptions.Item>
                            </Descriptions>


                        </Row>
                        {Array.isArray(sender?.userBanks) && sender?.userBanks.length > 0 && <div className="site-card-border-less-wrapper" style={{ background: '#fbfbfbfa' }}>
                            <h2>  طريقة الدفع </h2>
                            <div className="expenseForm" >
                                <Form.Item name="bankSender" rules={[{ required: true, message: 'يجب إختيار طريقه للارسال' }]}>
                                    <Radio.Group onChange={changeBanksToSenders}>
                                        {Array.isArray(sender?.userBanks) && sender.userBanks.map((radio: any, key: any) => {
                                            return <Radio value={radio.id} key={key} style={{ color: '#000' }}>
                                                <span className='cartType' >{radio.bankId.nameAr}</span><br />
                                                <span className='cartNum'>{radio.cardNumber}</span>
                                            </Radio>
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>}
                        <hr style={{ border: '1px solid #ddd' }} />
                        <Row>
                            <Col span='18' >
                                <label>اسم المستلم </label>
                                <Form.Item
                                    name="receiver"
                                    rules={[{ required: true, message: 'اجباري' }]}

                                >
                                    <Select
                                        onChange={onUsersChange}
                                        filterOption={((input, option: any) => dropDownSearch(input, option))}
                                        showSearch
                                        placeholder="اختار اسم المستلم">
                                        {Array.isArray(users) && users.map((user: any, key: number) => {
                                            return <Option value={user.id} receiver={user} key={key}>{user.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Descriptions bordered style={{ margin: '18px' }} title="معلومات عن المستلم">
                                <Descriptions.Item label="الاسم" span={2}>{receiver?.name ? receiver?.name : 'لا يوجد'}</Descriptions.Item>
                                <Descriptions.Item label='رقم الموبايل' span={2}>{receiver?.phone ? receiver?.phone : 'لا يوجد'}</Descriptions.Item>
                                <Descriptions.Item label='كود المتسخدم ' span={2}>{receiver?.staffID ? receiver?.staffID : 'لا يوجد'}</Descriptions.Item>
                                <Descriptions.Item label="رقم القومي" span={2}>{receiver?.nationalID ? receiver?.nationalID : 'لا يوجد'}</Descriptions.Item>
                            </Descriptions>
                        </Row>

                        {Array.isArray(receiver?.userBanks) && receiver?.userBanks.length > 0 && <div className="site-card-border-less-wrapper" style={{ background: '#fbfbfbfa' }}>
                            <h2>  طريقة الصرف </h2>
                            <div className="expenseForm" >
                                <Form.Item name="bankReceiver" rules={[{ required: true, message: ' يجب إختيار طريقه للاستلام' }]}>
                                    <Radio.Group onChange={changeBanksToReceivers}>
                                        {Array.isArray(receiver?.userBanks) && receiver.userBanks.map((radio: any, key: any) => {
                                            return <Radio value={radio.id} key={key} style={{ color: '#000' }}>
                                                <span className='cartType' >{radio.bankId.nameAr}</span><br />
                                                <span className='cartNum'>{radio.cardNumber}</span>
                                            </Radio>
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>}

                        <Row style={{ margin: '15px 0 80px 0' }}>
                            <Col span='12' >
                                <label>المبلغ </label>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: 'اجباري' }]}>
                                    <Input placeholder='برجاء ادخال المبلغ المراد تحويله' />
                                </Form.Item>
                            </Col>

                            <Col span={12} ><Form.Item>
                                <Button className='transferBTN' type="primary" htmlType="submit" loading={transferLoader} >
                                    تحويل
                                </Button>
                            </Form.Item></Col>
                        </Row>

                    </Form>
                </div>}

            </ConfigProvider>
        </>
    )
}
