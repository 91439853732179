import { Descriptions } from 'antd'
import moment from 'moment';
import React, { PureComponent } from 'react'
interface Props {
    info: {
        name: string, staff_id: number, companyName: string, userID: number, phone: string, jobTitle?: string, email: string, userStatusType: string,
        nationalIDNumber?: string, hiringDate?: string, hasMeezaCard?: number, department?: any
    },
}
class BasicUserInfo extends PureComponent<Props> {
    render() {
        const { info } = this.props;

        return (
            <div style={{ margin: '20px 0' }}>
                {info && <Descriptions bordered>
                    {<Descriptions.Item span={2} label="User ID">{info.userID ? info.userID : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item span={2} label="Name">{info.name ? info.name : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item label="Staff ID" span={2}>
                        {info.staff_id ? info.staff_id : 'NA'}
                    </Descriptions.Item>}
                    {<Descriptions.Item span={2} label="Company">{info.companyName ? info.companyName : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item span={2} label="Phone">{info.phone ? info.phone : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item label="Email" span={2}>
                        {info.email ? info.email : 'NA'}
                    </Descriptions.Item>}
                    {<Descriptions.Item label="Status" span={2}>
                        {info.userStatusType ? info.userStatusType : 'NA'}
                    </Descriptions.Item>}
                    <Descriptions.Item label="Hiring Date" span={2}>{info.hiringDate ? moment(info.hiringDate).format("Do MMM, YYYY") : 'NA'}</Descriptions.Item>
                    <Descriptions.Item label="Job Title" span={2}>{info?.jobTitle ? info?.jobTitle : 'NA'}</Descriptions.Item>
                    {<Descriptions.Item label="National ID" span={3}>
                        {info.nationalIDNumber ? info.nationalIDNumber : 'NA'}
                    </Descriptions.Item>}
                    <Descriptions.Item label="Department" span={2}>{info?.department?.nameEn ? info?.department?.nameEn : 'NA'}</Descriptions.Item>
                    <Descriptions.Item label="Has Meeza Card">{info?.hasMeezaCard ? (info?.hasMeezaCard === 2 ? "Received" : info?.hasMeezaCard === 1 ? "Requested" : "None") : 'NA'}</Descriptions.Item>

                </Descriptions>}
            </div>
        )
    }
}
export default BasicUserInfo
