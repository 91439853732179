import { Button, Col, Divider, Form, FormInstance, Input, message, Row, Select } from "antd"
import { createRef, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { request, stepperMoving } from "../../stores/actions"
import helper from "../../utils/helper"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createCompanyApi, updateCompanyInfoAdvanceAPI, updateCompanyInfoAPI } from "../../utils/apisConsts"
import { errorMessage } from "../../utils/commonStringsConsts"
import moment from "moment"
const { Option } = Select;

interface Props {
    setCompanyData: any,
    companyData: any,
    teams: any[],
    editAllInfo: boolean,
    companyOldInfo: any,
    create?: boolean
}

export default function EditCompanyForm3(props: Props) {
    const dispatch = useDispatch()
    const validation = helper.regexValidation
    const formRef = createRef<FormInstance>();
    let prevFormRef: any = createRef<FormInstance>();
    const [sendingData, setSendingData] = useState(false)
    const [clearData, setClearData] = useState(false)

    useEffect(() => {

        if (formRef) {
            prevFormRef.current = formRef.current
        }
        return () => {
            props.setCompanyData(!clearData ? {
                ...props.companyData,
                ...prevFormRef.current?.getFieldsValue(["SPOCs"])
            } : {})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef.current, clearData])

    const onFinish = async (values: any) => {
        try {
            let companyNewInfo = {
                ...props.companyData,
                ...values,
                establishedAt: moment(props.companyData.establishedAt).format("YYYY/MM/DD 12:00:00"),
                communicationCycleDays: props.companyData.communicationCycles[0],
                spocs: values.SPOCs,
                departmentIDs: props.companyData.departments
            }
            delete companyNewInfo.communicationCycles
            delete companyNewInfo.SPOCs
            delete companyNewInfo.departments

            if (props.create) {
                setClearData(true)
                companyNewInfo["communicationCycle"] = props.companyData.communicationCycles
                companyNewInfo["staffIdProvided"] = props.companyData.staffIDRequiredByCompany
                companyNewInfo["nationalIdProvided"] = props.companyData.nationalIDRequiredByCompany
                delete companyNewInfo.communicationCycleDays
                delete companyNewInfo.staffIDRequiredByCompany
                delete companyNewInfo.nationalIDRequiredByCompany
                const formData = new FormData();
                formData.append("logoID", companyNewInfo.logo[0].originFileObj);
                formData.append("establishedAt", companyNewInfo.establishedAt);
                delete companyNewInfo.logo
                delete companyNewInfo.establishedAt
                Object.keys(companyNewInfo).map((key, _index) => {
                    //append all data to Form data [used when we uploads files with data]
                    formData.append(key, JSON.stringify(companyNewInfo[key]))
                    return 0
                });
                companyNewInfo = formData
            }
            else {

                const companyOldInfo = {
                    ...props.companyOldInfo,
                    establishedAt: moment(props.companyOldInfo.establishedAt).format("YYYY/MM/DD 12:00:00"),
                    communicationCycleDays: props.companyOldInfo.communicationCycles[0],
                    spocs: props.companyOldInfo.SPOCs,
                    departmentIDs: props.companyOldInfo.departments
                }

                delete companyOldInfo.communicationCycles
                delete companyOldInfo.SPOCs
                delete companyOldInfo.departments

                // eslint-disable-next-line
                const newDataKeys = Object.keys(companyNewInfo).filter((key) => companyNewInfo[key] != companyOldInfo[key])
                Object.keys(companyNewInfo).map((key) => {
                    if (!newDataKeys.includes(key)) {
                        delete companyNewInfo[key]
                    }
                    return key
                })
                companyNewInfo["companyID"] = companyOldInfo.id

            }

            setSendingData(true)
            const res: any = await dispatch(request({ url: props.create ? createCompanyApi : props.editAllInfo ? updateCompanyInfoAdvanceAPI : updateCompanyInfoAPI, method: props.create ? "POST" : "PATCH", data: companyNewInfo }))
            if (res.data.status === 200) {
                message.success(res.data.message, 4)
                dispatch(stepperMoving(0))
            }
            else {
                message.error(res.data.message, 4)
            }
            setSendingData(false)
        }
        catch (e: any) {
            setSendingData(false)
            message.error(errorMessage, 4)
        }
    }
    const onFinishFailed = (error: any) => {
        console.log("🚀 ~ file: editCompanyForm1.tsx ~ line 40 ~ onFinishFailed ~ error", error)
    }

    return (
        <div className="createNewCompany">
            <Form
                className='SPOCs'
                name="basic"
                ref={formRef}
                initialValues={{ SPOCs: props.companyData.SPOCs?.length > 0 ? props.companyData.SPOCs : [{}] }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"

            >
                <Form.List name="SPOCs">

                    {(fields, { add, remove }) => (<>
                        {fields.map((field, i) => (
                            <>
                                <Row style={{ marginTop: i === 0 ? 0 : "50px" }}>
                                    <Col span={23}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                                prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                            }
                                        >
                                            {() => (
                                                <>
                                                    <label htmlFor="">Team <span>*</span></label>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'teamID']}
                                                        rules={[{ required: true, message: 'Required' }]}
                                                    >
                                                        <Select
                                                            placeholder="Select the team"
                                                            showSearch
                                                            allowClear
                                                        >
                                                            {Array.isArray(props.teams) && props.teams.map((i: any) => {
                                                                return <Option value={i.id}>{i.name}</Option>
                                                            })}
                                                        </Select>

                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '27px', padding: '0 0px' }} />
                                    </Col>


                                    <Col span={12}>
                                        <label htmlFor="">Title <span>*</span></label>
                                        <Form.Item  {...field}
                                            name={[field.name, 'title']}
                                            rules={[{ required: true, message: 'Required' }]}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the title' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label htmlFor="">Name <span>*</span></label>
                                        <Form.Item {...field}
                                            name={[field.name, 'name']}
                                            rules={[{ required: true, message: 'Required' }]}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the name' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label htmlFor="">Email <span>*</span></label>
                                        <Form.Item {...field}
                                            name={[field.name, 'email']}
                                            rules={[{ required: true, message: 'Required' }, { pattern: validation.email, message: 'Email is not valid' }]}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the email' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label htmlFor="">Phone <span>*</span></label>
                                        <Form.Item {...field}
                                            name={[field.name, 'phone']}
                                            rules={[{ required: true, message: 'Required' }, { pattern: validation.egyptPhoneNum, message: 'Phone is not valid' }]}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the phone' />
                                        </Form.Item>
                                    </Col>
                                    <Divider orientation="left"></Divider>
                                    <Col span={12}>
                                        <label htmlFor="">Secondary Title</label>
                                        <Form.Item {...field}
                                            name={[field.name, 'titleSecondary']}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the secondary title' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label htmlFor="">Secondary Name</label>
                                        <Form.Item {...field}
                                            name={[field.name, 'nameSecondary']}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the Secondary Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label htmlFor="">Secondary Email</label>
                                        <Form.Item {...field}
                                            name={[field.name, 'emailSecondary']}
                                            rules={[{ pattern: validation.email, message: 'Email is not valid' }]}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the Secondary Email' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label htmlFor="">Secondary phone</label>
                                        <Form.Item {...field}
                                            name={[field.name, 'phoneSecondary']}
                                            rules={[{ pattern: validation.egyptPhoneNum, message: 'Phone is not valid' }]}
                                        >
                                            <Input style={{ width: '90%' }} placeholder='Enter the Secondary phone' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ))}

                        {fields.length <= 28 && <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add
                            </Button>
                        </Form.Item>}
                    </>
                    )}
                </Form.List>
                <Row>
                    <Col span={24}>
                        <Form.Item wrapperCol={{ offset: 0, span: 0 }} style={{ margin: '10px 0' }}>
                            <Button type="primary" htmlType="submit" loading={sendingData}>
                                {props.create ? "Create" : "Submit"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}