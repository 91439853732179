import { Descriptions, Row } from 'antd';
import React from 'react';

interface Props{
    columns: {
        label: string,
        description: string,
        isRequired: boolean,
        link?: any
    }[]
}

export default function SheetTemplate(props: Props) {

    return (
        <Row className="sheetTemplate">
            <Descriptions bordered style={{ margin: '15px 0' }}>
                {
                    Array.isArray(props.columns) && props.columns.map((column)=><Descriptions.Item label={column.label} span={3}>{column.isRequired && <span>( Mandatory )</span>} {column.description} {column.link && <a href={column.link}>{column.link}</a>}</Descriptions.Item>)
                }
            </Descriptions>
        </Row>
    )
}