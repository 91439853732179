import React, { useRef, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Input, Row, Col, message } from 'antd';
import Helper from '../../../utils/helper';
// import { request } from '../../../stores/actions';
import { errorMessage, firstOption } from '../../../utils/commonStringsConsts';
import helper from '../../../utils/helper';
import { approveBankTransactionsApi } from '../../../utils/apisConsts';


export const SendEmails = () => {
    // const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const formRef: any = useRef([]);

    const sendEmailsNotifications = async (values: { emails: [] }) => {

        try {
            setLoader(true);
            const data = {
                emails: values.emails.map((obj: { email: string }) => obj.email),
            }
            const usersEmail: any = await helper.callApiRequest(approveBankTransactionsApi, 'POST', '', data);
            const resp = usersEmail.data
            if (resp.status === 200) {
                message.success(resp.message, 5);
                setLoader(false);
                return
            }
            message.error(resp.message, 5);
            setLoader(false);


        } catch (error) {
            message.error(errorMessage, 5);
            setLoader(false);
        }
    }
    return (
        <>
            <div className='sendEmails'>
                <Form
                    ref={formRef}
                    initialValues={{ emails: firstOption }}
                    onFinish={sendEmailsNotifications}
                >

                    {/* <Form.Item name="emails"
                        style={{ margin: '20px 0', padding: '0 20px' }}
                        rules={[{ required: true, message: 'Required' },
                        ]}
                    >
                        <label htmlFor="">البريد الالكنروني</label>

                        <Select
                            // onChange={onCategoriesChange}
                            size='large'
                            mode="tags" dropdownStyle={{ display: 'none' }} placeholder='ادخل البريد الالكتروني'
                            tokenSeparators={[',']}></Select>
                    </Form.Item> */}

                    <Form.List name="emails">

                        {(fields, { add, remove }) => (
                            <>

                                {fields.map(field => (
                                    <>
                                        <Row key={field.key}>
                                            <Col span={23}>
                                                <Form.Item

                                                    shouldUpdate={(prevValues, curValues) =>
                                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                    }>
                                                    {() => (
                                                        <>
                                                            <label htmlFor=""> البريد الالكنروني <span>*</span></label>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'email']}
                                                                style={{ margin: '5px 0 0 0' }}
                                                                rules={[{ required: true, message: 'اجباري' },
                                                                { pattern: Helper.regexValidation.email, message: 'البريد الالكتروني يجب ان يكون صحيح' }]}>
                                                                <Input size='large' id={field.key === 0 ? 'error' : ''} placeholder='ادخل البريد الالكتروني' />

                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.Item>
                                            </Col>

                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '40px', marginRight: '10px' }} />
                                            </Col>
                                        </Row>
                                    </>
                                ))}



                                {fields.length <= 10 && <Form.Item style={{ padding: '0 15px' }}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ margin: '20px 0', padding: '5px 0px' }}>
                                        اضافه بريد الكتروني جديد
                                    </Button>
                                </Form.Item>}
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loader}
                            style={{ margin: '0 15px', width: '150px', height: '40px', backgroundColor: '#6fb76c', border: '1px solid #6fb76c' }}>
                            ارسال
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    )
}
