import { Form, Row, Col, Input, Button, FormInstance, message, DatePicker, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getApi } from '../../apis/getRequests';
import { patchApi } from '../../apis/patchRequest';
import { stepperMoving } from '../../stores/actions';
import helper from '../../utils/helper';
import UserInfoUI from '../../components/uiInfo';

const { Option } = Select
interface Props {
    getApi?: any,
    info?: {
        userID: any;
        firstName: any;
        middleName: any;
        lastName: any;
        bankName: any;
        bankAccountHolderName: any;
        bankAccountNumber: any;
        netSalary: any;
        name: string,
        companyName: string,
        phone: string,
        email: string,

        nationalId: any,
        accountCreatedAt: string,


        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        hiringDate: string,
        jobTitle: string,
        companyID: number,
        department: any
        hasMeezaCard: string | number
    }
    patchApi?: any,
    stepperMoving?: any,
}
class EditApprovedUsers extends Component<Props> {
    state = {
        loadData: false,
        departments: [],
        loadingDepartments: false
    }
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.getCompanyDepartments()
    }

    updateUser = async (values: any) => {
        console.log("🚀 ~ file: userInfoForm.tsx ~ line 87 ~ EditUserInfo ~ updateUser= ~ values", values)
        this.setState({ loadData: true });
        const data: any = {
            userID: this.props.info?.userID,
            userInfo: {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                email: values.email,
                nationalIdNumber: values.nationalId ? values.nationalId : null,
                phone: values.phone,
                staff_id: values.staff_id,
                hiringDate: values.hiringDate ? moment(values.hiringDate).format("YYYY/MM/DD 12:00:00") : null,
                jobTitle: values.jobTitle,
                departmentID: values.departmentID,
                hasMeezaCard: values.hasMeezaCard === "2" ? true : false
            }

        };

        const updateUser = await this.props.patchApi("/users/updateApprovedUserInfo", data);
        const res = await updateUser.payload;
        try {
            if (res.status === 200) {
                this.setState({ loadData: false });
                message.success(res.message, 4);
                helper.resetAccountTableInStepper();
                this.props.stepperMoving(0);
            } else {
                this.setState({ loadData: false });
                message.error(res.message, 4);
            }
        } catch (error) {
            this.setState({ loadData: false });
            message.error("Something went wrong, please try again", 4);
        }

    };

    getCompanyDepartments = async () => {
        try {
            this.setState({ loadingDepartments: true })
            const departs: any = await helper.getDepartments({ companyID: this.props.info?.companyID })
            if (departs.data.status === 200) {
                this.setState({ loadingDepartments: false, departments: departs.data.departments })
            }
            else {
                this.setState({ loadingDepartments: false, errorMode: true })
            }
        }
        catch (error) {
            console.log("🚀 ~ file: userInfoForm.tsx:233 ~ EditUserInfo ~ getCompanyDepartments= ~ error:", error)
            this.setState({ loadingDepartments: false, errorMode: true })
        }
    }

    render() {
        const { loadData, loadingDepartments, departments } = this.state;
        const { info } = this.props;
        const validation: any = helper.regexValidation;
        const basicInfo = [
            {
                key: 'Name',
                value: <Row>
                    <Col span="8">
                        <label>First Name </label>

                        <Form.Item
                            name="firstName"
                            rules={[
                                { required: true, message: "First name is mandatory!" },
                            ]}
                            initialValue={info?.firstName}>
                            <Input placeholder="Enter the first name" style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                    <Col span="8">
                        <label>Middle Name </label>
                        <Form.Item
                            name={"middleName"}
                            rules={[
                                {
                                    required: true,
                                    message: "Group name is mandatory",
                                },
                            ]}
                            initialValue={info?.middleName}
                        >
                            <Input placeholder="Enter the middle name" style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                    <Col span="8">
                        <label>Last Name </label>

                        <Form.Item
                            name="lastName"
                            rules={[
                                { required: true, message: "lastName is mandatory!" },
                            ]}
                            initialValue={info?.lastName}
                        >
                            <Input placeholder="Enter the last name" style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            },
            {
                key: 'Company',
                value: <Form.Item
                    name={'s'}
                    initialValue={info?.companyName ? info?.companyName : 'NA'}
                >
                    <Input readOnly style={{ width: '90%' }} />
                </Form.Item>
            },
            {
                key: 'Staff ID',
                value: <Form.Item
                    name="staff_id"
                    rules={[
                        { required: true, message: "Staff Id is mandatory!" },
                    ]}
                    initialValue={info?.staff_id}
                >
                    <Input placeholder="Enter the Staff Id" style={{ width: '90%' }} />
                </Form.Item>
            },
            {
                key: 'Job Title',
                value: <Form.Item
                    name="jobTitle"
                    initialValue={info?.jobTitle}
                >
                    <Input placeholder="Enter the job title" style={{ width: '90%' }} />
                </Form.Item>
            },
            {
                key: 'Department',
                value: <Form.Item
                    name="departmentID"
                    initialValue={this.props.info?.department?.id}
                >
                    <Select style={{ width: '90%' }}
                        loading={loadingDepartments}
                        placeholder="Select a department name">
                        {Array.isArray(departments) && departments.map((department: any, key: number) => {
                            return <Option value={department.id} key={key}>{department.nameEn}</Option>
                        })}
                    </Select>
                </Form.Item>
            },
            {
                key: 'Hiring Date',
                value: <Form.Item
                    name="hiringDate"
                    initialValue={info?.hiringDate ? moment(info?.hiringDate) : undefined}>
                    <DatePicker placeholder="Enter the hiring date" style={{ width: '90%' }} />
                </Form.Item>
            },
        ]
        const personalInfo = [
            {
                key: 'Phone',
                value: <Form.Item
                    name={'phone'}
                    rules={[
                        { required: true, message: "Phone is mandatory" },
                    ]}
                    initialValue={info?.phone ? info?.phone : 'NA'}
                >
                    <Input placeholder='Enter your phone ' style={{ width: '90%' }} />
                </Form.Item>
            },
            {
                key: 'Email',
                value: <Form.Item
                    name="email"
                    rules={[
                        {
                            pattern: validation.email,
                            message: "Email is not valid",
                        },
                    ]}
                    initialValue={info?.email}
                >
                    <Input placeholder="Enter the email" style={{ width: '90%' }} />
                </Form.Item>
            },
            {
                key: 'National ID',
                value: <Form.Item
                    name="nationalId"
                    rules={[
                        {
                            pattern: validation.nationalId,
                            message: "National id is not valid",
                        },
                    ]}
                    /** info?.nationalIDNumber.includes('null') ? 'NA' : info?.nationalIDNumber */
                    initialValue={info?.nationalIDNumber && info?.nationalIDNumber.includes('null') ? '' : info?.nationalIDNumber}
                >
                    <Input placeholder="Enter the National Id" style={{ width: '90%' }} />
                </Form.Item>
            }
        ]
        const bankInfo = [
            {
                key: 'Meeza Card Status',
                value: <Form.Item
                    name="hasMeezaCard"
                    // rules={[{ required: true, message: 'Required' }]}
                    initialValue={(info?.hasMeezaCard)?.toString()}
                >
                    <Select placeholder="Select a status">
                        <Option value="1" disabled>Requested</Option>
                        <Option value="0">Not Received</Option>
                        <Option value="2">Received</Option>
                    </Select>
                </Form.Item>
            },
        ]
        return (
            <>
                <Form
                    name="nest-messages"
                    ref={this.formRef}
                    onFinish={this.updateUser}
                >
                    {info && (
                        <Row>
                            <Col span={24}>
                                <UserInfoUI userData={basicInfo} activeKey={1} header={'Basic Information'} nonEditable={true} />
                            </Col>
                            <Col span={24}>
                                <UserInfoUI userData={personalInfo} activeKey={2} header={'Personal Information'} nonEditable={true} />
                            </Col>
                            <Col span={24}>
                                <UserInfoUI userData={bankInfo} activeKey={3} header={'Bank Information'} nonEditable={true} />
                            </Col>

                            <Col style={{ display: "flex", justifyContent: "flex-end" }} span={24}>
                                <Form.Item>
                                    <Button style={{ margin: '30px 0 0 0 ', backgroundColor: "#7CC58F", borderColor: "#7CC58F" }} className='changeUserStatusBtn' shape="round" type="primary" htmlType="submit" loading={loadData} >
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Form>
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, patchApi, stepperMoving })(EditApprovedUsers) 
