import React, { Component } from 'react';
import { Spin } from 'antd';
interface Props {
    size: any,
    caption?: string,
}
class Spinner extends Component<Props> {
    render() {
        return <div className='spinner'>
            <Spin size={this.props.size} tip={this.props.caption ?? ''} />
        </div>;
    }
}

export default Spinner;
