import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie'
import Permission from './permission';
import { useDispatch } from 'react-redux';
import { getAccessRoutes } from '../stores/actions';

export const PrivteRoute = ({ component: Component, pathUrl, actionsAllowed, ...rest }: any) => {
    const dispatch = useDispatch();
    Permission.handleAddingPermisstion(actionsAllowed)
    Permission.handleAddingPath(pathUrl)
    return (
        <Route
            {...rest}
            render={props => {
                const check_cookis = Cookies.get('token') ? true : false;
                if (check_cookis) {
                    return <Component {...props} />;
                } else {
                    Cookies.remove('token');
                    dispatch(getAccessRoutes([]))
                    return (
                        <Redirect to='/log-in' />
                    )
                }
            }
            }
        />
    )
}

