import React, { Component } from 'react'
import { Menu } from 'antd';
import { LockOutlined, MenuUnfoldOutlined,MenuFoldOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { isAuthintecated, toggleSideBar, getAccessRoutes } from '../../stores/actions';
interface Props extends RouteComponentProps<any> {
  isAuthintecated: any,
  toggleSideBar: any,
  is_bar_collapsed: boolean,
  getAccessRoutes: any
}
class Navigation extends Component<Props> {
  state = {
    current: '',
  };

  handleClick = (e: any) => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };
  logOut = () => {
    Cookies.remove('token');
    this.props.isAuthintecated(false)
    this.props.history.push('/log-in')
    this.props.getAccessRoutes([])
  }
  collapseSideBar = () => {
    // this.props.toggleSideBar(!this.props.is_bar_collapsed)
  }
  render() {
    let intFrameWidth = window.innerWidth <= 600 ? true : false;
    const { current } = this.state;
    return (
      <Menu onClick={this.handleClick} style={{ position: 'relative', display: 'flex' }} selectedKeys={[current]} mode="horizontal">

        <Menu.Item key="button" className='navBtn' onClick={this.collapseSideBar} style={{ border: '1px solid #f0f0f0' }} disabled={intFrameWidth}>
          {this.props.is_bar_collapsed?<MenuUnfoldOutlined />:<MenuFoldOutlined />}
        </Menu.Item>
        <Menu.Item onClick={this.logOut} style={{ position: 'absolute', top: 0, right: 0 }} key="log" icon={<LockOutlined />}>
          <span>Log-out</span>
        </Menu.Item>
        
      </Menu>
    );
  }
}
const mapStateToProps = (state: any) => ({
  is_bar_collapsed: state.componentReducer.is_bar_collapsed,
})
export default withRouter(connect(mapStateToProps, { isAuthintecated, toggleSideBar, getAccessRoutes })(Navigation))
