import React, { Component } from 'react';
import { Result, Button } from 'antd';
interface Props{
    status?:any,
    title?:any,
    subTitle?:any,
    btnTitle?:any,
    btnClick?:any,

}
class OutPutResult extends Component<Props> {
    render() {
        const {status,title,subTitle,btnClick,btnTitle} = this.props
        return <div>
            <Result
                status={status}
                title={title?title:'Operation Portal'}
                subTitle={subTitle?subTitle:'All the links on the left of your screen are your account permissions, if you have any problem please contact us'}
                extra={<Button type="primary" onClick={btnClick}>{btnTitle}</Button>}
            />
        </div>;
    }
}

export default OutPutResult;
