import { AxiosRequestConfig } from "axios";
import axios from "../stores/requests";
import Cookies from 'js-cookie';

export const request = ({
  url,
  method,
  data,
  params,
  headers,
}: AxiosRequestConfig) => {
  return async (_dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      const token = Cookies.get('token');
      const axios_obj: AxiosRequestConfig = {
        url,
        method,
        ...(data && {
          data,
        }),
        ...(params && {
          params: {
            ...params,
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        ...(headers && {
            ...headers,
          })
        },
      };
      try {
        const res = await axios(axios_obj);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  };
};
