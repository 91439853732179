import React, { useState } from 'react'
import { Button, Col, Row } from 'antd';
import XLSX from "xlsx";
import helper from '../../utils/helper';
import { getDataSheet, stepperMoving } from '../../stores/actions';
import { useDispatch } from 'react-redux';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import fileSaver from 'file-saver';
import TruckingModal from '../Trucking/modal';
// import { bulkCreateProductsTemplate } from '../../utils/bulkSheetsTemplates';
interface Props {
    isUpdate?: boolean,
    validCols: any,
    fileUrl:string,
}

export default function UploadProds(props: Props) {
    const [validFiles, setValidFiles] = useState(false);
    const [viewInstructions, setViewInstructions] = useState(false);
    const [uploadingErrorMessage, setUploadingErrorMessage] = useState('');
    const [uploadingError, setUploadingError] = useState(false);
    const dispatch = useDispatch();

    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    const saveFile = () => fileSaver.saveAs(props.fileUrl, `sample`);

    const forwardClickToInputElement = () => {
        fileInputRef.current!.click();
    };
    const handleFile = (file: any) => {
        setUploadingError(false)
        let file_size = file.size / (1024 * 1024), maxSize = 1, size;
        if (file.size < 1024) {
            size = file.size + ' B';
        } else if (file.size < (1024 * 1024)) {
            size = Math.floor(file.size / 1024) + ' KB';
        } else {
            size = Math.floor(file.size / (1024 * 1024)) + ' MB';
        }
        console.log(size);

        /* Boilerplate to set up FileReader */
        let acceptable_exe = helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            setValidFiles(true);
        } else if (file_size > maxSize) {
            setValidFiles(true);
            // return Upload.LIST_IGNORE
        } else {
            setValidFiles(false);
            let fileReader = new FileReader(), rowObject: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {

                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rowObject = helper.sheetToJson(workbook.Sheets[sheet]);
                });

                const validation: any = helper.validateUploadSheetColumns(rowObject, props.validCols, true)
                console.log("🚀 ~ file: bulkUpdateUserStatus.tsx ~ line 135 ~ BulkUpdateUserStatus ~ handleFile ~ validation", validation)
                if (!validation.success) {
                    setUploadingError(true)
                    setUploadingErrorMessage(validation.message)

                    return
                }
                for (let row = 0; row < rowObject.length; row++) {
                    const element = rowObject[row];
                    if (typeof element.originalPrice !== 'number') {
                        setUploadingErrorMessage('originalPrice must be a number');
                        setUploadingError(true)
                        return 0
                    } else if (typeof element.storeID !== 'number') {
                        setUploadingErrorMessage('storeID must be a number');
                        setUploadingError(true)
                        return 0
                    } else if (typeof element.finalPrice !== 'number') {
                        setUploadingErrorMessage('finalPrice must be a number');
                        setUploadingError(true)
                        return 0
                    } else if (typeof element.quantity !== 'number') {
                        setUploadingErrorMessage('quantity must be a number');
                        setUploadingError(true)
                        return 0
                    }
                }
                dispatch(getDataSheet({ sheetData: rowObject }))
                dispatch(stepperMoving(1));
            }
        }
    }

    const handleFileChange = (e: { target: { files: any, value: any }; }) => {
        const files = e.target.files;
        if (files && files[0]) handleFile(files[0]);
        e.target.value = null;
    }

    const cancelInstructionsModal = () => {
        setViewInstructions(false)
    }

    const instructions = <>
        <div className="validationProds">
            <h4 style={{ margin: '15px 0 0 0' }}>Mandatory fields</h4>
            <ul className='list' about='Mandatory fields'>
                <li>partNumber</li>
                {/* <span className='simpleDesc'>رقم التليفون: لابد من ادخال رقم محمول مصري</span> */}

                {!props.isUpdate && <li>technicalModel </li>}

                <li>storeID</li>

                {!props.isUpdate &&
                    <><li>mainCategory</li>

                        <li>brand</li>



                        <li>arabicTitle</li></>}
                <li>englishTitle</li>
                <li>quantity </li>

                <li>originalPrice</li>
                <li>finalPrice </li>

                {!props.isUpdate && <li>imageURL1</li>}
            </ul>
            {!props.isUpdate && <>
                <hr />
                <h4>Don't add more than imageURL5</h4>
                <ul className='list' about='Mandatory fields'>
                    <li>imageURL2</li>
                    <li>imageURL3</li>
                    <li>imageURL4</li>
                    <li>imageURL5</li>
                </ul>
                <hr />
                <h4>Only for products specifications </h4>

                <ul className='list' about='Mandatory fields'>
                    <li>color</li>
                    <li>ram</li>

                </ul></>}
            <Button className='cancelInstruction' onClick={() => setViewInstructions(false)}>Cancel</Button>
        </div>
    </>
    return (
        <>
            <div className="instructions">
                <Row>
                    <Col xl={18} md={18} sm={24}>
                        <p className="head">
                            Before uploading, download the following form to fill in the required data,<br /> then re-upload it to the system
                        </p>
                        <p className='fillData' onClick={() => setViewInstructions(true)} ><ExclamationCircleOutlined /><span style={{ margin: '0 10px' }} className='fillHint'>
                            How to fill in the data sheet
                        </span></p>
                    </Col>
                    {<Col xl={6} md={6} sm={24}>
                        <Button className='sample' onClick={saveFile}>Download the file</Button>
                    </Col>}
                </Row>

            </div>

            {/* <Button className='uploadBtn' loading={spin} onClick={forwardClickToInputElement}>تحميل ملف بيانات الموظفين</Button><br /> */}
            <div className='upload' onClick={forwardClickToInputElement}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click to upload the file</p>
                {/* <p className="ant-upload-hint">يدعم تحميل ملف واحد</p> */}
                {validFiles && <p className="error-hint">{'Allowed extension CSV or XLS up to 12 MB'}</p>}
            </div>
            <span className="maxSize">The recommended size is up to 1MB</span><br />
            {uploadingError && <span className="maxSize" style={{ color: 'red' }}>{uploadingErrorMessage}</span>}
            <form className="form-inline">
                <div className="form-group">
                    <label htmlFor="file">  </label>
                    <input
                        hidden
                        type="file"
                        className="form-control"
                        id="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept='.xlsx,.xls,.csv'
                    />
                </div>
            </form>


            <TruckingModal visibleModal={viewInstructions}
                content={instructions}
                handleCloseModal={cancelInstructionsModal} title={'How to fill the data sheet'} />
        </>
    )
}
