import React, {  PureComponent } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import RequestInfo from './requestInfo';

class ViewRequestInfo extends PureComponent{
    

    render() {
        const components = [
            {
                title: 'Get Request',
                content: <GetInfo apiLink='requests/getAdvancesIssues' request={true}/>,
                id: 0,
            }, {
                title: 'Request Info',
                content: <RequestInfo />,
                id: 1
            },
        ]
        return (
            <>
                <Stepper componentSteps={components} request={true}/>
            </>
        )
    }
}

export default ViewRequestInfo