import React, { Component } from 'react'
import GetInfo from '../components/getInfo';
import Stepper from '../components/stepper';
import CompanyInfoList from './deployCompany/deployCompany';  //deploy company component

class ViewCompanyDetails extends Component{
    state = {
        componentSteps: [
            {
                title: 'Get Company',
                content: <GetInfo apiLink='companies/getOneCompany/' isAllCompanies={true}/>,
                id: 0,
            }, {
                title: 'Company Details',
                content: <CompanyInfoList deployNotAllowed = {true}/>,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default ViewCompanyDetails
