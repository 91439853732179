import React, { useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../apis/axiosRequest";
import { stepperMoving, getDataInfo } from "../../stores/actions";
import { errorMessage } from "../../utils/commonStringsConsts";
import { addUsersCommentsApi } from "../../utils/apisConsts";
import UserInfoUI from '../../components/uiInfo';
import BulkErrorsModal from "../../components/bulkErrorsModal";
import TransactionErrorModal from "../../components/transactionErrorModal";
import helper from "../../utils/helper";


export default function CreateUserCommentForm() {
    const userInfo = useSelector((state: any) => state.actionsReducer.info)
    const dispatch = useDispatch()
    const [sendingData, setSendingData] = useState(false)
    const [resErrorContent, setResErrorContent] = useState<any>({
        resErrorContent: "",
        validationErrors: "",
        visibleModal: false,
        message: "",
        visibleMessageModal: false
    })
    const commentInfo = [
        { key: 'Previous Comment', value: userInfo?.comment ? userInfo?.comment : 'NA' },
        {
            key: 'New Comment',
            value: <Form.Item name="newComment">
                <Input placeholder="Enter new comment" />
            </Form.Item>
        }
    ]

    const handleCloseModal = () => {
        setResErrorContent({
            resErrorContent: "",
            validationErrors: "",
            visibleModal: false,
            message: "",
            visibleMessageModal: false
        })
    }

    const createUserComment = async (values: { newComment: string }) => {
        try {
            if (!values.newComment) {
                message.error("There is no comment to be submitted", 4)
                return
            }
            setSendingData(true)
            const res: any = await dispatch(request({ url: addUsersCommentsApi, method: "POST", data: { data: [{ userID: userInfo.userID, text: values.newComment }] } }));
            if (res.data.status === 200) { //show success message
                message.success(res.data.message)
                dispatch(stepperMoving(0));
                dispatch(getDataInfo({
                    ...userInfo,
                    comment: values.newComment
                }));
                helper.resetAccountTableInStepper();
            }
            else if (res.data.status === 422) { //open errors list popup
                setResErrorContent({
                    resErrorContent: res.data.message,
                    validationErrors: res.data.failedComments,
                    visibleModal: true,
                    message: "",
                    visibleMessageModal: false
                })
            }
            else if (res.data.status === 400 && res.data.reason) { //open transaction error popup
                setResErrorContent({
                    message: res.data.message,
                    resErrorContent: res.data.reason,
                    visibleMessageModal: true,
                    validationErrors: "",
                    visibleModal: false,
                })
            }
            else { // show any other status errors messages (400 without reason OR 500)
                message.error(res.data.message)
            }
            setSendingData(false)
        } catch (error) { // show any response status errors messages except 200(OK)
            setSendingData(false)
            message.error(errorMessage);
        }
    }

    return (
        <div className="userComment">
            <Form name="nest-messages" onFinish={createUserComment}>

                <Row>
                    <Col span={24}>
                        <UserInfoUI userData={commentInfo} activeKey={1} header={'General Comment'} nonEditable={true} collapsible={"disabled"} />
                    </Col>

                    <Col span={24} className="btnCol">
                        <Form.Item>
                            <Button shape="round" block htmlType="submit" loading={sendingData}>
                                Update
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <BulkErrorsModal errors={resErrorContent.validationErrors} visibleModal={resErrorContent.visibleModal} handleCloseModal={handleCloseModal} errorMessage={resErrorContent.resErrorContent} />
            <TransactionErrorModal resErrorContent={resErrorContent.resErrorContent} visibleMessageModal={resErrorContent.visibleMessageModal} closeModal={handleCloseModal} />
        </div>
    )
}
