import { Form, Row, Col, Select, Button, Radio, ConfigProvider, Input, message, InputNumber, Descriptions } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { move, getApi, postApi } from '../../../stores/actions';
import { useCallback, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner';
import OutPutResult from '../../../components/result';
import helper from '../../../utils/helper';
import TruckingModal from '../modal';
import { useHistory, useRouteMatch } from 'react-router-dom'
import { truckingApis } from '../../../utils/commonStringsConsts';
const { Option } = Select;
interface IRootState {
  [key: string]: {
    driverData: {
      name: string,
      firstName: string,
      lastName: string,
    }
  };
}
function ExpenseRequest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const match: { [key: string]: any } = useRouteMatch();
  const driverSelector: any = useSelector<IRootState>(state => state.truckingReducer.driverData);
  const [services, setServices] = useState([]);
  const [spin, setSpin] = useState(false);
  const [errorView, setErrorView] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [openTruckingModal, setOpenTruckingModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [sending, setSending] = useState(false);
  const [orderData, setOrderData] = useState({
    userBankID: '',
    services: [],
  });
  const [sendExpense, setSendExpense] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [payrollResp, setPayrollResponse] = useState({
    actionBy: {
      firstName: '',
      lastName: '',
      middleName: '',
    },
    referenceNumber: '',
  });
  const [superVisorBalance, setSuperVisorBalance] = useState(false)
  const fetchServices = useCallback(async () => {
    setSpin(true);
    if (!navigator.onLine) {
      setErrorMessage('أنت غير متصل بالإنترنت')
      setSpin(false);
      setErrorView(true)
      return 0
    }
    const res: any = await dispatch(getApi(`${truckingApis}services/companyServices`, {}))
    try {
      const driverInfo = await res.payload;

      if (driverInfo.status !== 200) {
        setErrorMessage('حدث خطأ ')
        setSpin(false);
        setErrorView(true)
        return 0
      }
      setServices(driverInfo.services);
      setSpin(false)

    } catch (error: any) {
      setErrorMessage('حدث خطأ ')
      setSpin(false)
      setErrorView(true)
    }
  }, [dispatch])
  const companyBalance = useCallback(async () => {
    setSpin(true);
    if (!navigator.onLine) {
      setErrorMessage('أنت غير متصل بالإنترنت')
      setSpin(false);
      setErrorView(true)
      return 0
    }
    const balance: any = await dispatch(getApi(`${truckingApis}companies/balance`, {}))
    try {
      const res = await balance.payload;

      if (res.status !== 200) {
        setErrorMessage('حدث خطأ ')
        setSpin(false);
        setErrorView(true)
        return 0
      }
      setSpin(false);
      setTotalBalance(res.userBalance ? res.userBalance.userBalanceWithoutFees : res.companyBalance.toFixed(2));
      setSuperVisorBalance(res.userBalance ? true : false)
    } catch (error: any) {
      setErrorMessage('حدث خطأ ')
      setSpin(false)
      setErrorView(true)
    }
  }, [dispatch])
  useEffect(() => {
    fetchServices();
  }, [fetchServices]);
  useEffect(() => {
    companyBalance();
  }, [companyBalance]);
  const addExpense = (values: any) => {
    let categories = values.categories;
    let firstData = [{ id: values.id, amount: Number(values.amount) }];
    if (!categories || categories.length === 0) {
      const data: any = {
        uuid: driverSelector.uuid,
        services: firstData,
        userBankID: selectedBankID !== 0 ? selectedBankID : driverSelector?.userBanks.length > 0 ? driverSelector?.userBanks[0].id : null,
      };
      setTotalAmount(values.amount)
      totalExpenses(Number(values.amount), data)
      return
    } else {

      let changeTheAmountToNumber = [...firstData, ...categories]
      const data: any = {
        uuid: driverSelector.uuid,
        services: changeTheAmountToNumber.map((num: any) => { return { ...num, amount: Number(num.amount) } }),
        userBankID: selectedBankID !== 0 ? selectedBankID : driverSelector?.userBanks.length > 0 ? driverSelector?.userBanks[0].id : null,
      };
      const sum = data.services.reduce((accumulator: any, object: any) => {
        return accumulator + Number(object.amount);
      }, 0);

      setTotalAmount(sum);
      totalExpenses(sum, data)

    }

  }

  const totalExpenses = (sum: number, summary: any) => {
    if (sum > totalBalance) {
      setOpenTruckingModal(true);
      setModalTitle('حدث خطأ');
      setTotalAmount(sum)

      setSendExpense(1)
    } else if (sum < 30) {
      setOpenTruckingModal(true);
      setModalTitle('حدث خطأ');
      setTotalAmount(sum)

      setSendExpense(3)
    }
    else {
      setOrderData(summary);
      setModalTitle(' الطلب');
      setOpenTruckingModal(true);
      setSendExpense(2)

    }
  }

  const sendDriverExpenses = async (data: any) => {
    setSending(true)
    const driverExpenses: any = await dispatch(postApi(`${truckingApis}transactions/transaction`, data));
    try {
      const res = await driverExpenses.payload;
      if (res.status === 402) {
        message.error('لا يمكن إتمام هذه المعاملة لأنها أقل من الحد المسموح به', 3);
        setSending(false)
        return
      }
      if (res.status !== 200) {
        message.error('حدث خطأ ما');
        setSending(false)
        return
      }
      setPayrollResponse(res.data)
      setSendExpense(0)

      setSending(false)


    } catch (error) {

      message.error('حدث خطأ ما');
      setSending(false)
    }

  }

  const closeTruckingModal = () => {
    setOpenTruckingModal(false);
    // routeToExpense();

  }
  const createNewRequest = async () => {
    if (match.params.id) {
      await history.push("/trucking/viewDriversList");
      await helper.refreshPage();
      return
    }
    setOpenTruckingModal(false);
    dispatch(move(0))

  }
  const routeToExpense = () => {
    history.push('/trucking/updateTruckingStatus');
    helper.refreshPage()
  }
  const changeBanks = (evt: any) => {
    setSelectedBankID(evt.target.value)
  }

  const goBack = async () => {
    if (match.params.id) {
      await history.push("/trucking/viewDriversList");
      await helper.refreshPage()
      return
    }
    dispatch(move(0))
  }

  let toExpenses = <>
    <div className="reviewOrderBalance">
      <div style={{ textAlign: 'center' }}>
        <h3>تم اضافة الطلب بنجاح</h3>

        <Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          {<Descriptions.Item span={3} label="رقم الطلب">{payrollResp?.referenceNumber || 'لا يوجد'}</Descriptions.Item>}
          {<Descriptions.Item span={3} label="من">{`${payrollResp?.actionBy?.firstName || ''} ${payrollResp?.actionBy?.lastName || ''}`}</Descriptions.Item>}
          {<Descriptions.Item label="إلي" span={3}>{driverSelector?.name ?? 'لا يوجد'}</Descriptions.Item>}
          {<Descriptions.Item span={3} label="المبلغ">{totalAmount ? `${helper.amountFormatter(totalAmount)} جنية` : '0'}</Descriptions.Item>}
          {<Descriptions.Item label="التاريخ" span={3}>
            {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}
          </Descriptions.Item>}
        </Descriptions>
      </div>
      <div className='btns' style={{ textAlign: 'center' }}>
        <Button className='decline' onClick={createNewRequest}>إضافة طلب جديد </Button>

        <Button className='approve' onClick={routeToExpense}>الذهاب الي ادارة التحويلات</Button>
      </div>
    </div>
  </>
  let balanceContent = <>
    <div className="reviewOrderBalance">
      <div className="driverInfo">
        <h3 style={{ borderBottom: 'solid 1px #ddd', paddingBottom: '20px' }}>{superVisorBalance ? 'الرصيد غير كافي' : 'رصيد الشركة غير كافي'}</h3>

        <Row>
          <Col span={8}>
            <div className='info'>
              <h4>مجموع المصروفات</h4>
              {totalAmount ? `${helper.amountFormatter(totalAmount)} جنية` : '0'}

            </div>
          </Col>
          <Col span={8}>
            <div>
              <h4 style={{ display: 'flex' }}>{superVisorBalance ? 'الرصيد' : 'رصيد الشركة'}</h4>
              {helper.amountFormatter(totalBalance)} جنية
            </div>
          </Col>
        </Row>

      </div>
      <div className='btns' style={{ textAlign: 'center' }}>
        <Button className='approve' onClick={closeTruckingModal}>رجوع الي الطلب</Button>
      </div>
    </div>
  </>


  let minimalRequest = <>
    <div className="reviewOrderBalance">
      <div className="driverInfo">
        <h3 style={{ textAlign: 'center' }}>قيمة المصاريف يجب ان لا تكون اقل من 30 جنيه</h3>
      </div>
      <div className='btns' style={{ textAlign: 'center' }}>
        <Button className='approve' onClick={closeTruckingModal}>رجوع الي الطلب</Button>
      </div>
    </div>
  </>



  let orderReviewContent = <>
    <div className='reviewDriverOrder'>
      <div className="driverExpenses">

        <Row>
          <Col span={6}>
            <div className='info'>
              <h4>الاسم</h4>
              {console.log('driverSelector = ', driverSelector)}
              {driverSelector?.name ?? 'لا يوجد'}

            </div>
          </Col>

        </Row>

      </div>
      {/* <hr /> */}

      <div className="driverExpenses">
        <h3> المصروفات</h3>
        <Row>
          {Array.isArray(orderData.services) && orderData.services.map((oneSummary: any, key: any) => {
            return (
              <Col key={key} span={6}>
                <h4>{Array.isArray(services) && services.filter((serviceName: any) => serviceName.id === oneSummary.id).map((serviceName: any) => serviceName.nameAr)}</h4>
                <p>{helper.amountFormatter(oneSummary.amount)} جنيه</p>
              </Col>
            )
          })}
        </Row>
      </div>
      <Row>
        <Col span={6}>
          <div>
            <h4 style={{ display: 'flex' }}>طريقه الصرف</h4>
            {Array.isArray(driverSelector?.userBanks) && driverSelector?.userBanks.filter((paymentName: any) => paymentName.id === orderData.userBankID).map((paymentName: any) => paymentName.bankId.nameAr)}
          </div>
        </Col>
        <Col span={6}>
          <div>
            <h4 style={{ display: 'flex' }}>رقم الحساب</h4>
            {Array.isArray(driverSelector?.userBanks) && driverSelector?.userBanks.filter((paymentName: any) => paymentName.id === orderData.userBankID).map((paymentName: any) => paymentName.cardNumber)}
          </div>
        </Col>
        <Col span={6}>
          <h4>مجموع المصروفات</h4>
          {totalAmount ? `${helper.amountFormatter(totalAmount)} جنية` : '0'}
        </Col>
      </Row>
    </div>

    <div className='btns' style={{ float: 'left' }}>
      <Button className='decline' onClick={closeTruckingModal}>الرجوع الي الطلب</Button>
      <Button className='approve' type='primary' onClick={() => sendDriverExpenses(orderData)} loading={sending}>تأكيد الطلب</Button>
    </div>
  </>

  return (

    <div>
      <ConfigProvider direction="rtl">
        {driverSelector ? <>
          {spin ? <Spinner size='large' /> : errorView ? <OutPutResult status={'500'} title={errorMessage} subTitle={' '}
            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
            <Form
              initialValues={{ type: "type_1" }}
              onFinish={addExpense}
            >

              <div className="site-card-border-less-wrapper">
                <div className='expensesHead'>
                  <span className='arrowBack'>
                    <RightOutlined onClick={goBack} />  <span style={{ position: 'relative', bottom: '2px' }}>رجوع</span>
                  </span>
                  {/* <span>تحديد المصاريف اليوميه المتوقعة</span> */}
                </div>
                <div className="expenseForm" >
                  <Row>

                    <Col span={24}>
                      <label className='label' htmlFor="">المستلم</label>
                      <Form.Item name="driverID" rules={[{ required: false, message: 'اجباري' }]} initialValue={driverSelector?.name ?? 'لا يوجد'}>
                        <Input placeholder='اسم المستلم' readOnly />
                      </Form.Item>
                    </Col>

                  </Row>

                  <Row>
                    <Col span={12} xs={24} md={12} lg={12}>
                      <label className='label' htmlFor="">فئة المصروف </label>
                      <Form.Item name="id" rules={[{ required: true, message: 'اجباري' }]}>
                        <Select style={{ width: '100%' }} placeholder='اختار المصروف البومي'>
                          {Array.isArray(services) && services.map((group: any, key: number) => {
                            return <Option value={group.id} key={key} >{group.nameAr}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} md={12} lg={12}>
                      <label className='label' htmlFor="">قيمة المصروف </label>
                      <Form.Item
                        // style={{ margin: '0px 10px 5px 10px' }}
                        name="amount"
                        rules={[{ required: true, message: 'اجباري' }, { type: 'number', min: 1, message: `القيمة غير صحيحة` },
                        { type: 'number', max: 10000000, message: `القيمة غير صحيحة` }]}
                      >
                        <InputNumber style={{ width: '100%' }} placeholder='المصروف' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.List name="categories">

                    {(fields, { add, remove }) => (

                      <>
                        {fields.map((field: any, key: any) => (

                          <>
                            <Row key={key}>
                              <Col span={12} xs={24} md={12} lg={12}>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                  }
                                >
                                  {() => (
                                    <>
                                      <label className='label' htmlFor="">فئة المصروف </label>
                                      <Form.Item {...field} name={[field.name, 'id']} rules={[{ required: true, message: 'اجباري' }]}>
                                        <Select style={{ width: '100%' }} placeholder='اختار المصروف البومي' >
                                          {Array.isArray(services) && services.map((group: any, key: number) => {
                                            return <Option value={group.id} services={group.services} key={key}>{group.nameAr}</Option>
                                          })}
                                        </Select>

                                      </Form.Item>

                                    </>
                                  )}
                                </Form.Item>
                              </Col>
                              <Col span={11} xs={23} md={11} lg={11}>
                                <label className='label' htmlFor="">قيمة المصروف </label>

                                <Form.Item
                                  {...field}
                                  name={[field.name, 'amount']}
                                  // style={{ margin: '0px 10px 5px 10px' }}
                                  rules={[{ required: true, message: 'اجباري' }, { type: 'number', min: 1, message: `القيمة غير صحيحة` },
                                  { type: 'number', max: 10000000, message: `القيمة غير صحيحة` }]}
                                >
                                  <InputNumber style={{ width: '100%' }} placeholder='المصروف' />

                                </Form.Item>
                              </Col>
                              <Col span={1}>

                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '27px', padding: '0 0px' }} />
                              </Col>
                            </Row>
                          </>
                        ))}



                        {fields.length <= 28 && <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined style={{ paddingLeft: '6px' }} />}>
                            اضافة فئة جديدة
                          </Button>
                        </Form.Item>}
                      </>
                    )}
                  </Form.List>

                  {driverSelector?.userBanks.length > 0 && <div className="site-card-border-less-wrapper" style={{ background: '#fbfbfbfa' }}>
                    <label className='label'>  طريقة الصرف</label><br />
                    <h2>  طريقة الدفع </h2>
                    <div className="expenseForm" >
                      <Form.Item name="bank" rules={[{ required: true, message: 'يجب إختيار طريقه للدفع' }]}>
                        <Radio.Group onChange={changeBanks}>
                          {Array.isArray(driverSelector?.userBanks) && driverSelector.userBanks.map((radio: any, key: any) => {
                            return <Radio value={radio.id} key={key} style={{ color: '#000' }}>
                              <span className='cartType' >{radio.bankId.nameAr}</span><br />
                              <span className='cartNum'>{radio.cardNumber}</span>
                            </Radio>
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>}


                </div>
              </div>
              <div className='collectExpense'>
                <Form.Item>
                  <Row>
                    <Col className='total' lg={12} md={12} xs={24}>
                      <span>الرصيد</span><br />
                      <span className="amount">{helper.amountFormatter(totalBalance)} جنيه مصري</span>
                    </Col>
                    <Col className='submitExpense' lg={12} md={12} xs={24}>
                      <Button type="primary" htmlType="submit" loading={false}>
                        استمرار ومراجعه الطلب
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </div>

            </Form>}

          <TruckingModal handleCloseModal={[1, 2, 3].includes(sendExpense) ? closeTruckingModal : routeToExpense} visibleModal={openTruckingModal}
            content={sendExpense === 2 ? orderReviewContent : sendExpense === 1 ? balanceContent : sendExpense === 3 ? minimalRequest : toExpenses} title={modalTitle} />
        </> : ''}
      </ConfigProvider>

    </div>
  )
}
export default ExpenseRequest