import { Button, Descriptions, message } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { patchApi, stepperMoving, getApi, getDataInfo } from '../../stores/actions'
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps<any> {
    info?: {
        userID: number,
        name: string,
        companyName: string,
        phone: string,
        email: string,
        advanceID: number,
        numberOfMonths: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        dateOfRequest: string,
        netSalary: number,
        requestedAmount: number,
        AdvancesIssuesLogs: any,
    },
    patchApi: any,
    stepperMoving: any,
    getApi?: any,
    getDataInfo?: any
    stepNumber?: any
}
class UserInfo extends Component<Props> {
    state = {
        approveLoading: false,
        rejectLoading: false,
    }

    changeHighAmountStatus = async (status: boolean, dynamicState: string) => {
        this.setState({ [dynamicState]: true })
        const data = {
            requestID: this.props.info?.advanceID,
            isResolved: status
        }
        const changeStatus = await this.props.patchApi('requests/approveHighAmount', data);
        const res = await changeStatus.payload;
        try {
            if (res.status === 200) {
                this.setState({ [dynamicState]: false })
                message.success(res.message);
                this.props.stepperMoving(0)
            } else {
                this.setState({ [dynamicState]: false })
                message.error(res.message);
            }
        } catch (error) {
            this.setState({ [dynamicState]: false })
            message.error(`Something went wrong, please try again `);
        }

    }
    render() {
        const { info } = this.props;
        const { approveLoading, rejectLoading } = this.state;
        return (
            <div>
                    {info &&
                        <>

                            <Descriptions bordered style={{ margin: '15px 0' }}>
                                <Descriptions.Item label="Name" span={2}>{info.name ? info.name : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Company" span={2}>{info.companyName ? info.companyName : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Phone" span={2}>{info.phone ? info.phone : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="User ID" span={2}>{info.userID ? info.userID : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Date Of Request" span={2}>{info.dateOfRequest ? info.dateOfRequest : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Advance ID" span={2}>{info.advanceID ? info.advanceID : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Net Salary" span={2}>{info.netSalary ? info.netSalary : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Number Of Months" span={2}>{info.numberOfMonths ? info.numberOfMonths : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Staff ID" span={2}>{info.staff_id ? info.staff_id : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Amount" span={2}>{info.requestedAmount ? info.requestedAmount : 'NA'}</Descriptions.Item>
                                <Descriptions.Item label="Issue Name" span={2}>{info.AdvancesIssuesLogs ? info.AdvancesIssuesLogs[0].issueName : 'NA'}</Descriptions.Item>

                            </Descriptions>
                            <div style={{ float: 'right' }}>
                                <span>
                                    <Button type="primary" danger onClick={() => this.changeHighAmountStatus(false, 'rejectLoading')} loading={rejectLoading} style={{ width: '100px' }}>
                                        Reject
                                    </Button>
                                </span>
                                <span style={{ margin: '0 0px 0 50px' }}>
                                    <Button type="primary" onClick={() => this.changeHighAmountStatus(true, 'approveLoading')} loading={approveLoading} style={{ backgroundColor: '#6BCB77', border: '1px solid #6BCB77', width: '100px' }}>
                                        Approve
                                    </Button>

                                </span>
                            </div>
                        </>

                    }
            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info[0],
})
export default withRouter(connect(mapStateToProps, { patchApi, stepperMoving, getApi, getDataInfo })(UserInfo))