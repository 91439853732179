import { Badge, Button, Col, Descriptions, Form, FormInstance, InputNumber, message, Row, Select } from "antd"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BasicUserInfo from "../../components/basicUserInfo";
import OutPutResult from "../../components/result";
import Spinner from "../../components/spinner";
import { stepperMoving } from "../../stores/actions";
import { getBlockedUserAmountApi } from "../../utils/apisConsts";
import { errorMessage } from "../../utils/commonStringsConsts";
import helper from "../../utils/helper";

const { Option } = Select;
interface Props {
    isException?: boolean,
    mostRecent?: boolean,
    lostApi?:string,
}
export default function CreateLostForm(props: Props) {
    const userInfo = useSelector((state: any) => state.actionsReducer.info)
    const dispatch = useDispatch()
    const [sendingData, setSendingData] = useState(false)
    const [paymentCycles, setPaymentCycles] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [loadingAmount, setLoadingAmount] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [inputAmount, setInputAmount] = useState(0)
    const [amount, setAmount] = useState({
        before: 0,
        after: 0
    })
    const formRef = React.createRef<FormInstance>();

    useEffect(() => {
        checkUserStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkUserStatus = async () => {
        if (userInfo.userStatusTypeID !== 2) {
            dispatch(stepperMoving(0))
            message.error("This user must be blocked before adding a lost request", 4)
        }
        else {
            await getPaymentCycles()
        }
    }

    const createLostCase = async (values: any) => {
        try {
            setSendingData(true)
            const createLostRes: { [key: string]: any } = await dispatch(request({
                url: props.lostApi, method: "POST", data: {
                    amount: values.amount,
                    userID: userInfo.userID,
                    paymentCycle: values.paymentCycle,
                    isException: props.isException
                }
            }))
            if (createLostRes.data.status === 200) {
                message.success(`${createLostRes.data.message} With ID: ${createLostRes.data.requestID}`)
                dispatch(stepperMoving(0))
            }
            else if (createLostRes.data.status === 400) {
                message.error(`${createLostRes.data.message}, Reason: ${createLostRes.data.reason}`)
            }
            else {
                message.error(createLostRes.data.message)
            }
            setSendingData(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setSendingData(false)
        }
    }

    const getPaymentCycles = async () => {
        try {
            setLoading(true) 
            const paymentCyclesRes: any = await helper.getPaymentCycles(userInfo.companyID, props.mostRecent, userInfo.actualCutOffDate, true, props.isException)
            if (!paymentCyclesRes.errorMessage) {
                setPaymentCycles(paymentCyclesRes)
            } else {
                message.error(paymentCyclesRes.errorMessage)
                setErrorMode(true)
            }
            setLoading(false)
        }
        catch (error: any) {
            setErrorMode(true)
            setLoading(false)
        }
    }

    const getAmount = async (paymentCycle: any) => {
        try {
            setLoadingAmount(true)
            const amountRes: any = await dispatch(request({ url: getBlockedUserAmountApi, method: "GET", params: { userID: userInfo.userID, paymentCycle } }))
            if (amountRes.data.status) {
                setAmount({
                    before: amountRes.data.amount.totalDue,
                    after: Number(amountRes.data.amount.totalDue) - Number(inputAmount)
                })
            } else {
                message.error(amountRes.data.status)
                setErrorMode(true)
            }
            setLoadingAmount(false)
        }
        catch (error: any) {
            setErrorMode(true)
            setLoadingAmount(false)
        }
    }

    const onAmountChange = (value: number) => {
        setAmount({
            ...amount,
            after: Number(amount.before) - value
        })
        setInputAmount(value)
    }

    return (
        errorMode ?
            <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh the page and try again'}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
            loading ? <Spinner size="large" />
                : <div className="createLostCase">
                    <Form name="nest-messages" onFinish={createLostCase} ref={formRef}>
                        <Row>
                            <Col span={24}>
                                <label className="infoLabel">User Information</label>
                                <BasicUserInfo info={userInfo} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={10}>
                                <label>Lost Amount (EGP) <span>*</span></label>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: 'Required' }, { type: "number", min: 1 }]}
                                >
                                    <InputNumber placeholder="Enter lost amount" onChange={onAmountChange} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <label>Invoice Month <span>*</span></label>
                                <Form.Item
                                    name="paymentCycle"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select a month" onChange={getAmount}>
                                        {Array.isArray(paymentCycles) &&
                                            paymentCycles.map((paymentCycle: { id: number, paymentCycle: number, cutOffCycleDate: string, isLocked: boolean }) =>
                                                <Option key={paymentCycle.id} value={paymentCycle.paymentCycle}>
                                                    {moment(paymentCycle.cutOffCycleDate).format('Do MMM, YYYY')}
                                                    &nbsp; &nbsp; &nbsp;<Badge count={paymentCycle.isLocked ? "Locked invoice" : 0} />
                                                </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="btnCol">
                                <Form.Item>
                                    <Button shape="round" type="primary" block htmlType="submit" loading={sendingData}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                {loadingAmount ? <Spinner size="large" /> : amount.before ?
                                    <>
                                        <label className="infoLabel">User Amount</label>
                                        <Descriptions bordered style={{ margin: '15px 0' }}>
                                            <Descriptions.Item label="Before">{helper.amountFormatter(amount.before)} EGP</Descriptions.Item>
                                            <Descriptions.Item label="After">{helper.amountFormatter(amount.after)} EGP</Descriptions.Item>
                                        </Descriptions>
                                    </>
                                    : ""}
                            </Col>
                        </Row>
                    </Form>
                </div>
    )
}
