
import { Row, Col } from 'antd';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import GetBalance from '../../../components/getBalance';
import PaymentPlans from '../../../components/paymentPlans';
import SalaryAdvance from '../../../components/salaryAdvance';
import Stepper from '../../../components/stepper';
import SubmittingBin from '../../../components/submitBinNumber'
import { sendOTPApi } from '../../../utils/apisConsts';
interface Props {
    info: any,
    noLimits: boolean,
}


class CreateRequest extends Component<Props> {

    state = {
        componentSteps: [
            {
                title: 'Phone Number',
                content: <div className='phoneNumber'><GetBalance isPhone apiLink={sendOTPApi} /></div>,
                id: 0,
            }, {
                title: 'OTP',
                content: <div className="otp">
                    <Row>
                        <Col span={7}></Col>
                        <Col span={10}>
                            <SubmittingBin twilio={true} create userLang={"en"} />
                        </Col>
                        <Col span={7}></Col>
                    </Row>
                </div>,
                id: 1
            }, {
                title: 'Salary Advance',
                content: <SalaryAdvance step={3} noLimits={this.props.noLimits} updateRequest={false}/>,
                id: 2
            }, {
                title: 'Payment Plans',
                content: <PaymentPlans noLimits={this.props.noLimits} />,
                id: 3
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <div className='createRequest'>
                    <Stepper componentSteps={componentSteps} />
                </div>
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, {})(CreateRequest)
