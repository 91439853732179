import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import EditCompanyForm1 from './editCompanyForm1';
import EditCompanyForm3 from './editCompanyForm3';
import EditCompanyForm2 from './editCompanyForm2';
import { request, stepperMoving } from '../../stores/actions'
import Spinner from '../../components/spinner';
import OutPutResult from '../../components/result';
import helper from '../../utils/helper';
import { errorMessage } from '../../utils/commonStringsConsts';
import { Button } from 'antd';

interface Props {
    editAllInfo: boolean,
}

function EditCompany(props: Props) {
    const info = useSelector((state: any) => state.actionsReducer.info)
    const current = useSelector((state: any) => state.actionsReducer.stepNumber)
    const [companyData, setCompanyData] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [allStatus, setAllStatus] = useState([]);
    const [teams, setTeams] = useState([]);
    const [companyIndustries, setCompanyIndustries] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        getData(`/companies/getReasons`, { reasons: setAllStatus });
        getData(`/companies/getTeams`, { teams: setTeams });
        getData(`/companies/getIndustriesAndTypes`, { companyIndustries: setCompanyIndustries, companyTypes: setCompanyTypes });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (info) {
            setCompanyData(info)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info])

    const getData = async (apiLink: string, dynamicState: { [key: string]: any }) => {
        setLoadingData(true)
        try {
            const data: any = await dispatch(request({ url: apiLink, method: "GET" }));
            if (data.data.status === 200) {
                for (let key in dynamicState) {
                    dynamicState[key](data.data[key])
                }
            } else {
                setErrorMode(true)
            }
            setLoadingData(false)
        } catch (error) {
            console.log(error);
            setErrorMode(true)
            setLoadingData(false)
        }
    }


    return (
        loadingData ?
            <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                <>
                    <Stepper componentSteps={[
                        {
                            title: 'Get Company',
                            content: <div style={{ marginTop: "25px" }}><GetInfo apiLink='companies/getOneCompany/' isAllCompanies={true} /></div>,
                            id: 0,
                        },
                        {
                            title: 'Basic Info',
                            content: <EditCompanyForm1 allStatus={allStatus} companyData={companyData} setCompanyData={setCompanyData} companyIndustries={companyIndustries}
                                companyTypes={companyTypes} editAllInfo={props.editAllInfo} />,
                            id: 1
                        },
                        {
                            title: 'Additional Info',
                            content: <EditCompanyForm2 companyData={companyData} setCompanyData={setCompanyData}  editAllInfo={props.editAllInfo} />,
                            id: 2
                        },
                        {
                            title: 'Teams Info',
                            content: <EditCompanyForm3 companyData={companyData} setCompanyData={setCompanyData} teams={teams} editAllInfo={props.editAllInfo} companyOldInfo={info}/>,
                            id: 3
                        },
                    ]} />
                    <div className="steps-action" style={{ margin: '-55px 0 0 0' }}>
                        {current > 0 && (
                            <Button onClick={() => dispatch(stepperMoving(Number(current)-1))}>
                                Previous
                            </Button>
                        )}
                    </div>
                </>
    )
}


export default EditCompany
