import { Button, Col, Descriptions, Form, InputNumber, message, Row } from "antd"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { request } from "../../../apis/axiosRequest"
import Spinner from "../../../components/spinner"
import { move } from "../../../stores/actions"
import { getCompanyBalanceApi, updateCompanyBalanceApi } from "../../../utils/apisConsts"
import { RightOutlined } from '@ant-design/icons';
import helper from "../../../utils/helper"

interface Props {
    company: string | null
}

export default function UpdateBalanceForm(props: Props) {
    const [companyBalance, setCompanyBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    const [sendingData, setSendingData] = useState(false)
    const [newAmount, setNewAmount] = useState<any>(0)
    const dispatch = useDispatch()

    useEffect(() => {
        getCompanyBalance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompanyBalance = async () => {
        try {
            setLoading(true)
            const res: any = await dispatch(request({ url: getCompanyBalanceApi, params: { id: props.company }, method: 'get' }))
            if (res.data.status === 200) {
                setCompanyBalance(res.data.companyBalance)
            } else {
                message.error('حدث خطأ ما', 4)
                dispatch(move(0))
            }
            setLoading(false)
        } catch (err: any) {
            message.error('حدث خطأ ما', 4)
            dispatch(move(0))
            setLoading(false)
        }
    }

    const updateCompanyBalance = async (values: { amount: number }) => {
        try {
            setSendingData(true)
            const res: any = await dispatch(request({
                url: updateCompanyBalanceApi,
                data: {
                    companyID: props.company,
                    amount: values.amount
                },
                method: 'post'
            }))
            if (res.data.status === 200) {
                message.success("تم تحديث حساب الشركة بنجاح", 4)
                dispatch(move(0))
            } else {
                message.error('حدث خطأ ما', 4)
            }
            setSendingData(false)
        } catch (err: any) {
            message.error('حدث خطأ ما', 4)
            setSendingData(false)
        }
    }

    return (
        loading ? <Spinner size="large" />
            : <div className="updateCompanyBalance">
                <div className='goBack' onClick={() => dispatch(move(0))}>
                    <RightOutlined />  <span style={{ position: 'relative', bottom: '2px' }}>رجوع</span>
                </div>
                <Form name="nest-messages" onFinish={updateCompanyBalance}>
                    <Row>
                        <Col xs={24} md={10} lg={10}>
                            <label>المبلغ الجديد (ج.م) <span>*</span></label>
                            <Form.Item
                                name="amount"
                                rules={[{ required: true, message: 'مطلوب' }, { type: "number", min: 1, message: "لا يمكن ان يكون المبلغ الجديد اقل من 1" }]}
                            >
                                <InputNumber placeholder="ادخل المبلغ" onChange={setNewAmount} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4} lg={4} className="btnCol">
                            <Form.Item>
                                <Button shape="round" type="primary" block htmlType="submit" loading={sendingData}>
                                    تحديث
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            {loading ? <Spinner size="large" /> : companyBalance ?
                                <>
                                    <label className="infoLabel">رصيد الشركة</label>
                                    <Descriptions bordered style={{ margin: '15px 0' }}>
                                        <Descriptions.Item label="الرصيد الحالي">{helper.amountFormatter(companyBalance)} EGP</Descriptions.Item>
                                        <Descriptions.Item label="الرصيد الجديد">{helper.amountFormatter(companyBalance + newAmount)} EGP</Descriptions.Item>
                                    </Descriptions>
                                </>
                                : ""}
                        </Col>
                    </Row>
                </Form>
            </div>
    )
}