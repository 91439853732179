import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import DeployCompany from './deployCompany';

class ViewDeployCompany extends Component{
    state = {
        componentSteps: [
            {
                title: 'Get Company',
                content: <GetInfo apiLink='companies/getOneCompany/' isNonDeployedCompanies={true}/>,
                id: 0,
            }, {
                title: 'Deploy Company',
                content: <DeployCompany />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default ViewDeployCompany
