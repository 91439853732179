import React, { Component } from "react";
import DataTable from "../components/dataTable";
import {
  Button, Col, Form,
  //  InputNumber,
  Row, message, DatePicker, Select, Input, Checkbox
} from "antd";
import { FormInstance } from "antd/es/form";
import { ReloadOutlined } from "@ant-design/icons";
import ExportToExcel from "../components/exportExcel";
import { request } from "../stores/actions";
import { connect } from "react-redux";
import Spinner from "../components/spinner";
import OutPutResult from "../components/result";
import moment from "moment";
import helper from "../utils/helper";
import { errorMessage } from "../utils/commonStringsConsts";
import TruckingModal from "./Trucking/modal";
import { changeRequestStatusApi } from "../utils/apisConsts";
const { RangePicker } = DatePicker;

interface Props {
  request: any;
}
enum statusesIDsEnum {
  transferring = 9,
  processing = 2
}
enum sheetsTypesEnum {
  allValid = "all",
  bankMisr = "bankMisr",
  CIB = "CIB",
  meeza = "meeza",
  cash = "cash",
  mobileWallet = "mobileWallet",
  arabyBank = "arabyBank",
  allBanks = "allBanks",
};
enum servicesGroupsEnum {
  SALARY_ADVANCE_AND_MONEY_TRANSFER = 10
};

class ViewRequestsList extends Component<Props> {
  formStatusRef = React.createRef<FormInstance>();
  formFilterRef = React.createRef<FormInstance>();
  formRef: any = React.createRef<any>();
  selectRef: any = React.createRef<FormInstance>();
  commentRef: any = React.createRef<FormInstance>();

  state = {
    requestsList: [
      {
        advanceID: "1",
        userID: "46646",
        userName: "Youssef",
        companyName: "Nowpay",
        amount: "4000",
        dateOfRequest: "2022/2/1",
        transactionStatus: "approved",
        reasonName: "high amount",
        numOfMonths: 3,
        serviceTypeName: "RechargeMobile",
      },
    ],
    tableLoading: false,
    pageLoading: true,
    errorView: false,
    errorMessage: "",
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    columns: [],
    canResetFilter: false,
    showBlockingIssuesCheckBox: false,
    filters: {
      userID: null,
      requestID: null,
      statusID: null,
      dateFrom: null,
      dateTo: null,
      companyID: null,
      deployed: null,
      serviceGroupID: null,
      isACH: false,
    },
    bankMisrData: [],
    cibData: [],
    meezaData: [],
    statuses: [],
    defaultStatusName: '',
    isDownloadable: false,
    currentStatus: 2,
    companies: [],
    deployOptions: [
      { name: 'All Companies', id: null },
      { name: 'Not Deployed', id: '0' },
      { name: 'Deployed', id: 1 },
    ],
    selectedCompany: false,
    selectedDeployType: false,
    serviceTypes: [],
    serviceGroupIDSelected: 0,
    modalOn: false,
    selectedStatus: 0,
    advanceID: 0,
    changeStatusBtn: false,
    sheetsData: {
      allValid: [],
      bankMisr: [],
      CIB: [],
      meeza: [],
      cash: [],
      mobileWallet: [],
      arabyBank: [],
    },
    statusFilterSelected: 2,

  };

  storeRequestStatuses = async () => {
    const requestStatuses: any = await helper.getRequestsStatuses();
    if (requestStatuses.data.status === 200) {
      this.setState({
        statuses: requestStatuses.data.advanceStatus,
        // isDownloadable: true,
        defaultStatusName: requestStatuses.data.advanceStatus.filter((name: any) => name.advancesStatusTypeID === 2)[0].StatusType
      });
      return
    }
    message.error(errorMessage);

  }

  storeCompanies = async () => {
    const getCompanies: any = await helper.getCompanies();
    if (getCompanies.data.status === 200) {
      this.setState({ companies: getCompanies.data.data })
      return
    }
    message.error(errorMessage);
  }

  storeServiceTypes = async () => {

    try {
      const getServiceTypes: any = await helper.getServiceTypes();
      if (getServiceTypes.status !== 200) {
        message.error(`${errorMessage} with service types`);
        return
      }
      this.setState({ serviceTypes: getServiceTypes?.data?.data?.servicesGroups });
    } catch (error) {
      message.error(`${errorMessage} with service types`);
    }


  }
  getRequests = async (sheetType?: string) => {
    const date = new Date()
    date.setDate(date.getDate() - 30)
    const params = {
      ...(!sheetType && {
        ...this.state.filters,
        requestStatusID: this.state.statusFilterSelected ? this.state.statusFilterSelected : statusesIDsEnum.processing,
        rowsPerPage: this.state.pagination.pageSize,
        pageNum: this.state.pagination.current,
      }),
      ...(sheetType && {
        ...((sheetType !== sheetsTypesEnum.allValid) && { sheetType }),
        isACH: this.state.filters.isACH,
        dateFrom: this.state.filters.dateFrom,
        dateTo: this.state.filters.dateTo,
        requestStatusID: this.state.statusFilterSelected === statusesIDsEnum.transferring ? statusesIDsEnum.transferring : statusesIDsEnum.processing
      }),
    };
    try {
      !sheetType && this.setState({ tableLoading: true });
      const res = await this.props.request({
        url: "/requests/getAllRequests",
        method: "GET",
        params,
      });

      if (res.data.status === 200) {

        // this.setState({ columns });
        if (sheetType) {
          return res.data.advances
        }
        let listOFRequests = Array.isArray(res.data.advances) && res.data.advances.map((requests: any) => {
          return {
            ...requests,
            amount: helper.amountFormatter(requests?.amount) + ' EGP',
            recipientPhone: requests?.recipientPhone ? requests?.recipientPhone : 'NA',
            recipientName: requests?.recipientName ? requests?.recipientName : 'NA',
            bankSwiftCode: requests?.bankSwiftCode ? requests?.bankSwiftCode : 'NA'
          }

        })
        this.setState({
          pageLoading: false,
          tableLoading: false,
          requestsList: listOFRequests,
          pagination: {
            ...this.state.pagination,
            total: res.data.advanceCount,
          },
          // isDownloadable: this.state.currentStatus === 2 || this.state.currentStatus === 9 ? true : false
        });
        // this.generateSheetsObjects(res.data.advances);
      } else {
        this.setState({
          errorMode: true,
          pageLoading: false,
          tableLoading: false,
          errorMessage: res.data.message,
        });
      }
    } catch (e) {
      this.setState({
        errorView: true,
        pageLoading: false,
        tableLoading: false,
      });
    }
  };

  generateSheetsObjects = async (bankType: sheetsTypesEnum) => {
    const banks = [sheetsTypesEnum.CIB, sheetsTypesEnum.bankMisr, sheetsTypesEnum.arabyBank];
    const data = await this.getRequests(
      bankType === sheetsTypesEnum.meeza ? sheetsTypesEnum.meeza :
        bankType === sheetsTypesEnum.mobileWallet ? sheetsTypesEnum.mobileWallet :
          bankType === sheetsTypesEnum.cash ? sheetsTypesEnum.cash :
            [...banks, sheetsTypesEnum.allBanks].includes(bankType) ? 'bank' : sheetsTypesEnum.allValid
    );

    if (bankType === sheetsTypesEnum.allBanks) { // to reduce number of called requests
      // The return order [sheetsTypesEnum.CIB, sheetsTypesEnum.bankMisr, sheetsTypesEnum.arabyBank]
      return banks.map((sheetType: sheetsTypesEnum) => this.formalizeBankSheetData(data, sheetType));
    } else {
      return this.formalizeBankSheetData(data, bankType);
    }
  };

  formalizeBankSheetData(data: any, bankType: sheetsTypesEnum) {
    const sheet = [];
    if (data) {
      if (!data.length) { return []; }
      if (bankType === sheetsTypesEnum.cash) { return data; }

      for (let i = 0; i < data.length; i++) {
        switch (bankType) {
          case sheetsTypesEnum.CIB: {
            sheet.push({
              TransactionID: data[i]?.advanceID,
              CreditorName: data[i]?.accountHolderName,
              CreditorAccountNumber: data[i]?.accountNumber,
              CreditorBank: data[i]?.bankName,
              CreditorBankBranch: "",
              TransactionAmount: Number(data[i]?.amount),
              TransactionPurpose: "",
              Comments: data[i]?.comment.replace(/&/g, ''),
              ReceiverEmail: "",
              SMSMobileNumber: data[i]?.phone,
            });

            break;
          }
          case sheetsTypesEnum.bankMisr: {
            sheet.push({
              InstructionID: data[i]?.advanceID,
              EndtoEndId: "",
              NationalID: "",
              CreditorName: data[i]?.accountHolderName,
              CreditorAccountNumber: data[i]?.accountNumber,
              CreditorBank: data[i]?.bankName,
              CreditorBankBranch: "",
              TransactionAmount: Number(data[i]?.amount),
              TransactionPurpose: "",
              Comments: data[i]?.comment.replace(/&/g, ''),
              Email: "",
            });

            break;
          }
          case sheetsTypesEnum.meeza: {
            sheet.push({
              NationalID: data[i]?.NationalID,
              CreditorName: data[i]?.CreditorName,
              CreditorAccountNumber: data[i]?.CreditorAccountNumber,
              CreditorBank: data[i]?.CreditorBank,
              CreditorBankBranch: data[i]?.CreditorBankBranch,
              TransactionAmount: Number(data[i]?.TransactionAmount),
              Comments: data[i]?.Comments.replace(/&/g, ''),
              Email: data[i]?.Email,
              Mobile: data[i]?.Mobile,
              SMS: data[i]?.SMS,
            });

            break;
          }
          case sheetsTypesEnum.arabyBank: {
            sheet.push({
              'Payment Serial Number': i + 1,//
              'Transaction Reference': data[i]?.TransactionReference?.toString().replace(/\(\)&-,_/g, ' '),//nothing
              'Customer Reference No': data[i]?.advanceID?.toString().replace(/\(\)&-,_/g, ' '),//nothing
              'Debit Account No.': '5004646277439',
              'Value Date': helper.replaceTheDate('-', Date()),
              'Transaction Currency': 'EGP',
              'Payment Amount': Number(data[i]?.amount),
              'Beneficiary Bank Swift Code / IFSC Code': data[i]?.bankSwiftCode?.toString().replace(/\(\)&-,_/g, ' '),
              'Beneficiary Name': data[i]?.beneficiaryName?.toString().replace(/\(\)&-,_/g, ' ') ?? '',
              'Beneficiary Account No.': data[i]?.accountNumber?.toString().replace(/\(\)&-,_\s/g, ''),
              'Beneficiary Addr. Line 1': data[i]?.BeneficiaryAddrLine1,// empty
              'Beneficiary Addr. Line 2': data[i]?.BeneficiaryAddrLine2,// empty
              'Beneficiary Addr. Line 3': data[i]?.BeneficiaryAddrLine3,// empty
              'Purpose Code': '19',
              'Purpose Of Payment': data[i]?.companyID?.toString().replace(/\(\)&-,_/g, '') + " " + data[i]?.advanceID.toString().replace(/\(\)&-,_/g, ''),
              'Charge Account No': '5004646277439',
              'Charge Type': 'SHA',
              'Deal Reference No': data[i]?.DealReferenceNo?.toString().replace(/\(\)&-,_/g, ' '), //empty
              'Payment Type': 'ACH',
            });

            break;
          }
          case sheetsTypesEnum.mobileWallet: {
            sheet.push({
              Name: data[i]?.recipientName,
              Amount: Number(data[i]?.amount),
              Phone: data[i]?.recipientPhone,
              Bank: "MDIGEGCX"
            });

            break;
          }
          case sheetsTypesEnum.allValid: {
            sheet.push({
              requestID: data[i]?.advanceID,
              moneyDeductedBefore: data[i]?.moneyDeductedBefore,
              staffID: data[i]?.staffID,
              phone: data[i]?.phone,
              username: data[i]?.accountHolderName,
              companyName: data[i]?.companyName,
              bankName: data[i]?.bankName,
              bankAccountNumber: data[i]?.accountNumber,
              amount: Number(data[i]?.amount),
              userCurrentBalance: Number(data[i]?.userCurrentBalance),
              typeOfService: data[i]?.serviceTypeName,
              userStatus: data[i]?.userStatus,
              deductionsNo: data[i]?.numOfMonths,
              date: data[i]?.dateOfRequest,
              comment: data[i]?.comment.replace(/&/g, ''),
            });

            break;
          }
          default: {
            sheet.push({
              InstructionID: data[i]?.advanceID,
              EndtoEndId: "",
              NationalID: data[i]?.accountNumber,
              CreditorName: data[i]?.userName,
              CreditorAccountNumber: "100203500381",
              CreditorBank: data[i]?.bankName,
              CreditorBankBranch: "",
              TransactionAmount: Number(data[i]?.amount),
              TransactionPurpose: "",
              Comments: data[i]?.comment.replace(/&/g, ''),
              Email: "",
            });

            break;
          }
        }
      }
    }

    return sheet;
  }

  handlePagination = (e: any) => {
    this.setState({ loading: true, pagination: e }, () => this.getRequests());
  };

  handleSearch = (values: any) => {

    if (
      !values.userID &&
      !values.requestID &&
      !values.statusID &&
      !values.reasonID &&
      !values.dateRange &&
      !values.requestStatusID &&
      !values.serviceGroupID
    ) {
      message.error(`You can't search on nothing`, 4);
      return;
    }
    this.setState(
      {
        statusFilterSelected: typeof values.requestStatusID === 'number' ? values.requestStatusID : 2,
        filters: {
          ...this.state.filters,
          isACH: (values.serviceGroupID === servicesGroupsEnum.SALARY_ADVANCE_AND_MONEY_TRANSFER) && values.isACH,
          ...(values.userID ? { userID: values.userID } : { userID: null }),
          ...(values.requestID
            ? { requestID: values.requestID }
            : { requestID: null }),
          ...(values.statusID
            ? { statusID: values.statusID }
            : { statusID: null }),
          ...(values.requestStatusID
            ? { requestStatusID: values.requestStatusID === 'Processing' ? undefined : values.requestStatusID }
            : { requestStatusID: null }),
          ...(values.serviceGroupID
            ? { serviceGroupID: values.serviceGroupID }
            : { serviceGroupID: null }),
          ...(values.companyID
            ? { companyID: values.companyID }
            : { companyID: null }),
          ...(values.deployed
            ? { deployed: values.deployed === 'All Companies' ? undefined : values.deployed }
            : { deployed: null }),
          ...(values.reasonID
            ? { reasonID: values.reasonID }
            : { reasonID: null }),
          ...(values.dateRange
            ? {
              dateFrom: moment(values.dateRange[0]._d).format(
                "YYYY/MM/DD 00:00:00"
              ),
              dateTo: moment(values.dateRange[1]._d).format(
                "YYYY/MM/DD 23:59:59"
              ),
            }
            : { dateFrom: null, dateTo: null }),
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      async () => {
        this.getRequests().then(() => {
          this.setState({
            canResetFilter: true,
            // isDownloadable: this.state.currentStatus === 2 ? true : false 
          });
        });

        if (values.serviceGroupID === servicesGroupsEnum.SALARY_ADVANCE_AND_MONEY_TRANSFER) {
          await this.setSheetsData();
        }

      }
    );
  };

  async setSheetsData() {
    const [
      allValid,
      meeza,
      cash,
      mobileWallet,
      [CIB, bankMisr, arabyBank],
    ] = await Promise.all([ // handles fetching all sheets data at once (concurrently)
      this.generateSheetsObjects(sheetsTypesEnum.allValid),
      this.generateSheetsObjects(sheetsTypesEnum.meeza),
      this.generateSheetsObjects(sheetsTypesEnum.cash),
      this.generateSheetsObjects(sheetsTypesEnum.mobileWallet),
      this.generateSheetsObjects(sheetsTypesEnum.allBanks),
    ]);
    this.setState({
      sheetsData: {
        allValid,
        bankMisr,
        CIB,
        meeza,
        cash,
        mobileWallet,
        arabyBank,
      }
    });



  }

  getSheetData(sheetType: sheetsTypesEnum) {
    switch (sheetType) {
      case sheetsTypesEnum.allValid: {
        if (this.state.sheetsData.allValid?.length) { return this.state.sheetsData.allValid; } break;
      }
      case sheetsTypesEnum.bankMisr: {
        if (this.state.sheetsData.bankMisr?.length) { return this.state.sheetsData.bankMisr; } break;
      }
      case sheetsTypesEnum.CIB: {
        if (this.state.sheetsData.CIB?.length) { return this.state.sheetsData.CIB; } break;
      }
      case sheetsTypesEnum.meeza: {
        if (this.state.sheetsData.meeza?.length) { return this.state.sheetsData.meeza; } break;
      }
      case sheetsTypesEnum.cash: {
        if (this.state.sheetsData.cash?.length) { return this.state.sheetsData.cash; } break;
      }
      case sheetsTypesEnum.mobileWallet: {
        if (this.state.sheetsData.mobileWallet?.length) { return this.state.sheetsData.mobileWallet; } break;
      }
      case sheetsTypesEnum.arabyBank: {
        if (this.state.sheetsData.arabyBank?.length) { return this.state.sheetsData.arabyBank; } break;
      }
    }

    message.error(`Can't download empty file`);
    return [];
  }

  onSearchFailed = (error: any) => {
    console.log(
      "🚀 ~ file: viewRequestsList.tsx ~ line 77 ~ ViewRequestsList ~ error",
      error
    );
  };

  refreshPage = () => {
    window.location.reload();
  };

  clearFilter = () => {
    this.setState(
      {
        loading: true,

        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        filters: {
          userID: null,
          requestID: null,
          statusID: null,
          reasonID: null,
          companyID: null,
          deployed: null,
          serviceGroupID: null,
          isACH: false,
        },
        canResetFilter: false,
        showBlockingIssuesCheckBox: false,
        currentStatus: 2,
      },
      () => this.getRequests()
    );
    this.formFilterRef.current!.resetFields();
  };

  async componentDidMount() {

    await this.storeRequestStatuses()
    await this.storeCompanies();
    await this.storeServiceTypes()
    await this.getRequests()

  }
  changeStatus = (statusID: number) => {
    this.setState({ currentStatus: statusID })
  }
  changeCompany = (value: number) => {
    if (value) {
      this.setState({ selectedCompany: true });
      return

    }
    this.setState({ selectedCompany: false });
  }
  changeIsACH = () => {
    this.setState({ filters: { ...this.state.filters, isACH: !this.state.filters.isACH } });
  }
  changeDeployedType = (value: number) => {
    if (value) {
      this.setState({ selectedDeployType: true });
      return

    }
    this.setState({ selectedDeployType: false });
  }

  getOneServiceType = (service: number) => {
    this.setState({ serviceGroupIDSelected: service, canResetFilter: false })

    if (service === servicesGroupsEnum.SALARY_ADVANCE_AND_MONEY_TRANSFER) {
      this.setState({
        filters: { ...this.state.filters, isACH: true },
        showBlockingIssuesCheckBox: true,
      });
    } else { this.setState({ showBlockingIssuesCheckBox: false }); }
  }
  changeRequestStatus = (advanceID: number, statusRequestID: any) => {


    this.setState({ modalOn: true, selectedStatus: statusRequestID, advanceID: advanceID })
  }
  closeModal = () => {
    this.setState({ modalOn: false });
    this.commentRef.current?.resetFields();
    this.state.advanceID ? this.formRef.current[this.state.advanceID]!.resetFields() : this.formFilterRef.current!?.resetFields();

  }
  submitNewRequestStatus = async (values: { comment: string }) => {
    this.setState({ changeStatusBtn: true })
    const data = {
      newStatus: this.state.selectedStatus,
      advanceIDs: [this.state.advanceID],
      comment: values.comment,
    }
    try {
      const apiData: any = await this.props.request({
        url: changeRequestStatusApi, method: "PATCH",
        data: data,
      })
      const resp = apiData.data;
      if (resp.status === 200) {
        message.success(resp.message);
        this.getRequests();
        this.setState({ changeStatusBtn: false, modalOn: false });
        this.commentRef.current.resetFields();
        this.state.advanceID ? this.formRef.current[this.state.advanceID]!.resetFields() : this.formFilterRef.current!?.resetFields();

        return
      }
      message.error(resp.message);
    } catch (error: any) {
      message.error(error.response.data.message[0]);
      this.setState({ changeStatusBtn: false })
    }
  }

  getStatusByStatusID = (advancesStatusTypeID: number): string => {

    const status: any = this.state.statuses.find((status: any) => status.advancesStatusTypeID === advancesStatusTypeID);
    if (status) {
      return status.StatusType;
    }
    return '';
  };

  render() {
    const {
      showBlockingIssuesCheckBox,
      requestsList, tableLoading, pagination, canResetFilter, pageLoading, errorView, errorMessage, statuses, modalOn, changeStatusBtn,
      defaultStatusName,
      //  isDownloadable,
      companies, deployOptions, selectedCompany, selectedDeployType, serviceTypes, serviceGroupIDSelected
    } = this.state;

    const dateFormat = "YYYY/MM/DD";
    console.log(deployOptions, selectedCompany);

    let columns = helper.generateTableColumns(this.state.requestsList);
    const addStatusSelection: any = {
      title: 'Change Request Status',
      key: 'changeStatus',
      width: 200,
      render: (_text: any, record: any) => <>
        {<Form name="nest-messages"

          ref={(el: any) => {
            if (!Array.isArray(this.formRef.current)) {
              this.formRef.current = [];
            }
            this.formRef.current[record.advanceID] = el
          }}>
          {<Form.Item
            name={"status" + record.advanceID}
          >
            <Select style={{ width: "100%" }} placeholder="Change Status" onChange={(e: any) => this.changeRequestStatus(record.advanceID, e)} >
              {

                Array.isArray(record.validStatuses) &&
                record.validStatuses.map((status: any) => {
                  return (status.isValid && <Select.Option allowClear value={status.advancesStatusTypeID} key={status.advancesStatusTypeID}>{status.StatusType}</Select.Option>)
                })
              }

            </Select>
          </Form.Item>}
        </Form>}


      </>
    }
    columns = [addStatusSelection, ...columns];


    const content = <>
      <div className='declineRequest'>
        <Form
          ref={this.commentRef}
          name="changeStatus"
          initialValues={{ remember: true }}
          onFinish={this.submitNewRequestStatus}
          autoComplete="off"
        >
          <p className='question' style={{ textAlign: 'left', fontWeight: 600, fontSize: '16px' }}>Are you sure to change the transaction status?</p>

          {<> <label htmlFor="">Comment </label>
            <Form.Item name='comment'>
              <Input.TextArea placeholder='Why you want to change the status ? ' style={{ width: '100%' }} />
            </Form.Item></>}
          <Row style={{ margin: '20px 0' }}>
            <Col span={24}>
              <Form.Item>
                <Button className='decline' onClick={this.closeModal}>No </Button>
                <Button className='approve' htmlType="submit" loading={changeStatusBtn}>Yes</Button>
              </Form.Item>
            </Col>

          </Row>

        </Form>
      </div>
    </>
    return pageLoading ? (
      <Spinner size="large" />
    ) : errorView ? (
      <OutPutResult status={"500"} title={"Oops!"}
        subTitle={
          errorMessage
            ? errorMessage
            : "Something went wrong, please refresh the page and try again"
        }
        btnTitle={"Refresh the page"}
        btnClick={this.refreshPage}
      />
    ) : (
      <div className="requestsList">
        <Form
          ref={this.formFilterRef}
          name="basic"
          onFinish={this.handleSearch}
          onFinishFailed={this.onSearchFailed}
          autoComplete="off"
        >
          <Row className="statusRow">
            <Col md={6}>
              <label htmlFor="">Date </label>
              <Form.Item name="dateRange">
                <RangePicker format={dateFormat} />
              </Form.Item>
            </Col>
            {/* <Col md={6}>
              <label htmlFor="">User ID </label>
              <Form.Item name="userID">
                <InputNumber placeholder="Search by user id" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label htmlFor="">Request ID </label>
              <Form.Item name="requestID">
                <InputNumber placeholder="Search by request id" />
              </Form.Item>
            </Col> */}
            <Col md={6}>
              <label htmlFor="">Statues </label>
              <Form.Item name="requestStatusID"
                initialValue={defaultStatusName}
              >
                <Select style={{ width: "100%" }} placeholder="Select Status" allowClear showSearch
                  onChange={this.changeStatus}
                  filterOption={((input, option: any) => helper.dropDownSearch(input, option))}
                >
                  {

                    Array.isArray(statuses) &&
                    statuses.map((status: any, key: any) => {
                      return <Select.Option value={status.advancesStatusTypeID} key={key}>{status.StatusType}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
            </Col>

            <Col md={6}>
              <label htmlFor="">Service Groups </label>
              <Form.Item name="serviceGroupID">
                <Select style={{ width: "100%" }} placeholder="Select Service Group" allowClear showSearch
                  onChange={this.getOneServiceType}
                  filterOption={((input, option: any) => helper.dropDownSearch(input, option))}>
                  {
                    Array.isArray(serviceTypes) &&
                    serviceTypes.map((service: any, key: any) => {
                      return <Select.Option value={service.id} key={key}>{service.name}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
            </Col>

            {/* <Col md={6}>
              <label htmlFor="">Deploy Companies </label>
              <Form.Item name="deployed" initialValue={'All Companies'}>
                <Select style={{ width: "100%" }} disabled={selectedCompany} defaultValue={undefined} placeholder="Select" allowClear onChange={this.changeDeployedType}>
                  {

                    Array.isArray(deployOptions) &&
                    deployOptions.map((option: any, key: any) => {
                      return <Select.Option value={option.id} key={key}>{option.name}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
            </Col> */}

            <Col md={6}>
              <label htmlFor="">Companies </label>
              <Form.Item name="companyID" >
                <Select style={{ width: "100%" }} placeholder="Select Company" disabled={selectedDeployType} showSearch allowClear
                  filterOption={((input, option: any) => helper.dropDownSearch(input, option))}
                  onChange={this.changeCompany}>
                  {

                    Array.isArray(companies) &&
                    companies.map((company: any, key: any) => {
                      return <Select.Option value={company.id} key={key}>{company.name}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
            </Col>
            {showBlockingIssuesCheckBox && (
              <Col md={6}>
                <Form.Item name="isACH" valuePropName="checked" initialValue={this.state.filters.isACH}>
                  <div style={{ display: 'flex', paddingTop: '27px' }}>
                    <Checkbox style={{ width: '15%' }} checked={this.state.filters.isACH} onChange={this.changeIsACH} />
                    <label>  No Blocking Issues</label>
                  </div>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row className="statusRow" style={{ float: "right" }}>


            <Col style={{ float: "right", margin: "5px 0 0 0" }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#357C3C", borderColor: "#357C3C" }}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            {canResetFilter && (
              <Col style={{ float: "right", margin: "17px 0 0 0" }}>
                <Form.Item>
                  <Button danger onClick={this.clearFilter}>
                    Clear filter
                  </Button>
                </Form.Item>
              </Col>
            )}
            {(
              requestsList.length !== 0 &&
              [servicesGroupsEnum.SALARY_ADVANCE_AND_MONEY_TRANSFER].includes(serviceGroupIDSelected) &&
              canResetFilter && (
                this.state.statusFilterSelected === statusesIDsEnum.processing ||
                this.state.statusFilterSelected === statusesIDsEnum.transferring)
            ) && (

                <Col>
                  <ExportToExcel
                    fileName={`${this.getStatusByStatusID(this.state.statusFilterSelected)} All valid requests`}
                    otherSheets={[
                      {
                        name: "All valid requests",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)} All valid requests`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.allValid),
                      },
                      {
                        name: "Bank misr Requests list",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)}  Bank misr Requests list`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.bankMisr),
                      },
                      {
                        name: "CIB Requests list",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)} CIB Requests list`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.CIB),
                      },
                      {
                        name: "Meeza Requests list",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)} Meeza Requests list`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.meeza),
                      },
                      {
                        name: "Cash Requests list",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)} Cash Requests list`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.cash),
                      },
                      {
                        name: "Mobile Wallet Requests list",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)} Mobile Wallet Requests list`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.mobileWallet),
                      },
                      {
                        name: "Araby Bank Requests list",
                        statusName: `${this.getStatusByStatusID(this.state.statusFilterSelected)} Araby Bank Requests list`,
                        onClick: () => this.getSheetData(sheetsTypesEnum.arabyBank),
                      }
                      //Araby Bank type is here
                    ]}
                  />
                </Col>
              )}
            <Col style={{ float: "right", margin: "19px 0 0 0" }}>
              <Form.Item>
                <ReloadOutlined
                  style={{ fontSize: "20px" }}
                  onClick={this.refreshPage}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <DataTable
          columns={columns}
          rows={requestsList}
          handleTableChange={this.handlePagination}
          pagination={pagination}
          loading={tableLoading}
          scrolling={{ x: 'max-content' }}
        />
        <TruckingModal handleCloseModal={this.closeModal} visibleModal={modalOn} content={content} title={'Change request status'} />

      </div>
    );
  }
}

export default connect(null, { request })(ViewRequestsList);