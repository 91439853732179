import { message, Select } from 'antd';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TimerTimeOut, postApi } from "../stores/actions";
import helper from '../utils/helper';
import { errorMessage, otpSentSuccessfully } from '../utils/commonStringsConsts';
import { sendOTPApi } from '../utils/apisConsts';
interface props {
    postApi: any,
    TimerTimeOut: any,
    time_out: boolean,
    time: number,
    phone: string,
    twilio?: boolean,
    userLang?: ("ar" | "en")
    setLoading?: any,
    otpModalVisible?: boolean,
}
const { Option } = Select;
class Timer extends Component<props>{
    private timer: any;
    state = {
        time: this.props.time,
    };

    decrementTimeRemaining = () => {
        if (this.state.time > 0) {
            this.setState({
                time: this.state.time - 1
            });
        } else {
            clearInterval(this.timer!);
            this.props.TimerTimeOut(true);
        }
    };

    componentDidMount() {
        this.props.TimerTimeOut(false);
        this.timer = setInterval(() => {
            this.decrementTimeRemaining();
        }, 1000);
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.otpModalVisible && this.props.otpModalVisible !== prevProps.otpModalVisible) {
            this.setState({ time: 30 }, () => {
                this.timer = setInterval(() => {
                    this.decrementTimeRemaining();
                }, 1000);
                setTimeout(() => {
                    clearInterval(this.timer);
                    this.props.TimerTimeOut(true);
                }, 30000);
            })
        }
        if (!this.props.otpModalVisible && this.props.otpModalVisible !== prevProps.otpModalVisible) {
            clearInterval(this.timer);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
    reSendCode = async (option: (0 | 1 | 2 | 3)) => {
        const info = { phone: this.props.phone };
        this.props.setLoading(true);
        try {
            if (option === 0 || option === 3) {
                try {
                    await helper.callApiRequest(sendOTPApi, 'POST', '', info);
                    message.success(otpSentSuccessfully);
                    this.reTimer();
                } catch (error: any) {
                    error.status !== 404 ? message.error(error?.data?.message[0]) : message.error(errorMessage);
                }
            }
            else {
                const res: any = await helper.sendOtpTwilio(this.props.phone, option, this.props.userLang ? this.props.userLang : "en")
                if (res.status === 200) {
                    message.success(res.message);
                    this.reTimer()
                }
                else {
                    message.error(res.message)
                }
            }
        } catch (error) {
            message.error('Something went wrong')
        }

        this.props.setLoading(false)

    }
    reTimer = () => {
        this.setState({ time: 30 })
        this.props.TimerTimeOut(false);
        this.timer = setInterval(() => {
            this.decrementTimeRemaining();
        }, 1000);
    }
    render() {
        const { time } = this.state;
        const { time_out, twilio } = this.props;

        return (
            <div>
                <div className="timer">
                    {!time_out && <p className={time !== 0 ? 'counter' : ''}>(00 : {time >= 10 ? time : ` 0${time}`})</p>}
                    {time_out && <><p className='asking'>Didn't get the code?</p>
                        {twilio ?
                            <div className='options'>
                                <span>Resend OTP via</span>
                                <Select
                                    placeholder="Select an option"
                                    onChange={this.reSendCode}
                                >
                                    <Option value={0}>SMS</Option>
                                    <Option value={1}>WhatsApp</Option>
                                    {/* <Option value={2}>Voice Call</Option> */}
                                    <Option value={3}>SMS via Amazon</Option>
                                </Select>
                            </div>
                            : <>
                                <h4 className='resending' onClick={() => this.reSendCode(0)}>Resend Code</h4>
                                <h4 className='resending' style={{ fontWeight: 600 }} onClick={() => this.reSendCode(1)}>Resend Code via WhatsApp</h4>
                            </>}
                    </>}
                </div>
            </div>
        )
    }
}
const mapStateToProp = (state: any) => ({
    time_out: state.auth.time_out,
    otpModalVisible: state.actionsReducer.otpModalVisible

})
export default connect(mapStateToProp, { TimerTimeOut, postApi })(Timer)
