import { useSelector } from 'react-redux';
import ViewDrivers from '../add-expenses/viewDrivers';
import Salary from './salary';

interface IRootState {
    [key: string]: {
      step:number
    };
  }
function PayrollRequest() {
    const stepper: any = useSelector<IRootState>(state => state.truckingReducer.step);
    
    return (
        <>
            {stepper === 0 ? <ViewDrivers /> : <Salary />}
        </>
    )
}

export default PayrollRequest
