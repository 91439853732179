import { Descriptions, Form, Button, Col, Input, Row, Select, FormInstance, Switch, message, Divider, Spin, Collapse, Tooltip } from 'antd'
import Helper from '../utils/helper';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommanDrawer from '../components/drawer';
import FewRequests from '../components/fewRequests';
import OutPutResult from '../components/result';
import Spinner from '../components/spinner';
import { getApi, postApi, stepperMoving } from '../stores/actions';
import { getUserFreePeriodsApis } from '../utils/apisConsts';
import CancelRequest from './cancelRequest';
import CreateRequest from './createRequest';
import { InfoCircleOutlined } from '@ant-design/icons';
import SendUserOtp from './sendUserOtp';
import helper from '../utils/helper';
import { FalseIcon, TrueIcon } from '../utils/commonIcons';
import CollectionPlans from './collectionPlans';
import TruckingModal from './Trucking/modal';
// import { CallCenterModules } from '../utils/commonInterfaces';
import { errorMessage } from '../utils/commonStringsConsts';

const { Option } = Select;
const { Panel } = Collapse;
interface Props {
    info?: { name: string, staff_id: number, companyName: string, userID: number, phone: string },
    getApi: any,
    postApi: any,
    stepperMoving: any,
}
class CallInfo extends Component<Props> {
    formRef = React.createRef<FormInstance>();

    state = {
        subDrawer: false,
        drawerLayout: null,
        drawerChildLayout: null,
        subChildDrawer: false,
        visibleModal: false,

        status: [],
        reasons: [],
        modules: [],
        details: [],
        teamName: '',
        errorMode: false,
        loadData: false,
        errorMessage: '',
        title: '',
        sendCallDataSpin: false,
        titleChild: '',
        otherComment: false,
        drawerWidth: '',
        teamID: 0,
        seconds: 0,
        callTypes: [{ id: 1, name: 'Call' }, { id: 2, name: 'Whats App' }],
        outboundCall: false,
        outboundData: false,
        freePeriodsData: {
            freePeriodsEndDate: '',
            companyDeploymentDate: '',
            companyFreePeriod: '',
            userFirstRequestDate: '',
            userVIPStartDate: '',
            userVIPFreePeriod: '',
            companyUsersFreePeriod: '',
            companyUsersFreePeriodStarted: null,
            cutOffDay: '',
            freePeriodOnDeductions: ""
        },
    }
    private time: any;


    async componentDidMount() {
        this.timer();
        await this.getDropDawns('/callCenter/getCallCenterStatuses', 'status');
        await this.getDropDawns(`/callCenter/getCallCenterReasons?outboundCall=${this.state.outboundCall}`, 'reasons');
        await this.getDropDawns(`/callCenter/getCallCenterModules?outboundCall=${this.state.outboundCall}`, 'modules');
        await this.getUserFreePeriods();
    }
    getDropDawns = async (apiLink: string, dynamicState: string, shouldLoad?: boolean) => {
        this.setState({ loadData: !shouldLoad ? false : true })
        const drops = await this.props.getApi(apiLink);
        const res = await drops.payload;
        try {
            if (res.status === 200) {
                this.setState({ [dynamicState]: res.data, loadData: false, outboundData: false })
                this.formRef.current!.setFieldsValue({ details: null, reason: null, module: null });

            } else {
                this.setState({ loadData: false, outboundData: false, errorMode: true })
            }
        } catch (error) {
            this.setState({ loadData: false, outboundData: false, errorMode: true })
        }
    }
    openDrawer = (layout: any, title: string) => {
        this.setState({ subDrawer: true, drawerLayout: layout, title: title, drawerWidth: 1000 })
    }
    closeDrawer = () => {
        this.setState({ subDrawer: false })
    }
    openChildDrawer = (layout: any, title: string) => {
        this.setState({ subChildDrawer: true, drawerChildLayout: layout, titleChild: title, drawerWidth: 1000 })
    }
    closeChildDrawer = () => {
        this.setState({ subChildDrawer: false, drawerWidth: 985 })
    }
    endCall = async (value: any) => {
        console.log(value);

        let userId = this.props.info;
        const data = {
            userID: userId && userId.userID,
            statusID: value.status,
            callComment: value.comment,
            reasons: [value.reason],
            details: [value.details],
            detailComment: value.otherComment,
            openZoho: value.openZohoTicket,
            callInterrupted: value.callInteracted,
            teamID: this.state.teamID,
            duration: this.state.seconds,
            callType: value.callType,
            outboundCall: value.outboundCall,
        }
        this.setState({ sendCallDataSpin: true })
        const endingCall = await this.props.postApi('/callCenter/addCallInfo', data);

        try {
            const res = endingCall.payload;
            if (res?.status === 200) {
                console.log(res);
                this.setState({ sendCallDataSpin: false });
                message.success(res.message)
                this.props.stepperMoving(0)
                this.onReset();
            }
            else {
                this.setState({ sendCallDataSpin: false })
                if (res?.message) message.error(res?.message, 5)
                else message.error(errorMessage, 5)
            }
        } catch (error) {
            this.setState({ sendCallDataSpin: false })
            console.log(error);
            message.error('Something went wrong, please try again');

        }

    }
    refreshPage = () => {
        window.location.reload();
    }
    onOtherSelected = (value: any, detail: any) => {
        console.log(detail.name.name);

        if (detail.name.name.includes('Other')) {
            this.setState({ otherComment: true })
            return
        }
        this.setState({ otherComment: false })

    }
    gettingDetails = (selectId: number, module: any) => {
        // if (this.state.outboundCall && selectId === CallCenterModules.willPay) this.setState({ visibleModal: true });
        this.setState({ details: module.module.CallCenterDetails, teamName: module.module.team, teamID: module.module.teamID });
        this.formRef.current!.setFieldsValue({ details: null });
    }
    onStart = () => {
        this.setState({ seconds: this.state.seconds + 1 });
    }
    timer = () => {
        this.time = setInterval(this.onStart, 1000);
    }
    onReset = () => {
        clearInterval(this.time);
        this.setState({ seconds: 0 })
    }

    outBoundChange = (e: boolean) => {
        this.setState({ outboundCall: e, outboundData: true }, async () => {
            await this.getDropDawns(`/callCenter/getCallCenterReasons?outboundCall=${this.state.outboundCall}`, 'reasons', false);
            await this.getDropDawns(`/callCenter/getCallCenterModules?outboundCall=${this.state.outboundCall}`, 'modules', false);
        });

    }
    getUserFreePeriods = async () => {
        const freePeriods = await this.props.getApi(`${getUserFreePeriodsApis}?userID=${this.props.info?.userID}`);
        const res = await freePeriods.payload;
        try {
            if (res.status === 200) {
                console.log(res.data);
                this.setState({ freePeriodsData: res.data })
            } else {
                console.log('error');

            }
        } catch (error) {
            console.log(error);

        }

    }
    handleToggleTheModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal });
    }

    render() {
        const { subDrawer, drawerLayout, loadData, errorMode, errorMessage, otherComment, drawerWidth, visibleModal,
            status, reasons, title, sendCallDataSpin, subChildDrawer, drawerChildLayout,
            titleChild, modules, details, teamName, callTypes, outboundData, freePeriodsData } = this.state;
        const { info } = this.props;
        const requests = <>
            <div className="requests">

                <Divider orientation="left">Last Requests</Divider>
                <FewRequests userID={info} count={3} /><br />
                <div className="linksCollection">
                    <Button className='reqActions' onClick={() => this.openDrawer(<CreateRequest childComponent={true} />, 'Create Request')}>Create Request
                    </Button> <br />
                    <Button className='reqActions' onClick={() => this.openDrawer(<CancelRequest />, 'Cancel Request')}>Cancel Request
                    </Button><br />
                    <Button style={{ padding: '0' }} onClick={() => helper.setRedirect(`https://dashboard.nowpay.cloud/question/1886?UserID=${info && info.userID}`)} type="link">View All Requests</Button>
                </div>
            </div>

        </>
        const userInfo = <div className='userInfo'>
            <Divider orientation="left">User Information</Divider>

            {info && <Descriptions bordered>
                {info.name && <Descriptions.Item span={3} label="Name">{info.name}</Descriptions.Item>}
                {info.userID && <Descriptions.Item span={3} label="User ID">{info.userID}</Descriptions.Item>}
                {info.staff_id && <Descriptions.Item label="Staff ID" span={3}>
                    {info.staff_id}
                </Descriptions.Item>}
                {info.companyName && <Descriptions.Item span={3} label="Company">{info.companyName}</Descriptions.Item>}
                {info.userID && <Descriptions.Item label="Actions" span={3}>
                    <span className='action' onClick={() => helper.setRedirect(`https://dashboard.nowpay.cloud/question/1831?userID=${info.userID}`)}>Previous calls</span>
                    <br />
                    <span className='action' onClick={() => helper.setRedirect(`https://dashboard.nowpay.cloud/question/1807?userID=${info.userID}`)}>Deductions</span>
                    <br />
                    <span className='action' onClick={() => helper.setRedirect(`https://dashboard.nowpay.cloud/dashboard/247?userid=${info.userID}`)}>CallCenter Dashboard</span>
                    <br />
                    <span className='action' onClick={() => this.openChildDrawer(requests, ' Requests')}>Requests</span>
                    <br />
                    <span className='action' onClick={() => this.openDrawer(<SendUserOtp phone={this.props.info?.phone} />, 'Get OTP')}>Send OTP</span>
                    <br />
                </Descriptions.Item>}
            </Descriptions>}
        </div>;
        const userFreePeriod =
            <Collapse className="freePeriodInfo">
                <Panel header="User free period" key="1">
                    <Descriptions bordered column={4}>
                        <Descriptions.Item span={2} label="Free period end date">{freePeriodsData.freePeriodsEndDate ? Helper.changeDateFormat(freePeriodsData.freePeriodsEndDate) : 'NA'}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Cut off day">{freePeriodsData.cutOffDay ? freePeriodsData.cutOffDay : 'NA'}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Company deployment date">{freePeriodsData.companyDeploymentDate ? Helper.changeDateFormat(freePeriodsData.companyDeploymentDate) : 'NA'}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Company free period">{freePeriodsData.companyFreePeriod ? freePeriodsData.companyFreePeriod : 0}</Descriptions.Item>
                        <Descriptions.Item span={2} label={!freePeriodsData.companyUsersFreePeriodStarted ?
                            <><span>User first request date</span> &nbsp; <Tooltip title={`the user has ${freePeriodsData.companyUsersFreePeriod} free months but the periods hasn't started yet`}>
                                <InfoCircleOutlined style={{ color: 'red', fontWeight: 500 }} />
                            </Tooltip></>
                            : 'User first request date'}> {freePeriodsData.userFirstRequestDate ? Helper.changeDateFormat(freePeriodsData.userFirstRequestDate) : 'NA'}</Descriptions.Item>
                        <>
                            {/*  */}
                            {Number(freePeriodsData.companyUsersFreePeriod) < 3 ? <Descriptions.Item span={2} label={
                                <span>
                                    Users free period &nbsp; <Tooltip title=" Free Period is less than 3 months">
                                        <InfoCircleOutlined style={{ color: 'red', fontWeight: 500 }} />
                                    </Tooltip> &nbsp;
                                </span>}>{freePeriodsData.companyUsersFreePeriod ?
                                    <span style={{ color: 'red', fontWeight: 500 }}>{freePeriodsData.companyUsersFreePeriod}</span> : 0}
                            </Descriptions.Item> : <Descriptions.Item span={2} label='Users free period'>
                                {freePeriodsData.companyUsersFreePeriod ?
                                    freePeriodsData.companyUsersFreePeriod : 'NA'}
                            </Descriptions.Item>}
                            <Descriptions.Item span={2} label="VIP User start date">{freePeriodsData.userVIPStartDate ? Helper.changeDateFormat(freePeriodsData.userVIPStartDate) : 'NA'}</Descriptions.Item>
                        </>

                        <Descriptions.Item span={2} label="VIP User free period">{freePeriodsData.userVIPFreePeriod ? freePeriodsData.userVIPFreePeriod : 0}</Descriptions.Item>

                        <Descriptions.Item span={2} label="Free Period on Deductions">{freePeriodsData.freePeriodOnDeductions ? TrueIcon : FalseIcon}</Descriptions.Item>
                    </Descriptions>
                </Panel>
            </Collapse>
        const callInfo = <>

            <Divider orientation="left">Call Information</Divider>
            <Spin spinning={outboundData}>

                <Form name="nest-messages" ref={this.formRef} onFinish={this.endCall}>
                    <Row>
                        <Col span={10} style={{ padding: '0 15px 0 0' }}>
                            <label htmlFor="">Comment </label>
                            <Form.Item name="comment" initialValue={''} rules={[{ required: false, message: 'Required' }]}>
                                <Input.TextArea style={{ height: '110px' }} placeholder='Enter Comment' />
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ margin: '0px 0 20px 0' }}>
                            <Row>

                                <Col className='status' span='24'>
                                    <label>Status </label>

                                    <Form.Item
                                        name="status"
                                        rules={[{ required: true, message: 'Required' }]}

                                    >
                                        <Select
                                            placeholder="Enter Status">
                                            {status.map((service: any, key: number) => {
                                                return <Option value={service.id} key={key}>{service.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>

                                </Col>

                                <Col className='reason' span='24'>
                                    <label>Reason </label>
                                    <Form.Item
                                        name="reason"
                                        rules={[{ required: true, message: 'Required' }]}

                                    >
                                        <Select
                                            placeholder="Enter Reason">
                                            {reasons.map((service: any, key: number) => {
                                                return <Option value={service.id} key={key}>{service.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} style={{ margin: '10px 0 0px 0', padding: '0 20px' }}>
                            <Form.Item label='Out Bound Call' name="outboundCall" valuePropName="checked" initialValue={false}>
                                <Switch defaultChecked={false} onChange={this.outBoundChange} />
                            </Form.Item>
                            {this.state.outboundCall && <span className='action' style={{ marginTop: '30px', display: 'block' }} onClick={this.handleToggleTheModal}>Collection Plans</span>}
                        </Col>
                        <Col className='module' span='8' style={{ padding: '0 15px 0 0' }}>
                            <label>Modules </label>

                            <Form.Item
                                name="module"
                                rules={[{ required: true, message: 'Required' }]}

                            >
                                <Select
                                    onChange={this.gettingDetails}
                                    placeholder="Enter Module">
                                    {modules.map((module: any, key: number) => {
                                        return <Option value={module.id} module={module} key={key}>{module.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col className='detail' span='8' style={{ padding: '0 15px 0 0' }}>
                            <label>Details </label>
                            <Form.Item
                                name="details"
                                rules={[{ required: true, message: 'Required' }]}

                            >
                                <Select
                                    onChange={this.onOtherSelected}
                                    disabled={details.length === 0}
                                    placeholder="Enter Detail">
                                    {details.map((detail: any, key: number) => {
                                        return <Option value={detail.id} name={detail} key={key}>{detail.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col className='callType' span='8'>
                            <label>Call Type </label>
                            <Form.Item
                                name="callType"
                                rules={[{ required: true, message: 'Required' }]}

                            >
                                <Select
                                    onChange={this.onOtherSelected}
                                    placeholder="Enter The Call Type">
                                    {callTypes.map((detail: any, key: number) => {
                                        return <Option value={detail.id} name={detail} key={key}>{detail.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        {otherComment && <Col span={24} style={{ marginTop: '10px' }}>
                            <label htmlFor=""><b>Brief The details</b> </label>
                            <Form.Item name="otherComment" initialValue={''} rules={[{ required: true, message: 'Required' }]}>
                                <Input.TextArea style={{ height: '110px' }} placeholder='Enter Comment' />
                            </Form.Item>
                        </Col>}

                    </Row>
                    <Row style={{ margin: '15px 0 0 0' }}>
                        <Col span={6}>
                            <Form.Item label='Call interrupted' name="callInteracted" valuePropName="checked" initialValue={false}>
                                <Switch defaultChecked={false} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label='Open zoho ticket' name="openZohoTicket" valuePropName="checked" initialValue={false}>
                                <Switch defaultChecked={false} />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ padding: '5px 20px' }}>{teamName && <>Team : {teamName}</>}</Col>



                        <Col span={6} ><Form.Item>
                            <Button className='endCallBtn' type="primary" danger htmlType="submit" loading={sendCallDataSpin} >
                                End Call
                            </Button>
                        </Form.Item></Col>
                    </Row>

                </Form>
            </Spin>
        </>
        const sudDrawer = <CommanDrawer cancelDrawer={this.closeDrawer} drawerWidth={820}
            visible={subDrawer} drawerLayout={drawerLayout} showDrawer={this.openDrawer} drawerTitle={title} />
        const childDrawer = <CommanDrawer cancelDrawer={this.closeChildDrawer} drawerWidth={drawerWidth}
            visible={subChildDrawer} drawerLayout={drawerChildLayout} showDrawer={this.openChildDrawer} drawerTitle={titleChild} />
        return (
            <>
                <div className='callInfo'>
                    {loadData ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
                        btnTitle={'Refresh the page'} btnClick={this.refreshPage} /> : <>
                        {userInfo}
                        {userFreePeriod}
                        {callInfo}
                        {childDrawer}
                        {/* {modal} */}
                        {sudDrawer}
                    </>}
                </div>
                <TruckingModal title='Collection Plan' handleCloseModal={this.handleToggleTheModal} visibleModal={visibleModal} content={<CollectionPlans onCancel={this.handleToggleTheModal} userID={info?.userID as number} />} />
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, postApi, stepperMoving })(CallInfo)