import React, { useState } from 'react'
import { connect } from 'react-redux';
import UpdateBalanceForm from './UpdateBalanceForm';
import GetCompanies from './getCompanies';
import { truckingApis } from '../../../utils/commonStringsConsts';
interface data {
    step?: number
}
function UpdateCompanyBalance(props: data) {
    const { step } = props;
    const [company, setCompany] = useState(null)
    return (
        <>
            {step === 0 ? <GetCompanies setCompany = {setCompany} apiLink={truckingApis + '/Companies'} /> : <UpdateBalanceForm company={company}/>}
        </>
    )
}
const mapStateToProps = (state: any) => ({
    step: state.truckingReducer.step,
})
export default connect(mapStateToProps, null)(UpdateCompanyBalance)
