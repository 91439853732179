import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Row, Select, Switch, Tooltip, Upload } from "antd";
import { createRef, useEffect, useState } from "react";
import helper from "../../utils/helper";
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { stepperMoving } from "../../stores/actions";
import { InfoCircleOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';

import moment from "moment";

interface Props {
    companyData: any,
    setCompanyData: any,
    companyIndustries: any[],
    companyTypes: any[],
    editAllInfo: boolean,
    create?: boolean,
    allStatus: any
}

export default function EditCompanyForm1(props: Props) {
    const formRef = createRef<FormInstance>();
    const validation = helper.regexValidation
    const [imageFile, setImageFile] = useState<UploadFile[]>([])
    const [staffProvided, setStaffProvided] = useState(false)
    const [nationalProvided, setNationalProvided] = useState(false)
    const [inputValidation1, setInputValidation1] = useState(props.create ? true : false)
    const [inputValidation2, setInputValidation2] = useState(props.create ? true : false)
    const [inputValidation3, setInputValidation3] = useState(props.create ? true : false)
    const [inputValidation4, setInputValidation4] = useState(props.create ? true : false)
    const [inputValidation5, setInputValidation5] = useState(props.create ? true : false)
    const [inputValidation6, setInputValidation6] = useState(props.create ? true : false)
    const [inputValidation7, setInputValidation7] = useState(props.create ? true : false)

    const dispatch = useDispatch()

    useEffect(() => {
        setImageFile(props.companyData.logo)
        setStaffProvided(props.companyData.staffIDRequired)
        setNationalProvided(props.companyData.nationalIDRequired)
        formRef.current?.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.companyData, props.create])

    const onFinish = (values: any) => {
        props.create && delete (values['logo']);
        props.setCompanyData({
            ...props.companyData,
            ...values,
            ...(props.create && { logo: imageFile })
        })
        dispatch(stepperMoving(props.create ? 1 : 2))
    }
    const onFinishFailed = (error: any) => {
        console.log("🚀 ~ file: editCompanyForm1.tsx ~ line 40 ~ onFinishFailed ~ error", error)
    }

    const normFile = (e: any) => {
        setImageFile(e.fileList)
        return e.fileList || [];
    };

    const dataFile = {

        beforeUpload: (file: { size: any, name: string; }) => {
            let file_size = file.size / (1024 * 1024), maxSize = 2, size;
            if (file.size < 1024) {
                size = file.size + ' B';
            } else if (file.size < (1024 * 1024)) {
                size = Math.floor(file.size / 1024) + ' KB';
            } else {
                size = Math.floor(file.size / (1024 * 1024)) + ' MB';
            }
            let acceptable_exe = /(\.png|\.jpg|\.jpeg)$/i;
            if (!acceptable_exe.exec(file.name)) {
                message.error(`The extension of (${file.name}) is not supported!`);
                return Upload.LIST_IGNORE
            } else if (file_size > maxSize) {
                message.error(`File size = ${size} exceeds ${maxSize} MB`);
                return Upload.LIST_IGNORE
            } else {
                return true
            }

        }
    };

    const deleteOldLogo = () => {
        setImageFile([])
    }

    const getOptions = (options: any[], isSignUp?: boolean) => {
        const filteredOptions = !isSignUp ? options : options.filter((option) => option.reasonID !== 9 || option.reasonName !== "Active")
        return filteredOptions.map((i: any) => {
            return <Select.Option value={i.reasonID}>{i.reasonName}</Select.Option>
        });
    }

    return (
        <div className="createNewCompany">
            <Form
                name="basic"
                ref={formRef}
                initialValues={{
                    ...props.companyData,
                    establishedAt: props.companyData.establishedAt ? moment(props.companyData.establishedAt) : undefined,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"

            >

                <Row>
                    <Col span={12}>
                        <label htmlFor="">Name <span>*</span></label>
                        <Form.Item name="name"
                            rules={!inputValidation1 ? [] : [{ required: props.companyData.name || props.create, message: 'Required' }, { pattern: (props.editAllInfo || props.create) ? validation.englishRegex : "", message: 'Please input with English' }]}>
                            <Input placeholder='Please enter your name' disabled={!props.editAllInfo} 
                            onChange={(value) => {
                                if (props.create) {
                                    return
                                }
                                if (value.target.value !== props.companyData.name) {
                                    setInputValidation1(true)
                                }else{
                                    setInputValidation1(false)
                                }
                            }} 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">Arabic Name <span>*</span></label>
                        <Form.Item name="arabicName" rules={!inputValidation2 ? [] : [{ required: props.companyData.arabicName || props.create, message: 'Required' }, { pattern: (props.editAllInfo || props.create) ? validation.arabicRegex : "", message: 'Please input with Arabic' }]}>
                            <Input placeholder='Please enter your arabic name' disabled={!props.editAllInfo} 
                            onChange={(value) => {
                                if (props.create) {
                                    return
                                }
                                if (value.target.value !== props.companyData.arabicName) {
                                    setInputValidation2(true)
                                }else{
                                    setInputValidation2(false)
                                }
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">Description </label>
                        <Form.Item name="description" initialValue='' rules={!inputValidation3 ? [] : [{ pattern: validation.englishRegex, message: 'Please input with English' }]}>
                            <Input.TextArea placeholder='Please enter your description' 
                            onChange={(value) => {
                                if (props.create) {
                                    return
                                }
                                if (value.target.value !== props.companyData.description) {
                                    setInputValidation3(true)
                                }else{
                                    setInputValidation3(false)
                                }
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">Arabic Description</label>
                        <Form.Item name="arabicDescription" initialValue='' rules={!inputValidation4 ? [] : [{ pattern: validation.arabicRegex, message: 'Please input with Arabic' }]}>
                            <Input.TextArea placeholder='Please enter your arabic description' 
                            onChange={(value) => {
                                if (props.create) {
                                    return
                                }
                                if (value.target.value !== props.companyData.arabicDescription) {
                                    setInputValidation4(true)
                                }else{
                                    setInputValidation4(false)
                                }
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ marginBottom: "10px" }}>
                        <label htmlFor="">Company industry <span>*</span> </label>
                        <Form.Item name="industryID" rules={[{ required: props.companyData.industryID || props.create, message: 'Required' }]}>
                            <Select style={{ width: "100%" }} placeholder="Select company industry">
                                {
                                    Array.isArray(props.companyIndustries) &&
                                    props.companyIndustries.map((industry: { id: number, name: string }) => <Select.Option value={industry.id}>{industry.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ marginBottom: "10px" }}>
                        <label htmlFor="">Company type <span>*</span> </label>
                        <Form.Item name="typeID" rules={[{ required: props.companyData.typeID || props.create, message: 'Required' }]}>
                            <Select style={{ width: "100%" }} placeholder="Select company type">
                                {
                                    Array.isArray(props.companyTypes) &&
                                    props.companyTypes.map((type: { id: number, name: string }) => <Select.Option value={type.id}>{type.name}</Select.Option>)
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">Estimated Number Of Users <span>*</span></label>
                        <Form.Item name='estimatedNumbersOfUsers' rules={!inputValidation5 ? [] : [{ required: props.companyData.estimatedNumbersOfUsers || props.create, message: 'Required' }, { type: 'number', min: (props.editAllInfo || props.create) ? 1 : undefined, max: (props.editAllInfo || props.create) ? 999999 : undefined, message: 'must be between 1 and 999999' }]}>
                            <InputNumber placeholder='Please enter the estimated number of users' disabled={!props.editAllInfo} 
                            onChange={(value) => {
                                console.log(value);
                                
                                if (props.create) {
                                    return
                                }
                                if (Number(value) !== Number(props.companyData.estimatedNumbersOfUsers)) {
                                    setInputValidation5(true)
                                }else{
                                    setInputValidation5(false)
                                }
                            }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <label htmlFor="">Available For Deployment</label>
                        <Form.Item name='availableForDeployment' rules={!inputValidation6 ? [] : [{ type: 'number', min: (props.editAllInfo || props.create) ? 0 : undefined, max: (props.editAllInfo || props.create) ? 999999 : undefined, message: 'must be between 0 and 999999' }]}>
                            <InputNumber placeholder='Please enter the Available for deployment' disabled={!props.editAllInfo} 
                            onChange={(value) => {
                                if (props.create) {
                                    return
                                }
                                if (Number(value) !== Number(props.companyData.availableForDeployment)) {
                                    setInputValidation6(true)
                                }else{
                                    setInputValidation6(false)
                                }
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">In-Active Status <span>*</span></label>
                        <Form.Item name="defaultInactiveNextStatus" rules={[{ required: props.companyData.defaultInactiveNextStatus || props.create, message: 'Required' }]}>
                            <Select
                                placeholder="Select an inactive status"
                                showSearch
                                allowClear
                                disabled={!props.editAllInfo}
                            >
                                {getOptions(props.allStatus)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <label htmlFor="">Sign-up Status <span>*</span></label>
                        <Form.Item name="defaultSignupStatus" rules={[{ required: props.companyData.defaultSignupStatus || props.create, message: 'Required' }]}>
                            <Select
                                showSearch
                                placeholder="Select a signup status"
                                optionFilterProp="children"
                                allowClear
                                disabled={!props.editAllInfo}
                            >
                                {getOptions(props.allStatus, true)}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <label>Established At <span>*</span></label>
                        <Form.Item
                            name="establishedAt"
                            rules={[
                                { required: props.companyData.establishedAt || props.create, message: "Establish Date is mandatory!" },
                            ]}
                        >
                            <DatePicker placeholder="Enter the hiring date" />
                        </Form.Item>
                    </Col>
                    {(!imageFile || typeof imageFile !== "string") && props.create && <Col span={12}>
                        <label htmlFor="">Upload <span>*</span></label>
                        <Form.Item
                            name="logo"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Upload {...dataFile} maxCount={1} name="logo" action="/upload.do" listType="picture" accept='.png,.jpg,.jpeg'
                            >
                                <Button icon={<UploadOutlined />}>Click to upload the logo</Button>
                            </Upload>
                        </Form.Item>
                    </Col>}
                    {imageFile && typeof imageFile === "string" &&
                        <div className="oldPhoto" >
                            <img src={imageFile} alt="Logo" width={34} height={50} />
                            <span className="placeholder">
                                Logo
                            </span>
                            {props.create && <span className="deleteIcon">
                                <DeleteOutlined
                                    onClick={deleteOldLogo}
                                />
                            </span>}
                        </div>
                    }
                    <Col span={12}>
                        <label htmlFor="">Maximum Salary Percentage Allowed &nbsp;
                            <Tooltip title="Max Allowed Percentage of user net salary">
                                <InfoCircleOutlined />
                            </Tooltip> &nbsp;
                            <span>*</span></label>
                        <Form.Item name='maxAdvancePercentAllowed' rules={!inputValidation7 ? [] : [{ required: props.companyData.maxAdvancePercentAllowed || props.create, message: 'Required' }, { type: 'number', min: (props.editAllInfo || props.create) ? 0 : undefined, max: (props.editAllInfo || props.create) ? 300 : undefined, message: 'must be between 0 and 300' }]}>
                            <InputNumber placeholder='Please enter the max allowed users' disabled={!props.editAllInfo} 
                            onChange={(value) => {
                                if (props.create) {
                                    return
                                }
                                if (Number(value) !== Number(props.companyData.maxAdvancePercentAllowed)) {
                                    setInputValidation7(true)
                                }else{
                                    setInputValidation7(false)
                                }
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Divider orientation="left">Required Information</Divider>
                <Row style={{ justifyContent: "space-between" }}>
                    <Col span={11} style={{ border: "0.5px solid #d9e9e9", padding: "20px", borderRadius: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <label htmlFor="">Consent</label>
                                <Form.Item name="consentRequired" valuePropName="checked">
                                    <Switch defaultChecked={props.companyData.consentRequired ? true : false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <label htmlFor="">HR Letter</label>
                                <Form.Item name="hrLetterRequired" valuePropName="checked">
                                    <Switch defaultChecked={props.companyData.hrLetterRequired ? true : false} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <label htmlFor="">Promissory Note</label>
                                <Form.Item name="promissoryNoteRequired" valuePropName="checked">
                                    <Switch defaultChecked={props.companyData.promissoryNoteRequired ? true : false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11} style={{ border: "0.5px solid #d9e9e9", padding: "20px", borderRadius: "20px" }}>
                        <Row>
                            <Col span={10}>

                                <label htmlFor="">Staff ID</label>
                                <Form.Item name="staffIDRequired" valuePropName="checked" >
                                    <Switch defaultChecked={props.companyData.staffIDRequired ? true : false} onChange={setStaffProvided} />
                                </Form.Item>
                            </Col>
                            {staffProvided ? <Col span={14}>

                                <label htmlFor="">Provided by the company</label>
                                <Form.Item name={"staffIDRequiredByCompany"} valuePropName="checked" >
                                    <Switch defaultChecked={props.companyData.staffIDRequiredByCompany ? true : false} />
                                </Form.Item>
                            </Col> : ""}
                        </Row>
                        <Row>
                            <Col span={10}>

                                <label htmlFor="">National ID</label>
                                <Form.Item name="nationalIDRequired" valuePropName="checked">
                                    <Switch defaultChecked={props.companyData.nationalIDRequired ? true : false} onChange={setNationalProvided} />
                                </Form.Item>
                            </Col>
                            {nationalProvided ? <Col span={14}>

                                <label htmlFor="">Provided by the company</label>
                                <Form.Item name={"nationalIDRequiredByCompany"} valuePropName="checked">
                                    <Switch defaultChecked={props.companyData.nationalIDRequiredByCompany ? true : false} />
                                </Form.Item>
                            </Col> : ""}
                        </Row>
                    </Col>
                </Row>
                <Col span={24}>
                    <Form.Item wrapperCol={{ offset: 0, span: 0 }} style={{ margin: '10px 0' }}>
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
        </div>
    )
}