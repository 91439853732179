import { Result } from "antd";
import ModalCompnent from "./modal";

interface Props{
    visibleMessageModal: boolean,
    closeModal: any, 
    resErrorContent?: {
        message: string,
        errorData: string
    }
}

export default function TransactionErrorModal({visibleMessageModal, closeModal, resErrorContent}: Props) {
    return <ModalCompnent content={<div className="transactionErrorModal">
        <Result
            status={"error"}
            title={resErrorContent?.message}
        />
        <p>{resErrorContent?.errorData}</p>
    </div>}
        visibleModal={visibleMessageModal} handleAcceptModal={closeModal} handleCloseModal={closeModal} none="none" />
}