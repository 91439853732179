import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Select, Button, Input, Row, Col, Divider, message } from 'antd'
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { request } from '../../apis/axiosRequest';
import { dataAnalyticsScheduleApi } from '../../utils/apisConsts';
import { errorMessage, firstOption, variableCasesMsg } from '../../utils/commonStringsConsts';
import Helper from '../../utils/helper';

const { Option } = Select;

export default function Schedule() {
    const formRef: any = useRef([]);
    const hoursOfDay = Helper.getSequenceOfNumbers(24);
    const daysOfWeek = Helper.getSequenceOfNumbers(7, true);
    const daysOfMonth = Helper.getSequenceOfNumbers(30);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const runDAApiSchedule = async (values: any) => {
        const options = values.options;
        if (Helper.validKeys(options)) {
            return
        }
        setLoader(true);
        const data = {
            path: values.path,
            options: Helper.changeKeyByValue(options),
            schedule: {
                name: values.name,
                weekDay: values.weekDay,
                monthDay: values.monthDay,
                hour: values.hour,
            }
        }
        try {
            const apiData: any = await dispatch(request({ method: 'POST', url: dataAnalyticsScheduleApi, data: data }))
            const res = await apiData.data;
            if (res.status === 200) {
                message.success(res.message, 6);
                setLoader(false);
                formRef.current?.resetFields()

                return
            }
            message.error(res.message, 6);
            setLoader(false);
        } catch (error) {
            message.error(errorMessage, 6);
            setLoader(false);
        }
    }

    return (
        <>
            <div className="schedule">

                <Form
                    ref={formRef}
                    initialValues={{ options: firstOption }}
                    onFinish={runDAApiSchedule}
                >
                    <Divider orientation="left" className='divider'>Schedule</Divider>
                    <Row>
                        <Col span={12}>
                            <label htmlFor="">Name</label>
                            <Form.Item
                                name={'name'}
                                rules={[{ required: true, message: 'Required' }]}>
                                <Input placeholder='Enter the name' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Day of Month</label>
                            <Form.Item
                                name={'monthDay'}

                                rules={[{ required: true, message: 'Required' }]}>
                                <Select
                                    mode="multiple"
                                    placeholder="Select a month day">
                                    {daysOfMonth.map((num: number, key: number) => {
                                        return <Option value={num} key={key}>{num}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Day of Week </label>
                            <Form.Item
                                name={'weekDay'}
                                rules={[{ required: true, message: 'Required' }]}>
                                <Select
                                    mode="multiple"
                                    placeholder="Select a week day">
                                    {daysOfWeek.map((num: number, key: number) => {
                                        return <Option value={num} key={key}>{num}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <label htmlFor="">Hour</label>
                            <Form.Item
                                name={'hour'}
                                rules={[{ required: true, message: 'Required' }]}>
                                <Select
                                    placeholder="Select an hour">
                                    {hoursOfDay.map((num: number, key: number) => {
                                        return <Option value={num} key={key}>{num}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <label htmlFor="">Api Path</label>
                            <Form.Item
                                name={'path'}
                                rules={[{ required: true, message: 'Required' }]}
                            >
                                <Input placeholder='Enter the path' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Divider className='divider' orientation="left">Options</Divider>


                    <Form.List name="options">

                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <>
                                        <Row key={field.key}>
                                            <Col span={12}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, curValues) =>
                                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                    }>
                                                    {() => (
                                                        <>
                                                            <label htmlFor="">Key</label>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'key']}
                                                                rules={[{ required: true, message: 'Required' },
                                                                { pattern: Helper.regexValidation.variableCases, message: variableCasesMsg }]}
                                                            >

                                                                <Input id={field.key === 0 ? 'error' : ''} placeholder='Enter the key' />

                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={23}>
                                                <label htmlFor="">Value</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'value']}
                                                    rules={[{ required: true, message: 'Required' }]}                                            // rules={[{ required: true, message: 'Missing amount' }]}
                                                >
                                                    <Input.TextArea placeholder='Enter the value ' />
                                                </Form.Item>

                                            </Col>
                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ margin: ' 40px 0 0 20px' }} />


                                            </Col>


                                        </Row>
                                    </>
                                ))}



                                {fields.length <= 128 && <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ margin: '20px 0' }}>
                                        Add
                                    </Button>
                                </Form.Item>}
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loader}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>

            </div>
        </>
    )
}
