
import { message } from 'antd';
import React, { Component } from 'react'
import Stepper from '../../components/stepper';
import store from '../../stores';
import { stepperMoving } from '../../stores/actions';
import { errorMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';
import ClearRefund from '../clearRefund';
import RefundActiveRequestForm from './refundActiveRequestForm';

class RefundActiveRequest extends Component {
    state = {
        requestDetails: {
            installments: []
        },
        userInfo: {}
    }

    getData = async (setLoading: any, values: { advanceID: number, phone: string }) => {
        setLoading(true)
        try {
            const userInfo: any = await helper.getUserInfo(values.phone)
            const requestDetails: any = await helper.getRequestInstallments(values.advanceID, values.phone, true, "2,3,9,12,13,14", "1,10,14,15", true, false)
            if (userInfo.status !== 200) {
                message.error(userInfo.message, 4)
            }
            else if (requestDetails?.data.status !== 200) {
                message.error(requestDetails.data.message, 4)
            }
            else if (userInfo && requestDetails) {
                this.setState({ requestDetails: requestDetails.data.advanceDetails, userInfo: userInfo.userInfo }, () => {
                    if (this.state.requestDetails.installments.length) {
                        store.dispatch(stepperMoving(1))
                    }
                    else {
                        message.error("This request has no installments to be refunded", 4)
                    }
                })
            }
            else {
                message.error(errorMessage, 4)
            }

            setLoading(false)
        }
        catch (error: any) {
            console.log("🚀 ~ file: refundInActiveRequests.tsx:17 ~ RefundInActiveRequest ~ error", error)
            message.error(errorMessage, 4)
            setLoading(false)
        }
    }

    render() {
        const { requestDetails, userInfo } = this.state
        const componentSteps = [
            {
                title: 'Get Request Installments',
                content: <ClearRefund submitFunction={this.getData} />,
                id: 0,
            }, {
                title: 'Refund Active Request',
                content: <RefundActiveRequestForm requestDetails={requestDetails} userInfo={userInfo} />,
                id: 1
            },
        ]
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default RefundActiveRequest
