import { Button, ConfigProvider, Dropdown, Menu, message, Space } from "antd";
// import { useState } from "react";
import HrTables from "./hrTables";
import type { MenuProps } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { getApi } from '../../stores/actions';
import { exportToCSV } from "../../components/exportExcel";
// const { TabPane } = Tabs;

export default function Users() {
    // const [tabKey, setTabKey] = useState(1);
    const dispatch = useDispatch();

    const getDataForTable = async () => {
        const data: any = await dispatch(getApi(`/hr/pendingHrUsers`, {}));
        const res = await data.payload;

        try {
            if (res.status === 200) {
                console.log(res);
                exportToCSV(res.users,'الطلبات الجديده')
            } else if (res.status === 204) {
                message.error(res.message)
            }
            else {
                message.error(res.message)
            }
        } catch (error) {
            message.error('Something went wrong, please try again')
        }
    };

    // const onChange = (key: any) => {
    //     setTabKey(Number(key))
    // };
    const handleMenuClick: MenuProps['onClick'] = e => {
        getDataForTable();
    };
    
    const menu = <>
        <ConfigProvider direction="rtl">
            <Menu
                onClick={handleMenuClick}
                items={[

                    {
                        label: 'Excel.XLS',
                        key: '2',
                        icon: <FileExcelOutlined />,
                    },

                ]}
            />
        </ConfigProvider>
    </>
    return (
        <>

            <ConfigProvider direction="rtl">

                <div className="hrUsers">
                    {
                    // tabKey === 1 &&
                     <div style={{ textAlign: 'left',margin:'20px 0'}}>

                        <Dropdown overlay={menu}>
                            <Button className="view">
                                <Space>
                                    تنزيل بيانات الموظفين
                                    <DownloadOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </div>}
                    {/* <Row>
                        <Col></Col>
                        <Col>
                           
                        </Col>
                    </Row> */}
                    <div>

                        {/* <Tabs defaultActiveKey="1" onChange={onChange} >
                            <TabPane tab="الطلبات الجديدة" key="1" >
                                
                            </TabPane>
                            <TabPane tab="الطلبات المعلقة" key="2">
                                <HrTables isPending apiLink="/hr/pendingUsers" />
                            </TabPane>
                        </Tabs> */}
                        <HrTables apiLink="/hr/pendingHrUsers" />
                    </div>
                </div>
            </ConfigProvider>
        </>
    )
}




