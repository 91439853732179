import axios from 'axios';
// import Cookies from 'js-cookie';

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: 'https://beta.api.operations.nowpay.cloud/',
  headers: {
    'content-type': 'application/json'
  }
});
 
// const token =Cookies.get('token');
// console.log(token);

// instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
instance.defaults.headers.post['Content-Type'] = 'application/json';


export default instance;  
