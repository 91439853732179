import { Descriptions, Form, Row, Col, Input, Button, message } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../apis/axiosRequest'
import PaymentPlan from '../assets/images/Group@2x.png'
import { stepperMoving, openOTPModal, TimerTimeOut } from '../stores/actions'
import { createExceptionsRequestApi, createRequestApi, recalculateRequestApi, sendOTPApi } from '../utils/apisConsts'
import { errorMessage } from '../utils/commonStringsConsts'
import ModalComponent from './modal'
import SubmittingBin from './submitBinNumber'

interface Props {
    noLimits?: boolean,
    isUpdate?: boolean,
}
export default function PaymentPlans(props: Props) {
    const paymentPlansData = useSelector((state: any) => state.actionsReducer.userPlans);
    const user = useSelector((state: any) => state.actionsReducer.userBalance);
    const requestData = useSelector((state: any) => state.actionsReducer.requestDetails);
    const otpModalVisible = useSelector((state: any) => state.actionsReducer.otpModalVisible);
    const dispatch = useDispatch();
    const [noOfPayments, setNoOfPayments] = useState(0);
    const [requestedAmount, setRequestedAmount] = useState(0);
    const [installments, setInstallments] = useState([]);
    const [totalProcessingFees, setTotalProcessingFees] = useState(0);
    const [totalDue, setTotalDue] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [validPlan, setValidPlan] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [msg, setMsg] = useState('');
    const [submitSalaryRequest, setSubmitSalaryRequest] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);


    useEffect(() => {
        storePaymentPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const storePaymentPlans = () => {
        if (props.isUpdate) {
            handlePlansCreateAndUpdate(paymentPlansData.newData?.plansOptions);
            const plan = Array.isArray(paymentPlansData?.newData.plansOptions) && paymentPlansData?.newData.plansOptions.filter((onePlan: any) => onePlan.noOfPayments === requestData.numberOfMonths)

            setNoOfPayments(plan[0]?.noOfPayments); //installmentSelected
            setRequestedAmount(paymentPlansData?.newData.requestedAmount);
            setInstallments(plan[0]?.installments); //monthlyPayment
            setTotalProcessingFees(plan[0]?.summary?.totalProcessingFees); //totalCardFees
            setTotalDue(plan[0]?.summary?.total);
            setDiscount(plan[0]?.summary?.discount);
            setValidPlan(plan[0]?.isValid);
            setBtnDisable(plan[0]?.isValid ? false : true);
            return
        }
        handlePlansCreateAndUpdate(paymentPlansData?.plansOptions);
    }

    const handlePlansCreateAndUpdate = (plan: any) => {
        for (let onePlan = 0; onePlan < plan?.length; onePlan++) {
            if (plan[onePlan].isValid) {
                setNoOfPayments(plan[onePlan]?.noOfPayments); //installmentSelected
                setRequestedAmount(props.isUpdate ? paymentPlansData?.newData?.requestedAmount : paymentPlansData?.requestedAmount);
                setInstallments(plan[onePlan]?.installments); //monthlyPayment
                setTotalProcessingFees(plan[onePlan]?.summary?.totalProcessingFees); //totalCardFees
                setTotalDue(plan[onePlan]?.summary?.total);
                setDiscount(plan[onePlan]?.summary?.discount);
                setValidPlan(plan[onePlan]?.isValid);
                setBtnDisable(plan[onePlan]?.isValid ? false : true);
                break
            } else {
                setNoOfPayments(plan[onePlan]?.noOfPayments);
                setMsg(plan[onePlan]?.message);
                setBtnDisable(plan[onePlan]?.isValid ? false : true);
            }
        }
    }

    const plansSelected = (plan: any) => {
        if (!requestData?.editableInstallments && props.isUpdate) {
            message.error('Updates not allowed on this request');
            return
        }
        setNoOfPayments(plan?.noOfPayments); //installmentSelected
        setInstallments(plan?.installments); //monthlyPayment
        setTotalProcessingFees(plan?.summary?.totalProcessingFees); //totalCardFees
        setTotalDue(plan?.summary?.total);
        setDiscount(plan?.summary?.discount);
        setValidPlan(plan?.isValid);
        setBtnDisable(plan?.isValid ? false : true);
        setValidPlan(plan.isValid);

    }


    const submitPaymentPlan = async (values?: { comment: string }) => {

        if (props?.isUpdate) {
            // setSubmitSalaryRequest(true); 113526


            try {
                const data = { phone: requestData.phone }

                const userData: any = await dispatch(request({ url: sendOTPApi, data: data, method: 'POST' }));
                console.log("🚀 ~ file: paymentPlans.tsx:109 ~ submitPaymentPlan ~ userData:", userData)
                const res = await userData.data;
                console.log("🚀 ~ file: paymentPlans.tsx:107 ~ submitPaymentPlan ~ res:", res)
                if (res.status === 200) {
                    dispatch(openOTPModal(true));
                    dispatch(TimerTimeOut(false))
                    setSubmitSalaryRequest(false);
                    return
                }
                message.error(res.message);
                setSubmitSalaryRequest(false)
            } catch (error) {
                message.error(errorMessage);
                setSubmitSalaryRequest(false)
            }

            // For otp confirmation;
            return
        }
        setSubmitSalaryRequest(true)
        const paymentData = {
            numberOfMonths: noOfPayments,
            requestedAmount: requestedAmount,
            userID: user?.userID,
        }
        props.noLimits && Object.assign(paymentData, { 'comment': values?.comment });
        const sendPlan: any = await dispatch(request({ url: `${props.noLimits ? createExceptionsRequestApi : createRequestApi}`, method: 'POST', data: paymentData }));
        const res = await sendPlan.data;

        try {
            if (res.status === 200) {
                setSubmitSalaryRequest(false);
                message.success(res.message, 4);
                dispatch(stepperMoving(0))
            } else {
                setSubmitSalaryRequest(false);
                message.error(res.message, 4);
            }

        } catch (error) {
            console.log(error);
            setSubmitSalaryRequest(false)
            message.error(errorMessage, 4);
        }

    }
    const closeOTPModal = () => {
        dispatch(openOTPModal(false));
        dispatch(TimerTimeOut(false))
    }
    const handleUpdatePaymentPlan = async (otpNum: any) => {
        setOtpLoad(true)
        const updateObj = {
            advanceIDs: [requestData.advanceID],
            ...(Number(paymentPlansData?.newData?.requestedAmount) !== Number(paymentPlansData?.oldData?.requestedAmount) && { amount: paymentPlansData?.newData?.requestedAmount }),//because you can change the amount
            numberOfMonths: noOfPayments, //because you can change the no of installments
            otp: Number(otpNum),// for security
            comment: '',
        }
        try {
            const apiData: any = await dispatch(request({ url: recalculateRequestApi, data: updateObj, method: 'PATCH' }));
            const res = apiData.data;
            if (res.status === 200) {
                setOtpLoad(false)
                dispatch(openOTPModal(false));
                dispatch(stepperMoving(0))
                message.success(res.message);
                return
            }
            message.error(res.message);
            setOtpLoad(false)

        } catch (error) {
            message.error(errorMessage);
            setOtpLoad(false)
        }
    }

    const OTP = <>
        <Row>
            <SubmittingBin twilio={true} confirmUpdateData userLang={"en"}
                getOtpValue={handleUpdatePaymentPlan} otpLoad={otpLoad} />
        </Row>
    </>
    return (


        <>
            {<div className="paymentPlan">
                <img className='salaryAdvance' width='200' height='100' src={PaymentPlan} alt="" /><br />
                <div className='caption'>
                    {!validPlan ? <span className='unValidMessage'>{msg}</span> : <>
                        <span className='numberShouldPay'>{requestedAmount} EGP</span><br /></>}</div>
                <div className='plansContainer'>
                    {validPlan ? <p className='payBack'>Pay back over {noOfPayments} {noOfPayments > 1 ? 'months' : 'month'}</p> :
                        <p className='payBack'>Pay back over {noOfPayments} {noOfPayments > 1 ? 'months' : 'month'} not available</p>}

                    {props.isUpdate ? <>

                        {Array.isArray(paymentPlansData?.newData?.plansOptions) && paymentPlansData?.newData?.plansOptions.map((plan: any, i: any) => {
                            return <span className={noOfPayments === plan?.noOfPayments ? 'selectedPlan' : !requestData?.editableInstallments ? 'disabledPlans' : plan.isValid ? 'plans' : 'unValidPlan'} key={i} onClick={() => plansSelected(plan)}>{plan?.noOfPayments}</span>
                        })}
                    </> : <>
                        {Array.isArray(paymentPlansData?.plansOptions) && paymentPlansData?.plansOptions.map((plan: any, i: any) => {
                            return <span className={noOfPayments === plan?.noOfPayments ? 'selectedPlan' : !requestData?.editableInstallments ? 'disabledPlans' : plan.isValid ? 'plans' : 'unValidPlan'} key={i} onClick={() => plansSelected(plan)}>{plan?.noOfPayments}</span>
                        })}
                    </>}


                </div>

                {validPlan && <><Descriptions bordered>
                    <Descriptions.Item label="Amount">{requestedAmount}</Descriptions.Item>
                    <Descriptions.Item label="Processing Fees">{totalProcessingFees}</Descriptions.Item>
                    <Descriptions.Item label="Discount">{discount}</Descriptions.Item>
                    <Descriptions.Item span={3} label="Total">{totalDue}</Descriptions.Item>
                </Descriptions>
                    <Descriptions bordered title="Monthly Payments" style={{ marginTop: "20px" }}>
                        {
                            Array.isArray(installments) && installments?.map((month: { month: number, amount: number, serviceFees: number, processingFees: number }) => {
                                const date = new Date()
                                date.setMonth(month.month - 1)
                                return <Descriptions.Item span={2} label={moment(date).format('MMMM')}>{month.amount}{month.processingFees ? " + " + month.processingFees : ""}{month.serviceFees ? " + " + month.serviceFees : ""} EGP</Descriptions.Item>
                            })
                        }
                    </Descriptions>
                </>}
                {props.noLimits ? <Form style={{ margin: '15px 0 0 0' }} onFinish={submitPaymentPlan}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item name='comment' rules={[
                                { required: true, message: 'Comment is mandatory' }]}>
                                <Input.TextArea placeholder='Enter your comment' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className={btnDisable ? 'submitPaymentDisabled' : 'submitPayment'} shape="round" loading={submitSalaryRequest}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> : <Button className={btnDisable ? 'submitPaymentDisabled' : 'submitPayment'} disabled={btnDisable}
                    type="primary" shape="round" loading={submitSalaryRequest} onClick={() => submitPaymentPlan()}>
                    Submit
                </Button>}
            </div>}
            <ModalComponent content={OTP} none={'none'} okText='Close' title={`Installment Confirmation`} visibleModal={otpModalVisible} handleCloseModal={closeOTPModal} handleAcceptModal={() => dispatch(openOTPModal(false))} />
        </>
    )
}
