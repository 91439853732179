import React, { Component } from 'react'
import { Steps, Slider, InputNumber, Row, Col, Button, message, Descriptions, Form, Input } from 'antd';
import PhoneNumber from '../components/phoneNumber';
import SalaryAdvance from '../assets/images/Group 26@2x.png'
import PaymentPlan from '../assets/images/Group@2x.png'

import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import NotificationAlert from '../components/notificationAlert';
import { connect } from 'react-redux';
import { getApi, postApi } from '../stores/actions'
import Helper from '../utils/helper'
import SubmittingBin from '../components/submitBinNumber'
import { errorMessage } from '../utils/commonStringsConsts';
import moment from 'moment';
const { Step } = Steps;

interface Props {
    postApi: any,
    getApi: any,
    info: any,
    childComponent?: boolean,
    removeData: boolean,
    noLimits?: boolean
}
export class CreateRequest extends Component<Props> {

    state = {
        current: 0,
        neededSalary: 0,
        balance: 0,
        monthlyPayment: [],
        installmentSelected: 1,
        phone_is_valid: false,
        phone: undefined,
        submit_disable: true,
        submitPhoneNum: false,
        submitSalaryBtn: false,
        submitSalaryRequest: false,
        successNotification: null,
        newPlans: [],
        isFloat: false,
        summary: {},
        message: '',
        btnDisable: false,
        validPlan: false,
        requestedAmount: 0,
        monthlyServiceFees: 0,
        currentPlan: {},
        startMonth: 0,
        startYear: 0,
        totalCardFees: 0,
        userID: null,
        userLang: "en",
        totalDue: 0,
        discount: 0
    };

    componentDidMount() {
        this.props.childComponent && this.getUserBalance()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.removeData !== this.props.removeData && this.props.removeData) {
            this.setState({ current: 0, phone_is_valid: false, phone: undefined, neededSalary: 0 })
        }
    }

    phoneNumberChange = (value: string) => {
        if (Helper.regexValidation.egyptPhoneNum.test(value)) {
            this.setState({ phone_is_valid: false, submit_disable: false, phone: value.slice(-11) })
        }
        else {
            this.setState({ phone_is_valid: true, submit_disable: true })
        }
    }
    getUserBalance = async () => {
        let data = { phone: this.state.phone ? this.state.phone : this.props.info.phone }
        this.setState({ submitPhoneNum: true });
        const sendPhone = await this.props.getApi(`requests/user-balance-by-phone?phone=${data.phone}`);
        try {
            const res = sendPhone.payload;
            if (res.status === 200) {
                if (res.balance < 100) {
                    message.error(`Sorry user balance can't be less than ${100}`, 4);
                    this.setState({ submitPhoneNum: false });
                    return
                }
                await this.sendOtp()
                this.setState({ phone_is_valid: false, current: 1, submit_disable: true, submitPhoneNum: false, balance: res.balance, userLang: res.language, userID: res.userID })

            } else {
                this.setState({ submitPhoneNum: false });
                message.error(res.message, 4)

            }
        } catch (error) {
            this.setState({ submitPhoneNum: false });
            message.error('Something went wrong, please try again', 4)
        }
    }
    sendOtp = async () => {
        const info = {
            phone: this.state.phone ? this.state.phone : this.props.info.phone
        }
        const sendOtp = await this.props.postApi(`auth/send-otp`, info);
        try {
            if (sendOtp.payload.status !== 200) {
                message.error(sendOtp.payload.message, 4)
            }
        } catch (error) {
            message.error(errorMessage, 4)
        }
    }
    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (this.state.submit_disable) {
            return
        }
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.getUserBalance()

        }
    }
    onChangeSlider = (value: any) => {
        this.setState({
            neededSalary: value, isFloat: false
        });
        if (Helper.regexValidation.integerNumbers.test(value) === false && (value && value.length !== 0)) {
            this.setState({ isFloat: true });
            message.error(`The fractions are not allowed`, 5)
        }

    }


    submitSalaryAdvance = async () => {

        const info = {
            serviceID: 1,
            amountToPlan: this.state.neededSalary,
            ...(this.props.noLimits && { exception: 1 })
        }
        if (this.state.neededSalary >= 100) {
            this.setState({ submitSalaryBtn: true })
            const getPlans = await this.props.postApi(`/users/paymentPlans/${this.state.userID}`, info);
            const data = await getPlans.payload;

            try {
                if (data.status === 200) {
                    if (data.plans.plansOptions.length === 0) {
                        this.setState({ errorMessage: `You don't have plans`, submitSalaryBtn: false });
                        message.error(`You don't have plans`, 5)
                        return
                    }

                    let plan = data.plans.plansOptions;
                    for (let onePlan = 0; onePlan < plan.length; onePlan++) {
                        if (plan[onePlan].isValid) {

                            this.setState({
                                installmentSelected: plan[onePlan].noOfPayments, requestedAmount: data.plans.requestedAmount,
                                startMonth: data.plans.startMonth, startYear: data.plans.startYear,
                                monthlyPayment: plan[onePlan].installments, monthlyServiceFees: plan[onePlan].monthlyServiceFees, totalCardFees: plan[onePlan].summary.totalProcessingFees,
                                totalDue: plan[onePlan].summary.total, discount: plan[onePlan].summary.discount,
                                validPlan: plan[onePlan].isValid, btnDisable: plan[onePlan].isValid ? false : true, currentPlan: plan[onePlan],
                            });
                            break
                        } else {
                            this.setState({
                                installmentSelected: plan[onePlan].noOfPayments,
                                message: plan[onePlan].message, btnDisable: plan[onePlan].isValid ? false : true
                            });
                        }
                    }

                    this.setState({ newPlans: data.plans.plansOptions, submitSalaryBtn: false });
                    this.setState({ current: 3 })
                } else {
                    this.setState({ errorMessage: data.message, submitSalaryBtn: false });
                    message.error(data.message, 5)
                }
            } catch (error) {
                console.log("🚀 ~ file: createRequest.tsx ~ line 188 ~ CreateRequest ~ submitSalaryAdvance= ~ error", error)
                this.setState({ submitSalaryBtn: false });
                message.error('Something went wrong please try again', 5)
            }
        } else {
            message.error('Minimum amount is 100 EGP', 5)
        }

    }
    isFloat = (num: number) => {
        return !!(num % 1)
    }
    plansSelected = (plan: any) => {
        this.setState({
            monthlyPayment: plan.installments, installmentSelected: plan.noOfPayments, validPlan: plan.isValid,
            message: plan.isValid ? false : plan.message, monthlyServiceFees: plan.monthlyServiceFees, totalCardFees: plan.summary.totalProcessingFees,
            totalDue: plan.summary.total, discount: plan.summary.discount,
            btnDisable: plan.isValid ? false : true, currentPlan: plan
        })

    }
    submitPaymentPlan = async (values?: { comment: string }) => {
        this.setState({ submitSalaryRequest: true });
        const paymentData = {
            numberOfMonths: this.state.installmentSelected,
            requestedAmount: this.state.requestedAmount,
            userID: this.state.userID,

            // priceWillPay: this.state.salaryInstallment

        }
        this.props.noLimits && Object.assign(paymentData, { 'comment': values?.comment });

        const sendPlan = await this.props.postApi(this.props.noLimits ? '/requests/createExceptionRequest' : 'requests/createRequest', paymentData);
        try {
            const res = await sendPlan.payload;
            if (res.status === 200) {
                this.setState({ submitSalaryRequest: false, current: 0, phone: undefined, neededSalary: 0 });
                message.success(res.message, 4)

            } else {
                this.setState({ submitSalaryRequest: false });
                message.error(res.message, 4)
            }

        } catch (error) {
            console.log(error);
            this.setState({ submitSalaryRequest: false });
            message.error('Something went wrong, please try again', 4)
        }
        setTimeout(() => {
            this.setState({
                submitSalaryRequest: false,
            })
        }, 1000);

    }
    onStepperClick = (step: number) => {
        if (this.state.current === 0) {
            return
        }
        this.setState({ current: step })
        this.setState({ installmentSelected: 1 })
    }
    moveToFirstStep = () => {
        if (this.state.current !== 0) {
            this.setState({ current: 0, phone: undefined, neededSalary: 0 })
        }

    }
    render() {
        const { neededSalary, balance, installmentSelected, monthlyPayment, newPlans, validPlan, btnDisable, message,
            phone_is_valid, submit_disable, submitSalaryBtn, submitSalaryRequest, submitPhoneNum,
            successNotification, isFloat, requestedAmount, monthlyServiceFees, userLang, phone, totalCardFees, totalDue, discount } = this.state;

        let phoneNumberStep = <div className="phoneStep">
            <Row>
                <Col span={8}></Col>
                <Col span={8}>
                    <PhoneNumber value={phone} externalChangeFunction={this.phoneNumberChange} externalSubmitPhoneNumber={true} externalOnKey={this.onKeyDown} />
                    {phone_is_valid && <><span className='validNumber'>Phone number is invalid</span><br /></>}
                    <Button className={submit_disable ? 'disabled-ant-btn' : 'phoneBtn'} type="primary" loading={submitPhoneNum} htmlType="submit" block disabled={submit_disable}
                        onClick={this.getUserBalance}>Submit</Button>
                </Col>
                <Col span={8}></Col>
            </Row>


        </div>
        let otpStep = <div className="phoneStep">
            <Row>
                <Col span={7}></Col>
                <Col span={10}>
                    <SubmittingBin externalPhone={this.state.phone ? this.state.phone : this.props.info.phone} submitFunc={() => this.setState({ current: 2 })} twilio={true} userLang={userLang === "en" || userLang === "ar" ? userLang : "en"} />
                </Col>
                <Col span={7}></Col>
            </Row>


        </div>
        let slider = <Row style={{ justifyContent: 'center' }}>
            <Col span={24}>
                <img className='salaryAdvance' width='250' height='250' src={SalaryAdvance} alt="" />
            </Col>
            <Col span={24}>

                {/* <span className='sliderText'>Request Via EGY</span> */}
                <span className='sliderText' style={{ margin: '0' }}>Current Balance</span>
                <span className='sliderText'>{balance}</span>
            </Col>
            <Col span={12}>
                <Slider
                    disabled={this.state.current !== 2}
                    min={0}
                    max={balance}
                    step={50}
                    onChange={this.onChangeSlider}
                    defaultValue={0}
                    value={typeof neededSalary === 'number' ? neededSalary : 0}
                />
            </Col>
            <Col span={4}>
                <InputNumber

                    min={0}
                    max={balance}
                    disabled={this.state.current !== 2}
                    style={{ margin: '0 16px' }}
                    value={neededSalary}
                    onChange={this.onChangeSlider}
                />
            </Col>
            <Col span={12}>
                <Button className={isFloat ? 'submitSliderDisabled' : 'submitSlider'} disabled={isFloat} shape="round" type="primary" loading={submitSalaryBtn} onClick={this.submitSalaryAdvance}>
                    Submit
                </Button>

            </Col>
        </Row>
        let paymentPlan = <>
            <img className='salaryAdvance' width='200' height='100' src={PaymentPlan} alt="" /><br />
            <div className='caption'>
                {!validPlan ? <span className='unValidMessage'>{message}</span> : <>
                    <span className='numberShouldPay'>{requestedAmount} EGP</span><br /></>}</div>
            <div className='plansContainer'>
                {validPlan ? <p className='payBack'>Pay back over {installmentSelected} {installmentSelected > 1 ? 'months' : 'month'}</p> :
                    <p className='payBack'>Pay back over {installmentSelected} {installmentSelected > 1 ? 'months' : 'month'} not avilable</p>}
                {newPlans.map((plan: any, i) => {
                    return <span className={installmentSelected === plan.noOfPayments ? 'selectedPlan' : plan.isValid ? 'plans' : 'unValidPlan'} key={i} onClick={() => this.plansSelected(plan)}>{plan.noOfPayments}</span>
                })}
            </div>

            {validPlan && <><Descriptions bordered>
                <Descriptions.Item label="Amount">{requestedAmount}</Descriptions.Item>
                <Descriptions.Item label="Monthly Fees">{monthlyServiceFees}</Descriptions.Item>
                <Descriptions.Item label="Processing Fees">{totalCardFees}</Descriptions.Item>
                <Descriptions.Item label="Discount">{discount}</Descriptions.Item>
                <Descriptions.Item span={3} label="Total">{totalDue}</Descriptions.Item>
            </Descriptions>
                <Descriptions bordered title="Monthly Payments" style={{ marginTop: "20px" }}>
                    {
                        monthlyPayment.map((month: { month: number, amount: number, serviceFees: number, processingFees: number }) => {
                            const date = new Date()
                            date.setMonth(month.month - 1)
                            return <Descriptions.Item span={2} label={moment(date).format('MMMM')}>{month.amount}{month.processingFees ? " + " + month.processingFees : ""}{month.serviceFees ? " + " + month.serviceFees : ""} EGP</Descriptions.Item>
                        })
                    }
                </Descriptions>
            </>}
            {/* ref={formRef} */}
            {this.props.noLimits ? <Form onFinish={this.submitPaymentPlan} style={{margin:'15px 0 0 0'}} >
                <Row>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item name='comment' rules={[
                            { required: true, message: 'Comment is mandatory' }]}>
                            <Input.TextArea placeholder='Enter your comment' />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className={btnDisable ? 'submitPaymentDisabled' : 'submitPayment'} shape="round" loading={submitSalaryRequest}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form> : <Button className={btnDisable ? 'submitPaymentDisabled' : 'submitPayment'} disabled={btnDisable} type="primary" shape="round" loading={submitSalaryRequest} onClick={()=> this.submitPaymentPlan()}>
                Submit
            </Button>}
        </>
        return (
            <div className='createRequest'>
                <Steps current={this.state.current}>
                    <Step title="Get User" onStepClick={this.moveToFirstStep} />
                    <Step title="Verify OTP" />
                    <Step title="Salary Advance" onStepClick={() => this.state.current === 3 && this.setState({ current: 2 })} />
                    <Step title="Payment Plan" />
                </Steps>
                <div>
                    {this.state.current === 0 && <div className='phoneNumber' >{phoneNumberStep}</div>}
                    {this.state.current === 1 && <div className='otp'>{otpStep}</div>}
                    {this.state.current === 2 && <div className='salarySlider' >{slider} </div>}
                    {this.state.current === 3 && <div className='paymentPlan' >{paymentPlan} </div>}
                    {successNotification === true && <NotificationAlert message={'Request created successfully'} placement={'topLeft'} duration={4} icon={<CheckCircleTwoTone twoToneColor="#52c41a" />} />}
                    {successNotification === false && <NotificationAlert message={'Create request has been failed'} placement={'topLeft'} duration={4} icon={<CloseCircleOutlined style={{ color: "#ff4d4f" }} />} />}
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
    removeData: state.actionsReducer.removeData
})
export default connect(mapStateToProps, { postApi, getApi })(CreateRequest)
