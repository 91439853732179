import { Badge, Button, Col, Descriptions, Form, FormInstance, InputNumber, message, Row, Select } from "antd"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BasicUserInfo from "../../components/basicUserInfo";
import OutPutResult from "../../components/result";
import Spinner from "../../components/spinner";
import { stepperMoving } from "../../stores/actions";
import { getUserAndCompanyAmountsAPI } from "../../utils/apisConsts";
import { errorMessage } from "../../utils/commonStringsConsts";
import helper from "../../utils/helper";
const { Option } = Select;
interface Props {
    isException: boolean,
    mostRecent: boolean,
    apiName: string,
}
export default function AdjustmentForm(props: Props) {
    const userInfo = useSelector((state: any) => state.actionsReducer.info)
    const dispatch = useDispatch()
    const [paymentCycles, setPaymentCycles] = useState<any[]>([])
    const [newAmount, setNewAmount] = useState<any>(0)
    const [loadingPaymentCycles, setLoadingPaymentCycles] = useState(false)
    const [loadingAmounts, setLoadingAmounts] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [sendingData, setSendingData] = useState(false)
    const [userAmount, setUserAmount] = useState({
        before: 0,
        after: 0
    })
    const [companyAmount, setCompanyAmount] = useState({
        before: 0,
        after: 0
    })
    const [adjustmentType, setAdjustmentType] = useState<string | null>(null)
    const formRef = React.createRef<FormInstance>();

    useEffect(() => {
        getPaymentCycles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getPaymentCycles = async () => {
        try {
            setLoadingPaymentCycles(true)
            const paymentCyclesRes: any = await helper.getPaymentCycles(userInfo.companyID, props.mostRecent, userInfo.actualCutOffDate, true, props.isException)
            if (!paymentCyclesRes.errorMessage) {
                setPaymentCycles(paymentCyclesRes)
            } else {
                message.error(paymentCyclesRes.errorMessage)
                setErrorMode(true)
            }
            setLoadingPaymentCycles(false)
        }
        catch (error: any) {
            setErrorMode(true)
            setLoadingPaymentCycles(false)
        }
    }
    const getOldAmounts = async (paymentCycle: number) => {
        try {
            setLoadingAmounts(true)
            const amountsRes: { [key: string]: any } = await dispatch(request({
                url: getUserAndCompanyAmountsAPI, method: "GET", params: {
                    userID: userInfo.userID,
                    companyID: userInfo.companyID,
                    paymentCycle
                }
            }))
            if (amountsRes.data.status === 200) {
                setCompanyAmount({
                    before: amountsRes.data.companyAmount ? amountsRes.data.companyAmount : 0,
                    after: Number(amountsRes.data.companyAmount ? amountsRes.data.companyAmount : 0) + ((adjustmentType === "overpaid" ? 1 : -1) * Number(newAmount))
                })
                setUserAmount({
                    before: amountsRes.data.userAmount ? amountsRes.data.userAmount : 0,
                    after: Number(amountsRes.data.userAmount ? amountsRes.data.userAmount : 0) + ((adjustmentType === "overpaid" ? 1 : -1) * Number(newAmount))
                })
            } else {
                message.error(amountsRes.data.message)
            }
            setLoadingAmounts(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setLoadingAmounts(false)
        }
    }

    const onAmountChange = (value: number, type?: string) => {
        const adjType = type ? type : adjustmentType
        setCompanyAmount({
            before: companyAmount.before,
            after: Number(companyAmount.before) + ((adjType === "overpaid" ? 1 : -1) * Number(value))
        })
        setUserAmount({
            before: userAmount.before,
            after: Number(userAmount.before) + ((adjType === "overpaid" ? 1 : -1) * Number(value))
        })
        setNewAmount(value)
        setAdjustmentType(adjType)
    }

    const onTypeChange = (value: any) => {
        onAmountChange(newAmount, value)
    }

    const createAdjustment = async (values: any) => {
        try {
            setSendingData(true)
            const createAdvanceRes: { [key: string]: any } = await dispatch(request({
                url: props.apiName, method: "POST", data: {
                    type: values.adjustmentType,
                    paymentCycle: values.paymentCycle,
                    amount: values.amount,
                    userID: userInfo.userID
                }
            }))
            if (createAdvanceRes.data.status === 200) {
                message.success(`${createAdvanceRes.data.message} With ID: ${createAdvanceRes.data.advanceID}`)
                dispatch(stepperMoving(0))
            }
            else if (createAdvanceRes.data.status === 400) {
                message.error(`${createAdvanceRes.data.message}, Reason: ${createAdvanceRes.data.reason}`)
            }
            else {
                message.error(createAdvanceRes.data.message)
            }
            setSendingData(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setSendingData(false)
        }
    }

    return (
        errorMode ?
            <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh the page and try again'}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
            loadingPaymentCycles ? <Spinner size="large" />
                : <div className="createAdjustment">
                    <Form name="nest-messages" onFinish={createAdjustment} ref={formRef}>
                        <Row>
                            <Col span="24">
                                <label>Adjustment Type <span>*</span></label>

                                <Form.Item
                                    name="adjustmentType"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select
                                        placeholder="Select a type"
                                        onChange={onTypeChange}>
                                        <Option value="adjustment">Underpaid</Option>
                                        <Option value="overpaid">Overpaid</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label>Start Month <span>*</span></label>
                                <Form.Item
                                    name="paymentCycle"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select a month" onChange={getOldAmounts}>
                                        {Array.isArray(paymentCycles) &&
                                            paymentCycles.map((paymentCycle: { id: number, paymentCycle: number, cutOffCycleDate: string, isLocked: boolean }) =>
                                                <Option key={paymentCycle.id} value={paymentCycle.paymentCycle}>
                                                    {moment(paymentCycle.cutOffCycleDate).format('Do MMM, YYYY')}
                                                    &nbsp; &nbsp; &nbsp;<Badge count={paymentCycle.isLocked ? "Locked invoice" : 0} />
                                                </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label>Amount <span>*</span></label>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: 'Required' }, { type: "number", min: 1 }]}
                                >
                                    <InputNumber onChange={onAmountChange} placeholder="Enter adjustment amount" />
                                </Form.Item>
                            </Col>
                            <Col span='24' className="btnCol">
                                <Form.Item>
                                    <Button shape="round" type="primary" block htmlType="submit" loading={sendingData}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {adjustmentType && <Row className="amountsInfo">
                        {
                            loadingAmounts ? <Spinner size="large" />
                                : <>
                                    {<Col span={24}>
                                        <label className="infoLabel">User Amount</label>
                                        <Descriptions bordered style={{ margin: '15px 0' }}>
                                            <Descriptions.Item label="Before">{helper.amountFormatter(userAmount.before)} EGP</Descriptions.Item>
                                            <Descriptions.Item label="After">{helper.isFloat(userAmount.after)
                                                ? helper.amountFormatter(userAmount.after?.toFixed(2))
                                                : helper.amountFormatter(userAmount.after)} EGP</Descriptions.Item>
                                        </Descriptions>
                                    </Col>}
                                    {<Col span={24}>
                                        <label className="infoLabel">Company Amount</label>
                                        <Descriptions bordered style={{ margin: '15px 0' }}>
                                            <Descriptions.Item label="Before">{helper.amountFormatter(companyAmount.before)} EGP</Descriptions.Item>
                                            <Descriptions.Item label="After">{helper.isFloat(companyAmount.after)
                                                ? helper.amountFormatter(
                                                    companyAmount.after?.toFixed(2)
                                                )
                                                : helper.amountFormatter(companyAmount.after)} EGP</Descriptions.Item>
                                        </Descriptions>
                                    </Col>}
                                </>
                        }
                    </Row>}
                    <Row>
                        <Col span={24}>
                            <label className="infoLabel">User Information</label>
                            <BasicUserInfo info={userInfo} />
                        </Col>
                    </Row>
                </div>
    )
}
