import { Form, Row, Col, Select, Button, FormInstance, message } from 'antd'
import React, { useEffect, useState } from 'react'
import ReusableTable from '../../../components/reusableTable'
import Modal from '../../Trucking/modal';
import { requestBlockingIssues } from '../../../utils/tableColumns';
import helper from '../../../utils/helper';
import { getBlockingIssuesApi, getBlockingIssuesTypesApi, updateAdvanceIssueApi } from '../../../utils/apisConsts';
import OutPutResult from '../../../components/result';
import { errorMessage } from '../../../utils/commonStringsConsts';

export default function GetBlockingIssues() {
    const formFilterRef = React.createRef<FormInstance>();
    const [issuesTypes, setIssuesTypes] = useState([]);
    const [issues, setIssues] = useState([]);
    const [typeHasBug, setTypeHasBug] = useState(false);
    const [paginating, setPaginating] = useState({
        pageSize: 10,
        total: 0,
        current: 1,
    });
    const [filter, setFilter] = useState({
        issueType: '',
    })
    const [issuesLoading, setIssuesLoader] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [clear, setClear] = useState(false);
    const [modal, setModal] = useState(false);
    const [issueID, setIssueID] = useState(0);
    const [btnLoader, setBtnLoader] = useState(false);
    const [viewPagination, setViewPagination] = useState(true);
    const [resolveText, setResolveText] = useState('')
    useEffect(() => {
        storeBlockingIssuesTypes();
        storeBlockingIssues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const storeBlockingIssuesTypes = async () => {
        try {
            const apiData: any = await helper.callApiRequest(getBlockingIssuesTypesApi, 'GET');
            const resp = apiData.data;
            if (resp.status === 200) {
                setIssuesTypes(resp.data.issueTypes);
                return
            }
            message.error(resp.message, 4)
            setTypeHasBug(true);
        } catch (error: any) {
            message.error(error.data.message, 4)
            setTypeHasBug(true);
        }
    }

    const storeBlockingIssues = async (allParams?: any) => {
        setIssuesLoader(true);
        try {
            const params = {
                count: allParams?.pageSize ? allParams?.pageSize : paginating.pageSize,
                page: allParams?.current ? allParams?.current : paginating.current,
                issueType: allParams?.issueType ? allParams.issueType : '',
            }
            const apiData: any = await helper.callApiRequest(getBlockingIssuesApi, 'GET', params);
            const resp = apiData.data;
            if (resp.status === 200) {
                await setPaginating((prevState: any) => ({
                    ...prevState,
                    total: resp?.totalOFBlockingIssues,
                }))
                allParams?.issueType ? setClear(true) : setClear(false);
                const manipulateTheData = Array.isArray(resp.data) && resp.data.map((issue: any) => {
                    return {
                        ...issue,
                        createdAt: issue?.createdAt ? helper.changeDateFormat(issue?.createdAt) : 'NA',
                        requestedAmount: issue?.requestedAmount ? helper.amountFormatter(issue?.requestedAmount) + ' EGP' : 'NA',
                        userBalance: issue.userBalance ? helper.amountFormatter(issue.userBalance) + ' Egp' : 'NA'
                    }
                });

                setIssues(manipulateTheData);
                setIssuesLoader(false);
                setViewPagination(true);
                return
            }
            if (resp.status === 404) {
                setIssuesLoader(false);
                setIssues([]);
                setViewPagination(false);
                allParams?.issueType ? setClear(true) : setClear(false);
                return
            }

            setIssuesLoader(false);
            setErrorMode(true);
        } catch (error: any) {
            setIssuesLoader(false);
            setErrorMode(true);
        }
    }
    const changePagination = async (values: any) => {
        setPaginating((prevState: any) => ({
            ...prevState,
            current: values?.current,
            pageSize: values?.pageSize
        }))
        const allParams = {
            current: values?.current,
            pageSize: values?.pageSize,
            issueType: filter.issueType,

        }
        await storeBlockingIssues(allParams);
    }
    const filterBlockingIssues = async (values: { issueType: number | any }) => {
        setFilter({
            ...filter,
            ...(values.issueType ? { issueType: values.issueType } : { issueType: '' }),
        })
        setPaginating((prevState: any) => ({
            ...prevState,
            current: 1,
        }))
        const allParams = {
            pageSize: paginating.pageSize,
            total: paginating.total,
            current: 1,
            issueType: values.issueType,
        }
        await storeBlockingIssues(allParams);
    }
    const clearsIssuesFilter = () => {
        setFilter((prevState: any) => ({
            ...prevState,
            issueType: '',
        }));
        setPaginating((prevState: any) => ({
            ...prevState,
            current: 1,
        }))
        const allParams = {
            pageSize: 10,
            total: paginating.total,
            current: 1,
            issueType: '',
        }
        formFilterRef.current!.resetFields();
        storeBlockingIssues(allParams);
    }
    const selectingAnIssue = (issueID: number,isResolved: boolean) => {
        setModal(true)
        setResolveText(isResolved?'Un-resolved':'Resolve')
        setIssueID(issueID)
    }
    const closeTheModal = () => {
        setModal(false)
    }
    const resolveRequestIssue = async () => {
        try {
            setBtnLoader(true)
            const bodyData = {
                issueID: issueID
            }
            const apiData: any = await helper.callApiRequest(updateAdvanceIssueApi, 'PATCH', {}, bodyData);
            const resp = apiData.data;
            if (resp.status === 200) {
                setBtnLoader(false);
                setModal(false);
                message.success(resp.message);
                const allParams = {
                    pageSize: paginating.pageSize,
                    total: paginating.total,
                    issueType: filter.issueType,
                }
                await storeBlockingIssues(allParams);
                return
            }
            message.error(resp.message);
            setBtnLoader(false);
            setModal(false);
        } catch (error) {
            console.log("🚀 ~ file: getIssues.tsx:153 ~ resolveRequestIssue ~ error:", error)
            setBtnLoader(false);
            message.error(errorMessage)
        }
    }
    const resolveAction = {
        title: 'Resolve',
        render: (record: { manualResolve: boolean, issueID: number, isResolved: boolean }) => (
            <>
                <Button style={record.isResolved ? { borderColor: '#6fb76c', color: '#6fb76c', width: '114px' } : { color: '#40a9ff', borderColor: '#40a9ff', }}
                    disabled={!record.manualResolve}
                    onClick={() => selectingAnIssue(record.issueID,record.isResolved)} type={"ghost"} >{record.isResolved ? 'Resolved' : 'Resolve Issue'}</Button>
            </>
        )
    };
    const columns = [...requestBlockingIssues, resolveAction];
    const content = <>
        <div className='resolveIssue'>

            <p className='question' style={{ textAlign: 'center', fontSize: '16px', fontWeight: 600 }}>Are you sure you want to {resolveText} this issue?</p>

            <Row style={{ margin: '20px 0' }}>
                <Col span={24}>
                    <Button className='decline' onClick={closeTheModal}>No </Button>
                    <Button className='approve' onClick={resolveRequestIssue} loading={btnLoader}>Yes</Button>
                </Col>

            </Row>

        </div>
    </>
    return (
        <>
            {errorMode ?
                <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh and try again'} /> :
                <><div className="filterTransaction">
                    <Form
                        ref={formFilterRef}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={filterBlockingIssues}
                        autoComplete="off"
                    >
                        <Row className=''>


                            <Col span={12} style={{ padding: '0 10px 0 0' }}>
                                <label htmlFor="">Issue Types </label>
                                <Form.Item name="issueType">
                                    <Select style={{ width: "100%" }} placeholder="Select an issue type" disabled={typeHasBug}>
                                        {
                                            Array.isArray(issuesTypes) &&
                                            issuesTypes.map((issue: any) => <Select.Option value={issue.id}>{issue.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            {true && <Col span={4} style={{ float: 'right' }}>
                                <Form.Item>

                                </Form.Item></Col>}

                            <Col span={6}>
                                <Form.Item>
                                    <div style={{ display: 'flex', padding: '20px 0' }}>
                                        {clear && <Button type="primary" onClick={clearsIssuesFilter} style={{ width: '150px', background: '#d01a1a', borderColor: '#d01a1a' }}>
                                            Clear
                                        </Button>}
                                        <Button type="primary" htmlType="submit" style={{ width: '150px', margin: ' 0 0 0 15px' }}>
                                            Search
                                        </Button>
                                    </div>
                                </Form.Item></Col>


                        </Row>
                    </Form>
                </div>
                    <ReusableTable rows={issues} columns={columns} handleTableChange={changePagination}
                        pagination={viewPagination ? paginating : false} loading={issuesLoading} scrolling={{ x: 'max-content' }} hiddenRefreshing />
                    <Modal handleCloseModal={closeTheModal} visibleModal={modal} content={content} title={'Resolve issue'} /></>}
        </>
    )
}
