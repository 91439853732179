import React, { Component } from 'react';
import { Button, Col, Dropdown, Form, FormInstance, InputNumber, Menu, message, Result, Row, Select, Spin, Switch } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import DataTable from '../../components/dataTable';
import ModalCompnent from '../../components/modal';
import PdfComponent from '../../components/pdf';
import InvoiceTemp from '../deductions/invoiceTemp';
import { request, stepperMoving } from '../../stores/actions';
import { generateInvoiceApi, getInvoicesApi, getPaymentCyclesApi, lockPaymentCycleApi, unlockPaymentCycleApi } from '../../utils/apisConsts';
import { checkSumInvoicesError, errorMessage } from '../../utils/commonStringsConsts';
import { invoicesTableColumns } from '../../utils/tableColumns';
import { AxiosRequestConfig } from 'axios';
import { FrownOutlined, SmileOutlined, DownloadOutlined } from '@ant-design/icons';
import ErrorModal from '../../components/errorModal';

const { Option } = Select

interface Props {
    companyID?: number,
    request(obj: AxiosRequestConfig): any
    stepperMoving(num: number): void,
    stepNumber: number,
    lock: boolean
}

class ViewInvoice extends Component<Props> {
    state = {
        paymentCycles: [],
        invoices: [],
        lockedInvoice: null,
        isThereLockedInvoice: false,
        approvedInvoice: false,
        locking: false,
        visibleModal: false,
        visibleInvoiceModal: false,
        pagination: {},
        tableLoading: false,
        tableColumns: [],
        selectedInvoice: {
            salariesPaid: "",
            fees: 0,
            subject: "",
            com: "",
            date: "",
            serial: "",
            total: "",
            settled: "",
            id: null,
            taxRate: 0,
            taxFees: 0,
            debitAndCredit: 0,
            discount: 0,
            previousCreditRevenue: 0
        },
        selectedPaymentCycle: 0,
        gettingInvoicesError: false,
        errorMessageState: errorMessage,
        loadingPaymentCycles: false,
        loadingGenerate: false,
        lockCounter: 0,
        generateInvoiceForm: {
            visible: false,
            withTax: false,
            taxRate: 3
        },
        visibleErrorModal: false
    }
    invoiceFormRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.setTableColumns()
        this.getPaymentCycles()
    }
    componentDidUpdate(prevProps: any, prevState: any): void {
        if (prevState.lockCounter !== this.state.lockCounter && this.state.lockCounter >= 0) {
            this.setTableColumns()
        }
    }

    setTableColumns = () => {
        const pdfMenu = (invoice: any) => (
            <Menu>
                <Menu.Item
                    icon={<DownloadOutlined />}
                    onClick={() => this.openPdf(invoice, true)}
                >
                    With Fees
                </Menu.Item>
                <Menu.Item
                    icon={<DownloadOutlined />}
                    onClick={() => this.openPdf(invoice, false)}
                    disabled={invoice.taxRatePercent}
                >
                    Without Fees
                </Menu.Item>
            </Menu>
        );
        this.setState({
            tableColumns: [
                ...invoicesTableColumns,
                {
                    title: 'PDF',
                    dataIndex: '',
                    width: 30,
                    render: (row: any) => <Dropdown overlay={pdfMenu(row)}><Button>Show</Button></Dropdown>
                },
                {
                    title: this.props.lock ? 'Lock' : 'Unlock',
                    dataIndex: '',
                    width: 30,
                    render: (row: any) => !this.state.isThereLockedInvoice || row.lockedInvoice ?
                        <Button style={{ color: "blue", borderColor: "blue" }} loading={this.state.locking} onClick={row.handleOpenLockModal}
                            disabled={(row.lockedInvoice && this.props.lock) || (!this.state.isThereLockedInvoice && !this.props.lock) || (this.props.lock && !this.state.lockCounter)}>
                            {row.lockedInvoice ? (!this.props.lock ? "Unlock" : "Locked") : (this.props.lock ? `Lock (${this.state.lockCounter})` : "Unlocked")}
                        </Button> : ""
                },
            ]
        })
    }

    getPaymentCycles = async () => {
        try {
            this.setState({ loadingPaymentCycles: true, paymentCycles: [] });
            const params = {
                companyID: this.props.companyID
            }
            const paymentCycles = await this.props.request({ url: getPaymentCyclesApi, params, method: "get" })
            console.log("🚀 ~ file: viewInvoice.tsx ~ line 65 ~ ViewInvoice ~ getPaymentCycles= ~ paymentCycles", paymentCycles)
            if (paymentCycles.data.status === 200) {
                this.setState({ paymentCycles: paymentCycles.data.allPaymentCycles })
            } else {
                message.error(paymentCycles.data.message, 4);
                this.props.stepperMoving(this.props.stepNumber - 1);
            }
            this.setState({ loadingPaymentCycles: false })
        }
        catch (e: any) {
            message.error(errorMessage, 4);
            this.props.stepperMoving(this.props.stepNumber - 1);
            this.setState({ loadingPaymentCycles: false })
        }
    }

    getInvoices = async (paymentCycle: number) => {
        try {
            this.setState({ tableLoading: true })
            const invoices = await this.props.request({
                url: getInvoicesApi, method: "get", params: {
                    companyID: this.props.companyID,
                    paymentCycle
                }
            })
            if (invoices.data.status === 200) {
                const data: { [key: string]: any }[] = invoices.data.data.latestInvoices
                const lockedInvoice: { [key: string]: any } = invoices.data.data.locked ? {
                    ...invoices.data.data.lockedInvoice,
                    lockedInvoice: true
                } : null

                if (lockedInvoice) data.unshift(lockedInvoice)

                const invoicesArr = data.map((invoice: {}) => {
                    return {
                        ...invoice,
                        handleOpenLockModal: () => this.handleOpenLockModal(invoice),
                    }
                })
                console.log("🚀 ~ file: viewInvoice.tsx:146 ~ ViewInvoice ~ invoicesArr ~ invoicesArr", invoicesArr)
                this.setState({ invoices: invoicesArr, lockedInvoice: lockedInvoice, isThereLockedInvoice: invoices.data.data.locked, selectedPaymentCycle: paymentCycle })
            } else {
                this.setState({ gettingInvoicesError: true, invoices: [], errorMessageState: invoices.data.message })
            }
            this.setState({ tableLoading: false })
        }
        catch (e: any) {
            this.setState({ tableLoading: false, gettingInvoicesError: true, invoices: [], errorMessageState: errorMessage })
        }
    }

    handleLockPaymentCycle = async () => {
        this.setState({ locking: true })
        this.handleCloseModal()
        try {
            const res = await this.props.request({
                url: this.props.lock ? lockPaymentCycleApi : unlockPaymentCycleApi, method: 'PATCH',
                data: {
                    companyID: this.props.companyID,
                    paymentCycle: this.state.selectedPaymentCycle,
                    ...((this.state.selectedInvoice && this.state.selectedInvoice.id && this.props.lock) ? { invoiceID: this.state.selectedInvoice.id } : {}),
                }
            })
            console.log("🚀 ~ file: viewInvoice.tsx ~ line 137 ~ ViewInvoice ~ handleLockPaymentCycle= ~ res", res)
            if (res.data.status === 200) {
                this.setState({ locking: false })
                message.success(res.data.message, 4)
                this.getInvoices(this.state.selectedPaymentCycle)
            } else {
                message.error(res.data.message, 4);
            }
        }
        catch (error) {
            console.log("🚀 ~ file: viewInvoice.tsx ~ line 135 ~ ViewInvoice ~ handleLockPaymentCycle= ~ error", error)
            this.setState({ locking: false })
            message.error(errorMessage, 4)
        }
    }

    handleOpenLockModal = async (invoice: { [key: string]: any }) => {
        console.log("🚀 ~ file: viewInvoice.tsx ~ line 146 ~ ViewInvoice ~ handleOpenLockModal= ~ invoice", invoice)
        this.setState({ selectedInvoice: invoice.lockedInvoice ? null : invoice, visibleModal: true })
    }

    handleCloseModal = () => {
        this.setState({ visibleInvoiceModal: false, visibleModal: false, selectedInvoice: null })
    }

    openPdf = (invoice: any, withFees: boolean) => {
        console.log("🚀 ~ file: viewInvoice.tsx:195 ~ ViewInvoice ~ invoice", invoice)
        const date: { cutOffCycleDate: string } = this.state.paymentCycles.filter((paymentCycle: any) => paymentCycle.paymentCycle === invoice.paymentCycle)[0]
        this.setState({
            selectedInvoice: {
                salariesPaid: invoice.claim ? (Number(invoice.claim)).toFixed(2) : 0,
                ...(withFees && { fees: invoice.netRevenue ? Number(invoice.netRevenue).toFixed(2) : 0 }),
                subject: this.covertDateFormat(date.cutOffCycleDate) + " Claim",
                com: invoice.companyName,
                date: date.cutOffCycleDate,
                serial: invoice.serialNumber,
                settled: invoice.settled ? Number(invoice.settled).toFixed(2) : 0,
                taxRate: invoice.taxRatePercent ? invoice.taxRatePercent : null,
                taxFees: (invoice.taxAmount && withFees) ? (Number(invoice.taxAmount)).toFixed(2) : null,
                debitAndCredit: invoice.debitAndCreditAmounts.debitAndCredit,
                ...(withFees && {previousCreditRevenue: invoice?.previousCreditRevenue}),
                discount: invoice.discount,
                total: Number(invoice.total) ? Number(invoice.total).toFixed(2) : 0,
            },
            visibleInvoiceModal: true
        })
    }

    covertDateFormat = (date: string, isPaymentCycle?: boolean) => {
        if (isPaymentCycle) {
            return moment(date).format('MMMM YYYY')
        }
        return moment(date).format('MMMM DD, YYYY')
    }

    generateInvoice = async (values: any) => {
        try {
            this.setState({ loadingGenerate: true })
            this.toggleGenerateInvoiceForm(false)
            const invoiceData = await this.props.request({
                url: generateInvoiceApi, method: "GET", params: {
                    companyID: this.props.companyID,
                    paymentCycle: this.state.selectedPaymentCycle,
                    deductTaxRate: values.withTax ? values.taxRate : 0,
                    generateNew: 1
                }
            })
            if (invoiceData.data.status === 200) {
                this.getInvoices(this.state.selectedPaymentCycle)
                this.setState({ lockCounter: 90 }, () => {
                    const interval = setInterval(() => {
                        this.setState({ lockCounter: this.state.lockCounter - 1 }, () => {
                            if (this.state.lockCounter === 0) {
                                clearInterval(interval)
                            }
                        })
                    }, 1000)
                })
            }
            else {
                this.setState({ visibleErrorModal: true, errorMessageState: invoiceData.data.message })
            }
            this.setState({ loadingGenerate: false })
        }
        catch (e) {
            this.setState({ loadingGenerate: false })
            message.error(errorMessage, 4)
        }
    }

    closeErrorModal = () => {
        this.setState({ visibleErrorModal: false })
    }

    toggleGenerateInvoiceForm = (val: boolean) => {
        this.setState({
            generateInvoiceForm: {
                ...this.state.generateInvoiceForm,
                visible: val,
                withTax: false
            }
        }, () => {
            this.invoiceFormRef.current?.resetFields();
        })
    }

    render() {
        const { invoices, visibleModal, visibleInvoiceModal, pagination, tableLoading, paymentCycles, tableColumns, selectedInvoice,
            gettingInvoicesError, errorMessageState, loadingPaymentCycles, selectedPaymentCycle, lockedInvoice, loadingGenerate, generateInvoiceForm } = this.state
        const generateInvoiceModalContent = <>
            <Form
                ref={this.invoiceFormRef}
                name="basic"
                onFinish={this.generateInvoice}
                autoComplete="off"
                initialValues={generateInvoiceForm}
            >
                <Row className="statusRow">
                    <Col span={24}>
                        <label style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "15px" }}>Please enter suitable options</label>
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Deduct Tax Rate on Service Fees </label>
                        <Form.Item name="withTax">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={generateInvoiceForm.withTax}
                                onChange={(val) => this.setState({ generateInvoiceForm: { ...generateInvoiceForm, withTax: val } })}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Tax Rate on Service Fees {generateInvoiceForm.withTax && <span>*</span>}</label>
                        <Form.Item name="taxRate" rules={[
                            { required: generateInvoiceForm.withTax, message: "Tax rate is mandatory!" },
                            { type: "number", min: 1, max: 100, message: "The rate must be between 1 and 100" },
                        ]}>
                            <InputNumber placeholder='Enter the rate' disabled={!generateInvoiceForm.withTax} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
        return (
            <div className='approveInvoice'>
                <Row className='paymentCycles'>
                    <Col span={8}>
                        <label htmlFor="">Invoices months </label>
                        <Select
                            onChange={this.getInvoices}
                            placeholder='Please select an invoice month'
                            loading={loadingPaymentCycles}
                        >
                            {
                                Array.isArray(paymentCycles) &&
                                paymentCycles.reverse().map((paymentCycle: { paymentCycle: number, cutOffCycleDate: string }, index) =>
                                    <Option key={"PC" + index} value={paymentCycle.paymentCycle}>{this.covertDateFormat(paymentCycle.cutOffCycleDate, true)}</Option>)
                            }

                        </Select>
                    </Col>
                    {!lockedInvoice && selectedPaymentCycle && this.props.lock ? <Col span={16} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Button onClick={() => this.toggleGenerateInvoiceForm(true)} disabled={tableLoading} loading={loadingGenerate} type="primary">
                            Generate Invoice
                        </Button>
                    </Col> : ""}
                </Row>
                <Spin spinning={Array.isArray(invoices) && invoices.length === 0 && tableLoading}>
                    {
                        Array.isArray(invoices) && invoices.length > 0 ?
                            <DataTable columns={tableColumns}
                                pagination={pagination}
                                rows={invoices}
                                handleTableChange={() => { }}
                                loading={tableLoading}
                                scrolling={{ x: 500, y: 300 }}
                            />
                            :
                            <Result
                                icon={gettingInvoicesError ? <FrownOutlined /> : <SmileOutlined />}
                                title={gettingInvoicesError ? errorMessageState : "Hello, There are no invoices, Please select an invoice month!"}
                            />
                    }
                </Spin>

                <ModalCompnent visibleModal={visibleInvoiceModal} handleCloseModal={this.handleCloseModal} handleAcceptModal={this.handleCloseModal}
                    content={<PdfComponent content={<InvoiceTemp data={selectedInvoice} />} />} none="none" />

                <ModalCompnent title={'Approve Invoice'} visibleModal={visibleModal} handleAcceptModal={this.handleLockPaymentCycle} handleCloseModal={this.handleCloseModal}
                    content={<p style={{ fontSize: '17px', fontWeight: 'lighter' }}>Are you sure you want to change this month invoice status?</p>} okText="Confirm" />
                <ModalCompnent okText='Generate Invoice' visibleModal={generateInvoiceForm.visible} handleAcceptModal={() => this.invoiceFormRef.current?.submit()} handleCloseModal={() => this.toggleGenerateInvoiceForm(false)}
                    content={generateInvoiceModalContent} />
                <ErrorModal handleCloseModal={this.closeErrorModal} visibleModal={this.state.visibleErrorModal} errorMessage={checkSumInvoicesError} warn/>

            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    companyID: state.actionsReducer.info,
    stepNumber: state.actionsReducer.stepNumber
})

export default connect(mapStateToProps, { request, stepperMoving })(ViewInvoice)
