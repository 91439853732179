import { Form, Row, Col, InputNumber, Button, DatePicker, FormInstance, Select, message, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { request } from '../apis/axiosRequest';
import ReusableTable from '../components/reusableTable'
import helper from '../utils/helper';
import moment from "moment";
import { getOnlineTransactionsApi, getRequestsStatusApi, getStoreNamesApi, updateOnlineTransactionsApi } from '../utils/apisConsts';
import Modal from './Trucking/modal';
import { patchApi } from '../apis/patchRequest';
import { exportToCSV } from '../components/exportExcel';
import { failedToDownloadMessage } from '../utils/commonStringsConsts';


export default function OnlineTransactions() {
    const formFilterRef = React.createRef<FormInstance>();
    const commentRef: any = React.useRef();
    const formRef: any = React.useRef([]);

    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';
    const [paginating, setPaginating] = useState({
        pageSize: 10,
        total: 0,
        current: 1,
    });
    const [filter, setFilter] = useState({
        dateFrom: '',
        dateTo: '',
        requestID: '',
        storeID: '',
        statusID: '',
    })
    const [storeNames, setStoreNames] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [tableSpin, setTableSpin] = useState(false);
    const [canClear, setCanClear] = useState(false);
    const [storeDisabled, setStoreDisabled] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [statuesDisabled, setStatuesDisabled] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [requestID, setRequestID] = useState(0);
    const [newStatus, setNewStatus] = useState(0);
    const [updateLoader, setUpdateLoader] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getStoreNames();
        getOnlineTransactions();
        getRequestsStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const downloadTransactions = async () => {
        const paramsToDownload = {
            dateFrom: filter?.dateFrom ? filter.dateFrom : '',
            dateTo: filter?.dateTo ? filter.dateTo : '',
            requestID: filter?.requestID ? filter.requestID : '',
            storeID: filter?.storeID ? filter.storeID : '',
            statusID: filter?.statusID ? filter.statusID : '',
        }
        getOnlineTransactions(null, paramsToDownload, true)

    }
    const getOnlineTransactions = async (data?: any, paramsToDownload?: any, down?: boolean) => {
        setTableSpin(true)
        const params = {
            pageNum: data?.current ? data?.current : paginating.current,
            rowsPerPage: data?.pageSize ? data?.pageSize : paginating.pageSize,
            dateFrom: data?.dateFrom ? data?.dateFrom : '',
            dateTo: data?.dateTo ? data?.dateTo : '',
            requestID: data?.requestID ? data.requestID : '',
            storeID: data?.storeID ? data.storeID : '',
            statusID: data?.statusID ? data.statusID : '',
        }
        try {
            const apiData: any = await dispatch(request({ url: getOnlineTransactionsApi, params: paramsToDownload ? paramsToDownload : params }));
            const res = await apiData.data;

            if (down === true) {
                if (res.status === 200) {
                    exportToCSV(res?.request, 'Online Transactions');
                    setTableSpin(false);
                    return
                }
                message.error(failedToDownloadMessage);
                setTableSpin(false);
            } else {
                if (res.status === 200) {
                    await setPaginating((prevState: any) => ({
                        ...prevState,
                        total: res?.numberOfRequest,
                        current: data?.current,
                    }))
                    const modifiedTransactions = res?.request.map((transaction: any) => {
                        return {
                            ...transaction,
                            dateOfRequest: helper.changeDateFormat(transaction.dateOfRequest),
                            numberOfMonths: transaction.numberOfMonths > 1 ? `${transaction.numberOfMonths} months` : 'month',
                            requestedAmount: helper.amountFormatter(transaction.requestedAmount) + ' EGP',
                            createdAt: helper.changeDateFormat(transaction.createdAt)

                        }
                    })
                    setTransactions(modifiedTransactions);
                    setTableSpin(false);
                    return
                }
                setPaginating((prevState: any) => ({
                    ...prevState,
                    total: res?.numberOfRequest
                }))
                setTransactions([])
                setTableSpin(false)
            }


        } catch (error) {
            setTableSpin(false)
        }
    }
    const getStoreNames = async () => {
        try {
            const apiData: any = await dispatch(request({ url: getStoreNamesApi }));
            const res = await apiData.data;
            if (res.status === 200) {
                setStoreNames(res.productsStores);
                return
            }
            message.error(`Will not able to filter by store names now`, 4);
            setStoreDisabled(true)

        } catch (error) {
            message.error(`Will not able to filter by store names now`, 4)
            setStoreDisabled(true)
        }
    }

    const getRequestsStatuses = async () => {
        try {
            const apiData: any = await dispatch(request({ url: getRequestsStatusApi }));
            const res = await apiData.data;
            if (res.status === 200) {
                setStatuses(res.advanceStatus);
                return
            }
            message.error(`Will not able to filter by request status now`, 4);
            setStatuesDisabled(true)

        } catch (error) {
            message.error(`Will not able to filter by request status now`, 4)
            setStatuesDisabled(true)
        }
    }

    const changePagination = async (values: any) => {
        await setPaginating((prevState: any) => ({
            ...prevState,
            current: values?.current,
            pageSize: values.pageSize
        }))

        const data = {
            pageSize: values.pageSize,
            current: values?.current,
            dateFrom: filter?.dateFrom ? filter.dateFrom : '',
            dateTo: filter?.dateTo ? filter.dateTo : '',
            requestID: filter?.requestID ? filter.requestID : '',
            storeID: filter?.storeID ? filter.storeID : '',
            statusID: filter?.statusID ? filter.statusID : '',
        }
        await getOnlineTransactions(data);
    }

    const filterTransactions = async (values: any) => {

        if (
            !values.dateRange &&
            !values.requestID &&
            !values.storeID &&
            !values.statusID
        ) {
            message.error(`You can't search on nothing`, 4);
            return;
        }
        setTableSpin(true)

        setFilter({
            ...filter,
            ...(values.requestID ? { requestID: values.requestID } : { requestID: '' }),
            ...(values.storeID ? { storeID: values.storeID } : { storeID: '' }),
            ...(values.statusID ? { statusID: values.statusID } : { statusID: '' }),
            ...(values.dateRange ? { dateFrom: moment(values.dateRange[0]._d).format("YYYY-MM-DD") } : { dateFrom: '' }),
            ...(values.dateRange ? { dateTo: moment(values.dateRange[1]._d).format("YYYY-MM-DD") } : { dateTo: '' }),
        })
        const data = {
            pageSize: paginating.pageSize,
            total: paginating.total,
            current: 1,
            dateFrom: values?.dateRange ? moment(values.dateRange[0]._d).format("YYYY-MM-DD") : '',
            dateTo: values?.dateRange ? moment(values.dateRange[1]._d).format("YYYY-MM-DD") : '',
            requestID: values?.requestID ? values?.requestID : '',
            storeID: values?.storeID ? values?.storeID : '',
            statusID: values?.statusID ? values.statusID : '',
        }
        getOnlineTransactions(data);
        setCanClear(true)
    }

    const clearFilter = () => {
        setTableSpin(true);
        setFilter((prevState: any) => ({
            ...prevState,
            dateFrom: '',
            dateTo: '',
            requestID: '',
            storeID: '',
            statusID: '',
        }))
        const data = {
            pageSize: paginating.pageSize,
            total: paginating.total,
            current: 1,
            dateFrom: '',
            dateTo: '',
            requestID: '',
            storeID: '',
            statusID: '',
        }
        getOnlineTransactions(data);
        formFilterRef.current!.resetFields();
        setCanClear(false)
    }
    const closeTheModal = () => {
        commentRef.current?.resetFields();
        setOpenModal(false);
        requestID ? formRef.current[requestID]!.resetFields() : formFilterRef.current!?.resetFields();
    }
    const changeTransactionsStatuses = async (id: number, newStatusID: any) => {
        setNewStatus(newStatusID)
        setRequestID(id);
        setOpenModal(true)
    }

    const changeTransactionsToNewStatuses = async (values: { comment: string }) => {
        setUpdateLoader(true);
        const data = {
            statusID: Number(newStatus),
            comment: values?.comment,
        }
        // const apiData: any = await dispatch(request({ method: 'PATCH', url: `${updateOnlineTransactionsApi}${requestID}`, data: data }));
        const apiData: any = await dispatch(patchApi(`${updateOnlineTransactionsApi}${requestID}`, data));

        try {
            const res = await apiData.payload;
            await closeTheModal()
            if (res.status === 200) {
                message.success(res.message);
                setUpdateLoader(false);
                setOpenModal(false);

                const data = {
                    pageSize: paginating.pageSize,
                    current: paginating?.current,
                    dateFrom: filter?.dateFrom ? filter.dateFrom : '',
                    dateTo: filter?.dateTo ? filter.dateTo : '',
                    requestID: filter?.requestID ? filter.requestID : '',
                    storeID: filter?.storeID ? filter.storeID : '',
                    statusID: filter?.statusID ? filter.statusID : '',
                }
                await getOnlineTransactions(data);
                return
            }
            setUpdateLoader(false);
            message.error(res.message)
        } catch (error) {
            setUpdateLoader(false);
            message.error(`Something went wrong, please try again`)
        }
    }
    const columns: any = [
        {

            title: (`Change Status`),
            key: 'changeStatus',
            width: 180,
            fixed: true,
            render: (_text: any, record: any) => (
                <>
                    <Form name="nest-messages"
                        ref={(el: any) => (formRef.current[record.requestID] = el)}>
                        {<Form.Item
                            name={"status" + record.requestID}
                        >
                            <Select allowClear style={{ width: "100%" }} placeholder="Select Status" onChange={(e: any) => changeTransactionsStatuses(record.requestID, e)}>
                                {

                                    Array.isArray(statuses) &&
                                    statuses.map((status: any, key: any) => {
                                        return <Select.Option value={status.advancesStatusTypeID} key={key}>{status.StatusType}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>}
                    </Form>
                </>
            )
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
        },
        {
            title: 'Order ID',
            dataIndex: 'orderID',
        },
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
        },
        {
            title: 'Phone 1',
            dataIndex: 'phone1',
        },
        {
            title: 'Phone 2',
            dataIndex: 'phone2',
        },
        {
            title: 'Amount',
            dataIndex: 'requestedAmount',
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
        },
        {
            title: 'Order Address',
            dataIndex: 'address',
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
        },

        {
            title: 'Company Name',
            dataIndex: 'companyName',

        },
        {
            title: 'User ID',
            dataIndex: 'userID',
        },
        {
            title: 'Advance ID',
            dataIndex: 'requestID',
        },
        {
            title: 'Amount',
            dataIndex: 'requestedAmount',
        },
        {
            title: 'Merchant Name',
            dataIndex: 'storeName',
        },
        {
            title: 'Service Type',
            dataIndex: 'serviceType',
        },
        {
            title: 'Number of Installments',
            dataIndex: 'numberOfMonths',
        },

        {
            title: 'Date Of Request',
            dataIndex: 'dateOfRequest',
        },










    ];
    const content = <>
        <div className='declineRequest'>
            <Form
                ref={commentRef}
                name="changeStatus"
                initialValues={{ remember: true }}
                onFinish={changeTransactionsToNewStatuses}
                autoComplete="off"
            >
                <p className='question' style={{ textAlign: 'left' }}>Are you sure to change the transaction status?</p>

                {<> <label htmlFor="">Comment </label>
                    <Form.Item name='comment' rules={[{ required: (newStatus === 7 || newStatus === 5) ? true : false, message: 'Required' }]}>
                        <Input.TextArea placeholder='Why you want to change the status ? ' style={{ width: '100%' }} />
                    </Form.Item></>}
                <Row style={{ margin: '20px 0' }}>
                    <Col span={24}>
                        <Form.Item>
                            <Button className='decline' onClick={closeTheModal}>No </Button>
                            <Button className='approve' htmlType="submit" loading={updateLoader}>Yes</Button>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </div>
    </>
    return (
        <>
            <div className="filterTransaction">
                <Form
                    ref={formFilterRef}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={filterTransactions}
                    autoComplete="off"
                >
                    <Row className=''>
                        <Col span={6} style={{ padding: '0 10px 0 0' }}>

                            <label htmlFor="">Date </label>
                            <Form.Item name="dateRange">
                                <RangePicker format={dateFormat} allowClear/>
                            </Form.Item>

                        </Col>

                        <Col span={6} style={{ padding: '0 10px 0 0' }}>
                            <label htmlFor="">Store Name </label>
                            <Form.Item name="storeID">
                                <Select allowClear style={{ width: "100%" }} placeholder="Select the store" disabled={storeDisabled}>
                                    {
                                        Array.isArray(storeNames) &&
                                        storeNames.map((store: any) => <Select.Option value={store.id}>{store.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6} style={{ padding: '0 10px 0 0' }}>
                            <label htmlFor="">Request Statuses </label>
                            <Form.Item name="statusID">
                                <Select allowClear style={{ width: "100%" }} placeholder="Select status" disabled={statuesDisabled}>
                                    {
                                        Array.isArray(statuses) &&
                                        statuses.map((status: any) => <Select.Option value={status.advancesStatusTypeID}>{status.StatusType}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6} style={{ padding: '0 10px 0 0' }}>
                            <label htmlFor="">Request ID </label>
                            <Form.Item name='requestID'>
                                <InputNumber placeholder='Search by request id' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row style={{ textAlign: 'center', margin: '40px 0px', justifyContent: 'center' }}>

                        {canClear && <Col span={4} style={{}}>
                            <Form.Item>
                                <Button type="primary" onClick={clearFilter} style={{ width: '150px', background: '#d01a1a', borderColor: '#d01a1a' }}>
                                    Clear
                                </Button>
                            </Form.Item></Col>}

                        <Col span={4}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '150px' }}>
                                    Search
                                </Button>
                            </Form.Item></Col>
                        <Col span={4}>
                            <Form.Item>
                                <Button type="primary" onClick={downloadTransactions} style={{ width: '150px', background: '#357C3C', borderColor: '#357C3C' }}>
                                    Download
                                </Button>
                            </Form.Item></Col>
                    </Row>
                </Form>
            </div>
            <ReusableTable rows={transactions} columns={columns} handleTableChange={changePagination}
                pagination={paginating} loading={tableSpin} scrolling={{ x: 'max-content' }} hiddenRefreshing />
            <Modal handleCloseModal={closeTheModal} visibleModal={openModal} content={content} title={'Change Request Status'} />

        </>
    )
}