import { Button, Col, ConfigProvider, Form, InputNumber, message, Row } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { request } from '../../../apis/axiosRequest';
import { createVirtualDepositApi } from '../../../utils/apisConsts';
import { arabicErrorMessage } from '../../../utils/commonStringsConsts';
import { supervisorsListAccountantColumns } from '../../../utils/tableColumns';
import DriversList from '../driversList/driversList'
import TruckingModal from '../modal';

export default function AddVirtualDeposit() {
  const depositRef: { current: any } = React.useRef();
  const dispatch = useDispatch();
  const [truckingUserID, setTruckingUserID] = useState(0);
  const [openTruckingModal, setOpenTruckingModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getUsersData, setGetUsersData] = useState<any>();

  const addDepositBtn = {
    render: (row: any) => <Button onClick={() => openDepositModal(row)}>تخصيص</Button>
  }
  const columns: any = [...supervisorsListAccountantColumns, addDepositBtn];
  const openDepositModal = (row: { id: number }) => {
    setTruckingUserID(row.id);
    setOpenTruckingModal(true);
  }
  const closeTruckingModal = () => {
    setOpenTruckingModal(false);
    depositRef.current!.resetFields();
  }
  const createDeposit = async (values: { virtualDeposit: number }) => {
    setLoader(true);
    const data = {
      userID: truckingUserID,
      amount: values?.virtualDeposit
    }
    try {
      const apiData: any = await dispatch(request({ url: createVirtualDepositApi, method: 'POST', data: data }));
      const resp = apiData.data;
      if (resp.status === 200) {
        message.success(`تم تخصيص المبلغ بنجاح`);
        setLoader(false);
        closeTruckingModal();
        getUsersData && getUsersData();
        return
      }
      message.error(resp.message);
      setLoader(false);
    } catch (error) {
      console.log("🚀 ~ file: addVirtualDeposit.tsx:54 ~ createDeposit ~ error:", error)
      message.error(arabicErrorMessage)
      setLoader(false);
    }

  }


  const depositContent = <>
    <div className='changeUserStatus'>
      <Form name="nest-messages" onFinish={createDeposit} ref={depositRef}>

        <label className='label'>المبلغ</label>

        <Form.Item
          name="virtualDeposit"
          rules={[{ required: true, message: 'اجباري' }, { type: 'number', min: 1, message: 'يجب ان لا يكون اصغر من جنيه' }]}
        >
          <InputNumber placeholder={'تخصيص المبلغ'} style={{ width: '100%' }} />

        </Form.Item>

        <br />
        <Row>
          <Col>
            <Form.Item>
              <Button className='approve' htmlType="submit" loading={loader}>نعم، تخصيص</Button>
            </Form.Item>
          </Col>
          <Col>
            <Button className='decline' onClick={closeTruckingModal}>لا اريد التخصيص</Button>
          </Col>
        </Row>
      </Form>


    </div>
  </>
  return (
    <>
      <DriversList columns={columns} searchable={false} role='Supervisor' reGetSupervisors={(getUsersFunc: any) => setGetUsersData(() => getUsersFunc)} />

      <ConfigProvider direction='rtl'>

        <TruckingModal handleCloseModal={closeTruckingModal} visibleModal={openTruckingModal}
          content={depositContent} title={'تخصيص الاموال'} />
      </ConfigProvider>
    </>
  )
}
