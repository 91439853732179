import { useSelector } from 'react-redux';
import AddServiceLimit from '../../components/addServiceLimit';
import { userInfo } from '../../utils/commonInterfaces';
interface IRootState {
    [key: string]: {
        info?: userInfo,
    }
}
export default function UpdateUserCaps() {
    const info: any = useSelector<IRootState>(state => state.actionsReducer.info);
    return (<>
        <AddServiceLimit defaultCaps={{
            totalReqLimit: info.maxMonthlyRequestsLimit,
            reqsNum: info.onGoingAdvancesRequests,
            salaryPercent: info.onGoingAdvancesPercentThreshold
        }} 
        id={info.userID} isUser url='services/updateUserCap'/>
    </>)
}