import { message, Upload } from "antd";
import moment from "moment";
import { request } from "../apis/axiosRequest";
import {
    allCompanies, deployedCompanies, getTruckingCompaniesApi, getPaymentCyclesApi,
    getUserInfoApi, sendOtpTwilioApi, unDeployedCompanies, getRequestInstallmentsApi,
    getRequestsStatusApi, getDepartmentsApi, getAllSuspensionReasonsApi,
    getCompanySuspensionReasonsApi, getServiceGroupsApi
} from "./apisConsts";
import XLSX from "xlsx";

import store from '../stores/index'
import { errorMessage } from "./commonStringsConsts";
import { viewAccountTable } from "../stores/actions";

const dispatch = store.dispatch;
class Helper {

    unCamelCase = (str: string) => {
        return str
            // insert a space between lower & upper
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            // space before last upper in a sequence followed by lower
            .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
            // uppercase the first character
            .replace(/^./, function (str) { return str.toUpperCase(); })
    }

    getNumberFromString(string: string, stringRemoved: string): string {
        const numberString = string.replace(",", "");
        const numberWithoutCurrencySymbol = numberString.replace(stringRemoved, "");
        return numberWithoutCurrencySymbol;
    }

    refreshPage = () => {
        window.location.reload();
    }
    isArabic = (text: any) => {
        var arabic = /[\u0600-\u06FF]/;
        let result = arabic.test(text);
        return result;
    }
    changeDateFormat = (date: any, formatToApply = 'YYYY-MM-DD') => {
        return moment(date).utc().format(formatToApply);
    }
    replaceTheDate = (symbol: string, date: string) => {
        const [year, month, day] = this.changeDateFormat(date).split(symbol);
        const formattedDate: string = `${day}-${month}-${year}`;
        return formattedDate
    }

    changeKeyByValue = (options: { key: string, value: string }[]) => {
        let changedKeys: any = [];
        for (let option = 0; option < options.length; option++) {
            changedKeys.push({ [options[option].key]: options[option].value });
        }
        changedKeys = changedKeys.reduce(function (result: any, item: any) {
            let key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
        }, {});
        return changedKeys;
    }

    changeTruckingDateFormat = (date: string) => {
        return moment(date).utc().format('DD/MM/YYYY')
    }
    amountFormatter = (num?: number | string) => {
        return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    getSequenceOfNumbers = (num: number, startOfZero?: boolean) => {
        let arrOfNumbers: number[] = [];
        for (let index = 0; index < num; index++) {
            if (startOfZero) {
                arrOfNumbers.push(index)
            } else {
                arrOfNumbers.push(index + 1)
            }
        };
        return arrOfNumbers;
    }

    validKeys = (options: { key: string, value: string }[]) => {
        let element = options[0].key
        for (let option = 0; option < options.length; option++) {
            const allOptions = options[option];
            if (option > 0) {
                if (option <= options.length - 1) {

                    if (element === allOptions.key) {
                        console.log('element = ', element, 'start = ', allOptions.key);
                        message.error(`key= '${allOptions.key}' is repeated more than one time, use it only once`, 4);
                        return true
                    } else {
                        element = options[option].key;
                        return false
                    }
                }
            }
        }
    }

    regexValidation: any = {
        englishRegex: /^[a-zA-Z0-9$-@$!%,:*?&#^-_. +]+$/,
        englishStringRegex: /^[a-zA-Z]{2}[a-zA-Z ]+$/,
        arabicRegex: /[\u0600-\u06FF]/,
        xlsxCsvRegex: /(\.csv|\.xlsx)$/i,
        // egyptPhoneNum: /^((\+?20)|0)?1[01258]\d{8}$/,
        egyptPhoneNum: /\d/,
        phoneNum: /^(010|011|012|015|018)\d{8}$/,
        integerNumbers: /^[0-9]*$/,
        positiveIntegerNumbers: /^[1-9][0-9]*$/,
        hotlineRegex: /^[0-9]{5,11}$/,
        email: /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4}|museum)$/,
        urlRegex: /^(ftp|http|https):\/\/[^ "]+$/,
        nationalId: /^([1-9]{1})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})[0-9]{3}([0-9]{1})[0-9]{1}$/,
        accountNumber: /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/,
        spacesNotAllowed: /^\S+$/,
        variableCases: /^[a-zA-Z_]+$/,
    }
    dataFile = {
        beforeUpload: (file: { size: any, name: string; }) => {
            let file_size = file.size / (1024 * 1024), maxSize = 2, size;
            if (file.size < 1024) {
                size = file.size + ' B';
            } else if (file.size < (1024 * 1024)) {
                size = Math.floor(file.size / 1024) + ' KB';
            } else {
                size = Math.floor(file.size / (1024 * 1024)) + ' MB';
            }
            let acceptable_exe = /(\.png|\.jpg|\.jpeg)$/i;
            if (!acceptable_exe.exec(file.name)) {
                message.error(`The extension of (${file.name}) is not supported!`);
                return Upload.LIST_IGNORE
            } else if (file_size > maxSize) {
                message.error(`File size = ${size} exceeds ${maxSize} MB`);
                return Upload.LIST_IGNORE
            } else {
                return true
            }

        }
    };
    isFloat = (num: number) => {
        return !!(num % 1);
    };
    sendOtpTwilio = (phone: string, type: (1 | 2), lang: ("ar" | "en")) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res: { [key: string]: any } = await dispatch(request({
                    url: sendOtpTwilioApi, method: "POST", data: {
                        phone,
                        type,
                        lang
                    }
                }))
                resolve(res.data)
            }
            catch (error) {
                console.log("🚀 ~ file: helper.ts ~ line 70 ~ Helper ~ error", error)
                reject(error)
            }
        })
    }
    getUserInfo = (phone?: any, userID?: any) => {
        let params: any;
        if (userID) {
            params = {
                userID: userID
            };
            console.log(params);

        } else {
            params = {
                phone: phone
            };
            console.log(params);

        }


        return new Promise(async (resolve, reject) => {
            try {
                const res: { [key: string]: any } = await dispatch(request({ url: getUserInfoApi, method: "GET", params }))
                resolve(res.data)
            }
            catch (error) {
                console.log("🚀 ~ file: helper.ts ~ line 70 ~ Helper ~ error", error)
                reject(error);
            }
        })
    }
    getCompanies = () => {

        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({ url: allCompanies, method: "GET" }));
                res(resp)
            } catch (error: any) {
                rej(error.response);
            }
        })

    }

    getServiceTypes = () => {
        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({ url: getServiceGroupsApi, method: "GET" }));

                res(resp)
            } catch (error: any) {
                // res(error.response)
                rej(error);
            }
        })

    }

    getDepartments = (params?: any) => {
        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({ url: getDepartmentsApi, method: "GET", params: params }));
                res(resp)
            } catch (error: any) {
                rej(error.response);
                message.error(errorMessage)

            }
        })

    }

    getSuspensionReasons = (isCompany?: boolean) => {
        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({
                    url: isCompany ? getCompanySuspensionReasonsApi : getAllSuspensionReasonsApi, method: "GET",
                    params: {
                        showHidden: isCompany ? 1 : "0"
                    }
                }));
                res(resp)
            } catch (error: any) {
                rej(error.response);
            }
        })

    }

    getRequestsStatuses = () => {
        const dispatch = store.dispatch;
        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({ url: getRequestsStatusApi, method: 'GET' }));
                res(resp)
            } catch (error: any) {
                rej(error.response)
            }
        })
    }

    getTruckingCompanies = () => {

        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({ url: getTruckingCompaniesApi, method: "GET" }));
                res(resp)
            } catch (error: any) {
                rej(error.response)
            }
        })

    }

    callApiRequest = async (externalURL: string, externalMethod: any, params?: any, data?: any) => {

        return new Promise<void>(async (res, rej) => {
            try {
                const resp: any = await dispatch(request({ url: externalURL, method: externalMethod, params: params, data: data }));
                res(resp)
            } catch (error: any) {
                rej(error.response)
            }
        })

    }
    resetAccountTableInStepper = () => {
        dispatch(viewAccountTable(false));
    }

    setRedirect = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    validateUploadSheetColumns = (data: { [key: string]: any }[], template: { [key: string]: any }[], isLimited?: boolean) => {

        if (data.length === 0) {
            return {
                success: false,
                message: "Empty File"
            }
        }

        let requiredColumns = template.filter((column) => column.isRequired).map((column) => column.label)

        for (let index = 0; index < data.length; index++) {
            let missingColumns: string[] = []
            for (let i = 0; i < data.length; i++) {
                const rowCols = requiredColumns.filter((column) => !Object.keys(data[i]).includes(column))
                missingColumns = [...missingColumns, ...rowCols]
            }
            missingColumns = missingColumns.filter((key, index) => missingColumns.indexOf(key) === index);
            if (missingColumns.length > 0) {
                return {
                    success: false,
                    message: `${missingColumns.map((col, i) => " " + col)} ${missingColumns.length > 1 ? " values are " : " value is "} required`
                }
            }
            if (data[index].hasOwnProperty('phone') && String(data[index].phone[0]) !== "0") {
                data[index].phone = "0" + String(data[index].phone)
            }
        }

        return {
            success: true,
            message: "",
            data
        }
    }

    excelDateFormat = (date: Date | string, format = 'YYYY/MM/DD') => {
        if (date instanceof Date) {
            return XLSX.SSF.format(format, date);
        }
        else {
            return moment(date).format(format);
        }
    }
    
    sheetToJson = (worksheet: XLSX.WorkSheet): XLSX.WorkSheet => {
        const hiringDateFormat = 'YYYY/MM/DD';
        const invoiceMonthFormat = 'MM/YYYY';
        const collectionDateFormat = 'MM/DD/YYYY';
        
        const jsonWorksheet: XLSX.WorkSheet = XLSX.utils.sheet_to_json(worksheet);

        return jsonWorksheet.map((row: XLSX.WorkSheet) => {
            let updatedRow = row;
            const hiringDateCell = row?.hiringDate;
            const invoiceMonthCell = row?.invoiceMonth;
            const collectionDateCell = row?.collectionDate;
            if (hiringDateCell) {
                updatedRow = {
                    ...updatedRow,
                    hiringDate: this.excelDateFormat(hiringDateCell, hiringDateFormat)
                }
            }
            if (invoiceMonthCell) {
                updatedRow = {
                    ...updatedRow,
                    invoiceMonth: this.excelDateFormat(invoiceMonthCell, invoiceMonthFormat)
                }
            }
            if (collectionDateCell) {
                updatedRow = {
                    ...updatedRow,
                    collectionDate: this.excelDateFormat(collectionDateCell, collectionDateFormat)
                }
            }
            return updatedRow;
        })
    }

    removeStringFromArray = (array: string[], stringToRemove: string) => array.filter((string) => string !== stringToRemove);

    generateTableColumns = (data: any[], width?: number): { title: string; dataIndex: string; width?: number }[] => {
        let tableColumns: { title: string; dataIndex: string; width?: number }[] = [];
        let keys = data[0] ? Object.keys(data[0]) : [];
        keys = this.removeStringFromArray(keys, 'validStatuses')
        for (let i = 0; i < keys.length; i++) {
            tableColumns.push({

                title: this.unCamelCase(keys[i]),
                dataIndex: keys[i],
                ...(width && { width }),
                // ...(typeof data[0][keys[i]] === "object" && {
                //     render: (value: any) => moment(value).format("MM/DD/YYYY")
                // })
            });
        }
        return tableColumns;
    };

    getPaymentCycles = (companyID: number, mostRecent?: boolean, actualCutOffDate?: any, futureCycle?: boolean, isException?: boolean) => new Promise(async (resolve, reject) => {
        const params = {
            companyID: companyID,
            mostRecent: mostRecent,
            isException: isException,
        }
        try {
            const paymentCyclesRes: { [key: string]: any } = await dispatch(request({ url: getPaymentCyclesApi, method: "GET", params: params }))
            if (paymentCyclesRes.data.status === 200) {
                const todayDate = new Date()
                const companyDate = new Date()
                companyDate.setDate(actualCutOffDate)
                if (companyDate <= todayDate) {
                    companyDate.setMonth(companyDate.getMonth() + 1)
                }
                const currentCycle = {
                    cutOffCycleDate: companyDate,
                    cutOffDay: actualCutOffDate,
                    paymentCycle: Array.isArray(paymentCyclesRes.data.allPaymentCycles) ?
                        Number(paymentCyclesRes.data.allPaymentCycles.at(-1).paymentCycle) + 1 :
                        Number(paymentCyclesRes.data.allPaymentCycles.paymentCycle) + 1
                }
                if (futureCycle && actualCutOffDate && !isException) {
                    resolve([currentCycle, paymentCyclesRes.data.allPaymentCycles]);
                }
                if (!mostRecent && isException) {
                    resolve([currentCycle, ...paymentCyclesRes.data.allPaymentCycles.reverse()]);
                }
                resolve(paymentCyclesRes.data.allPaymentCycles)
            } else {
                resolve({ errorMessage: paymentCyclesRes.data.message })
            }
        }
        catch (error: any) {
            reject(error)
        }
    })

    getRequestInstallments = (advanceID: number, phone: string, isActiveRequest?: boolean, status?: number[] | string, type?: number[] | string, hasFees?: boolean, hasRefund?: boolean) => new Promise(async (resolve, reject) => {
        try {
            const params = {
                advanceID,
                phone,
                isActiveRequest,
                status,
                type,
                hasFees,
                hasRefund
            }
            const requestInstallments: { [key: string]: any } = await dispatch(request({ url: getRequestInstallmentsApi, method: "GET", params }))
            resolve({ data: requestInstallments.data })
        }
        catch (error: any) {
            reject(error)
        }
    })
    getAllCompanies = (deployed: (boolean | undefined) = undefined) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res: { [key: string]: any } = await dispatch(request({
                    url: deployed === undefined ? allCompanies : deployed === true ? deployedCompanies : unDeployedCompanies,
                    method: "GET"
                }))
                resolve(res.data)
            }
            catch (error) {
                console.log("🚀 ~ file: helper.ts:252 ~ Helper ~ returnnewPromise ~ error", error)
                reject(error)
            }
        })
    }

}




export default new Helper();
export const _ = ''