import { Button, Col, message, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ReusableTable from '../../components/reusableTable'
import { resolveIssues } from '../../utils/commonInterfaces';
import helper from '../../utils/helper';
import { resolveIssuesColumns } from '../../utils/tableColumns';
import Modal from '../Trucking/modal';
import { FalseIcon, TrueIcon } from '../../utils/commonIcons';
import { request } from '../../apis/axiosRequest';
import { getRequestIssuesApi, resolveIssueApi, resolveSystemIssuesApi } from '../../utils/apisConsts';
import Spinner from '../../components/spinner';
import OutPutResult from '../../components/result';
import { errorMessage } from '../../utils/commonStringsConsts';


export default function ResolveIssuesTable() {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [issues, setIssues] = useState([]);
    const [loadIssues, setLoadIssues] = useState(false);
    const [errorMode, setErrorMode] = useState(false)
    const [issueID, setIssueID] = useState(0);
    const [resolvingSpin, setResolvingSpin] = useState(false);
    const [issueErrorMessage, setIssueErrorMessage] = useState('');
    const [loadAllIssues, setLoadAllIssues] = useState(false);
    let requestID: any = useSelector<resolveIssues>(state => state.actionsReducer.requestID);

    useEffect(() => {
        getIssues(requestID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getIssues = async (requestID: number) => {
        setLoadIssues(true)
        try {
            const data: any = await dispatch(request({ method: 'GET', url: `${getRequestIssuesApi}/${requestID}` }));
            const res = await data.data;
            if (res.status === 200) {
                let requestIssues = res.advanceIssues;
                requestIssues = Array.isArray(requestIssues) && requestIssues.map((issue: any) => {
                    return {
                        ...issue,
                        createdAt: issue.createdAt ? helper.changeDateFormat(issue.createdAt) : 'NA',
                        resolvedAt: issue.resolvedAt ? helper.changeDateFormat(issue.resolvedAt) : 'NA',
                        isResolvedIcon: issue.isResolved ? TrueIcon : FalseIcon,
                        resolvedBy: issue.resolvedBy ? issue.resolvedBy : 'NA',
                        isResolved: issue.isResolved,
                    }
                })
                setIssues(requestIssues);
                setLoadIssues(false);
                return
            }
            setLoadIssues(false);
            setErrorMode(true)
            setIssueErrorMessage(res.message)
        } catch (error) {
            setLoadIssues(false);
            setErrorMode(true);
            setIssueErrorMessage(errorMessage)
        }
    }
    const OpenTheModal = (issueID: number) => {
        setIssueID(issueID)
        setOpenModal(true);
    }

    const resolveTheIssue = async () => {
        setResolvingSpin(true)
        try {
            const api: any = await dispatch(request({ url: resolveIssueApi, method: 'PATCH', data: { issueID: issueID } }));
            const res = await api.data;
            if (res.status === 200) {
                console.log(res);
                setResolvingSpin(false);
                message.success(res.message)
                setOpenModal(false);
                getIssues(requestID)
                return
            }
            setResolvingSpin(false);
            message.error(res.message)

        } catch (error) {
            console.log("🚀 ~ file: resolveIssuesTable.tsx:76 ~ resolveTheIssue ~ error", error)
            setResolvingSpin(false)
            message.error(errorMessage)
        }
    }

    const closeTheModal = () => {
        setOpenModal(false);
    }
    const resolvedAllIssues = async () => {
        setLoadAllIssues(true);
        const data = {
            advanceID: requestID,
        }
        try {
            const apiData: any = await dispatch(request({ method: 'PATCH', url: resolveSystemIssuesApi, data: data }));
            const res = apiData.data;
            if (res.status === 200) {
                if (res?.resolved && res?.unresolved) {
                    setLoadAllIssues(false);
                    getIssues(requestID);
                    if (res?.unresolved.length > 0) {
                        message.error('Not all issues have resolved', 4);
                        return
                    }
                    message.success('Updated successfully', 4);
                    return
                }
                setLoadAllIssues(false);
                message.info(res.message, 4)
                return
            }
            setLoadAllIssues(false);
            message.success(res.message, 4)
        } catch (error) {
            setLoadAllIssues(false);
            message.success(errorMessage, 4)
        }
    }
    const resolveAction = {
        title: 'Resolve',
        render: (record: { manualResolve: boolean, id: number, isResolved: boolean }) => (
            <>
                <Button style={{ borderColor: '#6fb76c', color: '#6fb76c', }}
                    disabled={(record.isResolved ? true : record.manualResolve ? false : true)} type="ghost" onClick={() => OpenTheModal(record.id)}>Resolve</Button>
            </>
        )
    };
    const columns = [...resolveIssuesColumns, resolveAction];

    const content = <>
        <div className='resolveIssue'>

            <p className='question' style={{ textAlign: 'center', fontSize: '16px', fontWeight: 600 }}>Are you sure to resolve this issue?</p>

            <Row style={{ margin: '20px 0' }}>
                <Col span={24}>
                    <Button className='decline' onClick={closeTheModal}>No </Button>
                    <Button className='approve' onClick={resolveTheIssue} loading={resolvingSpin}>Yes</Button>
                </Col>

            </Row>

        </div>
    </>
    return (
        <>
            <div style={{ margin: '20px 0' }}>
                {loadIssues ? <Spinner size={'large'} /> : errorMode ? <OutPutResult status='error' title={issueErrorMessage} subTitle={' '} />
                    : issues.length === 0 ? <OutPutResult status='403' title={'There are no issues on this request '} subTitle={' '} /> : <>

                        <ReusableTable rows={issues} columns={columns}
                            pagination={false} loading={false} scrolling={{ x: 'max-content' }} hiddenRefreshing />

                        <Modal handleCloseModal={closeTheModal} visibleModal={openModal} content={content} title={'Resolve issue'} />
                        <Button type="primary" onClick={resolvedAllIssues} loading={loadAllIssues}
                            style={{ width: 'fit-content', margin: '20px 0', float: 'right' }}>Update Blocking Issues</Button>
                    </>}
            </div>
        </>
    )
}
