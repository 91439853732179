import { Button, Form, InputNumber, Select } from 'antd';
import React, { useRef, useState } from 'react'
import { driversListAccountantColumns } from '../../../utils/tableColumns';
import DriversList from './driversList'
const { Option } = Select;

export default function DriverListAccount() {
    const amountRef:any = useRef([]);
    const bankRef:any = useRef([]);
    const [transactionAmount, setTransactionAmount] = useState({
        amount: '',
        userID: '',
    });

    const [bankAccount, setBankAccount] = useState({
        cardNumber: '',
        userID: ''
    });

    const inputChange = (evt: any, record: any) => {
        setTransactionAmount({
            ...transactionAmount,
            amount: evt,
            userID: record.id,
        })
    }
    const changeBanks = (evt: any, record: any) => {
        console.log("🚀 ~ file: driverListAccount.tsx:28 ~ changeBanks ~ evt:", evt)
        console.log("🚀 ~ file: driverListAccount.tsx:28 ~ changeBanks ~ record:", record)
        setBankAccount({
            ...bankAccount,
            cardNumber: evt,
            userID: record.id,
        })
    }
    const addExpensesBtn = {
        title: 'تحديد التحويلات',
        dataIndex: 'phone',
        render: (row: any) => <Button onClick={() => {
            window.open(`${window.location.origin}/trucking/viewDrivers/${row}`, '_parent')
            window.focus();
        }}>تحديد التحويلات اليوميه</Button>
    }
    const addAmount = {
        title: 'المصروف',
        dataIndex: '',
        render: (record: any) => <>
            <Form ref={(el: any) => (amountRef.current[record.id] = el)} name="nest-messages"style={{ margin: '20px 0 0 0 ' }} >
                <Form.Item name="amount">
                    <InputNumber placeholder='المبلغ' 
                    onChange={(evt) => inputChange(evt, record)} 
                    />
                </Form.Item>
            </Form>
        </>
    }
    
    const addPaymentMethods = {
        title: 'طريقه الدفع',
        dataIndex: '',
        render: (record: any) => <>
        {    console.log('bankRef.current[record.id] = ',bankRef.current)}
            <Form style={{ margin: '20px 0 0 0 ' }} ref={(el: any) => (bankRef.current[record.id] = el)} >
                <Form.Item name="payment">
                    <Select style={{ width: '100%' }} placeholder='اختار طريقه الدفع' onChange={(evt) => changeBanks(evt, record)}>
                        {Array.isArray(record.userBanks) && record.userBanks.map((bank: any, key: number) => {
                            return <Option value={bank.id} key={key} >{bank.bankId.nameAr}<br /> {bank.cardNumber}</Option>
                        })}
                    </Select>
                </Form.Item>

            </Form>
        </>
    }

    const columns: any = [ addExpensesBtn,...driversListAccountantColumns, addAmount, addPaymentMethods];

    return (
        <>
            <DriversList columns={columns} role='Driver' ops={false} inlineAmount={transactionAmount}
                inlineBank={bankAccount} amountRef={amountRef} bankRef={bankRef} />
        </>
    )
}
