import { Button, message, Descriptions, Form, Row, Col, DatePicker, FormInstance } from 'antd'
import moment from 'moment';
import React, { createRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { request, stepperMoving } from '../../stores/actions';
import { revokeRequestApi } from '../../utils/apisConsts';
import { errorMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';

export default function RevokeRequestForm() {
    const reqInfo = useSelector((state: any) => {
        let info = state.actionsReducer.info
        delete info.installmentsOrder
        delete info.transactionStatusID
        return info
    })
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const formRef = createRef<FormInstance>()

    const revokeRequest = async (values: { revokedAt: string }) => {
        setLoading(true)
        const revokeRes: { [key: string]: any } = await dispatch(request({
            url: `${revokeRequestApi}/${reqInfo?.advanceID}`, method: "PATCH",
            data: { note: "asd", revokedAt: moment(values.revokedAt).format("YYYY-MM-DD") }
        }));
        try {
            if (revokeRes.data.status === 200) {
                message.success(revokeRes.data.message)
                dispatch(stepperMoving(0))
            }
            else {
                message.error(revokeRes.data.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error(errorMessage);
        }
    }
    return (
        <div className='revokeRequest' style={{ marginTop: "30px" }}>
            <Descriptions
                title={`Request#${reqInfo?.advanceID}`}
                bordered
                size={'small'}
                extra={
                    <Form name="nest-messages" onFinish={revokeRequest} ref={formRef}>
                        <Row className="inputsRow">
                            <Col span={24}>
                                <label htmlFor="">Revoke Date </label>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="revokedAt"
                                    rules={[{ required: true, message: 'Required' }]}>
                                    <DatePicker format={"YYYY/MM/DD"} />
                                </Form.Item>
                            </Col>
                            <Col span={8} className='revokeBtn'>
                                <Button type="default" shape='round' danger loading={loading} htmlType="submit">Revoke Request</Button>
                            </Col>
                        </Row>
                    </Form>
                }
            >
                {
                    Object.keys(reqInfo).filter((key) => key !== "advanceID").map((key, i) => <Descriptions.Item key={`${key}-${i}`} label={helper.unCamelCase(key)} span={2}>{reqInfo[key]}</Descriptions.Item>)
                }
            </Descriptions>
        </div>
    )
}


