import { Button, Col, Descriptions, message, Result, Row, Spin } from 'antd'
import React, { Component, Fragment } from 'react'
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import DataTable from '../../components/dataTable';
import XLSX from "xlsx";
import Helper from '../../utils/helper';
import { connect } from 'react-redux';
import { request } from "../../stores/actions";
import { createPartnerBranches } from '../../utils/apisConsts';
import { errorMessage } from '../../utils/commonStringsConsts';
import ModalCompnent from '../../components/modal';
import helper from '../../utils/helper';

const tableColumns = [
    {
        title: 'Branch Number',
        dataIndex: 'Branch Number',
        width: 80
    },
    {
        title: 'Branch',
        dataIndex: 'Branch',
        width: 80
    },
    {
        title: 'Branch in Arabic',
        dataIndex: 'Branch in Arabic',
        width: 80
    },
    {
        title: 'Address In Arabic',
        dataIndex: 'Address In Arabic',
        width: 100
    },
    {
        title: 'Address In English',
        dataIndex: 'Address In English',
        width: 100
    },
    {
        title: 'Maps Location',
        dataIndex: 'Maps Location',
        width: 100,
        render: (col: any) => <a href={col} target="_blank" rel="noreferrer">{col}</a>
    },
    {
        title: 'Branch Phone Number',
        dataIndex: 'Branch Phone Number',
        width: 80
    },
]

interface Props {
    selectedPartnerId: number,
    request: any
}

class ViewPartnerBranchesSheet extends Component<Props> {
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        submittingData: false,
        tableLoading: false,
        pagination: {},
        tableData: [],
        uploadingError: false,
        errorMessage: "",
        uploading: false,
        showModal: false,
        missingFields: {},
        modalContent: []
    }

    sendPartnerBranches = async () => {
        const branches = this.state.tableData.map((branch) => {
            return {
                branchNumber: branch["Branch Number"],
                region: branch["Branch"],
                regionAr: branch["Branch in Arabic"],
                address: branch["Address In English"],
                addressAr: branch["Address In Arabic"],
                locationUrl: branch["Maps Location"],
                phone: typeof branch["Branch Phone Number"] === "number" ? "0"+JSON.stringify(branch["Branch Phone Number"]) : branch["Branch Phone Number"]
            }
        })
        try {
            this.setState({ submittingData: true })
            const res = await this.props.request({
                url: createPartnerBranches, method: "post", data: {
                    partnerId: this.props.selectedPartnerId,
                    branches
                }
            })
            this.setState({ submittingData: false });
            if (res.data.status === 200) {
                message.success(res.data.message, 4);
                this.setState({ tableData: [] })
            } else {
                message.error(res.data.message, 4);
            }
        }
        catch (e: any) {
            this.setState({ submittingData: false });
            if (e.response.data.status === 500) {
                message.error(e.response.data.message, 4);
                return
            }
            console.log("🚀 ~ file: viewPartnerBranchesSheet.tsx ~ line 97 ~ ViewPartnerBranchesSheet ~ sendPartnerBranches= ~ e", e.response)
            message.error(errorMessage, 4);
        }

    }

    submitData = () => {
        const missingFields: { [key: string]: [] } = this.state.missingFields
        let modalContent: string[] = []
        let key: string
        for (key in missingFields) {
            if (missingFields[key].length > 0) modalContent.push(`${key} -> ${missingFields[key].join(", ")}`)
        }
        if (modalContent.length > 0) {
            this.setState({ modalContent }, () => {
                this.setState({ showModal: true })
            })
            return
        }
        this.sendPartnerBranches()
    }

    emptyBranchesTable = () => {
        this.setState({ tableData: [], showModal: false })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    openSelectFile = () => {
        this.fileInputRef.current!.click();
    }

    handleFile(file: any /*:File*/) {
        console.log('file = ', file);
        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            this.setState({ uploading: true })
            let fileReader = new FileReader(), rows: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {
                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rows = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                this.validateData(rows)

            }
        }
    }

    validateData = (data: { [key: string]: any }[]) => {
        const validationRegex: { [key: string]: any } = {
            "Branch Number": Helper.regexValidation.integerNumbers,
            "Branch": Helper.regexValidation.englishRegex,
            "Branch in Arabic": Helper.regexValidation.arabicRegex,
            "Address In English": Helper.regexValidation.englishRegex,
            "Address In Arabic": Helper.regexValidation.arabicRegex,
            "Maps Location": Helper.regexValidation.urlRegex,
            "Branch Phone Number": Helper.regexValidation.egyptPhoneNum
        }
        let missingFields: { [key: string]: any } = {}
        this.setState({ uploading: false })
        for (let index = 0; index < data.length; index++) {
            const row = index + 2
            const missingCols = Object.keys(validationRegex).filter((key) => !Object.keys(data[index]).includes(key))
            missingFields["Row " + row] = [...missingCols]

            for (let key in data[index]) {
                if (!validationRegex[key]) {
                    this.setState({ uploadingError: true, errorMessage: `Incorrect header (${key}), Please check if this column is required and the spelling is correct` })
                    return
                }
                if (validationRegex[key] && !validationRegex[key].exec(data[index][key])) {
                    this.setState({ uploadingError: true, errorMessage: `Data format is incorrect (${key}) in row (${index+2})` })
                    return
                }
            }
        }

        this.setState({ tableData: data, missingFields })
        console.log("🚀 ~ file: viewPartnerBranchesSheet.tsx ~ line 182 ~ ViewPartnerBranchesSheet ~ missingFields", missingFields)
    }

    handleUploadFile = (e: { target: { files: any, value: any }; }) => {
        this.setState({ tableData: [], uploadingError: false });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        // to clear input value to be able to add the same file again
        e.target.value = null;
    }

    handleTablePagination = (e: any) => {
        this.setState({
            pagination: e, tableLoading: true
        })
        setTimeout(() => this.setState({ tableLoading: false }), 1500)
    }

    render() {
        const { submittingData, tableLoading, tableData, pagination, uploadingError, errorMessage, uploading, showModal } = this.state
        return (
            <Fragment>
                <input
                    hidden
                    type="file"
                    className="form-control"
                    id="file"
                    ref={this.fileInputRef}
                    accept=".csv,.xlsx"
                    onChange={this.handleUploadFile}
                />
                <Row style={{ marginTop: "20px" }}>
                    <Col span={5}><Button icon={<UploadOutlined />} onClick={this.openSelectFile} >Click to Upload file</Button></Col>
                    {tableData.length > 0 && <Col span={19}> <Button type="primary" onClick={this.submitData} loading={submittingData} style={{ float: 'right' }}>
                        Submit File
                    </Button>  </Col>}
                    {tableData.length === 0 && <Col>
                        <p>Please Upload your file as shown in the following table, Only the following data will be sent</p>
                    </Col>}
                </Row>
                {
                    !uploading && (tableData.length > 0 ?
                        <Row style={{ marginTop: "20px", maxWidth: "1300px" }}>
                            <label style={{ fontSize: "20px", fontWeight: "bold" }}>Branches Data</label>
                            <DataTable columns={tableColumns}
                                pagination={pagination}
                                rows={tableData}
                                handleTableChange={this.handleTablePagination}
                                loading={tableLoading}
                                scrolling={{ x: 1500, y: 300 }} />
                        </Row>
                        : <Row className='sheetTemplate'>
                            <Descriptions bordered style={{ margin: '15px 0' }}>
                                <Descriptions.Item label="Branch Number" span={3}><span>( Mandatory )</span> This field accepts only numbers that present branches numbers or IDs</Descriptions.Item>
                                <Descriptions.Item label="Branch" span={3}><span>( Mandatory )</span> This field accepts branch name or region in English language</Descriptions.Item>
                                <Descriptions.Item label="Branch in Arabic" span={3}><span>( Mandatory )</span> This field accepts branch name or region in Arabic language</Descriptions.Item>
                                <Descriptions.Item label="Address In Arabic" span={3}><span>( Mandatory )</span> This field accepts address In Arabic language</Descriptions.Item>
                                <Descriptions.Item label="Address In English" span={3}><span>( Mandatory )</span> This field accepts address In English language</Descriptions.Item>
                                <Descriptions.Item label="Maps Location" span={3}><span>( Mandatory )</span> Branch location URL must be a valid URL ( ex: https://example.com ) OR shorten URL ( ex: https://example )</Descriptions.Item>
                                <Descriptions.Item label="Branch Phone Number" span={3}><span>( Mandatory )</span> This field accepts only egyptian phone numbers</Descriptions.Item>
                            </Descriptions>
                        </Row>)
                }
                {
                    (uploadingError || uploading) && <Result
                        icon={uploading ? <Spin size="large" /> : <FrownOutlined />}
                        title={uploading ? "Please wait, your file is uploading now" : errorMessage}
                    />
                }

                <ModalCompnent visibleModal={showModal} handleAcceptModal={this.emptyBranchesTable} handleCloseModal={this.hideModal} okText={"Upload again"}
                    content={<p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        You have missing mandatory data in:
                        <br />
                        {this.state.modalContent.map((row) => {
                            return <>
                                <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{row}</span>
                                <br />
                            </>
                        })}
                    </p>} title='Warning Message' />
            </Fragment>
        )
    }
}

const mapStateToProps = (state: any) => ({
    selectedPartnerId: state.actionsReducer.selectedPartnerId,
})
export default connect(mapStateToProps, { request })(ViewPartnerBranchesSheet)