import React, { useEffect, useState } from "react";
import { Button, Col, Form, message, Row, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BasicUserInfo from "../../components/basicUserInfo";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import helper from "../../utils/helper";
import { request } from "../../apis/axiosRequest";
import { errorMessage } from "../../utils/commonStringsConsts";
import { stepperMoving } from "../../stores/actions";

interface userInfoTypes {
    name: string,
    staff_id: number,
    companyName: string,
    userID: number,
    phone: string,
    jobTitle: string,
    email: string,
    userStatusType: string,
    hrLetter: [string?],
    nationalId: [string?, string?],
    consent: [string?]
}
export interface UserDocsFormProps {
    apiLink: string;
}
export default function UserDocsForm({ apiLink }: UserDocsFormProps) {
    const dispatch = useDispatch()
    const userInfo = useSelector<{ [key: string]: any }, userInfoTypes>(state => state.actionsReducer.info)
    const [userFiles, setUserFiles] = useState<{ [key: string]: any }>({
        hrLetter: "",
        nationalIdFront: "",
        nationalIdBack: "",
        consent: "",
        userID: ""
    })
    const [sending, setSending] = useState(false)
    useEffect(() => {
        setUserFiles({
            hrLetter: userInfo.hrLetter[0] ? userInfo.hrLetter[0] : "",
            nationalIdFront: userInfo.nationalId[0] ? userInfo.nationalId[0] : "",
            nationalIdBack: userInfo.nationalId[1] ? userInfo.nationalId[1] : "",
            consent: userInfo.consent[0] ? userInfo.consent[0] : "",
            userID: userInfo.userID
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const checkDocsChanged = () => {
        let updatedDocs: string[] = []
        if (userInfo) {
            if (userFiles.consent !== userInfo.consent[0]) {
                updatedDocs.push("consent")
            }
            if (userFiles.hrLetter !== userInfo.hrLetter[0]) {
                updatedDocs.push("hrLetter")
            }
            if ((userFiles.nationalIdFront !== userInfo.nationalId[0]) && !updatedDocs.includes("nationalID")) {
                updatedDocs.push("nationalID")
            }
            if ((userFiles.nationalIdBack !== userInfo.nationalId[1]) && !updatedDocs.includes("nationalID")) {
                updatedDocs.push("nationalID")
            }
        }
        return updatedDocs;
    }
    const deleteOldPhoto = (fileName: ("hrLetter" | "nationalIdFront" | "nationalIdBack" | "consent")) => {
        let files: any = { ...userFiles }
        files[fileName] = ""
        setUserFiles(files)
    }
    const updateUserDocs = async () => {
        const data : {[key: string]: any} = {
            ...userFiles,
            updatedDocs: checkDocsChanged().length > 0 ? checkDocsChanged() : [" "]
        }
        console.log("🚀 ~ file: userDocsForm.tsx ~ line 71 ~ updateUserDocs ~ data", data)
        let formData = new FormData()
        console.log("🚀 ~ file: userDocsForm.tsx ~ line 72 ~ updateUserDocs ~ updatedDocs", data)
        Object.keys(data).map((key, _index) => {
            //append all data to Form data [used when we uploads files with data]
            formData.append(key, data[key]);
            return 0;
        });
        setSending(true)
        try {
            const res: { [key: string]: any } = await dispatch(request({
                url: apiLink, method: "patch", data: formData
            }))
            console.log("🚀 ~ file: userDocsForm.tsx ~ line 50 ~ updateUserDocs ~ res", res)
            if (res.data.status === 200) {
                dispatch(stepperMoving(0))
                message.success(res.data.message, 4)
            }
            else {
                message.error(res.data.message, 4)
            }
            setSending(false)
        }
        catch (error: any) {
            setSending(false)
            message.error(errorMessage, 4)
        }
    }
    const saveFile = (fileName: ("hrLetter" | "nationalIdFront" | "nationalIdBack" | "consent"), file: { [key: string]: any }) => {
        setUserFiles({
            ...userFiles,
            [fileName]: file.fileList.length > 0 ? file.file.originFileObj : ""
        })
    }
    return (
        <div className="uploadUserDocs">
            <Row>
                <Col span={24}>
                    <BasicUserInfo info={userInfo} />
                </Col>
            </Row>
            <Form onFinish={updateUserDocs} >
                <Row>
                    <Col span={12} className="uploaderCol">

                        <label htmlFor="">Upload Hr Letter</label>
                        {(!userFiles.hrLetter || typeof userFiles.hrLetter !== "string") && (
                            <Form.Item
                                name="hrLetter"
                                valuePropName="fileList"
                                getValueFromEvent={(file) => saveFile("hrLetter", file)}
                            >
                                <Upload
                                    {...helper.dataFile}
                                    maxCount={1}
                                    name="logo"
                                    listType="picture"
                                    accept="image/*"
                                >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        )}
                        {userFiles.hrLetter && typeof userFiles.hrLetter === "string" &&
                            <div className="oldPhoto" >
                                <img src={userFiles.hrLetter} alt="Hr" width={34} height={50} />
                                <span className="placeholder">
                                    Hr Letter
                                </span>
                                <span className="deleteIcon">
                                    <DeleteOutlined
                                        onClick={() => {
                                            deleteOldPhoto("hrLetter");
                                        }}
                                    />
                                </span>
                            </div>
                        }
                    </Col>
                    <Col span={12} className="uploaderCol">

                        <label htmlFor="">Upload Consent</label>
                        {(!userFiles.consent || typeof userFiles.consent !== "string") && (
                            <Form.Item
                                name="consent"
                                valuePropName="fileList"
                                getValueFromEvent={(file) => saveFile("consent", file)}
                            >
                                <Upload
                                    {...helper.dataFile}
                                    maxCount={1}
                                    name="logo"
                                    listType="picture"
                                    accept="image/*"
                                >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        )}
                        {userFiles.consent && typeof userFiles.consent === "string" &&
                            <div className="oldPhoto" >
                                <img src={userFiles.consent} alt="consent" width={34} height={50} />
                                <span className="placeholder">
                                    Consent
                                </span>
                                <span className="deleteIcon">
                                    <DeleteOutlined
                                        onClick={() => {
                                            deleteOldPhoto("consent");
                                        }}
                                    />
                                </span>
                            </div>}
                    </Col>
                    <Col span={12} className="uploaderCol">

                        <label htmlFor="">Upload Front of National ID</label>
                        {(!userFiles.nationalIdFront || typeof userFiles.nationalIdFront !== "string") && (
                            <Form.Item
                                name="FrontNational"
                                valuePropName="fileList"
                                getValueFromEvent={(file) => saveFile("nationalIdFront", file)}
                            >
                                <Upload
                                    {...helper.dataFile}
                                    maxCount={1}
                                    name="logo"
                                    listType="picture"
                                    accept="image/*"
                                >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        )}
                        {userFiles.nationalIdFront && typeof userFiles.nationalIdFront === "string" &&
                            <div className="oldPhoto" >
                                <img src={userFiles.nationalIdFront} alt="front national id" width={34} height={50} />
                                <span className="placeholder">
                                    Front National Id
                                </span>
                                <span className="deleteIcon">
                                    <DeleteOutlined
                                        onClick={() => {
                                            deleteOldPhoto("nationalIdFront");
                                        }}
                                    />
                                </span>
                            </div>
                        }
                    </Col>
                    <Col span={12} className="uploaderCol">

                        <label htmlFor="">Upload Back National ID</label>
                        {(!userFiles.nationalIdBack || typeof userFiles.nationalIdBack !== "string") && (
                            <Form.Item
                                name="backNational"
                                valuePropName="fileList"
                                getValueFromEvent={(file) => saveFile("nationalIdBack", file)}
                            >
                                <Upload
                                    {...helper.dataFile}
                                    maxCount={1}
                                    name="logo"
                                    listType="picture"
                                    accept="image/*"
                                >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        )}
                        {userFiles.nationalIdBack && typeof userFiles.nationalIdBack === "string" &&
                            <div className="oldPhoto" >
                                <img src={userFiles.nationalIdBack} alt="back national id" width={34} height={50} />
                                <span className="placeholder">
                                    Back National Id
                                </span>
                                <span className="deleteIcon">
                                    <DeleteOutlined
                                        onClick={() => {
                                            deleteOldPhoto("nationalIdBack");
                                        }}
                                    />
                                </span>
                            </div>
                        }
                    </Col>
                    <Col className="colOfAssign" span="24">
                        <Form.Item style={{ padding: "25px 46px", float: "right" }}>
                            <Button
                                className="updateUserBtn"
                                type="primary"
                                block
                                htmlType="submit"
                                loading={sending}
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </div>
    )
}