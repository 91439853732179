import { arabicEmailData, englishEmailData } from "../../utils/commonStringsConsts";
import {
    GET_INFO, MOVE_STEP, SAVE_PARTNER_Id, UPDATE_REQUESTS, SAVE_ARABIC_EMAIL,
    SAVE_ENGLISH_EMAIL, Empty_Drawer, HR_STEP, USER_COUNT, CAPTION_CONFIRMATION,
    USERS_TO_APPROVE, DATA_SHEET, ONE_PARTNER, REQUEST_ID, USER_BALANCE, USER_PLANS,
    REQUEST_DETAILS, OTP_MODAL, DEDUCT_FILTERS, USER_ACCOUNTS, VIEW_ACCOUNTS, VIEW_PAYMENT_PLANS

} from "./types";
const initialState = {
    info: {},
    stepNumber: 0,
    shouldUpdateRequests: false,
    selectedPartnerId: {},
    englishEmailData,
    arabicEmailData,
    emailLang: "ar",
    removeData: false,
    hrStep: 0,
    usersCount: 0,
    caption: '',
    usersToApprove: [
        {
            phone: '',
            userID: 0,
        }
    ],
    dataSheet: [],
    onePartner: {},
    requestID: 0,
    userBalance: {},
    userPlans: {},
    requestDetails: {},
    otpModalVisible: false,
    deductionsFilter: {},
    userAccounts: [],
    viewAccounts: false,
    viewPaymentPlansArr:{},
}
const componentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_INFO:
            return {
                ...state,
                info: action.payload
            }
        case MOVE_STEP:
            return {
                ...state,
                stepNumber: action.payload
            }
        case UPDATE_REQUESTS:
            return {
                ...state,
                shouldUpdateRequests: action.payload
            }
        case SAVE_PARTNER_Id:
            return {
                ...state,
                selectedPartnerId: action.payload
            }
        case SAVE_ENGLISH_EMAIL:
            return {
                ...state,
                englishEmailData: action.payload,
                emailLang: "en"
            }
        case SAVE_ARABIC_EMAIL:
            return {
                ...state,
                arabicEmailData: action.payload,
                emailLang: "ar"
            }
        case Empty_Drawer:
            return {
                ...state,
                removeData: action.payload
            }
        case HR_STEP:
            return {
                ...state,
                hrStep: action.payload
            }
        case USER_COUNT:
            return {
                ...state,
                usersCount: action.payload
            }
        case CAPTION_CONFIRMATION:
            return {
                ...state,
                caption: action.payload
            }
        case USERS_TO_APPROVE:
            return {
                ...state,
                usersToApprove: action.payload
            }
        case DATA_SHEET:
            return {
                ...state,
                dataSheet: action.payload
            }
        case ONE_PARTNER:
            return {
                ...state,
                onePartner: action.payload
            }
        case REQUEST_ID:
            return {
                ...state,
                requestID: action.payload
            }
        case USER_BALANCE:
            return {
                ...state,
                userBalance: action.payload
            }
        case USER_PLANS:
            return {
                ...state,
                userPlans: action.payload
            }
        case REQUEST_DETAILS:
            return {
                ...state,
                requestDetails: action.payload
            }
        case OTP_MODAL:
            console.log(action.payload);

            return {
                ...state,
                otpModalVisible: action.payload
            }
        case DEDUCT_FILTERS:
            return {
                ...state,
                deductionsFilter: action.payload
            }
        case USER_ACCOUNTS:
            return {
                ...state,
                userAccounts: action.payload
            }
        case VIEW_ACCOUNTS:
            return {
                ...state,
                viewAccounts: action.payload
            }
        case VIEW_PAYMENT_PLANS:
            return {
                ...state,
                viewPaymentPlansArr: action.payload
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default componentReducer