import React, { Component } from 'react'
import {
    Result, Button, message,
    Row,
    Col,
    Spin,

} from 'antd';
import XLSX from "xlsx";
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import Helper from '../../utils/helper';
import DataTable from '../../components/dataTable';
import { connect } from 'react-redux';
import { stepperMoving } from '../../stores/actions';
import SheetTemplate from '../../components/bulkActionSheetTemplate';
import helper from '../../utils/helper';
import { bulkUpdateApprovedUserInfoTemplate } from '../../utils/bulkSheetsTemplates';

interface Props {
    setData?: any,
    stepperMoving: any,
    uploadedData: { [key: string]: any }[],
    template?: any[]
}
export class BulkUpdateUploadSheet extends Component<Props> {
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        pagination: {
            current: 1,
            pageSize: 0,
            total: 0,
        },
        loading: false,
        dataSheet: [],
        columns: [],
        uploading: false,
        showTable: false,
        uploadingError: false,
        uploadingErrorMessage: ""
    }
    componentDidMount() {
        this.setSheetData()
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.uploadedData !== prevProps.uploadedData) {
            this.setSheetData()
        }
    }
    setSheetData = () => {
        this.setState({ dataSheet: this.props.uploadedData }, () => {
            if (this.state.dataSheet && this.state.dataSheet.length > 0) {
                const columns = helper.generateTableColumns(this.state.dataSheet, 150)
                this.setState({ showTable: true, pagination: {}, columns });
            }
        })
    }

    handleFile(file: any /*:File*/) {
        console.log('file = ', file);
        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            this.setState({ loading: true })
            let fileReader = new FileReader(), rowObject: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {
                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rowObject = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                const validation : any = helper.validateUploadSheetColumns(rowObject, this.props.template ? this.props.template : bulkUpdateApprovedUserInfoTemplate)
                if (!validation.success) {
                    this.setState({ dataSheet: [], columns: [], showTable: false, uploading: false, uploadingError: true, uploadingErrorMessage: validation.message });
                    const element: any = document.getElementById('error');
                    element?.scrollIntoView({ behavior: 'smooth' });
                    return
                }
                const columns = helper.generateTableColumns(validation.data, 150)
                this.setState({ dataSheet: validation.data, loading: false, pagination: {}, showTable: true, columns });
            }
        }
        this.setState({ uploading: false });
    }
    handleFileChange = (e: { target: { files: any, value: any }; }) => {
        this.setState({ uploading: true, showTable: false, uploadingError: false, loading: false });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        e.target.value = null;
    }
    forwardClickToInputElement = () => {
        this.fileInputRef.current!.click();
    };

    handleTableChange = (e: any) => {
        console.log(e);
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ pagination: e, loading: false })
        }, 500);
    }
    goToNextStep = () => {
        this.props.setData(this.state.dataSheet)
        this.props.stepperMoving(1)
    }
    render() {
        const { columns, dataSheet, loading, pagination, uploading, showTable, uploadingError, uploadingErrorMessage } = this.state;
        return (
            <div className='userStatus'>
                <form className="form-inline">
                    <div className="form-group">
                        <label htmlFor="file">  </label>
                        <input
                            hidden
                            type="file"
                            className="form-control"
                            id="file"
                            ref={this.fileInputRef}
                            onChange={this.handleFileChange}
                        />
                    </div>
                </form>

                <Row>
                    <Col span={4}><Button icon={<UploadOutlined />} onClick={this.forwardClickToInputElement} >Click to Upload file</Button></Col>
                    <Col span={13}>{showTable ? <><b>Uploaded Rows Count: </b> {dataSheet.length} {dataSheet.length > 1 ? "Users" : "User"}</> : "Please Upload your file as shown in the following table, Only the following data will be sent"}</Col>
                    <Col span={7}>
                        <Button disabled={!showTable} type="primary" onClick={this.goToNextStep} style={{ float: 'right' }}> Next </Button>
                    </Col>
                </Row>
                {dataSheet.length === 0 && <SheetTemplate columns={this.props.template ? this.props.template : bulkUpdateApprovedUserInfoTemplate} />}
                <span id='error'>
                    {uploadingError && <Result
                        icon={<FrownOutlined />}
                        title={uploadingErrorMessage}
                    />}
                </span>
                {showTable &&
                    <>
                        {uploading ? <Spin size="large" /> :
                            <DataTable columns={columns} pagination={pagination} rows={dataSheet} handleTableChange={this.handleTableChange}
                                loading={loading} scrolling={{ x: 2000 }} />
                        }
                    </>}

            </div>
        )
    }
}

export default connect(null, { stepperMoving })(BulkUpdateUploadSheet)


