import { Button, Descriptions, Image } from 'antd'
import React, { PureComponent } from 'react'
import helper from '../utils/helper';
interface Props {
    info: {
        name: string,
        name_ar: string,
        description: string,
        description_ar: string,
        categories: string,
        categories_ar: string,
        workTime: string,
        workTime_ar: string,
        hotLine: string,
        logoUrl: string,
        isTopMerchant: boolean,
        switching: boolean,
        createdAt: string,
    },
    changeToTopPartner?: any,
    spin: boolean,
    isBug: boolean,
}
class PartnerInfo extends PureComponent<Props> {
    render() {
        const { info, changeToTopPartner, spin } = this.props;

        return (
            <div style={{ margin: '20px 0' }}>
                <div className="img"
                    style={{ textAlign: 'center', margin: '10px 0' }}>
                    <Image
                        width={150}
                        // height={200}
                        src={info?.logoUrl ? info?.logoUrl : 'https://via.placeholder.com/150'}
                    />
                </div>
                {info && <Descriptions bordered>

                    {<Descriptions.Item span={2} label="Name">{info.name ? info.name : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item label="Arabic Name" span={2}>
                        {info.name_ar ? info.name_ar : 'NA'}
                    </Descriptions.Item>}
                    {<Descriptions.Item span={2} label="Description">{info.description ? info.description : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item span={2} label="Arabic Description">{info.description_ar ? info.description_ar : 'NA'}</Descriptions.Item>}
                    {<Descriptions.Item label="Categories" span={2}>
                        {info.categories ? info.categories : 'NA'}
                    </Descriptions.Item>}
                    {<Descriptions.Item label="Categories in Arabic" span={2}>
                        {info.categories_ar ? info.categories_ar : 'NA'}
                    </Descriptions.Item>}
                    <Descriptions.Item label="Work Time" span={2}>{info.workTime ? info.workTime : 'NA'}</Descriptions.Item>
                    <Descriptions.Item label="Work Time" span={2}>{info.workTime_ar ? info.workTime_ar : 'NA'}</Descriptions.Item>
                    {<Descriptions.Item label="Created At" span={2}>
                        {info.createdAt ? helper.changeDateFormat(info.createdAt) : 'NA'}
                    </Descriptions.Item>}
                    {<Descriptions.Item label="Hotline" span={2}>
                        {info.hotLine ? info.hotLine : 'NA'}
                    </Descriptions.Item>}
                    {<Descriptions.Item label="Popular" span={2}>
                        {info.isTopMerchant ? 'Yes' : 'No'}

                    </Descriptions.Item>}

                    {<Descriptions.Item label="Action" span={2}>

                        <Button loading={spin} style={{ margin: '20px 0', background: '#004e64', color: '#fff', borderColor: '#004e64' }}
                            onClick={changeToTopPartner}>{info.isTopMerchant ? 'Make Unpopular Merchant' : 'Make Popular Merchant'}</Button>

                    </Descriptions.Item>}
                </Descriptions>}

            </div>
        )
    }
}
export default PartnerInfo
