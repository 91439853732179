import React, { PureComponent } from 'react'
import ReusableDataTable from '../components/reusableTable'
import { Button, Col, Descriptions, Row, Space } from 'antd';
import ModalCompnent from '../components/modal';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

class ViewFeesModels extends PureComponent {
    state = {
        visibleModal: false,
        feesRateBrackets: [],
    }
    showMoreInfo(record: any): void {
        console.log(record.FeesRateBrackets, record.FeesType);
        this.setState({
            visibleModal: true, feesRateBrackets: record.FeesRateBrackets,
        })

    }
    handleAcceptModal = () => {
        this.setState({ visibleModal: false })
    }
    render() {
        const { visibleModal, feesRateBrackets } = this.state;
        // const reversedFeesRateBrackets = [...feesRateBrackets].reverse();


        const columns = [
            {
                title: 'Models Names',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Models Descriptions',
                dataIndex: 'description',
                key: 'description',
                width: '25%',
            },
            {
                title: 'Model Type',
                dataIndex: 'FeesModelType',
                key: 'FeesType',
                render: (FeesModelType: any) => (
                    <>
                        <span>{FeesModelType ? FeesModelType.name : "NA"}</span>
                    </>
                )
            },
            {
                title: 'Applied on first deduction',
                dataIndex: 'applyOnFirstDeduction',
                key: 'FeesType',
                render: (applyOnFirstDeduction: any) => (
                    <>
                        <span>{applyOnFirstDeduction ? <CheckCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#52c41a" />
                            : <CloseCircleTwoTone style={{ fontSize: "20px" }} twoToneColor="#eb2f96" />}</span>
                    </>
                )
            },
            {
                title: 'Fees Type',
                dataIndex: 'FeesType',
                key: 'FeesType',
                // width: '20%',
                render: (FeesType: any) => (
                    <>
                        <span>{FeesType.name}</span>
                    </>
                )
            },
            {
                title: 'Type Descriptions',
                dataIndex: 'FeesType',
                key: 'FeesType',
                width: '20%',
                render: (FeesType: any) => (
                    <>
                        <span>{FeesType.description}</span>
                    </>
                )
            },
            {
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (_text: any, record: any) => (
                    <Space size="middle" style={{ color: 'blue' }}>
                        <>
                            {record.transactionStatusID === 7 || record.transactionStatusID === 5 ? <span style={{ color: '#ff7875' }}>{record.requestStatus}</span>
                                :
                                <Button type="primary" onClick={() => this.showMoreInfo(record)}>
                                    Show
                                </Button>
                            }
                        </>

                    </Space>
                ),
            },
        ];
        const table = <ReusableDataTable columns={columns} apiLink={`fees/getModelsFeesDetails`}
            pagination={{}} loading={false} scrolling={{ x: 100, y: 300 }} loadData={true}/>
        const modalContent = <>
            <Row>

                <Col md='24'>
                    <label htmlFor="">Fees Model Brackets</label><br />
                    {feesRateBrackets.map((tag: any, idx: any) => (
                        <>
                            <Descriptions bordered style={{ padding: '0 0 0px 0' }} key={idx} className='brackets'>
                                {<Descriptions.Item label="Start">{tag.startRange}</Descriptions.Item>}
                                {<Descriptions.Item label="Amount fees">{tag.amount}</Descriptions.Item>}
                            </Descriptions>

                        </>
                    ))}
                </Col>
            </Row>
        </>
        const modal = <ModalCompnent visibleModal={visibleModal} handleAcceptModal={this.handleAcceptModal}
            content={modalContent} title='Fees Model Information' handleCloseModal={this.handleAcceptModal} none='none' />

        return (
            <>
                {table}
                {modal}
            </>

        )
    }

}
export default (ViewFeesModels)
