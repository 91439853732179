
import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import CreateLostForm from './createLostForm';
import { createLostApi, getUserByPhoneApi } from '../../utils/apisConsts';

class CreateLost extends Component{
    state = {
        componentSteps: [
            {
                title: 'Get User',
                content: <GetInfo apiLink={getUserByPhoneApi} />,
                id: 0,
            }, {
                title: 'Create Lost',
                content: <CreateLostForm lostApi={createLostApi} isException={false} mostRecent={true}/>,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;         
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default CreateLost
