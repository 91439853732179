import React, { PureComponent } from 'react'
import ReusableDataTable from '../components/reusableTable'
import { Tag } from 'antd';

class ViewFeesCompanies extends PureComponent {
    render() {
        const columns = [
            {
                title: 'Companies Names',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Groups',
                dataIndex: 'groups',
                key: 'groups',
                render: (groups: any) => (
                    <>
                        {groups.map((group: any, idx: any) => {
                            return <><Tag color="blue" style={{ margin: `2px 5px` }} key={idx}>
                                {group.group}
                            </Tag>
                                <hr style={{ borderWidth: 'inherit' }} />
                            </>
                        })}
                    </>
                )
            },
            {
                title: 'Fees Models',
                dataIndex: 'groups',
                key: 'groups',
                render: (groups: any) => (
                    <>
                        {groups.map((group: any, idx: any) => {
                            return <> <span>

                                {group.models.map((model: any) => {

                                    return <><Tag color="volcano" style={{ margin: `2px 5px` }} key={idx}>
                                        {model}

                                    </Tag>

                                    </>
                                })}

                            </span>
                                <hr style={{ borderWidth: 'inherit' }} />
                            </>
                        })}
                    </>
                )
            },

        ];
        const table = <ReusableDataTable columns={columns} apiLink={`fees/getCompaniesFees`}
            pagination={{}} loading={false} scrolling={{ x: 100, y: 300 }} loadData={true}/>

        return (
            <>
                {table}
            </>
        )
    }
}
export default (ViewFeesCompanies)
