import { Form, Row, Col, Button, Input, FormInstance, message } from 'antd';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApi, patchApi, stepperMoving } from '../../stores/actions';
import UserInfoUI from '../../components/uiInfo';
import helper from '../../utils/helper';

interface Props {
    getApi?: any,
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        userID: any,
    }
    patchApi?: any,
    stepperMoving?: any,
}
class UserSalary extends Component<Props> {
    state = {
        loadData: false
    }
    formRef = React.createRef<FormInstance>();


    changeUserSalary = async (values: { netSalary: number }) => {
        this.setState({ loadData: true })

        const data = {
            userID: this.props.info?.userID,
            netSalary: Number(values.netSalary)
        }
        this.setState({ changeSalarySpin: true })
        const endingCall = await this.props.patchApi('users/updateUserSalary', data);

        try {
            const res = endingCall.payload;
            if (res.status === 200) {
                console.log(res);
                this.setState({ changeSalarySpin: false, loadData: false });
                message.success(res.message);
                helper.resetAccountTableInStepper();
                this.props.stepperMoving(0);
            }
            else {
                this.setState({ changeSalarySpin: false, loadData: false })
                console.log(res.message);
                message.error(res.message)
            }
        } catch (error) {
            this.setState({ changeSalarySpin: false, loadData: false })
            console.log(error);
            message.error('Something went wrong, please try again');

        }

    }
    render() {
        const { info } = this.props;
        const { loadData } = this.state;
        const salaryInfo = [
            {
                key: 'Net Salary',
                value: <Form.Item
                    name="netSalary"
                    rules={[{ required: true, message: 'Required' }]}
                    initialValue={info?.netSalary}
                >
                    <Input placeholder="Enter the net salary" />
                </Form.Item>
            }
        ]
        return (
            <>
                <Form name="nest-messages" ref={this.formRef} onFinish={this.changeUserSalary}>
                    <Row>
                        <Col span={24}>
                            <UserInfoUI userData={salaryInfo} activeKey={1} header={'Salary Information'} nonEditable={true} collapsible={"disabled"} />
                        </Col>
                        <Col style={{display:"flex", justifyContent:"flex-end"}} span={24}>
                            <Form.Item>
                                <Button style={{ margin: '30px 0 0 0 ', backgroundColor:"#7CC58F", borderColor:"#7CC58F" }} className='changeUserStatusBtn' shape="round" type="primary" htmlType="submit" loading={loadData} >
                                    Change
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, patchApi, stepperMoving })(UserSalary) 
