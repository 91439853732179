import React, { PureComponent } from 'react'
import ReusableDataTable from '../components/reusableTable'
import { Tag } from 'antd';

class viewFeesGroups extends PureComponent {
    render() {
        const columns = [
            {
                title: 'Group Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Services',
                dataIndex: 'services',
                key: 'services',
                render: (services: any) => (
                    <>
                        {services.map((tag: any) => (
                            <Tag color="blue" style={{ margin: `2px 5px` }} key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </>
                )
            },
        ];
        const table = <ReusableDataTable columns={columns} apiLink={`fees/getServiceGroup`}
            pagination={{}} loading={false} scrolling={{ x: 100, y: 300 }} loadData={true}/>
        return (
            <>
                {table}
            </>

        )
    }
}
export default (viewFeesGroups)
