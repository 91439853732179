import { Row, Col, Button, message, Table } from 'antd'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { move, request } from '../../../stores/actions';
import { errorMessage, truckingApis } from '../../../utils/commonStringsConsts';
import { truckingUsersBulkCreateColumns } from '../../../utils/tableColumns';

interface IRootState {
    [key: string]: any;
    usersCount: number,
    step: number,
    usersToApprove: any,
}
const errorsColumns: any = [
    {
        title: 'User Phone',
        dataIndex: 'phone',
    },
    {
        title: 'Errors',
        dataIndex: 'errors',
        render: (record: string[]) =>
            <>
                <ul style={{ margin: '0', padding: '0' }}>
                    {Array.isArray(record) && record.map((error: any, key: any) => {
                        return <li style={{ color: '#CC4949', listStyle: 'none' }} key={key}>{error}</li>
                    })}
                </ul>
            </>
    },
];


export default function Step2() {
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState<any>([]);
    const dispatch = useDispatch();
    const step: any = useSelector<IRootState>(state => state.truckingReducer.step);
    const users: any = useSelector<IRootState>(state => state.actionsReducer.dataSheet.sheetData);

    const bulkCreateUsers = async () => {
        setLoader(true)
        try {
            const res: any = await dispatch(request({ url: `${truckingApis}users`, data: { users }, method: "POST" }))
            if (res.data.status === 400) {
                setLoader(false)
                setErrors(res.data.data)
                return 0
            }
            else if (res.data.status !== 200 ) {
                setLoader(false)
                message.error(errorMessage, 4)
                return 0
            }

            dispatch(move(step + 1));
        } catch (error) {
            setLoader(false)
            message.error(errorMessage, 4)
        }
    }
    const goBack = () => {
        dispatch(move(step - 1));
    }
    return (
        <>
            <div className="submitUsers" style={{ textAlign: 'center' }}>


                {users.length && !errors.length ?
                    <>
                        <h3 className='downloaded'>{users?.length} Uploaded User{users?.length > 1 ? "s" : ""}</h3>
                        <div className="uploaded" style={{ textAlign: 'center' }}>
                            <div className="usersDataTable">
                                <Table columns={truckingUsersBulkCreateColumns} dataSource={users} pagination={false} scroll={{y:"3000px"}}/>
                            </div>
                        </div>
                        <Row>
                            <Col span={12}>
                                <Button className='approve' onClick={bulkCreateUsers} loading={loader}>Submit users data</Button>
                            </Col>
                            <Col span={12}>
                                <Button className='notApprove' onClick={goBack}>Upload another sheet</Button>
                            </Col>
                        </Row>
                    </>
                    : errors &&
                    <div className="uploaded" style={{ textAlign: 'center' }}>
                        <div className="table">
                            <Table columns={errorsColumns} dataSource={errors} pagination={false} />
                        </div>
                    </div>
                }

                {errors?.length ? <Button className='reUpload' onClick={() => dispatch(move(0))}>Re-upload the file</Button> : ""}
            </div>
        </>
    )
}
