import { Form, Row, Col, Select, Button, ConfigProvider, Input, message, DatePicker, InputNumber } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { move, getApi, postApi } from '../../../stores/actions';
import { useCallback, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner';
import OutPutResult from '../../../components/result';
import helper from '../../../utils/helper';
import TruckingModal from '../modal';
import moment from "moment";
import { truckingApis } from '../../../utils/commonStringsConsts';
const { Option } = Select;
interface IRootState {
  [key: string]: {
    driverData: {
      name: string,
      firstName: string,
      lastName: string,
    }
  };
}
function ExpenseRequest() {
  const dispatch = useDispatch();
  const driverSelector: any = useSelector<IRootState>(state => state.truckingReducer.driverData);
  const [services, setServices] = useState([]);
  const [spin, setSpin] = useState(false);
  const [errorView, setErrorView] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [openTruckingModal, setOpenTruckingModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [sending, setSending] = useState(false);

  const [orderData, setOrderData] = useState({
    userBankID: '',
    services: [],
  });
  const [sendExpense, setSendExpense] = useState(0);
  const [totalCompanyBalance, setTotalCompanyBalance] = useState(0);
  const fetchServices = useCallback(async () => {
    setSpin(true);
    if (!navigator.onLine) {
      setErrorMessage('أنت غير متصل بالإنترنت')
      setSpin(false);
      setErrorView(true)
      return 0
    }
    const res: any = await dispatch(getApi(`${truckingApis}services/companyServices`, {}))
    try {
      const driverInfo = await res.payload;

      if (driverInfo.status !== 200) {
        setErrorMessage('حدث خطأ ')
        setSpin(false);
        setErrorView(true)
        return 0
      }
      setServices(driverInfo.services);
      setSpin(false)

    } catch (error: any) {
      setErrorMessage('حدث خطأ ')
      setSpin(false)
      setErrorView(true)
    }
  }, [dispatch])
  const companyBalance = useCallback(async () => {
    setSpin(true);
    if (!navigator.onLine) {
      setErrorMessage('أنت غير متصل بالإنترنت')
      setSpin(false);
      setErrorView(true)
      return 0
    }
    const balance: any = await dispatch(getApi(`${truckingApis}companies/balance`, {}))
    try {
      const res = await balance.payload;
      if (res.status !== 200) {
        setErrorMessage('حدث خطأ ')
        setSpin(false);
        setErrorView(true)
        return 0
      }
      setSpin(false);
      setTotalCompanyBalance(res.companyBalance.toFixed(2))

    } catch (error: any) {
      setErrorMessage('حدث خطأ ')
      setSpin(false)
      setErrorView(true)
    }
  }, [dispatch])
  useEffect(() => {
    fetchServices();
  }, [fetchServices]);
  useEffect(() => {
    companyBalance();
  }, [companyBalance]);
  const addExpense = (values: any) => {
    console.log("🚀 ~ file: verifyRequest.tsx ~ line 99 ~ addExpense ~ values", values)
    let categories = values.categories;
    let firstData = [{ id: values.id, amount: Number(values.amount), receiptNumber: values.receiptNumber, receiptDate: values.receiptDate }];
    if (!categories || categories.length === 0) {
      const data: any = {
        uuid: driverSelector.uuid,
        services: firstData,
        userBankID: values.radioGroup ? values.radioGroup : 1,
      };
      setTotalAmount(values.amount)
      totalExpenses(Number(values.amount), data)
      return
    } else {

      let changeTheAmountToNumber = [...firstData, ...categories]
      const data: any = {
        uuid: driverSelector.uuid,
        services: changeTheAmountToNumber.map((num: any) => { return { ...num, amount: Number(num.amount) } }),
      };
      const sum = data.services.reduce((accumulator: any, object: any) => {
        return accumulator + Number(object.amount);
      }, 0);

      setTotalAmount(sum);
      totalExpenses(sum, data)

    }

  }

  const totalExpenses = (sum: number, summary: any) => {
    // if (sum > totalCompanyBalance) {
    //   setOpenTruckingModal(true);
    //   setModalTitle('رصيدك غير كافي');
    //   setTotalAmount(sum)

    //   setSendExpense(1)
    // } else {


    // }
    setOrderData(summary);
    setModalTitle('مراجعه ');
    setOpenTruckingModal(true);
    setSendExpense(2)
  }

  const verifyExpenses = async (data: any) => {
    setSending(true)
    const driverExpenses: any = await dispatch(postApi(`${truckingApis}transactions/verifiedTransaction`, data));
    try {
      const res = await driverExpenses.payload;
      if (res.status !== 200) {
        message.error('حدث خطأ ما');
        setSending(false)

        return
      }
      message.success('تم تسجيل الإيصالات بنجاح');

      setSendExpense(0)
      setOpenTruckingModal(false);
      dispatch(move(0));
      setSending(false)


    } catch (error) {
      message.error('حدث خطأ ما');
      setSending(false)
    }

  }

  const closeTruckingModal = () => {
    setOpenTruckingModal(false);

  }




  let balanceContent = <>
    <div className="reviewOrderBalance">
      <div className="driverInfo">
        <h3 style={{ borderBottom: 'solid 1px #ddd', paddingBottom: '20px' }}>رصيدك غير كافي من فضلك لا تضف مصروفات اكبر من رصيد الشركة</h3>

        <Row>
          <Col span={8}>
            <div className='info'>
              <h4>مجموع المصروفات</h4>
              {totalAmount ? `${totalAmount} جنية` : '0'}

            </div>
          </Col>
          <Col span={8}>
            <div>
              <h4 style={{ display: 'flex' }}>رصيد الشركة</h4>
              {totalCompanyBalance} جنية
            </div>
          </Col>
        </Row>

      </div>
      <div className='btns' style={{ textAlign: 'center' }}>
        <Button className='approve' onClick={closeTruckingModal}>ارجع الي المصروفات</Button>
      </div>
    </div>
  </>

  let orderReviewContent = <>
    <div className='reviewDriverOrder'>
      <div className="driverInfo">

        <Row>
          <Col span={6}>
            <div className='info'>
              <h4>الاسم</h4>
              {driverSelector.name ? driverSelector?.firstName + ' ' + driverSelector?.lastName : driverSelector.name}

            </div>
          </Col>

        </Row>

      </div>
      {/* <hr /> */}
      <div className="driverExpenses">
        <h3> المصروفات الفعلية</h3>
        {Array.isArray(orderData.services) && orderData.services.map((oneSummary: any, key: any) => {
          return (
            <>
              <Row key={key} style={{ margin: '10px 0 0 0 ' }}>

                <Col key={key} span={6}>
                  <h4>اليوم</h4>
                  <p>{helper.changeTruckingDateFormat(oneSummary.receiptDate._d)}</p>
                </Col>

                <Col key={key} span={6}>
                  <h4>فئة المصروف</h4>
                  <p>{Array.isArray(services) && services.filter((serviceName: any) => serviceName.id === oneSummary.id).map((serviceName: any) => serviceName.nameAr)}</p>

                </Col>
                <Col key={key} span={6}>
                  <h4>قيمة المصروف</h4>
                  <p>{helper.amountFormatter(oneSummary.amount)} جنيه</p>
                </Col>
                <Col key={key} span={6}>
                  <h4>رقم الإيصال</h4>
                  <p>{oneSummary.receiptNumber}</p>
                </Col>

              </Row>

            </>

          )
        })}
      </div>
    </div>
    <div className='btns' style={{ float: 'left' }}>
      <Button className='decline' onClick={closeTruckingModal}>رجوع</Button>
      <Button className='approve' type='primary' onClick={() => verifyExpenses(orderData)} loading={sending}>تأكيد </Button>
    </div>
  </>

  return (

    <div>
      <ConfigProvider direction="rtl">
        {spin ? <Spinner size='large' /> : errorView ? <OutPutResult status={'500'} title={errorMessage} subTitle={' '}
          btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
          <Form
            initialValues={{ type: "type_1" }}
            onFinish={addExpense}
          >

            <div className="site-card-border-less-wrapper">
              <div className='expensesHead'>
                <span className='arrowBack'>
                  <RightOutlined onClick={() => dispatch(move(0))} />  <span style={{ position: 'relative', bottom: '2px' }}>رجوع</span>
                </span>
              </div>
              <div className="expenseForm" >
                <Row>

                  <Col span={24}>
                    <label className='label' htmlFor="">المستلم</label>
                    <Form.Item name="driverID" rules={[{ required: false, message: 'اجباري' }]} initialValue={driverSelector.name ? driverSelector?.firstName + ' ' + driverSelector?.lastName : driverSelector.name}>
                      <Input placeholder='اسم المستلم' readOnly />
                    </Form.Item>
                  </Col>

                </Row>

                <Row>
                  <Col span={12}>
                    <label className='label' htmlFor="">فئة المصروف</label>
                    <Form.Item name="id" rules={[{ required: true, message: 'اجباري' }]}>
                      <Select style={{ width: '100%' }} placeholder='اختار المصروف البومي'>
                        {services.map((group: any, key: number) => {
                          return <Option value={group.id} key={key + 1} >{group.nameAr}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className='label' htmlFor="">قيمه المصروف</label>
                    <Form.Item
                      name="amount"
                      initialValue={0}
                      rules={[{ required: true, message: 'اجباري' }, { type: 'number', min: 0, message: `القيمة غير صحيحة` },
                      { type: 'number', max: 10000000, message: `القيمه غير صحيحة` }]}
                    >
                      <InputNumber style={{ width: '100%' }} placeholder='المصروف' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <label className='label' htmlFor=""> اليوم</label>
                    <Form.Item name="receiptDate" rules={[{ required: true, message: 'اجباري' }]}>
                      <DatePicker style={{ width: '100%' }} placeholder='يوم /شهر /سنه' disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return current && current < moment(customDate, "YYYY-MM-DD");
                      }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className='label' htmlFor="">رقم الايصال</label>
                    <Form.Item
                      name="receiptNumber"
                      rules={[{ required: true, message: 'اجباري' }]}
                    >
                      <Input placeholder='رقم الإيصال' />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.List name="categories">

                  {(fields, { add, remove }) => (

                    <>
                      {fields.map((field: any, key: any) => (

                        <>
                          <Row key={field.id}>
                            <Col span={12}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                }
                              >
                                {() => (
                                  <>
                                    <label className='label' htmlFor="">فئة المصروف</label>
                                    <Form.Item {...field} name={[field.name, 'id']} rules={[{ required: true, message: 'اجباري' }]}>
                                      <Select style={{ width: '100%' }} placeholder='اختار المصروف البومي' >
                                        {services.map((group: any, key: number) => {
                                          return <Option value={group.id} services={group.services} key={group.id}>{group.nameAr}</Option>
                                        })}
                                      </Select>

                                    </Form.Item>

                                  </>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <label className='label' htmlFor="">قيمه المصروف</label>

                              <Form.Item
                                {...field}
                                name={[field.name, 'amount']}
                                initialValue={0}
                                rules={[{ required: true, message: 'اجباري' }, { type: 'number', min: 0, message: `القيمة غير صحيحة` },
                                { type: 'number', max: 10000000, message: `القيمه غير صحيحة` }]}
                              >
                                <InputNumber style={{ width: '100%' }} placeholder='المصروف' />

                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <label className='label' htmlFor=""> اليوم</label>

                              <Form.Item
                                {...field}
                                name={[field.name, 'receiptDate']}

                                rules={[{ required: true, message: 'اجباري' }]}
                              >
                                <DatePicker style={{ width: '100%' }} placeholder='يوم /شهر /سنه' disabledDate={(current) => {
                                  let customDate = moment().format("YYYY-MM-DD");
                                  return current && current < moment(customDate, "YYYY-MM-DD");
                                }} />

                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <label className='label' htmlFor="">رقم الايصال</label>

                              <Form.Item
                                {...field}
                                name={[field.name, 'receiptNumber']}

                                rules={[{ required: true, message: 'اجباري' }]}
                              >
                                <Input placeholder='رقم الإيصال' />

                              </Form.Item>
                            </Col>
                            <Col span={1}>

                              <MinusCircleOutlined onClick={() => remove(field.name)} style={{ position: 'relative', top: '27px', padding: '0 0px' }} />
                            </Col>
                          </Row>
                        </>
                      ))}



                      {fields.length <= 28 && <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined style={{ paddingLeft: '6px' }} />}>
                          اضافة فئة جديدة
                        </Button>
                      </Form.Item>}
                    </>
                  )}
                </Form.List>

              </div>
            </div>
            <div className='collectExpense'>
              <Form.Item>
                <Row>
                  <Col className='total' span={12}>
                    {/* <span>رصيد الشركة</span><br />
                    <span className="amount">{totalCompanyBalance} جنيه مصري</span> */}
                  </Col>
                  <Col className='submitExpense' span={12}>
                    <Button type="primary" htmlType="submit" loading={false}>
                      استمرار ومراجعه 
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </div>

          </Form>}
        <TruckingModal handleCloseModal={closeTruckingModal} visibleModal={openTruckingModal} content={sendExpense === 2 ? orderReviewContent : balanceContent} title={modalTitle} />
      </ConfigProvider>

    </div>
  )
}
export default ExpenseRequest