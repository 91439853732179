import React, { useState } from 'react'
import { Button, Col, Row, message } from 'antd';
import XLSX from "xlsx";
import helper from '../../../utils/helper';
import { getDataSheet, move } from '../../../stores/actions';
import { useDispatch, useSelector } from 'react-redux';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import fileSaver from 'file-saver';
import TruckingModal from '../../Trucking/modal';
interface IRootState {
    [key: string]: any;
    step: number,
}

export default function Step1() {
    const [validFiles, setValidFiles] = useState(false);
    const [viewInstructions, setViewInstructions] = useState(false);
    const dispatch = useDispatch();
    const step: any = useSelector<IRootState>(state => state.truckingReducer.step);

    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    const saveFile = () => fileSaver.saveAs(`https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Nowpay-Docs/sample.xlsx`, `Model`);

    const forwardClickToInputElement = () => {
        fileInputRef.current!.click();
    };
    const handleFile = (file: any) => {
        let file_size = file.size / (1024 * 1024), maxSize = 12, size;
        if (file.size < 1024) {
            size = file.size + ' B';
        } else if (file.size < (1024 * 1024)) {
            size = Math.floor(file.size / 1024) + ' KB';
        } else {
            size = Math.floor(file.size / (1024 * 1024)) + ' MB';
        }
        console.log(size);

        /* Boilerplate to set up FileReader */
        let acceptable_exe = helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            setValidFiles(true);
        } else if (file_size > maxSize) {
            setValidFiles(true);
            // return Upload.LIST_IGNORE
        } else {
            setValidFiles(false);
            let fileReader = new FileReader(), rowObject: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {

                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rowObject = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                if (rowObject.length === 0) {
                    message.error('The file must not be empty');
                    return 0
                }
                const newRowObj = Array.isArray(rowObject) && rowObject.map((user: any) => {
                    return {
                        name: user.name ? user.name.toString() : "",
                        companyName: user.companyName ? user.companyName.toString() : "",
                        nationalID: user.nationalID ? user.nationalID.toString() : null,
                        phone: user.phone ? user.phone.toString() : "",
                        role: user.role ? user.role.toString() : "",
                        staffID: user.staffID ? user.staffID.toString() : null,
                        payroll: user.payroll ? Number(user.payroll) : null,
                        accountNumber: user.accountNumber? user.accountNumber.toString() : null,
                        bankName: user.bankName ? user.bankName.toString() : null,
                        accountName: user.accountName ? user.accountName.toString() : null,
                        cardNumber: user.cardNumber ? user.cardNumber.toString() : null
                    }
                });

                dispatch(getDataSheet({ sheetData: newRowObj }))
                dispatch(move(step + 1));
            }
        }
    }

    const handleFileChange = (e: { target: { files: any, value: any }; }) => {
        const files = e.target.files;
        if (files && files[0]) handleFile(files[0]);
        e.target.value = null;
    }

    const cancelInstructionsModal = () => {
        setViewInstructions(false)
    }

    const instructions = <>
        <div className="validation validation-en">
            <ul className='list'>
                <li>name </li>
                <span className='simpleDesc'>Employee name is mandatory</span>

                <li>phone</li>
                <span className='simpleDesc'>Employee phone is mandatory</span>

                <li>companyName </li>
                <span className='simpleDesc'>Company name is mandatory: This field accepts only these companies names in the same case: <a href='https://dashboard.nowpay.cloud/question/3151' target={"_blank"} rel="noreferrer">https://dashboard.nowpay.cloud/question/3151</a></span>

                <li>role </li>
                <span className='simpleDesc'>User role is mandatory: This field accepts Driver, Supervisor OR PartnerAccountant roles</span>

                <li>nationalID</li>
                <span className='simpleDesc'>National ID is optional: This field accepts only Egyptian national id format that present Users national IDs </span>

                <li>staffID </li>
                <span className='simpleDesc'>Staff ID is optional</span>

                <li>payroll </li>
                <span className='simpleDesc'>Payroll is optional</span>

                <li>bankName</li>
                <span className='simpleDesc'>Bank name is mandatory for Driver role: This field accepts only these bank names in the same case: <a href='https://dashboard.nowpay.cloud/question/3150' target={"_blank"} rel="noreferrer">https://dashboard.nowpay.cloud/question/3150</a></span>

                <li>accountName</li>
                <span className='simpleDesc'>Account name is mandatory for Driver and Supervisor role</span>

                <li><span>accountNumber</span> </li>
                <span className='simpleDesc'>Account number is mandatory for Driver amd Supervisor role</span>

                <li>cardNumber </li>
                <span className='simpleDesc'>Card number is mandatory for Driver and Supervisor role</span>

               
            </ul>
            <Row className='cancelInstructionContainer'>
                <Button className='cancelInstruction' onClick={() => setViewInstructions(false)}>Cancel</Button>
            </Row>
        </div>
    </>
    return (
        <>
            <div className="instructions">
                <Row>
                    <Col xl={18} md={18} sm={24}>
                        <p className="head">Before uploading, download the following form in order to fill in the required data and then re-upload it to the system</p>
                        <p className='fillData' onClick={() => setViewInstructions(true)} ><ExclamationCircleOutlined /><span className='fillHint'>How to fill in the form data?</span></p>
                    </Col>
                    <Col xl={6} md={6} sm={24}>
                        <Button className='sample' onClick={saveFile}>Download the model file</Button>
                    </Col>
                </Row>

            </div>

            <div className='upload' onClick={forwardClickToInputElement}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click here to upload the file</p>
                <p className="ant-upload-hint">Supports single file upload</p>
                {validFiles && <p className="error-hint">{'Allowed extension CSV or XLS up to 12 MB'}</p>}
            </div>
            <span className="maxSize">The recommended size is up to 12MB</span>
            <form className="form-inline">
                <div className="form-group">
                    <label htmlFor="file">  </label>
                    <input
                        hidden
                        type="file"
                        className="form-control"
                        id="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept='.xlsx,.xls,.csv'
                    />
                </div>
            </form>


            <TruckingModal visibleModal={viewInstructions}
                content={instructions}
                handleCloseModal={cancelInstructionsModal} title={'How to fill users sheet'} />
        </>
    )
}
