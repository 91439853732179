import { COLLAPSED_BAR,ACTIVE_NAV } from "./types";
const initialState = {
    is_bar_collapsed: false,
    active_nav:'0'
}
const componentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COLLAPSED_BAR:
            return {
                ...state,
                is_bar_collapsed: action.payload
            }
        case ACTIVE_NAV:
            return {
                ...state,
                active_nav: action.payload
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default componentReducer