export const otpSentSuccessfully = "You will recieve OTP SMS shortly!";
export const errorMessage = "Something went wrong, please try again";
export const failedToDownloadMessage = `Sorry the file can't be downloaded`;
export const arabicErrorMessage = "حدث خطأ ما ، برجاء المحاولة مرة اخري!";
export const arabicSuccessTransferMessage = 'تم التحويل بنجاح'
export const dateFormat = "YYYY/MM/DD";
export const noFilterDataMessage = `You can't search on nothing`;
export const egyptianNumberMsg = 'Phone number must be egyptian';
export const getDeductionsMsg = 'Press on filter to get the deductions';
export const firstOption = [{}];
export const checkSumInvoicesError = `Generating a new invoice in the background. Try again in a few minutes`;
//validation strings
export const spacesNotAllowedMsg = 'Spaces not allowed';
export const variableCasesMsg = 'Only accept letters, camelCase, or sneck_case';
export const englishInputError = "Please input with English";
export const arabicInputError = "Please input with Arabic";
// export const truckingApis = `https://dev.api.trucking.nowpay.cloud/`;
export const truckingApis = `https://beta.api.trucking.nowpay.cloud/`;
//Emails default values
export const englishEmailData = {
    companyName:"CEQUENS",
    companyLogo:"https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/cequens.png",
    headerAfterName: "is delighted to offer new benefit in collaboration with NowPay",
    description: "Now you can get your salary at any time, you don't need to wait for the end of the month with the Nowpay app",
    installmentOption:3 ,
    ifFreePeriod: false,
    freePeriod: 6,
    refLink: "https://getnowpay.com/?lang=en&ref=cequens",
    salaryAdvanceService: "You can now request your salary at any point during the month trough Nowpay's app. The amount will be deducted from your next salary, the requested amount will be deposited to your bank account directly within 1 to 2 business days and you can repay the amount in up to 3 months installments plan.",
    paymentService: "You can charge your mobile and pay your bill (electricity, gas, water, internet, and land line) with just one click through NowPay's payment.",
    internalTransferringService: "You can transfer amounts from your Nowpay's account to your colleagues inside the company and the amount will be directly deducted from your monthly salary up to 3 months installments plan.",
    shoppingService: "Now you can benefit from our hassle-free shopping service, pick your favorite products and we will deliver them to your doorstep.",
    payrollDescription: "The payroll department will directly deduct the requested amounts from your monthly salary",
    serviceFees: 2.5,
    howItWorks1: "Use this link to",
    howItWorks2: "Request your salary at any point during the month",
    howItWorks3: "Pick your suitable installment plan up to three months",
    howItWorks4: "We will transfer your requested amount directly",
    videoLink: "https://www.youtube.com/watch?v=936fFoyhNtg",
    questionsString: "If you have any questions, please reply to this email or contact us at",
    questionsContact: "support@nowpay.cash"
}

export const arabicEmailData= {
    companyName:"SAKNAFATA",
    companyLogo:"https://elasticbeanstalk-us-east-1-927288046810.s3.amazonaws.com/Logos/saknafta.jpg",
    headerAfterName:"خدمة مميزة لمُوظفي",
    description: "دلوقتي تقدر تقبض مرتبك في أي وقت مش محتاج تستني لأخر الشهر مع تطبيق ناوباي.",
    installmentOption:5,
    ifFreePeriod: false,
    freePeriod: "5",
    refLink: "https://getnowpay.com/?ref=saknafata",
    salaryAdvanceService: "تسطيع الحصول علي مرتبك في اي وقت خلال الشهر، المبلغ المطلوب بيتحول على حسابك خلال 1-2 يوم عمل ، تسطيع أيضاً تقسيط المبلغ بكل سهولة علي شهر أو اثنان أو ثلالثة.",
    paymentService: "دفع فواتيرك من كهرباء، غاز، مياه، خط أرضي، انترنت وتشحن رصيد لهاتفك بكل سهولة من راتب الشهر القادم بدون الحاجة لبطاقة بنكية.",
    internalTransferringService: "تستطيع تحويل نقود لأي من زملائك في الشركة من خلال التطبيق و يتم خصم النقود منك و سدادها على شهر أو اثنان أو ثلالثة.",
    shoppingService: "تستطيع شراء وتقسيط المشتريات ويتم خصم ثمنها تلقائيا من مرتبك دون أي متاعب أو إجراءات مرهقة, وسيصلك المنتج حتي باب البيت",
    payrollDescription: "يتم خصم الأقساط تلقائيًا من مرتبك التالي من خلال إدارة المرتبات بشركتك",
    serviceFees: 2.5,
    howItWorks1: "قــم بــتحـميل تطـبيـق نـاوبـاي من ",
    howItWorks2: "اطلب راتبك في أي وقت خلال الشهر",
    howItWorks3: "حدد أشهر السداد سواءا شهر أو اثنان أو ثلاثة",
    howItWorks4: "تحويل فوري لراتبك من خلال التطبيق",
    videoLink: "https://www.youtube.com/watch?v=936fFoyhNtg",
    questionsString: "الاستفسارات حول كيفية عمل الخدمة، يمكنك الرد علي الايميل أو التواصل معنا علي",
    questionsContact: "support@nowpay.cash"
}

