import { Form, Row, Col, Button, message, Select, Input } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { patchApi, stepperMoving } from '../../stores/actions';
import helper from '../../utils/helper';
import OutPutResult from '../../components/result';
import { errorMessage } from '../../utils/commonStringsConsts';
import UserInfoUI from '../../components/uiInfo';

const { Option } = Select;

interface Props {
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
    }
    patchApi?: any,
    stepperMoving?: any,
}
class UserSuspending extends Component<Props> {
    state = {
        dataSpin: false,
        reasons: [],
        loadingReasons: false,
        errorMode: false,
        selectedReason: null
    }

    componentDidMount(): void {
        this.getReasons()
    }

    getReasons = async () => {
        try {
            this.setState({ loadingReasons: true })
            const res: any = await helper.getSuspensionReasons()
            if (res.data.status === 200) {
                this.setState({ reasons: res.data.data, loadingReasons: false })
            }
            else {
                this.setState({ loadingReasons: false, errorMode: true })
            }
        }
        catch (error) {
            this.setState({ loadingReasons: false, errorMode: true })
            console.log("🚀 ~ file: userSuspending.tsx:53 ~ UserSuspending ~ getReasons= ~ error:", error)
        }
    }

    suspendingAction = async (values: { reasonID: number | string, comment: string }) => {
        this.setState({ dataSpin: true })
        const data: any = {
            phone: this.props.info?.phone,
            reasonID: values?.reasonID,
            comment: values?.comment
        }
        const endingCall = await this.props.patchApi('/users/suspendUser', data);
        try {
            const res = endingCall.payload;
            if (res.status === 200) {
                console.log(res);
                message.success(res.message);
                helper.resetAccountTableInStepper();
                this.props.stepperMoving(0)
            }
            else {
                console.log(res.message);
                message.error(res.message)
            }
            this.setState({ dataSpin: false });
        } catch (error) {
            this.setState({ dataSpin: false })
            console.log(error);
            message.error('Something went wrong, please try again');

        }
    }
    render() {
        const { dataSpin, reasons, errorMode, loadingReasons, selectedReason } = this.state;
        const suspensionStatusInfo = [
            {
                key: 'Suspension Reason',
                value: <Form.Item
                    name="reasonID"
                    rules={[{ required: true, message: 'Required' }]}

                >
                    <Select
                        loading={loadingReasons}
                        placeholder="Select a reason"
                        onChange={(value) => this.setState({ selectedReason: value })}
                    >
                        {Array.isArray(reasons) && reasons.map((reason: any, key: number) => {
                            return <Option value={reason.id} key={key}>{reason.name}</Option>
                        })}
                    </Select>
                </Form.Item>
            },
            {
                key: 'Comment',
                value: <Form.Item
                    name="comment"
                    rules={[{ required: selectedReason === 2, message: 'Required' }]}
                >
                    <Input.TextArea placeholder="Enter a comment" style={{ height: "32px" }} />
                </Form.Item>
            }
        ]
        return (
            errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                <>
                    <Form name="nest-messages" onFinish={this.suspendingAction}>
                        <Row>
                            <Col span={24}>
                                <UserInfoUI userData={suspensionStatusInfo} activeKey={1} header={'Suspension Status'} nonEditable={true} collapsible={"disabled"} />
                            </Col>
                            <Col className='colOfAssign' span='24   ' style={{ display: "flex", justifyContent: "end" }}>
                                <Form.Item>
                                    <Button style={{ margin: '22px 0 0 0 ', width: '120px', background: '#7CC58F', borderColor: '#7CC58F' }} className='suspendUserBtn' shape="round" type="primary" htmlType="submit" loading={dataSpin} >
                                        Suspend
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { patchApi, stepperMoving })(UserSuspending)


