import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import UserInfo from './userInfo';
import { getUserAccountsApi } from '../../utils/apisConsts';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { stepperMoving } from '../../stores/actions';
import { Space, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import DrawerBtn from '../../components/drawerBtn';
import SendUserOtp from '../sendUserOtp';

interface Props extends RouteComponentProps<any> {
    stepNumber?: number,
    info?: { userStatus: string, phone: string }
    viewAccounts: boolean,
}
class ViewUserInfo extends Component<Props>{
    
    state = {
        componentSteps: [],
    };
    static getDerivedStateFromProps(nextProps: { viewAccounts: boolean }, prevState: { viewAccounts: boolean }) {
        if (nextProps.viewAccounts !== prevState.viewAccounts) {
            const newComponentSteps = [
                {
                    title: nextProps.viewAccounts ? "User Accounts" : "Get User",
                    content: <GetInfo apiLink={getUserAccountsApi} />,
                    id: 0,
                },
                {
                    title: "User Info",
                    content: <UserInfo />,
                    id: 1,
                },
            ];

            return {
                componentSteps: newComponentSteps,
            };
        }

        return null;
    }
    render() {
        const { componentSteps } = this.state;
        return (
            <>
                {this.props.stepNumber === 1 && <>
                    <Space wrap className='btnsDrawer'>
                        <Button type="text" style={{ fontWeight: 500, color: this.props.info?.userStatus?.includes('Active') ? '#52C41A' : '#FF4D4F' }}
                            icon={<UserOutlined />}>
                            {this.props.info?.userStatus?.includes('Active') ? 'User Active' : 'User Pending'}
                        </Button>
                        <DrawerBtn
                            drawerWidth={800}
                            drawerTitle={"Send OTP"}
                            actionName={"sendOtpViaTwilio"}
                            action={<SendUserOtp phone={this.props.info?.phone} />}>
                            <Button shape="round" style={{ fontWeight: 500 }}>Send OTP</Button>
                        </DrawerBtn>
                        {/* <Button shape="round" style={{ fontWeight: 500 }}>Force Reset Password</Button> */}


                    </Space>
                    <hr className='drawerUnderline' />

                </>}
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    stepNumber: state.actionsReducer.stepNumber,
    info: state.actionsReducer.info,
    viewAccounts: state.actionsReducer.viewAccounts,

})
export default withRouter(connect(mapStateToProps, { stepperMoving })(ViewUserInfo))