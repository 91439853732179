import { Button, Col, DatePicker, Descriptions, Form, FormInstance, InputNumber, message, Row } from "antd"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BasicUserInfo from "../../components/basicUserInfo";
import DataTable from "../../components/dataTable";
import OutPutResult from "../../components/result";
import Spinner from "../../components/spinner";
import { stepperMoving } from "../../stores/actions";
import { createLostCollectionApi, getLostDetailsApi } from "../../utils/apisConsts";
import { errorMessage } from "../../utils/commonStringsConsts";
import helper from "../../utils/helper";
import { lostCollections } from "../../utils/tableColumns";

export default function LostCollectionForm() {
    const userInfo = useSelector((state: any) => state.actionsReducer.info)
    const dispatch = useDispatch()
    const [lostCase, setLostCase] = useState<{ [key: string]: any }>([])
    const [loadingGettingLost, setLoadingGettingLost] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [sendingData, setSendingData] = useState(false)
    const formRef = React.createRef<FormInstance>();

    useEffect(() => {
        getLostDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLostDetails = async () => {
        try {
            setLoadingGettingLost(true)
            const lostDetails: { [key: string]: any } = await dispatch(request({
                url: getLostDetailsApi, method: "GET", params: {
                    userID: userInfo.userID
                }
            }))
            if (lostDetails.data.status === 200) {
                setLostCase({...lostDetails.data,
                totalCollectedAmount: lostDetails.data.collections.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue.paymentsAmount), 0)
                })
            }
            else if (lostDetails.data.status === 400 && lostDetails.data.reason) {
                message.error(`${lostDetails.data.message}, Reason: ${lostDetails.data.reason}`, 4)
                dispatch(stepperMoving(0))
            }
            else {
                message.error(lostDetails.data.message, 4)
                setErrorMode(true)
            }
            setLoadingGettingLost(false)
        }
        catch (error) {
            console.log("🚀 ~ file: lostCollectionForm.tsx ~ line 46 ~ getLostDetails ~ error", error)
            setLoadingGettingLost(false)
            setErrorMode(true)
        }
    }



    const createCollection = async (values: any) => {
        try {
            setSendingData(true)
            const createLostRes: { [key: string]: any } = await dispatch(request({
                url: createLostCollectionApi, method: "POST", data: {
                    amount: values.amount,
                    discount: values.discount || 0,
                    userID: userInfo.userID,
                    collectionDate: moment(values.collectionDate).format("YYYY-MM-DD")
                }
            }))
            if (createLostRes.data.status === 200) {
                message.success(`${createLostRes.data.message} With ID: ${createLostRes.data.collectionID}`)
                dispatch(stepperMoving(0))
            }
            else if (createLostRes.data.status === 400) {
                message.error(`${createLostRes.data.message}, Reason: ${createLostRes.data.reason}`)
            }
            else {
                message.error(createLostRes.data.message)
            }
            setSendingData(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setSendingData(false)
        }
    }

    return (
        errorMode ?
            <OutPutResult status={'500'} title={'Oops!'} subTitle={'Something went wrong, please refresh the page and try again'}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} />
            : loadingGettingLost ? <Spinner size="large" />
                : <div className="createLostCollection">
                    <Row>
                        <Col span={24}>
                            <Descriptions
                                title={`Lost Request #${lostCase.lostRequestID}`}
                                bordered
                            >
                                <Descriptions.Item span={2} label="Total Lost Amount (EGP)">{helper.amountFormatter(lostCase.totalLostAmount)}</Descriptions.Item>
                                <Descriptions.Item span={2} label="Total Collected Amount (EGP)">{helper.amountFormatter(lostCase.totalCollectedAmount)}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {Array.isArray(lostCase.collections) && lostCase.collections.length > 0 &&
                            <Col span={24} className="collectionDetails">
                                <label className="infoLabel">Collections Details</label>
                                <DataTable
                                    columns={lostCollections}
                                    rows={lostCase.collections}
                                    pagination={false}
                                    loading={loadingGettingLost}
                                    scrolling={{ y: 300}}
                                />
                            </Col>}
                    </Row>
                    <Form name="nest-messages" onFinish={createCollection} ref={formRef}>
                        <Row>
                            <Col span={7}>
                                <label>Amount (EGP) <span>*</span></label>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: 'Required' }, { type: "number", min: 1 }]}
                                >
                                    <InputNumber placeholder="Enter collection amount" />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <label htmlFor="">Collection Date </label>
                                <Form.Item name="collectionDate"
                                    rules={[{ required: true, message: 'Required' }]}>
                                    <DatePicker format={"YYYY/MM/DD"} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label>Discount (EGP)</label>
                                <Form.Item
                                    name="discount"
                                    rules={[{ type: "number", min: 0 }]}

                                >
                                    <InputNumber defaultValue={0} placeholder="Enter collection discount" />
                                </Form.Item>
                            </Col>
                            <Col span='4' className="btnCol">
                                <Form.Item>
                                    <Button shape="round" type="primary" block htmlType="submit" loading={sendingData}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col span={24}>
                            <label className="infoLabel">User Information</label>
                            <BasicUserInfo info={userInfo} />
                        </Col>
                    </Row>
                </div>
    )
}
