import React, { Component } from 'react'
import ReusableDataTable from '../components/reusableTable'
interface Props {
    userID: any,
    count: number
}
class FewRequests extends Component<Props> {

    render() {
        const columns = [
            {
                title: 'Request ID',
                dataIndex: 'requestID',
                key: 'requestID',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: 'Service',
                dataIndex: 'service',
                key: 'service',
            },
            {
                title: 'Deductions',
                dataIndex: 'deductions',
                key: 'deductions',
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];
        const { userID, count } = this.props;
        const table = <ReusableDataTable columns={columns}
            apiLink={`requests/getUserLastAdvancesByCount?userID=${userID.userID}&count=${count}`}
            pagination={false} loading={false} scrolling={{ x: 100, y: 300 }} />
        return (
            <>
                {table} 
            </>

        )
    }
}

export default FewRequests