import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormInstance, Input, message, Row, Select, Upload } from "antd";
import regex from '../utils/helper'
import { UploadOutlined } from '@ant-design/icons';
import { request } from "../stores/actions";
import { createPartnerAPI, getPartnersTypesApi } from "../utils/apisConsts";
import { arabicInputError, englishInputError, errorMessage } from "../utils/commonStringsConsts";
import Spinner from "../components/spinner";
import Result from "../components/result";
const { Option } = Select;

interface Props {
    request: any,
}

class CreatePartner extends Component<Props> {
    formRef = createRef<FormInstance>();
    validation: any = regex.regexValidation;
    state = {
        sending: false,
        imageFile: [],
        categoriesValid: true,
        categoriesArValid: true,
        partnersTypes: [],
        loadTypes: false,
        errorMode: false,
        errorMessage: ''
    }
    componentDidMount(): void {
        this.getPartnersTypes()
    }
    getPartnersTypes = async () => {
        this.setState({ loadTypes: true })
        try {
            const apiData = await this.props.request({ url: getPartnersTypesApi, method: "get" });
            const res = await apiData.data
            if (res.status === 200) {
                this.setState({ partnersTypes: res.partnersTypes, loadTypes: false });
                return
            }
            this.setState({ errorMode: true, loadTypes: false, errorMessage: res.message })
        } catch (error) {
            this.setState({ errorMode: true, loadTypes: false, errorMessage: 'Something went wrong, please try again' });            
        }
    }
    submitPartner = async (values: any) => {
        values.logoUrl = this.state.imageFile
        const categories = values.categories.join("|||")
        const categoriesAr = values.categoriesAr.join("|||")
        values.categories = categories
        values.categoriesAr = categoriesAr

        const formData = new FormData()
        formData.append("logoUrl", values.logoUrl)
        formData.append("hotline", values.hotline)
        for (let key in values) {
            (key !== "logoUrl" && key !== "hotline") && formData.append(key, JSON.stringify(values[key]))
        }
        this.setState({ sending: true })
        try {
            const res = await this.props.request({ url: createPartnerAPI, method: "post", data: formData })
            this.setState({ sending: false });
            if (res.data.status === 200 || res.data.status === 201) {
                message.success(res.data.message, 4);
                this.formRef.current!.resetFields();
            } else {
                message.error(res.data.message, 4);
            }
        }
        catch (e: any) {
            this.setState({ sending: false });
            message.error(errorMessage, 4);
        }

    }

    onFinishFailed = (e: any) => {
        console.log("🚀 ~ file: createPartner.tsx ~ line 24 ~ CreatePartner ~ e", e)
    }

    dataFile = {

        beforeUpload: (file: { size: any, name: string; }) => {
            let file_size = file.size / (1024 * 1024), maxSize = 2, size;
            if (file.size < 1024) {
                size = file.size + ' B';
            } else if (file.size < (1024 * 1024)) {
                size = Math.floor(file.size / 1024) + ' KB';
            } else {
                size = Math.floor(file.size / (1024 * 1024)) + ' MB';
            }
            let acceptable_exe = /(\.png|\.jpg|\.jpeg)$/i;
            if (!acceptable_exe.exec(file.name)) {
                message.error(`The extension of (${file.name}) is not supported!`);
                return Upload.LIST_IGNORE
            } else if (file_size > maxSize) {
                message.error(`File size = ${size} exceeds ${maxSize} MB`);
                return Upload.LIST_IGNORE
            } else {
                return true
            }

        }
    };

    normFile = (e: any) => {
        this.setState({ imageFile: e.file.originFileObj });
        return e.fileList || e.file;
    };

    onCategoriesChange = (values: [], lang: string) => {
        let count = 0;
        const regex = lang === "en" ? this.validation.englishRegex : this.validation.arabicRegex
        const state = lang === "en" ? "categoriesValid" : "categoriesArValid"
        for (let index = 0; index < values.length; index++) {
            if (!regex.exec(values[index])) {
                count++;
                break;
            }
        }
        if (count > 0) {
            this.setState({ [state]: false })
            return
        }
        this.setState({ [state]: true })
    }

    render() {
        const { sending, partnersTypes, loadTypes, errorMode, errorMessage } = this.state
        const validation: any = this.validation
        return (
            <>
                {loadTypes ? <Spinner size={'large'} /> : errorMode ? <Result status={500} title={`Oop's`} subTitle={errorMessage} /> : <div className="createPartner">
                    <Form
                        name="basic"
                        ref={this.formRef}
                        initialValues={{ remember: true }}
                        onFinish={this.submitPartner}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col span={12}>
                                <label htmlFor="">Name <span>*</span></label>
                                <Form.Item name="name" rules={[{ required: true, message: 'Required' }, { pattern: validation.englishRegex, message: englishInputError }]}>
                                    <Input placeholder='Please enter partner name' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Arabic Name <span>*</span></label>
                                <Form.Item name="nameAr" rules={[{ required: true, message: 'Required' }, { pattern: validation.arabicRegex, message: arabicInputError }]}>
                                    <Input placeholder='Please enter partner arabic name' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Description <span>*</span></label>
                                <Form.Item name="description" initialValue={''} rules={[{ required: true, message: "Required" }, { pattern: validation.englishRegex, message: englishInputError }]}>
                                    <Input.TextArea placeholder='Please enter partner description' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Arabic Description <span>*</span></label>
                                <Form.Item name="descriptionAr" initialValue={''} rules={[{ required: true, message: "Required" }, { pattern: validation.arabicRegex, message: arabicInputError }]}>
                                    <Input.TextArea placeholder='Please enter partner arabic description' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <label htmlFor="">Categories <span>*</span></label>
                                <Form.Item name="categories"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        onChange={(values: []) => this.onCategoriesChange(values, "en")}
                                        mode="tags" dropdownStyle={{ display: 'none' }} placeholder='Please enter categories in English'
                                        tokenSeparators={[',']}></Select>
                                </Form.Item>
                                {!this.state.categoriesValid && <span className="categoriesError">{englishInputError}</span>}
                            </Col>
                            <Col span={24}>
                                <label htmlFor="">Categories in Arabic <span>*</span></label>
                                <Form.Item name="categoriesAr"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        onChange={(values: []) => this.onCategoriesChange(values, "ar")}
                                        mode="tags" dropdownStyle={{ display: 'none' }} placeholder='Please enter categories in Arabic'
                                        tokenSeparators={[',']}></Select>
                                </Form.Item>
                                {!this.state.categoriesArValid && <span className="categoriesError">{arabicInputError}</span>}
                            </Col>

                            <Col span={12}>
                                <label htmlFor="">Hotline <span>*</span></label>
                                <Form.Item name="hotline" initialValue={''} rules={[{ required: true, message: "Required" }, { pattern: validation.hotlineRegex, message: "Please enter a valid hotline" }]}>
                                    <Input placeholder='Please enter partner hotline' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Partners Types <span>*</span></label>
                                <Form.Item name="types"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select placeholder='Please select partners type' mode="multiple">
                                        {Array.isArray(partnersTypes) && partnersTypes?.map((type: { name: string, id: number }, key: number) => {
                                            // {console.log(typeof type.id)}
                                            return <Option value={type.id} name={type} key={key}>{type.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <label htmlFor="">Work Time <span>*</span></label>
                                <Form.Item name="workTime" initialValue={''} rules={[{ required: true, message: "Required" }, { pattern: validation.englishRegex, message: englishInputError }]}>
                                    <Input.TextArea placeholder='Please enter work time' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label htmlFor="">Work Time (In Arabic) <span>*</span></label>
                                <Form.Item name="workTimeAr" initialValue={''} rules={[{ required: true, message: "Required" }, { pattern: validation.arabicRegex, message: arabicInputError }]}>
                                    <Input.TextArea placeholder='Please enter work time in Arabic' />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={18}>
                                <label htmlFor="">Upload Logo <span>*</span></label>
                                <Form.Item
                                    name="logoUrl"
                                    valuePropName="fileList"
                                    getValueFromEvent={this.normFile}
                                    rules={[{ required: true, message: 'Required' }]}>
                                    <Upload {...this.dataFile} maxCount={1} name="logo" action="/upload.do" listType="picture">
                                        <Button icon={<UploadOutlined />}>Click to upload the logo</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>

                        </Row>
                        {/* Here am going to add partner type */}
                        <Col span={24}>
                            <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
                                <Button type="primary" htmlType="submit" loading={sending}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Form>
                </div>}
            </>
        )
    }
}

export default connect(null, { request })(CreatePartner);