import { Button, Col, Divider, message, Row, Table } from "antd";
import { useEffect, useState } from "react"
import { recalculatedInstallmentsColumns, recalculatedRequestsColumns } from "../../utils/tableColumns";
import ModalComponent from '../../components/modal';
import { request } from "../../apis/axiosRequest";
import { useDispatch } from "react-redux";
import { stepperMoving } from "../../stores/actions";
import { errorMessage } from "../../utils/commonStringsConsts";
import BulkErrorsModal from "../../components/bulkErrorsModal";
import { recalculateRequestApi } from "../../utils/apisConsts";
import TransactionErrorModal from "../../components/transactionErrorModal";

interface RequestType {
    installments: any[]
}

interface RecalculationDataType {
    companyID: string | number,
    advanceIDs: string[],
    comment: string
}

interface Props {
    recalculatedRequests: RequestType[],
    recalculationData: RecalculationDataType,
}
export default function ViewRecalculatedRequests({ recalculatedRequests, recalculationData }: Props) {
    const [sending, setSending] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleErrorModal, setVisibleErrorModal] = useState(false)
    const [visibleMessageModal, setVisibleMessageModal] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState<any>({
        newData: {},
        oldData: {}
    })
    const [resErrorContent, setResErrorContent] = useState<any>(undefined)
    const [validationErrors, setValidationErrors] = useState([])
    const [requestsColumns, setRequestsColumns] = useState<any[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        setRequestsColumns([
            ...recalculatedRequestsColumns,
            {
                title: "Installments",
                dataIndex: "",
                render: (row: any) => <Button onClick={() => selectRequest(row)}>Show installments</Button>
            }
        ])
    }, [])

    const selectRequest = (request: any) => {
        setSelectedRequest(request)
        setVisibleModal(true)
    }

    const submitRecalculation = async (values: any) => {
        try {
            setSending(true)
            const res: any = await dispatch(request({ url: recalculateRequestApi, method: "PATCH", data: recalculationData }));
            if (res.data.status === 200) { //show success message
                message.success(res.data.message)
                dispatch(stepperMoving(0))
            }
            else if (res.data.status === 422) { //open errors list popup
                setResErrorContent(res.data.message)
                setValidationErrors(res.data.failedUsers)
                setVisibleErrorModal(true)
            }
            else if (res.data.status === 400 && res.data.reason) { //open transaction error popup
                setResErrorContent(
                    {
                        message: res.data.message,
                        errorData: res.data.reason
                    }
                )
                setVisibleMessageModal(true)
            }
            else { // show any other status errors messages (400 without reason OR 500)
                message.error(res.data.message)
            }
            setSending(false)
        } catch (error) { // show any response status errors messages except 200(OK)
            setSending(false)
            message.error(errorMessage);
        }
    }

    const handleCloseModal = () => {
        setVisibleModal(false)
        setVisibleErrorModal(false)
        setVisibleMessageModal(false)
    }

    const getTable = (columns: any[], data: any[]) => <Table
        pagination={false}
        onChange={() => { }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
    />

    return (
        <div className="recalculatedRequests">
            {recalculatedRequests.length ? <>
                <Divider orientation="left">Recalculated Requests</Divider>
                {getTable(requestsColumns, recalculatedRequests)}
                <Row>
                    <Col span='24' className="btnCol">
                        <Button shape="round" type="primary" block htmlType="submit" loading={sending} onClick={submitRecalculation}>
                            Submit
                        </Button>
                    </Col>
                </Row>
                <ModalComponent content={
                    <Row className="installmentsComparison">
                        <Col span={24}>
                            <Divider orientation="left">Old installments details</Divider>
                            {getTable(recalculatedInstallmentsColumns, selectedRequest.oldData.installments)}
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">New installments details</Divider>
                            {getTable(recalculatedInstallmentsColumns, selectedRequest.newData.installments)}
                        </Col>
                    </Row>
                }
                    visibleModal={visibleModal} handleAcceptModal={handleCloseModal} handleCloseModal={handleCloseModal} none="none" />
            </> : ""
            }
            <BulkErrorsModal errors={validationErrors} visibleModal={visibleErrorModal} handleCloseModal={handleCloseModal} errorMessage={resErrorContent} />
            <TransactionErrorModal resErrorContent={resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={handleCloseModal} />

        </div>
    )
}