import { Result } from "antd"
import { errorMessage } from "../utils/commonStringsConsts"
import ModalCompnent from "./modal"

interface Props {
    visibleModal: boolean
    handleCloseModal: any,
    errorMessage?: string | null,
    info?: boolean,
    warn?: boolean,
}

export default function ErrorModal(props: Props) {
    const content = <Result
        status={props.info ? "info" : props.warn ? 'warning' : "error"}
        style={{ padding: "30px" }}
        title={<p style={{ fontSize: '18px', padding: 0 }}>{props.errorMessage ? props.errorMessage : errorMessage}</p>}
    />
    return (
        <ModalCompnent content={content}
            visibleModal={props.visibleModal} handleCloseModal={props.handleCloseModal} handleAcceptModal={props.handleCloseModal} cancelText="Close" none={"none"} />
    )
}