import React, { Component } from 'react'
import { Form, Input, Button, Select, Checkbox, Divider, Row, Col, notification, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { getComponies } from "../stores/actions";
import { connect } from 'react-redux';
import {
    CheckCircleTwoTone,
    //  CloseCircleOutlined
} from '@ant-design/icons';
import NotFound from '../components/notFound';
const { TextArea } = Input;

const { Option } = Select;
const children: any = [];
for (let i = 0; i < 0; i++) {
    children.push(<Option key={i.toString(0) + i} value=''>{i.toString(5) + i} </Option>);
}
interface Props {
    getComponies?: any,
}
class SmsNotification extends Component<Props> {
    formRef = React.createRef<FormInstance>();

    state = {
        layout: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        },
        uploading: {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
        },
        usersSegmant: [
            { name: 'All User', id: '1' },
            { name: 'In Active', id: '2' },
            { name: 'All Pending', id: '3' },
            { name: 'All Active', id: '4' },
            { name: 'Active with no requests', id: '5' },
            { name: 'Active with requests', id: '6' },
            { name: 'Custom List', id: '7' },
        ],

        selectedCompanies: [],
        usersSegmantSelected: null,
        validCompany: true,
        dataSending: false,
        showNotifications: false,

        isChecked: false,
        selectedAll: false,
        companies: [],
        loadingData: false,
        gotCompanies: true,
    }
    componentDidMount() {
        this.getAllCompanies()
    }
    getAllCompanies = async () => {
        const data = await this.props.getComponies()
        try {
            if (data.error) {
                this.setState({ gotCompanies: false, loadingData: false })
            } else {
                let initCompanies: any[] = [];
                data.payload.companies && data.payload.companies.map((i: { id: any; name: any; }) => {
                    return initCompanies = [...initCompanies,
                    {
                        id: i.id,
                        name: i.name,
                    }
                    ]
                });
                this.setState({ companies: initCompanies, loadingData: false, gotComponies: true }, () => {
                    // this.openDrawerByDynamicId()
                })

            }
        } catch (error) {
            this.setState({ gotCompanies: false, loadingData: false })
        }
    }
    // for showing notfication
    openNotificationWithIcon = (message: string, placement: any, duration: number, icon: any) => {
        notification.open({
            message,
            placement,
            duration,
            icon,
        });
    };
    submitSMSNotifications = (value: any) => {
        if (this.state.selectedCompanies.length === 0 && value.userSegmant !== '7') {
            this.setState({ validCompany: false })
            return
        }
        this.setState({ dataSending: true })
        setTimeout(() => {
            let smsObject = {
                userSegmantId: value.userSegmant,
                companyIds: value.userSegmant !== '7' ? this.state.selectedCompanies : [],
                phoneNumbers: value.phone,
                message: value.message
            }
            console.log('smsObject = ', smsObject);
            this.formRef.current!.resetFields();
            this.setState({ dataSending: false, selectedCompanies: [], usersSegmantSelected: null, validCompany: true, selectedAll: false });
            // this.openNotificationWithIcon('Add user failed', 'topLeft', 4, <CloseCircleOutlined style={{ color: "#ff4d4f" }} />)
            this.openNotificationWithIcon('SMS Sent Successfully', 'topLeft', 4, <CheckCircleTwoTone twoToneColor="#52c41a" />)
        }, 5000);
    }
    onUsersSegmantChange = (usersSegmantSelected: any) => {
        this.setState({ usersSegmantSelected })
    }

    toggleSelectAll = () => {
        this.setState({ selectedAll: !this.state.selectedAll }, () => {
            let allOptions = this.state.companies && this.state.companies.map((i: { id: any; }) => { return i.id })
            if (this.state.selectedAll) {
                this.setState({ selectedCompanies: allOptions, validCompany: true }, () => {
                    // console.log(this.state.selectedCompanies);
                })
            } else {
                this.setState({ selectedCompanies: [], validCompany: false })
            }
        })


    }
    handleCompanyChange = (selectedCompanies: any) => {
        if (this.state.companies && this.state.companies.length > selectedCompanies.length) {
            this.setState({ selectedAll: false })
        } else {
            this.setState({ selectedAll: true })
        }
        this.setState({ selectedCompanies: selectedCompanies, validCompany: true })
        console.log(selectedCompanies);
    }
    handlePhoneNumberChange = (value: any) => {
        console.log(`selected ${value}`);
    }
    companyFocas = (value: any) => {
        console.log(value);

    }
    handleCompanyValidation = (value: any) => {
        if (this.state.selectedCompanies.length === 0) {
            this.setState({ validCompany: false })
            return
        }
    }

    render() {
        const { layout, dataSending, usersSegmant, usersSegmantSelected,
            validCompany, selectedCompanies, selectedAll, gotCompanies, loadingData, companies } = this.state;
        console.log('gotCompanies = ', companies);

        const filteredOptions = companies ? companies.filter(
            ({ id }) => !this.state.selectedCompanies.some((item: any) => item.id === id)
        ) : [];

        return (
            <div>

                {!loadingData ? <>
                    {!gotCompanies ? <NotFound image={'https://img.icons8.com/ios/500/000000/cancel-2.png'}
                        description='Please wait a few minuets before you try again' btnDefault={true} /> :

                        <Form {...layout} name="nest-messages" ref={this.formRef} onFinish={this.submitSMSNotifications}>

                            <Form.Item name={['userSegmant']} label="Users Segmants"
                                rules={[{ required: true, message: 'Please select the segmant' },
                                ]}>
                                <Select
                                    value={usersSegmantSelected || undefined}
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select the user segmant"
                                    optionFilterProp="children"
                                    onChange={this.onUsersSegmantChange}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >   {usersSegmant && usersSegmant.map((segmant?: any, idx?: any) => {
                                    return <Option value={segmant && segmant.id} key={idx}>{segmant.name}</Option>
                                })}

                                </Select>
                            </Form.Item>
                            {usersSegmantSelected !== '7' && <Form.Item
                                rules={[{ required: true, message: 'Please choose the company!' }]}>
                                <Row>
                                    <Col style={{
                                        position: 'relative',
                                        left: '41px'
                                    }} lg={3}><span style={{
                                        display: 'inlineBlock',
                                        color: '#ff4d4f',
                                        fontSize: '14px',
                                        fontFamily: 'SimSun',
                                        lineHeight: 1,
                                        content: '*',
                                    }}>*</span> <span>Company :</span> </Col>
                                    <Col lg={21} >
                                        <Select
                                            className={!validCompany ? 'error' : ''}
                                            mode="multiple" style={{
                                                margin: '0 0 0 48px',
                                                width: '114%'
                                            }}
                                            value={selectedCompanies}
                                            onChange={this.handleCompanyChange}
                                            onClick={this.companyFocas}
                                            optionFilterProp="children"
                                            autoClearSearchValue
                                            placeholder='Select the company'
                                            dropdownRender={(menu) => {
                                                return (
                                                    <div>
                                                        <div onClick={this.toggleSelectAll} style={{ padding: "4px 8px 8px 8px", cursor: "pointer" }}
                                                        >
                                                            <span>{selectedAll ? <Checkbox checked /> : <Checkbox checked={false} />} Select All </span>
                                                        </div>
                                                        <Divider style={{ margin: "2px 0" }} />
                                                        {menu}
                                                    </div>
                                                )
                                            }}
                                        > {filteredOptions.map((i: any) => { return <Option value={i.id}>{i.name}</Option> })}
                                        </Select>{!validCompany && <span className='messageError'>Please select the company</span>}</Col>

                                </Row>

                            </Form.Item>}
                            <Form.Item name="phone" label="Phone Numbers"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <Select mode="tags" dropdownStyle={{ display: 'none' }} placeholder='Enter phone numbers separated by comma'
                                    style={{ width: '100%' }} onChange={this.handlePhoneNumberChange} tokenSeparators={[',']}>
                                    {children}
                                </Select>
                            </Form.Item>
                            <Form.Item name={['message']} label="Message"
                                rules={[{ required: true, message: 'Please input your message!' },
                                { min: 25, message: `Message can't be less than 25 chars` },
                                { max: 255, message: `Message can't be more than 255 chars` },
                                ]}>
                                <TextArea rows={4} placeholder='Enter your message' />
                            </Form.Item>

                            <Form.Item className='addUserBtn' wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit" style={{ float: 'right' }} onClick={this.handleCompanyValidation} loading={dataSending}>
                                    Submit
                                </Button>

                            </Form.Item>

                        </Form>
                    }
                </> : <Spin tip="Loading..." size="large" >

                </Spin>}
            </div>
        )
    }
}

export default connect(null, { getComponies })(SmsNotification)


