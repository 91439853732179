import axios from '../stores/requests';
import Cookies from 'js-cookie';

export const patchApi = (api: string, info: any) => {

    return async (_dispatch: any) => {
        try {
            const token = Cookies.get('token');
            const { data } = await axios.patch(api, info, {
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            return {
                payload: data
            };
        } catch (e) {
            return {
                error: true,
            };
        }
    }
}