import { Button, Col, Form, message, Row, Select } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getApi } from '../stores/actions';
const { Option } = Select;

interface Props {
    getApi: any
}
export class ZohoTest extends Component<Props> {
    state = {
        sending: false,
        type: ['company', 'user']
    }

    zohoTicket = async (value: any) => {
        this.setState({ sending: true })
        console.log("🚀 ~ file: zohoTest.tsx ~ line 17 ~ ZohoTest ~ value", value)
        // let data = value.zohoCompany ? 'company' : value.zohoCompany && 'user';
        // console.log("🚀 ~ file: zohoTest.tsx ~ line 15 ~ ZohoTest ~ data", data)
        // console.log("🚀 ~ file: zohoTest.tsx ~ line 6 ~ ZohoTest ~ value", value);
        const zoho = await this.props.getApi(`/companies/testZohoTicket?zoho=${value.type}`);

        try {
            const res = await zoho.payload;
            if (res.status === 200) {
                this.setState({ sending: false })
                message.success(res.message)
            } else {
                this.setState({ sending: false })
                message.error(res.message)
            }
        } catch (error) {
            this.setState({ sending: false })
            message.error('Something went wrong, please try again')
        }
    }
    render() {
        const { sending, type } = this.state;
        return (
            <div>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.zohoTicket}
                    autoComplete="off"

                >
                    <Row>
                        <Col span={12}>
                            <label htmlFor="">Open zoho for</label>
                            <Form.Item
                                name={'type'}
                                rules={[{ required: true, message: 'Required' }]}
                            >
                                <Select
                                    placeholder="Select the team"
                                    showSearch
                                    allowClear
                                >
                                    {type && type.map((i: any) => {
                                        return <Option value={i}>{i}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={13}>
                            <Form.Item wrapperCol={{ offset: 0, span: 0 }} style={{ margin: '10px 0' }}>
                                <Button type="primary" htmlType="submit" loading={sending}>
                                    Next
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </div>
        )
    }
}

export default connect(null, { getApi })(ZohoTest)