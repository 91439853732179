import { Button, Col, Form, Input, Row, Select, message } from 'antd'
import GetInfoPic from '../../assets/images/getInfoPic.png'
import UserPhoneVector from "../../assets/images/userPhoneVector.png"
import helper from '../../utils/helper';
import { getUserBalanceApi, getRequestFeesApi, getServiceTypesApi } from '../../utils/apisConsts';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { stepperMoving, viewPaymentPlans } from '../../stores/actions';
import { errorMessage } from '../../utils/commonStringsConsts';
import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
interface paymentPlansParams {
    phone: string,
    amount: string,
    serviceTypeID: number,
}

export default function GetPaymentPlans() {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [isLoadingService, setIsLoadingService] = useState(false);
    const [isError, setIsError] = useState(false);
    const [serviceTypes, setServiceTypes] = useState([]);
    const { Option } = Select;
    const storeServiceTypes = async () => {
        setIsLoadingService(true)
        try {
            const getServiceTypes: any = await helper.callApiRequest( getServiceTypesApi, 'GET');;
            setIsLoadingService(false)
            if (getServiceTypes?.data?.status !== 200) {
                setIsError(true)
            }
            setServiceTypes(getServiceTypes?.data?.data)
        } catch (error) {
            setIsLoadingService(false)
            setIsError(true)
        }


    }
    useEffect(() => {
        storeServiceTypes();
    }, [])

    const getUserData = async (values: paymentPlansParams) => {
        setLoader(true);
        const userBalanceInfo = {
            phone: values.phone
        }

        const getUserID: any = await helper.callApiRequest(getUserBalanceApi, 'GET', userBalanceInfo, '');
        if (getUserID?.data?.status === 200) {
            const getAllPlans: any = await helper.callApiRequest(getRequestFeesApi + `?userID=${getUserID?.data?.userID}&amount=${values.amount}&serviceID=${values?.serviceTypeID}`, 'GET');
            console.log('getAllPlans.status = ', getAllPlans.status);
            console.log('getAllPlans = ', getAllPlans);


            if (getAllPlans.data.status === 200) {
                dispatch(viewPaymentPlans(getAllPlans?.data?.plans))
                dispatch(stepperMoving(1));
                setLoader(false);
                return
            }
            setLoader(false);
            message.error(getAllPlans?.data?.message);
            return
        } 
        setLoader(false);
        message.error(getUserID?.data?.message ?? errorMessage)
    }
    return (
        <div className="userInfo">
            {isLoadingService ? <Spinner size='large' /> : isError ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage}
                btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
                <Form name="nest-messages" onFinish={getUserData}>
                    <Row>

                        <Col className='phoneForm' span={8}>
                            <img src={GetInfoPic} alt='GetInfoPic' style={{ display: 'flex', margin: '0 auto' }} />


                            <Form.Item name={'phone'} style={{ width: '100%' }}
                                rules={[
                                    { required: true, message: "Phone Number field is mandatory!" },
                                ]}>
                                <div className='infoInputs'>

                                    <div className='inputFrame' style={{ marginTop: "15px" }}>
                                        <Input placeholder='Phone Number'
                                            prefix={<img src={UserPhoneVector} className='icons' alt='icon' />} />
                                    </div>
                                </div>
                            </Form.Item>




                            <Form.Item name={'amount'} style={{ width: '100%' }}
                                rules={[
                                    { required: true, message: "Amount field is mandatory!" },
                                ]}>
                                <div className='infoInputs'>

                                    <div className='inputFrame' style={{ marginTop: "15px" }}>
                                        <Input placeholder='Amount'
                                            prefix={<span style={{ color: '#7CC58F', margin: '0 4px', fontSize: '15px' }}>$</span>} />
                                    </div>
                                </div>
                            </Form.Item>


                            <div className='infoInputs'>
                                <div className='inputFrame' style={{ marginTop: "15px" }}>
                                    <Form.Item name={"serviceTypeID"} style={{ width: '100%' }} rules={[{ required: true, message: "Service Type is mandatory!" }]}>
                                    <Select style={{ marginTop: '10px',width:'100%' }}
                                            placeholder={'Please select the service'}
                                            showSearch
                                            allowClear
                                            filterOption={((input, option: any) => helper.dropDownSearch(input, option))}
                                        >
                                            {serviceTypes.map((service: any, key: number) => {
                                                return <Option value={service.id} data={service} key={key}>{service.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>


                            <Form.Item>
                                <Button className='userBtn' shape="round" type="primary" loading={loader} block htmlType="submit" style={{ backgroundColor: "#7CC58F", borderColor: "#7CC58F" }}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >}
        </div>
    )
}
