import { AUTHED, NUMBER_VERIFY, PIN_VERIFY, FORGOT_PASSWORD, TIME_OUT, PHONE_NUMBER, PIN_CODE, ROUTES } from "./types";
import Cookies from 'js-cookie'

const initialState = {
    authed: Cookies.get('token') ? true : false,
    phone_verified: false,
    pin_code_verified: false,
    forgot_password: false,
    time_out: false,
    phoneNumber: null,
    pinCode: '85369',
    accessRoutes: [],
}
const Login = (state = initialState, action: any) => {
    switch (action.type) {
        case AUTHED:
            return {
                ...state,
                authed: action.payload
            }
        case PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
            }
        case NUMBER_VERIFY:
            return {
                ...state,
                phone_verified: action.payload
            }
        case PIN_CODE:
            return {
                ...state,
                pinCode: action.payload
            }
        case PIN_VERIFY:
            return {
                ...state,
                pin_code_verified: action.payload
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgot_password: action.payload
            }
        case TIME_OUT:
            return {
                ...state,
                time_out: action.payload
            }
        case ROUTES:
            console.log('Routes = ', action.payload);

            return {
                ...state,
                accessRoutes: action.payload
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default Login