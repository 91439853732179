import React from 'react'
import { driversListOpsColumns } from '../../../utils/tableColumns'
import DriversList from './driversList'

export default function DriverListOps() {
    const columns: any = driversListOpsColumns;
    return (
        <>
            <DriversList columns={columns} role='Driver' ops/>
        </>
    )
}
