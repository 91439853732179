import { useEffect, useState } from 'react';
import moment from "moment";
import helper from '../../utils/helper';
import { errorMessage } from '../../utils/commonStringsConsts';
import { getUserBanksApi, createBulkUsersAPI } from '../../utils/apisConsts';
import TruckingModal from "../Trucking/modal";

import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  message,
  List,
} from "antd";
const { Option } = Select;


export default function CreateUser() {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMode, setErrorMode] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const [banks, setBanks] = useState([]);
  const [companies, setCompanies] = useState([]);


  const [createUserFormInstance] = Form.useForm();
  const validationPatterns: any = helper.regexValidation;


  useEffect(() => {
    fetchRequiredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Functions ----------------------------------------------------------
  const fetchRequiredData = async () => {
    try {
      setLoader(true);
      const [companiesData, banksRes]: any[] = await Promise.all([
        helper.getAllCompanies(),
        helper.callApiRequest(getUserBanksApi, 'GET')
      ]);

      setLoader(false);
      if ((companiesData.status !== 200) || (banksRes.data.status !== 200)) {
        setErrorMode(true); return;
      }

      setCompanies(companiesData.data);
      setBanks(banksRes.data.banks);
    } catch (error) {
      setErrorMode(true);
      setLoader(false);
    }
  }

  const submitForm = async (userData: any) => {
    setSaving(true);

    try {
      const formalizedUserData = {
        updatedFields: [
          "phone", "name", "bankName", "accountHolderName", "accountNumber", "netSalary", "companyName", "email", "nationalID", "staff_id"
        ],
        sheetData: [{
          ...userData,
          hiringDate: userData.hiringDate ? helper.changeDateFormat(userData.hiringDate, 'YYYY/MM/DD 12:00:00') : '',
        }],
        defaultSignUp: true,
      };
      userData.hiringDate && formalizedUserData.updatedFields.push('hiringDate');
      userData.jobTitle && formalizedUserData.updatedFields.push('jobTitle');
      userData.accountHolderNameEn && formalizedUserData.updatedFields.push('accountHolderNameEn');
      const submissionResponse: any = await helper.callApiRequest(createBulkUsersAPI, 'POST', '', formalizedUserData);
      setSaving(false);

      if (submissionResponse?.data?.status === 200) {
        createUserFormInstance.resetFields();
        message.success(submissionResponse.data.message);
        return;
      }

      setErrorsList(submissionResponse.data?.reason[0]?.errors ?? []);
    } catch (_) {
      message.error(errorMessage);
      setSaving(false);
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const closeErrorsModal = () => { setErrorsList([]); }

  // JSX ----------------------------------------------------------
  const createUserFormFieldsData = [
    <Form.Item
      label='Phone'
      name="phone"
      rules={[
        { required: true, message: "Phone field is mandatory!" },
        {
          pattern: validationPatterns.phoneNum,
          message: "Phone field is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the Phone" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='User Name'
      name="name"
      rules={[
        { required: true, message: "User Name field is mandatory!" },
        {
          pattern: validationPatterns.englishStringRegex,
          message: "User Name field is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the User Name" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='Net Salary'
      name="netSalary"
      rules={[
        { required: true, message: "Net Salary field is mandatory!" },
        {
          pattern: validationPatterns.positiveIntegerNumbers,
          message: "Net Salary field is not valid",
        }
      ]}
    >
      <Input type='number' placeholder="Enter the Net Salary" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item name="companyName" label="Companies" rules={[{ required: true, message: "Company field is mandatory!" }]}>
      <Select
        placeholder="Select a company"
        allowClear
        disabled={saving}
        showSearch
        filterOption={((input, option: any) => helper.dropDownSearch(input, option))}
      >
        {Array.isArray(companies) && companies.map((companyData: any, key: number) => {
          return <Option value={companyData.name} key={key}>{companyData.name}</Option>
        })}
      </Select>
    </Form.Item>
    ,
    <Form.Item name="bankName" label="Banks" rules={[{ required: true, message: "Bank field is mandatory!" }]}>
      <Select
        placeholder="Select a bank"
        allowClear
        disabled={saving}
        showSearch
        filterOption={((input, option: any) => helper.dropDownSearch(input, option))}
      >
        {Array.isArray(banks) && banks.filter((bankData: any) => bankData.bankID).map((bankData: any, key: number) => {
          return <Option value={bankData.bankName} key={key}>{bankData.bankName}</Option>
        })}
      </Select>
    </Form.Item>
    ,
    <Form.Item
      label='Account Number'
      name="accountNumber"
      rules={[
        { required: true, message: "Account Number field is mandatory!" },
        {
          pattern: validationPatterns.integerNumbers,
          message: "Account Number field is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the Account Number" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='Account Holder Name'
      name="accountHolderName"
      rules={[
        { required: true, message: "Account Holder Name field is mandatory!" },
        {
          pattern: validationPatterns.englishStringRegex,
          message: "Account Holder Name field is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the Account Holder Name" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='Account Holder Name (English)'
      name="accountHolderNameEn"
      rules={[
        { pattern: helper.regexValidation.englishRegex, message: 'Account name (English) must be in english' },
        { min: 3, message: `Account name (English) can't be less than 3 chars` },
        { max: 250, message: `Account name (English) can't be more than 250 chars` },
      ]}
    >
      <Input placeholder="Enter the Account Holder Name (English)" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='National ID'
      name="nationalID"
      rules={[
        {
          pattern: validationPatterns.nationalId,
          message: "National ID field is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the National ID" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='Email'
      name="email"
      rules={[
        {
          pattern: validationPatterns.email,
          message: "Email field is not valid",
        },
      ]}
    >
      <Input placeholder="Enter the Email" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='Hiring Date'
      name="hiringDate"
    >
      <DatePicker
        style={{ width: '100%' }}
        placeholder="Enter the Hiring Date"
        disabledDate={(pickerDate) => {
          const currentDate = moment().format("YYYY-MM-DD");
          return pickerDate && pickerDate > moment(currentDate, "YYYY-MM-DD");
        }}
        disabled={saving}
      />
    </Form.Item>
    ,
    <Form.Item
      label='Staff ID'
      name="staff_id"
      rules={[
        {
          pattern: validationPatterns.englishRegex,
          message: "Staff ID is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the Staff Id" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item
      label='Job Title'
      name="jobTitle"
      rules={[
        {
          pattern: validationPatterns.englishRegex,
          message: "Job Title field is not valid",
        }
      ]}
    >
      <Input placeholder="Enter the job title" disabled={saving} />
    </Form.Item>
    ,
    <Form.Item style={{ marginTop: "30px", float: "right", width: "17%" }}>
      <Button
        type="primary"
        block
        shape="round"
        htmlType="submit"
        loading={saving}
        disabled={saving}
        style={{ backgroundColor: "#7CC58F", borderColor: "#7CC58F" }}
      >
        Save
      </Button>
    </Form.Item>
  ];

  const createUserForm = <>
    <Form
      name="create"
      form={createUserFormInstance}
      onFinish={submitForm}
      className='applyNewDesign'
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign='left'
      labelWrap
    >
      {
        createUserFormFieldsData.map((formFieldData: any) => <>{formFieldData}</>)
      }
    </Form>
  </>;

  const errorsModalContent = <List
    style={{ width: '500px' }}
    dataSource={errorsList}
    renderItem={(errorMessage: string) => (
      <List.Item>
        <List.Item.Meta
          title={<h4>{errorMessage}</h4>}
        />
      </List.Item>
    )}
  />;


  // Render ----------------------------------------------------------
  return (
    <>
      {
        loader ? <Spinner size='large' /> : (
          errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage} btnTitle={'Refresh the page'} btnClick={refreshPage} /> :
            createUserForm
        )
      }

      <TruckingModal handleCloseModal={closeErrorsModal} visibleModal={!!errorsList.length} content={errorsModalContent} title="Error Message" />
    </>
  )
}
