
import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import UnAssignUserRoleForm from './unAssignUserRoleForm';
import { getUserByPhoneApi } from '../../utils/apisConsts';

class UnAssignUserRoles extends Component{
    state = {
        componentSteps: [
            {
                title: 'Get User',
                content: <GetInfo apiLink={getUserByPhoneApi} />,
                id: 0,
            }, {
                title: 'Un-Assign User Roles',
                content: <UnAssignUserRoleForm />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default UnAssignUserRoles
