import React, { PureComponent } from 'react'
import { Empty, Button } from 'antd';
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps<any> {
    /* Parent component's props*/
    image?: string,
    description?: string,
    btnDefault?: boolean,
}
export class NotFound extends PureComponent<Props, {}> {
    homeRedirect = () => {
        this.props.history.push('/');
    }
    render() {
        const { image, description, btnDefault } = this.props;
        return (
            <div>
                <Empty
                    image={image ? image : "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"}
                    imageStyle={{
                        height: 60,
                    }}
                    description={
                        <span>
                            {description ? description : 'Where are you going ?'}
                        </span>
                    }
                >
                    <Button type={btnDefault ? "default" : "primary"} onClick={this.homeRedirect}>Home</Button>
                </Empty>
            </div>
        )
    }
}

export default withRouter(NotFound)
