
import React, { Component } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import UserSalary from './userSalary';
import { getUserByPhoneApi } from '../../utils/apisConsts';

class ViewUserSalary extends Component{
    state = {
        componentSteps: [
            {
                title: 'Get User',
                content: <GetInfo apiLink={getUserByPhoneApi}/>,
                id: 0,
            }, {
                title: 'User Status',
                content: <UserSalary />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}


export default ViewUserSalary