import { Button, Col, Empty, Form, FormInstance, Input, message, Row, Select, Table } from 'antd';
import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
import { useState, useCallback, useEffect, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { getApi, patchApi } from '../../stores/actions';
import ImageViewer from 'react-simple-image-viewer';
import helper from '../../utils/helper';
import TruckingModal from '../Trucking/modal';
import regex from '../../utils/helper';
import ErrorsContent from './errorsContent';

// import EditForm from './editForm';
const { Option } = Select;
const formRef = createRef<FormInstance>();
const columns: any = [


    {
        title: 'رقم تليفون الموظف',
        dataIndex: 'phone',
    },
    {
        title: 'نوع الخطأ',
        dataIndex: 'arabicErrors',
        render: (record: { problem: any }) =>
            <>
                <ul style={{ margin: '0 15px', padding: '0' }}>
                    {Array.isArray(record) && record?.map((oneRecord: any, key: any) => {
                        return <li style={{ color: '#CC4949' }} key={key}>{oneRecord.error}</li> 
                    })}
                </ul>
            </>
    },
];
interface Props {
    getApi?: any,
    rows?: any[],
    handleTableChange?: any,
    pagination?: any,
    loading?: boolean,
    scrolling?: any,
    gettingData?: any,
    apiLink?: string,
    isPending?: boolean,
}
interface DataType {
    key?: React.Key;
    name?: string;
    age?: number;
    address?: string;
    id?: any,
}
function HrTables(props: Props) {
    const [loader, setLoader] = useState(false);
    const [errorMode, setErrorMode] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [visible, setVisible] = useState(false);

    const [paginating, setPaginating] = useState({
        pageSize: 10,
        total: 0,
        current: 1,
    })
    const [spin, setSpin] = useState(false);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openRejectionModal, setOpenRejectionModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [question, setQuestion] = useState('');
    const [reasons, setReasons] = useState([]);
    const [currentUser, setCurrentUser] = useState({
        userID: '',
        phone: '',
    });
    const [approveSpin, setApproveSpin] = useState(false);
    const [reasonID, setReasonID] = useState(0);
    const [editable, setEditable] = useState(false);
    const [banks, setBanks] = useState([]);
    const [user, setUser] = useState({
        staffID: '',
        name: '',
        nationalID: '',
        netSalary: '',
        jobTitle: '',
        phone: '',
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        userID: '',
    });
    const [editSpin, setEditSpin] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [openErrorsModal, setOpenErrorsModal] = useState(false);
    const [errors, setErrors] = useState([{
        fieldName: '',
        errors: [],
    }]);
    const [errorsConfig, setErrorsConfig] = useState({
        nameRequired: 0,
        phoneRequired: 0,
        netSalaryRequired: 0,
        bankAccountNumberRequired: 0,
        bankAccountNameRequired: 0,
        jobTitleRequired: 0,
        staffIDRequired: 0,
        bankNameRequired: 0,
        nationalIDRequired: 0
    })
    const [consentRequired, setConsentRequired] = useState(false);
    const [hrLetterRequired, setHrLetterRequired] = useState(false);
    const [cash, setCash] = useState(false);
    const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
    const [keys, setKeys] = useState<React.Key[]>([]);
    const [moreUsers, setMoreUsers] = useState(false);
    const [bulkApproveModal, setBulkApproveModal] = useState(false);
    const [bulkErrors, setBulkErrors] = useState([{
        phone: '',
        arabicErrors: [],
    }]);
    const [openBulkErrorsModal, setOpenBulkErrorsModal] = useState(false)
    const dispatch = useDispatch();

    const errorFunc = (msg: string) => {
        setLoader(false);
        setErrorMode(true);
        setEditSpin(false);

    }
    const getDataForTable = useCallback(async (dataP: any) => {
        const data: any = await dispatch(getApi(`${props?.apiLink}?rowsPerPage=${dataP.pageSize}&pageNum=${dataP.current}`, {}))
        try {
            const res = await data.payload;
            console.log("🚀 ~ file: hrTables.tsx ~ line 130 ~ getDataForTable ~ res", res)
            setConsentRequired(res.consentRequired)
            setHrLetterRequired(res.hrLetterRequired)


            if (res.status === 200) {
                setLoader(false);
                setSpin(false);
                const newData = res?.users?.map((user: any, key: any) => {
                    return {
                        ...user,
                        key: setTimeout(() => {
                            return `${key} ${data.current} ${data.filter}`
                        }, 100),
                    }
                })
                setTableRows(newData)
                setPaginating((prevState: any) => ({
                    ...prevState,
                    total: res?.userCount,
                    current: dataP?.current,
                }));

            }
            else if (res.status === 204) {
                errorFunc(res.message)
            }
            else {
                errorFunc(res.message)
            }
        } catch (error) {
            errorFunc('Something went wrong, please try again')

        }
    }, [dispatch, props.apiLink]);

    const getDropdownsData = useCallback(async (apiName: string) => {

        const data: any = await dispatch(getApi(apiName, {}))
        try {
            const res = await data.payload;
            if (res.status === 200) {
                res.reasons && setReasons(res.reasons);
                res.banks && setBanks(res.banks)
            } else if (res.status === 204) {
                errorFunc(res.message)
            }
            else {
                errorFunc(res.message)
            }
        } catch (error) {
            errorFunc('حدث خطأ ما برجاء المحاوله لاحقا')
        }
    }, [dispatch]);

    const getValidationObj = useCallback(async () => {

        const data: any = await dispatch(getApi('hr/companyRequiredData', {}))
        try {
            const res = await data.payload;
            if (res.status === 200) {
                setErrorsConfig(res.data)
            } else if (res.status === 204) {
                errorFunc(res.message)
            }
            else {
                errorFunc(res.message)
            }
        } catch (error) {
            errorFunc('حدث خطأ ما برجاء المحاوله لاحقا')
        }
    }, [dispatch]);
    useEffect(() => {
        setLoader(true)

        const data = {
            pageSize: 10,
            total: 0,
            current: 1,
        }
        getDataForTable(data);
    }, [getDataForTable]);

    useEffect(() => {
        getDropdownsData('/users/banks');
        getDropdownsData('/hr/availableReasons');
    }, [getDropdownsData]);
    useEffect(() => {
        getValidationObj()
    }, [getValidationObj]);
    const changePagination = async (values: any) => {
        setSpin(true);
        await setPaginating((prevState: any) => ({
            ...prevState,
            current: values?.current,
            pageSize: values.pageSize
        }))
        const data: any = {
            pageSize: paginating.pageSize,
            total: values.pageSize,
            current: values?.current,
        }
        await getDataForTable(data)


    }
    const closeImageViewer = () => {
        setIsViewerOpen(false);
        setVisible(false)
    };
    const approveModal = async (val: boolean, record: any) => {
        record.bankName.includes("Cash") && setCash(true);
        await setCurrentUser(record)
        await setUser(record)
        await setOpenApproveModal(val)
        await setQuestion('هل انت متأكد من قبول طلب الموظف ؟');
        await setModalTitle('قبول طلب الموظف');
        await setEditMode(false);

        await formRef.current!.resetFields();
    }
    const rejectionModal = (val: boolean, record: any, Q: string) => {
        setCurrentUser(record)
        setOpenRejectionModal(val);
        setQuestion(Q);
        setModalTitle('رفض طلب الموظف');
    }
    const closingApprovalModal = async () => {
        await setOpenApproveModal(false);
        await formRef.current!.resetFields();
    }
    const closingRejectionModal = () => {
        setOpenRejectionModal(false);
        formRef.current?.resetFields();
    }
    const changeUserStatus = async (isApproved: boolean) => {
        setApproveSpin(true);
        console.log(selectedRows);
        const oneUser = {
            phone: currentUser.phone,
            userID: currentUser.userID
        }
        const data = isApproved ? {
            sheetData: moreUsers ? selectedRows : [oneUser],
            reasonID: 52,
        } : {
            sheetData: moreUsers ? selectedRows : [oneUser],
            reasonID: reasonID,
        }
        console.log(data);

        try {
            const api: any = await dispatch(patchApi(`/hr/hrBulkUpdateStatus`, data));
            const res = api.payload;
            if (res.status === 400) {
                console.log(res?.data?.reason);
                const newReasons: any = Array.isArray(res?.data?.reason) && res?.data?.reason.map((reason: any, key: any) => {
                    return {
                        phone: reason?.phone,
                        arabicErrors: reason?.arabicErrors,
                    }
                });
                setBulkErrors(newReasons);
                setOpenBulkErrorsModal(true);
                setApproveSpin(false);
                setBulkApproveModal(false)
                return 0
            }
            if (res.status === 200) {
                message.success('تم تغيير حالة الموظف بنجاح');
                setApproveSpin(false);
                setSpin(true)

                const data = {
                    pageSize: paginating.pageSize,
                    total: paginating.total,
                    current: paginating.current,
                }
                getDataForTable(data);
                setOpenApproveModal(false);
                setOpenRejectionModal(false);
                setMoreUsers(false)
                setBulkApproveModal(false)
                formRef.current?.resetFields();

            } else {
                message.error('يوجد خطأ، غير قادر علي تغيير حالة الموظف');
                setApproveSpin(false)
            }

        } catch (error) {
            console.log(error);
            message.error('حدث خطأ ما برجاء المحاوله مره اخري');
            setApproveSpin(false)
        }
    }
    const onReasonsChange = (val: number) => {
        setReasonID(val)
    }
    const openEditModal = async (record: any) => {
        record.bankName.includes("Cash") && setCash(true);
        await setEditMode(true)
        await setUser(record)
        await setEditable(true);
        await formRef.current!.resetFields();

    }
    const cancelEditModal = () => {
        setEditable(false);
        setEditMode(false);
        formRef.current!.resetFields();
        setCash(false)
    }
    const editUser = async (values: any) => {
        const data = {
            reasonID: 52,
            userID: user?.userID,
            phone: values?.phone,
            name: values?.name,
            bankName: values?.bankName,
            accountNumber: values.accountNumber ? values?.accountNumber : 'Cash',
            accountHolderName: values.accountHolderName ? values?.accountHolderName : 'Cash',
            staff_id: values.staff_id,
            nationalID: values.nationalID ? values?.nationalID : '',
            jobTitle: values.jobTitle ? values?.jobTitle : '',
            netSalary: Number(values?.netSalary),
        }


        if (editMode) {
            const apiData: any = await dispatch(patchApi(`hr/hrUpdateUser`, data));
            const res = await apiData.payload;
            setEditSpin(true);
            try {
                if (res.status === 200) {
                    message.success('User Updated Successfully');
                    const data = {
                        pageSize: paginating.pageSize,
                        total: paginating.total,
                        current: paginating.current,
                    }
                    getDataForTable(data);
                    setEditSpin(false);
                    cancelEditModal();
                } else {
                    setEditSpin(false);
                    if (res.message.includes('full name')) {
                        message.error('برجاء ادخال اسم الموظف كامل');
                        return
                    }
                    if (res.status === 422) {
                        setErrors(res.errors)
                        setOpenErrorsModal(true);
                        return 0
                    }
                    if (res.status === 400) {
                        setErrors(res.errors)
                        setOpenErrorsModal(true);
                        return 0
                    }
                    message.error(res.message);
                }
            } catch (error) {
                setEditSpin(false)
                message.error('حدث خطأ ما برجاء المحاوله لاحقا')
            }
            return 0

        } else {
            setApproveSpin(true)
            try {
                const api: any = await dispatch(patchApi(`/hr/hrUpdateAndApproveUser`, data));
                const res = api.payload;
                if (res.status === 422) {
                    setErrors(res.errors)
                    setOpenErrorsModal(true);
                    setApproveSpin(false)

                    return 0
                }
                if (res.status === 200) {

                    message.success('تم تغيير حالة الموظف بنجاح');
                    setApproveSpin(false);
                    cancelEditModal();
                    setSpin(true)

                    const data = {
                        pageSize: paginating.pageSize,
                        total: paginating.total,
                        current: paginating.current,
                    }
                    getDataForTable(data);
                    setOpenApproveModal(false);
                    setOpenRejectionModal(false);
                    formRef.current?.resetFields();
                } else {
                    message.error('يوجد خطأ، غير قادر علي تغيير حالة الموظف');
                    setApproveSpin(false)
                }

            } catch (error) {
                console.log(error);
                message.error('حدث خطأ ما برجاء المحاوله مره اخري');
                setApproveSpin(false)
            }
            return 0
        }


    }
    const cancelModalApprove = async () => {
        await setOpenApproveModal(false);
        await formRef.current!.resetFields();
    }
    const changeBank = (bank: any) => {
        if (bank.includes('Cash')) {
            setCash(true);
        } else {
            setCash(false)
        }
    }


    const rowSelection = {

        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            const users: any = selectedRows?.map((user: any, i: any) => {
                return {
                    phone: user.phone,
                    userID: user.userID
                }
            })
            if (selectedRowKeys.length > 1) {
                setMoreUsers(true);
            } else {
                setMoreUsers(false)
            }
            setKeys(selectedRowKeys)
            setSelectedRows(users)

        },
        keys,
    };

    const newUsersColumns = [
        {
            title: ' الرقم التعريفي',
            dataIndex: 'staff_id',
            width: 120
        },
        {
            title: 'اسم الموظف',
            dataIndex: 'name',
            width: 250
        },
        {
            title: 'رقم التليفون',
            dataIndex: 'phone',
            width: 150
        },
        {
            title: 'المرتب',
            dataIndex: 'netSalary',
            width: 100
        },
        {
            title: 'HR Letter',
            width: 100,
            dataIndex: 'hrLetter',
            render: (_text: any, record: any) => ((<>
                {record.hrLetter ? <div>
                    <span className='consentTable' onClick={() => setIsViewerOpen(true)}>{'Hr_Letter.png'}</span>
                    {isViewerOpen && (
                        <ImageViewer
                            src={[record.hrLetter]}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                            backgroundStyle={{ background: '#114c6480' }}
                        />
                    )}
                </div> : 'لا يوجد'}
            </>))
        },
        {
            title: 'Consent',
            width: 100,
            dataIndex: 'consent',
            render: (_text: any, record: any) => ((<>
                {record.consent ? <div>
                    <span className='consentTable' onClick={() => setVisible(true)}>{'Consent.png'}</span>
                    {visible && (
                        <ImageViewer
                            src={[record.consent]}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                            backgroundStyle={{ background: '#114c6480' }}
                        />
                    )}
                </div> : 'لا يوجد'}
            </>))
        },
        {
            title: '',
            key: 'actions',
            // width: 280,
            render: (_text: any, record: {
                staffID: string,
                name: string,
                nationalID: string,
                netSalary: number,
                jobTitle: string,
                phone: string,
            }) => ((<>
                <>
                    <Button className='approve' onClick={() => approveModal(true, record)} type="primary">موافقه</Button>
                    <Button className='refuse' onClick={() => rejectionModal(true, record, 'هل انت متأكد من رفض طلب الموظف؟')}>رفض </Button>
                    <Button className='edit' onClick={() => openEditModal(record)}> تعديل</Button></>
            </>)),
        }
    ];
    const pendingUsersColumns = [
        {
            title: ' الرقم التعريفي',
            dataIndex: 'staffID',
            width: 100
        },
        {
            title: 'اسم الموظف',
            dataIndex: 'name',
            width: 250
        },

        {
            title: 'الملاحظات',
            dataIndex: 'pendingStatus',
            width: 200
        },

        {
            title: 'HR Letter',
            width: 100,
            dataIndex: 'hrLetter',
            render: (_text: any, record: any) => ((<>
                {record.hrLetter ? <div>
                    <span className='consentTable' onClick={() => setIsViewerOpen(true)}>{'Hr_Letter.png'}</span>
                    {isViewerOpen && (
                        <ImageViewer
                            src={[record.hrLetter]}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                            backgroundStyle={{ background: '#114c6480' }}
                        />
                    )}
                </div> : 'لا يوجد'}
            </>))
        },
        {
            title: 'Consent',
            width: 100,
            dataIndex: 'consent',
            render: (_text: any, record: any) => ((<>
                {record.consent ? <div>
                    <span className='consentTable' onClick={() => setVisible(true)}>{'Consent.png'}</span>
                    {visible && (
                        <ImageViewer
                            src={[record.consent]}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                            backgroundStyle={{ background: '#114c6480' }}
                        />
                    )}
                </div> : 'لا يوجد'}
            </>))
        },
        // {
        //     title: '',
        //     key: 'actions',
        //     width: 280,
        //     render: (_text: any, record: any) => ((<>
        //         <Button className='view'> مشاهدة بيانات الموظف</Button>
        //     </>)),
        // }
    ];

    const newUsersConsent = newUsersColumns.findIndex((object: any) => {
        return object.dataIndex === 'consent';
    });


    !consentRequired && newUsersColumns.splice(newUsersConsent, 1);
    const newUsersHR = newUsersColumns.findIndex((object: any) => {
        return object.dataIndex === 'hrLetter';
    });
    !hrLetterRequired && newUsersColumns.splice(newUsersHR, 1);

    const pendingUsersConsent = pendingUsersColumns.findIndex((object: any) => {
        return object.dataIndex === 'consent';
    });
    !consentRequired && pendingUsersColumns.splice(pendingUsersConsent, 1);
    const pendingUsersHR = pendingUsersColumns.findIndex((object: any) => {
        return object.dataIndex === 'hrLetter';
    });
    !hrLetterRequired && pendingUsersColumns.splice(pendingUsersHR, 1);
    const rejectionContent = <>
        <div className='changeUserStatus'>
            <Form name="nest-messages" onFinish={() => changeUserStatus(false)} ref={formRef}>

                <p className='question'>{question}</p>
                <label className='label'>سبب الرفض</label>

                <Form.Item
                    name="reasonID"
                    rules={[{ required: true, message: 'اجباري' }]}
                >
                    <Select
                        onChange={onReasonsChange}
                        placeholder="اختار سبب الرفض">
                        {Array.isArray(reasons) && reasons.map((status: any, key: number) => {
                            return <Option value={status.id} status={status} key={key}>{status.arabicName}</Option>
                        })}
                    </Select>
                </Form.Item>

                <br />
                <Row>
                    <Col>
                        <Form.Item>
                            <Button className='reject' htmlType="submit" loading={approveSpin}>نعم، اريد الرفض</Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button className='decline' onClick={closingRejectionModal}>لا اريد الإلغاء</Button>
                    </Col>
                </Row>
            </Form>


        </div>
    </>
    const approveBulkUsers = <>
        <div className='changeUserStatus'>
            <p className='question'>{'هل انت متأكد من قبول الموظفين ؟'}</p>
            <Button className='approve' onClick={() => changeUserStatus(true)} loading={approveSpin}>نعم، اريد القبول </Button>
            <Button className='decline' onClick={() => setBulkApproveModal(false)}>لا اريد الإلغاء</Button>
        </div>

    </>
    const editContent = <>

        <Form
            initialValues={user}
            onFinish={editUser}
            ref={formRef}
        >

            <div className="hrEditUser">
                <Row>
                    <Col span={24}>
                        <label className='label' htmlFor="">اسم الموظف
                            {errorsConfig.nameRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="name" rules={[{ required: errorsConfig.nameRequired ? true : false, message: 'برجاء ادخال اسم الموظف' },
                        { min: 9, message: 'برجاء ادخال اسم الموظف كامل' }]}
                            initialValue={user?.name}>
                            <Input placeholder='اسم الموظف' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <label className='label' htmlFor="">المسمي الوظيفي
                            {errorsConfig.jobTitleRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="jobTitle" initialValue={user.jobTitle}
                            rules={[{ required: errorsConfig.jobTitleRequired ? true : false, message: 'برجاء ادخال المسمي الوظيفي للموظف  ' }]}>
                            <Input placeholder='علي سبيل المثال: اخصائي مبيعات' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <label className='label' htmlFor="">الرقم التعريفي للموظف
                            {errorsConfig.staffIDRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="staff_id" rules={[{ required: errorsConfig.staffIDRequired ? true : false, message: 'برجاء ادخال الرقم التعريفي للموظف' }]}
                            initialValue={user.staffID} >
                            <Input placeholder='00000' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <label className='label' htmlFor="">الرقم القومي
                            {errorsConfig.nationalIDRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="nationalID" rules={[{ required: errorsConfig.nationalIDRequired ? true : false, message: 'برجاء ادخال الرقم القومي' },
                        { pattern: regex.regexValidation.nationalId, message: 'برجاء ادخال رقم قومي صحيح' }]} initialValue={user?.nationalID} >
                            <Input placeholder='00 0000 0000 0000' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <label className='label' htmlFor="">رقم التليفون
                            {errorsConfig.phoneRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="phone" rules={[{ required: errorsConfig.phoneRequired ? true : false, message: 'برجاء ادخال رقم التليفون' },
                        { pattern: regex.regexValidation.egyptPhoneNum, message: 'برجاء ادخال رقم صحيح' }]} initialValue={user?.phone} >
                            <Input placeholder='01001111001' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <label className='label' htmlFor="">المرتب
                            {errorsConfig.netSalaryRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="netSalary" rules={[{ required: errorsConfig.netSalaryRequired ? true : false, message: 'برجاء ادخال الراتب للموظف' }]} initialValue={user?.netSalary} >
                            <Input placeholder='0 جنيه مصري' />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <label className='label' htmlFor="">اسم البنك
                            {errorsConfig.bankNameRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                        <Form.Item name="bankName" rules={[{ required: errorsConfig.bankNameRequired ? true : false, message: 'يرجاء اختيار البنك الخاص بالموظف' }]} initialValue={user?.bankName}>
                            <Select style={{ width: '100%' }} placeholder='اختار البنك الخاص بالموظف' onChange={changeBank}>
                                {Array.isArray(banks) && banks.map((bank: any, key: number) => {
                                    return <Option value={bank.bankName} key={key} >{bank.arabicName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {!cash && <>
                        <Col span={24}>
                            <label className='label' htmlFor="">رقم حساب الموظف
                                {errorsConfig.bankAccountNumberRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                            <Form.Item name="accountNumber" rules={[{ required: errorsConfig.bankAccountNumberRequired ? true : false, message: 'برجاء ادخال رقم حساب الموظف' },
                            { pattern: regex.regexValidation.accountNumber, message: 'برجاء ادخال رقم الحساب بطريقه صحيحه' }]} initialValue={user?.accountNumber}>
                                <Input placeholder='0000 0000 0000 0000' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label className='label' htmlFor="">الاسم الخاص بحساب الموظف
                                {errorsConfig.bankAccountNameRequired ? <>&nbsp;<span> * </span></> : <span className='optional'>(اختياري)</span>}</label>
                            <Form.Item name="accountHolderName" initialValue={user?.accountHolderName}
                                rules={[{ required: errorsConfig.bankAccountNameRequired ? true : false, message: 'برجاء ادخال الاسم الخاص بحساب الموظف' }]}>
                                <Input placeholder='اسم حساب الموظف' />
                            </Form.Item>
                        </Col>
                    </>}
                    <Col span={24}>
                        {editMode ? <div className="editFormBtns">
                            <Form.Item>
                                <Button className='confirmEdit' type="primary" htmlType="submit" loading={editSpin}>
                                    تأكيد
                                </Button>
                                <Button className='cancelEdit' type="default" onClick={cancelEditModal}>
                                    الغاء
                                </Button>
                            </Form.Item>
                        </div> :

                            <div className='changeUserStatus'>
                                <p className='question'>{question}</p>
                                <Button className='approve' htmlType="submit" loading={approveSpin}>نعم، اريد القبول </Button>
                                <Button className='decline' onClick={cancelModalApprove}>لا اريد الإلغاء</Button>
                            </div>}
                    </Col>
                </Row>

            </div>


        </Form>

    </>

    const tableErrors = <>
        <div className="table">
            <Table columns={columns} bordered dataSource={bulkErrors} pagination={false} />
        </div>
    </>
    return (
        <>

            <div style={{ margin: '20px 0 0 0' }}>
                {loader ? <Spinner size='large' /> : errorMode ? <OutPutResult status={'500'} title={'حدث خطأ ما'} subTitle={' '}
                    btnTitle={'إعاده تحميل الصفحه'} btnClick={helper.refreshPage} /> : tableRows?.length === 0 ? <Empty description='لا يوجد موظفون جدد' /> :
                    <>
                        {moreUsers && <>
                            <div style={{ margin: '-57px 0px 10px 0' }}>
                                <Button className='approve' style={{ width: 'fit-content' }} onClick={() => setBulkApproveModal(true)} type="primary"> قبول الموظفين </Button>
                                <Button className='refuse' style={{ width: 'fit-content' }} onClick={() => rejectionModal(true, 'record', 'هل انت متأكد من رفض الموظفين ؟')}> رفض الموظفين </Button>
                            </div>
                        </>
                        }
                        <Table
                            dataSource={tableRows}
                            scroll={{ x: 25 }}
                            columns={props.isPending ? pendingUsersColumns : newUsersColumns}
                            onChange={changePagination}
                            rowSelection={rowSelection}
                            pagination={paginating}
                            loading={spin}
                        />
                    </>
                }
            </div>;

            <TruckingModal visibleModal={openApproveModal} content={editContent}
                handleCloseModal={closingApprovalModal} title={modalTitle} />

            <TruckingModal visibleModal={bulkApproveModal} content={approveBulkUsers}
                handleCloseModal={() => setBulkApproveModal(false)} title={'الموافقه علي الموظفين'} />

            <TruckingModal visibleModal={openRejectionModal}
                content={rejectionContent} handleCloseModal={closingRejectionModal} title={modalTitle} />

            <TruckingModal visibleModal={editable}
                content={editContent}
                handleCloseModal={cancelEditModal} title={'تعديل بيانات الموظف'} />

            <TruckingModal visibleModal={openErrorsModal}
                // content={rejectionContent}
                content={<ErrorsContent backErrors={errors} />}

                handleCloseModal={() => setOpenErrorsModal(false)} title={'اسباب فشل التعديل'} />
            <TruckingModal visibleModal={openBulkErrorsModal}
                // content={rejectionContent}
                content={tableErrors}

                handleCloseModal={() => setOpenBulkErrorsModal(false)} title={'اسباب فشل التعديل'} />
        </>
    )
}

export default HrTables