import React, { Component } from 'react'
import Stepper from '../../components/stepper';
import FilterInvoices from './filterInvoices';
import Deductions from './view';

interface Props {
    lock: boolean
}

class ApproveNewInvoice extends Component<Props>{

    render() {
        const componentSteps = [
            {
                title: 'Get Deductions',
                content: <FilterInvoices />,
                id: 0,
            }, {
                title: 'View Deductions',
                content: <Deductions generateInvoice />,
                id: 1
            },
        ]
        return (
            <div className='create-merchant'>
                <Stepper componentSteps={componentSteps} />
            </div>
        )
    }
}

export default ApproveNewInvoice
