import React from 'react';
class PdfData extends React.Component {
  render() {
    return (
      <>
        {this.props.content}
      </>
    );
  }
}

export default PdfData;