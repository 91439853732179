import React from 'react'
import { Button, Collapse, List } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DrawerBtn from './drawerBtn';

const { Panel } = Collapse;
interface Props {
    userData?: any,
    activeKey: number,
    header: string,
    action?: any,
    actionName?: string,
    drawerTitle?: string,
    drawerWidth?: number,
    nonEditable?: boolean,
    collapsible?: any
}


export default function userInfoUI(props: Props) {
    return (
        <div className='infoUI'>

            <br />
            <Collapse defaultActiveKey={['1']} >
                <Panel header={props.header} key={props.activeKey} collapsible={props.collapsible ? props.collapsible : undefined} showArrow={props.collapsible === "disabled" ? false : true }
                    extra={props.nonEditable ? "" : <DrawerBtn drawerWidth={props.drawerWidth? props.drawerWidth : undefined} drawerTitle={props.drawerTitle? props.drawerTitle : ""}  actionName={props.actionName? props.actionName : ""} action={props.action}><Button shape="round" icon={<EditOutlined />}>Edit</Button></DrawerBtn>}>
                    <List
                        itemLayout="horizontal"
                        dataSource={props.userData}
                        renderItem={(item: any) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<h4>{item.key}</h4>}
                                />
                                <List.Item.Meta
                                    title={<p>{item.value}</p>}
                                />
                            </List.Item>
                        )}
                    />
                </Panel>
            </Collapse>

        </div>
    )
}
