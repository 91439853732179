
import React, { useState } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import UserDocsForm from './userDocsForm';
import { getUserByPhoneApi } from '../../utils/apisConsts';
export interface UploadUserDocsProps {
    apiLink: string;
}
export default function UploadUserDocs({ apiLink }: UploadUserDocsProps) {
    const [componentSteps] =useState([
        {
            title: 'Get User',
            content: <GetInfo apiLink={getUserByPhoneApi} />,
            id: 0,
        }, {
            title: 'Upload Documents',
            content: <UserDocsForm apiLink={apiLink} />,
            id: 1
        },
    ])

    return (
        <>
            <Stepper componentSteps={componentSteps} />
        </>
    )
}