import { Button, Col, Divider, Form, FormInstance, InputNumber, message, Row, Select, Switch, Tooltip, DatePicker } from "antd";
import { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { stepperMoving } from "../../stores/actions";
import { InfoCircleOutlined } from '@ant-design/icons';
import helper from "../../utils/helper";
import moment from "moment";

const { Option } = Select;

interface Props {
    companyData: any,
    setCompanyData: any,
    editAllInfo: boolean,
    create?: boolean
}

export default function EditCompanyForm2(props: Props) {
    const [inputValidation1, setInputValidation1] = useState(props.create ? true : false)
    const [inputValidation2, setInputValidation2] = useState(props.create ? true : false)
    const [inputValidation3, setInputValidation3] = useState(props.create ? true : false)
    const [inputValidation4, setInputValidation4] = useState(props.create ? true : false)
    const [inputValidation5, setInputValidation5] = useState(props.create ? true : false)
    const [inputValidation6, setInputValidation6] = useState(props.create ? true : false)
    const [inputValidation7, setInputValidation7] = useState(props.create ? true : false)
    const [cuttOffDayRefValidation, setCuttOffDayRefValidation] = useState(props.create ? true : false)
    const [departments, setDepartments] = useState([])
    const [loadingDepartments, setLoadingDepartments] = useState(false)
    const formRef = createRef<FormInstance>();
    let prevFormRef: any = createRef<FormInstance>();
    const dispatch = useDispatch()

    useEffect(()=>{
        getAllDepartments()
    },[])

    useEffect(() => {
        console.log("🚀 ~ file: editCompanyForm2.tsx:62 ~ return ~ departments:", props.companyData.departments)
        if (formRef) {
            prevFormRef.current = formRef.current
        }
        return () => {
            const keys = [
                "collectionDayExact",
                "collectionMonthShift",
                "maxInstallmentCount",
                "maxMonthlyPercentAllowed",
                "cashEnabled",
                "companyFreeMonths",
                "userFreePaymentCycles",
                "departments",
                "salaryPreparationDay",
                'cuttOffDayRef',
                "hasDeductionBasedFreePeriods",
                "requestRequiresActiveUser",
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
            ]
            props.create && keys.push("freeDeductionFees")
            onFinish(prevFormRef.current?.getFieldsValue(keys), false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef.current])

    const onFinish = (values: any, next?: boolean) => {
        console.log("🚀 ~ file: editCompanyForm2.tsx:67 ~ onFinish ~ values:", values)
        const communicationCycles = [{
            sunday: values.sunday,
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday
        }]
        values["communicationCycles"] = communicationCycles
        delete values.sunday
        delete values.monday
        delete values.tuesday
        delete values.wednesday
        delete values.thursday

        props.setCompanyData({
            ...props.companyData,
            ...values,
            // cuttOffDayRef: values.cuttOffDayRef
        })

        next && dispatch(stepperMoving(props.create ? 2 : 3))
    }

    const onFinishFailed = (error: any) => {
        console.log("🚀 ~ file: editCompanyForm1.tsx ~ line 40 ~ onFinishFailed ~ error", error)
    }

    const dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const getAllDepartments = async() => {
        try {
            setLoadingDepartments(true)
            const departs: any = await helper.getDepartments()
            if (departs.data.status === 200) {
               console.log("🚀 ~ file: editCompanyForm2.tsx:96 ~ getAllDepartments ~ departs.data:", departs.data)
               setDepartments(departs.data.departments)
            }
            else {
                message.error(departs.data.message, 4)
            }
            setLoadingDepartments(false)
        }
        catch (error) {
            console.log("🚀 ~ file: editCompanyForm2.tsx:113 ~ getAllDepartments ~ error:", error)
            setLoadingDepartments(false)
        }
    }

    return (
        <div className="createNewCompany">
            <Form
                name="basic"
                ref={formRef}
                initialValues={{
                    ...props.companyData,
                    ...(props.companyData.communicationCycles &&
                    {
                        sunday: props.companyData.communicationCycles[0]?.sunday,
                        monday: props.companyData.communicationCycles[0]?.monday,
                        tuesday: props.companyData.communicationCycles[0]?.tuesday,
                        wednesday: props.companyData.communicationCycles[0]?.wednesday,
                        thursday: props.companyData.communicationCycles[0]?.thursday,
                    }),
                    salaryPreparationDay: Number(props.companyData.salaryPreparationDay),
                    departments: Array.isArray(props.companyData.departments) ? props.companyData.departments.map((department:any)=> department?.departmentID ? department.departmentID : department) : [],
                    // cuttOffDayRef: Number(props.companyData.cuttOffDayRef)
                    deploymentDate: props.companyData.deploymentDate && moment(props.companyData.deploymentDate),
                    freePeriodExpiryDate: props.companyData.freePeriodExpiryDate && moment(props.companyData.freePeriodExpiryDate)
                }}
                onFinish={(values) => onFinish(values, true)}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row>
                    <Divider orientation="left">Invoices / Collection Configuration</Divider>
                    <Col span={!props.create ? 6 : 10}>

                        <label htmlFor="">Collection Exact Day <span>*</span></label>
                        <Form.Item name='collectionDayExact' rules={!inputValidation1 ? [] : [{ type: 'number', min: (props.editAllInfo || props.create) ? 1 : undefined, max: (props.editAllInfo || props.create) ? 31 : undefined, message: 'must be between 1 and 31' }, { required: props.companyData.collectionDayExact || props.create, message: 'Required' }]}>
                            <InputNumber placeholder='Please collection exact day' disabled={!props.editAllInfo}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.collectionDayExact)) {
                                        setInputValidation1(true)
                                    } else {
                                        setInputValidation1(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={!props.create ? 6 : 10}>

                        <label htmlFor="">Cutoff Date <span>*</span></label>
                        <Form.Item name='salaryPreparationDay' rules={!inputValidation2 ? [] : [{ type: 'number', min: 1, max: 28, message: 'must be between 1 and 28' },
                        { required: props.companyData.salaryPreparationDay || props.create, message: 'Required' }]}>
                            <InputNumber placeholder='Please enter cutoff date'
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.salaryPreparationDay)) {
                                        setInputValidation2(true)
                                    } else {
                                        setInputValidation2(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {!props.create && <Col span={6}>

                        <label htmlFor="">Cutoff Day (Reference)</label>
                        <Form.Item name='cuttOffDayRef' rules={!cuttOffDayRefValidation ? [] : [{ type: 'number', min: 1, max: 28, message: 'must be between 1 and 28' }]}>
                            <InputNumber placeholder='Enter reference to cutoff date' disabled={!props.editAllInfo}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props?.companyData?.cuttOffDayRef)) {
                                        setCuttOffDayRefValidation(true)
                                    } else {
                                        setCuttOffDayRefValidation(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>}
                    <Col span={!props.create ? 6 : 4}>

                        <label htmlFor="">Collection Month Shift</label>
                        <Form.Item name="collectionMonthShift" valuePropName="checked">
                            <Switch defaultChecked={props.companyData.collectionMonthShift ? true : false} disabled={!props.editAllInfo} />
                        </Form.Item>
                    </Col>

                    <Divider orientation="left">Requests Configuration</Divider>
                    <Col span={12}>

                        <label htmlFor="">Installments count <span>*</span></label>
                        <Form.Item name='maxInstallmentCount' rules={!inputValidation3 ? [] : [{ required: props.companyData.maxInstallmentCount || props.create, message: 'Required' }, { type: 'number', min: (props.editAllInfo || props.create) ? 1 : undefined, max: (props.editAllInfo || props.create) ? 24 : undefined, message: 'must be between 1 and 24' }]}>
                            <InputNumber placeholder='Please enter the Installments count' disabled={!props.editAllInfo}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.maxInstallmentCount)) {
                                        setInputValidation3(true)
                                    } else {
                                        setInputValidation3(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">Monthly Deduction Cap</label>
                        <Form.Item name='maxMonthlyPercentAllowed' rules={!inputValidation4 ? [] : [{ type: 'number', min: (props.editAllInfo || props.create) ? 1 : undefined, max: (props.editAllInfo || props.create) ? 100 : undefined, message: 'must be between 1 and 100' }]}>
                            <InputNumber placeholder='Please enter the max percent monthly' disabled={!props.editAllInfo}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.maxMonthlyPercentAllowed)) {
                                        setInputValidation4(true)
                                    } else {
                                        setInputValidation4(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <label htmlFor="">Cash Enabled</label>
                        <Form.Item name="cashEnabled" valuePropName="checked">
                            <Switch defaultChecked={props.companyData.cashEnabled ? true : false} disabled={!props.editAllInfo} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>

                        <label htmlFor="">Free Period Applied on Deductions</label>
                        <Form.Item name={props.create ? "freeDeductionFees" : "hasDeductionBasedFreePeriods"} valuePropName="checked">
                            <Switch defaultChecked={props.companyData?.hasDeductionBasedFreePeriods ? 
                                props.companyData?.hasDeductionBasedFreePeriods : true} disabled={!props.editAllInfo} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>

                        <label htmlFor="">Request Required Active User</label>
                        <Form.Item name="requestRequiresActiveUser" valuePropName="checked">
                            <Switch defaultChecked={props.companyData.requestRequiresActiveUser ? true : false} disabled={!props.editAllInfo} />
                        </Form.Item>
                    </Col>

                    <Divider orientation="left">Free Months</Divider>
                    <Col span={12} className='groupCol'>
                        <label htmlFor="">Company Free Months &nbsp;
                            <Tooltip title="Starts from the company’s deployment date">
                                <InfoCircleOutlined />
                            </Tooltip> &nbsp;
                            <span>*</span></label>
                        <Form.Item
                            name={'companyFreeMonths'}
                            rules={!inputValidation5 ? [] : [{ required: props.companyData.companyFreeMonths || props.create, message: 'Required' }, { type: 'number', min: (props.editAllInfo || props.create) ? 0 : undefined, max: (props.editAllInfo || props.create) ? 60 : undefined, message: 'must be between 0 and 60' }]}
                        >
                            <InputNumber placeholder='Enter The Free Months' disabled={!props.editAllInfo}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.companyFreeMonths)) {
                                        setInputValidation5(true)
                                    } else {
                                        setInputValidation5(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label htmlFor="">Company Users Free Months &nbsp;
                            <Tooltip title="Starts from each user’s first request date">
                                <InfoCircleOutlined />
                            </Tooltip> &nbsp;
                            <span>*</span></label>
                        <Form.Item name='userFreePaymentCycles' rules={!inputValidation6 ? [] : [{ required: props.companyData.userFreePaymentCycles || props.create, message: 'Required' }, { type: 'number', min: (props.editAllInfo || props.create) ? 0 : undefined, max: (props.editAllInfo || props.create) ? 999 : undefined, message: 'must be between 1 and 999' }]}>
                            <InputNumber placeholder='Please enter free payment cycles' disabled={!props.editAllInfo}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.userFreePaymentCycles)) {
                                        setInputValidation6(true)
                                    } else {
                                        setInputValidation6(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <label>Deployment Date</label>
                        <Form.Item name='deploymentDate'>
                            <DatePicker disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                       <label>Company Free Period Expiry Date</label>
                        <Form.Item name='freePeriodExpiryDate'>
                            <DatePicker disabled={true} />
                        </Form.Item>
                    </Col>

                    <Divider orientation="left">Company departments</Divider>
                    <Col span={24}>
                        <Form.Item name='departments' 
                        rules={!inputValidation7 ? [] : 
                        [{ required: props.companyData.departments || props.create, message: 'Required' }]}>
                            <Select style={{ width: '100%' }}
                                placeholder={'Please select departments'}
                                showSearch
                                allowClear
                                mode={"multiple"}
                                filterOption={((input, option: any) => dropDownSearch(input, option))}
                                loading={loadingDepartments}
                                onChange={(value) => {
                                    if (props.create) {
                                        return
                                    }
                                    if (Number(value) !== Number(props.companyData.departments)) {
                                        setInputValidation7(true)
                                    } else {
                                        setInputValidation7(false)
                                    }
                                }}
                            >
                                {Array.isArray(departments) && departments.map((department: any, key: number) => {
                                    return <Option value={department.id} key={key}>{department.nameEn}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>


                <Divider orientation="left">Communication Cycle Days</Divider>
                <Row>
                    <Col span={5}>

                        <label htmlFor="">Sunday</label>
                        <Form.Item name="sunday" valuePropName="checked">
                            <Switch defaultChecked={Array.isArray(props.companyData.communicationCycles) && props.companyData.communicationCycles.length > 0 && props.companyData.communicationCycles[0]?.sunday ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>

                        <label htmlFor="">Monday</label>
                        <Form.Item name="monday" valuePropName="checked">
                            <Switch defaultChecked={Array.isArray(props.companyData.communicationCycles) && props.companyData.communicationCycles.length > 0 && props.companyData.communicationCycles[0]?.monday ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>

                        <label htmlFor="">Tuesday</label>
                        <Form.Item name="tuesday" valuePropName="checked">
                            <Switch defaultChecked={Array.isArray(props.companyData.communicationCycles) && props.companyData.communicationCycles.length > 0 && props.companyData.communicationCycles[0]?.tuesday ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>

                        <label htmlFor="">Wednesday</label>
                        <Form.Item name="wednesday" valuePropName="checked">
                            <Switch defaultChecked={Array.isArray(props.companyData.communicationCycles) && props.companyData.communicationCycles.length > 0 && props.companyData.communicationCycles[0]?.wednesday ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>

                        <label htmlFor="">Thursday</label>
                        <Form.Item name="thursday" valuePropName="checked">
                            <Switch defaultChecked={Array.isArray(props.companyData.communicationCycles) && props.companyData.communicationCycles.length > 0 && props.companyData.communicationCycles[0]?.thursday ? true : false} />
                        </Form.Item>
                    </Col>
                </Row>

                <Col span={24}>
                    <Form.Item wrapperCol={{ offset: 0, span: 0 }} style={{ margin: '10px 0' }}>
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
        </div>
    )
}