import { Button, Col, Form, FormInstance, Input, message, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { userInfo } from "../../utils/commonInterfaces";
import helper from "../../utils/helper";
import DataTable from "../../components/dataTable";
import { request, stepperMoving } from "../../stores/actions";
import { deployedCompanies, getPaymentCyclesApi, getUserInstallmentsApi, updateUserCompanyApi } from "../../utils/apisConsts"
import { errorMessage } from "../../utils/commonStringsConsts";
import OutPutResult from "../../components/result";
import moment from "moment";
const { Option } = Select;

interface State {
    [key: string]: {
        info?: userInfo,
    }
}

export default function UpdateUserCompanyForm() {
    const userInfo: any = useSelector<State>(state => state.actionsReducer.info);
    const [changingCompany, setChangingCompany] = useState(false)
    const [companies, setCompanies] = useState<any[]>([])
    const [paymentCycles, setPaymentCycles] = useState<any[]>([])
    const [installments, setInstallment] = useState<any[]>([])
    const [columns, setColumns] = useState<any[]>([])
    const [loadingTableData, setLoadingTableData] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        total: 0,
    })
    const dispatch = useDispatch()
    const formRef = React.createRef<FormInstance>();

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompanies = async () => {
        try {
            setLoadingData(true)
            const companiesRes: { [key: string]: any } = await dispatch(request({ url: deployedCompanies, method: "GET" }))
            if (companiesRes.data.status === 200) {
                const isThereNowPay = Array.isArray(companiesRes.data.data) ? companiesRes.data.data.some((company:any)=> company.id === 13) : false
                const isNonCorporate = Array.isArray(companiesRes.data.data) ? companiesRes.data.data.some((company:any)=> company.id === 100) : false
                if (Array.isArray(companiesRes.data.data)) {
                    !isThereNowPay && companiesRes.data.data.push({
                        name:"NowPay",
                        id: 13
                    })
                    !isNonCorporate && companiesRes.data.data.push({
                        name:"Non-Corporate",
                        id: 100
                    })
                }
                const deployedCompanies = Array.isArray(companiesRes.data.data) ? companiesRes.data.data.filter((company:any)=> company.id !== userInfo.companyID) : []
                setCompanies(deployedCompanies)
                await getPaymentCycles()
            } else {
                message.error(companiesRes.data.message)
                setErrorMode(true)
            }
            setLoadingData(false)
        }
        catch (error: any) {
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
            setLoadingData(false)
            setErrorMode(true)
        }
    }
    const getPaymentCycles = async () => {
        const params = {
            companyID: userInfo.companyID,
            mostRecent: true
        }
        try {
            const paymentCyclesRes: { [key: string]: any } = await dispatch(request({ url: getPaymentCyclesApi, method: "GET", params: params }))
            if (paymentCyclesRes.data.status === 200) {
                const todayDate = new Date()
                const companyDate = new Date()
                companyDate.setDate(userInfo.actualCutOffDate)
                if (companyDate <= todayDate) {
                    companyDate.setMonth(companyDate.getMonth() + 1)
                }
                const currentCycle = {
                    cutOffCycleDate: companyDate,
                    paymentCycle: paymentCyclesRes.data.allPaymentCycles ? Number(paymentCyclesRes.data.allPaymentCycles.paymentCycle) + 1 : 1
                }
                setPaymentCycles(paymentCyclesRes.data.allPaymentCycles ? [paymentCyclesRes.data.allPaymentCycles, currentCycle] : [currentCycle])
            } else {
                message.error(paymentCyclesRes.data.message)
                setErrorMode(true)
            }
        }
        catch (error: any) {
            setErrorMode(true)
            setLoadingData(false)
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }
    const getInstallments = async (paymentCycle: number) => {
        try {
            setLoadingTableData(true)
            const installmentsRes: { [key: string]: any } = await dispatch(request({ url: getUserInstallmentsApi, method: "GET", params: { userID: userInfo.userID, paymentCycle } }))
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 87 ~ getInstallments ~ installmentsRes", installmentsRes)
            if (installmentsRes.data.status === 200) {
                const installmentsData = Array.isArray(installmentsRes.data.installments) ?
                    installmentsRes.data.installments.map((installment: any) => ({
                        "User ID": installment["Advance.userID"],
                        "Advance ID": installment.advanceID,
                        "Service Type": installment["advanceID.serviceType"],
                        "Installment ID": installment.installmentID,
                        "Instalment Order": installment.instalmentOrder,
                        "Payment Month": moment(installment.actualPaymentDate).format('Do MMM, YYYY'),
                        "Payments Amount (EGP)": installment.paymentsAmount,
                        "Processing Fees (EGP)": installment.processingFees,
                        "Discount (EGP)": installment.advanceID,
                        "Revenue (EGP)": installment.revenueAfterDiscount
                    })) : []
                handleTableChange({
                    current: 1,
                    total: installmentsRes.data.installmentsCount,
                    pageSize: 5
                })
                setInstallment(installmentsData)
                setColumns(helper.generateTableColumns(installmentsData))
            } else if (installmentsRes.data.status === 404) {
                message.success(installmentsRes.data.message)
                setInstallment([])
            }
            else {
                message.error(installmentsRes.data.message)
                setInstallment([])
            }
            setLoadingTableData(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setLoadingTableData(false)
            setInstallment([])
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }
    const changeUserCompany = async (values: any) => {
        try {
            setChangingCompany(true)
            const changingRes: { [key: string]: any } = await dispatch(request({
                url: updateUserCompanyApi, method: "PATCH",
                data: {
                    userID: userInfo.userID,
                    newCompany: values.newCompany,
                    ...(values.comment && {comment: values.comment}),
                    ...(installments?.length > 0 && {startingCycle: values.paymentCycle})
                }
            }))
            if (changingRes.data.status === 200) {
                message.success(changingRes.data.message)
                dispatch(stepperMoving(0))
            } 
            else if (changingRes.data.status === 422) {
                message.error(`${changingRes.data.message}, Reason: ${changingRes.data.reason}`)
            } 
            else {
                message.error(changingRes.data.message)
            }
            setChangingCompany(false)
        }
        catch (error: any) {
            message.error(errorMessage)
            setChangingCompany(false)
            console.log("🚀 ~ file: updateUserCompanyForm.tsx ~ line 41 ~ getCompanies ~ error", error)
        }
    }
    const handleTableChange = (e: any) => {
        console.log(e);
        setLoadingTableData(true)
        setTimeout(() => {
            setPagination(e)
            setLoadingTableData(false)
        }, 500);
    }
    const dropDownSearch = (input: string, option: { children: string; }) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return (
        errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage ? errorMessage : 'Something went wrong, please refresh the page and try again'}
            btnTitle={'Refresh the page'} btnClick={helper.refreshPage} /> :
            <div className="updateUserCompany">
                <Spin size="large" spinning={loadingData}>
                    <Form name="nest-messages" onFinish={changeUserCompany} ref={formRef}>
                        <Row className="inputsRow">
                            <Col span='11'>
                                <label>User Company</label>
                                <Form.Item
                                    name="oldCompany"
                                    initialValue={userInfo.companyName}
                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span='2' className="arrowCol">
                                <ArrowRightOutlined />
                            </Col>
                            <Col span='11'>
                                <label>New Company <span>*</span></label>
                                <Form.Item
                                    name="newCompany"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select a company" showSearch allowClear
                                        filterOption={((input, option: any) => dropDownSearch(input, option))}
                                    >
                                        {Array.isArray(companies) &&
                                            companies.map((company: { id: number, name: string }) => <Option key={company.id} value={company.id} company={company}>{company.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="inputsRow">
                            <Col span='11'>
                                <label>Move from (Invoice Month) <span>*</span></label>
                                <Form.Item
                                    name="paymentCycle"
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Select placeholder="Select a month" onChange={getInstallments}>
                                        {Array.isArray(paymentCycles) &&
                                            paymentCycles.map((paymentCycle: { id: number, paymentCycle: number, cutOffCycleDate: string }) => <Option key={paymentCycle.id} value={paymentCycle.paymentCycle}>{moment(paymentCycle.cutOffCycleDate).format('Do MMM, YYYY')}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span='2' ></Col>
                            <Col span='11'>
                                <label>Changing Company Comment </label>
                                <Form.Item
                                    name="comment"
                                >
                                    <Input.TextArea style={{height:"27px"}}/>
                                </Form.Item>
                            </Col>
                            <Col span='24' className="btnCol">
                                <Form.Item>
                                    <Button className='changeUserStatusBtn' shape="round" type="primary" block htmlType="submit" loading={changingCompany} >
                                        Change company
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                {(Array.isArray(installments) && installments.length > 0) ? <Row>
                    <Col>
                        <label className="tableLabel">Installments will be transferred to new company</label>
                    </Col>
                    <Col span='24' className="btnCol">
                        <DataTable columns={columns} pagination={pagination} rows={installments} handleTableChange={handleTableChange}
                            loading={loadingTableData} scrolling={{ x: 2000 }} />
                    </Col>
                </Row>
                    : loadingTableData && <Spin size="large" />
                }
            </div>
    )
}