import { Button, Descriptions, message } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../apis/axiosRequest'
import DataTable from '../../components/dataTable'
import { stepperMoving } from '../../stores/actions'
import { deleteAdjustmentApi } from '../../utils/apisConsts'
import { errorMessage } from '../../utils/commonStringsConsts'
import { adjustmentTableColumns } from '../../utils/tableColumns'

export default function DeleteAdjustmentForm() {
  const advance = useSelector((state: any) => state.actionsReducer.info)
  const [sendingData, setSendingData] = useState(false)
  const dispatch = useDispatch()

  const deleteAdvance = async () => {
    try {
      setSendingData(true)
      const deleteRes: { [key: string]: any } = await dispatch(request({
        url: deleteAdjustmentApi, method: "DELETE", data: { advanceID: advance.requestID }
      }))
      if (deleteRes.data.status === 200) {
        message.success(deleteRes.data.message)
        dispatch(stepperMoving(0))
      } else {
        message.error(`${deleteRes.data.message}, Reason: ${deleteRes.data.reason}`)
      }
      setSendingData(false)
    }
    catch (error: any) {
      console.log("🚀 ~ file: deleteAdjustmentForm.tsx ~ line 28 ~ deleteAdvance ~ error", error)
      message.error(errorMessage)
      setSendingData(false)
    }
  }

  return (
    <div style={{ padding: "30px 0" }}>
      <Descriptions
        title={`Request #${advance.requestID}`}
        bordered
        size={'small'}
        extra={<Button className='cancelBtn' type="default" shape='round' danger onClick={deleteAdvance} loading={sendingData}>Delete Advance</Button>}
      >
        <Descriptions.Item span={2} label="User ID">{advance.userID}</Descriptions.Item>
        <Descriptions.Item span={2} label="Request Type">{`${advance.requestType} (${Number(advance.installments[0].paymentAmount) > 0 ? "Overpaid" : "Underpaid"})`}</Descriptions.Item>
        <Descriptions.Item span={2} label="Amount">{advance.amount}</Descriptions.Item>
        <Descriptions.Item span={2} label="Number Of Months">{advance.numberOfMonths}</Descriptions.Item>
        <Descriptions.Item span={2} label="Created At">{moment(advance.createdAt).format("Do MMM, YYYY")}</Descriptions.Item>
      </Descriptions>

      {Array.isArray(advance.installments) && advance.installments.length > 0 &&
        <div style={{ marginTop: "25px" }}>
          <label style={{fontWeight:"bolder", fontSize:"16px"}}>Request Installments</label>
          <DataTable
            columns={adjustmentTableColumns}
            rows={advance.installments}
            pagination={{}}
            loading={false}
            scrolling={{}}
          />
        </div>}
    </div>
  )
}
