import React, { Component } from 'react'
import GetInfo from '../components/getInfo'
import Stepper from '../components/stepper'
import EndCall from './endCall'
import { getUserByPhoneApi } from '../utils/apisConsts'

export class StartingCall extends Component {
    state = {
        componentSteps: [
            {
                title: 'Start Call',
                content: <GetInfo apiLink={getUserByPhoneApi}/>,
                id: 0,
            }, {
                title: 'End Call',
                content: <EndCall />,
                id: 1
            },
        ]
    }

    render() {
        const { componentSteps } = this.state;
        return (
            <>
                <Stepper componentSteps={componentSteps} />
            </>
        )
    }
}

export default StartingCall