import { Modal } from 'antd';

interface Props {
    loadingConfirm?: boolean,
    visibleModal?: boolean,
    handleAcceptModal?: any,
    handleCloseModal?: any,
    okText?: string,
    cancelText?: string,
    title?: string,
    btnDisable?: boolean,
    content?: any,
    none?: any
}
export default function TruckingModal(props: Props) {

    const { loadingConfirm, visibleModal,
        title, handleCloseModal, handleAcceptModal, content } = props;
    return (
        <div className='modalTrucking'>
            <Modal
                confirmLoading={loadingConfirm}
                width={400}
                title={title}
                centered
                visible={visibleModal}
                onCancel={handleCloseModal}
                onOk={handleAcceptModal}
                footer={null}
            >
                {content}

            </Modal>
        </div>
    )



}
