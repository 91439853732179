import { Component } from 'react'
import { withRouter, RouteComponentProps } from "react-router-dom";
import Permission from '../utils/permission';
import Action from '../utils/operationsActions';
//Import from the components 
import CommanDrawer from '../components/drawer';
import MainLayoutGroups from '../components/layout/mainLayoutGroups';
//Import from the actions 
import ChangePhoneNumber from '../actionsGroups/changePhoneNumber';
import BulkUpdateUserInfo from '../actionsGroups/bulkUpdateUsersInfo/bulkUpdateUserInfo'
import CancelRequest from '../actionsGroups/cancelRequest';
import SmsNotification from '../actionsGroups/smsNotification';
import OfflineShoppingTransactions from '../actionsGroups/offlineShoppingTransactions';
import CreateFeesGroup from '../actionsGroups/createFeesGroup';
import CreateFeesModel from '../actionsGroups/createFeesModel';
import AssignCompany from '../actionsGroups/assignCompany';
import ViewFeesGroups from '../actionsGroups/viewFeesGroups';
import ViewFeesModels from '../actionsGroups/viewFeesModels';
import ViewFeesCompanies from '../actionsGroups/viewFeesCompanies';
import StartingCall from '../actionsGroups/startingCall';
import { connect } from 'react-redux';
import { stepperMoving, emptyDrawer, move, hrStepper } from '../stores/actions';
import ModalCompnent from '../components/modal';
import ViewUserInfo from '../actionsGroups/viewUserInfo/viewUserInfo';
import ViewRequestsList from '../actionsGroups/viewRequestsList';
import EditUserInfo from '../actionsGroups/editUserInfo/editUserInfo';
import ViewDeployCompany from '../actionsGroups/deployCompany/viewDeployCompany';
import ViewRequestInfo from '../actionsGroups/highAmountRequest/viewRequestInfo';
import ViewAssignUser from '../actionsGroups/assignUserToFeesModel/viewAssignUser';

import ZohoTest from '../actionsGroups/zohoTest';
import CreatePartner from '../actionsGroups/createPartner';
import ApproveUserDocs from '../actionsGroups/approveUserDocuments/viewApprovingDocs';
import CreatePartnerBranches from '../actionsGroups/createPartnerBranches/createPartnerBranches';
import CreateCompanyGroup from '../actionsGroups/createCompanyGroup';
import CreateWelcomeEmail from '../actionsGroups/createWelcomeEmail/createWelcomeEmail';
import CompaniesGroup from '../actionsGroups/viewCompanyGroups/companyGroups';
import {
    assignFeesCompaniesApi, getFeesCompaniesApi, unDeployedCompanies,
    assignNonDeployedCompanyToFeesModel, updateBulkApprovedUsersAdvanced,
    addCatagoriesApi, addBrandsApi, createPartnersTypesApi, addRequestsCommentsApi, addUsersCommentsApi, transfersAnalyticsApi, getAllTransfersApi, uploadApprovedUserDocsApi, uploadUserDocsApi
} from '../utils/apisConsts';
import ViewDeductions from '../actionsGroups/deductions/viewDeductions';
import ApproveInvoice from '../actionsGroups/approveInvoice/approveInvoice';

import ExpensesParent from '../actionsGroups/Trucking/add-expenses/index';
import ViewExpenses from '../actionsGroups/Trucking/expenses-requests/viewExpenses';
// import ExpenseRequest from '../actionsGroups/Trucking/expenseRequest';

import ViewCompanyDetails from '../actionsGroups/viewCompanyDetails';
import VerifyExpenses from '../actionsGroups/Trucking/verify-expenses/index';
// import PayrollRequest from '../actionsGroups/Trucking/payroll-request/index';
import helper from '../utils/helper';
import { ConfigProvider } from 'antd';
import SendUserOtp from '../actionsGroups/sendUserOtp';
import ViewUserStatus from '../actionsGroups/userStatus/viewUserStatus';
import ViewUserSalary from '../actionsGroups/ChangeUserSalary/viewUserSalary';
import ViewUpdateBankInfo from '../actionsGroups/updateBankInfo/viewUpdateBankInfo';
import ViewEditApprovedUserSInfo from '../actionsGroups/editApprovedUsersInfo/viewEditApprovedUsersInfo';
import BulkUpdateUserStatus from '../actionsGroups/bulkUpdateUserStatus';
import ViewUserSuspending from '../actionsGroups/userSuspending/viewUserSuspending';
import ViewResetPassword from '../actionsGroups/resetUserPass/viewResetUserPass';
import UploadUserDocs from '../actionsGroups/uploadUserDocs/uploadUserDocs';
import UserUnSuspending from '../actionsGroups/userUnSuspending/userUnSuspending';
import UpdateGlobalCaps from '../actionsGroups/updateGlobalCaps/updateGlobalCaps';
import ViewUpdateCompanyCaps from '../actionsGroups/updateCompanyCaps/viewUpdateCompanyCaps';
import UpdateUserCaps from '../actionsGroups/updateUserCaps/viewUpdateUserCaps';
import Salary from '../actionsGroups/Trucking/salary/index';
import GetUserOtp from '../actionsGroups/sendUserOtp';
import SuspendCompanyServices from '../actionsGroups/suspendCompanyServices/suspendCompanyServices';
import SuspendGlobalServices from '../actionsGroups/suspendGlobalServices/suspendGlobalServices';
import Users from '../actionsGroups/HR/users';

import BulkCreateUserInfo from '../actionsGroups/bulkCreateUserInfo';
import AssignUserRoles from '../actionsGroups/assignUserRole/assignUserRole';
import UnAssignUserRoles from '../actionsGroups/unAssignUserRole/unAssignUserRole';
import UpdateUserCompany from '../actionsGroups/updateUserCompany/updateUserCompany';
import BulkUpdateApprovedUsersInfo from '../actionsGroups/bulkUpdateApprovedUsersInfo/bulkUpdateApprovedUsersInfo';
import BulkUpdateUsersHrInfo from '../actionsGroups/HR/bulkUsers/bulkUpdateUserHrInfo'
import EarlyPayment from '../actionsGroups/createEarlyPayment/earlyPayment';
import RevokeRequest from '../actionsGroups/revokeRequest/revokeRequest';
import BulkCreateMeezaRequests from '../actionsGroups/bulkCreateMeezaRequests';
import BulkUploadCollections from '../actionsGroups/bulkUploadCollection';
import BulkCreateEarlyPayment from '../actionsGroups/bulkCreateEarlyPayment';
import CreateAdjustment from '../actionsGroups/createAdjustment/createAdjustment';
import DeleteAdjustment from '../actionsGroups/deleteAdjustment/deleteAdjustment';
import EditCompany from '../actionsGroups/editCompany/editCompany';
import CreateCompanyNew from '../actionsGroups/createCompanyNew';
import { bulkUpdateApprovedUserAllInfoTemplate, createRequestsCommentsTemplate, createUsersCommentsTemplate } from '../utils/bulkSheetsTemplates';
import CreateLost from '../actionsGroups/createLostCase/createLost';
import LostCollection from '../actionsGroups/lostCollection/lostCollection';
import DeleteLost from '../actionsGroups/deleteLost/deleteLost';
import BestSellersProds from '../actionsGroups/onlineShopping/products/bestSellersProds';
import PopularPartner from '../actionsGroups/offlineShopping/popularPartner/popularPartner';
import CategoriesAndBrands from '../actionsGroups/categoriesAndBrands';
import BulkCreateProducts from '../actionsGroups/bulkCreateProducts/bulkCreateProducts';
import DeleteDebitAndCredit from '../actionsGroups/deleteDebitAndCredit/deleteDebitAndCredit';
import BulkUpdateProducts from '../actionsGroups/bulkUpdateProducts/bulkUpdateProducts';
import OnlineTransactions from '../actionsGroups/onlineTransactions';
import UpdateCompanyBalance from '../actionsGroups/Trucking/update-company-balance';
import DriversList from '../actionsGroups/Trucking/driversList/driverListAccount';
import CreateTruckingUsers from '../actionsGroups/Trucking/createTruckingUsers/createUsers';
import Schedule from '../actionsGroups/analytics/schedule';
import Demand from '../actionsGroups/analytics/demand';
import DeleteSchedule from '../actionsGroups/analytics/deleteSchedule';
import TerminateAnalytics from '../actionsGroups/analytics/terminateAnalytics';
import ResolveIssues from '../actionsGroups/resolveIssues/resolveIssues';
import ResolveIssuesByDate from '../actionsGroups/resolveIssues/resolveIssuesByDate';
import BulkCreateClearance from '../actionsGroups/bulkCreateClearance';
import RefundInActiveRequest from '../actionsGroups/refundInActiveRequest/refundInActiveRequests';
import ApproveNewInvoice from '../actionsGroups/approveInvoiceV2/approve';
import ClearRefund from '../actionsGroups/clearRefund';
import RefundActiveRequest from '../actionsGroups/refundActiveRequest/refundActiveRequest';
import BulkRecalculateRequest from '../actionsGroups/bulkRecalculateRequests/bulkRecalculateRequests';
import DriverListOps from '../actionsGroups/Trucking/driversList/driverListOps';
import BulkUpdateRequestsStatus from '../actionsGroups/bulkUpdateRequestsStatus';
import CreateRequest from '../actionsGroups/requests/createRequests/create';
import UpdateRequest from '../actionsGroups/requests/updateRequests/update';
import BulkCreateComments from '../actionsGroups/bulkCreateComments';
import CreateDepartments from '../actionsGroups/createDepartments';
import BulkCallInfo from '../actionsGroups/bulkCallInfo';
import AddVirtualDeposit from '../actionsGroups/Trucking/addVirtualDeposit/addVirtualDeposit';
import Result from '../components/result';
import CreateTransfers from '../actionsGroups/Trucking/createTransfers/create';
import CreateUserComment from '../actionsGroups/createUserComments/createUserComment';
import GetBlockingIssues from '../actionsGroups/requests/requestBlockingIssues/getIssues';
import { SendEmails } from '../actionsGroups/Trucking/sendEmails/sendEmails';
import CreateExceptLost from '../actionsGroups/createLostCase/createExecpLost';
import CreateUser from '../actionsGroups/createSingleUser/createUser';
// import TestChatGpt from '../actionsGroups/testChatGpt';
import CreateExceptionAdjustment from '../actionsGroups/createAdjustment/createExceptionAdjustment';
import ViewRequestFees from '../actionsGroups/viewRequestFees/viewRequestFees';
import AmanBalance from '../actionsGroups/aman/amanBalance';


let intFrameWidth: number = window.innerWidth;
interface DataProps extends RouteComponentProps<any> {
    stepperMoving: any,
    move: any,
    emptyDrawer: any,
    hrStepper: any,
    stepNumber: any
}
class Categories extends Component<DataProps> {
    state = {
        loading: false,
        drawerLayout: null,
        drawerTitle: '',
        visibleDrawer: false,
        drawerWidths: null,
        hiddenScroll: false,
        dataForm: {},
        visibleChildDrawer: false,
        visibleModal: false,
        inputChange: false,
    }
    componentDidMount() {
        this.openDrawerByDynamicId();
    }
    componentDidUpdate(prevProps: any, prevState: any): void {
        if (!this.state.inputChange && prevState.inputChange === this.state.inputChange && this.getInputs(true).length > 2) {
            this.setState({ inputChange: true })
        }
    }


    // checkTypeOfResponsive = () => {
    //     let intFrameWidth: number = window.innerWidth;
    //     if (intFrameWidth <= 600) {
    //     } else {
    //     }
    //   }
    activeAction = (actionName: string) => {
        switch (actionName) {
            case 'changePhoneNumber':
                return <ChangePhoneNumber />
            case 'updateBulkUsers':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkUpdateUserInfo />
            case 'createBulkUsers':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkCreateUserInfo />
            case 'createRequest':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <CreateRequest noLimits={false} />
            case 'createExceptionRequest':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <CreateRequest noLimits={true} />
            case 'cancelRequest':
                this.setState({ drawerWidths: 1000 })
                return <CancelRequest />
            case 'smsNotification':
                return <SmsNotification />
            case 'pushNotification':
                return <h1>Push Notification</h1>
            case 'voiceNotification':
                return <h1>Voice Message</h1>
            case 'createDeduction':
                return <h1>Create Deduction</h1>
            case 'deleteDeduction':
                return <h1>Delete Deduction</h1>
            case 'createCompany':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <CreateCompanyNew />
            case 'cancelMerchantRequest':
                if (intFrameWidth <= 500) {
                    this.setState({ drawerWidths: 370, hiddenScroll: false })
                } else {

                    this.setState({ drawerWidths: intFrameWidth - 160, hiddenScroll: false })
                }
                return <OfflineShoppingTransactions />

            case 'createServicesGroup':
                this.setState({ drawerWidths: 820 })
                return <CreateFeesGroup />
            case 'createFeesModel':
                this.setState({ drawerWidths: 820, hiddenScroll: false })
                return <CreateFeesModel />
            case 'assignCompanyToFeesModel':
                this.setState({ drawerWidths: 820, hiddenScroll: false })
                return (<AssignCompany getFeesCompaniesApi={getFeesCompaniesApi} assignFeesCompaniesApi={assignFeesCompaniesApi} />)
            case 'getServiceGroup':
                this.setState({ drawerWidths: 820, hiddenScroll: false })
                return <ViewFeesGroups />
            case 'getModelsFeesDetails':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <ViewFeesModels />
            case 'getCompaniesFees':
                this.setState({ drawerWidths: 820, hiddenScroll: false })
                return <ViewFeesCompanies />

            case 'addCallInfo':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <StartingCall />
            case 'getUserInfo':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewUserInfo />
            case 'getAllRequests':
                if (intFrameWidth <= 500) {
                    this.setState({ drawerWidths: 370, hiddenScroll: false })
                } else {

                    this.setState({ drawerWidths: intFrameWidth - 160, hiddenScroll: false })
                }
                return <ViewRequestsList />
            case 'updateUserInfo':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <EditUserInfo />
            case 'changeDeploymentStatus':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewDeployCompany />
            case 'getOneCompany':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewCompanyDetails />
            case 'approveHighAmount':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewRequestInfo />
            case 'testZohoTicket':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ZohoTest />
            case 'assignUserToFeesModel':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewAssignUser />
            case 'createPartner':
                return <CreatePartner />
            case 'approveUserDocs':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ApproveUserDocs />
            case 'createPartnerBranches':
                this.setState({ drawerWidths: 1300, hiddenScroll: false })
                return <CreatePartnerBranches />
            case 'createCompanyGroup':
                this.setState({ hiddenScroll: false })
                return <CreateCompanyGroup />
            case 'createWelcomeEmail':
                return <CreateWelcomeEmail />
            case 'getCompaniesGroups':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <CompaniesGroup />
            case 'assignNonDeployedCompanyToFeesModel':
                return <AssignCompany getFeesCompaniesApi={unDeployedCompanies} assignFeesCompaniesApi={assignNonDeployedCompanyToFeesModel} />
            case 'getDeductions':
                this.setState({ drawerWidths: 1280, hiddenScroll: false })
                return <ViewDeductions generateInvoice={false} />
            case 'generateInvoice':
                this.setState({ drawerWidths: 1280, hiddenScroll: false })
                return <ViewDeductions generateInvoice={true} />
            case 'lockPaymentCycle':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ApproveInvoice lock={true} />
            case 'viewDrivers':
                return <ExpensesParent />
            case 'addVerifiedTransactions':
                return <VerifyExpenses />
            case 'updateTruckingStatus':
                if (intFrameWidth <= 500) {
                    this.setState({ drawerWidths: 370, hiddenScroll: false })
                } else {

                    this.setState({ drawerWidths: intFrameWidth - 200, hiddenScroll: false })
                }
                return <ViewExpenses />

            case 'payroll':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <Salary />
            case 'getUserLastOtp':
                return <GetUserOtp />
            case 'sendOtpViaTwilio':
                return <SendUserOtp />
            case 'updateStatus':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewUserStatus />
            case 'updateUserSalary':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <ViewUserSalary />
            case 'updateBankInfo':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <ViewUpdateBankInfo />
            case 'updateApprovedUserInfo':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewEditApprovedUserSInfo />
            case 'updateBulkStatus':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkUpdateUserStatus />
            case 'suspendUser':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <ViewUserSuspending />
            case 'unSuspendUser':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <UserUnSuspending />
            case 'updateForceResetPassword':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <ViewResetPassword />
            case 'uploadUserDocs':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <UploadUserDocs apiLink={uploadUserDocsApi} />
            case 'uploadApprovedUserDocs':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <UploadUserDocs apiLink={uploadApprovedUserDocsApi} />
            case 'updateGlobalServicesCaps':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <UpdateGlobalCaps />
            case 'updateCompanyCap':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ViewUpdateCompanyCaps />
            case 'updateUserCap':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <UpdateUserCaps />
            case 'updateGlobalServiceSuspension':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <SuspendGlobalServices />
            case 'updateCompanySuspension':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <SuspendCompanyServices />
            case 'hrGetUsers':
                if (intFrameWidth <= 500) {
                    this.setState({ drawerWidths: 370, hiddenScroll: false })
                } else {

                    this.setState({ drawerWidths: intFrameWidth - 200, hiddenScroll: false })
                }
                return <Users />
            case 'assignUserToRole':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <AssignUserRoles />
            case 'unAssignUserFromRole':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <UnAssignUserRoles />
            case 'updateUserCompany':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <UpdateUserCompany />
            case 'updateBulkApprovedUsers':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkUpdateApprovedUsersInfo />
            case 'hrBulkUpsertUsers':
                this.setState({ drawerWidths: 900, hiddenScroll: false })
                return <BulkUpdateUsersHrInfo />
            case 'externalPayment':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <EarlyPayment />
            case 'revokeRequest':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <RevokeRequest />
            case 'createBulkMeezaCards':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkCreateMeezaRequests />
            case 'clearanceTransactions':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkCreateClearance />
            case 'addCollections':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkUploadCollections />
            case 'bulkExternalPayment':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkCreateEarlyPayment />
            case 'createAdjustment':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <CreateAdjustment />
            case 'deleteAdjustment':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <DeleteAdjustment />
            case 'updateCompanyInfo':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <EditCompany editAllInfo={false} />
            case 'updateCompanyInfoAdvance':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <EditCompany editAllInfo={true} />
            case 'bestSellerToggle':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })

                return <BestSellersProds />

            case 'partnerPopularToggle':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <PopularPartner />

            case 'updateBulkApprovedUsersAdvanced':
                this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <BulkUpdateApprovedUsersInfo template={bulkUpdateApprovedUserAllInfoTemplate} api={updateBulkApprovedUsersAdvanced} />
            case 'createLostRequest':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <CreateLost />
            case 'createExceptionLost':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <CreateExceptLost />
            case 'createLostCollection':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <LostCollection />
            case 'deleteLostRequest':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <DeleteLost />
            case 'deleteDebitCredit':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <DeleteDebitAndCredit />
            case 'unlockPaymentCycle':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <ApproveInvoice lock={false} />
            // return <BulkUpdateApprovedUsersInfo template={bulkUpdateApprovedUserAllInfoTemplate} api={updateBulkApprovedUsersAdvanced} />

            case 'categories':
                // this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <CategoriesAndBrands name={'Categories'} isCategories={true} apiLink={addCatagoriesApi} />

            case 'brands':
                // this.setState({ drawerWidths: 1282, hiddenScroll: false })
                return <CategoriesAndBrands name={'Brands'} isCategories={false} apiLink={addBrandsApi} />


            case 'bulkCreateProducts':
                // this.setState({ drawerWidths: 1282, hiddenScroll: false })
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <BulkCreateProducts />
            case 'bulkUpdateProducts':
                // this.setState({ drawerWidths: 1282, hiddenScroll: false })
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <BulkUpdateProducts />

            case 'onlineShoppingRequests':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <OnlineTransactions />
            case 'createPartnerType':
                return <CategoriesAndBrands name={'Partner Type'} isCategories={true} apiLink={createPartnersTypesApi} isPartnerTypes={true} />
            case 'addCompanyDeposit':
                return <UpdateCompanyBalance />

            case 'viewDriversList':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <DriversList />

            case 'createTruckingUser':
                this.setState({ drawerWidths: 900, hiddenScroll: false })
                return <CreateTruckingUsers />

            case 'createRefundRequest':
                this.setState({ drawerWidths: 1150, hiddenScroll: false })
                return <RefundInActiveRequest />
            case 'callAnalyticsApi':
                return <Demand />

            case 'scheduleAnalyticsApi':
                return <Schedule />

            case 'deleteScheduledAnalyticsApi':
                return <DeleteSchedule />

            case 'terminateAnalyticsApi':
                return <TerminateAnalytics />
            case 'resolveAdvanceIssuesManually':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <ResolveIssues />

            case 'resolveAdvancesIssuesAutomatically':
                return <ResolveIssuesByDate />

            case 'newDeductions':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <ApproveNewInvoice lock />

            case 'clearAdvanceRefund':
                return <ClearRefund />

            case 'refundActiveRequest':
                this.setState({ drawerWidths: 1150, hiddenScroll: false })
                return <RefundActiveRequest />
            case 'viewTruckingUsers':
                this.setState({ drawerWidths: 1000, hiddenScroll: false })
                return <DriverListOps />
            case 'bulkChangeRequestsStatus':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <BulkUpdateRequestsStatus />

            case 'updateRequest':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <UpdateRequest noLimits={false} />
            case 'addRequestsComments':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <BulkCreateComments template={createRequestsCommentsTemplate} title="Request" api={addRequestsCommentsApi} />
            case 'addUsersComments':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <BulkCreateComments template={createUsersCommentsTemplate} title="User" api={addUsersCommentsApi} />
            // updateBlockIssues

            case 'recalculateRequests':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <BulkRecalculateRequest />

            case 'createDepartments':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <CreateDepartments />

            case 'importCallCenterReport':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <BulkCallInfo />

            case 'createVirtualDeposit':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <AddVirtualDeposit />

            case 'createTransfer':
                this.setState({ drawerWidths: 900, hiddenScroll: false })
                return <CreateTransfers />

            case 'changeTransferStatus':
                this.setState({ drawerWidths: 900, hiddenScroll: false })
                if (intFrameWidth <= 500) {
                    this.setState({ drawerWidths: 370, hiddenScroll: false })
                } else {

                    this.setState({ drawerWidths: intFrameWidth - 200, hiddenScroll: false })
                }
                return <ViewExpenses analyticsApi={transfersAnalyticsApi} getDataApi={getAllTransfersApi} />

            case 'userCreateSingleComment':
                this.setState({ drawerWidths: 1100, hiddenScroll: false })
                return <CreateUserComment />

            case 'getBlockingIssues':
                this.setState({ drawerWidths: 1200, hiddenScroll: false })
                return <GetBlockingIssues />

            case 'sendingEmails':
                this.setState({ hiddenScroll: false })
                return <SendEmails />
            //SendEmails
            case 'createUser':
                return <CreateUser />

            case 'getAmanBalance':
                return <AmanBalance />
            case 'createExceptionAdjustment':
                this.setState({ drawerWidths: 900, hiddenScroll: false })
                return <CreateExceptionAdjustment />
            case 'viewRequestFees':
                this.setState({ drawerWidths: 900, hiddenScroll: false })
                return <ViewRequestFees />
            default:
                break;
        }
    }

    openDrawerByDynamicId = () => {
        let availableActions: any = Permission.handleGettingPermission('permission');
        let actions = Action.actionsCreated(),
            action = 0,
            actionsIds = actions.map((actionId: { id: any, actionName: string }) => { return actionId.actionName });

        if (actionsIds.includes((this.props.match.params.actionName))) {
            for (action; action < actions.length; action++) {
                if (actions[action].actionName === (this.props.match.params.actionName) && availableActions.includes(this.props.match.params.actionName)) {
                    this.setState({
                        visibleDrawer: true,
                        drawerLayout: this.activeAction(actions[action].actionName),
                        drawerTitle: actions[action].name
                    });
                    return
                }
                this.setState({
                    visibleDrawer: true,
                    drawerLayout: <Result status="403" title="Permission Issue"
                        subTitle={`${helper.unCamelCase(this.props.match.params.actionName)} action is out of your permissions`} />,
                    drawerTitle: 'Out Of Permissions'
                });
            }
            return
        }


    }
    selectedAction = (actionData: any) => {
        this.props.emptyDrawer(true)
        this.props.history.push(`${this.props.location.pathname}/${actionData.actionName}`)
        this.setState({ visibleDrawer: true, drawerLayout: this.activeAction(actionData.actionName), drawerTitle: actionData.name })
    }

    getInputs = (onlyInputs?: boolean) => {
        const inputs: any = document.getElementsByTagName("input")
        const textarea: any = document.getElementsByTagName("textarea")
        const buttons: any = document.getElementsByTagName("button")
        if (onlyInputs) {
            return [...inputs, ...textarea]
        }
        return [...inputs, ...textarea, ...buttons]
    }

    openModal = () => {
        this.setState({ visibleModal: true })
    }

    cancelDrawer = () => {
        const formInputs: any[] = this.getInputs()
        for (let index = 0; index < formInputs.length; index++) {
            if ((formInputs[index] && formInputs[index].value) || (this.state.inputChange && this.props.stepNumber > 0)) {
                this.openModal()
                return;
            }
        }
        this.confirmationModal()
    }
    confirmationModal = () => {
        const forms = document.getElementsByTagName("form")
        for (let index = 0; index < forms.length; index++) {
            forms[index].reset();
        }
        this.setState({ visibleDrawer: false, visibleModal: false, drawerWidths: null })
        this.props.history.push(`/${Permission.handleGettingPath()}`);
        this.props.stepperMoving(0);
        this.props.move(0);
        this.props.hrStepper(0);
        this.props.emptyDrawer(false)
        helper.resetAccountTableInStepper();
    }

    handleCloseModal = () => {
        this.setState({ visibleModal: false })
    }

    render() {
        const { visibleModal } = this.state;
        return (
            <>
                <MainLayoutGroups groupActions={Action.actionsCreated()} selectedAction={this.selectedAction}
                    permission={Permission.handleGettingPermission('permission')} />
                <CommanDrawer drawerLayout={this.state.drawerLayout} drawerWidth={this.state.drawerWidths !== null ? this.state.drawerWidths : 820}
                    drawerTitle={this.state.drawerTitle} visible={this.state.visibleDrawer}
                    showDrawer={this.selectedAction} cancelDrawer={this.cancelDrawer} hiddenScroll={this.state.hiddenScroll} />
                {helper.isArabic(this.state.drawerTitle) ? <ConfigProvider direction='rtl'>

                    <ModalCompnent okText={'نعم'} cancelText={helper.isArabic(this.state.drawerTitle) ? 'لا' : 'No'} visibleModal={visibleModal} handleAcceptModal={this.confirmationModal}
                        content={<p style={{ fontSize: '17px', fontWeight: 'lighter' }}>انت ستغادر هذه الصفحه. هل أنت متأكد؟</p>} title='تحذير' handleCloseModal={this.handleCloseModal} />
                </ConfigProvider>
                    : <ModalCompnent okText={'Yes'} cancelText={'No'} visibleModal={visibleModal} handleAcceptModal={this.confirmationModal}
                        content={<p style={{ fontSize: '17px', fontWeight: 'lighter' }}>{`You have attempted to leave this page. Are you sure?`}</p>} title={'Warning Message'} handleCloseModal={this.handleCloseModal} />}
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    stepNumber: state.actionsReducer.stepNumber
})

export default withRouter(connect(mapStateToProps, { stepperMoving, move, hrStepper, emptyDrawer })(Categories))
