import { Form, Row, Col, Input, Button, Alert, message, InputNumber } from 'antd'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { request } from '../apis/axiosRequest'
import { clearRefundApi } from '../utils/apisConsts'
import { egyptianNumberMsg, errorMessage } from '../utils/commonStringsConsts'
import helper from '../utils/helper'

interface Props{
    submitFunction?: any
}

export default function ClearRefund({submitFunction}: Props) {
    const refForm: { current: any } = useRef();
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const getInvoicesParams = async (values: { phone: string, advanceID: string }) => {
        setLoad(true);
        try {
            const clear: any = await dispatch(request({ method: 'DELETE', params: values, url: clearRefundApi }));
            const res = await clear.data;
            if (res.status === 200) {
                console.log(res);
                setLoad(false);
                refForm.current!.resetFields();
                message.success(res.message)
                return
            }
            setLoad(false);
            message.error(res.message)

        } catch (error: any) {
            setLoad(false);
            if (error.response.status === 500) {
                message.error(error.response.data.message);
                return
            }
            message.error(errorMessage)

        }
    }

    return (
        <>
            <div className='deduct'>
                {<Form name="nest-messages" onFinish={submitFunction ? (values: any)=> submitFunction(setLoad, values) : getInvoicesParams} ref={refForm}>
                {!submitFunction && <Alert message="The refund can be cleared by phone number and requestID" type="info" />}

                    <Row>
                        <Col className='deductForm' span={8}>

                            {<>
                                <Form.Item name={'phone'} style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Required' },
                                    { pattern: helper.regexValidation.egyptPhoneNum, message: egyptianNumberMsg }]}>
                                    <Input placeholder={'Enter The Phone Number'} />
                                </Form.Item>
                            </>}
                            {<>
                                <Form.Item name={'advanceID'}
                                    rules={[{ required: true, message: 'Required' }]}>
                                    <InputNumber placeholder={'Enter The Request ID'} style={{ width: '100%' }} />
                                </Form.Item>
                            </>}


                            <Form.Item>
                                <Button className='btn' shape="round" type="primary" loading={load} block htmlType="submit">
                                    {submitFunction ? "Submit" : "Clear"}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>}
            </div>
        </>
    )
}
