import { Button, FormInstance, message } from 'antd';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AllUserInfo from '../../components/allUserInfo';
import ModalCompnent from '../../components/modal';
import { getApi, patchApi, stepperMoving } from '../../stores/actions';

interface Props {
    getApi?: any,
    info?: {
        name: string,
        companyName: string,
        phone: string,
        email: string,

        consent: [],
        nationalId: any,
        hrLetter: [],
        accountCreatedAt: string,
        bankAccountHolderName: string,
        bankAccountNumber: string,
        bankName: string,

        userStatus: string,
        userStatusType: string,

        maxAdvancePercentAllowedPerCompany: number,
        maxBalance: number,
        maxShoppingBalnce: number,

        wallet: number,
        staff_id: string,
        companyMaxInstallmentCount: number,
        nationalIDNumber: string,
        netSalary: any,
        userID: any,
    }
    patchApi?: any,
    stepperMoving?: any,
}
class ResetUserPass extends Component<Props> {
    state = {
        loadData: false,
        visibleModal: false,
        confirmLoading: false,
    }
    formRef = React.createRef<FormInstance>();


   
    confirmationModal = async () => {
        const data = {
            userID: this.props.info?.userID,
            forceResetPassword: 0
        }
        this.setState({ confirmLoading: true })
        const resettingPassword = await this.props.patchApi('/users/updateForceResetPassword', data);

        try {
            const res = resettingPassword.payload;
            if (res.status === 200) {
                this.setState({ unSuspSpin: false, dataSpin: false, confirmLoading: false });
                message.success(res.message)
                this.props.stepperMoving(0)
            }
            else {
                this.setState({ unSuspSpin: false, dataSpin: false, confirmLoading: false })
                message.error(res.message)
            }
        } catch (error) {
            this.setState({ unSuspSpin: false, dataSpin: false, confirmLoading: false })
            console.log(error);
            message.error('Something went wrong, please try again');

        }
    }
    handleOpenModal = () => {
        this.setState({ visibleModal: true })
    }
    handleCloseModal = () => {
        this.setState({ visibleModal: false })
    }
    render() {
        const { info } = this.props;
        const { loadData, visibleModal, confirmLoading } = this.state;
        return (
            <>
                <AllUserInfo info={info} />
                <Button style={{ margin: '22px 0 0 0 ', float: 'right' }} className='changeUserStatusBtn' onClick={this.handleOpenModal} shape="round" type="primary" loading={loadData} >
                    Reset Password
                </Button>
                <ModalCompnent visibleModal={visibleModal} handleAcceptModal={this.confirmationModal} loadingConfirm={confirmLoading}
                    content={<p style={{ fontSize: '17px', fontWeight: 'lighter' }}>You have asked to reset the password. Are you sure?</p>} title='Warning Message' handleCloseModal={this.handleCloseModal} />
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    info: state.actionsReducer.info,
})
export default connect(mapStateToProps, { getApi, patchApi, stepperMoving })(ResetUserPass)

