import { COMPANIES } from './types';
const initialState = {
    companies: [],
    routes:{
        users:['createUser','ChangePhoneNumber','test'],
        deductions:['create','delete'],
        requests:['create','cancel'],
        notifications:['sms'],
    }
}
const General = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPANIES:
            console.log('action.payload reducer = ',action.payload);
                        
            return {
                ...state,
                companies: action.payload
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default General