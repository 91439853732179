import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Row, Col, Spin, Result } from 'antd';
import { toggleSideBar, getApi, getAccessRoutes } from './stores/actions'
import { connect } from 'react-redux';
import { PrivteRoute } from "./utils/privteRoutes";
import Cookies from 'js-cookie';

import 'antd/dist/antd.css';
import 'react-phone-input-2/lib/style.css'
import './scss/style.scss';

import NotFound from './components/notFound';
import Navigation from './components/layout/navigation';
import SideBar from './components/layout/sideBar';
import Login from './views/commans/auth/index';
import Categories from './views/categories';
import X from './components/result'
const token = Cookies.get('token');
interface Props {
  authed: boolean,
  is_bar_collapsed: boolean,
  toggleSideBar: any,
  accessRoutes: [],
  getApi: any,
  getAccessRoutes: (arg0: []) => void
}
class App extends Component<Props> {
  state = {
    loadPermission: true,
    noPerrmision: false,
  }
 
  componentDidMount() {
    // console.log(process.env);
    
    console.log = function () { };
    this.checkTypeOfResponsive();
    this.getPermission();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.accessRoutes && this.props.accessRoutes &&
      prevProps.accessRoutes.length !== this.props.accessRoutes.length &&
      this.props.accessRoutes.length > 0) {
      this.setState({ noPerrmision: false })
    }
  }

  getPermission = async () => {

    const permission = await this.props.getApi('users/get-permissions');
    try {
      if (permission.payload.status === 200) {
        this.setState({ loadPermission: false })
        // this.setState({activePage:permission.payload.accessRoute[0].path})
        this.props.getAccessRoutes(permission.payload.accessRoutes)
      } else {
        this.setState({ loadPermission: false, noPerrmision: true })
      }
    } catch (error) {
      this.setState({ loadPermission: false, noPerrmision: true })
    }
  }

  checkTypeOfResponsive = () => {
    let intFrameWidth: number = window.innerWidth;
    if (intFrameWidth <= 600) {
      this.props.toggleSideBar(!this.props.is_bar_collapsed)
    } else {
      this.props.toggleSideBar(this.props.is_bar_collapsed)
    }
  }

  render() {

    return (
      <>
        <div className="App">

          <BrowserRouter>
            <Switch>
              <Route exact path="/log-in" component={Login} />
              <>

                <div>
                  {<>

                    {this.props.authed && <Navigation />}
                    {this.state.noPerrmision && token ?
                      <Result
                        status="403"
                        title="Permission"
                        subTitle="You don't have permission to access this page"
                      /> :
                      <Row>
                        {this.props.authed && <Col xs={!this.props.is_bar_collapsed ? 6 : 4}
                          md={!this.props.is_bar_collapsed ? 6 : 4}
                          lg={!this.props.is_bar_collapsed ? 4 : 2}><SideBar accessRoutes={this.props.accessRoutes} /></Col>}
                        <Col
                          xs={!this.props.authed ? 24 : !this.props.is_bar_collapsed ? 18 : 20}
                          md={!this.props.authed ? 24 : !this.props.is_bar_collapsed ? 18 : 20}
                          lg={!this.props.authed ? 24 : !this.props.is_bar_collapsed ? 20 : 22}>
                          <Switch>
                            <Route exact path="/log-in" component={Login} />
                            {/* Handle dynamic routes */}

                            {this.state.loadPermission ? <Spin size="large" tip="Loading..." style={{ margin: '50px 0' }} /> : this.props.accessRoutes.map((route: any, index: any) => {
                              return <PrivteRoute key={index} exact path={`/${route.path}/:actionName?/:id?`} actionsAllowed={route.actions} pathUrl={route.path} component={Categories} replace />
                            })}
                            <PrivteRoute path='/' component={X} />
                            <PrivteRoute component={NotFound} />
                          </Switch>
                        </Col>
                      </Row>
                    }</>}
                </div>

              </>
            </Switch>
          </BrowserRouter>

        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  authed: state.auth.authed,
  accessRoutes: state.auth.accessRoutes,
  is_bar_collapsed: state.componentReducer.is_bar_collapsed,

})
export default connect(mapStateToProps, { toggleSideBar, getApi, getAccessRoutes })(App);
