import React, { Component } from 'react'
import {
    Result, Button, message,
    Row,
    Col,
    Spin,

} from 'antd';
import XLSX from "xlsx";
import { UploadOutlined, FrownOutlined } from '@ant-design/icons';
import Helper from '../utils/helper';
import DataTable from '../components/dataTable';
import { connect } from 'react-redux';
import { request } from '../stores/actions';
import SheetTemplate from '../components/bulkActionSheetTemplate';
import helper from '../utils/helper';
import { bulkCreateUserInfoTemplate } from '../utils/bulkSheetsTemplates';
import BulkErrorsModal from '../components/bulkErrorsModal';
import { errorMessage } from '../utils/commonStringsConsts';
import moment from 'moment';
import TransactionErrorModal from '../components/transactionErrorModal';

interface Props {
    request: any,
    removeData: boolean
}
export class BulkCreateUserInfo extends Component<Props> {
    [x: string]: any;
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    state = {
        pagination: {
            current: 1,
            pageSize: 0,
            total: 0,
        },
        loading: false,
        dataSheet: [],
        columns: [],
        uploading: false,
        showTable: false,
        uploadingError: false,
        uploadingErrorMessage: "",
        visibleModal: false,
        validationErrors: [],
        visibleMessageModal: false,
        resErrorContent: undefined,
        sendingData: false
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.removeData !== prevProps.removeData && this.props.removeData) {
            this.clearData()
        }
    }

    handleFile(file: any /*:File*/) {
        console.log('file = ', file);
        /* Boilerplate to set up FileReader */
        let acceptable_exe = Helper.regexValidation.xlsxCsvRegex;
        if (!acceptable_exe.exec(file.name)) {
            message.error(`The extension of (${file.name}) is not supported!`);
        } else {
            this.setState({ loading: true })
            let fileReader = new FileReader(), rowObject: any, result, workbook: XLSX.WorkBook;
            fileReader.readAsBinaryString(file);
            fileReader.onload = (event: any) => {

                result = event.target.result;
                workbook = XLSX.read(result, { type: "binary", cellDates: true });
                workbook.SheetNames.forEach(sheet => {
                    rowObject = helper.sheetToJson(workbook.Sheets[sheet]);
                });
                const validation : any = helper.validateUploadSheetColumns(rowObject, bulkCreateUserInfoTemplate)
                if (!validation.success) {
                    this.setState({ dataSheet: [], columns: [], showTable: false, uploading: false, uploadingError: true, uploadingErrorMessage: validation.message });
                    const element: any = document.getElementById('error');
                    element?.scrollIntoView({ behavior: 'smooth' });
                    return
                }
                const columns = helper.generateTableColumns(validation.data, 150)
                this.setState({ dataSheet: validation.data, loading: false, pagination: {}, showTable: true,  columns});
            }
        }
        this.setState({ uploading: false });
    }
    handleFileChange = (e: { target: { files: any, value: any }; }) => {
        this.setState({ uploading: true, showTable: false, uploadingError: false, loading: false });

        const files = e.target.files;
        if (files && files[0]) this.handleFile(files[0]);

        e.target.value = null;
    }
    forwardClickToInputElement = () => {
        this.fileInputRef.current!.click();
    };

    handleTableChange = (e: any) => {
        console.log(e);
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ pagination: e, loading: false })
        }, 500);
    }
    submitData = async () => {
        try {
            this.setState({ sendingData: true })
            let updatedFields: string[] = []
            let dataSheet: any = [...this.state.dataSheet]
            for (let index = 0; index < dataSheet.length; index++) {
                updatedFields = [
                    ...updatedFields,
                    ...Object.keys(dataSheet[index])
                ]
                if (dataSheet[index]["hiringDate"]) {
                    const hiringDate = dataSheet[index]["hiringDate"]
                    if(isNaN(Number(hiringDate))){
                        dataSheet[index]["hiringDate"] = moment(new Date(hiringDate)).format("YYYY/MM/DD 12:00:00")
                    }
                }
            }
            updatedFields = updatedFields.filter((key, index) => updatedFields.indexOf(key) === index);
            const data = {
                sheetData: dataSheet,
                updatedFields,
                defaultSignUp: false,
            };
            const response: { [key: string]: any } = await this.props.request({ url: `/users/createBulkUsers`, method: "POST", data });
            if (response.data.status === 200) {
                message.success(response.data.message, 5)
                this.clearData()
            }
            else if (response.data.status === 422) {
                this.setState({
                    resErrorContent: response.data.message,
                    validationErrors: response.data.reason,
                    visibleModal: true
                })
            }
            else if (response.data.status === 400) {
                this.setState({
                    resErrorContent: {
                        message: response.data.message,
                        errorData: response.data.reason
                    },
                    visibleMessageModal: true
                })
            }
            else {
                message.error(response.data.message, 5)
            }
            this.setState({ sendingData: false })
        } catch (error) {
            console.log("🚀 ~ file: bulkCreateUserInfo.tsx ~ line 163 ~ BulkCreateUserInfo ~ submitData= ~ error", error)
            this.setState({ sendingData: false })
            message.error(errorMessage, 4)
        }
    }
    closeModals = () => {
        this.setState({ visibleModal: false, visibleMessageModal: false })
        this.clearData()
    }
    clearData = () => {
        this.setState({ dataSheet: [], showTable: false })
    }
    render() {
        const { columns, dataSheet, loading, pagination, uploading, showTable, uploadingError, uploadingErrorMessage, visibleModal, validationErrors,
            visibleMessageModal, resErrorContent, sendingData } = this.state;
        return (
            <div className='userStatus'>
                <form className="form-inline">
                    <div className="form-group">
                        <label htmlFor="file">  </label>
                        <input
                            hidden
                            type="file"
                            className="form-control"
                            id="file"
                            ref={this.fileInputRef}
                            onChange={this.handleFileChange}
                        />
                    </div>
                </form>

                <Row>
                    <Col span={4}><Button icon={<UploadOutlined />} onClick={this.forwardClickToInputElement} >Click to Upload file</Button></Col>
                    <Col span={13}>{showTable ? <><b>Uploaded Rows Count: </b> {dataSheet.length} {dataSheet.length > 1 ? "Users" : "User"}</> : "Please Upload your file as shown in the following table, Only the following data will be sent"}</Col>
                    {showTable && <Col span={7}>
                        <Button type="primary" onClick={this.submitData} style={{ float: 'right' }} loading={sendingData}> Submit File Data </Button>
                    </Col>}
                </Row>
                {dataSheet.length === 0 && <SheetTemplate columns={bulkCreateUserInfoTemplate} />}
                <span id='error'>
                    {uploadingError && <Result
                        icon={<FrownOutlined />}
                        title={uploadingErrorMessage}
                    />}
                </span>
                {showTable &&
                    <>
                        {uploading ? <Spin size="large" /> :
                            <DataTable columns={columns} pagination={pagination} rows={dataSheet} handleTableChange={this.handleTableChange}
                                loading={loading} scrolling={{ x: 2000 }} />
                        }
                    </>}
                <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={this.closeModals} errorMessage={resErrorContent} />
                <TransactionErrorModal resErrorContent={resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={this.handleCloseModal} />

            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    removeData: state.actionsReducer.removeData,
})

export default connect(mapStateToProps, { request })(BulkCreateUserInfo)


