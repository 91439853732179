import { STEP, SET_DRIVER_DATA } from "./types";
const initialState = {
    step: 0,
    driverData: {},
}
const truckingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case STEP:
            return {
                ...state,
                step: action.payload
            }
        case SET_DRIVER_DATA:
            return {
                ...state,
                driverData: action.payload
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default truckingReducer