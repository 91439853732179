import React, {  PureComponent } from 'react'
import GetInfo from '../../components/getInfo';
import Stepper from '../../components/stepper';
import AssignUser from './assignUser';
import { getUserByPhoneApi } from '../../utils/apisConsts';

class ViewAssignUser extends PureComponent{
    

    render() {
        const components = [
            {
                title: 'Get User',
                content: <GetInfo apiLink={getUserByPhoneApi}/>,
                id: 0,
            }, {
                title: 'Assign VIP User ',
                content: <AssignUser />,
                id: 1
            },
        ]
        return (
            <>
                <Stepper componentSteps={components} request={true}/>
            </>
        )
    }
}

export default ViewAssignUser