
import { Button, Col, Form, message, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { request } from '../../apis/axiosRequest';
import OutPutResult from '../../components/result';
import Spinner from '../../components/spinner';
import { errorMessage } from '../../utils/commonStringsConsts';
import helper from '../../utils/helper';

interface ServicesTypes {
    id: number,
    name: string,
    isSuspended: boolean
}
export default function SuspendGlobalServices() {
    const [services, setServices] = useState<ServicesTypes[]>([])
    const [updating, setUpdating] = useState(false)
    const [loadingServices, setLoadingServices] = useState(false)
    const [errorMode, setErrorMode] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        getGlobalServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGlobalServices = async () => {
        try {
            setLoadingServices(true)
            const services: { [key: string]: any } = await dispatch(request({ url: "/services/getAllGlobalServicesCaps", method: "GET" }))
            if (services.data.status === 200) {
                const data = services.data.data
                setServices(data.services)
            }
            else {
                setErrorMode(true)
            }
            setLoadingServices(false)
        }
        catch (e: any) {
            setLoadingServices(false)
            setErrorMode(true)
            console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 28 ~ getGlobalCaps ~ e", e)
        }
    }

    const updateServicesStatus = async (values: any) => {
        console.log("🚀 ~ file: updateGlobalCaps.tsx ~ line 70 ~ updateCaps ~ values", values)
        try {
            setUpdating(true)
            const suspend: number[] = [], unsuspend: number[] = []
            Object.keys(values).map((key) => {
                values[key] ? suspend.push(parseInt(key)) : unsuspend.push(parseInt(key))
                return key
            })
            const dataObj = { suspend, unsuspend }
            const data: { [key: string]: any } = await dispatch(request({
                url: "/services/updateGlobalServiceSuspension", method: "PATCH", data: dataObj
            }))
            if (data.data.status === 200) {
                message.success(data.data.message, 4)
            }
            else {
                message.error(data.data.message, 4)
            }
            setUpdating(false)
        }
        catch (e: any) {
            setUpdating(false)
            message.error(errorMessage, 4)
        }
    }

    return (
        loadingServices ? <Spinner size={"large"} />
            : errorMode ? <OutPutResult status={'500'} title={'Oops!'} subTitle={errorMessage} />
                : <div>
                    <Form name="nest-messages" onFinish={updateServicesStatus}>
                        <Row className='servicesSuspensionForm'>
                            <Col span='24'>
                                <label className='formTitle'>Global services (All services but system services)</label>
                            </Col>
                            {services.map((service: ServicesTypes) => <Col className='servicesInputs' span='8'>
                                <Row>
                                    <Col span={24}>
                                        <label>{service.name}</label>
                                    </Col>
                                    <Col span={24} className="switchCol">
                                        <p>Suspended: </p>
                                        <Form.Item name={service.id} initialValue={service.isSuspended}>
                                            <Switch defaultChecked={service.isSuspended} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>)}

                            <Col span='24'>
                                <Form.Item>
                                    <Button style={{ margin: '22px 0 0 0 ', width: "15%", float: "right" }} shape="round" type="primary" block htmlType="submit" loading={updating} >
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <span className='formTitle'>Overview Dashboard: </span><span className='action' onClick={() => helper.setRedirect("https://dashboard.nowpay.cloud/dashboard/276")}>https://dashboard.nowpay.cloud/dashboard/276</span>
                    </Form>
                </div>
    )
}