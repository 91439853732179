import { Button, Col, Descriptions, Divider, Form, Input, InputNumber, message, Row, Table } from "antd";
import { useState } from "react"
import { useDispatch } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BasicUserInfo from "../../components/basicUserInfo";
import { stepperMoving } from "../../stores/actions";
import { refundActiveRequestsAPI } from "../../utils/apisConsts";
import { errorMessage } from "../../utils/commonStringsConsts";
import { activeRequestsColumns } from "../../utils/tableColumns";

interface Props {
    userInfo: any,
    requestDetails: any
}

export default function RefundActiveRequestForm({ userInfo, requestDetails }: Props) {
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();

    const submitRefund = async (values: { refundedAmount: number, comment: string }) => {
        try {
            setSending(true)
            const response: any = await dispatch(request({
                url: refundActiveRequestsAPI, method: "POST",
                data: {
                    phone: userInfo.phone,
                    totalAmount: values.refundedAmount,
                    comment: values.comment,
                    advanceID: requestDetails.advanceID
                }
            }))

            if (response.data.status === 200) { //success
                message.success(response.data.message, 4)
                dispatch(stepperMoving(0))
            }
            else {
                message.error(`${response.data.message}, ${response.data.errorData? "Reason: " +response.data.errorData : ""}`, 4)
            }
            setSending(false)
        }
        catch (error: any) {
            console.log("🚀 ~ file: refundInActiveRequestsForm.tsx:91 ~ submitRefund ~ error", error)
            message.error(errorMessage)
            setSending(false)
        }
    }

    return (
        <div className="refundRequest">
            {requestDetails.installments.length ? <>
                <Divider orientation="left">Request #{requestDetails.advanceID}</Divider>
                    <Descriptions bordered>
                        <Descriptions.Item label="Requested Amount" span={1}>{requestDetails.requestedAmount}</Descriptions.Item>
                        <Descriptions.Item label="Service" span={1}>{requestDetails.serviceTypeName}</Descriptions.Item>
                        <Descriptions.Item label="Transaction Status" span={1}>{requestDetails.transactionStatusName}</Descriptions.Item>
                    </Descriptions>
                <Divider orientation="left">Request Installments</Divider>
                <Table
                    pagination={false}
                    onChange={() => { }}
                    columns={activeRequestsColumns}
                    dataSource={requestDetails.installments.sort((a: {instalmentOrder: number}, b: {instalmentOrder: number})=> a.instalmentOrder - b.instalmentOrder)}
                    scroll={{ x: 'max-content' }}
                />
                <Form name="nest-messages" onFinish={submitRefund}>
                    <Row>
                        <Col span='24'>
                            <label>Refund Amount <span>*</span></label>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                name="refundedAmount"
                                rules={[{ required: true, message: 'Required' }, { type: "number", min: 10, message: "Refund amount can't be less than 10 EGP" }]}
                            >
                                <InputNumber placeholder="Enter refund amount" />
                            </Form.Item>
                        </Col>
                        <Col span='24'>
                            <Form.Item
                                name="comment"
                                rules={[{ required: true, message: 'Required' }]}
                            >
                                <Input.TextArea placeholder="Enter a comment" />
                            </Form.Item>
                        </Col>
                        <Col span='24' className="btnCol">
                            <Button shape="round" type="primary" block htmlType="submit" loading={sending}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </> : ""
            }
            <Divider orientation="left">User Information</Divider>
            <BasicUserInfo info={userInfo} />
        </div>
    )
}