import { Button, Checkbox, Col, Divider, Form, message, Row } from "antd"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { request } from "../../apis/axiosRequest";
import BulkErrorsModal from "../../components/bulkErrorsModal";
import { bulkUpdateApprovedUserInfoTemplate } from "../../utils/bulkSheetsTemplates"
import { errorMessage } from "../../utils/commonStringsConsts";
import { stepperMoving } from "../../stores/actions"
import moment from "moment";
import { updateBulkApprovedUsersApi } from "../../utils/apisConsts";
import TransactionErrorModal from "../../components/transactionErrorModal";

interface Props {
    uploadedData: { [key: string]: any }[],
    setData: any,
    api?: string,
    template?:any[]
}
export default function BulkUpdateForm(props: Props) {
    const [sendingData, setSendingData] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [resErrorContent, setResErrorContent] = useState<any>(undefined);
    const [visibleMessageModal, setVisibleMessageModal] = useState(false)
    const [validationErrors, setValidationErrors] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        console.log("🚀 ~ file: bulkUpdateForm.tsx ~ line 7 ~ uploadedData", props.uploadedData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const generateInputs = () => {
        const template = props.template ? props.template : bulkUpdateApprovedUserInfoTemplate
        const commonFields = template.filter((value: { label: string }) => Object.keys(props.uploadedData[0]).includes(value.label))
        const fieldsCanUpdate=commonFields.map((item:{label:string})=>item.label)
        const requiredFields = template.filter((field) => field.isRequired).map((filed) => filed.label)
        const inputs = Array.isArray(props.uploadedData) && props.uploadedData.length > 0 &&
            fieldsCanUpdate.map((key) => <Col span={12} className="fieldCol">
                <Form.Item name={key} className="filedItem" valuePropName="checked" initialValue={requiredFields.includes(key)}>
                    <Checkbox defaultChecked={requiredFields.includes(key)} disabled={requiredFields.includes(key)}>{key}</Checkbox>
                </Form.Item>
            </Col>)
        return Array.isArray(inputs) ? inputs : 'No fields available to be updated'
    }

    const submitData = async (values: any) => {
        console.log("🚀 ~ file: bulkUpdateForm.tsx ~ line 27 ~ submitData ~ values", values)
        try {
            setSendingData(true)
            const updatedFields = Object.keys(values).filter((key) => !!values[key])
            const sheetData = props.uploadedData.map((row) => {
                const keys = Object.keys(row)
                let selectedData: { [key: string]: any } = {}
                for (let index = 0; index < keys.length; index++) {
                    if (updatedFields.includes(keys[index])) {
                        if (keys[index] === "hiringDate") {
                            if(isNaN(Number(row[keys[index]]))){
                                const date = new Date(row[keys[index]])
                                row[keys[index]] = moment(date).format("YYYY/MM/DD 12:00:00")
                        }
                        }
                        selectedData[keys[index]] = row[keys[index]]
                    }
                }
                return selectedData
            })

            const data = {
                sheetData,
                updatedFields: updatedFields.filter((key) => key !== "userID")
            };
            const response: { [key: string]: any } = await dispatch(request({ url: props.api ? props.api : updateBulkApprovedUsersApi, method: "PATCH", data }));
            if (response.data.status === 200) {
                message.success(response.data.message, 5)
                clearData()
            }
            else if (response.data.status === 422) {
                setValidationErrors(response.data.reason)
                setResErrorContent(response.data.message)
                setVisibleModal(true)
            }
            else if (response.data.status === 400) {
                setResErrorContent({
                    message: response.data.message,
                    errorData: response.data.reason
                })
                setVisibleMessageModal(true)
            }
            else {
                message.error(response.data.message, 5)
            }
            setSendingData(false)
        } catch (error) {
            setSendingData(false)
            message.error(errorMessage, 4)
        }
    }

    const closeModals = () => {
        setVisibleModal(false)
        setVisibleMessageModal(false)
        clearData()
    }

    const clearData = () => {
        props.setData([])
        dispatch(stepperMoving(0))
    }

    return (<>
        <Divider orientation="left" className='formTitle'>Choose Fields To update</Divider>
        <Form onFinish={submitData} style={{ margin: '20px 0' }} className="servicesCapsForm">
            <Row>
                {
                    generateInputs()
                }
                <Col span={24}>
                    <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={sendingData}> Submit File Data </Button>
                </Col>
            </Row>
        </Form>
        <BulkErrorsModal errors={validationErrors} visibleModal={visibleModal} handleCloseModal={closeModals} errorMessage={resErrorContent} />
        <TransactionErrorModal resErrorContent={resErrorContent} visibleMessageModal={visibleMessageModal} closeModal={closeModals} />

    </>)
}